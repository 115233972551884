import React from 'react'
import { MarketPlace } from '../../../../../../assessts/LandingPage'
import style from './four.module.css'

const Four = () => {
  return (
    <div className={`${style.main} d-flex flex-column align-items-center justify-content-center`}>
      <h1>Discover your perfect program in our marketplace</h1>
      <p className='text-center'>Browse through a diverse range of training programs created by expert coaches, allowing you to find the one that resonates with your aspirations and preferences. Unlock your full potential with a program that suits you.</p>
      <img src={MarketPlace} alt="" />
    </div>
  )
}

export default Four
