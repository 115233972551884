import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import styles from "./feedcard.module.css";
import SvgIcon from "../../../../assessts/Svg/svgIcons";
import { ThemeContext } from "../../../../context/themeContext";
import axios from "axios";
import { auth } from "../../../../firebase";
import { getFeeds } from "../../../../services/store/coachApi";
import FeedImages from "../../../../assessts/images/gym.jpg";
import UserIcon from "../../../../assessts/icons/avtr.png";
import Like from "../../../ReusableUi/Like";
import StyledMessageInput from "../../../inbox/messageInput";
import Comment from "../../../ReusableUi/comment";
import Loader from "../../../ReusableUi/Loader";
import SvgIcons from "../../../ReusableUi/SvgIcon";
import io from 'socket.io-client';
const FeedCard = () => {
  const { theme } = useContext(ThemeContext);
  const [isFeedCardDetails, setFeedCardDetails] = useState([]);
  const [isLikeCount, setLikeCount] = useState("0");
  const [isOpenComment, setOpenComment] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [authReady, setAuthReady] = useState(false);
  const [isEmptyDataForFeed, setEmptyDataForFeed] = useState(false);
  const socket = useRef();
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_Baseurl, { transports: ["websocket"] });

    socket.current.on("connect", () => {
      console.log("Socket connected:", socket.current.id);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user); // Set authReady to true if user is logged in
    });

    return () => unregisterAuthObserver(); // Clean up observer on unmount
  }, []);
  const handleOpenComment = () => {
    setOpenComment(!isOpenComment);
  };

  const getLikes = async () => {
    socket.current.on("likedFeed", async (newLike) => {

      await fetchFeedDetails();
    });
  };

  useEffect(() => {
    getLikes();
    return () => {
      socket.current.off("likedFeed");
    };
  }, []);

  const getComments = async () => {
    socket.current.on("commented_Feed", async (newLike) => {
      await fetchFeedDetails();
    });
  };

  useEffect(() => {
    getComments();
    return () => {
      socket.current.off("commented_Feed");

    };
  }, [socket]);
  useEffect(() => {
    if (authReady) {
      setLoader(true);

      fetchFeedDetails();
    }
  }, [authReady]);
  const fetchFeedDetails = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getFeeds}?All="true"`,
        { headers: { Authorization: token } }
      );
      const feedDataWithLikes = response?.data?.feeds?.map((item) => ({
        ...item,
        liked: false,
      }));
      
      {
        feedDataWithLikes.length <= 0
          ? setEmptyDataForFeed(true)
          : setEmptyDataForFeed(false);
      }
      setFeedCardDetails(feedDataWithLikes);
      setLikeCount(response?.data?.likesCount);
    } catch (error) {
      console.error("Error fetching session calendar:", error);
    }
    setLoader(false);
  };

  function timeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.round((now - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes}m${minutes > 1 ? "" : ""}`;
    } else if (hours < 24) {
      return `${hours}hr${hours > 1 ? "" : ""}`;
    } else if (days < 7) {
      return `${days}day${days > 1 ? "" : ""}`;
    } else {
      return date.toLocaleDateString();
    }
  }

  // Handle To Create  Feed
  const handleLikeClick = async (feedData) => {
    const feedId = feedData.id
    const userId = auth?.currentUser?.uid;;
    try {
      const clickedLike = feedData?.feedlikes.find(
        (like) => like.userId === userId
      );
      const isLikeUpdate = clickedLike?.isLike == true ? true : false;

      socket.current.emit("likeFeed", {
        feedId: feedId,
        userId: userId,
        isLike: isLikeUpdate == true ? false : true,
        likes: isLikeUpdate == true ? null : 1,
        groupId: feedData?.groupId,
        likeId: clickedLike?.id || null,
      });
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token },
    };
    await axios.post(url, payload, config);
  };
  const isFeed = true;
  
  return (
    <>
      <Container fluid>
        <Row>
          {isLoader ? (
            <div  style={{height : "25vw",margin  :"auto"}} className="d-flex justify-content-center align-items-center" >

              <Loader />
            </div>
          ) : isEmptyDataForFeed ? (
            <div
            style={{ height: "25vw"}}
              className="w-full   d-flex flex-column justify-content-center align-items-center"
            >
              <SvgIcons iconName="noSessionPlane" />
              <div className="w-100 d-flex flex-column justify-content-center">
                <h4 className="noSessionHeading d-flex justify-content-center">

                  No Feeds
                </h4>
                <div className="d-flex justify-content-center">
                  {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
                </div>
              </div>
            </div>
          ) : (
            isFeedCardDetails?.map((feedData, index) => {
              const islike = feedData.feedlikes.find(
                (like) => like.userId === auth.currentUser.uid
              );
              return (
                <Col xs={12} md={12} lg={12} key={index} className="mt-4">
                  <Card className={styles.feedCard}>
                    <Card.Header
                      className={`${styles.feedCardsHeader} m-0 px-2 py-2`}
                    >
                      <div
                        className={`${styles.feedCardHeaderNameContainer} d-flex m-0 p-0`}
                      >
                        <div>
                          <Card.Img
                            width={45}
                            height={45}
                            className="object-fit-contain m-0 p-0 "
                            src={UserIcon}
                          />
                        </div>
                        <Card.Title>
                          <p className={`${styles.feedCardHeaderTitle} m-0`}>
                            {feedData &&
                              `${feedData?.athletes?.firstName} ${feedData?.athletes?.lastName}`}
                          </p>
                        </Card.Title>
                      </div>
                      <div className={`${styles.feedCardUpdatedAt} m-0`}>
                        <span>{timeAgo(feedData.createdAt)}</span>
                      </div>
                    </Card.Header>
                    <Card.Body className="m-0 py-1 px-2">
                      <div className="w-100">
                        <Card.Img
                          className="object-fit-contain m-0 p-0 w-100 "
                          src={FeedImages}
                        />
                      </div>
                      {/* <div className={`${styles.Info} mt-3`}>
                        <div
                          style={{
                            background: "#25BD3D",
                          }}
                        >
                          <SvgIcon iconName="block" size={30} color="blue" />
                          <p>Blocks</p>
                          <h5>
                            {(feedData &&
                              feedData?.athleteSessionFeed?.totalBlocks) ||
                              "0"}
                            /4
                          </h5>
                        </div>
                        <div
                          style={{
                            background: "#DC5C3E",
                          }}
                        >
                          <SvgIcon iconName="minutes" size={30} color="blue" />
                          <p>Minutes</p>
                          <h5>
                            {(feedData &&
                              feedData?.athleteSessionFeed?.totalDuration) ||
                              "00"}
                          </h5>
                        </div>
                        <div
                          style={{
                            background: "#3919EC",
                          }}
                        >
                          <SvgIcon iconName="infinty" size={30} color="blue" />
                          <p>Intensity</p>
                          <h5>
                            {(feedData &&
                              feedData?.athleteSessionFeed?.intensity) ||
                              "0"}
                          </h5>
                        </div>
                        <div
                          style={{
                            background: "#09D0AD",
                          }}
                        >
                          <SvgIcon iconName="LB" size={30} color="blue" />
                          <p>LB</p>
                          <h5>
                            {(feedData &&
                              feedData?.athleteSessionFeed?.totalWeight) ||
                              "40"}
                          </h5>
                        </div>
                      </div> */}
                      <div className={styles.feedCardLikesandComments}>
                        <div onClick={()=>handleLikeClick(feedData)}>
                          {theme === "light" ? (
                            <>
                              <>
                                {islike?.isLike ? (
                                  <svg
                                    style={{
                                      fill: `var(--primary-text-color, black)`,
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                 
                                    width="20"
                                    height="20"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                                  </svg>
                                ) : (
                                  <svg
                                   
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    stroke={`black`}
                                    id="favourite"
                                  >
                                    <path
                                      d="M10.0341 16.1413C10.0341 16.1413 2.8916 12.1415 2.8916 7.28466C2.8916 6.42605 3.18908 5.59396 3.73343 4.92996C4.27778 4.26596 5.03537 3.81107 5.8773 3.64269C6.71924 3.4743 7.59351 3.60282 8.35137 4.00637C9.10922 4.40993 9.70386 5.0636 10.0341 5.85616C10.3643 5.0636 10.959 4.40993 11.7168 4.00637C12.4747 3.60282 13.3489 3.4743 14.1909 3.64269C15.0328 3.81107 15.7904 4.26596 16.3347 4.92996C16.8791 5.59396 17.1766 6.42605 17.1766 7.28466C17.1766 12.1415 10.0341 16.1413 10.0341 16.1413Z"
                                      stroke-opacity="0.8"
                                      stroke-width="1.52373"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                )}
                              </>
                            </>

                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 19 19"
                                fill="none"
                              >
                                <path
                                  d="M9.53409 15.6411C9.53409 15.6411 2.3916 11.6413 2.3916 6.78441C2.3916 5.9258 2.68908 5.09372 3.23343 4.42972C3.77778 3.76572 4.53537 3.31083 5.3773 3.14244C6.21924 2.97406 7.09351 3.10257 7.85137 3.50613C8.60922 3.90969 9.20386 4.56335 9.53409 5.35591C9.86432 4.56335 10.459 3.90969 11.2168 3.50613C11.9747 3.10257 12.8489 2.97406 13.6909 3.14244C14.5328 3.31083 15.2904 3.76572 15.8347 4.42972C16.3791 5.09372 16.6766 5.9258 16.6766 6.78441C16.6766 11.6413 9.53409 15.6411 9.53409 15.6411Z"
                                  stroke="white"
                                  stroke-opacity="0.8"
                                  stroke-width="1.52373"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </>
                          )}
                          {/* <Like  /> */}

                          <span>{feedData.likesCount}</span>
                        </div>
                        <div onClick={handleOpenComment}>
                          {theme === "light" ? (
                            <>
                              <svg
                                style={{ fill: `black` }}
                                id="chatcoment"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 19 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.36189 12.3156C8.68079 12.3156 8.93931 12.5741 8.93931 12.893V15.154C12.1137 13.2999 14.0235 11.9883 15.1753 10.8431C15.8437 10.1785 16.2114 9.61175 16.4249 9.07133C16.6396 8.52795 16.7344 7.92082 16.7344 7.11881C16.7344 4.24872 14.4078 1.92206 11.5377 1.92206H7.49576C4.62568 1.92206 2.29901 4.24872 2.29901 7.11881C2.29901 9.9889 4.62568 12.3156 7.49576 12.3156H8.36189ZM8.93931 16.8227C16.6352 12.3938 18.178 10.5797 18.178 7.11881C18.178 3.45148 15.205 0.478516 11.5377 0.478516H7.49576C3.82843 0.478516 0.855469 3.45148 0.855469 7.11881C0.855469 10.7861 3.82843 13.7591 7.49576 13.7591V16.6561C7.49576 17.0979 7.97151 17.3758 8.3553 17.1571C8.5538 17.044 8.74845 16.9326 8.93931 16.8227Z"
                                  fill-opacity="0.8"
                                />
                              </svg>
                            </>
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.86189 12.8156C8.18079 12.8156 8.43931 13.0741 8.43931 13.393V15.654C11.6137 13.7999 13.5235 12.4883 14.6753 11.3431C15.3437 10.6785 15.7114 10.1118 15.9249 9.57133C16.1396 9.02795 16.2344 8.42082 16.2344 7.61881C16.2344 4.74872 13.9078 2.42206 11.0377 2.42206H6.99576C4.12568 2.42206 1.79901 4.74872 1.79901 7.61881C1.79901 10.4889 4.12568 12.8156 6.99576 12.8156H7.86189ZM8.43931 17.3227C16.1352 12.8938 17.678 11.0797 17.678 7.61881C17.678 3.95148 14.705 0.978516 11.0377 0.978516H6.99576C3.32843 0.978516 0.355469 3.95148 0.355469 7.61881C0.355469 11.2861 3.32843 14.2591 6.99576 14.2591V17.1561C6.99576 17.5979 7.47151 17.8758 7.8553 17.6571C8.0538 17.544 8.24845 17.4326 8.43931 17.3227Z"
                                  fill="white"
                                  fill-opacity="0.8"
                                />
                              </svg>
                            </>
                          )}

                          <span style={{ color: `black` }}></span>
                        </div>
                      </div>
                      <div>
                        {isOpenComment && (
                          <StyledMessageInput
                          setOpenComment={setOpenComment}
                            isFeed={isFeed}
                            feedData={feedData}
                            feedId={feedData.id}
                          />
                        )}

                        <Comment comments={feedData?.likesAndComments} />
                        
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          )}
        </Row>
      </Container>
    </>
  );
};

export default FeedCard;