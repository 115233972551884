import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { createProgram, editProgram } from "../../../apis";
import { auth } from "../../../firebase";
import LoadingSpinner from "../../loadingSpinner";
import "./createProgram.css";
import { editAdminProgram } from "../../../services/store/adminApi";
import imageCompression from "browser-image-compression";

/**
 * CreateProgram Component
 * Used for creating or editing a program.
 * @param {Object} props - Component props
 * @param {Function} onClose - Function to close the modal
 * @param {Object} currentProgram - The program currently being edited, if any
 * @param {Function} setCurrentProgram - Function to set the current program
 */

const CreateProgram = ({
  onClose,
  currentProgram,
  setCurrentProgram,
  activeTab,
}) => {
  // State for loading and validation errors
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  // const [EditImg, setEditImg] = useState();
  // useEffect(() => {
  //   const arrayBufferToBase64 = (buffer) => {
  //     let binary = "";
  //     const bytes = new Uint8Array(buffer);
  //     const len = bytes.byteLength;
  //     for (let i = 0; i < len; i++) {
  //       binary += String.fromCharCode(bytes[i]);
  //     }
  //     return window.btoa(binary);
  //   };
  //   const getImgSrc = (imageSrc) => {
  //     const binaryImage = imageSrc?.data;
  //     if (binaryImage) {
  //       const base64Image = arrayBufferToBase64(binaryImage);
  //       return `data:image/png;base64,${base64Image}`;
  //     }
  //     return null;
  //   };
  //   const Img = getImgSrc(currentProgram?.Picture);
  //   setEditImg(Img);
  // }, [currentProgram]);

  const [formData, setFormData] = useState({
    file: currentProgram?.Picture || "",
    title: currentProgram?.title || "",
    level: currentProgram?.level || "",
    description: currentProgram?.description || "",
    marketplace: currentProgram?.marketplace === true, // Ensure it's a boolean
    fee: currentProgram?.fee || "",
    weeks: currentProgram?.weeks || "",
    coachId: auth.currentUser.uid || "",
  });

  // useEffect(() => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     file: EditImg || "",
  //   }));
  // }, [EditImg]);

  /**
   * Handles file upload and updates form data state.
   * @param {Object} e - Event object
   */
  const MAX_FILE_SIZE = 100 * 1024;
  const COMPRESSION_LIMIT = 10 * 1024; // 50 KB

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
    console.log(`File size: ${fileSizeMB} MB`);

    if (file) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 500,
        initialQuality: 1,
        useWebWorker: true,
      };

      try {
        let fileToProcess = file;

        // Check if compression is needed
        if (file.size > COMPRESSION_LIMIT) {
          const compressedFile = await imageCompression(file, options);
          fileToProcess = compressedFile;
          if (fileToProcess.size > MAX_FILE_SIZE) {
            toast.error("File size exceeds limit.");
            return;
          }
          console.log(fileToProcess.size, "fileToProcess.size");
          console.log("File compressed successfully.");
        } else {
          console.log("No compression needed.");
        }

        // Read the processed file as base64
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64File = reader.result;
          const base64SizeMB = (base64File.length * 3) / 4 / (1024 * 1024);
          console.log(`Base64 file size: ${base64SizeMB.toFixed(2)} MB`);

          setFormData((prevData) => ({ ...prevData, file: base64File }));
          console.log("File (base64) added to form state:", base64File);
        };

        reader.onerror = (error) => {
          console.error("FileReader error:", error);
          toast.error("An error occurred while reading the file.");
        };

        reader.readAsDataURL(fileToProcess);
      } catch (error) {
        console.error("Error during file processing:", error);
        toast.error("An error occurred while processing the file.");
      }
    } else {
      toast.error("No file selected.");
    }
  };

  /**
   * Handles input changes and updates form data state.
   * @param {Object} e - Event object
   * @param {string} field - The field to update
   */

  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
    setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  /**
   * Handles radio button changes.
   * @param {string} value - The selected value
   * @param {string} field - The field to update
   */

  const handleRadioChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  /**
   * Handles saving the program.
   * Performs validation and makes an API call to save or edit the program data.
   */
  const handleSave = async () => {
    try {
      // Check if it's a new program creation
      if (!currentProgram) {
        // Validate form data
        const validationErrors = validateFormData(formData);

        // Update state with validation errors
        setValidationErrors(validationErrors);

        // Check for any validation errors
        if (Object.keys(validationErrors).length > 0) {
          toast.error("Please fill in all mandatory fields.");
          return;
        }
      }

      // Indicate loading state
      setIsLoading(true);

      // Get authorization token
      const token = await auth.currentUser.getIdToken(true);
      // Determine the API endpoint and payload based on whether it's a new program or an edit
      const endpoint =
        activeTab === "manageMarketplace"
          ? editAdminProgram
          : currentProgram
          ? editProgram
          : createProgram;
      const payload = currentProgram ? { ...formData } : { ...formData };

      // Make the API call
      await makeApiCall(endpoint, payload, token);

      // Reset current program and close the modal
      setCurrentProgram("");
      onClose();

      // Show success message
      toast.success(
        currentProgram
          ? "Program edited successfully"
          : "Program created successfully"
      );
    } catch (err) {
      // Handle errors
      setIsLoading(false);
      console.error(err);
      toast.error("Error Occure");
      setFormData({
        file: "",
        title: "",
        level: "",
        description: "",
        marketplace: "",
        fee: "",
        weeks: "",
        coachId: "",
      });
    }
  };

  /**
   * Validates form data and returns any errors found.
   * @param {Object} data - Form data to validate
   * @return {Object} - Object containing validation errors
   */
  const validateFormData = (data) => {
    const errors = {};
    if (!data.file) errors.file = "Image is required";
    if (!data.title) errors.title = "Program title is required";
    if (!data.level) errors.level = "Level is required";
    if (!data.description) errors.description = "Description is required";
    return errors;
  };

  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token },
    };

    if (currentProgram) {
      config.params = { programId: currentProgram.id };
    }

    if (currentProgram) {
      // For editing (patch request)
      await axios.patch(url, payload, config);
    } else {
      // For creation (post request)
      await axios.post(url, payload, config);
    }
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col xs={4} md={4}>
          <Form.Group>
            <Form.Label className="upload">Upload Profile</Form.Label>
            <div
              style={{
                position: "relative",
                width: "60%",
                height: "200px", // Set a height to visualize the container
              }}
              onDragOver={(e) => e.preventDefault()} // Prevent default to allow drop
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files[0];
                if (file) {
                  handleFileUpload({ target: { files: [file] } });
                }
              }}
            >
              <div
                style={{
                  backgroundImage: formData?.file
                    ? `url(${formData?.file})`
                    : "none",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  border: formData.file ? "none" : "2px dashed #ccc", // Optional: Border for drag-drop area
                }}
              >
                {!formData.file && (
                  <div
                    className="drag-drop-text"
                    style={{ textAlign: "center" }}
                  >
                    <p>Drag & Drop your file here</p>
                    <span style={{ marginRight: "5px" }}>or</span>
                    <label
                      htmlFor="fileInput"
                      className="browse-text"
                      style={{
                        cursor: "pointer",
                        color: "#007bff",
                        textDecoration: "underline",
                      }}
                    >
                      Browse
                    </label>
                    <span> to upload</span>
                  </div>
                )}

                {formData?.file && (
                  <div
                    className="hover-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      transition: "opacity 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)}
                    onMouseLeave={(e) => (e.currentTarget.style.opacity = 0)}
                  >
                    <div
                      className="drag-drop-text"
                      style={{ textAlign: "center" }}
                    >
                      <p>Drag & Drop your file here</p>
                      <span style={{ marginRight: "5px" }}>or</span>
                      <label
                        htmlFor="fileInput"
                        className="browse-text"
                        style={{
                          cursor: "pointer",
                          color: "#007bff",
                          textDecoration: "underline",
                        }}
                      >
                        Browse
                      </label>
                      <span> to upload</span>
                    </div>
                  </div>
                )}

                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  className="file-input"
                  onChange={handleFileUpload}
                  style={{ display: "none" }} // Hide the file input
                />
              </div>
            </div>

            {validationErrors.file && (
              <p style={{ color: "red", marginTop: "5px" }}>
                {validationErrors.file}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Form.Group controlId="frmB">
            <Form.Label className="customInputLabel">Program Title</Form.Label>
            <Form.Control
              value={formData.title}
              type="text"
              placeholder=" Title"
              className="customInput"
              onChange={(e) => handleInputChange(e, "title")}
            />
            {validationErrors.title && (
              <p style={{ color: "red", marginTop: "5px" }}>
                {validationErrors.title}
              </p>
            )}
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="frmB">
            <Form.Label className="customInputLabel">Level</Form.Label>
            <Form.Control
              value={formData.level}
              type="text"
              placeholder="Level"
              className="customInput"
              onChange={(e) => handleInputChange(e, "level")}
            />
            {validationErrors.level && (
              <p style={{ color: "red", marginTop: "5px" }}>
                {validationErrors.level}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12}>
          <Form.Group controlId="frmB">
            <Form.Label className="customInputLabel">Description</Form.Label>
            <Form.Control
              value={formData.description}
              as="textarea"
              rows={3}
              placeholder="Description"
              className="customInput"
              onChange={(e) => handleInputChange(e, "description")}
            />
            {validationErrors.description && (
              <p style={{ color: "red", marginTop: "5px" }}>
                {validationErrors.description}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Form.Group controlId="frmB">
            <Form.Label>Fee</Form.Label>
            <Form.Control
              value={formData.fee}
              type="text"
              placeholder="$1200"
              onChange={(e) => handleInputChange(e, "fee")}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="frmB">
            <Form.Label>Duration (Weeks)</Form.Label>
            <Form.Control
              value={formData.weeks}
              type="text"
              placeholder="2"
              onChange={(e) => handleInputChange(e, "weeks")}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12}>
          <Form.Group>
            <Form.Label className="customInputLabel">
              Sell on Marketplace
            </Form.Label>
            <div className="radioCon">
              <Form.Check
                type="radio"
                label="Yes"
                name="marketplace"
                id="yes"
                checked={formData.marketplace === true} // Check if marketplace is true
                onChange={() => handleRadioChange(true, "marketplace")} // Pass true for Yes
              />

              <Form.Check
                type="radio"
                label="No"
                name="marketplace"
                id="no"
                checked={formData.marketplace === false} // Check if marketplace is false
                onChange={() => handleRadioChange(false, "marketplace")} // Pass false for No
                className="custom-radio"
              />
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="d-flex justify-content-end gap-4 mt-3">
          <Button
            style={{
              backgroundColor: `var(--primary-color,#A19686)`,
              color: `var(--primary-text-color,white)`,
              height: "48px",
              width: "120px",
              borderRadius: "8px",
              border: "none",
              fontSize: "19px",
              fontWeight: "600",
              lineHeight: "24px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: `var(--secondary-color,#A19686)`,
              color: `var(--secondary-text-color,white)`,
              height: "48px",
              width: "120px",
              borderRadius: "8px",
              border: "none",
              fontSize: "19px",
              fontWeight: "600",
              lineHeight: "24px",
            }}
            onClick={handleSave}
          >
            {isLoading ? (
              <LoadingSpinner size="sm" />
            ) : currentProgram ? (
              "Update"
            ) : (
              "Save"
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateProgram;
