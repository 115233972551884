import React from 'react'
// import SvgIcon from '../../../../assessts/Svg/svgIcons'
// import YoutubeIcon from '../../../assessts/icons/youTube.svg'
import logo from '../../../../assessts/logotrnDark.png'
import style from './footer.module.css'
import { AppStore, Facebook, GooglePlay, Instagram, Pinterest, Twitter, Youtube } from '../../../../assessts/LandingPage'

const Footer = () => {
    return (
        <div className={`${style.main} d-flex flex-column`} style={{ fontFamily: "'Hurme Geometric Sans 2', sans-serif" }}>
            <div className={`${style.contentBox} d-flex  `}>
                <div className={style.leftBox}>
                    <img src={logo} alt="logo" className={`${style.logo} d-flex flex-column`} />
                    <div className='d-flex flex-row '>
                        <button className={`${style.btnWimg}`}>
                            <img src={AppStore} alt="AppStore" className={`${style.btnImg}`} />
                            <div className={`${style.btnDiv}`}>
                                <span className={`${style.btnText1}`}>Download on the</span>
                                <span className={`${style.btnText2}`}>App Store</span>
                            </div>
                        </button>

                        <button className={`${style.btnWimg2}`}>
                            <img src={GooglePlay} alt="GooglePlay" className={`${style.btnImg}`} />
                            <div className={`${style.btnDiv}`}>
                                <span className={`${style.btnText11}`}>GET IT ON</span>
                                <span className={`${style.btnText22}`}>Google Play</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className={`${style.middleBox} d-flex `}>
                    <ul class={`${style.ullists}`}>
                        <li><p className={style.linkHeads}>WORKOUTS</p></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Workout Videos</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Custom Workouts</a></li>
                        <li><p className={style.linkHeads}>PROGRAMS</p></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Workout Programs</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Meal Plans</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Pilot Programs</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Routines</a></li>
                    </ul>
                    <ul class={`${style.ullists}`}>
                        <li><p className={style.linkHeads}>HEALTHY LIVING</p></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Fitness</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Health</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Nutrition</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Healthy Recipes</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Experts</a></li>
                    </ul>
                    <ul class={`${style.ullists}`}>
                        <li><p className={style.linkHeads}>ABOUT</p></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Careers</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Tutorials</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>Our Team</a></li>
                        <li><a href="#" className='text-reset text-decoration-none'>B2B Options</a></li>
                    </ul>
                </div>
                <div className={`${style.rightBox} `}>
                    <h1>MEMBERSHIP</h1>
                    <div class={`${style.rightLinks} d-flex `} >
                        <a href="#" className='text-reset text-decoration-none'>FB PLUS</a>
                        <a href="#" className='text-reset text-decoration-none'>COMMUNITY</a>
                        <a href="#" className='text-reset text-decoration-none'>BLOG</a>
                        <a href="#" className='text-reset text-decoration-none'>CONTACT US</a>
                        <a href="#" className='text-reset text-decoration-none'>FAQ</a>
                        <a href="#" className='text-reset text-decoration-none'>STORE</a>
                    </div>
                </div>
            </div>
            <div className={`${style.bottomLine} d-flex justify-content-between align-items-center align-items-sm-start`}>
                <div>
                    <p>Copyright 2023 the training app. All rights reserved. <a href="#">Terms of Use</a> <a href="#">Privacy Policy</a> </p>
                </div>
                <div className={`${style.socialLinks} d-flex `}>
                    <img src={Youtube} alt="" />
                    <img src={Pinterest} alt="" />
                    <img src={Facebook} alt="" />
                    <img src={Instagram} alt="" />
                    <img src={Twitter} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Footer
