import React, { useContext, useState } from 'react'
import SearchInput from '../../../../ReusableUi/SearchInput';
import IndexSession from '../../../../modules/AtheleteIndex/IndexSession/IndexSession';
import { SessionContext } from '../../../../../context/sessionContext';
import { Col } from 'react-bootstrap';

const SessionIndex = () => {
  const {activeBtn, setActivebtn} = useContext(SessionContext)
  return (
    <>
    <Col md={12} sm={12} lg={12}>    
      <div className="businessHeaderbuttons flex justify-content-center m-auto mb-4">
        <div id={activeBtn === "oneToOne" && "activeButton"} style={{ width: "50%" }}>
          <button
            variant="text"
            onClick={() => setActivebtn("oneToOne")}
          >
            <span>
              One To One
            </span>
          </button>
        </div>
        <div style={{ width: "50%" }} id={activeBtn === "group" && "activeButton"}>
          <button

            onClick={() => setActivebtn("group")}
          >
            <span>
              Group
            </span>
          </button>
        </div>
      </div>
      <SearchInput />
      <IndexSession activeBtn={activeBtn}/>
    </Col>
    </>
  )
}

export default SessionIndex