// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_superset_link {
    position: absolute;
    top: 12px;
    left: -5px;
    bottom: -80px;
    width: 40px;
    
    cursor: pointer;
    z-index: 10;
}

.line {
    position: absolute;
    border-left: 2px dotted #2c2c2e;
    top: 125px;
    left: 49px;
    bottom: 0px;
    opacity: .3;
    transition: all .2s ease-in;
}
.line.solid {
    border-left: 2px solid #2c2c2e;
    opacity: 1;
}
.block_superset_link .line i {
    position: relative;
    top: 50%;
    left: -9px;
    font-size: 15px;
    transform: translateY(-50%);
    background: #fff;
}


.mdi_link_variant:before {
    content: "\\F339";
}
.mdi-set, .mdi:before {
    display: inline-block;
    font: normal normal normal 24px / 1 Material Design Icons;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}
.blocktitle{
    display: flex;
    align-items: center;
    
}`, "",{"version":3,"sources":["webpack://./src/components/Common/Form/GroupSessionForm/CreateGroupSession/GroupSessionBlock/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,aAAa;IACb,WAAW;;IAEX,eAAe;IACf,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,UAAU;IACV,UAAU;IACV,WAAW;IACX,WAAW;IAGX,2BAA2B;AAC/B;AACA;IACI,8BAA8B;IAC9B,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,eAAe;IAGf,2BAA2B;IAC3B,gBAAgB;AACpB;;;AAGA;IACI,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,yDAAyD;IACzD,kBAAkB;IAClB,oBAAoB;IACpB,mCAAmC;IACnC,kCAAkC;IAGlC,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;;AAEvB","sourcesContent":[".block_superset_link {\r\n    position: absolute;\r\n    top: 12px;\r\n    left: -5px;\r\n    bottom: -80px;\r\n    width: 40px;\r\n    \r\n    cursor: pointer;\r\n    z-index: 10;\r\n}\r\n\r\n.line {\r\n    position: absolute;\r\n    border-left: 2px dotted #2c2c2e;\r\n    top: 125px;\r\n    left: 49px;\r\n    bottom: 0px;\r\n    opacity: .3;\r\n    -webkit-transition: all .2s ease-in;\r\n    -o-transition: all .2s ease-in;\r\n    transition: all .2s ease-in;\r\n}\r\n.line.solid {\r\n    border-left: 2px solid #2c2c2e;\r\n    opacity: 1;\r\n}\r\n.block_superset_link .line i {\r\n    position: relative;\r\n    top: 50%;\r\n    left: -9px;\r\n    font-size: 15px;\r\n    -webkit-transform: translateY(-50%);\r\n    -ms-transform: translateY(-50%);\r\n    transform: translateY(-50%);\r\n    background: #fff;\r\n}\r\n\r\n\r\n.mdi_link_variant:before {\r\n    content: \"\\F339\";\r\n}\r\n.mdi-set, .mdi:before {\r\n    display: inline-block;\r\n    font: normal normal normal 24px / 1 Material Design Icons;\r\n    font-size: inherit;\r\n    text-rendering: auto;\r\n    -webkit-font-smoothing: antialiased;\r\n    -moz-osx-font-smoothing: grayscale;\r\n    -webkit-transform: translate(0);\r\n    -ms-transform: translate(0);\r\n    transform: translate(0);\r\n}\r\n.blocktitle{\r\n    display: flex;\r\n    align-items: center;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
