import React, { useState } from 'react';
import SessionForm from '../forms/sessionForm/sessionForm';
import './leaderBoardModal.css'
import Avtr from '../../assessts/images/avtr.png'
import LeaderBoardForm from '../forms/sessionForm/leaderBoardForm';
const LeaderBoardModal = ({ isOpen, onClose, leaderBoardData, onLeaderBoardDataChange }) => {

  return (
    <div className={`invite-modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modalBody">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <div className='leaderHeaders'>
       <h4>Link Daily Leatherboard</h4>
       <h4>Conditioning</h4>
       </div>
       <LeaderBoardForm onLeaderBoardDataChange={onLeaderBoardDataChange} leaderBoardData={leaderBoardData} />
      </div>
    </div>
  );
};

export default LeaderBoardModal;
