import React, { useState } from "react";
import "./business.css";
import BusinessTable from "./businessTable/businessTable";


const Business = () => {



  return (
    <div className="businessContainer" style={{ height: "85vh", overflowY: "scroll",marginLeft : "12px" ,}}>
      <div
        className={"athbody"}
        style={{
          padding: "2rem",
        }}
      >
        <BusinessTable />

      </div>
    </div>
  );
};

export default Business;
