import React, { useContext } from 'react'
import { SessionContext } from '../../../../context/sessionContext'
import { Button, Col, Container, Row, Form } from 'react-bootstrap'
import styled from 'styled-components'
import styles from "./anlyatic.module.css"

const SetDiv = styled.div`
display: flex;
margin-top: 3vh;
padding: 0px;
gap: 10px;
flex-shrink: 0;
`;
const AnalyticModal = ({ data , isOpen, onClose}) => {

     // Function to convert numbers to letters (A, B, C, ...)
     const numberToLetter = (number) => {
        return String.fromCharCode(65 + number);
    };
    
    return (
        <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`}>
            <div className="exercise-overlay" onClick={onClose}></div>
            <div className="exercisemmodalBody" style={{ width: "30vw", padding: "20px" }}>
                {data?.AthBlckAthSession?.map((blockData, index) => {
                    return (
                        <>
                            <div className='d-flex align-items-center'>
                                <div className="cirlce">{numberToLetter(index)}</div>
                                <div className='px-2'>
                                    <p id="as" className={styles.analyticsModalSessionTitle}>{blockData?.athltExercises?.title}</p>
                                </div>
                            </div>
                            <Container>
                                <Row className='mb-3'>
                                    <SetDiv>
                                        <div className={styles.setsDiv}>
                                            <span>Sets</span>
                                        </div>
                                        <div className={styles.setsDiv}>
                                            <span>Reps</span>
                                        </div>
                                        <div className={styles.setsDiv}>
                                            <span>Weight</span>
                                        </div>
                                    </SetDiv>
                                    {blockData?.AthltBlockSets?.map((data, index) => {
                                        return(

                                    <div className={styles.setsInputDiv} style={{ padding: "0px" }} >
                                        <div className={styles.sessionValueInput}><span>01</span></div>
                                        <div className={styles.sessionValueInput}><span>{data?.value1}</span></div>
                                        <div className={styles.sessionValueInput}><span>{data?.value2}</span></div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 25" fill="none">
                                                <path opacity="0.4" d="M16.6896 2.16406H8.01961C4.62961 2.16406 2.34961 4.54406 2.34961 8.08406V16.2541C2.34961 19.7841 4.62961 22.1641 8.01961 22.1641H16.6896C20.0796 22.1641 22.3496 19.7841 22.3496 16.2541V8.08406C22.3496 4.54406 20.0796 2.16406 16.6896 2.16406Z" fill="#1AA053" />
                                                <path d="M11.1626 15.4125C10.9386 15.4125 10.7146 15.3275 10.5436 15.1565L8.17056 12.7835C7.82856 12.4415 7.82856 11.8875 8.17056 11.5465C8.51256 11.2045 9.06556 11.2035 9.40756 11.5455L11.1626 13.3005L15.2906 9.17252C15.6326 8.83052 16.1856 8.83052 16.5276 9.17252C16.8696 9.51452 16.8696 10.0685 16.5276 10.4105L11.7816 15.1565C11.6106 15.3275 11.3866 15.4125 11.1626 15.4125Z" fill="#1AA053" />
                                            </svg>
                                        </div>

                                    </div>
                                        )
                                    })}
                      
                                    <span className={styles.analyticModalCommentsHeading}>Comments</span>
                                    <Col>
                                        <div className={styles.commentsDescription}>
                                            <span>{blockData?.athltExercises?.instructions}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )
                })}


            </div>
        </div>
    )
}

export default AnalyticModal