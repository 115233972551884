import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import "./businessTable.css";
import {
  checkCoachConnect,
  checkCoachSubscription,
} from "../../../../../src/services/store/coachApi";
import { Col, Row, Toast } from "react-bootstrap";
import { auth } from "../../../../firebase";
import {
  coachSubscription,
  coachSubscritpion,
  getCustomerPortal,
} from "../../../../services/store/coachApi";
import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../loadingSpinner";
import useCheckCoachSub from "../../../../hooks/useCheckCoachSub";
import Loader from "../../../ReusableUi/Loader";
import WhiteLoader from "../../../Loader/WhiteLoader";

const stripePromise = loadStripe(
  "pk_test_51NXcWqKpYizIWrOobsNz3syBnITmQuKTbCLUALIYfcpzSgg56xIv4q6pmV8kpMM5TYRiGePtekovSchdbzikjODf00P2vpl6bc"
);

const BusinessTable = () => {
  const [authReady, setAuthReady] = useState(false);
  const [connect, setConnect] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const { subscriptionPlan, loading, error } = useCheckCoachSub(
    checkCoachSubscription
  );
  console.log(subscriptionPlan);
  const [isLoadingFirst, setIsLoadingFirst] = useState(false);
  const [isLoadingSecond, setIsLoadingSecond] = useState(false);
  const [isLoadingThird, setIsLoadingThird] = useState(false);
  const [isSelected, setIsSelected] = useState(
    localStorage.getItem("isSelected") === "true"
  );
  const [isSelectedPlantinum, setIsSelectedPlantinum] = useState(
    localStorage.getItem("isSelectedPlantinum") === "true"
  );
  const [isSelectedStandard, setIsSelectedStandard] = useState(
    localStorage.getItem("isSelectedStandard") === "true"
  );
  const handleCreateConnectAccount = async () => {
    try {
      setLoader(true);
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/api/v1/coach/connectAccountLink`,
        {
          headers: { Authorization: token },
        }
      );
      const redirectUrl = response?.data.link;
      if (redirectUrl) {
        // Open the URL in a new tab
        window.open(redirectUrl, "_blank");
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.response.data.error);
      console.error("Error connecting account:", error);
    }
  };

  const handlecheckCoachConnect = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${checkCoachConnect}`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(response?.data, "connection");
      const value = response?.data?.hasConnectId;

      setConnect(value);
    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.error("Error connecting account:", error);
    }
  };

  const BuyerSubScription = async (productType) => {
    if (authReady && productType) {
      try {
        if (productType === "growth") {
          setIsLoadingFirst(true);
        } else if (productType === "elite") {
          setIsLoadingSecond(true);
        } else if (productType === "professional") {
          setIsLoadingThird(true);
        }

        const token = await auth.currentUser.getIdToken(true);
        const email = await auth.currentUser.email;
        const endpoint = coachSubscritpion;
        const payload = { email, productType };
        await makeApiCall(endpoint, payload, token);
        setIsLoadingFirst(false);
        setIsLoadingSecond(false);
        setIsLoadingThird(false);
      } catch (error) {
        Toast.err(error);
        setIsLoadingFirst(false);
        setIsLoadingSecond(false);
        setIsLoadingThird(false);
      }
    }
  };
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user);
      handlecheckCoachConnect();
    });

    return () => unregisterAuthObserver();
  }, []);

  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${coachSubscription}`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(url, payload, config);
      const { sessionId } = response.data;
      const stripe = await stripePromise;

      const session = await stripe.redirectToCheckout({ sessionId: sessionId });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const handleUpdateSubscription = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);

      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getCustomerPortal}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const redirectUrl = response.data.url;
      if (redirectUrl) {
        // Open the URL in a new tab
        window.open(redirectUrl, "_blank");
      }
    } catch (error) {
      console.error("Error fetching Customer Portal URL:", error);
      // Handle error
    }
  };

  useEffect(() => {
    localStorage.setItem("isSelected", isSelected);
    localStorage.setItem("isSelectedPlantinum", isSelectedPlantinum);
    localStorage.setItem("isSelectedStandard", isSelectedStandard);
  }, [isSelected, isSelectedPlantinum, isSelectedStandard]);

  return (
    <>
      <div className="businessHeader">
        <div className="businessHeaderTexts">
          <h4>Pricing plans that suit you</h4>
        </div>
      </div>
      {loading ? (
        <>
          <div className="d-flex justify-content-center align-items-center h-100">
            <Loader />
          </div>
        </>
      ) : (
        <>
          <Row>
            <Col
              lg="12"
              md="12"
              className="text-center gap-5 w-100 d-flex justify-content-center"
            >
              <>
                <button
                  className="subsPlansButton"
                  onClick={() => handleCreateConnectAccount()}
                  disabled={connect}
                  style={{
                    color: connect ? "black" : "white",
                    cursor: connect ? "not-allowed" : "pointer",
                    opacity: connect ? 0.5 : 1,
                  }}
                >
                  {isLoader ? (
                    <LoadingSpinner size="sm" />
                  ) : connect ? (
                    "Stripe Payments Onboarding Done"
                  ) : (
                    "Setup Stripe Payments Onboarding"
                  )}
                </button>
                {subscriptionPlan?.hasActiveSubscriptions === true ? (
                  <>
                    <button
                      className="subsPlansButton"
                      style={{ background: "#000" }}
                      onClick={handleUpdateSubscription}
                    >
                      Update Subscription
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </>
            </Col>
          </Row>
          <div className="businesscardrow">
            <Col lg="4" md="4" sm="12">
              <div
                className="businesscard"
                style={{
                  backgroundColor:
                    subscriptionPlan?.subscription?.plan?.name === "growth"
                      ? "#F6F6F0"
                      : "inherit",
                  border:
                    subscriptionPlan?.subscription?.plan?.name === "growth"
                      ? "4px solid #A19686"
                      : "1px solid #A19686",
                }}
              >
                <div
                  id="regularPlan"
                  className=""
                  style={{ position: "relative", height: "100%" }}
                >
                  <div className="planHeader mb-4">
                    <div className="regularIcon">
                      <svg
                        id="businesscardicon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54 55"
                        fill="none"
                      >
                        <circle cx="27" cy="27.958" r="27" fill="#E6E6E6" />
                        <rect
                          x="16"
                          y="27.958"
                          width="9"
                          height="13"
                          rx="4.5"
                          fill="#1D2130"
                        />
                        <rect
                          x="16"
                          y="16.958"
                          width="20"
                          height="9"
                          rx="4.5"
                          fill="#1D2130"
                        />
                        <rect
                          x="27"
                          y="27.958"
                          width="9"
                          height="13"
                          rx="4.5"
                          fill="#1D2130"
                        />
                      </svg>
                    </div>
                    <div>
                      <h4 id="asf">Growth</h4>
                      <p id="as"> Starter Plan</p>
                    </div>
                  </div>
                  <div className="planBenifits">
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "3px" }}>
                          $9.99/month Designed for coaches starting of
                        </p>
                        {/* <p>Limited Projects</p> */}
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Manage up to 10 clients seamlessly.
                        </p>
                        {/* <p>Regular Support Business</p> */}
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Unlimited program creation
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Unlimited program creation
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Payment integration: streamline client billing and
                          monetise your programs effortlessly. 
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                        Collaborative coaching: Add additional coaches for just $6.99/month
                          per coach. Grow your team and diversify expertise.
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Access analytics and client progress tracking.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="businessbutton"
                    style={{
                      position: "absolute",
                      bottom: "40px",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div className="planStatus">
                      <h4 id="asf">$9.99</h4>
                      <p id="asf">Billing Monthly</p>
                      {subscriptionPlan?.subscription?.plan?.name ? (
                        <></>
                      ) : (
                        <>
                          <button onClick={() => BuyerSubScription("growth")}>
                            {isLoadingFirst ? (
                              <WhiteLoader  />
                            ) : (
                              "Buy Now"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12 ">
              <div
                className="businesscard"
                style={{
                  backgroundColor:
                    subscriptionPlan?.subscription?.plan?.name === "elite"
                      ? "#F6F6F0"
                      : "inherit",
                  border:
                    subscriptionPlan?.subscription?.plan?.name === "elite"
                      ? "4px solid #A19686"
                      : "1px solid #A19686",
                }}
              >
                <div
                  id="plantinumPlan"
                  className=""
                  style={{ position: "relative", height: "100%" }}
                >
                  <div className="planHeader mb-4">
                    <div className="regularIcon">
                      <svg
                        id="businesscardicon2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 55"
                        fill="none"
                      >
                        <ellipse
                          cx="23.6571"
                          cy="27.958"
                          rx="23.6571"
                          ry="27"
                          fill="white"
                          fill-opacity="0.38"
                        />
                        <rect
                          x="13.1426"
                          y="38.958"
                          width="9"
                          height="13.1429"
                          rx="4.5"
                          transform="rotate(-90 13.1426 38.958)"
                          fill="#6B7CFF"
                        />
                        <rect
                          x="13.1426"
                          y="27.958"
                          width="9"
                          height="7.88571"
                          rx="3.94286"
                          transform="rotate(-90 13.1426 27.958)"
                          fill="#6B7CFF"
                        />
                        <rect
                          x="22.7812"
                          y="27.958"
                          width="9"
                          height="11.3905"
                          rx="4.5"
                          transform="rotate(-90 22.7812 27.958)"
                          fill="#6B7CFF"
                        />
                        <rect
                          x="27.1621"
                          y="38.958"
                          width="9"
                          height="7.00952"
                          rx="3.50476"
                          transform="rotate(-90 27.1621 38.958)"
                          fill="#6B7CFF"
                        />
                      </svg>
                    </div>
                    <div className="platHdr">
                      <h4 id="asf">Elite</h4>

                      <p id="as">For the best results</p>
                    </div>
                  </div>
                  <div className="planBenifits">
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Tailored for established coaches or fitness
                          businesses.
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Unlimited clients: scale without restrictions.
                        </p>
                        {/* <p>Regular Support Business</p> */}
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Unlimited program creation to cater to diverse client
                          needs.
                        </p>
                        {/* <p>1 month Free Trial</p> */}
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Payment integration: streamline client billing and
                          monetise your programs effortlessly. 
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                        Collaborative coaching: Add additional coaches for just $6.99/month
                          per coach. Grow your team and diversify expertise.
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Access analytics and client progress tracking
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="businessbutton2"
                    style={{
                      position: "absolute",
                      bottom: "40px",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div id="plSt" className="planStatus">
                      <h4 id="asf">$79.99 </h4>
                      <p id="asf" className="pDark">
                        Billing Monthly
                      </p>
                      {subscriptionPlan?.subscription?.plan?.name ? (
                        <></>
                      ) : (
                        <>
                          <button onClick={() => BuyerSubScription("elite")}>
                            {isLoadingSecond ? (
                              <WhiteLoader />
                            ) : (
                              "Buy Now"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div
                className="businesscard"
                style={{
                  backgroundColor:
                    subscriptionPlan?.subscription?.plan?.name ===
                    "professional"
                      ? "#F6F6F0"
                      : "#inherit",
                  border:
                    subscriptionPlan?.subscription?.plan?.name ===
                      "professional" ||
                    subscriptionPlan?.Subscriptions === false
                      ? "4px solid #A19686"
                      : "1px solid #A19686",
                }}
              >
                <div
                  className=""
                  style={{ position: "relative", height: "100%" }}
                >
                  <div
                    className="w-100 justify-content-center d-flex position-absolute"
                    style={{ top: "-45px" }}
                  >
                    <p
                      className="rounded-pill d-flex justify-content-center align-items-center"
                      style={{
                        background: "#A19686",
                        width: "100px",
                        height: "29px",
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Best Value
                    </p>
                  </div>
                  <div className="planHeader mb-4">
                    <div>
                      <svg
                        id="businesscardicon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 54 55"
                        fill="none"
                      >
                        <circle cx="27" cy="27.958" r="27" fill="#E6E6E6" />
                        <rect
                          x="16"
                          y="28.958"
                          width="9"
                          height="9"
                          rx="4.5"
                          fill="#1D2130"
                        />
                        <rect
                          x="15"
                          y="17.958"
                          width="9"
                          height="9"
                          rx="4.5"
                          fill="#1D2130"
                        />
                        <rect
                          x="27"
                          y="17.958"
                          width="9"
                          height="20"
                          rx="4.5"
                          fill="#1D2130"
                        />
                      </svg>
                    </div>
                    <div>
                      <h4 id="as">Professional</h4>

                      <p id="asf">Most popular</p>
                    </div>
                  </div>
                  <div className="planBenifits">
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Tailored for established coaches or fitness
                          businesses.
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Unlimited clients: scale without restrictions.
                        </p>
                        {/* <p>Regular Support Business</p> */}
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Unlimited program creation to cater to diverse client
                          needs.
                        </p>
                        {/* <p>1 month Free Trial</p> */}
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Payment integration: streamline client billing and
                          monetise your programs effortlessly. 
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                        Collaborative coaching: Add additional coaches for just $6.99/month
                          per coach. Grow your team and diversify expertise.
                        </p>
                      </div>
                    </div>
                    <div className="benifit">
                      <div>
                        <svg
                          id="businesstexticon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <circle cx="12" cy="12.958" r="12" fill="#D5F5EA" />
                          <path
                            d="M7 13.458L10.6667 16.958L18 9.95801"
                            stroke="#1D2130"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p style={{ marginTop: "2px" }}>
                          Access analytics and client progress tracking
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="businessbutton3"
                    style={{
                      position: "absolute",
                      bottom: "40px",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div className="planStatus">
                      <h4 id="asf">$39.99</h4>
                      <p id="asf">Billing Monthly</p>
                      {subscriptionPlan?.subscription?.plan?.name ? (
                        <></>
                      ) : (
                        <>
                          <button
                            onClick={() => BuyerSubScription("professional")}
                          >
                            {isLoadingThird ? (
                              <WhiteLoader  />
                            ) : (
                              "Buy Now"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </>
      )}
    </>
  );
};

export default BusinessTable;
