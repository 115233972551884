import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { auth } from "../../firebase";
import io from "socket.io-client";
import StyledMessageInput from "./messageInput";
import { ThemeContext } from "../../context/themeContext";
import { getChatById, getGroupChatById } from "../../apis";
import axios from "axios";
import "./chats.css";
import Avt from "../../assessts/images/avtr.png";
import Messages from "./Messages";
import Loader from "../ReusableUi/Loader";

import MessageSkeletonContainer from "./MessageSkeletonContainer";
const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1rem 1rem;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--white-100, #fff);
`;

const Chats = ({ user, isGroupChat, isAthlete, chatUsers, refresh }) => {
  const {
    typerId,
    setTyperId,
    setIsTyping,
    isTyping,
    activeChat,
    setIsChatOpen,
    ActiveChatId,
    ActiveChatGroupId,
  } = useContext(ThemeContext);
  const [messages, setMessages] = useState([]);
  const chatContainerRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const socket = useRef();
  const [SendingImage, SetSendingImage] = useState();
  const imageInputRef = useRef(null);
  const [isRecording, setIsRecording] = useState();
  const [recordedAudio, setRecordedAudio] = useState();
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_Baseurl, {
      transports: ["websocket"],
    });

    socket.current.on("connect", () => {
      console.log("Socket connected:", socket.current.id);
      handleSocketEvents();
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);

  const handleSocketEvents = () => {
    if (!socket.current) return;
    socket.current.on("private_message", (data) => handleIncomingMessage(data));
    socket.current.on("send_image", (data) => handleIncomingMessage(data));
    socket.current.on("send_audio", (data) => handleIncomingMessage(data));
    socket.current.on("group_message", (data) => handleIncomingMessage(data));
  };

  const handleIncomingMessage = (data) => {
    console.log("Received message:", data);
    if (
      data?.receiverId === auth.currentUser.uid ||
      data?.senderId === auth.currentUser.uid
    ) {
      // setMessages((prevMessages) => [...prevMessages, data]);
      scrollToBottom();
    }
  };

  useEffect(() => {
    const getChat = async () => {
      const senderId = auth?.currentUser?.uid;
      setIsLoader(true);
      try {
        const groupId = ActiveChatGroupId;
        let fetchedMesgs;
        if (isGroupChat) {
          fetchedMesgs = await axios.get(
            `${process.env.REACT_APP_Baseurl}${getGroupChatById}${groupId}`
          );
          setMessages(fetchedMesgs?.data);
          setIsLoader(false);
        } else {
          const recipientId = ActiveChatId;
          fetchedMesgs = await axios.get(
            `${process.env.REACT_APP_Baseurl}${getChatById}${senderId}/${recipientId}`
          );
          setMessages(fetchedMesgs?.data?.chats);
          setIsLoader(false);
        }
        scrollToBottom();
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
        console.error("Error fetching chat:", err);
      }
    };

    getChat();
  }, [chatUsers, activeChat, refresh]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const handleSendMessage = (newMessage) => {
    if (newMessage) {
      const messageText = newMessage[0]?.message?.trim();

      if (messageText && selectedImage) {
        const reader = new FileReader();

        reader.onload = () => {
          const arrayBuffer = reader.result;
          const imageBuffer = new Uint8Array(arrayBuffer);

          const messageData = {
            recipientId: ActiveChatGroupId,
            senderId: auth?.currentUser?.uid,
            imageCaption: messageText,
            imageSrc: imageBuffer,
            audioMsg: null,
          };
          const GroupMessageData = {
            groupId: ActiveChatGroupId,
            senderId: auth?.currentUser?.uid,
            imageCaption: messageText,
            imageSrc: imageBuffer,
          };
          if (socket.current) {
            socket.current.emit(
              isGroupChat ? "send_group_image" : "send_image",
              isGroupChat ? GroupMessageData : messageData
            );
            if (isGroupChat) {
              setMessages((prevMessages) => [
                ...prevMessages,
                GroupMessageData,
              ]);
            } else {
              setMessages((prevMessages) => [...prevMessages, messageData]);
            }
          } else {
            console.error("Socket is not connected.");
          }
          setSelectedImage(null);
        };

        // Read the selected image as an ArrayBuffer
        reader.readAsArrayBuffer(selectedImage);
      } else if (messageText !== "") {
        const messageData = {
          recipientId: ActiveChatGroupId,
          senderId: auth?.currentUser?.uid,
          message: messageText,
          imageSrc: null,
          audioMsg: null,
        };
        const GroupMessageData = {
          groupId: ActiveChatGroupId,
          senderId: auth?.currentUser?.uid,
          message: messageText,
        };
        if (socket.current) {
          socket.current.emit(
            isGroupChat ? "group_message" : "private_message",
            isGroupChat ? GroupMessageData : messageData
          );
          setMessages((prevMessages) => [...prevMessages, messageData]);
        } else {
          console.error("Socket is not connected.");
        }
      }
    } else if (recordedAudio) {
      const messageData = {
        recipientId: ActiveChatGroupId,
        senderId: auth?.currentUser?.uid,
        message: null,
        audioMsg: recordedAudio,
        imageSrc: null,
      };
      const GroupMessageData = {
        groupId: ActiveChatGroupId,
        senderId: auth?.currentUser?.uid,
        audioMsg: recordedAudio,
      };
      if (socket.current) {
        socket.current.emit(
          isGroupChat ? "send_group_audio" : "send_audio",
          isGroupChat ? GroupMessageData : messageData
        );
        if (isGroupChat) {
          setMessages((prevMessages) => [...prevMessages, GroupMessageData]);
        } else {
          setMessages((prevMessages) => [...prevMessages, messageData]);
        }
      } else {
        console.error("Socket is not connected.");
      }
    }
  };

  const handleImageSelection = (event) => {
    const file = event.target.files[0];
    console.log(file,"file")
    setSelectedImage(file);
  };
  return (
    <StyledDiv>
      {isLoader ? (
         <div
         style={{
           width: "100%",
           height: "100%",
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
         }}
       >
      <>
     <MessageSkeletonContainer />
      </>
      </div> 
      ) : (
        <>
          {activeChat ? (
            <>
              <div
                ref={chatContainerRef}
                className="message-list"
                style={{ height: isAthlete ? "67vh" : "320px", width: "100%" }}
              >
                <Messages messages={messages} auth={auth} Avt={Avt} />
              </div>
              <StyledMessageInput
                recordedAudio={recordedAudio}
                setRecordedAudio={setRecordedAudio}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                typerId={typerId}
                setTyperId={setTyperId}
                setIsTyping={setIsTyping}
                isTyping={isTyping}
                user={user}
                onSendMessage={handleSendMessage}
                handleImageSelection={handleImageSelection}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                imageInputRef={imageInputRef}
              />
            </>
          ) : (
            <div style={{ textAlign: "center", width: "100%" }}>
              <h4>Chat is not selected</h4>
            </div>
          )}
        </>
      )}
    </StyledDiv>
  );
};

export default Chats;
