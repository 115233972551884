import React, { useContext, useState } from "react";
import "./createExerciseModal.css";
import { SessionContext } from "../../context/sessionContext";
import styled from "styled-components";
import styles from "./SingleExercise/resttime.module.css";
import { auth } from "../../firebase";
import {
  atheleteFinishSession,
  finishGroupSession,
  finishSession,
} from "../../apis";
import axios from "axios";
import { toast } from "react-toastify";
const HeaderSection = styled.div`
  background: white;
  margin-bottom: 24px;
  & > div:first-child {
    display: flex;
    justify-content: end;
    padding: 10px 10px 0px 0px;
    & > div {
      width: 33.3px;
      cursor: pointer;
      display: flex;
      height: 33.3px;
      transform: rotate(-90deg);
      flex-shrink: 0;
      border-radius: 75.967px;
      background: rgba(38, 38, 38, 0.5);
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
      }
    }
  }
  & > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    & > h2 {
      color: var(--neutral-color-black, #0a1633);
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      font-family: Hurme Geometric Sans 2;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 150% */
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }
  & div:nth-child(3) {
    color: var(--neutral-color-charcol, #63676f);
    text-align: center;
    font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
    font-family: Hurme Geometric Sans 2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  & > div:nth-child(4) {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 5px;
    margin: auto 130px;
    margin-top: 20px;
    justify-content: space-evenly;
    padding-bottom: 40px;
    & > div {
      width: 42.502px;
      height: 42.502px;
      flex-shrink: 0;
      border-radius: 75.967px;
      background: #f1f5f9;
      & > span {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        color: var(--black-100, #1c1c1c);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
      }
    }
  }
`;

const Section = styled.div`
  background: white;
`;
const InputDiv = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  padding-bottom: 20px;
`;

const Footer = styled.footer`
  display: grid;
  gap: 10px;
  & > div:first-child {
    border-radius: 8px;
    background: var(--secondary-purple-a, #a19686);
    border-radius: 8px;
    background: var(--secondary-purple-a, #a19686);
    display: flex;
    width: 388px;
    height: 46px;
    padding: 12px 37px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    & > span {
      color: var(--primary-light, var(--white-100, #fff));
      text-align: center;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      /* 18 Semibold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
  & > div:last-child {
    color: var(--secondary-purple-a, #a19686);
    text-align: center;
    font-feature-settings: "cv11" on, "cv01" on, "ss01" on;

    /* 18 Semibold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
`;
const IntensityModal = (props) => {
  const {
    isSelectedDate,
    group,
    groupsessionid,
    weight,
    PerformBlock,
    setSessionComplete,
    globalTotalWeight,
    getCoachId,
    getSessionId,
    sessionTime,
    checking,
    getAtheleteSessionExercise,
    AtheleteExercise,
  } = props;
  const { handleOpenPostFeed } = useContext(SessionContext);
  const [selectedIntensity, setSelectedIntensity] = useState("");
 console.log(group)
  const convertSecondsToTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };
  const Time = convertSecondsToTime(sessionTime);

  const handleSelectIntensity = (intensity) => {
    setSelectedIntensity(intensity);
  };

  const intensityClass = (intensity) => {
    return selectedIntensity === intensity
      ? `${styles.intensityChecked} ${styles.intensityCheckedActive}`
      : styles.intensityChecked;
  };
  console.log("getSessionId", getSessionId);

  const [formData, setFormData] = useState({
    sessionId: getSessionId,
    totalBlocks: PerformBlock,
    totalWeight: weight,
    imageOrVideoUrl:
      "https://s3.amazonaws.com/your-bucket-name/path-to-file.jpg",
    coachId: getCoachId,
  });

  const { isEndTraningSession, setEndTraningSession } =
    useContext(SessionContext);

  const handleSessionTimeChange = (e) => {
    const value = e.target.value;

    // Ensure that only numbers can be inputted
    // if (!isNaN(value) && value >= 0) {
    //     setInputTime(value);
    // }
  };
  //Function To Hit Api
  const handleFinishSession = async () => {
  try {
    const token = await auth.currentUser.getIdToken(true);

    const updatedFormData = {
      ...formData,
      intensity: selectedIntensity,
      totalDuration: Time,

    };

    const path = group ? finishGroupSession : atheleteFinishSession;
    
    if(group){
        await makeApiCall2(path, updatedFormData, token);
    }else{
        await makeApiCall(path, updatedFormData, token);
    }

    toast.success("Congratulations! You Have Successfully Completed The Session.");


    handleOpenPostFeed();
  } catch (error) {
    toast.error("An error occurred while saving the program");
    console.error(error);
  }
};


  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token },
    };
    const response = await axios.patch(url, payload, config);
  };
  const makeApiCall2 = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token },
    };
    const response = await axios.patch(url, payload, config);
  };
  const Cross = () => {
    setSessionComplete(false);
    setEndTraningSession(false);
  };
  return (
    <div
      className={`exercise-modal ${
        isEndTraningSession ? "exercise-open w-100" : ""
      }`}
    >
      <div
        className="exercise-overlay"
        onClick={() => setEndTraningSession(false)}
      ></div>
      <div
        className="exercisemmodalBody"
        style={{
          background: "#F8F8F8",
          gap: "24px",
          padding: "24px",
          width: "29%",
        }}
      >
        <HeaderSection>
          <div>
            <div onClick={Cross}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M4.85693 2.43839L8.97426 6.55572L13.0703 2.45972C13.1607 2.36342 13.2697 2.28638 13.3907 2.23323C13.5117 2.18008 13.6421 2.1519 13.7743 2.15039C14.0572 2.15039 14.3285 2.26277 14.5285 2.46281C14.7285 2.66285 14.8409 2.93416 14.8409 3.21706C14.8434 3.34783 14.8192 3.47774 14.7696 3.5988C14.7201 3.71986 14.6464 3.82952 14.5529 3.92106L10.4036 8.01706L14.5529 12.1664C14.7287 12.3384 14.8318 12.5713 14.8409 12.8171C14.8409 13.1 14.7285 13.3713 14.5285 13.5713C14.3285 13.7713 14.0572 13.8837 13.7743 13.8837C13.6383 13.8894 13.5027 13.8667 13.376 13.8171C13.2493 13.7675 13.1343 13.6921 13.0383 13.5957L8.97426 9.47839L4.8676 13.5851C4.77747 13.6781 4.6698 13.7525 4.5508 13.8037C4.4318 13.855 4.30382 13.8822 4.17426 13.8837C3.89136 13.8837 3.62005 13.7713 3.42001 13.5713C3.21998 13.3713 3.1076 13.1 3.1076 12.8171C3.10511 12.6863 3.12937 12.5564 3.1789 12.4353C3.22842 12.3143 3.30216 12.2046 3.3956 12.1131L7.54493 8.01706L3.3956 3.86772C3.21979 3.69573 3.1167 3.46283 3.1076 3.21706C3.1076 2.93416 3.21998 2.66285 3.42001 2.46281C3.62005 2.26277 3.89136 2.15039 4.17426 2.15039C4.43026 2.15359 4.6756 2.25706 4.85693 2.43839Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div>
            <h2>Intensity</h2>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_3363_26900)">
                  <rect
                    width="15.3699"
                    height="15.3699"
                    transform="translate(0.296875 0.296875)"
                    fill="white"
                  />
                  <path
                    d="M7.6172 8.58139L9.57526 7.3495L8.3426 9.30879C8.3102 9.35191 8.27158 9.39 8.22799 9.42182C8.11623 9.49883 7.97873 9.52907 7.84491 9.506C7.71008 9.48276 7.58986 9.40728 7.51034 9.29595C7.43082 9.18462 7.39841 9.04642 7.42015 8.91134C7.44128 8.78008 7.51188 8.66206 7.6172 8.58139Z"
                    fill="white"
                    stroke="#1C1C1C"
                    stroke-width="0.768494"
                  />
                  <path
                    d="M7.98214 2.21875C4.26875 2.21875 1.25782 5.22668 1.25782 8.94007C1.25554 10.583 1.85702 12.1695 2.94791 13.3979C2.98093 13.434 3.01095 13.47 3.04397 13.503C3.115 13.5799 3.20124 13.6412 3.29721 13.6831C3.39318 13.7249 3.49679 13.7464 3.60148 13.7461C3.70618 13.7458 3.80967 13.7238 3.90542 13.6814C4.00116 13.6391 4.08706 13.5773 4.15768 13.5C4.64445 12.971 5.23566 12.5488 5.89394 12.2599C6.55223 11.9711 7.26328 11.822 7.98214 11.822C8.701 11.822 9.41205 11.9711 10.0703 12.2599C10.7286 12.5488 11.3198 12.971 11.8066 13.5C11.8772 13.5773 11.9631 13.6391 12.0589 13.6814C12.1546 13.7238 12.2581 13.7458 12.3628 13.7461C12.4675 13.7464 12.5711 13.7249 12.6671 13.6831C12.763 13.6412 12.8493 13.5799 12.9203 13.503L13.0164 13.3979C14.1073 12.1695 14.7087 10.583 14.7065 8.94007C14.7065 5.22668 11.6955 2.21875 7.98214 2.21875Z"
                    stroke="#1C1C1C"
                    stroke-width="1.15274"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.98242 4.13867V5.09929"
                    stroke="#1C1C1C"
                    stroke-width="1.53699"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M12.7848 8.94336H11.8242"
                    stroke="#1C1C1C"
                    stroke-width="1.53699"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.14031 8.94336H3.17969"
                    stroke="#1C1C1C"
                    stroke-width="1.53699"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M5.26527 6.22426L4.58594 5.54492"
                    stroke="#1C1C1C"
                    stroke-width="1.53699"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M10.6992 6.22426L11.3786 5.54492"
                    stroke="#1C1C1C"
                    stroke-width="1.53699"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3363_26900">
                    <rect
                      width="15.3699"
                      height="15.3699"
                      fill="white"
                      transform="translate(0.296875 0.296875)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div>
            <span>How did this session Feel?</span>
          </div>
          <div>
            {[1, 2, 3, 4, 5].map((intensity) => (
              <div
                key={intensity}
                className={intensityClass(intensity)}
                onClick={() => handleSelectIntensity(intensity)}
              >
                <span>{intensity}</span>
              </div>
            ))}
          </div>
        </HeaderSection>
        <Section>
          <div style={{ paddingTop: "20px" }}>
            <h2 className="traningDurationTitle">Training Duration</h2>
            <h2 className="traningDurationMintsFormate">Minutes Format</h2>
          </div>
          <InputDiv>
            <div className="plusMinus" style={{ cursor: "pointer" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M1.43503 9.99956L5.98747 9.99851L5.98763 7.99792C5.9865 8.82538 6.20031 9.4978 6.99198 9.49662C6.35267 9.49908 5.9865 8.82792 5.98647 7.99924L5.98747 9.99851L14.5651 10C15.3579 10 15.9977 9.32645 16 8.5002C15.9988 7.67273 15.3555 7.00031 14.565 7.00028L5.98621 7.00011L5.98721 9.00677C5.98718 8.17809 6.7783 7.50567 6.99376 7.50806C6.59619 7.50683 6.23919 7.67401 5.97956 7.94538C5.71878 8.21796 5.98835 8.5911 5.98836 9.00544L5.98852 7L1.43376 7.00105C1.03734 7.00103 0.680345 7.16821 0.420716 7.43958C0.161086 7.71095 0.00114402 8.08651 0 8.49964C3.02537e-05 9.32832 0.642198 9.99953 1.43503 9.99956Z"
                  fill="#A01A1A"
                />
              </svg>
            </div>
            <div>
              <input
                type="text"
                className={styles.restTimeInput}
                value={Time}
                onChange={handleSessionTimeChange}
                style={{
                  padding: "7px 0px",
                  textAlign: "center",
                  display: "flex",
                }}
              />
            </div>

            <div className="pluscircle" style={{ cursor: "pointer" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M2.47505 9.42596L6.57352 9.42496L6.57367 13.5223C6.57255 14.3092 7.21206 14.9488 7.99903 14.9476C8.78946 14.95 9.42546 14.3117 9.42543 13.5235L9.42643 9.42507L13.5272 9.42638C14.3153 9.42641 14.9513 8.7858 14.9536 7.99998C14.9524 7.21301 14.3129 6.5735 13.5271 6.57347L9.42517 6.57331L9.42617 2.48176C9.42614 1.69363 8.78663 1.05412 8.00081 1.0564C7.60559 1.05523 7.25071 1.21422 6.99263 1.47231C6.73338 1.73155 6.57439 2.08643 6.57441 2.48049L6.57456 6.5732L2.47379 6.5742C2.07973 6.57418 1.72485 6.73318 1.46676 6.99126C1.20867 7.24935 1.04968 7.60654 1.04854 7.99945C1.04857 8.78757 1.68693 9.42593 2.47505 9.42596Z"
                  fill="#1AA053"
                />
              </svg>
            </div>
          </InputDiv>
        </Section>
        <Footer>
          <div onClick={handleFinishSession} style={{ cursor: "pointer" }}>
            <span>Finish Session</span>
          </div>
          <div>
            <span>Back To Training</span>
          </div>
        </Footer>
      </div>
    </div>
  );
};

export default IntensityModal;
