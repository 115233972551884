import React from "react";
import './messageskeleton.css'; // Make sure to create this CSS file for styles

const MessageSkeleton = ({ type }) => {
  return (
    <div className={`skeletonMessage ${type}`}>
      <div className="skeletonAvatar" />
      <div className="skeletonContent">
        {type === "text" && <div className="skeletonText" />}
        {type === "image" && <div className="skeletonImage" />}
        {type === "audio" && (
          <div className="skeletonAudio">
            <div className="skeletonText" style={{ width: "70%" }} />
            <div className="skeletonText" style={{ width: "30%" }} />
          </div>
        )}
      </div>
    </div>
  );
};

const InputSkeleton = () => {
  return (
    <div className="inputSkeleton">
      <div className="skeletonInput" />
      <div className="skeletonButton" />
    </div>
  );
};

const MessageSkeletonContainer = () => {
  return (
    <div className="message-list">
      <MessageSkeleton type="text" />
      <MessageSkeleton type="image" />
      <MessageSkeleton type="audio" />
      <InputSkeleton />
    </div>
  );
};

export default MessageSkeletonContainer;
