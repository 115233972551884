import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap';
// import GroupSection from './layout/Section';
import Header from './layout/Header';
import styles from "../atheleteSessions.module.css"
const AtheleteTraningSession = () => {
  const [isTimeStart, setTimeStart] = useState({ minutes: 0, seconds: 0 });

  return (
    <>
      <Col md={12} sm={12} lg={12} className={styles.traningGroupHeading}>
        <Header isTimeStart={isTimeStart} setTimeStart={setTimeStart} />
      </Col>
      <Col md={12} sm={12} lg={8}>
        {/* <GroupSection /> */}

      </Col>
    </>
  )
}

export default AtheleteTraningSession