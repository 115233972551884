import React from 'react';
import styled from 'styled-components';
import styles from "./history.module.css"

const SetsArea = styled.div`
  width: 100%;
  max-height: 500px; /* Set a maximum height for the scrolling area */
  overflow-y: auto; /* Enable vertical scrolling */
`;
const SetDiv = styled.div`
  display: flex;
  margin-top: 3vh;
  gap: 20px;
  flex-shrink: 0;
`;

const HistorySessionModal = ({ isOpen, onClose, getHistory }) => {
    return (
        <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`}>
            <div className="exercise-overlay" onClick={onClose}></div>
            <div className="exercisemmodalBody" style={{ width: "33%" }}>
                <SetsArea className={styles.setRepsWeightDiv}>
                    {getHistory.map((historyItem, historyIndex) => (
                        <div key={`historyItem-${historyIndex}`}>
                            <SetDiv>
                                <div className={styles.setsDiv}>
                                    <span>Sets</span>
                                </div>
                                <div className={styles.setsDiv}>
                                    <span>Reps</span>
                                </div>
                                <div className={styles.setsDiv}>
                                    <span>Weight</span>
                                </div>
                                <div className='d-flex' style={{ alignItems: "center" }}>
                                    <div className={styles.custom_checkbox}>
                                        <input type="checkbox" checked />
                                        <label htmlFor='checked'></label>
                                    </div>
                                </div>
                            </SetDiv>
                            {historyItem?.AthltBlockSets?.map((data, index) => (
                                <div key={`set-${index}`} className={styles.setsInputDiv} >
                                    <input
                                        type="text"
                                        name="set"
                                        value={`${index + 1}`}
                                        className={styles.sessionValueInput}
                                    />

                                    <input
                                        type="text"
                                        name="reps"
                                        value={data?.value1}
                                        className={styles.sessionValueInput}
                                    />

                                    <input
                                        type="text"
                                        name="weight"
                                        value={data?.value2}
                                        className={styles.sessionValueInput}
                                    />

                                    <div className='d-flex' style={{ alignItems: "center" }}>
                                        <div className={styles.custom_checkbox}>
                                            <input type="checkbox" checked />
                                            <label htmlFor='checked'></label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className={styles.saveButton} onClick={onClose}><span>Close</span></div>
                </SetsArea>
            </div>
        </div>
    );
};

export default HistorySessionModal;
