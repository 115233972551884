import React, { useContext, useEffect, useState } from "react";
import styles from "../atheleteSessions.module.css";
import { Col, Row } from "react-bootstrap";
import { ThemeContext } from "../../../../context/themeContext";
import { SessionContext } from "../../../../context/sessionContext";
import styled from "styled-components";
import Av from "../../../../assessts/icons/avtr.png";
import DarkAv from "../../../../assessts/icons/darkAv.png";
import DetailsHistoy from "../../../../assessts/icons/Button.svg";
import "./GroupCompleteSessionView.css";
import SvgIcon from "../../../../assessts/Svg/svgIcons";
import ViewSummaryModel from "../../Athelete/TraningSession/Details/ViewSummaryModel";
const ZoomableImage = styled.img`
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease; /* Add a smooth transition effect */
  cursor: pointer;
  &:hover {
    transform: scale(1.2); /* Scale up the image on hover */
  }
`;

const AtheleteDetails = ({
  totalSets,
  totalRepsValue,
  totalBlocks,
  setExerciseData,
  isSelectedDate,
  getGroupSesssionDetails
}) => {
  const { theme } = useContext(ThemeContext);
  const { handleStartSession, handleStartSessionGroup } =
    useContext(SessionContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal when the overlay or close button is clicked
  };
  const viewSummary = () => {
    setIsModalOpen(true); // Open the modal when the button is clicked
  };
  const [showBtn, setShowBtn] = useState(true);
  const [feedValue, setFeedValue] = useState();
  // const storageKey = `IsCompleteSetGroup_${isSelectedDate}`;

  // useEffect(() => {
  //   const isSessionComplete = localStorage.getItem(storageKey);
  //   if (isSessionComplete === "group") {
  //     setShowBtn(false);
  //   }
  // }, [storageKey]);
  // const getStoredData = () => {
  //   const CompleteFeedValueKey = `CompleteFeedValueForGroup${isSelectedDate}`;
  //   const storedData = localStorage.getItem(CompleteFeedValueKey);
  //   if (storedData) {
  //     setFeedValue(JSON.parse(storedData));
  //   } else {
  //     console.log("No data found for the selected date.");
  //     return null;
  //   }
  // };
  // useEffect(() => {
  //   getStoredData();
  // }, []);
  return (
    <>
      <div className="d-flex justify-content-between w-full pt-3 pb-3 gap-2">
        <div>
          <ZoomableImage id="lighticon" src={theme === "light" ? Av : DarkAv} />
          <span className={styles.cardiotext}>Cardio training Day 1</span>
        </div>
      </div>
      <div>
        {getGroupSesssionDetails.isComplete === false ? (
          <button
            className={styles.startSession}
            onClick={handleStartSessionGroup}
          >
            <span>Start Session</span>
          </button>
        ) : (
          <div className="CompleteSession">
            <div className="main">
              <div
                className="custom-box"
                style={{
                  background: "#25BD3D",
                }}
              >
                <SvgIcon iconName="block" size={30} color="blue" />
                <p className="main_text">Blocks</p>
                <h5 className="sub_text">
                  {getGroupSesssionDetails ? getGroupSesssionDetails?.totalBlocks : "0"}/5
                </h5>
              </div>
              <div
                className="custom-box"
                style={{
                  background: "#DC5C3E",
                }}
              >
                <SvgIcon iconName="minutes" size={30} color="blue" />
                <p className="main_text">Minutes</p>
                <h5 className="sub_text">
                  {getGroupSesssionDetails ? getGroupSesssionDetails?.totalDuration : "0"}
                </h5>
              </div>
              <div
                className="custom-box"
                style={{
                  background: "#3919EC",
                }}
              >
                <SvgIcon iconName="infinty" size={30} color="blue" />
                <p className="main_text">Intensity</p>
                <h5 className="sub_text">
                  {getGroupSesssionDetails ? getGroupSesssionDetails?.intensity : "0"}
                </h5>
              </div>
              <div
                className="custom-box"
                style={{
                  background: "#09D0AD",
                }}
              >
                <SvgIcon iconName="LB" size={30} color="blue" />
                <p className="main_text">LB</p>
                <h5 className="sub_text">
                  {getGroupSesssionDetails ? getGroupSesssionDetails?.totalWeight : ""}
                </h5>
              </div>
            </div>

            <button className={styles.startSession} onClick={viewSummary}>
              <span>View Summary</span>
            </button>
          </div>
        )}
        <ViewSummaryModel
          selectedDate={isSelectedDate}
          totalBlocks={totalBlocks}
          totalRepsValue={totalRepsValue}
          totalSets={totalSets}
          isOpen={isModalOpen}
          onClose={closeModal}
          feedValue={feedValue}
          getGroupSesssionDetails={getGroupSesssionDetails}
        />
      </div>
    </>
  );
};

export default AtheleteDetails;
