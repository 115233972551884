import React from 'react'
import { Mockup } from '../../../../../assessts/LandingPage'
import style from './sixth.module.css'
import Button from '../../../Components/Button/Button'

const Sixth = () => {
  return (
    <div className={`${style.main}  align-items-center justify-content-center`}>
      <div className={`${style.TEXT} `}>
        <h1>Expand your reach beyond your local community</h1>
        <p>TrainingApp offers you a powerful marketplace where you can showcase and sell your expertise and programs to a global audience. Tap into a whole new world of opportunities to share your knowledge and make an impact.</p>
        <Button title={'Improve your business plan'} bg={false}/>
      </div>
      <div className={`${style.PIC}`}>
        <img src={Mockup} alt="" />
      </div>
    </div>
  )
}

export default Sixth
