import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import {
  deleteProgram,
  deleteSession,
  getALLSessions,
  getAllExercise,
  getAllPrograms,
} from "../../apis";
import { auth } from "../../firebase";
import CreateExerciseModal from "../modals/createExersiceModal";
import CreateProgramModal from "../modals/createProgramModal";
import ExerciseTable from "./exerciseTable/exerciseTable";
import "./index.css";

import Marketplace from "./marketplace/marketplace";
import IndexTable from "./indexTable/indexTable";
import SessionTable from "./sessionTable/sessionTable";
import IndexCalendar from "./IndexCalender/indexCalender";
import { SessionContext } from "../../context/sessionContext";
import { toast } from "react-toastify";
import SessionModal from "../Common/Form/SessionForm/sessionModal";
import SvgIcon from "../../assessts/Svg/svgIcons";
import TabHeader from "../coachPortal/index/TabHeader/tabHeader";
import { Container } from "react-bootstrap";
import { deleteExercise } from "../../services/store/coachApi";
import CreateSession from "../modules/Modal/CreateSession";
const getInitialActiveTab = () => {
  const storedTab = localStorage.getItem("indexactiveTab");
  return storedTab ? storedTab : "programs";
};
const Index = () => {
  const [activeTab, setActiveTab] = useState(getInitialActiveTab);
  const { t } = useTranslation();
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isOpenExerModal, setIsOpenExerModal] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isProgramModalOpen, SetIsProgramModal] = useState(false);
  const [currentProgram, setCurrentProgram] = useState("");
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [selectedSession, setSelectedSession] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState([]);
  const [searchProgram, setSearchProgram] = useState("");
  const [isGetExercise, setGetExercise] = useState([]);
  const [isEditExercise, setEditEditExercise] = useState("");
  const [isEditSession, setEditSession] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [isEmptyDataForProgram, setisEmptyDataForProgram] = useState(false);
  const [isEmptyDataForSession, setisEmptyDataForSession] = useState(false);
  const [isEmptyDataForExercise, setisEmptyDataForExercise] = useState(false);
  const [isOpenSession, setIsOpenSession] = useState(false);
  //State for Pagination in Exercise Table
  //States for Pagination in Session Table
  const [isSessionTotalPages, setSessionTotalPages] = useState("");
  const [isPaginataionIndexSession, setPaginataionIndexSession] = useState(
    () => {
      const savedPage = localStorage.getItem("activePage");
      return savedPage !== null ? JSON.parse(savedPage) : 1;
    }
  );
  const currentData= new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(currentData);
  const handleEditSession = (data) => {
    setEditSession(data);
    setIsOpenSession(true);
  };
  const closeSessionForm = () => {
    setRefreshData((prev) => !prev)
    setIsOpenSession(false);
  };
  useEffect(() => {
    localStorage.setItem(
      "activePage",
      JSON.stringify(isPaginataionIndexSession)
    );
  }, [isPaginataionIndexSession]);
  //Get Active Tab Index Values
  const handleActiveIndex = (field) => {
    setActiveTab(field);
  };
  useEffect(() => {
    localStorage.setItem("indexactiveTab", activeTab);
  }, [activeTab]);
  const handleActivePagination = (page) => {
    setPaginataionIndexSession(page);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      fetchSessions();
    }
  };
  useEffect(() => {
    fetchSessions(isPaginataionIndexSession);
    // Add event listener for visibilitychange
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isPaginataionIndexSession]);

  // Session States
  const [getSessions, setGetSessions] = useState([]);
  const { isActivePaginationProgamme, isActivePaginationExercise } =
    useContext(SessionContext);
  const [isSessionPages, setSessionPages] = useState("");

  const [isShowDeleteCheckboxProgramme, setShowDeleteCheckboxProgramme] =
    useState(true);
  const [isShowDeleteCheckboxSession, setShowDeleteCheckboxSession] =
    useState(true);
  const [isShowDeleteCheckboxExercise, setShowDeleteCheckboxExercise] =
    useState(true);
  const { setLoader } = useContext(SessionContext);

  const closeExerciseModal = () => {
    setIsOpenExerModal(false);
    setRefreshData((prev) => !prev);
  };



  const closeProgramModal = () => {
    SetIsProgramModal(false);
  };

  const handleClick = () => {
    if (activeTab === "session") {
      setIsOpenSession(true);
      setEditSession("");
    } else if (activeTab === "exercises") {
      setIsOpenExerModal(true);
      setEditSession("");
    } else if (activeTab === "programs") {
      SetIsProgramModal(true);
      setEditSession("");
    }
  };
  const handleSearchChange = (e) => {
    setSearchProgram(e.target.value);
  };
  // Initial page
  const [totalPages, setTotalPages] = useState(""); // Total page
  // Empty dependency array to run only on component mount
  const [programs, setPrograms] = useState([]);
  const [authReady, setAuthReady] = useState(false);
  const [coachName, setCoachName] = useState("");
  // Effect to check when the authentication state is ready
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user); // Set authReady to true if user is logged in
      if (user) {
        setCoachName(user.displayName); // Set the coach's name
      }
    });

    return () => unregisterAuthObserver(); // Clean up observer on unmount
  }, []);

  // Fetch programs when the auth state is ready
  useEffect(() => {
    if (authReady) {
      getPrograms();
    }
  }, [authReady, isActivePaginationProgamme]);

  const getPrograms = async () => {
    setLoader(true);
    try {
      const token = await auth?.currentUser?.getIdToken(true);
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getAllPrograms}?currentPage=${isActivePaginationProgamme}&itemsPerPage=3`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include 'Bearer ' before the token
            },
          }
        );
        const { programs, totalPages: totalPagesCount } = response.data;
        {
          programs.length <= 0
            ? setisEmptyDataForProgram(true)
            : setisEmptyDataForProgram(false);
        }
        setPrograms(programs);
        setTotalPages(totalPagesCount); // Set total pages based on the response
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoader(false);
  };
  useEffect(() => {
    getPrograms();
  }, [
    window.location.pathname === "/index",
    searchProgram,
    auth?.currentUser,
    activeTab,
    isProgramModalOpen,
    currentProgram,
    isActivePaginationProgamme,
  ]);
  const handleDeleteProgram = async () => {
    setShowDeleteCheckboxProgramme(!isShowDeleteCheckboxProgramme);
    try {
      const token = await auth.currentUser.getIdToken(true);

      await axios.delete(`${process.env.REACT_APP_Baseurl}${deleteProgram}`, {
        headers: { Authorization: token },
        params: { programId: selectedPrograms },
      });
      // After deletion, you might want to refresh the table data
      setSelectedPrograms([]);
      await getPrograms();
      toast.success("Program Deleted successfully");
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
  useEffect(() => {
    fetchSessions();
  }, [isActivePaginationProgamme, refreshData]);
  /**
   * Handle To Delete Session
   */
  useEffect(() => {
    if (authReady) {
      fetchSessions();
    }
  }, [authReady, isPaginataionIndexSession]);
  const fetchSessions = async () => {
    setLoader(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      if (!token) {
        console.error("Authorization token is not available.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getALLSessions}?currentPage=${isPaginataionIndexSession}&itemsPerPage=3`,
        { headers: { Authorization: token } }
      );

      const sessionData = response.data.sessions;
      {
        sessionData.length <= 0
          ? setisEmptyDataForSession(true)
          : setisEmptyDataForSession(false);
      }
      setGetSessions(sessionData);
      setSessionTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };
  const handleDeleteSession = async () => {
    setShowDeleteCheckboxSession(!isShowDeleteCheckboxSession);
    try {
      const token = await auth.currentUser.getIdToken(true);

      await axios.delete(`${process.env.REACT_APP_Baseurl}${deleteSession}`, {
        headers: { Authorization: token },
        params: { sessionId: selectedSession },
      });

      setSelectedSession([]);
      toast.success("Session Deleted successfully");

      // Call fetchSessions to refresh data
      await fetchSessions();
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
  const handleDeleteExercise = async () => {
    setShowDeleteCheckboxExercise(!isShowDeleteCheckboxExercise);
    try {
      const token = await auth.currentUser.getIdToken(true);

      await axios.delete(`${process.env.REACT_APP_Baseurl}${deleteExercise}`, {
        headers: { Authorization: token },
        params: { exerciseId: selectedExercise },
      });

      setSelectedExercise([]);
      await fetchExercises();
      toast.success("Exercise Deleted successfully");

      // Call fetchSessions to refresh data
      await fetchSessions();
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
  const handleDeleteBoth = async () => {
    try {
      if (activeTab === "programs") {
        await handleDeleteProgram();
      } else if (activeTab === "session") {
        await handleDeleteSession();
      } else if (activeTab === "exercises") {
        await handleDeleteExercise();
      }
      // Additional logic if needed after both deletions are successful
    } catch (error) {
      // Error handling if either of the deletions fail
      console.error("Error in deleting:", error);
    }
  };

  /**
   * Handles get the program.
   */

  useEffect(() => {
    if (authReady) {
      fetchExercises();
    }
  }, [authReady, isActivePaginationExercise, refreshData]);
  const fetchExercises = async () => {
    setLoader(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      if (!token) {
        // Handle the case where the token is not available
        console.error("Authorization token is not available.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getAllExercise}?currentPage=${isActivePaginationExercise}&itemsPerPage=4`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const exercisesData = response.data.coachExercises;
      {
        exercisesData.length <= 0
          ? setisEmptyDataForExercise(true)
          : setisEmptyDataForExercise(false);
      }
      setGetExercise(exercisesData);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };
  /**
   * Handles get the program.
   */

  return (
    <Container fluid>
      <div className="indexContainer" >
        {showCalendar && (
          <IndexCalendar
            setShowCalendar={setShowCalendar}
            currentProgram={currentProgram}
          />
        )}
        <div style={{ padding: "2rem", display: showCalendar && "none" }}>
          <TabHeader
            indexActive={activeTab}
            handleActiveIndex={handleActiveIndex}
          />
          {activeTab !== "marketplace" && (
            <div className="athlt2ndHeader">
              <div className="programHeaderButtons w-100">
                <div
                  style={{ maxWidth: "650px", width: "67%" }}
                  className="search-container"
                >
                  <span className="search-icon h-100 d-flex justify-content-center align-items-center">
                    <FaSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searchProgram}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="indexDnCbtn">
                  <button
                    style={{
                      cursor: selectedPrograms.length === 0 && "default",
                    }}
                    disabled={setSelectedPrograms.length == 0}
                    onClick={handleDeleteBoth}
                    className="indeDelete-button"
                  >
                    <SvgIcon iconName="deleteIndex" size={20} />
                    Delete
                  </button>
                  {/* <button className="indexCopy-button">
                    <span className="delete-icon">
                      <SvgIcon iconName="copy" size={21} />
                    </span>
                    Copy
                  </button> */}
                </div>
                <div>
                  <button className="index-button" onClick={handleClick}>
                    <SvgIcon iconName="addIndex" size={21} />
                    {activeTab === "programs" && "Create Program"}
                    {activeTab === "session" && "Create Session"}
                    {activeTab === "exercises" && "Create Exercises"}
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeTab === "programs" && (
            // <IndexTable
            // activeTab={activeTab} />
            <IndexTable
              totalPages={totalPages}
              showDeleteCheckBox={isShowDeleteCheckboxProgramme}
              isEmptyDataForProgram={isEmptyDataForProgram}
              setShow={setShowCalendar}
              setCurrentProgram={setCurrentProgram}
              currentProgram={currentProgram}
              programs={programs}
              onDelete={handleDeleteProgram}
              setSelectedPrograms={setSelectedPrograms}
              selectedPrograms={selectedPrograms}
            />
          )}
          {activeTab === "session" && (
            <SessionTable
            handleEditSession={handleEditSession}
              onclose={closeExerciseModal}
              isEmptyDataForSession={isEmptyDataForSession}
              refreshData={refreshData}
              setRefreshData={setRefreshData}
              getSessions={getSessions}
              totalPages={isSessionTotalPages}
              paginationActive={isPaginataionIndexSession}
              handleActivePagination={handleActivePagination}
              isEditSession={isEditSession}
              setEditSession={setEditSession}
              setSelectedSession={setSelectedSession}
              selectedSession={selectedSession}
              showDeleteCheckBox={isShowDeleteCheckboxSession}
            />
          )}
          
          {activeTab === "exercises" && (
            <ExerciseTable
              selectedExercise={selectedExercise}
              setSelectedExercise={setSelectedExercise}
              showDeleteCheckBox={isShowDeleteCheckboxExercise}
              setRefreshData={setRefreshData}
              isEmptyDataForExercise={isEmptyDataForExercise}
              exerciseLists={isGetExercise}
              totalPages={totalPages}
              setEditEditExercise={setEditEditExercise}
              isEditExercise={isEditExercise}
            />
          )}
          {activeTab === "marketplace" && (
            <Marketplace coachName={coachName} authReady={authReady} />
          )}
          {isOpenSession && (
            <CreateSession
            setSelectedDate={setSelectedDate}
              isEditSession={isEditSession}
              setEditSession={setEditSession}
              isOpen={isOpenSession}
              onClose={closeSessionForm}
            />

            // <SessionModal
            //   isEditSession={isEditSession}
            //   setEditSession={setEditSession}
            //   isOpen={isInviteModalOpen}
            //   onClose={closeInviteModal}
            // />
          )}

          {isOpenExerModal && (
            <CreateExerciseModal
              setEditEditExercise={setEditEditExercise}
              isOpen={isOpenExerModal}
              editExercise={isEditExercise}
              onClose={closeExerciseModal}
            />
          )}
          {isProgramModalOpen && (
            <CreateProgramModal
              setCurrentProgram={setCurrentProgram}
              isOpen={isProgramModalOpen}
              onClose={closeProgramModal}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Index;
