import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import styles from './userdetails.module.css';
import { useTranslation } from 'react-i18next';
import Profile from "../../../../assessts/icons/Profile.png";
import Team from "../../../../assessts/icons/Iconly.png";
import Program from "../../../../assessts/icons/program.png";
import Session from "../../../../assessts/icons/session.png";
import { getTotalStatus } from '../../../../services/store/coachApi';
import { auth } from '../../../../firebase';
import axios from 'axios';
import useThemeColors from '../../../../hooks/useThemeColors';

const dashboardItems = [
    { id: 'athlete', titleKey: 'Atheletes', icon: Profile, dataKey: 'athleteCount' },
    { id: 'group', titleKey: 'Groups', icon: Team, dataKey: 'groupCount' },
    { id: 'program', titleKey: 'Program', icon: Program, dataKey: 'marketplaceProgramCount' },
    { id: 'session', titleKey: 'Session', icon: Session, dataKey: 'totalCoachActiveSessionsCount' },
];

const DashboardCard = ({ title, icon, value }) => {
    return (
        <Col xs={12} sm={6} md={6} lg={3} xlg={3} className='px-5 my-4'>
            <Card className={`${styles.userDetailsCard} px-4 py-3 w-100`}>
                <div className={`${styles.userDetailsCardImgContainer} mb-2`}>
                    <Card.Img className='w-100 h-100' alt={title} src={icon} />
                </div>
                <Card.Body className='d-flex align-items-end justify-content-between p-0'>
                    <Card.Text className={`${styles.userDetailsCardTitle} m-0`}>{title}</Card.Text>
                    <Card.Text className={styles.userDetailsCardValues}>{value || 0}</Card.Text>

                </Card.Body>
            </Card>
        </Col>
    )
};


const CoachGrid = () => {
    const [userDetails, setUserDetails] = useState({});
    const { t } = useTranslation();
    useEffect(() => {
        const fetchFeedDetails = async () => {
            try {
                const token = await auth.currentUser.getIdToken(true);
                const response = await axios.get(
                    `${process.env.REACT_APP_Baseurl}${getTotalStatus}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setUserDetails(response.data);
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        fetchFeedDetails();
    }, []);

    return (
        <Row className={`${styles.userDetailsRow} m-0`}>
            {dashboardItems.map(item => (
                <DashboardCard
                    key={item.id}
                    title={t(item.titleKey)}
                    icon={item.icon}
                    value={userDetails[item.dataKey]}
                />
            ))}
        </Row>
    );
};

export default CoachGrid;
