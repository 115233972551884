import React from 'react'
import style from './one.module.css'
import Button from '../../../../Components/Button/Button'

const One = () => {
  return (
    <div className={`${style.bgIMG} d-flex flex-column align-items-center justify-content-center`}>
      <div className={`${style.content} d-flex flex-column align-items-center justify-content-center`}>
        <h1>THE TRAINING APP MARKETPLACE</h1>
        <p>Find Your Perfect Training Plan</p>
        <Button title="FIND MY PROGRAM" bg={true}/>
      </div>
    </div>
  )
}

export default One
