import React from 'react'
import style from './third.module.css'

const Third = () => {

    const data = [
        { count: 66, heading: "A GREAYT START TO A HEALTHY LIFE", para: "Laboriosam molestias aperiam sit corporis sunt, hic veritatis possimus optio reprehenderit, laudantium excepturi, consequatur. Assumenda hic error veniam exercitationem.", name: 'John Donas', post: "Manager" },
        { count: 66, heading: "THE WORKOUT WORTH ATTENDING!", para: "Laboriosam molestias aperiam sit corporis sunt, hic veritatis possimus optio reprehenderit,laudantium excepturi, consequatur. Assumenda hic error veniam exercitationem", name: 'Donas Jack', post: "Manager" },
        { count: 66, heading: "VERY PROFESSIONAL CLUB AND COACHES", para: "Laboriosam molestias aperiam sit corporis sunt, hic veritatis possimus optio reprehenderit,laudantium excepturi, consequatur. Assumenda hic error veniam exercitationem", name: 'Mikel Hussy', post: "Manager" },
        { count: 66, heading: "SHAPE YOUR BODY AND HEALTHY!", para: "Laboriosam molestias aperiam sit corporis sunt, hic veritatis possimus optio reprehenderit, laudantium excepturi, consequatur. Assumenda hic error veniam exercitationem.", name: 'John Donas', post: "Manager" },
    ]
    return (
        <div className={`${style.main} d-flex flex-column align-items-center justify-content-center bg-dark text-center`}>
            <div className='d-flex flex-column text-center align-items-center'>
            <hr />
                <h1>WHAT PEOPLE SAY</h1>
            </div>
            <div className={`${style.beforeMap} d-flex flex-column`}>
                {data.map((data, key) => (
                    <div key={key} className={`${style.mapped} d-flex flex-column align-items-center `}>
                        <h1>{data.count}</h1>
                        <h2>{data.heading}</h2>
                        <p>{data.para}</p>
                        <div>
                            <h2>{data.name}</h2>
                            <h3>{data.post}</h3>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Third

