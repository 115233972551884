import axios from "axios";
import React, { useState } from "react";
import { createCoachGroup, editCoachGroup } from "../../apis";
import "./inviteModal.css";

import { auth } from "../../firebase";
import LoadingSpinner from "../loadingSpinner";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';
const CreateGroupModal = ({ isOpen, onClose, isEditGroup, setEditGroup }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    groupName: isEditGroup?.groupName || "",
    sportsType: isEditGroup?.sportsType || "",
    coachID: auth.currentUser.uid,
    file: isEditGroup?.file || "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
    setValidationErrors({ ...validationErrors, [field]: "" });
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.groupName) errors.groupName = "Group Name is required.";
    if (!formData.sportsType) errors.sportsType = "Sports Type is required.";
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleCreateGroup = async () => {
    if (!validateForm()) return; // Stop if validation fails
    try {
      setIsLoading(true);
      const token = await auth.currentUser.getIdToken(true);
      const endpoint = isEditGroup ? editCoachGroup : createCoachGroup;
      const payload = { ...formData };
      await makeApiCall(endpoint, payload, token);
      onClose();
      toast.success(isEditGroup ? "Group updated successfully" : "Group created successfully");
    } catch (err) {
      console.error(err);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = { headers: { Authorization: token } };
    if (isEditGroup) {
      config.params = { groupId: isEditGroup.id };
    }
    if (isEditGroup) {
      await axios.patch(url, payload, config);
    } else {
      await axios.post(url, payload, config);
    }
  };
  const MAX_FILE_SIZE = 100 * 1024;
  const COMPRESSION_LIMIT = 10 * 1024; // 10 KB
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return; // No file selected
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      initialQuality: 1,
      useWebWorker: true,
    };
    try {
      let fileToProcess = file;
      if (file.size > COMPRESSION_LIMIT) {
        const compressedFile = await imageCompression(file, options);
        fileToProcess = compressedFile;
        if (fileToProcess.size > MAX_FILE_SIZE) {
          toast.error("File size exceeds limit.");
          return;
        }
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64File = reader.result;
        setFormData((prevData) => ({ ...prevData, file: base64File }));
      };
      reader.onerror = (error) => {
        console.error("FileReader error:", error);
        toast.error("An error occurred while reading the file.");
      };
      reader.readAsDataURL(fileToProcess);
    } catch (error) {
      console.error("Error during file processing:", error);
      toast.error("An error occurred while processing the file.");
    }
  };
  return (
    <div className={`exercise-modal ${isOpen ? "exercise-open" : ""}`}>
      <div className="exercise-overlay" onClick={onClose}></div>
      <div className="exercisemmodalBody" style={{ width: "35vw" }}>
        <h2 style={{ textTransform: "uppercase" }}>Create Group</h2>
        <Container>
          <Row>
            <Form.Label className="upload">Upload Pic</Form.Label>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "200px", // Set a height to visualize the container
              }}
              onDragOver={(e) => e.preventDefault()} // Prevent default to allow drop
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files[0];
                if (file) {
                  handleFileUpload({ target: { files: [file] } });
                }
              }}
            >
              <div
                style={{
                  backgroundImage: formData?.file
                    ? `url(${formData?.file})`
                    : "none",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  border: formData.file ? "none" : "2px dashed #ccc", // Optional: Border for drag-drop area
                }}
              >
                {!formData.file && (
                  <div
                    className="drag-drop-text"
                    style={{ textAlign: "center" }}
                  >
                    <p>Drag & Drop your file here</p>
                    <span style={{ marginRight: "5px" }}>or</span>
                    <label
                      htmlFor="fileInput"
                      className="browse-text"
                      style={{
                        cursor: "pointer",
                        color: "#007bff",
                        textDecoration: "underline",
                      }}
                    >
                      Browse
                    </label>
                    <span> to upload</span>
                  </div>
                )}
                {formData?.file && (
                  <div
                    className="hover-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      transition: "opacity 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)}
                    onMouseLeave={(e) => (e.currentTarget.style.opacity = 0)}
                  >
                    <div
                      className="drag-drop-text"
                      style={{ textAlign: "center" }}
                    >
                      <p>Drag & Drop your file here</p>
                      <span style={{ marginRight: "5px" }}>or</span>
                      <label
                        htmlFor="fileInput"
                        className="browse-text"
                        style={{
                          cursor: "pointer",
                          color: "#007bff",
                          textDecoration: "underline",
                        }}
                      >
                        Browse
                      </label>
                      <span> to upload</span>
                    </div>
                  </div>
                )}
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  className="file-input"
                  onChange={handleFileUpload}
                  style={{ display: "none" }} // Hide the file input
                />
              </div>
            </div>
          </Row>
          <Row className="mb-3 mt-3">
            <Col sm={12} md={12} className="p-0">
              <Form.Group controlId="frmB">
                <Form.Label className="customInputLabel">
                  <b>{"Group Name"}</b>
                </Form.Label>
                <Form.Control
                  value={formData.groupName}
                  type="text"
                  placeholder="Enter Group Name"
                  name="groupName"
                  className="customInput"
                  onChange={(e) => handleInputChange(e, "groupName")}
                ></Form.Control>
                {validationErrors.groupName && (
                  <p style={{ color: "red", marginTop: "5px" }}>
                    {validationErrors.groupName}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Col sm={12} md={12} className="p-0">
              <Form.Group controlId="frmB">
                <Form.Label className="customInputLabel">
                  <b>{"Sport Type"}</b>
                </Form.Label>
                <Form.Control
                  value={formData.sportsType}
                  type="text"
                  placeholder="Enter Sports Type"
                  name="sportsType"
                  className="customInput"
                  onChange={(e) => handleInputChange(e, "sportsType")}
                ></Form.Control>
                {validationErrors.sportsType && (
                  <p style={{ color: "red", marginTop: "5px" }}>
                    {validationErrors.sportsType}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="d-flex justify-content-end gap-4 mt-3">
              <Button
                style={{
                  backgroundColor: `var(--secondary-color,#A19686)`,
                  color: `var(--secondary-text-color,white)`,
                  height: "48px",
                  width: "120px",
                  borderRadius: "8px",
                  border: "none",
                  fontSize: "19px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
                onClick={handleCreateGroup}
              >
                {isLoading ? <LoadingSpinner size="sm" /> : isEditGroup ? "Update" : "Create"}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default CreateGroupModal;