import React from 'react'
import style from './last.module.css'
import Button from '../../../../Components/Button/Button'


const Last = () => {
  return (
    <div className={`${style.main} d-flex flex-column align-items-center justify-content-center`}>
      <div className={`${style.BgImg}`}>

    <div className={`${style.content} d-flex flex-column text-center align-items-center justify-content-center`}>
        <h1>You Work too Hard to Not See Progress</h1>
        <p>find a program that suits you</p>
        <div className={`${style.btnDIV} d-flex`}>
            <Button title={'Find a Training Program'} bg={true}/>
            <Button title={'Download the app'} bg={false}/>
        </div>
    </div>
      </div>
</div>
  )
}

export default Last
