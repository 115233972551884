import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { auth } from "../../../firebase";
import LoadingSpinner from "../../loadingSpinner";
import { toast } from 'react-toastify';
import { deleteAthelete, deleteBlockSession, deleteExercise, deleteInvitedAthelete, deleteSession } from '../../../services/store/coachApi';
import "./button.css";

const DeleteModal = ({ isOpen, onClose, athleteID, sessionId, type, isActiveTab ,method}) => {
  const [isLoading, setIsLoading] = useState(false);

  const apiEndpoints = {
    deleteAthlete: deleteAthelete,
    deleteInvitedAthele: deleteInvitedAthelete,
    deleteBlock: deleteBlockSession,
    deleteSession: deleteSession,
    deleteExercise: deleteExercise,
  };

  const handleApiCall = async (endpoint, id, method = 'delete') => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}?${id}`;
    const token = await auth.currentUser.getIdToken(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios[method](url, config);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      let endpoint;
      let id;

      if (isActiveTab === "invited") {
        endpoint = apiEndpoints.deleteInvitedAthele;
        id = `athleteID=${athleteID}`;
      } else {
        endpoint = athleteID ? apiEndpoints.deleteAthlete : 
                  type === "blockDelete" ? apiEndpoints.deleteBlock : 
                  type === "exerciseDelete" ? apiEndpoints.deleteExercise : 
                  apiEndpoints.deleteSession;

        id = athleteID ? `athleteID=${athleteID}` : 
            type === "blockDelete" ? `blockId=${sessionId}` : 
            type === "exerciseDelete" ? `exerciseId=${sessionId}` : 
            `sessionId=${sessionId}`;
      }
      
      // Make the API call
      await handleApiCall(endpoint, id,method);

      // Success message
      toast.success(`${athleteID ? 'Athlete' : type === "blockDelete" ? 'Block' : type === "exerciseDelete" ? 'Exercise' : 'Session'} Deleted Successfully!`);
      onClose();
    } catch (err) {
      console.error("Error:", err);
      // Check if the error response is unauthorized (401)
      if (err.response && err.response.status === 401) {
        toast.error("Unauthorized. Please log in again.");
      } else {
        toast.error("Failed to delete. Please try again.");
      }
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return isOpen ? (
    <div className="exercise-modal exercise-open">
      <div className="exercise-overlay" onClick={onClose}></div>
      <div className="exercisemmodalBody text-center" style={{ width: "35%", padding: "20px", backgroundColor: `var(--secondary-bg-color,white)` }}>
        <h2 style={{ color: `var(--primary-text-color,black)` }}>
          Are you sure you want to delete this {athleteID ? 'Athlete' : type === "blockDelete" ? 'Block' : type === "exerciseDelete" ? 'Exercise' : 'Session'}?
        </h2>
        <div className='d-flex justify-content-around p-3'>
          <Button variant="light" onClick={onClose} style={{ width: "30%", backgroundColor: `var(--secondary-bg-color,#f8f9fa)`, color: `var(--primary-text-color,black)` }}>No</Button>
          <Button variant="danger" onClick={handleDelete} style={{ width: "30%" }}>
            {isLoading ? <LoadingSpinner size="sm" /> : "Yes"}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default DeleteModal;
