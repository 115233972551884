// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/****
Custom Pagination
*******/
.pagination_Pagination__lok50 ul {
    padding: 0;
    margin: 0;
}

.pagination_Pagination__lok50 ul li {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: 400;
    margin: 2px;
}

.pagination_Pagination__lok50 ul li a,
.pagination_Pagination__lok50 ul li span {
    color: gray;
    text-align: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
}

.pagination_Pagination__lok50 ul li.pagination_active__-RUFn a,
.pagination_Pagination__lok50 ul li.pagination_activePagination__kQK0q span {
    background: #A19686 !important;
    color:#fff !important;
    border: 1px solid transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/Pagination/pagination.module.css"],"names":[],"mappings":"AAAA;;OAEO;AACP;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf;;AAEA;;IAEI,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;;IAEI,8BAA8B;IAC9B,qBAAqB;IACrB,6BAA6B;AACjC","sourcesContent":["/****\r\nCustom Pagination\r\n*******/\r\n.Pagination ul {\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.Pagination ul li {\r\n    display: inline-block;\r\n    margin-bottom: 4px;\r\n    font-weight: 400;\r\n    margin: 2px;\r\n}\r\n\r\n.Pagination ul li a,\r\n.Pagination ul li span {\r\n    color: gray;\r\n    text-align: center;\r\n    display: inline-block;\r\n    width: 40px;\r\n    height: 40px;\r\n    line-height: 40px;\r\n    border-radius: 50%;\r\n    border: 1px solid #e6e6e6;\r\n}\r\n\r\n.Pagination ul li.active a,\r\n.Pagination ul li.activePagination span {\r\n    background: #A19686 !important;\r\n    color:#fff !important;\r\n    border: 1px solid transparent;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Pagination": `pagination_Pagination__lok50`,
	"active": `pagination_active__-RUFn`,
	"activePagination": `pagination_activePagination__kQK0q`
};
export default ___CSS_LOADER_EXPORT___;
