import React from 'react'
import style from './CoachMain.module.css'
import Button from '../../../Components/Button/Button'
const Main = () => {
    return (
        <div className={`${style.bgImg}`}>
            <div className={`${style.content}`}>
                <div className={`${style.TEXT} d-flex flex-column`}>
                    <h1 className='text-center'>EXPLORE THE TRUE POTENTIAL OF COACHING ABILITIES</h1>
                    <p>The game-changing platform that will revolutionize your business and propel it to new heights. Scale your coaching services beyond your wildest imagination with our innovative features and tools.</p>
                    <div className={`${style.btnDIV}`}>
                    {/* <button>Start your FREE trial</button> */}
                    <Button title={'Start your FREE trial'} bg={true}/>
                    <Button title={'View Pricing'} bg={false}/>
                    </div>
                </div>
                <div className={`${style.video}`}> 
                    <div>
                    <video src="#" ></video>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Main
// width={'650'} height={'380'}