import React from 'react'
import Layout from '../../Layout'
import Last from './components/Last/Last'
import One from './components/One/One'
import Two from './components/Two/Two'
import Three from './components/Three/Three'

const MarketPlace = () => {
  return (
    <>
    <Layout>
      <div>
      <One/>
      <Two/>
      <Three/>
      <Last />
      </div>
    </Layout>
    </>
  )
}

export default MarketPlace
