import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./traningSession.module.css";
import Session from "../../../Common/Session/Session";
import CalenderUI from "../../CalenderUI";
import UserSession from "../../../atheleteComponnent/manageTraining/Components/UserSession/userSession";
import SvgIcons from "../../../ReusableUi/SvgIcon";
import Loader from "../../../ReusableUi/Loader";
import AtheleteDetails from "./Details/AtheleteDetails";
import { getAtheleSession } from "../../../../apis";
import { auth } from "../../../../firebase";
import axios from "axios";
import { SessionContext } from "../../../../context/sessionContext";

const NoSessionPlaceholder = () => (
  <div
    className="w-full d-flex flex-column justify-content-center align-items-center"
    style={{ height: "30vw" }}
  >
    <SvgIcons iconName="noSessionPlane" />
    <div className="w-100 d-flex flex-column justify-content-center">
      <h4
        className={`${styles?.noSessionHeading} d-flex justify-content-center`}
      >
        No Sessions Planned
      </h4>
      <div className="d-flex justify-content-center">
        <h4 className={styles?.noSessionDescription}>
          Contact your coach and plan a session today or buy a program from the marketplace.
        </h4>
      </div>
    </div>
  </div>
);

const TraningSession = () => {
  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDate = localStorage.getItem("selectedDate");
    if (storedDate) {
      return storedDate;
    } else {
      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
      localStorage.setItem("selectedDate", formattedDate);
      return formattedDate;
    }
  });
  const [startSession, setStartSession] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [sessionData, setSessionData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authReady, setAuthReady] = useState(false);
  const [isEmptyDataForFeed, setisEmptyDataForFeed] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [feedValue,setFeedValue]=useState({})
  useEffect(() => {
    if (refresh || authReady) {
      fetchSessionData();
    }
  }, [refresh, authReady,selectedDate]);
  const [totalSets, setTotalSets] = useState(0);
  const [totalRepsValue,setTotalRepsValue]=useState(0);
  const [totalBlocks,setTotalBlocks]=useState(0)
  const [apiResponse, setApiResponse] = useState([]);
  const [apiPersonalRecordResponse, setApiPersonalRecordResponse] = useState(
    []
  );
  const [CurrentExerciseid,setCurrentExerciseid]=useState("")
  const [CurrentExerciseBlockId,setCurrentExerciseBlockId]=useState("")
  const fetchSessionData = async () => {
    if (!selectedDate || !auth.currentUser) return;
    setIsLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getAtheleSession}?date=${selectedDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          cancelToken: source.token,
        }
      );

      setisEmptyDataForFeed(response?.data?.responseData.length <= 0);
      const countSets = (data) => {
        let total = 0;
        data.forEach(item => {
          if (item.AthBlckAthSession) {
            item.AthBlckAthSession.forEach(block => {
              if (block.AthltBlockSets) {
                total += block.AthltBlockSets.length;
              }
            });
          }
        });
        return total;
      };
  
      const setsCount = countSets(response?.data?.responseData);
      setTotalSets(setsCount);
      const calculateTotalRepsValue = (data) => {
        let total = 0;
        data.forEach(item => {
          if (item.AthBlckAthSession) {
            item.AthBlckAthSession.forEach(block => {
              if (block.AthltBlockSets) {
                block.AthltBlockSets.forEach(set => {
                  total += parseInt(set.value1, 10); // Convert value1 to an integer and sum
                });
              }
            });
          }
        });
        return total;
      };
  
      const total = calculateTotalRepsValue(response?.data?.responseData);
      setTotalRepsValue(total);
      const countBlocks = (data) => {
        let blockCount = 0;
        data.forEach(item => {
          if (item.AthBlckAthSession) {
            blockCount = item.AthBlckAthSession.length; // Count the number of blocks
          }
        });
        return blockCount;
      };
  
      const blocksCount = countBlocks(response?.data?.responseData);
      setTotalBlocks(blocksCount);
      // Get the session data and then apply sorting
      const data = response?.data?.responseData[0];

      // Sort `AthBlckAthSession` by `createdAt`
      if (data && data.AthBlckAthSession) {
        data.AthBlckAthSession.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        // Sort each `AthltBlockSets` within `AthBlckAthSession`
        data.AthBlckAthSession.forEach((session) => {
          if (session.AthltBlockSets) {
            session.AthltBlockSets.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          }
        });
      }

      setSessionData(data);

    } catch (error) {
      if (!axios.isCancel(error)) {
        setError(error);
        console.error("Error fetching session calendar:", error);
      }
    } finally {
      setIsLoading(false);
    }

    return () => source.cancel("Component got unmounted");
  };
  


  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user); // Set authReady to true if user is logged in
    });

    return () => unregisterAuthObserver(); // Clean up observer on unmount
  }, []);

  const exerciseId = sessionData?.AthBlckAthSession[0]?.exerciseId;
  const coachId = sessionData?.coachId;
  const sessionId = sessionData?.id;
  const [AtheleteExercise, setAtheleteExercise] = useState("");
 const { setSessionHistory} = useContext(SessionContext);
 const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  useEffect(() => {
    if (!isLoading) {
      setPageLoading(false); // Set loading to false once the data is fetched
    }
  }, [isLoading]);

  // Handle to Open Session
  const handleSession = () => {
    fetchSessionData()
    setStartSession(!startSession);
    setSessionHistory(false)
  };

  const renderSessionContent = () => {
    if (isLoading) {
      return (
        <div
          className="w-full d-flex flex-column justify-content-center align-items-center"
          style={{ height: "30vw" }}
        >
          <Loader />
        </div>
      );
    }
    if (isEmptyDataForFeed) {
      return <NoSessionPlaceholder />;
    }

    return (
      <>
        <AtheleteDetails
        blockId={CurrentExerciseBlockId}
        exerciseId={CurrentExerciseid}
        setApiResponse={setApiResponse}
        setApiPersonalRecordResponse={setApiPersonalRecordResponse}
        currentExerciseIndex={currentExerciseIndex}
        selectedDate={selectedDate}
        totalBlocks={totalBlocks}
        totalRepsValue={totalRepsValue}
        totalSets={totalSets}
        feedValue={feedValue}
        setStartSession={setStartSession}
          getAtheleteSessionExercise={sessionData}
          handleSession={handleSession}
        />
        <UserSession
          setAtheleteExercise={setAtheleteExercise}
          getAtheleteSessionExercise={sessionData?.AthBlckAthSession}
          handleSession={handleSession}
        />
      </>
    );
  };
  if (pageLoading) {
    return (
      <div
        className="w-full d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Loader />
      </div>
    );
  }
  
  return (
    <Container fluid>
      <Row>
        <Col className={styles.trainingSessionContent}>
          {startSession ? (
            <Session
            CurrentExerciseid={CurrentExerciseid}
            setCurrentExerciseBlockId={setCurrentExerciseBlockId}
            setCurrentExerciseIndex={setCurrentExerciseIndex}
            apiPersonalRecordResponse={apiPersonalRecordResponse}
            apiResponse={apiResponse}
            setApiResponse={setApiResponse}
            setApiPersonalRecordResponse={setApiPersonalRecordResponse}
            setCurrentExerciseid={setCurrentExerciseid}
            currentExerciseIndex={currentExerciseIndex}
            setFeedValue={setFeedValue}
            startSession={startSession}
              refresh={refresh}
              setRefresh={setRefresh}
              SelectedDate={selectedDate}
              AtheleteExercise={AtheleteExercise}
              sessionData={sessionData}
              isExerciseId={exerciseId}
              getSessionId={sessionId}
              getCoachId={coachId}
              getAtheleteSessionExercise={sessionData?.AthBlckAthSession}
              setStartSession={setStartSession}
              handleSessionGetData={fetchSessionData}
            />
          ) : (
            <div className="px-4">
              <CalenderUI setSelectedDate={setSelectedDate} />
              {renderSessionContent()}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TraningSession;
