import React from 'react';
import './createProgramModal.css'
import CreateProgram from '../forms/createProgram/createProgram';
const CreateProgramModal = ({ isOpen, onClose, currentProgram, setCurrentProgram, activeTab }) => {
  
  return (
    <div className={`program-modal ${isOpen ? 'program-open' : ''}`}>
      <div className="program-overlay" onClick={onClose}></div>
      <div style={{
                    overflowY: "scroll",
                    height: "83vh",
                    maxWidth: "80%",
                    width: "80% ",
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                    zIndex: "1002",
                    position: "relative",
                    borderRadius: "8px",
                    background: " #FFF"
                }}>
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <h2  >  {currentProgram ? 'Edit Program' : 'Create a Program'}</h2>
        <CreateProgram currentProgram={currentProgram} setCurrentProgram={setCurrentProgram} onClose={onClose} activeTab={activeTab} />
      </div>
    </div>
  );
};

export default CreateProgramModal;
