import React from 'react'
import style from './four.module.css'
import { icon1, icon2, icon3 } from '../../../../../../assessts/LandingPage'
import Button from '../../../../Components/Button/Button'

const Fourth = () => {
    const data = [
        { icon: icon1, heading: "3 PRICING TIERS ONLY", para: "Don't be consistently punished for growth. Feel secure settling into an pricing option that fits your budget and capacity."},
        { icon: icon2, heading: "ACCESSIBLE BY MOBILE PHONE", para: "Life is busy and in todays world we are always on the GO! We're removing the stresses of programming limitation with all updates accessible anywhere at anytime."},
        { icon: icon3, heading: "MANAGE PAYMENT SYSTEMS", para: "Process, pause and prepare payment systems for any and all clients across any program."},
    ]
  return (
    <div className={`${style.main} d-flex flex-column align-items-center justify-content-center`}>
        <hr />
        <div className='d-flex flex-column text-center'> 
        <h1>WHAT MAKES US DIFFERENT</h1>
        <p>Running a Gym is financially challenging and never fully understood. Our pricing strategies reflect the struggles from everyday experience and our dedication is offering affordable solutions without compromising quality.</p>
        </div>
      <div className={`${style.beforeMap} `}>
      {data.map((data, key)=>(
        <div key={key} className={`${style.mapped} d-flex`}>
                <div className={`${style.map} d-flex flex-column align-items-center justify-content-center text-center`}>
                    <img src={data.icon}  />
                    <h1>{data.heading}</h1>
                    <p>{data.para}</p>
                </div>
            </div>
      ))}
      </div>

    </div>
  )
}

export default Fourth
