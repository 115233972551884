import React from 'react'
import style from './two.module.css'
import { DropDownArrow, FilterIcon, SearchIcon } from '../../../../../../assessts/LandingPage'

const Two = () => {
  const programs = [
    { name: "Functional Fitness" },
    { name: "Conditioning" },
    { name: "Powerlifting" },
    { name: "Bodybuilding" },
    { name: "Sports" },
    { name: "Mobility" },
  ]
  return (
    <div className={`${style.main} d-flex flex-column align-items-center justify-content-center`}>
      <div className={`${style.inputs} d-flex`}>
        <div className={`${style.inputWrap} d-flex align-items-center position-relative`}>
          <input type="text" id='search' placeholder='search all programs' className={`${style.searchInput} `} />
          <img src={SearchIcon} className={`${style.searchImg} position-absolute`} alt="" />
        </div>
        {/* Below is DropDown */}
        <div className={`${style.dropdown} d-flex align-items-center position-relative`}>
          <select name="Low to High" id="programDropdown" className={`${style.selects}position-relative`}>
            <option value="lowtohigh">Low to High</option>
            <option value="hightolow">High to Low</option>
            <option value="mostsearched">Most Searched</option>
          </select>
          <img src={FilterIcon} className={`${style.filterImg} position-absolute`} alt="" />
          <img src={DropDownArrow} className={`${style.arrowImg} position-absolute`} alt="" />
        </div>
      </div>
      <div className={`${style.programs} `}>
        {programs.map((data, key) => (
          <p key={key}>{data.name}</p>
        ))}
      </div>
    </div>
  )
}

export default Two
