import React, { useEffect, useState } from "react";
import "./manageExercise.css";
import { FaSearch } from "react-icons/fa";
import CreateExerciseModal from "../../modals/createExersiceModal";
import Table from "../../Table/Table";
import { deleteExercise, getExercises } from "../../../services/store/adminApi";
import { auth } from "../../../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import SvgIcon from "../../../assessts/Svg/svgIcons";

const ManageExercise = (props) => {

  const { activeTab } = props;
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [isShowDeleteCheckbox, setShowDeleteCheckbox] = useState(false)
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [isOpenExerModal, setIsOpenExerModal] = useState(false);
  const [isEditExercise, setEditExercise] = useState(false)
  const [currentExercise, setCurrentExercise] = useState("")
  // const [isPaginataionGroups, setPaginataionGroups] = useState(() => {
  //   const savedPage = localStorage.getItem('activePage');
  //   return savedPage !== null ? JSON.parse(savedPage) : 1;
  // });
  const closeExerciseModal = () => {
    setIsOpenExerModal(false);
  };

  // useEffect(() => {
  //   localStorage.setItem('activePage', JSON.stringify(isPaginataionGroups));
  // }, [isPaginataionGroups]);
  // const handleActivePagination = (page) => {
  //   setPaginataionGroups(page);
  // };

  // const handleVisibilityChange = () => {
  //   if (document.visibilityState === 'visible') {
  //     fetchAtheleteGroup();
  //   }
  // };
  // useEffect(() => {
  //   fetchAtheleteGroup();
  //   // // Add event listener for visibilitychange
  //   // document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // // Clean up the event listener when the component unmounts
  //   // return () => {
  //   //   document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   // };
  // }, []);

  const fetchAtheleteGroup = async () => {

    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getExercises}?currentPage=1&itemsPerPage=4`,
        { headers: { Authorization: token } }
      );
      setData(response?.data?.AdminExercises)
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch groups.");
    }
  }

  useEffect(() => {
    fetchAtheleteGroup();
  }, []);

  const handleDeleteProgram = async () => {
   
    setShowDeleteCheckbox(!isShowDeleteCheckbox)
   
    try {
      const token = await auth.currentUser.getIdToken(true);

      await axios.delete(`${process.env.REACT_APP_Baseurl}${deleteExercise}`, { headers: { Authorization: token, }, params: { exerciseId: selectedPrograms }, });
      // After deletion, you might want to refresh the table data
      setSelectedPrograms([]);
      await fetchAtheleteGroup();
      toast.success("Program Deleted successfully");
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const handleClick = () => {
    setIsOpenExerModal(true)
  }
  return (
    <div style={{ height: "auto" }} className="athltContainer">
      <div className="athltHeader">Exercises</div>
      <div
        className={!show && "athbody"}
        style={{
          padding: !show && "2rem",
        }}
      >
        <div className="athlt2ndHeader">
          <div>
            <div className="search-container">
              <span className="search-icon">
                <FaSearch />
              </span>
              <input
                type="text"
                placeholder="Search"
                className="search-input"
              />
            </div>
          </div>
          <div className="athltbuttons">
            <button style={{ cursor: selectedPrograms.length === 0 && 'default' }} disabled={setSelectedPrograms.length == 0} onClick={handleDeleteProgram} className="indeDelete-button">
              <SvgIcon iconName="deleteIndex" size={20} />
              Delete
            </button>
            <button className="index-button" onClick={handleClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.625 4.375C10.625 4.02982 10.3452 3.75 10 3.75C9.65482 3.75 9.375 4.02982 9.375 4.375V9.375H4.375C4.02982 9.375 3.75 9.65482 3.75 10C3.75 10.3452 4.02982 10.625 4.375 10.625H9.375V15.625C9.375 15.9702 9.65482 16.25 10 16.25C10.3452 16.25 10.625 15.9702 10.625 15.625V10.625H15.625C15.9702 10.625 16.25 10.3452 16.25 10C16.25 9.65482 15.9702 9.375 15.625 9.375H10.625V4.375Z"
                  fill="#F9F9F9"
                />
              </svg>

              Create Exercises
            </button>
          </div>
        </div>
        <Table
          setSelectedPrograms={setSelectedPrograms}
          selectedPrograms={selectedPrograms}
          activeTab={activeTab}
          onDelete={handleDeleteProgram}
          data={data}
          showDeleteCheckBox={isShowDeleteCheckbox}
          setEditExercise={setEditExercise}
          setCurrentExercise={setCurrentExercise} 
          setIsOpenExerModal={setIsOpenExerModal}
          />
        {/* <Table activeTab={activeTab} data={data} /> */}
      </div>
      {isOpenExerModal && (
        <CreateExerciseModal
          // selectedDate={selectedDate}
          activeTab={activeTab}
          isOpen={isOpenExerModal}
          isEditExercise={currentExercise}
          onClose={closeExerciseModal}
        />
      )}
    </div>
  );
};

export default ManageExercise;
