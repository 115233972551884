import React from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.css';

const CheckBox = ({ checked, onChange, label, customStyles }) => {
    return (
        <label className={`${styles.container} ${customStyles.container} d-flex gap-2 align-items-center`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className={`${styles.checkbox} ${customStyles.checkbox}`}
            />
            <div className={`${styles.checkmark} ${customStyles.checkmark}`}></div>
            {label && <span className={`${styles.labelText} ${customStyles.label} `}>{label}</span>}
        </label>
    );
};

CheckBox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    customStyles: PropTypes.shape({
        container: PropTypes.string,
        checkbox: PropTypes.string,
        checkmark: PropTypes.string,
        label: PropTypes.string,
    }),
};

export default CheckBox;
