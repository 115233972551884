import React from 'react';
import { Form } from 'react-bootstrap';

const DropDown = ({
  selectedOption,
  options,
  getAtheleteId,
  groupName = '', 
  placeholder = 'Select an option', 
  onChange, 
  isActiveTab,
  style = { width: "22%" }, 
  className = 'basic-single', 
  ariaLabel = "Default select example"
}) => {
  console.log(isActiveTab)

  const handleChange = (event) => {
    console.log(event)
    onChange && onChange(event.target.value);
  
  };

 

  return (
    <Form.Select 
      aria-label={ariaLabel} 
      className={className} 
      style={style} 
      onChange={handleChange}
      value={selectedOption}
      >
        
      {placeholder && <option value="" disabled>{placeholder}</option>}
      {options?.map((option, index) => {

        const match= isActiveTab === "invited" ? getAtheleteId == option.invitedFor ? true: false : getAtheleteId == (option.athleteID || option.id)  ? true : false ;
        const label = isActiveTab === "invited" ? option?.email : option.groupName ? option.groupName : `${option.athleteSubscriptions?.firstName} ${option.athleteSubscriptions?.lastName}`;

        return (
          <option key={index} value={option.athleteID || option.id || option.invitedFor } selected={match}  >
            {label}
          </option>
        );
      })}
    </Form.Select>
  );
};

export default DropDown;
