import React from 'react'
import Layout from '../../Layout'
import Main from './components/Main/Main'
import SecondComp from './components/SecondComp/SecondComp'
import ThirdComp from './components/ThirdComp/ThirdComp'
import FourthComp from './components/FourthComp/FourthComp'
import style from './home.module.css'
import FeedBack from './components/FeedBack/FeedBack'
import UsersComp from './components/UsersComp/UsersComp'

const Home = () => {
  return (
    <Layout>
      <div className={`${style.main}`}>
      <Main/>
      <SecondComp/>
      <ThirdComp/>
      <FourthComp/>
      <FeedBack/>
      <UsersComp/>
      </div>
    </Layout>
  )
}

export default Home