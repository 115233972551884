// WorkingMax.js

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "./workingmax.module.css";
import SplineAreaChart from "../../../Graph/SplineAreaChart";
import ModalUi from "../../../UI/Modals/index";
import axios from "axios";
import { auth } from "../../../../../firebase";
import {
  getGroupPersonalRecords,
  getOneRepMax,
} from "../../../../../services/store/atheleteApi";
import Loader from "../../../../ReusableUi/Loader";

const Section = styled.section`
  margin: 0px 150px;
`;

const ScrollableContainer = styled.div`
  max-height: 600px; /* Adjust based on your design */
  overflow-y: auto;
`;

const WorkingMax = ({
  apiResponse,
  exerciseId,
  blockId,
  apiPersonalRecordResponse,
}) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [getPersonalRecords, setPersonalRecords] = useState([]);
  const [viewOneRepxMax, setviewOneRepxMax] = useState();
  const handleOpenRepModal = () => {
    setOpenModal(!isOpenModal);
  };

  useEffect(() => {
    const fetchPersonalRecord = async () => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getGroupPersonalRecords}?exerciseId=${exerciseId}`,
          { headers: { Authorization: token } }
        );
        console.log(response?.data?.personalRecords, "fetchPersonalRecord");
        setPersonalRecords(response.data.personalRecords);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching personal records:", error);
        setIsLoading(false); // Also stop loading on error
      }
    };

    fetchPersonalRecord();
  }, [blockId]);
  useEffect(() => {
    const fetchOneRepMax = async () => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getOneRepMax}?exerciseId=${exerciseId}`,
          { headers: { Authorization: token } }
        );
        setviewOneRepxMax(response?.data?.data);

        // setPersonalRecords(response.data.personalRecords);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching personal records:", error);
        setIsLoading(false); // Also stop loading on error
      }
    };

    fetchOneRepMax();
  }, [exerciseId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  const repsArray = getPersonalRecords.map(item => item.reps);
  const weightArray = getPersonalRecords.map(item => item.weight);
  
  // Prepare chart data
  const chartData = {
    labels: [0, ...weightArray], // Include 0 at the start
    datasets: [
      {
        label: 'Reps vs Weight',
        data: [0, ...repsArray], // Include 0 for reps to start from zero
        fill: true,
        backgroundColor: 'rgba(0, 0, 0, 0)', // Gradient will be applied dynamically
        borderColor: '#A19686',
        borderWidth: 2, // Thicker line for visibility
        tension: 0.4, // Spline effect
        pointRadius: 4, // Visible points
        pointHitRadius: 10, // Increase interaction radius for points
        pointBackgroundColor: '#A19686', // Point color
      },
    ],
  };
  

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center h-100">
      <Loader />
    </div>
  ) : (
    <Section>
      <ScrollableContainer>
        <div className={styles.workingMaxPersonalRecords}>
          <span>Personal Records</span>
        </div>
        {getPersonalRecords?.map((data, index) => (
          <div key={index} className={styles.workingMaxPersonalRecordsData}>
            <div>
              <span>
                {data.reps}RM @ {data.weight}kg
              </span>
            </div>
            <div>
              <span>{formatDate(data.createdAt)}</span>
            </div>
          </div>
        ))}
        <div
          className={styles.workingMaxPersonalRecords}
          style={{ marginTop: "10px" }}
        >
          <span>Working Max for 1-Rep</span>
        </div>
        <div className={styles.workingMaxforRep} onClick={handleOpenRepModal}>
        <span>
  {viewOneRepxMax?.value 
    ? viewOneRepxMax.value 
    : apiResponse?.oneRepMax 
      ? apiResponse.oneRepMax 
      : 0} Kg
</span>
        </div>
        <div className={styles.workingMaxGraph} style={{ marginTop: "10px" }}>
          <span>Working Max</span>
        </div>
        <div style={{ marginTop: "20px", width: "100%",height : "100vh" }}>
                        <SplineAreaChart data={chartData} />
                    </div>
      </ScrollableContainer>
      {isOpenModal && (
        <ModalUi
          isOpen={isOpenModal}
          onClose={handleOpenRepModal}
          exerciseId={exerciseId}
          valueResponse={apiResponse?.weight}
        />
      )}
    </Section>
  );
};

export default WorkingMax;
