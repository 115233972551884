import React from 'react'
import { Ellipse1, PaymentPic } from '../../../../../assessts/LandingPage'
import style from './thirdcomp.module.css'

const ThirdComp = () => {
    return (
        <div className={`${style.main} d-flex flex-column`}>
            <div className={`${style.belowDiv} align-items-center justify-content-center`}>
                <div className={`${style.imgDIV} d-flex flex-row`}>
                    <div className='d-flex flex-column'>
                        <img src={Ellipse1} className={`${style.ecli}`} />
                        <img src={PaymentPic} className={`${style.mainPic}`} />
                    </div>
                    <img src={Ellipse1} alt="" />
                </div>
                <div className={`${style.text} d-flex flex-column`}>
                    <h1>NO MORE PAYMENT HEADACHES</h1>
                    <p>With TrainingApp, you have complete control over accepting payments from your clients, whether they're participating in online sessions or in-person training. Set and adjust prices according to your preferences, maximising your earning potential.</p>
                </div>
            </div>
        </div>
    )
}

export default ThirdComp
