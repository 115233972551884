import React from 'react'
import style from './three.module.css'
import { BlueStar, Picture1, Picture2, Picture3, Picture4, Picture5, Picture6, Picture7, Picture8, Picture9 } from '../../../../../../assessts/LandingPage'
import Button from '../../../../Components/Button/Button'
import { Link } from 'react-router-dom'

const Three = () => {
  const programData = [
    { img: Picture1, title: 'Program Name 1', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },
    { img: Picture2, title: 'Program Name 2', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },
    { img: Picture3, title: 'Program Name 3', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },
    { img: Picture4, title: 'Program Name 4', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },
    { img: Picture5, title: 'Program Name 5', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },
    { img: Picture6, title: 'Program Name 6', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },
    { img: Picture7, title: 'Program Name 7', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },
    { img: Picture8, title: 'Program Name 8', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },
    { img: Picture9, title: 'Program Name 9', description: 'Take your physique to a new level with a progressive overload, phase based approach that will keep you progressing without the burn out.', rating: BlueStar, ratingCount: '45', button: "Buy Now" },

  ]
  return (
    <div className={`${style.main} d-flex flex-column align-items-center justify-content-center`}>
      <div className={`${style.cardDiv} `}>
        {programData.map((data, key) => (
          <div key={key} className={`${style.card} `}>
            <img src={data.img} alt="Program Picture" />
            <div>
              <h1>{data.title}</h1>
              <p>{data.description}</p>
            </div>
            <div className={`${style.ratings} d-flex`}>
              <img src={BlueStar} alt="Rating Stars" />
              <img src={BlueStar} alt="Rating Stars" />
              <img src={BlueStar} alt="Rating Stars" />
              <img src={BlueStar} alt="Rating Stars" />
              <img src={BlueStar} alt="Rating Stars" />
              <p>({data.ratingCount})</p>
            </div>
            <Link to="/MPprograms">
            <Button bg={true} title={data.button}/>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Three
