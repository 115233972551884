
import React, { useContext, useEffect, useState } from "react";
import "./inviteModal.css";
import { SessionContext } from "../../context/sessionContext";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { auth } from "../../firebase";
import { inviteAssCoach } from "../../apis";
import { toast } from "react-toastify";
import axios from "axios";

const AssistantCoachModal = () => {
    const [loader, setLoader] = useState(false)
    const { isInviteAssitantCoach, setInviteAssitantCoach } = useContext(SessionContext)
    const generateRandomCode = (length) => {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let code = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters.charAt(randomIndex);
        }

        return code;
    };
    const invitationCode = generateRandomCode(4);

    const [formData, setFormData] = useState({
        email: "",
        InvitedBy: auth.currentUser.uid,
        invitationType: "assisCoach",
        amount: "5",
        invitationLink: `${window.location.origin}/invitation/${invitationCode}`,
    }
    )
    const handleCreateAssistantCoach = async () => {
        try {
            setLoader(true)
            const token = await auth.currentUser.getIdToken(true);
            const endpoint = inviteAssCoach;
            const payload = { ...formData };
            await makeApiCall(endpoint, payload, token);
            setInviteAssitantCoach(false);
            setLoader(false);
            toast.success("Assistan Coach Invited Sucessfully");
        } catch (err) {
            setLoader(false);
            setInviteAssitantCoach(false);
            console.error(err);
        }
    }

    /**
 * Makes an API call to the given endpoint with the provided payload and token.
 * @param {string} endpoint - API endpoint
 * @param {Object} payload - Data to send in the API request
 * @param {string} token - Authorization token
 */
    const makeApiCall = async (endpoint, payload, token) => {
        const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
        const config = {
            headers: { Authorization: token }
        };
        await axios.post(url, payload, config);
    }
    // Update the invitation link whenever the amount changes
    useEffect(() => {
        const newLink = `${window.location.origin}/invitation/${invitationCode}?price=${formData.amount || 'defaultPrice'}`;
        setFormData(prevFormData => ({ ...prevFormData, invitationLink: newLink }));
    }, [formData.amount]);

    const handleChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    }
    return (
        <div className={`exercise-modal ${isInviteAssitantCoach ? 'exercise-open' : ''}`}>
            <div className="exercise-overlay" onClick={() => setInviteAssitantCoach(false)}></div>
            <div className="exercisemmodalBody" style={{ width: "35vw" }}>
                <h2 style={{ textTransform: "uppercase" }}>Invite Assistan Coach</h2>
                <Container>
                    <Row className='mb-3'>
                        <Col xs={12} md={12} className='p-0'>
                            <Form.Group controlId='frmB'>
                                <Form.Label className='customInputLabel'> Invitation Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Email of Invitation"
                                    className='customInput'
                                    onChange={(e) => handleChange(e, 'email')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='d-flex justify-content-end gap-4 mt-3' >
                            <Button variant="secondary" className='programSave' onClick={handleCreateAssistantCoach}>Pay & Send</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default AssistantCoachModal;
