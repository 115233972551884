import React from 'react';
import styles from "../index.module.css";
import ProgramIndex from './Program/programIndex';
import SessionIndex from './Session/sessionIndex';
import ExerciseIndex from './Exercise/exerciseIndex';
import CircuitIndex from './Circuit/circuitIndex';
import { Container, Row } from 'react-bootstrap';

const ActiveTab = ({ activeIndex,setSessionHistory }) => {

    return (
        <Container className='px-5'>
            <Row>
                {activeIndex === "program" && <ProgramIndex />}
                {activeIndex === "session" && <SessionIndex />}
                {activeIndex === "exercise" && <ExerciseIndex setSessionHistory={setSessionHistory} />}
                {activeIndex === "circuit" && <CircuitIndex />}
            </Row>
        </Container>

    );
};

export default ActiveTab;
