import athleteImage from "../../../assessts/images/athleteSource.png";


export const athletes = [
   {
      "id": 1,
      "img": athleteImage,
      "name": "Rizwan",
      "program": "Dev Ops",
      "coachName": "Hamza",
      "joined": "12/10/2023",
      "cost": "$2000/Month"
   },
   {
      "id": 2,
      "img": athleteImage,
      "name": "Athlete 2",
      "program": "Program 2",
      "coachName": "Coach 2",
      "joined": "Date 2",
      "cost": "$Amount/Month"
   },
   {
      "id": 3,
      "img": athleteImage,
      "name": "Athlete 3",
      "program": "Program 3",
      "coachName": "Coach 3",
      "joined": "Date 3",
      "cost": "$Amount/Month"
   }
]

export const coach = [
   {
      "id": 1,
      "img": athleteImage,
      "name": "Elon Mask",
      "program": "Makhi Team",
      "assitantCoachName": "HarrySmith",
      "subscription": "12/10/2023",
      "athlete": "3"
   },
   {
      "id": 2,
      "img": athleteImage,
      "name": "Elon Mask",
      "program": "Makhi Team",
      "assitantCoachName": "HarrySmith",
      "subscription": "12/10/2023",
      "athlete": "3"
   },
   {
      "id": 3,
      "img": athleteImage,
      "name": "Elon Mask",
      "program": "Makhi Team",
      "assitantCoachName": "HarrySmith",
      "subscription": "12/10/2023",
      "athlete": "3"
   },
]

export const exercise = [
   {
      "id": 1,
      "title": "Abdullah",
      "url": "https://react.dev/reference/react",
      "instruction": "12 Pull Up",
      "date": "25/08/2023"
   },
   {
      "id": 1,
      "title": "Abdullah",
      "url": "https://react.dev/reference/react",
      "instruction": "12 Pull Up",
      "date": "25/08/2023"
   },
   {
      "id": 1,
      "title": "Abdullah",
      "url": "https://react.dev/reference/react",
      "instruction": "12 Pull Up",
      "date": "25/08/2023"
   },
]


export const marketPlace = [
   {
      "id": 1,
      "img": athleteImage,
      "coach": "Rizwan",
      "fee": "$99",
      "level": "Beigner",
   },
   {
      "id": 1,
      "img": athleteImage,
      "coach": "Rizwan",
      "fee": "$99",
      "level": "Beigner",
   },
   {
      "id": 1,
      "img": athleteImage,
      "coach": "Rizwan",
      "fee": "$99",
      "level": "Beigner",
   }
]




export const historyData = [
   { date: 'Wednesday September 21, 2023', duration: '4 x 120:00 m:s' },
   { date: 'Thursday September 21, 2023', duration: '1 x 120:00 m:s' },
   { date: 'Friday September 21, 2023', duration: '1 x 120:00 m:s' },
   { date: 'Saturday September 21, 2023', duration: '1 x 120:00 m:s' },
   { date: 'Sunday September 21, 2023', duration: '1 x 120:00 m:s' },
]