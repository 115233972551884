import React, { useEffect, useState } from "react";
import "./inviteModal.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { auth } from "../../firebase";
import { inviteAthelete } from "../../apis";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingSpinner from "../loadingSpinner";

const CoachAtheleteModal = ({  isOpen, onClose }) => {
  const [loader, setLoader] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const generateRandomCode = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    return code;
  };
  const invitationCode = generateRandomCode(4);
  const [formData, setFormData] = useState({
    creatorEmail: auth.currentUser.email,
    invitedBy: auth.currentUser.uid,
    email: "",
    amount: "",
    invitationLink: `${window.location.origin}/invitation/${invitationCode}`,
    invitationType: "one-to-one",
  });

  // Update the invitation link whenever the amount changes
  useEffect(() => {
    const newLink = `${window.location.origin}/invitation/?invitationType=${
      formData.invitationType
    }&invitationCode=${invitationCode}&price=${
      formData.amount || "defaultPrice"
    }&productName=program&subscriptionType=forthnightly`;
    setFormData((prevFormData) => ({
      ...prevFormData,
      invitationLink: newLink,
      invitationCode,
    }));
  }, [formData.amount]);

  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
    setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };
  const validateFormData = (data) => {
    const errors = {};
    if (!data.email) errors.email = "Email is Requried";
    if (!data.amount) errors.amount = "Amount is required";
    return errors;
  };
  const handleCreateAthelete = async () => {
    
    try {
      setLoader(true);
      const token = await auth.currentUser.getIdToken(true);
      const endpoint = inviteAthelete;
      const payload = { ...formData };

      const validationErrors = validateFormData(formData);

      // Update state with validation errors
      setValidationErrors(validationErrors);

      // Check for any validation errors
      if (Object.keys(validationErrors).length > 0) {
        toast.error("Please fill in all mandatory fields.");
        return;
      }
      setIsLoading(true)
      await makeApiCall(endpoint, payload, token);
      onClose();
      setLoader(false);
      
      toast.success("Athelete Invited Sucessfully");
    } catch (err) {
        setIsLoading(true)
      setLoader(false);
      onClose();
      console.error(err);
    }
  };

  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token },
    };

    await axios.post(url, payload, config);
  };

  return (
    <div   className={`exercise-modal ${isOpen ? "exercise-open" : ""}`}>
      <div  className="exercise-overlay" onClick={onClose}></div>
      <div  className="exercisemmodalBody" style={{ width: "30vw",backgroundColor : `white` }}>
        <h2 style={{ textTransform: "uppercase",color : `black` }}>Invite Athelete</h2>
        <Container>
          <Row className="mb-3">
            <Col xs={12} md={12} className="p-0">
              <Form.Group controlId="frmB">
                <Form.Label  style={{color : `black`}} className="customInputLabel">
                  {" "}
                  Invitation Email
                </Form.Label>
                <Form.Control
                  value={formData.email}
                  type="text"
                  placeholder="Enter Email of Invitation"
                  className="customInput"
                  style={{backgroundColor : `#F9F9F9`,color : `black`}}
                  onChange={(e) => handleInputChange(e, "email")}
                />
                {validationErrors.email && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {validationErrors.email}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={12} className="p-0 mt-3">
              <Form.Group controlId="frmB">
                <Form.Label className="customInputLabel" style={{color : `black`}} > Pay</Form.Label>
                <Form.Control
                  value={formData.amount}
                  type="text"
                  style={{backgroundColor : `#F9F9F9`,color : `black`}}
                  placeholder="Enter Payment"
                  className="customInput"
                  onChange={(e) => handleInputChange(e, "amount")}
                />
                {validationErrors.amount && (
                  <p style={{ color: "red", marginTop: "5px" }}>
                    {validationErrors.amount}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="d-flex justify-content-end mt-3">
              <button
              style={{backgroundColor:`var(--secondary-color,#A19686)`,color : `var(--secondary-text-color,white)`,height : "48px",width : "120px", borderRadius : "8px",border : "none",fontSize : "19px" , fontWeight: "600" , lineHeight: "24px"}}
                // variant="secondary"
                // className="programSave"
                onClick={handleCreateAthelete}
              >
                {isLoading ? <LoadingSpinner size="sm" /> : "Send"}
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CoachAtheleteModal;
