import React, { useContext } from "react";
import { ThemeContext } from "../../../../context/themeContext";
import SessionForm from "../../../forms/sessionForm/sessionForm";
import ExerciseTypes from "./exerciseTypes";
import "./previewSession.css";
import PreviewSessionDate from "./previewSessionDate";
const PreviewSession = () => {
  const { activeTab, activeComponent } = useContext(ThemeContext);

  return (
    <div className="previewContainer">
      {activeTab !== 'index' && <PreviewSessionDate/>}
      <div>
        <div className="mt-5">
          <h5 className="SessionPreview">Session Preview</h5>
          <div className="preHeader">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M9.27967 4.14454L5.53167 0.379543C5.24967 0.0965433 4.75067 0.0965433 4.46767 0.379543L0.71967 4.14454C0.42767 4.43854 0.428671 4.91354 0.722671 5.20554C1.01667 5.49754 1.49067 5.49754 1.78367 5.20354L4.25067 2.72654L4.25067 9.08154C4.25067 9.49654 4.58667 9.83154 5.00067 9.83154C5.41467 9.83154 5.75067 9.49654 5.75067 9.08154L5.75067 2.72654L8.21667 5.20354C8.36367 5.35054 8.55567 5.42354 8.74867 5.42354C8.93967 5.42354 9.13167 5.35054 9.27767 5.20554C9.57067 4.91354 9.57167 4.43854 9.27967 4.14454Z"
                fill="#1AA053"
              />
            </svg>
            <p>Thursday, 3,2023</p>
          </div>
        </div>
      </div>
      <ExerciseTypes/>
      <SessionForm/>
    </div>
  );
};

export default PreviewSession;
