import React, { useEffect, useState } from "react";
import "./manageAthelete.css";
import { FaSearch } from "react-icons/fa";
import InviteAthleteModal from "../../modals/inviteAtheleteModal";
import Table from "../../Table/Table";
import { athletes } from '../jsonData/atheletes';
import { auth } from "../../../firebase";
import axios from "axios";
import { deleteAthelete, getAthelete } from "../../../services/store/adminApi";
import { toast } from "react-toastify";
import { getIdTokenResult } from "firebase/auth";


const ManageAthelete = (props) => {

  const { activeTab } = props;
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isShowDeleteCheckbox, setShowDeleteCheckbox] = useState(false)
  const [selectedPrograms, setSelectedPrograms] = useState("");


  const closeInviteModal = () => {
    setInviteModalOpen(false);
  };

  auth.onAuthStateChanged(user => {
    if (user) {
      getIdTokenResult(user).then(idTokenResult => {
        // Check if user has admin custom claim
        if (!!idTokenResult.claims.isAdmin) {
          console.log('User is an admin');
        } else {
          console.log('User is not an admin');
        }
      });
    }
  });

  const fetchAtheleteGroup = async () => {

    try {
      const token = await auth.currentUser.getIdToken(true);
      // const roleCheck = auth.verifyIdToken(token)
      // console.log(roleCheck)
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getAthelete}?currentPage=1&itemsPerPage=4`,
        { headers: { Authorization: token } }
      );
      setData(response?.data?.athletes)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchAtheleteGroup();
  }, []);

  const handleDeleteProgram = async () => {
    setShowDeleteCheckbox(!isShowDeleteCheckbox)
    try {
      const token = await auth.currentUser.getIdToken(true);


      await axios.patch(`${process.env.REACT_APP_Baseurl}${deleteAthelete}?athleteID=${selectedPrograms}`, { headers: { Authorization: token }});
      // After deletion, you might want to refresh the table data
      setSelectedPrograms([]);
      await fetchAtheleteGroup();
      toast.success("Athelete Deleted successfully");
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  return (
    <div style={{ height: "auto" }} className="athltContainer">
      {!show && <div className="athltHeader">Atheletes</div>}
      <div
        className={!show && "athbody"}
        style={{
          padding: !show && "2rem",
        }}
      >
        {!show && (
          <div className="athlt2ndHeader">
            <div>
              <div className="search-container">
                <span className="search-icon">
                  <FaSearch />
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input"
                />
              </div>
            </div>
            <div className="athltbuttons">
              <button className="delete-button" onClick={handleDeleteProgram}>
                <span className="delete-icon">
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Trash-r">
                      <g id="Vector">
                        <path d="M17.5527 3.75H3.80273C3.45756 3.75 3.17773 4.02982 3.17773 4.375C3.17773 4.72018 3.45756 5 3.80273 5H17.5527C17.8979 5 18.1777 4.72018 18.1777 4.375C18.1777 4.02982 17.8979 3.75 17.5527 3.75Z" fill="#A19686" />
                        <path d="M8.17773 8.125V13.125C8.17773 13.4702 8.45756 13.75 8.80273 13.75C9.14791 13.75 9.42773 13.4702 9.42773 13.125V8.125C9.42773 7.77982 9.14791 7.5 8.80273 7.5C8.45756 7.5 8.17773 7.77982 8.17773 8.125Z" fill="#A19686" />
                        <path d="M11.9277 8.125V13.125C11.9277 13.4702 12.2076 13.75 12.5527 13.75C12.8979 13.75 13.1777 13.4702 13.1777 13.125V8.125C13.1777 7.77982 12.8979 7.5 12.5527 7.5C12.2076 7.5 11.9277 7.77982 11.9277 8.125Z" fill="#A19686" />
                        <path d="M5.67773 16.25V4.375C5.67773 4.02982 5.39791 3.75 5.05273 3.75C4.70756 3.75 4.42773 4.02982 4.42773 4.375V16.25C4.42773 16.7678 4.79385 17.1339 4.79385 17.1339C5.15997 17.5 5.67773 17.5 5.67773 17.5H15.6777C16.1955 17.5 16.5616 17.1339 16.5616 17.1339C16.9277 16.7678 16.9277 16.25 16.9277 16.25V4.375C16.9277 4.02982 16.6479 3.75 16.3027 3.75C15.9576 3.75 15.6777 4.02982 15.6777 4.375V16.25H5.67773Z" fill="#A19686" />
                        <path d="M7.47691 1.79917C6.92773 2.34835 6.92773 3.125 6.92773 3.125V4.375C6.92773 4.72018 7.20756 5 7.55273 5C7.89791 5 8.17773 4.72018 8.17773 4.375V3.125C8.17773 2.86612 8.36079 2.68306 8.36079 2.68306C8.54385 2.5 8.80273 2.5 8.80273 2.5H12.5527C12.8116 2.5 12.9947 2.68306 12.9947 2.68306C13.1777 2.86612 13.1777 3.125 13.1777 3.125V4.375C13.1777 4.72018 13.4576 5 13.8027 5C14.1479 5 14.4277 4.72018 14.4277 4.375V3.125C14.4277 2.34835 13.8786 1.79917 13.8786 1.79917C13.3294 1.25 12.5527 1.25 12.5527 1.25H8.80273C8.02608 1.25 7.47691 1.79917 7.47691 1.79917Z" fill="#A19686" />
                      </g>
                    </g>
                  </svg>
                </span>
                Delete
              </button>
            </div>
          </div>
        )}
        {!show &&
          <Table
            setShow={setShow}
            activeTab={activeTab}
            data={data}
            onDelete={handleDeleteProgram}
            showDeleteCheckBox={isShowDeleteCheckbox}
            setSelectedPrograms={setSelectedPrograms}
            selectedPrograms={selectedPrograms}
             />}
      </div>
      {isInviteModalOpen && (
        <InviteAthleteModal
          isOpen={isInviteModalOpen}
          onClose={closeInviteModal}
        />
      )}
    </div>
  );
};

export default ManageAthelete;
