import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import SvgIcon from "../../../../../assessts/Svg/svgIcons";
import {
  createGroupSession,
  EditGroupSession,
  getAllExerciseList,
} from "../../../../../apis";
import { auth } from "../../../../../firebase";
import { toast } from "react-toastify";
import CreateGroupSessionBlock from "./GroupSessionBlock/groupSessionBlock";
import LoadingSpinner from "../../../../loadingSpinner";

/**
 * Component for creating or editing training sessions.
 * It allows a coach to input session details and manage session blocks dynamically.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.onClose - Function to call on closing the form.
 */

const GroupSessionForm = ({
  onClose,
  selectedDate,
  groupDetails,
  SessionData,
  IsEdit,
}) => {
  const [exerciseList, setExerciseList] = useState([]);
  const [isActiveLoader, setActiveLoader] = useState(false);
  const [sessionBlocks, setSessionBlocks] = useState([
    {
      title: "",
      selectedExercise: null,
      instructions: "",
      videoURL: "",
      blocktitle: "",
      Label: "",
      connected : false,
      sets: [
        { title1: "", title2: "", reps: "", weight: "" },
        { title1: "", title2: "", reps: "", weight: "" },
        { title1: "", title2: "", reps: "", weight: "" },
      ],
      supersetGroup: "A",
      supersetOrder: 0,
      connected: false, 
    },
]);

  console.log(sessionBlocks,"sessionBlocks")
  const coachId = auth.currentUser.uid;
  const [sessionFormData, setSessionFormData] = useState({
    title: SessionData?.title || "",
    instructions: SessionData?.instructions || "",
    addedByAthlete: false,
    groupSharing: true,
    oneToOne: false,
    coachId: coachId,
    athleteId: null,
    groupId: groupDetails,
    date: selectedDate,
    leaderboard: {
      scoreType: "Weight",
      customCalculations: "More is better",
      numberOfIntervals: "4",
      leaderboardCalculations: "More is better",
    },
  });
  useEffect(() => {
    fetchExerciseList();
  }, []);
  console.log(SessionData,"SessionData")
  useEffect(() => {
    if (SessionData && Array.isArray(SessionData.groupBlocks)) {
        const formattedBlocks = SessionData.groupBlocks.map((block) => {
            const selectedExercise = block.groupsExercises || null;
            const exerciseTitle = selectedExercise ? selectedExercise.title : "Unknown Exercise";
            const exerciseId = selectedExercise ? selectedExercise.id : null;

            return {
                id: block.id,
                blocktitle: block.blocktitle || "", 
                selectedExercise: {
                    title: exerciseTitle,
                    id: exerciseId,
                },
                instructions: selectedExercise?.instructions || "", 
                videoURL: selectedExercise?.URL || "",
                sets: Array.isArray(block.groupBlockSets) ? block.groupBlockSets.map((set) => ({
                    id: set.id,
                    title1: set.title1 || "Untitled", // Default title
                    title2: set.title2 || "Untitled", // Default title
                    reps: set.value1 || "0", // Default value
                    weight: set.value2 || "0", // Default value
                })) : [],
                supersetGroup: block.supersetGroup || "A", 
                supersetOrder: block.supersetOrder || 0,
            };
        });

        // After processing all blocks, set the state
        console.log("Formatted Blocks:", formattedBlocks); // Log the formatted blocks before setting state
        setSessionBlocks(formattedBlocks);
    } else {
        console.error("Invalid SessionData format", SessionData); // Log error
        // Set default state if SessionData is invalid
        setSessionBlocks([{
            blocktitle: "",
            selectedExercise: null,
            instructions: "",
            videoURL: "",
            connected : false,
            sets: [
                { title1: "", title2: "", reps: "", weight: "" },
                { title1: "", title2: "", reps: "", weight: "" },
                { title1: "", title2: "", reps: "", weight: "" },
            ],
            supersetGroup: "A",
            supersetOrder: 0,
        }]);
    }
}, [SessionData]);


;
  const fetchExerciseList = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getAllExerciseList}?coachId=${coachId}`,
          { headers: { Authorization: token } }
        );
        setExerciseList(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  /**
   * Handles form submission for creating or editing a session.
   */
  const handleSaveSession = async () => {
    try {
      setActiveLoader(true);

      // Get authorization token
      const token = await auth.currentUser.getIdToken(true);

      // Determine the API endpoint and payload based on whether it's a new program or an edit

      const endpoint = IsEdit ? EditGroupSession : createGroupSession;
      const payload = IsEdit
        ? {
            ...sessionFormData,
            blocks: sessionBlocks.map((block) => ({
              blockId: block.id, // Use block ID for edit case
              exerciseTitle: block.selectedExercise
                ? block.selectedExercise.title
                : "",
              exerciseURL: block.videoURL || "",
              exerciseInstructions: block.instructions || "",
              exerciseID: block.selectedExercise
                ? block.selectedExercise.id
                : null,
              exerciseCategory: block.selectedExercise
                ? block.selectedExercise.category || ""
                : "",
                blocktitle: block.blocktitle,
              sets: block.sets.map((set) => ({
                setId: set.id, 
                title1: set.title1,
                title2: set.title2, 
                value1: set.reps || "0", 
                value2: set.weight || "0",
              })),
              supersetGroup: block.supersetGroup,
              supersetOrder: block.supersetOrder,
            })),
          }
        : {
            ...sessionFormData,
            blocks: sessionBlocks.map((block) => ({
              exerciseTitle: block.selectedExercise
                ? block.selectedExercise.title
                : "",
              exerciseURL: block.videoURL || "",
              exerciseInstructions: block.instructions || "",
              // exerciseID: block.exerciseId
              //   ? block.exerciseId
              //   : null,
              exerciseID: block.selectedExercise
                ? block.selectedExercise.id
                : null,
              exerciseCategory: block.selectedExercise
                ? block.selectedExercise.category || ""
                : "",
              blocktitle: block.blocktitle,
              sets: block.sets.map((set) => ({
                title1: set.title1,
                title2: set.title2,
                value1: set.reps || "0",
                value2: set.weight || "0",
              })),
              supersetGroup: block.supersetGroup,
              supersetOrder: block.supersetOrder,
            })),
          };

      // Make the API call
      await makeApiCall(endpoint, payload, token);
      setActiveLoader(false);
      onClose();
      // Show success message
      toast.success("Session Created Successfully");
    } catch (error) {
      // Handle errors
      setActiveLoader(false);
      toast.error("An error occurred while saving the Session");
    }
  };

  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;

    const config = {
      headers: { Authorization: token },
    };

    if (IsEdit) {
      config.params = { sessionId: SessionData?.id };
    }
    if (IsEdit) {
      await axios.patch(url, payload, config);
    } else {
      // For creation (post request)
      console.log("url", url);
      console.log("payload", payload);
      console.log("config", config);
      await axios.post(url, payload, config);
    }
  };

  const handleInstructionsChange = (blockIndex, newInstructions) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].instructions = newInstructions;
    setSessionBlocks(updatedBlocks);
  };

  const handleURLChange = (blockIndex, newURL) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].videoURL = newURL;
    setSessionBlocks(updatedBlocks);
  };
  const handleBlockTitle = (blockIndex, newtitle) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].blocktitle = newtitle;
    setSessionBlocks(updatedBlocks);
  };

  // Handles changes in session title and instructions input fields.
  const handleInputSessionChange = (e, field) => {
    setSessionFormData({ ...sessionFormData, [field]: e.target.value });
  };

  const handleRemoveSet = (blockIndex) => {
    const newBlocks = [...sessionBlocks]; // Create a shallow copy of the current sessionBlocks

    // Get the number of sets in the current block
    const numSets = newBlocks[blockIndex].sets.length;

    // If there are any sets, remove the last one
    if (numSets > 0) {
      newBlocks[blockIndex].sets.splice(numSets - 1, 1); // Remove the last set
      setSessionBlocks(newBlocks); // Update the state with the modified blocks
    }
  };

  // Adds a new set to a session block.
  const handleAddSet = (blockIndex) => {
    const newBlocks = [...sessionBlocks];
    newBlocks[blockIndex].sets.push({ reps: "", weight: "" });
    setSessionBlocks(newBlocks);
  };

  // Updates the details of a specific set within a block.
  const handleSetChange = (blockIndex, setIndex, key, value) => {
    const newBlocks = [...sessionBlocks];

    // For the first 3 inputs, mirror the value across all of them
    if (setIndex < 3) {
      newBlocks[blockIndex].sets.forEach((set, idx) => {
        if (idx < 3) {
          set[key] = value;
        }
      });
    } else {
      newBlocks[blockIndex].sets[setIndex][key] = value;
    }

    setSessionBlocks(newBlocks);
  };

  // Manages the selection of an exercise and updates block data accordingly.
  const handleExerciseSelectionChange = (blockIndex, exerciseTitle, exerciseId) => {
 
    const newBlocks = [...sessionBlocks];
    const selectedExerciseData = exerciseList.find(
      (exercise) => exercise.title === exerciseTitle
    );
  
    if (selectedExerciseData) {
      newBlocks[blockIndex] = {
        ...newBlocks[blockIndex], 
        selectedExercise: selectedExerciseData, 
        instructions: selectedExerciseData.instructions,
        videoURL: selectedExerciseData.URL,
        sets: newBlocks[blockIndex].sets.map(set => ({
          ...set,
          title1: selectedExerciseData.title1 || "", 
          title2: selectedExerciseData.title2 || "", 
        })),
      };
    } else {
      console.error("Exercise not found for title:", exerciseTitle);
    }
    setSessionBlocks(newBlocks);
  };
  

  const getNextGroup = () => {
    const currentGroups = sessionBlocks.map((block) => block.supersetGroup);
    const allSameGroup = currentGroups.every(
      (group) => group === currentGroups[0]
    );

    if (allSameGroup) {
      const lastGroup = currentGroups[0]; 
      return String.fromCharCode(lastGroup?.charCodeAt(0) + 1); 
    } else {
      const uniqueGroups = [...new Set(currentGroups)];
      return uniqueGroups.length > 0
        ? String.fromCharCode(
            uniqueGroups[uniqueGroups.length - 1]?.charCodeAt(0) + 1
          )
        : "A";
    }
  };

  const addSessionBlock = () => {
    const nextGroup = getNextGroup();

    // Add a new block with the determined supersetGroup
    setSessionBlocks((prevBlocks) => [
      ...prevBlocks,
      {
        title: "",
        selectedExercise: null,
        instructions: "",
        videoURL: "",
        blocktitle: "",
        Label: "",
        connected  :false,
        sets: [
          { title1: "", title2: "", reps: "", weight: "" },
          { title1: "", title2: "", reps: "", weight: "" },
          { title1: "", title2: "", reps: "", weight: "" },
        ],
        supersetGroup: nextGroup,
        supersetOrder: 0,
      },
    ]);
  };

  // Removes a specified session block.
  const removeSessionBlock = (blockIndex) => {
    setSessionBlocks((currentBlocks) =>
      currentBlocks.filter((_, index) => index !== blockIndex)
    );
  };

  return (
    <Container className="m-0 p-0">
      <Row className="m-0 p-0">
        <Col sm={12} md={12} lg={12} className="m-0 p-0">
          <Form id="as">
            <Form.Group className="mt-3 mb-3" controlId="formBasic">
              <Form.Label
                style={{
                  fontSize: "16px",
                  color: `var(--primary-text-color,black)`,
                }}
              >
                Session Title
              </Form.Label>
              <Form.Control
                style={{
                  backgroundColor: `var(--secondary-bg-color,#fafafa);)`,
                }}
                type="text"
                name="sessionTitle"
                placeholder="Session Title"
                value={sessionFormData.title}
                onChange={(e) => handleInputSessionChange(e, "title")}
              />
            </Form.Group>
            <Form.Group controlId="formBasic">
              <Form.Label
                style={{
                  fontSize: "16px",
                  color: `var(--primary-text-color,black)`,
                }}
              >
                Coach
              </Form.Label>
              <Form.Control
                style={{
                  backgroundColor: `var(--secondary-bg-color,#fafafa);)`,
                }}
                as="textarea"
                rows={5}
                name="coach"
                placeholder="Session Instruction"
                value={sessionFormData.instructions}
                onChange={(e) => handleInputSessionChange(e, "instructions")}
              />
              {sessionBlocks?.map((block, index) => (
                <CreateGroupSessionBlock

                SessionData={SessionData}
                  getNextGroup={getNextGroup}
                  sessionBlocks={sessionBlocks}
                  setSessionBlocks={setSessionBlocks}
                  fetchExerciseList={fetchExerciseList}
                  onClose={onClose}
                  handleRemoveSet={handleRemoveSet}
                  key={index}
                  blockIndex={index}
                  blockData={block}
                  handleAddSet={handleAddSet}
                  handleSetChange={handleSetChange}
                  exerciseList={exerciseList}
                  removeBlock={removeSessionBlock}
                  handleExerciseSelectionChange={handleExerciseSelectionChange}
                  handleInstructionsChange={handleInstructionsChange}
                  handleURLChange={handleURLChange}
                  handleBlockTitle={handleBlockTitle}
                />
              ))}

              <div className="sessionBtm mt-4" style={{ textAlign: "end" }}>
                <div className="pluscircle" style={{ marginLeft: "85px" }}>
                  <SvgIcon
                    iconName="plusButton"
                    onClick={addSessionBlock}
                    size={16}
                  />
                </div>
                <div className="lastButtons mb-0">
                  <Button
                    style={{
                      backgroundColor: `var(--primary-color,#A19686)`,
                      color: `var(--primary-text-color,white)`,
                      height: "48px",
                      width: "120px",
                      borderRadius: "8px",
                      border: "none",
                      fontSize: "19px",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      backgroundColor: `var(--secondary-color,#A19686)`,
                      color: `var(--secondary-text-color,white)`,
                      height: "48px",
                      width: "120px",
                      borderRadius: "8px",
                      border: "none",
                      fontSize: "19px",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                    onClick={handleSaveSession}
                  >
                    {isActiveLoader ? (
                      <LoadingSpinner size="sm" />
                    ) : IsEdit ? (
                      "Updated"
                    ) : (
                      "Create"
                    )}
                  </Button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default GroupSessionForm;
