// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonUi {
    width: 150px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: red;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    background: #e62222;
   }
   
   .buttonUi, .buttonUi span {
    transition: 200ms;
   }
   
   .buttonUi .text {
    transform: translateX(35px);
    color: white;
    font-weight: bold;
   }
   
   .buttonUi .icon {
    position: absolute;
    border-left: 1px solid #c41b1b;
    transform: translateX(110px);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
   }
   
   .buttonUi svg {
    width: 15px;
    fill: #eee;
   }
   
   .buttonUi:hover {
    background: #ff3636;
   }
   
   .buttonUi:hover .text {
    color: transparent;
   }
   
   .buttonUi:hover .icon {
    width: 150px;
    border-left: none;
    transform: translateX(0);
   }
   
   .buttonUi:focus {
    outline: none;
   }
   
   .buttonUi:active .icon svg {
    transform: scale(0.8);
   }`, "",{"version":3,"sources":["webpack://./src/components/modules/Modal/button.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,mBAAmB;GACpB;;GAEA;IACC,iBAAiB;GAClB;;GAEA;IACC,2BAA2B;IAC3B,YAAY;IACZ,iBAAiB;GAClB;;GAEA;IACC,kBAAkB;IAClB,8BAA8B;IAC9B,4BAA4B;IAC5B,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;GACxB;;GAEA;IACC,WAAW;IACX,UAAU;GACX;;GAEA;IACC,mBAAmB;GACpB;;GAEA;IACC,kBAAkB;GACnB;;GAEA;IACC,YAAY;IACZ,iBAAiB;IACjB,wBAAwB;GACzB;;GAEA;IACC,aAAa;GACd;;GAEA;IACC,qBAAqB;GACtB","sourcesContent":[".buttonUi {\r\n    width: 150px;\r\n    height: 50px;\r\n    cursor: pointer;\r\n    display: flex;\r\n    align-items: center;\r\n    background: red;\r\n    border: none;\r\n    border-radius: 5px;\r\n    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);\r\n    background: #e62222;\r\n   }\r\n   \r\n   .buttonUi, .buttonUi span {\r\n    transition: 200ms;\r\n   }\r\n   \r\n   .buttonUi .text {\r\n    transform: translateX(35px);\r\n    color: white;\r\n    font-weight: bold;\r\n   }\r\n   \r\n   .buttonUi .icon {\r\n    position: absolute;\r\n    border-left: 1px solid #c41b1b;\r\n    transform: translateX(110px);\r\n    height: 40px;\r\n    width: 40px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n   }\r\n   \r\n   .buttonUi svg {\r\n    width: 15px;\r\n    fill: #eee;\r\n   }\r\n   \r\n   .buttonUi:hover {\r\n    background: #ff3636;\r\n   }\r\n   \r\n   .buttonUi:hover .text {\r\n    color: transparent;\r\n   }\r\n   \r\n   .buttonUi:hover .icon {\r\n    width: 150px;\r\n    border-left: none;\r\n    transform: translateX(0);\r\n   }\r\n   \r\n   .buttonUi:focus {\r\n    outline: none;\r\n   }\r\n   \r\n   .buttonUi:active .icon svg {\r\n    transform: scale(0.8);\r\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
