import React from 'react'
import Layout from '../../Layout'
import Main from './components/Main/Main'
import Sec from './components/Sec/Sec'
import Third from './components/Third/Third'
import Four from './components/Four/Four'
import Five from './components/Five/Five'
import Six from './components/Six/Six'

const Athlete = () => {
  return (
    <Layout>
      <div>
      <Main/>
      <Sec/>  
      <Third/>
      <Four/>
      <Five/>
      <Six/>
      </div>
    </Layout>
  )
}

export default Athlete
