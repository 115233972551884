import React, { useContext } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../context/themeContext";
import ManageAthelete from "../../components/admincomponents/manageAthelete/manageAthelete";
import ManageCoach from "../../components/admincomponents/manageCoach/manageCoach";
import ManageExercise from "../../components/admincomponents/manageExercise/manageExercise";
import ManageBusiness from "../../components/admincomponents/manageBusiness/ManageBusiness";
import ManageMarketplace from "../../components/admincomponents/manageMarketplace/manageMarketplace";

const StyledDiv = styled.div`
  display: flex;
  border-radius: 10px;
  margin: 2rem;
  @media (max-width: 600px) {
    margin: 0;
  }
`;
const AdminPanel = () => {
  const location = useLocation();
  const { activeTab, activeComponent } = useContext(ThemeContext);

  return (
    <StyledDiv>
     {activeTab === "manageAthelete" && <ManageAthelete activeTab={activeTab} /> }
     {activeTab === "manageCoach" && <ManageCoach activeTab={activeTab} /> }
     {activeTab === "manageExercise" && <ManageExercise activeTab={activeTab} /> }
     {activeTab === "manageBusiness" && <ManageBusiness activeTab={activeTab} /> }
     {activeTab === "manageMarketplace" && <ManageMarketplace activeTab={activeTab} /> }
    </StyledDiv>
  );
};

export default AdminPanel;
