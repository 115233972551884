import React, { useContext } from 'react'
import styled from 'styled-components';
import Program from "../../../../../../assessts/images/indexCard.svg"
import styles from "../../../index.module.css"
import { SessionContext } from '../../../../../../context/sessionContext';


const Header = styled.header`
display: flex;
`;
const Section = styled.section`
margin-top: 50px;
`;
const SectionHeader = styled.section`
display: flex;
justify-content:space-between;
& > div:first-child > div:first-child {
  width: 50px;
  height: 50px;
  border-radius: var(--8, 8px);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
& > img {
  width: 100%;
  height: 100%;
}
}
`;
const SectionArea = styled.section`
margin-top: 50px;
width: 25vw;
display: grid;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 20px;
border-radius: var(--8, 8px);
border: 1px solid var(--Brand-Primary, #F6F6F0);
`;



const SessionDetails = () => {
    const { handleSessionDetail } = useContext(SessionContext)
    return (
        <div className={styles.programDetails}>
            <Header>
                <div style={{ cursor: "pointer" }} onClick={handleSessionDetail}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="21" viewBox="0 0 12 21" fill="none">
                        <path d="M10 19.2855L2 10.4328L10 1.58008" stroke="#100837" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div style={{ cursor: "pointer" }} onClick={handleSessionDetail}>
                    <span className={styles.programDetailTitleProgram}>
                        {"Index"}
                    </span>
                </div>
            </Header>
            <Section>
                <SectionHeader>
                    <div className='d-flex'>
                        <div>
                            <img src={Program} />
                        </div>
                        <div className={styles.programTitle} style={{ paddingLeft: "15px", width: "261", height: "50px", alignItems: "center", display: "flex"}}>{"Session A"}</div>
                    </div>
                    <div>
                        <div className='d-grid gap-2 text-end'>
                            <span className={styles.groupName} style={ {height: "50px", alignItems: "center", display: "flex"}}>Maki</span>
                        </div>
                    </div>
                </SectionHeader>
                <div className={styles.programDetailsDescription}>
                    <span>Just like you did for the other exercises in the last session, today you're just trying to find a good working weight and get better at the movement so you can build on this foundation  just trying to find a good working weight and get better at the movement so you can build on this foundation</span>
                </div>
                <SectionArea>
                    <div className={styles.programDetailSessionTitle}>
                        <span>Week 1</span>
                    </div>
                    <div className="child2innerDiv" style={{ marginBottom: "0px" }}>
                        <div className="cirlce">A</div>
                        <div>
                            <p id="as" style={{ margin: 0, padding: 0 }}>Bench Press</p>
                            <p id="as" style={{ margin: 0, padding: 0 }}> 1 x 120:00 m:s</p>
                        </div>
                    </div>
                    <div className="child2innerDiv" style={{ marginBottom: "0px" }}>
                        <div className="cirlce">A</div>
                        <div>
                            <p id="as" style={{ margin: 0, padding: 0 }}>Bench Press</p>
                            <p id="as" style={{ margin: 0, padding: 0 }}> 1 x 120:00 m:s</p>
                        </div>
                    </div>
                    <div className="child2innerDiv" style={{ marginBottom: "0px" }}>
                        <div className="cirlce">A</div>
                        <div>
                            <p id="as" style={{ margin: 0, padding: 0 }}>Bench Press</p>
                            <p id="as" style={{ margin: 0, padding: 0 }}> 1 x 120:00 m:s</p>
                        </div>
                    </div>
                    <div className="child2innerDiv" style={{ marginBottom: "0px" }}>
                        <div className="cirlce">A</div>
                        <div>
                            <p id="as" style={{ margin: 0, padding: 0 }}>Bench Press</p>
                            <p id="as" style={{ margin: 0, padding: 0 }}> 1 x 120:00 m:s</p>
                        </div>
                    </div>
                </SectionArea>
            </Section>
        </div>
    )
}

export default SessionDetails