import React, { useEffect, useState } from "react";
import "./indexcalendar.css"; // Create a CSS file for styling
import IndexSessionModal from "../../Common/Form/ProgramSession/indexProgramSession";
import { getProgramSession } from "../../../services/store/coachApi";
import axios from "axios";
import { auth } from "../../../firebase";

const IndexCalendar = ({ currentProgram, setShowCalendar }) => {

  const [selectedWeekNumber, setSelectedWeekNumber] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [hoveredDay, setHoveredDay] = useState(null);
  const [getProgramSessions, setProgramSession] = useState([])


  const closeSessionModal = () => {
    setIsOpen(false);
  };

  const fetchProgramSession = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getProgramSession}?programId=${currentProgram?.id}`,
        { headers: { Authorization: token } }
      );

      setProgramSession(response.data.sessions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchProgramSession()
  }, [])

  const generateWeeks = (totalWeeks) => {
    const weeks = [];
    for (let i = 1; i <= totalWeeks; i++) {
      weeks.push(i);
    }
    return weeks;
  };
  const [hoverCol, setHoverCol] = useState(false);

  const WeekRows = () => {
    const weeks = generateWeeks(currentProgram.weeks);

    // Dummy session data
    const dummySessions = [
      {
        week: 1,
        day: 1,
        title: "Hiking",
        description: "use this area for use this area for session",
        exercise: "Hiking",
        time: "1 x 120:00 m:s",
        detail: "use this area for Exercises",
      },
      {
        week: 1,
        day: 3,
        title: "Hiking",
        description: "use this area for use this area for session",
        exercise: "Hiking",
        time: "1 x 120:00 m:s",
        detail: "use this area for Exercises",
      },
      {
        week: 2,
        day: 2,
        title: "Hiking",
        description: "use this area for use this area for session",
        exercise: "Hiking",
        time: "1 x 120:00 m:s",
        detail: "use this area for Exercises",
      },
    ];

    const isButtonDisabled = (weekNumber, dayIndex) => {
  
      // Check if the button should be disabled for this day
      const session = dummySessions.find(
        (s) => s?.week === weekNumber && s?.day === dayIndex + 1
      );
      return !!session;
    };

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {weeks.map((weekNumber, weekIndex) => (
          <div
            key={weekNumber}
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div style={{ flex: 1, padding: "8px" }}>Week {weekNumber}</div>
            <div style={{ display: "flex", width: "100%" }}>
              {Array.from({ length: 7 }, (_, dayIndex) => {

                const isDisabled = isButtonDisabled(weekNumber, dayIndex);
                const session = getProgramSessions.find(s => {
                  const programDate = JSON.parse(s.programDate);
                  return programDate.Week === weekNumber && programDate.Day === dayIndex;
                });
                const dayKey = `week-${weekNumber}-day-${dayIndex + 1}`;
                return (
                  <div
                    key={dayKey}
                    onMouseEnter={() => setHoveredDay(dayKey)}
                    onMouseLeave={() => setHoveredDay(null)}
                    className={hoveredDay === dayKey && "indexCalendarDay"}
                    style={{
                      flex: 1,
                      border: "1px solid #ccc",
                      padding: "8px",
                      height: !hoveredDay === dayKey && !session && '200px',
                      margin: "0.5rem",
                    }}
                  >
                    Week {weekNumber} Day {weekIndex * 7 + dayIndex + 1}
                    {session && (
                      <div className="event">
                        <div className="event-title">
                          {/* {i} */}
                          <h6>{session.title}</h6>
                        </div>
                        <div className="event-icons">
                          <div id="icon1" className="event-icon">
                            <div>
                              <svg
                                id="iconpic"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M5.75447 7.86764C5.6651 7.78223 5.54624 7.73457 5.42263 7.73457C5.42253 7.73457 5.41173 7.7347 5.41173 7.7347C5.28438 7.73759 5.16339 7.79095 5.07538 7.88304C4.98998 7.97241 4.94232 8.09127 4.94232 8.21488L4.94244 8.22578C4.94533 8.35313 4.99869 8.47412 5.09079 8.56213L6.84992 10.2432C7.03546 10.4205 7.32761 10.4206 7.5133 10.2435L11.0374 6.88144C11.1296 6.79351 11.1832 6.67245 11.1862 6.5451C11.1864 6.53741 11.1864 6.52972 11.1862 6.52202C11.1833 6.40265 11.136 6.28865 11.0536 6.20226C10.9629 6.10725 10.8373 6.05349 10.706 6.05349L10.6925 6.05368C10.5737 6.05704 10.4604 6.10427 10.3745 6.18627L7.18204 9.23187L5.75447 7.86764Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.06433 1.73071C8.06433 1.73071 9.33435 1.73071 10.4949 2.2216C10.4949 2.2216 11.6155 2.69558 12.4795 3.55954C12.4795 3.55954 13.3435 4.42351 13.8175 5.54413C13.8175 5.54413 14.3083 6.70471 14.3083 7.97473C14.3083 7.97473 14.3083 9.24474 13.8175 10.4053C13.8175 10.4053 13.3435 11.5259 12.4795 12.3899C12.4795 12.3899 11.6155 13.2539 10.4949 13.7279C10.4949 13.7279 9.33434 14.2187 8.06433 14.2187C8.06433 14.2187 6.79431 14.2187 5.63373 13.7279C5.63373 13.7279 4.51311 13.2539 3.64914 12.3899C3.64914 12.3899 2.78518 11.5259 2.3112 10.4053C2.3112 10.4053 1.82031 9.24475 1.82031 7.97473C1.82031 7.97473 1.82031 6.7047 2.3112 5.54413C2.3112 5.54413 2.78518 4.42351 3.64914 3.55954C3.64914 3.55954 4.51311 2.69558 5.63373 2.2216C5.63373 2.2216 6.7943 1.73071 8.06433 1.73071ZM8.06433 2.69133C8.06433 2.69133 6.9891 2.69133 6.00794 3.10633C6.00794 3.10633 5.05988 3.50732 4.3284 4.2388C4.3284 4.2388 3.59692 4.97028 3.19593 5.91834C3.19593 5.91834 2.78093 6.8995 2.78093 7.97473C2.78093 7.97473 2.78093 9.04995 3.19593 10.0311C3.19593 10.0311 3.59692 10.9792 4.3284 11.7107C4.3284 11.7107 5.05988 12.4421 6.00794 12.8431C6.00794 12.8431 6.98911 13.2581 8.06433 13.2581C8.06433 13.2581 9.13955 13.2581 10.1207 12.8431C10.1207 12.8431 11.0688 12.4421 11.8003 11.7107C11.8003 11.7107 12.5317 10.9792 12.9327 10.0311C12.9327 10.0311 13.3477 9.04994 13.3477 7.97473C13.3477 7.97473 13.3477 6.89951 12.9327 5.91834C12.9327 5.91834 12.5317 4.97028 11.8003 4.2388C11.8003 4.2388 11.0688 3.50732 10.1207 3.10633C10.1207 3.10633 9.13955 2.69133 8.06433 2.69133Z"
                                  fill="white"
                                />
                              </svg>
                            </div>

                            <p>Blocks</p>
                            <h6>4/4</h6>
                          </div>
                          <div id="icon2" className="event-icon">
                            <div>
                              <svg
                                id="iconpic"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.00183 1.73071C8.00183 1.73071 9.27185 1.73071 10.4324 2.2216C10.4324 2.2216 11.553 2.69558 12.417 3.55954C12.417 3.55954 13.281 4.42351 13.755 5.54413C13.755 5.54413 14.2458 6.70471 14.2458 7.97473C14.2458 7.97473 14.2458 9.24474 13.755 10.4053C13.755 10.4053 13.281 11.5259 12.417 12.3899C12.417 12.3899 11.553 13.2539 10.4324 13.7279C10.4324 13.7279 9.27184 14.2187 8.00183 14.2187C8.00183 14.2187 6.73181 14.2187 5.57123 13.7279C5.57123 13.7279 4.45061 13.2539 3.58664 12.3899C3.58664 12.3899 2.72268 11.5259 2.2487 10.4053C2.2487 10.4053 1.75781 9.24475 1.75781 7.97473C1.75781 7.97473 1.75781 6.7047 2.2487 5.54413C2.2487 5.54413 2.72268 4.42351 3.58664 3.55954C3.58664 3.55954 4.45061 2.69558 5.57123 2.2216C5.57123 2.2216 6.7318 1.73071 8.00183 1.73071ZM8.00183 2.69133C8.00183 2.69133 6.9266 2.69133 5.94544 3.10633C5.94544 3.10633 4.99738 3.50732 4.2659 4.2388C4.2659 4.2388 3.53442 4.97028 3.13343 5.91834C3.13343 5.91834 2.71843 6.8995 2.71843 7.97473C2.71843 7.97473 2.71843 9.04995 3.13343 10.0311C3.13343 10.0311 3.53442 10.9792 4.2659 11.7107C4.2659 11.7107 4.99738 12.4421 5.94544 12.8431C5.94544 12.8431 6.92661 13.2581 8.00183 13.2581C8.00183 13.2581 9.07705 13.2581 10.0582 12.8431C10.0582 12.8431 11.0063 12.4421 11.7378 11.7107C11.7378 11.7107 12.4692 10.9792 12.8702 10.0311C12.8702 10.0311 13.2852 9.04994 13.2852 7.97473C13.2852 7.97473 13.2852 6.89951 12.8702 5.91834C12.8702 5.91834 12.4692 4.97028 11.7378 4.2388C11.7378 4.2388 11.0063 3.50732 10.0582 3.10633C10.0582 3.10633 9.07705 2.69133 8.00183 2.69133Z"
                                  fill="white"
                                />
                                <path
                                  d="M8.00183 8.45504H11.364C11.6293 8.45504 11.8443 8.23999 11.8443 7.97473C11.8443 7.70946 11.6293 7.49442 11.364 7.49442H8.48213V4.61257C8.48213 4.3473 8.26709 4.13226 8.00183 4.13226C7.73656 4.13226 7.52152 4.3473 7.52152 4.61257V7.97473C7.52152 8.23999 7.73656 8.45504 8.00183 8.45504Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p>Minutes</p>
                            <h6>75</h6>
                          </div>
                          <div id="icon3" className="event-icon">
                            <div>
                              <svg
                                id="iconpic"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_1963_19636)">
                                  <path
                                    d="M10.0512 7.25132L8.62532 9.51777C8.56698 9.59862 8.49603 9.66957 8.41518 9.72791C8.22 9.86407 7.979 9.91777 7.74447 9.87734C7.50995 9.83691 7.30083 9.70562 7.16251 9.51196C7.02418 9.31831 6.96781 9.07792 7.00563 8.84296C7.04345 8.608 7.1724 8.39743 7.36451 8.25696L9.63097 6.83105C9.68249 6.79513 9.74378 6.77588 9.80658 6.77588C9.86938 6.77588 9.93067 6.79513 9.98219 6.83105C10.0469 6.87776 10.0905 6.94819 10.1034 7.02694C10.1164 7.10569 10.0976 7.18636 10.0512 7.25132Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M7.94698 2.21106C4.2336 2.21106 1.22266 5.21899 1.22266 8.93238C1.22038 10.5753 1.82186 12.1618 2.91275 13.3902C2.94577 13.4263 2.97579 13.4623 3.00881 13.4953C3.07985 13.5722 3.16608 13.6335 3.26205 13.6754C3.35802 13.7172 3.46163 13.7387 3.56633 13.7384C3.67102 13.7381 3.77452 13.7161 3.87026 13.6737C3.966 13.6314 4.0519 13.5696 4.12253 13.4923C4.60929 12.9633 5.2005 12.5411 5.85879 12.2522C6.51707 11.9634 7.22812 11.8143 7.94698 11.8143C8.66585 11.8143 9.3769 11.9634 10.0352 12.2522C10.6935 12.5411 11.2847 12.9633 11.7714 13.4923C11.8421 13.5696 11.928 13.6314 12.0237 13.6737C12.1195 13.7161 12.2229 13.7381 12.3276 13.7384C12.4323 13.7387 12.5359 13.7172 12.6319 13.6754C12.7279 13.6335 12.8141 13.5722 12.8852 13.4953L12.9812 13.3902C14.0721 12.1618 14.6736 10.5753 14.6713 8.93238C14.6713 5.21899 11.6604 2.21106 7.94698 2.21106Z"
                                    stroke="white"
                                    stroke-width="1.15274"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.94727 4.13232V5.09294"
                                    stroke="white"
                                    stroke-width="1.53699"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M12.7497 8.9353H11.7891"
                                    stroke="white"
                                    stroke-width="1.53699"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M4.10515 8.9353H3.14453"
                                    stroke="white"
                                    stroke-width="1.53699"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M5.23012 6.21828L4.55078 5.53894"
                                    stroke="white"
                                    stroke-width="1.53699"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M10.6641 6.21828L11.3434 5.53894"
                                    stroke="white"
                                    stroke-width="1.53699"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1963_19636">
                                    <rect
                                      width="15.3699"
                                      height="15.3699"
                                      fill="white"
                                      transform="translate(0.261719 0.289795)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>

                            <p>Intensity</p>
                            <h6>5</h6>
                          </div>
                          <div id="icon4" className="event-icon">
                            <div>
                              <svg
                                id="iconpic"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3.07252 4.08264C3.07252 4.08264 3.07252 3.68107 3.35647 3.39711C3.35647 3.39711 3.64043 3.11316 4.042 3.11316H5.49623C5.49623 3.11316 5.89781 3.11316 6.18176 3.39711C6.18176 3.39711 6.46572 3.68107 6.46572 4.08264V11.8385C6.46572 11.8385 6.46572 12.2401 6.18176 12.5241C6.18176 12.5241 5.89781 12.808 5.49623 12.808H4.042C4.042 12.808 3.64043 12.808 3.35647 12.5241C3.35647 12.5241 3.07252 12.2401 3.07252 11.8385V4.08264ZM4.042 4.08264V11.8385H5.49623V4.08264H4.042Z"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.37417 4.08264C9.37417 4.08264 9.37417 3.68107 9.65813 3.39711C9.65813 3.39711 9.94208 3.11316 10.3437 3.11316H11.7979C11.7979 3.11316 12.1995 3.11316 12.4834 3.39711C12.4834 3.39711 12.7674 3.68107 12.7674 4.08264V11.8385C12.7674 11.8385 12.7674 12.2401 12.4834 12.5241C12.4834 12.5241 12.1995 12.808 11.7979 12.808H10.3437C10.3437 12.808 9.94208 12.808 9.65813 12.5241C9.65813 12.5241 9.37417 12.2401 9.37417 11.8385V4.08264ZM10.3437 4.08264V11.8385H11.7979V4.08264H10.3437Z"
                                  fill="white"
                                />
                                <path
                                  d="M13.7369 10.3843H12.2826C12.0149 10.3843 11.7979 10.6013 11.7979 10.869C11.7979 11.1368 12.0149 11.3538 12.2826 11.3538H13.7369C14.1384 11.3538 14.4224 11.0698 14.4224 11.0698C14.7063 10.7859 14.7063 10.3843 14.7063 10.3843V5.53687C14.7063 5.1353 14.4224 4.85134 14.4224 4.85134C14.1384 4.56739 13.7369 4.56739 13.7369 4.56739H12.2826C12.0149 4.56739 11.7979 4.78441 11.7979 5.05213C11.7979 5.31985 12.0149 5.53687 12.2826 5.53687H13.7369V10.3843Z"
                                  fill="white"
                                />
                                <path
                                  d="M2.10303 5.53687H3.55726C3.82498 5.53687 4.042 5.31985 4.042 5.05213C4.042 4.78441 3.82498 4.56739 3.55726 4.56739H2.10303C1.70146 4.56739 1.4175 4.85134 1.4175 4.85134C1.13355 5.1353 1.13355 5.53687 1.13355 5.53687V10.3843C1.13355 10.7859 1.4175 11.0698 1.4175 11.0698C1.70146 11.3538 2.10303 11.3538 2.10303 11.3538H3.55726C3.82498 11.3538 4.042 11.1368 4.042 10.869C4.042 10.6013 3.82498 10.3843 3.55726 10.3843H2.10303V5.53687Z"
                                  fill="white"
                                />
                                <path
                                  d="M5.98097 8.44533H9.85892C10.1266 8.44533 10.3437 8.2283 10.3437 7.96059C10.3437 7.69287 10.1266 7.47584 9.85892 7.47584H5.98097C5.71326 7.47584 5.49623 7.69287 5.49623 7.96059C5.49623 8.2283 5.71326 8.44533 5.98097 8.44533Z"
                                  fill="white"
                                />
                                <path
                                  d="M13.7369 7.96059C13.7369 8.2283 13.9539 8.44533 14.2216 8.44533H15.1911C15.4588 8.44533 15.6758 8.2283 15.6758 7.96059C15.6758 7.69287 15.4588 7.47584 15.1911 7.47584H14.2216C13.9539 7.47584 13.7369 7.69287 13.7369 7.96059Z"
                                  fill="white"
                                />
                                <path
                                  d="M0.164062 7.96059C0.164062 8.2283 0.381089 8.44533 0.648805 8.44533H1.61829C1.88601 8.44533 2.10303 8.2283 2.10303 7.96059C2.10303 7.69287 1.88601 7.47584 1.61829 7.47584H0.648805C0.381089 7.47584 0.164062 7.69287 0.164062 7.96059Z"
                                  fill="white"
                                />
                              </svg>
                            </div>

                            <p>LB</p>
                            <h6>980</h6>
                          </div>
                        </div>
                        {show && <div className="event-details">{session.description}</div>}
                        <div className="event-type mt-3 mb-3">
                          <div className="event-nmbr">A</div>
                          <div >
                            <div className="event-exer">{session.exercise}</div>
                            <div className="event-exer">{session.time}</div>
                          </div>
                        </div>
                        {show && <div className="event-details">{session.detail}</div>}
                      </div>
                    )}
                    {hoveredDay === dayKey && !session && (
                      <div className="event-creation">
                        <button
                          style={{
                            marginTop: "1rem",
                          }}
                          className="create-session-button"
                          onClick={() => {
                            setIsOpen(true);
                            setSelectedWeekNumber(weekNumber);
                            setSelectedDay(dayIndex + 1);

                          }}
                          // disabled={isDisabled}
                        >
                          Create Session
                        </button>
                        <button
                          className="create-session-button"
                          onClick={() => {
                            // Handle program addition here
                            console.log(
                              `Add program for Week ${weekNumber}, Day ${dayIndex + 1
                              }`
                            );
                          }}
                          disabled={isDisabled}
                        >
                          Add from index
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const dayLabels = ["Day1", "Day2", "Day3", "Day4", "Day5", "Day6", "Day7"];
  return (
    <div className="calendar-container">
      <div className="test">
        <div className="d-flex justify-content-between py-4 px-4">
          <div onClick={() => setShowCalendar(false)} style={{ marginTop: '1rem', cursor: "pointer", width: "fit-content" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none">
              <path d="M10 20.1038L2 11.2511L10 2.39844" stroke="#100837" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>

          <div className="weeklyProgramTitle">{currentProgram.weeks} Week Program</div>
          <div className="publishButtons flex align-items-center">
            <div style={{ cursor: "pointer" }} onClick={() => setShow(!show)}>
              <svg id="Publishpic"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.1854 12.3046C23.1854 12.3046 22.9759 12.7758 22.5487 13.4543C22.5487 13.4543 21.707 14.7911 20.5928 15.9053C20.5928 15.9053 16.9982 19.5 12 19.5C12 19.5 7.00184 19.5 3.40717 15.9053C3.40717 15.9053 2.29298 14.7911 1.45126 13.4543C1.45126 13.4543 1.02406 12.7758 0.814641 12.3046C0.728453 12.1107 0.728453 11.8893 0.814641 11.6954C0.814641 11.6954 1.02406 11.2242 1.45126 10.5457C1.45126 10.5457 2.29298 9.20886 3.40717 8.09467C3.40717 8.09467 7.00184 4.5 12 4.5C12 4.5 16.9981 4.5 20.5928 8.09467C20.5928 8.09467 21.707 9.20886 22.5487 10.5457C22.5487 10.5457 22.9759 11.2242 23.1854 11.6954C23.2715 11.8893 23.2715 12.1107 23.1854 12.3046ZM21.2794 12.6551C21.2794 12.6551 21.5125 12.2849 21.6644 12C21.6644 12 21.5125 11.7151 21.2794 11.3449C21.2794 11.3449 20.5273 10.1505 19.5322 9.15533C19.5322 9.15533 16.3768 6 12 6C12 6 7.62316 6 4.46783 9.15533C4.46783 9.15533 3.47265 10.1505 2.72061 11.3449C2.72061 11.3449 2.48753 11.7151 2.33558 12C2.33558 12 2.48753 12.2849 2.72061 12.6551C2.72061 12.6551 3.47265 13.8495 4.46783 14.8447C4.46783 14.8447 7.62316 18 12 18C12 18 16.3768 18 19.5322 14.8447C19.5322 14.8447 20.5273 13.8495 21.2794 12.6551Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 7.5C12 7.5 13.864 7.5 15.182 8.81802C15.182 8.81802 16.5 10.136 16.5 12C16.5 12 16.5 13.864 15.182 15.182C15.182 15.182 13.864 16.5 12 16.5C12 16.5 10.136 16.5 8.81802 15.182C8.81802 15.182 7.5 13.864 7.5 12C7.5 12 7.5 10.136 8.81802 8.81802C8.81802 8.81802 10.136 7.5 12 7.5ZM12 9C12 9 10.7574 9 9.87868 9.87868C9.87868 9.87868 9 10.7574 9 12C9 12 9 13.2426 9.87868 14.1213C9.87868 14.1213 10.7574 15 12 15C12 15 13.2426 15 14.1213 14.1213C14.1213 14.1213 15 13.2426 15 12C15 12 15 10.7574 14.1213 9.87868C14.1213 9.87868 13.2426 9 12 9Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        
      </div>
      <div
        className="athbody"
        style={{
          padding: "1rem",
        }}
      >
        <div>{WeekRows()}</div>
      </div>
      {isOpen && (
        <IndexSessionModal
          selectedWeekNumber={selectedWeekNumber}
          currentProgram={currentProgram}
          selectedDay={selectedDay}
          isOpen={isOpen}
          onClose={closeSessionModal}
        />
      )}
    </div>
  );
};

export default IndexCalendar;
