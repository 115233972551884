import React from 'react'
import Avtr from "../../../../assessts/images/athleteSource.png";
import './businessTable.css'
const BusinessTable = ({ activeBtn }) => {
  return (
    <div className="businesscontainer">
      <table>
        <thead>
          <tr>
            <th style={{
              textAlign: "start",
              padding: "8px 32px",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              {activeBtn === "coach" ? "Coach" : "Athlete"}
            </th>
            <th style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              {activeBtn === "coach" ? "Assistant Coach" : "Program"}
            </th>
            <th style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              {activeBtn === "coach" ? "Price" : "Coach Name"}
            </th>
            <th style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              {activeBtn === "coach" ? "Date" : "Market Place"}
            </th>
            <th style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              {activeBtn === "coach" ? "Athlete" : "Price"}
            </th>
            <th style={{
              textAlign: "center",
              paddingRight: "1rem ",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              {activeBtn === "coach" ? "" : "Date"}
            </th>
          </tr>
        </thead>
        <tbody style={{ borderStyle: "none" }} className="tableBody">
          <br />
          <tr className='mt-2 businessTablebody'>
            <td className="chk">
              <img width="48px" height="48px" src={Avtr} />
              <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>Alon </p>
            </td>
            <td style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{activeBtn === "coach" ? "Hamza" : "Session 1"}</p>
            </td>
            <td style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>
                {activeBtn === "coach" ?
                  <>
                    $12 <span style={{ display: "grid" }}>Platinum</span>
                  </> :
                  "Hamza"
                }
              </p>
            </td>
            <td style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{activeBtn === "coach" ? "25/08/2023" : "Yes/No"}</p>
            </td>
            <td style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              {activeBtn === "coach" ? <>
                <div className='manageCoachAthAv'>
                  <div>
                    <img className='coachAthAv1' src={Avtr} alt="" />
                  </div>
                  <div>
                    <img className='coachAthAv1' src={Avtr} alt="" />
                  </div>
                  <div><span>+3</span></div>
                </div>
              </> : "$12"}
            </td>
            <td style={{
              textAlign: "center",
              fontFamily: 'Hurme Geometric Sans 2'
            }}>
              {activeBtn === "coach" ? <>                           <div className='view'>
                <p >view</p>
              </div></> : "25/08/2023"}
            </td>

          </tr>

        </tbody>
      </table>
    </div>
  )
}

export default BusinessTable
