import React, { useContext, useEffect, useState } from "react";
import Avtr from "../../../../assessts/images/avtr.png";
import Communication from "../../../../assessts/icons/Communication.png";
import Calender from "../../../../assessts/icons/Time.png";
import { useTranslation } from "react-i18next";
import "./table.css";
import { SessionContext } from "../../../../context/sessionContext";
import moment from "moment";
import Tabs from "../../../ReusableUi/Tabs";
import SvgIcon from "../../../../assessts/Svg/svgIcons";
import DeleteModal from "../../../modules/Modal/DeleteModal";
import CustomPagination from "../../../Common/Pagination/pagination";
import {
  getInvitedAthelete,
  fetchUserByEmail,
} from "../../../../services/store/coachApi";
import axios from "axios";
import { auth } from "../../../../firebase";
import Loader from "../../../ReusableUi/Loader";
import SvgIcons from "../../../ReusableUi/SvgIcon";
import Skeleton from "react-loading-skeleton";
import { ThemeContext } from "../../../../context/themeContext";
import Tooltip from "../../../ReusableComponent/Tooltip";

const AtheleteTable = ({
  setSelectedData,
  isEmptyDataForJoined,
  setrefresh,
  isLoader,
  setLoader,
  setShow,
  getAtheleteGroup,
  setAtheleteId,
  handleActivePaginationathelete,
  isPaginataionathelete,
  isTotalPages,
  getAtheleteInvited,
  setAtheleteInvited,
  isActiveTab,
  setactiveTab,
  setInvitedAtheleteId,
  selectedAthelete,
  setSelectedAthelete,
  isShowDeleteCheckBox,
  refresh,
}) => {
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [isEmptyDataForinvited, setEmptyDataForInvited] = useState(false);
  const [isTotalPagesInvited, setTotalPagesInvited] = useState("");
  const { setActiveTab, setActiveChat, setIsGroupChat, setIsChatOpen } =
    useContext(ThemeContext);
  const [isPaginataionInvitedAthelete, setPaginataionInvitedAthelete] =
    useState(() => {
      const savedPage = localStorage.getItem("InvitedAtheleteactivePage");
      return savedPage ? JSON.parse(savedPage) : 1;
    });
  const handleGroupId = (data, atheleteData) => {
    setSelectedData(atheleteData);
    setAtheleteId(data);
    setShow(true);
  };
  const handleCheckboxChange = (atheleteId) => {
    setSelectedAthelete((prevSelectedAthelete) => {
      if (prevSelectedAthelete.includes(atheleteId)) {
        return prevSelectedAthelete.filter((id) => id !== atheleteId);
      } else {
        return [...prevSelectedAthelete, atheleteId];
      }
    });
  };
  // Invite Athelete Pagination
  useEffect(() => {
    localStorage.setItem(
      "InvitedAtheleteactivePage",
      JSON.stringify(isPaginataionInvitedAthelete)
    );
  }, [isPaginataionInvitedAthelete]);
  const handleActivePaginationInvitedAthelete = (page) => {
    setLoader(true);
    setPaginataionInvitedAthelete(page); // Update pagination state
  };

  const handlecreateInvitedUSerSession = async (email) => {
    const token = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
      `${process.env.REACT_APP_Baseurl}${fetchUserByEmail}`,
      {
        headers: { Authorization: token },
        params: { email },
      }
    );

    response && handleGroupId(response.data?.user.id);
  };

  const handleDeleteModal = (data) => {
    setOpenModal(!isOpenModal);
    setSelectedId(data);
    setrefresh((prev) => !prev);
  };
  const {
    setShowDeleteCheckBox,
    selectedItems,
    // isShowDeleteCheckBox,
  } = useContext(SessionContext);
  useEffect(() => {
    // This effect sets up the auth state observer
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); // Set the authenticated user
      if (currentUser) {
        // User is signed in, now it's safe to fetch data
        fetchInvitedAtheleteList(isPaginataionInvitedAthelete); // Make sure this function and its dependencies are correctly set up
      } else {
        // User is signed out
        console.log("No user is signed in.");
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, [isPaginataionInvitedAthelete]); // Add other dependencies as needed
  // Invite Athelete Data Get
  const fetchInvitedAtheleteList = async (page) => {
    try {
      setLoader(true);
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getInvitedAthelete}?currentPage=${page}&itemsPerPage=2`,
        { headers: { Authorization: token } }
      );
      if (response?.data?.invitations.length <= 0) {
        setEmptyDataForInvited(true);
      } else {
        setEmptyDataForInvited(false);
      }
      console.log(response?.data?.invitations);
      setAtheleteInvited(response?.data?.invitations);
      setTotalPagesInvited(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false); // Ensure loader is hidden whether the fetch is successful or not
    }
  };

  // Fetch invited athletes list on component mount and when pagination changes
  useEffect(() => {
    fetchInvitedAtheleteList(isPaginataionInvitedAthelete); // Pass the current page
  }, [isPaginataionInvitedAthelete]);

  // Optionally, if you need it on component mount
  useEffect(() => {
    fetchInvitedAtheleteList(isPaginataionInvitedAthelete);
  }, []);
  useEffect(() => {
    fetchInvitedAtheleteList(isPaginataionInvitedAthelete);
  }, [refresh]);

  // Step 1: Create a function to handle data deletion
  const tabs = [
    { id: "jioned", label: "Joined" },
    { id: "invited", label: "Invited" },
    // Add more tabs as needed
  ];

  const handleTabSelect = (tabId) => {
    setactiveTab(tabId);
    console.log(isActiveTab);
    localStorage.setItem("activeAtheleteTab", tabId);
    if (isActiveTab === "invited") {
      setSelectedAthelete([]);
      setShowDeleteCheckBox(false);
    }

    // Handle tab selection (e.g., updating UI or fetching data)
  };
  const handleChatOpen = (athelete) => {
    console.log(athelete, "athelete");
    setActiveTab("coach");
    setIsChatOpen(true);
    setActiveChat(athelete);
  };
  return (
    <div className="table-container">
      <Tabs tabs={tabs} onTabSelect={handleTabSelect} />
      {isActiveTab === "invited" ? (
        <>
          <table>
            <tbody style={{ borderStyle: "none" }} className="tableBody">
              {isLoader ? (
                Array.from({ length: 2 }).map((_, index) => (
                  <>
                    <br />
                    <tr key={index}>
                      <td>{<Skeleton height={58} width={100} />}</td>
                      <td>{<Skeleton height={58} width={50} />}</td>
                    </tr>
                  </>
                ))
              ) : isEmptyDataForinvited ? (
                <div
                  className="w-full d-flex flex-column justify-content-center align-items-center"
                  style={{ height: "20vw" }}
                >
                  <SvgIcons iconName="noSessionPlane" />
                  <div className="w-100 d-flex flex-column justify-content-center">
                    <h4 className="noSessionHeading d-flex justify-content-center">
                      No Invited
                    </h4>
                    <div className="d-flex justify-content-center">
                      {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
                    </div>
                  </div>
                </div>
              ) : (
                <table>
                  <thead className="tablehead">
                    <tr>
                      <th
                        style={{
                          textAlign: "start",
                          padding: "8px 20px",
                          fontSize: "14px",
                          fontWeight: "700",
                          backgroundColor: `var(--primary-color,#F6F6F0)`,
                          color: `var(--primary-text-color,#a19686)`,
                        }}
                      >
                        {" "}
                        Athlete
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "700",
                          backgroundColor: `var(--primary-color,#F6F6F0)`,
                          color: `var(--primary-text-color,#a19686)`,
                        }}
                      >
                        {t("Joined")}
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "700",
                          backgroundColor: `var(--primary-color,#F6F6F0)`,
                          color: `var(--primary-text-color,#a19686)`,
                        }}
                      >
                        {t("Actions")}{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ borderStyle: "none" }} className="tableBody">
                    {getAtheleteInvited.map((data, index) => {
                      console.log(data);
                      return (
                        <>
                          <tr>
                            <td className="chk">
                              {isShowDeleteCheckBox && (
                                <>
                                  <label class="containerInput">
                                    <input
                                      type="checkbox"
                                     checked={selectedAthelete.includes(
                                        data?.invitedFor
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          data?.invitedFor                                        )
                                      }
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </>
                              )}
                              {/* <img id='chkImage' width="48px" height="48px" src={Avtr} /> */}
                              <p
                                className="chkname"
                                style={{
                                  margin: "0",
                                  fontSize: "16px",
                                  paddingLeft: "6px",
                                  color: `black`,
                                }}
                                id="as"
                              >
                                {data.email}
                              </p>
                            </td>

                            <td>
                              <p
                                className="chkname"
                                style={{
                                  margin: "0",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  color: `black`,
                                }}
                                id="as"
                              >
                                {moment(data.createdAt).format("D/MM/YYYY")}
                              </p>
                            </td>
                            <td>
                              <div
                                className="icongap"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  onClick={() =>
                                    handleGroupId(data?.invitedFor)
                                  }
                                >
                                  <Tooltip
                                    children={
                                      <img
                                        id="tableicon"
                                        className="athttblimg"
                                        style={{ cursor: "pointer" }}
                                        src={Calender}
                                      />
                                    }
                                    content={"View Calendar"}
                                  />
                                </div>

                                {/* <img
                                onClick={() => handleChatOpen(data.athleteSubscriptions)}
                                  className="athttblimg"
                                  id="tableicon"
                                  src={Communication}
                                /> */}
                                <div
                                  onClick={() =>
                                    handleDeleteModal(
                                      data?.invitedFor,
                                    )
                                  }
                                >
                                  <Tooltip
                                    children={
                                      <div className="indexSessionDel">
                                        <SvgIcon
                                          iconName="deleteIcon"
                                          size={16}
                                        />
                                      </div>
                                    }
                                    content={"Delete Invited Athelete"}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <br />
                        </>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </tbody>
          </table>
          <CustomPagination
            currentPage={isPaginataionInvitedAthelete}
            totalPages={isTotalPagesInvited}
            onPageChange={handleActivePaginationInvitedAthelete}
          />
        </>
      ) : (
        <>
          <table>
            <tbody style={{ borderStyle: "none" }} className="tableBody">
              {isLoader ? (
                Array.from({ length: 2 }).map((_, index) => (
                  <React.Fragment key={index}>
                    <br />
                    <tr>
                      <td>
                        <Skeleton height={58} width={100} />
                      </td>
                      <td>
                        <Skeleton height={58} width={50} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : isEmptyDataForJoined ? (
                <div
                  className="w-full d-flex flex-column justify-content-center align-items-center"
                  style={{ height: "20vw" }}
                >
                  <SvgIcons iconName="noSessionPlane" />
                  <div className="w-100 d-flex flex-column justify-content-center">
                    <h4 className="noSessionHeading d-flex justify-content-center">
                      No joined
                    </h4>
                    <div className="d-flex justify-content-center">
                      {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
                    </div>
                  </div>
                </div>
              ) : (
                getAtheleteGroup && (
                  <table>
                    <thead className="tablehead">
                      <tr>
                        <th
                          style={{
                            textAlign: "start",
                            padding: "8px 20px",
                            fontSize: "14px",
                            fontWeight: "700",
                            backgroundColor: `var(--primary-color,#F6F6F0)`,
                            color: `var(--primary-text-color,#a19686)`,
                          }}
                        >
                          Athlete
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "700",
                            backgroundColor: `var(--primary-color,#F6F6F0)`,
                            color: `var(--primary-text-color,#a19686)`,
                          }}
                        >
                          {t("Joined")}
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "700",
                            backgroundColor: `var(--primary-color,#F6F6F0)`,
                            color: `var(--primary-text-color,#a19686)`,
                          }}
                        >
                          {t("Actions")}
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{ borderStyle: "none" }}
                      className="tableBody"
                    >
                      {getAtheleteGroup.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td className="chk">
                                {isShowDeleteCheckBox && (
                                  <label className="containerInput">
                                    <input
                                      type="checkbox"
                                      checked={selectedAthelete.includes(
                                        data?.athleteSubscriptions?.id
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          data?.athleteSubscriptions?.id
                                        )
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                )}
                                <img
                                  id="chkImage"
                                  width="48px"
                                  height="48px"
                                  src={Avtr}
                                  alt="avatar"
                                />
                                <p
                                  className="chkname"
                                  style={{
                                    margin: "0",
                                    fontSize: "16px",
                                    paddingLeft: "6px",
                                    color: `black`,
                                  }}
                                >
                                  {data?.athleteSubscriptions?.firstName}{" "}
                                  {data?.athleteSubscriptions?.lastName}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="chkname"
                                  style={{
                                    margin: "0",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    color: `black`,
                                  }}
                                >
                                  {moment(data.createdAt).format("D/MM/YYYY")}
                                </p>
                              </td>
                              <td>
                                <div
                                  className="icongap"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    onClick={() =>
                                      handleGroupId(
                                        data?.athleteSubscriptions?.id,
                                        data?.athleteSubscriptions
                                      )
                                    }
                                  >
                                  <Tooltip
                                    children={
                                      <img
                                        id="tableicon"
                                        className="athttblimg"
                                        src={Calender}
                                        alt="calendar"
                                      />
                                    }
                                    content={"View Calendar"}
                                  />
                                  </div>
                                  <div
                                   onClick={() =>
                                    handleChatOpen(data.athleteSubscriptions)
                                  }
                                  >
                                  <Tooltip
                                    children={
                                      <img
                                    className="athttblimg"
                                    id="tableicon"
                                    src={Communication}
                                    alt="communication"
                                  />
                                    }
                                    content={"Send Message"}
                                  />
                                  </div>
                                  <div
                                  onClick={() =>
                                    handleDeleteModal(
                                      data?.athleteSubscriptions?.id
                                    )
                                  }
                                  >
                                  <Tooltip
                                    children={
                                      <div
                                    className="indexSessionDel"
                                    
                                  >
                                    <SvgIcon iconName="deleteIcon" size={16} />
                                  </div>
                                    }
                                    content={"Delete Joined Athelete"}
                                  />
                                  </div>

                                  
                                  
                                </div>
                              </td>
                            </tr>
                            <br />
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                )
              )}
            </tbody>
          </table>
          <CustomPagination
            currentPage={isPaginataionathelete}
            totalPages={isTotalPages}
            onPageChange={handleActivePaginationathelete}
          />
        </>
      )}
      {isOpenModal && (
        <DeleteModal
          method={ isActiveTab==="jioned"  ? "patch" : "delete"}
          isActiveTab={isActiveTab}
          isOpen={isOpenModal}
          onClose={handleDeleteModal}
          athleteID={selectedId}
        />
      )}
    </div>
  );
};

export default AtheleteTable;
