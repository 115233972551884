import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import SvgIcon from "../../../../../assessts/Svg/svgIcons";
import CreateSessionBlock from "./SessionBlocks/createSessionBlock";
import {
  createSession,
  editSession,
  getAllExerciseList,
} from "../../../../../apis";
import { auth } from "../../../../../firebase";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../../loadingSpinner";

/**
 * Component for creating or editing training sessions.
 * It allows a coach to input session details and manage session blocks dynamically.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.onClose - Function to call on closing the form.
 * @param {Object} props.isEditSession - Session data for editing; null for new session.
 */

const CreateSessionForm = ({ onClose, isEditSession }) => {

  const [isLoading, setIsLoading] = useState(false);
  // State for managing the list of exercises.
  const [exerciseList, setExerciseList] = useState([]);

  // State for managing session blocks.
  const [sessionBlocks, setSessionBlocks] = useState([
    {
      title: "",
      selectedExercise: null,
      instructions: "",
      videoURL: "",
      blocktitle: "",
      Label: "",
      sets: [
        { title1: "", title2: "", reps: "", weight: "" },
        { title1: "", title2: "", reps: "", weight: "" },
        { title1: "", title2: "", reps: "", weight: "" },
      ],
      supersetGroup: "A",
      supersetOrder: 0,
    },
  ]);

  // State for loading indicator.
  const [isActiveLoader, setActiveLoader] = useState(false);

  // Coach ID, fetched from Firebase authentication.
  const coachId = auth.currentUser.uid;

  // Step 1: Function to get the current date in "YYYY-MM-DD" format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // JavaScript months are 0-based.
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Step 2: Store the current date in a variable
  const currentDate = getCurrentDate();

  // State for form data like session title and instructions.
  const [sessionFormData, setSessionFormData] = useState({
    title: isEditSession?.title || "",
    instructions: isEditSession?.instructions || "",
    addedByAthlete: false,
    fixedLength: false,
    groupSharing: true,
    oneToOne: false,
    coachId: coachId,
    athleteId: null,
    programId: null,
    groupId: null,
    date: currentDate,
    programDate: {
      Week: null,
      Day: null,
    },
    leaderboard: {
      scoreType: "Weight",
      customCalculations: "More is better",
      numberOfIntervals: "4",
      leaderboardCalculations: "More is better",
    },
  });

  // Effect to fetch exercise list on mount.
  useEffect(() => {
    fetchExerciseList();
  }, []);

  // Effect to load session data for editing.
  useEffect(() => {
    if (isEditSession && Array.isArray(isEditSession.blocks)) {
        const formattedBlocks = isEditSession.blocks.map((block) => {
            const selectedExercise = block.exercises || null;
            const exerciseTitle = selectedExercise ? selectedExercise.title : "Unknown Exercise";
            const exerciseId = selectedExercise ? selectedExercise.id : null;

            return {
                id: block.id,
                blocktitle: block.title || "", // Default to empty string if no title
                selectedExercise: {
                    title: exerciseTitle,
                    id: exerciseId,
                },
                instructions: selectedExercise?.instructions || "", // Optional chaining for instructions
                videoURL: selectedExercise?.URL || "", // Optional chaining for video URL
                sets: Array.isArray(block.blockSets) ? block.blockSets.map((set) => ({
                    id: set.id,
                    title1: set.title1 || "Untitled", // Default title
                    title2: set.title2 || "Untitled", // Default title
                    reps: set.value1 || "0", // Default reps value
                    weight: set.value2 || "0", // Default weight value
                })) : [],
                supersetGroup: block.supersetGroup || "A", 
                supersetOrder: block.supersetOrder || 0,
            };
        });

        // Log the formatted blocks and set the state
        console.log("Formatted Blocks:", formattedBlocks); 
        setSessionBlocks(formattedBlocks);
    } else {
        console.error("Invalid isEditSession format", isEditSession); // Log error for invalid format
        // Set default state if isEditSession is invalid
        setSessionBlocks([{
            blocktitle: "",
            selectedExercise: null,
            instructions: "",
            videoURL: "",
            sets: [
                { title1: "", title2: "", reps: "", weight: "" },
                { title1: "", title2: "", reps: "", weight: "" },
                { title1: "", title2: "", reps: "", weight: "" },
            ],
            supersetGroup: "A",
            supersetOrder: 0,
        }]);
    }
}, [isEditSession]);

  /**
   * Handler Functions
   */

  /**
   * Fetches the list of exercises from the API.
   */

  const fetchExerciseList = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getAllExerciseList}?coachId=${coachId}`,
          { headers: { Authorization: token } }
        );
        setExerciseList(response.data);
        // if (!isEditSession) {
        //   setSessionBlocks([{
        //     title: response.data[0].title,
        //     selectedExercise: response.data[0],
        //     instructions: response.data[0].instructions,
        //     videoURL: response.data[0].URL,
        //     sets: [{ reps: "", weight: "", }, { reps: "", weight: "", }, { reps: "", weight: "", }],
        //   }]);
        // }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  /**
   * Handles form submission for creating or editing a session.
   */
  const HandleForm = () => {
    handleSaveSession()
  }
  const handleSaveSession = async () => {
    try {
      setIsLoading(true);
      setActiveLoader(true);

      // Get authorization token
      const token = await auth.currentUser.getIdToken(true);
      // Determine the API endpoint and payload based on whether it's a new program or an edit
      const endpoint = isEditSession ? editSession : createSession;
      const payload = isEditSession
        ? {
          ...sessionFormData,
          blocks: sessionBlocks.map((block) => ({
            blockId: block.id,
            exerciseTitle: block.selectedExercise ? block.selectedExercise.title : "",
            exerciseURL: block.videoURL,
            exerciseInstructions: block.instructions,
            exerciseID: block.selectedExercise ? block.selectedExercise.id : null, // Assuming you have an id field
            exerciseCategory: block.selectedExercise ? block.selectedExercise.category || "Power" : "Power", // Assuming you have a category field
            blocktitle: block.blocktitle,
              sets: block.sets.map((set) => ({
                title1: set.title1,
                title2: set.title2,
                value1: set.reps || "0",
                value2: set.weight || "0",
              })),
              supersetGroup: block.supersetGroup,
              supersetOrder: block.supersetOrder,
          })),
        }
        : {
          ...sessionFormData,
          blocks: sessionBlocks.map((block) => ({
            exerciseTitle: block.selectedExercise ? block.selectedExercise.title : "",
            exerciseURL: block.videoURL,
            exerciseInstructions: block.instructions,
            exerciseID: block.selectedExercise ? block.selectedExercise.id : null, // Assuming you have an id field
            exerciseCategory: block.selectedExercise ? block.selectedExercise.category || "Power" : "Power", // Assuming you have a category field
            blocktitle: block.blocktitle,
            sets: block.sets.map((set) => ({
              title1: set.title1,
              title2: set.title2,
              value1: set.reps || "0",
              value2: set.weight || "0",
            })),
            supersetGroup: block.supersetGroup,
            supersetOrder: block.supersetOrder,
          })),
        };

      // console.log("Payload:", payload); // Log the payload

      // Make the API call
      await makeApiCall(endpoint, payload, token);
      onClose();

      toast.success(
        isEditSession
          ? "Session Updated Successfully"
          : "Session Created Successfully"
      );
    } catch (error) {
      // Handle errors
      setIsLoading(false);
      setActiveLoader(false);
      toast.error("An error occurred while saving the program");
    }
    setIsLoading(false);
  };

  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token },
    };

    if (isEditSession) {
      config.params = { sessionId: isEditSession.id };
    }
    if (isEditSession) {
      // For editing (patch request)
      await axios.patch(url, payload, config);
    } else {
      // For creation (post request)
      console.log("url", url)
      console.log("payload", payload)
      console.log("config", config)
      await axios.post(url, payload, config);
    }
  };

  const handleInstructionsChange = (blockIndex, newInstructions) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].instructions = newInstructions;
    setSessionBlocks(updatedBlocks);
  };

  const handleURLChange = (blockIndex, newURL) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].videoURL = newURL;
    setSessionBlocks(updatedBlocks);
  };

  // Handles changes in session title and instructions input fields.
  const handleInputSessionChange = (e, field) => {
    setSessionFormData({ ...sessionFormData, [field]: e.target.value });
  };

  // Adds a new set to a session block.
  const handleAddSet = (blockIndex) => {
    const newBlocks = [...sessionBlocks];
    newBlocks[blockIndex].sets.push({ reps: "", weight: "" });
    setSessionBlocks(newBlocks);
  };
  const handleBlockTitle = (blockIndex, newtitle) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].blocktitle = newtitle;
    setSessionBlocks(updatedBlocks);
  };
  const handleRemoveSet = (blockIndex) => {
    const newBlocks = [...sessionBlocks]; // Create a shallow copy of the current sessionBlocks

    // Get the number of sets in the current block
    const numSets = newBlocks[blockIndex].sets.length;

    // If there are any sets, remove the last one
    if (numSets > 0) {
      newBlocks[blockIndex].sets.splice(numSets - 1, 1); // Remove the last set
      setSessionBlocks(newBlocks); // Update the state with the modified blocks
    }
  };

  // Updates the details of a specific set within a block.
  const handleSetChange = (blockIndex, setIndex, key, value) => {
    const newBlocks = [...sessionBlocks];

    // For the first 3 inputs, mirror the value across all of them
    if (setIndex < 3) {
      newBlocks[blockIndex].sets.forEach((set, idx) => {
        if (idx < 3) {
          set[key] = value;
        }
      });
    } else {
      newBlocks[blockIndex].sets[setIndex][key] = value;
    }

    setSessionBlocks(newBlocks);
  };

  // Manages the selection of an exercise and updates block data accordingly.
    // Manages the selection of an exercise and updates block data accordingly.
    const handleExerciseSelectionChange = (blockIndex, exerciseTitle, exerciseId) => {
 
      const newBlocks = [...sessionBlocks];
      const selectedExerciseData = exerciseList.find(
        (exercise) => exercise.title === exerciseTitle
      );
    
      if (selectedExerciseData) {
        newBlocks[blockIndex] = {
          ...newBlocks[blockIndex], 
          selectedExercise: selectedExerciseData, 
          instructions: selectedExerciseData.instructions,
          videoURL: selectedExerciseData.URL,
          sets: newBlocks[blockIndex].sets.map(set => ({
            ...set,
            title1: selectedExerciseData.title1 || "", 
            title2: selectedExerciseData.title2 || "", 
          })),
        };
      } else {
        console.error("Exercise not found for title:", exerciseTitle);
      }
      setSessionBlocks(newBlocks);
    };

    const getNextGroup = () => {
      const currentGroups = sessionBlocks.map((block) => block.supersetGroup);
      const allSameGroup = currentGroups.every(
        (group) => group === currentGroups[0]
      );
  
      if (allSameGroup) {
        const lastGroup = currentGroups[0]; 
        return String.fromCharCode(lastGroup?.charCodeAt(0) + 1); 
      } else {
        const uniqueGroups = [...new Set(currentGroups)];
        return uniqueGroups.length > 0
          ? String.fromCharCode(
              uniqueGroups[uniqueGroups.length - 1]?.charCodeAt(0) + 1
            )
          : "A";
      }
    };
  
    const addSessionBlock = () => {
      const nextGroup = getNextGroup();
  
      // Add a new block with the determined supersetGroup
      setSessionBlocks((prevBlocks) => [
        ...prevBlocks,
        {
          title: "",
          selectedExercise: null,
          instructions: "",
          videoURL: "",
          blocktitle: "",
          Label: "",
          sets: [
            { title1: "", title2: "", reps: "", weight: "" },
            { title1: "", title2: "", reps: "", weight: "" },
            { title1: "", title2: "", reps: "", weight: "" },
          ],
          supersetGroup: nextGroup,
          supersetOrder: 0,
        },
      ]);
    };
  // Removes a specified session block.
  const removeSessionBlock = (blockIndex, blockId) => {
    console.log(blockIndex)
    setSessionBlocks((currentBlocks) =>
      currentBlocks.filter((_, index) => index !== blockIndex)
    );
  };


  return (
    <Container className="m-0 p-0">
      <Row className="m-0 p-0">
        <Col sm={12} md={12} lg={12} className="m-0 p-0">
          <Form id="as">
            <Form.Group className="mt-2 mb-2" controlId="formBasic">
              <Form.Label style={{ fontSize: "16px", color: `var(--primary-text-color,black)` }}>
                Session Title
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: `#F6F6F6` }}
                type="text"
                name="sessionTitle"
                placeholder="Session Title"
                value={sessionFormData.title}
                onChange={(e) => handleInputSessionChange(e, "title")}
              />
            </Form.Group>
            <Form.Group controlId="formBasic">
              <Form.Label style={{ fontSize: "16px", color: `var(--primary-text-color,black)` }}>Session Instruction</Form.Label>
              <Form.Control
                style={{ backgroundColor: `#F6F6F6` }}
                as="textarea"
                rows={5}
                name="coach"
                placeholder="Session Instruction"
                value={sessionFormData.instructions}
                onChange={(e) => handleInputSessionChange(e, "instructions")}
              />
              {sessionBlocks?.map((block, index) => (
                <CreateSessionBlock
                isEditSession={isEditSession}
                // SessionData={SessionData}
                getNextGroup={getNextGroup}
                sessionBlocks={sessionBlocks}
                setSessionBlocks={setSessionBlocks}
                  fetchExerciseList={fetchExerciseList}
                  onClose={onClose}
                  handleRemoveSet={handleRemoveSet}
                  key={index}
                  blockIndex={index}
                  // blockLabel={getBlockLabel(index)}
                  blockData={block}
                  handleBlockTitle={handleBlockTitle}
                  handleAddSet={handleAddSet}
                  handleSetChange={handleSetChange}
                  exerciseList={exerciseList}
                  removeBlock={removeSessionBlock}
                  handleExerciseSelectionChange={handleExerciseSelectionChange}
                  handleInstructionsChange={handleInstructionsChange}
                  handleURLChange={handleURLChange}
                />
              ))}

              <div className="sessionBtm mt-4" style={{ textAlign: "end" }}>
                <div className="pluscircle" onClick={addSessionBlock}>
                  <SvgIcon iconName="plusButton" size={16} />
                </div>
                <div className="lastButtons mb-0">
                  <Button style={{ backgroundColor: `var(--primary-color,#A19686)`, color: `var(--primary-text-color,white)`, height: "48px", width: "120px", borderRadius: "8px", border: "none", fontSize: "19px", fontWeight: "600", lineHeight: "24px" }} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button style={{ backgroundColor: `var(--secondary-color,#A19686)`, color: `var(--secondary-text-color,white)`, height: "48px", width: "120px", borderRadius: "8px", border: "none", fontSize: "19px", fontWeight: "600", lineHeight: "24px" }} onClick={HandleForm}>
                    {isLoading ? (
                      <LoadingSpinner size="sm" />
                    ) : (
                      isEditSession ? "Update" : "Create"
                    )}
                  </Button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateSessionForm;
