import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { ThemeContext } from '../context/themeContext';

function LoadingSpinner({ size = 'sm' }) {
  console.log(size)
  const {theme } = useContext(ThemeContext);
  return (
    <Spinner style={{color: theme === 'dark' ? '#1C1C1C' : `black`}} as="span" animation="border" size={size} role="status" aria-hidden="true" />
  );
}

export default LoadingSpinner;