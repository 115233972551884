// SplineAreaChart.js
import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const SplineAreaChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
      gradient.addColorStop(0, '#E8E2DA');
      gradient.addColorStop(1, 'rgba(232, 226, 218, 0)');

      chart.data.datasets[0].backgroundColor = gradient;
      chart.update();
    }
  }, [data]);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true, // Show grid lines for better readability
        },
        ticks: {
          callback: function (value) {
            return value % 5 === 0 ? value : ''; // Show ticks at intervals of 5
          },
        },
        title: {
          display: true,
          text: 'Reps',
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value, index) {
            // Show x-axis labels for the data points
            return index === 0 ? '0' : data.labels[index];
          },
        },
        title: {
          display: true,
          text: 'Weight',
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Disable the legend since it's not needed
      },
      tooltip: {
        enabled: true, // Show tooltips with values
        callbacks: {
          label: function (tooltipItem) {
            return `Reps: ${tooltipItem.raw}, Weight: ${tooltipItem.label}`;
          },
        },
      },
    },
  };

  return <Line ref={chartRef} data={data} options={options} />;
};

export default SplineAreaChart;
