import React from 'react';
import style from './fourth.module.css';
import { discript, FunctionalF, intercom, unsplash } from '../../../../../../assessts/LandingPage';

const iconsData = [
    { src: FunctionalF, link: '#FunctionalFitness', alt: 'Functional F', text: 'Functional Fitness' },
    { src: FunctionalF, link: '#FunctionalFitness', alt: 'Functional F', text: 'Functional Fitness' },
    { src: FunctionalF, link: '#FunctionalFitness', alt: 'Functional F', text: 'Functional Fitness' },
    { src: FunctionalF, link: '#FunctionalFitness', alt: 'Functional F', text: 'Functional Fitness' },
    { src: FunctionalF, link: '#FunctionalFitness', alt: 'Functional F', text: 'Functional Fitness' },
    { src: FunctionalF, link: '#FunctionalFitness', alt: 'Functional F', text: 'Functional Fitness' },
];

const partnersData = [
    { src: unsplash, alt: '', text: 'Unsplash' },
    { src: unsplash, alt: '', text: 'Notion' },
    { src: intercom, alt: '', text: 'INTERCOM' },
    { src: discript, alt: '', text: 'descript' },
    { src: unsplash, alt: '', text: 'grammarly' },
];

const FourthComp = () => {
    return (
        <div>
            <div className={`${style.Text} d-flex flex-column text-center `}>
                <h1>COMPLETE USER EXPERIENCE</h1>
                <p>We understand that in the fast-paced world of fitness, adaptability is key. Life has a habit of throwing curveballs, and sometimes, changes need to be made on the fly. TheTrainingApp equips you with not only the ability to modify and update your programming instantly, right from your device, but the added luxury of adding brand new clients straight to the programs and team without having to wait. Whether you're a coach or a gym owner, our app gives you the flexibility to make immediate changes, ensuring your training plans are always up-to-date and reflective of your evolving needs and those of your clients. Dive into the new age of fitness management with TheTrainingApp.</p>
            </div>

            <div className={`${style.iconBox}`}>
                <h1>Recommended Training Programs</h1>
                <div>
                    {iconsData.map((icon, index) => (
                        <div key={index} className={`${style.icons}`}>
                            <div >
                                <img src={icon.src} alt={icon.alt} />
                                <a href={icon.link}><h1 className={`${style.iconH1}`}>{icon.text}</h1></a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            <div className={`${style.partners}`}>
                <h1>Our Trusted Partners</h1>
                <div>
                    {partnersData.map((partner, index) => (
                        <div key={index}>
                            <img src={partner.src} alt={partner.alt} />
                            <p>{partner.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FourthComp;

