import React from 'react'
import { auth } from '../../../../firebase';
import Avtr from '../../../../assessts/images/avtr.png'
import IndexSessionForm from './CreateGroupSession/createIndexProgramSession';

const IndexSessionModal = (props) => {
    const { currentProgram ,selectedWeekNumber, selectedDay, isOpen, onClose, selectedDate, groupDetails } = props;


    // Convert 'selectedDate' (string) into a Date object
    const currentDate = new Date();
    // Note: getMonth() returns 0-11 so we add 1 to get 1-12
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

    // Get day, date, and month
    const day = currentDate.getDay(); // Returns a number (0-6) representing the day of the week
    const date = currentDate.getDate(); // Returns the day of the month (1-31)
    const month = currentDate.getMonth(); // Returns a number (0-11) representing the month

    // Convert day and month to human-readable format
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const readableDay = dayNames[day];
    const readableMonth = monthNames[month];
    // To Get Coach Id From Auth
    const coachName = auth.currentUser.displayName;

    return (
        <>
            <div className={`invite-modal ${isOpen ? 'open' : ''}`}>
                <div className="modal-overlay" onClick={onClose}></div>
                <div className="modalBody">
                    <h2 id='asf' className='pb-4' style={{ fontSize: "24px", fontWeight: 700 }}>{readableDay} {readableMonth} {date}</h2>
                    <div className='userInfo'>
                        <img width="36px" heightd="36px" src={Avtr} alt="" />
                        <div className='userNameAndStatus'>
                            <h6 id='as' style={{ textTransform: "capitalize" }}>{coachName}</h6>
                            <p id='asf'>Unpublished</p>
                        </div>
                    </div>
                    <IndexSessionForm
                        groupDetails={groupDetails}
                        selectedDate={selectedDate}
                        formattedDate={formattedDate}
                        currentProgram= {currentProgram}
                        onClose={onClose}
                        selectedWeekNumber={selectedWeekNumber}
                        selectedDay={selectedDay}
                    />
                </div>
            </div>
        </>
    )
}

export default IndexSessionModal