import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AnalyticModal from "../../../Common/Modals/AnalyticModals/analyticModals";
import { getAllAthelete } from "../../../../services/store/coachApi";
import { Form, Spinner } from "react-bootstrap";  
import { getApiMethod } from "../../../../store/useGetApi";
import SvgIcons from "../../../ReusableUi/SvgIcon";
import { auth } from "../../../../firebase";

const AnalysisAtheleteTable = () => {
  const [getData, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [getId, setId] = useState("");
  const [isViewAnalyticDetails, setViewAnalyticDetails] = useState(false);
  const [getSessionData, setSessionData] = useState();
  const [isBlockSet, setBlockSet] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [NoData, SetNoData] = useState(false);
  const [authReady, setAuthReady] = useState(false);
  const [EmptyData,setEmptyData]=useState(true)
  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value;
    setId(selectedValue);
    handleGetSession(selectedDate, selectedValue);
  };

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user);
    });

    return () => unregisterAuthObserver();
  }, []);
  useEffect(() => {
    if (authReady) {
      handleGetData()
    }
  }, [authReady]);
  



  const handleGetData = async () => {
    try {
      setIsLoading(true);
      const endpoint = getAllAthelete;
      const data = await getApiMethod(endpoint);
      console.log("datadata",data)

      setData(data.coachAthletes);

      if (data.coachAthletes.length > 0) {
        const firstAthleteId = data.coachAthletes[0].athleteID;
        setId(firstAthleteId);
        handleGetSession(new Date(), firstAthleteId);
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleGetSession = async (date, athleteId) => {
    try {
      setIsLoading(true);
      const formattedDate = date.toISOString().split('T')[0];
      const endpoint = `/api/v1/coach/getSessions?athleteId=${athleteId}&date=${formattedDate}`;
      const response = await getApiMethod(endpoint);
      console.log("response",response)
      {response.sessions.length <= 0 ? setEmptyData(true) : setEmptyData(false)}
      setSessionData(response.sessions);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("Error in handleGetSession:", err);
    }
    setIsLoading(false);
  };

  const handleDateFilterClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleGetSession(date, getId);
    setShowDatePicker(false);
  };

  const handleViewAnalyticModal = (data) => {
    setBlockSet(data);
    setViewAnalyticDetails(!isViewAnalyticDetails);
  };

  return (
    <>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) :(
        <>
          <div
            className="athltbuttons"
            style={{ justifyContent: "space-between", padding: "0 0 40px", position: "relative" }}
          >
            <Form.Select
              aria-label={"Default select example"}
              value={getId}
              onChange={handleSelectionChange}
              style={{ width: "20%" }}
            >
              <option value="">Select an Athlete</option>
              {getData?.map((option, index) => {
                const label = `${option.athleteSubscriptions?.firstName} ${option.athleteSubscriptions?.lastName}`;
                return (
                  <option key={index} value={option.athleteID} style={{ textTransform: "capitalize" }}>
                    {label}
                  </option>
                );
              })}
            </Form.Select>
            <button
              className="invite-button"
              style={{ backgroundColor: "#A19686" }}
              onClick={handleDateFilterClick}
            >
              Date Filter
            </button>
            {showDatePicker && (
              <div style={{ position: "absolute", right: "0px", top: "40px" }}>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  inline
                />
              </div>
            )}
          </div>
          <div className="table-container">
            {
              EmptyData ? 
              <>
               <div
                  className="w-full d-flex flex-column justify-content-center align-items-center"
                  style={{ height: "20vw" }}
                >
                  <SvgIcons iconName="noSessionPlane" />
                  <div className="w-100 d-flex flex-column justify-content-center">
                    <h4 className="noSessionHeading d-flex justify-content-center">
                      No Session
                    </h4>
                    <div className="d-flex justify-content-center">
                      {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
                    </div>
                  </div>
                </div>
              </>

              :
              <table>
              <thead
                style={{
                  boxShadow: `0px 3px 8px 0px var(--primary-bg-color,rgba(0, 0, 0, 0.16))`,
                }}
              >
                <tr>
                  <th
                    style={{
                      textAlign: "start",
                      fontWeight: 700,
                      paddingLeft: "1rem ",
                      paddingBottom: "0.5rem",
                      paddingTop: "0.5rem",
                      fontFamily: "Hurme Geometric Sans 2",
                      backgroundColor: `var(--primary-color,#F6F6F0)`,
                      color: `var(--secondary-text-color,#a19686)`,
                    }}
                  >
                    Programme
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      paddingBottom: "0.5rem",
                      paddingTop: "0.5rem",
                      fontFamily: "Hurme Geometric Sans 2",
                      backgroundColor: `var(--primary-color,#F6F6F0)`,
                      color: `var(--secondary-text-color,#a19686)`,
                    }}
                  >
                    Weight & Intensity
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      paddingBottom: "0.5rem",
                      paddingTop: "0.5rem",
                      fontFamily: "Hurme Geometric Sans 2",
                      backgroundColor: `var(--primary-color,#F6F6F0)`,
                      color: `var(--secondary-text-color,#a19686)`,
                    }}
                  >
                    Block & Minutes
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      paddingBottom: "0.5rem",
                      paddingTop: "0.5rem",
                      fontFamily: "Hurme Geometric Sans 2",
                      backgroundColor: `var(--primary-color,#F6F6F0)`,
                      color: `var(--secondary-text-color,#a19686)`,
                    }}
                  >
                    Assigned by
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      paddingBottom: "0.5rem",
                      paddingTop: "0.5rem",
                      fontFamily: "Hurme Geometric Sans 2",
                      backgroundColor: `var(--primary-color,#F6F6F0)`,
                      color: `var(--secondary-text-color,#a19686)`,
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody style={{ borderStyle: "none" }} className="tableBody">
                {getSessionData?.map((data, index) => {
                  console.log(data)
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: `var(--secondary-bg-color,white)`,
                        boxShadow: `0px 3px 8px 0px var(--primary-bg-color,rgba(0, 0, 0, 0.16))`,
                      }}
                    >
                      <td className="chk">
                        <div>
                          <p style={{ color: `var(--primary-text-color,black)`, textTransform: "capitalize" }}>
                            {data.title}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="anaWeightIntentsity">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.87721 3.89759C3.87721 3.89759 3.87721 3.49601 4.16116 3.21206C4.16116 3.21206 4.44512 2.9281 4.84669 2.9281H6.30092C6.30092 2.9281 6.70249 2.9281 6.98645 3.21206C6.98645 3.21206 7.2704 3.49601 7.2704 3.89759V11.6535C7.2704 11.6535 7.2704 12.055 6.98645 12.339C6.98645 12.339 6.70249 12.623 6.30092 12.623H4.84669C4.84669 12.623 4.44512 12.623 4.16116 12.339C4.16116 12.339 3.87721 12.055 3.87721 11.6535V3.89759ZM4.84669 3.89759V11.6535H6.30092V3.89759H4.84669Z"
                              fill="white"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.1789 3.89759C10.1789 3.89759 10.1789 3.49601 10.4628 3.21206C10.4628 3.21206 10.7468 2.9281 11.1483 2.9281H12.6026C12.6026 2.9281 13.0041 2.9281 13.2881 3.21206C13.2881 3.21206 13.5721 3.49601 13.5721 3.89759V11.6535C13.5721 11.6535 13.5721 12.055 13.2881 12.339C13.2881 12.339 13.0041 12.623 12.6026 12.623H11.1483C11.1483 12.623 10.7468 12.623 10.4628 12.339C10.4628 12.339 10.1789 12.055 10.1789 11.6535V3.89759ZM11.1483 3.89759V11.6535H12.6026V3.89759H11.1483Z"
                              fill="white"
                            />
                            <path
                              d="M14.5415 10.1992H13.0873C12.8196 10.1992 12.6026 10.4163 12.6026 10.684C12.6026 10.9517 12.8196 11.1687 13.0873 11.1687H14.5415C14.9431 11.1687 15.2271 10.8848 15.2271 10.8848C15.511 10.6008 15.511 10.1992 15.511 10.1992V5.35181C15.511 4.95024 15.2271 4.66628 15.2271 4.66628C14.9431 4.38233 14.5415 4.38233 14.5415 4.38233H13.0873C12.8196 4.38233 12.6026 4.59936 12.6026 4.86707C12.6026 5.13479 12.8196 5.35181 13.0873 5.35181H14.5415V10.1992Z"
                              fill="white"
                            />
                            <path
                              d="M2.90772 5.35181H4.36195C4.62966 5.35181 4.84669 5.13479 4.84669 4.86707C4.84669 4.59936 4.62966 4.38233 4.36195 4.38233H2.90772C2.50615 4.38233 2.22219 4.66628 2.22219 4.66628C1.93824 4.95024 1.93824 5.35181 1.93824 5.35181V10.1992C1.93824 10.6008 2.22219 10.8848 2.22219 10.8848C2.50615 11.1687 2.90772 11.1687 2.90772 11.1687H4.36195C4.62966 11.1687 4.84669 10.9517 4.84669 10.684C4.84669 10.4163 4.62966 10.1992 4.36195 10.1992H2.90772V5.35181Z"
                              fill="white"
                            />
                            <path
                              d="M6.78566 8.26027H10.6636C10.9313 8.26027 11.1483 8.04324 11.1483 7.77553C11.1483 7.50781 10.9313 7.29078 10.6636 7.29078H6.78566C6.51795 7.29078 6.30092 7.50781 6.30092 7.77553C6.30092 8.04324 6.51795 8.26027 6.78566 8.26027Z"
                              fill="white"
                            />
                            <path
                              d="M14.5415 7.77553C14.5415 8.04324 14.7586 8.26027 15.0263 8.26027H15.9958C16.2635 8.26027 16.4805 8.04324 16.4805 7.77553C16.4805 7.50781 16.2635 7.29078 15.9958 7.29078H15.0263C14.7586 7.29078 14.5415 7.50781 14.5415 7.77553Z"
                              fill="white"
                            />
                            <path
                              d="M0.96875 7.77553C0.96875 8.04324 1.18578 8.26027 1.45349 8.26027H2.42298C2.69069 8.26027 2.90772 8.04324 2.90772 7.77553C2.90772 7.50781 2.69069 7.29078 2.42298 7.29078H1.45349C1.18578 7.29078 0.96875 7.50781 0.96875 7.77553Z"
                              fill="white"
                            />
                          </svg>
                          <p>{data.totalWeight}</p>
                        </div>

                        <div className="anaWeightIntentsity1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_2389_31548)">
                              <path
                                d="M10.3286 7.5958L8.90266 9.86226C8.84433 9.9431 8.77338 10.0141 8.69253 10.0724C8.49735 10.2086 8.25634 10.2622 8.02182 10.2218C7.78729 10.1814 7.57818 10.0501 7.43985 9.85645C7.30153 9.66279 7.24516 9.4224 7.28297 9.18744C7.32079 8.95248 7.44975 8.74191 7.64185 8.60144L9.90831 7.17553C9.95983 7.13962 10.0211 7.12036 10.0839 7.12036C10.1467 7.12036 10.208 7.13962 10.2595 7.17553C10.3242 7.22224 10.3678 7.29267 10.3808 7.37142C10.3937 7.45017 10.3749 7.53084 10.3286 7.5958Z"
                                fill="white"
                              />
                              <path
                                d="M8.2253 2.55591C4.51192 2.55591 1.50098 5.56384 1.50098 9.27723C1.49871 10.9201 2.10018 12.5066 3.19107 13.7351C3.22409 13.7711 3.25411 13.8071 3.28713 13.8402C3.35817 13.9171 3.4444 13.9784 3.54037 14.0202C3.63634 14.0621 3.73995 14.0835 3.84465 14.0833C3.94934 14.083 4.05284 14.061 4.14858 14.0186C4.24432 13.9762 4.33022 13.9144 4.40085 13.8372C4.88761 13.3082 5.47882 12.8859 6.13711 12.5971C6.79539 12.3083 7.50644 12.1591 8.2253 12.1591C8.94417 12.1591 9.65522 12.3083 10.3135 12.5971C10.9718 12.8859 11.563 13.3082 12.0498 13.8372C12.1204 13.9144 12.2063 13.9762 12.302 14.0186C12.3978 14.061 12.5013 14.083 12.606 14.0833C12.7107 14.0835 12.8143 14.0621 12.9102 14.0202C13.0062 13.9784 13.0924 13.9171 13.1635 13.8402L13.2595 13.7351C14.3504 12.5066 14.9519 10.9201 14.9496 9.27723C14.9496 5.56384 11.9387 2.55591 8.2253 2.55591Z"
                                stroke="white"
                                stroke-width="1.15274"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.22461 4.47717V5.43779"
                                stroke="white"
                                stroke-width="1.53699"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                              />
                              <path
                                d="M13.028 9.28015H12.0674"
                                stroke="white"
                                stroke-width="1.53699"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                              />
                              <path
                                d="M4.38249 9.28015H3.42188"
                                stroke="white"
                                stroke-width="1.53699"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                              />
                              <path
                                d="M5.50746 6.56288L4.82812 5.88354"
                                stroke="white"
                                stroke-width="1.53699"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                              />
                              <path
                                d="M10.9414 6.56288L11.6207 5.88354"
                                stroke="white"
                                stroke-width="1.53699"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2389_31548">
                                <rect
                                  width="15.3699"
                                  height="15.3699"
                                  fill="white"
                                  transform="translate(0.540039 0.634644)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <p>{data.intensity}</p>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="blockMin">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                            >
                              <path
                                d="M6.01619 7.66842C5.92682 7.58301 5.80796 7.53535 5.68435 7.53535C5.68425 7.53535 5.67345 7.53548 5.67345 7.53548C5.5461 7.53837 5.42511 7.59173 5.3371 7.68382C5.2517 7.77319 5.20404 7.89205 5.20404 8.01566L5.20416 8.02656C5.20705 8.15391 5.26041 8.2749 5.35251 8.36291L7.11164 10.044C7.29718 10.2213 7.58933 10.2214 7.77502 10.0443L11.2992 6.68222C11.3913 6.59429 11.4449 6.47324 11.4479 6.34588C11.4481 6.33819 11.4481 6.3305 11.4479 6.32281C11.445 6.20344 11.3977 6.08944 11.3153 6.00304C11.2246 5.90803 11.0991 5.85427 10.9677 5.85427L10.9542 5.85446C10.8354 5.85782 10.7222 5.90506 10.6362 5.98705L7.44376 9.03265L6.01619 7.66842Z"
                                fill="white"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.32605 1.53149C8.32605 1.53149 9.59607 1.53149 10.7566 2.02238C10.7566 2.02238 11.8773 2.49636 12.7412 3.36032C12.7412 3.36032 13.6052 4.22429 14.0792 5.34491C14.0792 5.34491 14.5701 6.50549 14.5701 7.77551C14.5701 7.77551 14.5701 9.04553 14.0792 10.2061C14.0792 10.2061 13.6052 11.3267 12.7412 12.1907C12.7412 12.1907 11.8773 13.0547 10.7566 13.5286C10.7566 13.5286 9.59606 14.0195 8.32605 14.0195C8.32605 14.0195 7.05603 14.0195 5.89545 13.5286C5.89545 13.5286 4.77482 13.0547 3.91086 12.1907C3.91086 12.1907 3.0469 11.3267 2.57291 10.2061C2.57291 10.2061 2.08203 9.04553 2.08203 7.77551C2.08203 7.77551 2.08203 6.50549 2.57291 5.34491C2.57291 5.34491 3.0469 4.22429 3.91086 3.36032C3.91086 3.36032 4.77482 2.49636 5.89545 2.02238C5.89545 2.02238 7.05602 1.53149 8.32605 1.53149ZM8.32605 2.49211C8.32605 2.49211 7.25082 2.49211 6.26966 2.90711C6.26966 2.90711 5.3216 3.3081 4.59012 4.03958C4.59012 4.03958 3.85864 4.77106 3.45765 5.71912C3.45765 5.71912 3.04265 6.70028 3.04265 7.77551C3.04265 7.77551 3.04265 8.85073 3.45765 9.8319C3.45765 9.8319 3.85864 10.78 4.59012 11.5114C4.59012 11.5114 5.3216 12.2429 6.26966 12.6439C6.26966 12.6439 7.25083 13.0589 8.32605 13.0589C8.32605 13.0589 9.40126 13.0589 10.3824 12.6439C10.3824 12.6439 11.3305 12.2429 12.062 11.5114C12.062 11.5114 12.7935 10.78 13.1944 9.8319C13.1944 9.8319 13.6094 8.85073 13.6094 7.77551C13.6094 7.77551 13.6094 6.70029 13.1944 5.71912C13.1944 5.71912 12.7934 4.77106 12.062 4.03958C12.062 4.03958 11.3305 3.3081 10.3824 2.90711C10.3824 2.90711 9.40127 2.49211 8.32605 2.49211Z"
                                fill="white"
                              />
                            </svg>
                            <p>{data.totalBlocks}/4</p>
                          </div>

                          <div className="blockMin1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.82605 2.06848C7.82605 2.06848 9.09607 2.06848 10.2566 2.55936C10.2566 2.55936 11.3773 3.03335 12.2412 3.89731C12.2412 3.89731 13.1052 4.76127 13.5792 5.8819C13.5792 5.8819 14.0701 7.04247 14.0701 8.3125C14.0701 8.3125 14.0701 9.58251 13.5792 10.7431C13.5792 10.7431 13.1052 11.8637 12.2412 12.7277C12.2412 12.7277 11.3773 13.5916 10.2566 14.0656C10.2566 14.0656 9.09606 14.5565 7.82605 14.5565C7.82605 14.5565 6.55603 14.5565 5.39545 14.0656C5.39545 14.0656 4.27482 13.5916 3.41086 12.7277C3.41086 12.7277 2.5469 11.8637 2.07291 10.7431C2.07291 10.7431 1.58203 9.58252 1.58203 8.3125C1.58203 8.3125 1.58203 7.04247 2.07291 5.8819C2.07291 5.8819 2.5469 4.76127 3.41086 3.89731C3.41086 3.89731 4.27482 3.03335 5.39545 2.55936C5.39545 2.55936 6.55602 2.06848 7.82605 2.06848ZM7.82605 3.0291C7.82605 3.0291 6.75082 3.0291 5.76966 3.4441C5.76966 3.4441 4.8216 3.84509 4.09012 4.57657C4.09012 4.57657 3.35864 5.30805 2.95765 6.25611C2.95765 6.25611 2.54265 7.23727 2.54265 8.3125C2.54265 8.3125 2.54265 9.38772 2.95765 10.3689C2.95765 10.3689 3.35864 11.3169 4.09012 12.0484C4.09012 12.0484 4.8216 12.7799 5.76966 13.1809C5.76966 13.1809 6.75083 13.5959 7.82605 13.5959C7.82605 13.5959 8.90126 13.5959 9.88243 13.1809C9.88243 13.1809 10.8305 12.7799 11.562 12.0484C11.562 12.0484 12.2935 11.3169 12.6944 10.3689C12.6944 10.3689 13.1094 9.38771 13.1094 8.3125C13.1094 8.3125 13.1094 7.23728 12.6944 6.25611C12.6944 6.25611 12.2934 5.30805 11.562 4.57657C11.562 4.57657 10.8305 3.84509 9.88243 3.4441C9.88243 3.4441 8.90127 3.0291 7.82605 3.0291Z"
                                fill="white"
                              />
                              <path
                                d="M7.82605 8.7928H11.1882C11.4535 8.7928 11.6685 8.57776 11.6685 8.3125C11.6685 8.04723 11.4535 7.83219 11.1882 7.83219H8.30635V4.95033C8.30635 4.68507 8.09131 4.47003 7.82605 4.47003C7.56078 4.47003 7.34574 4.68507 7.34574 4.95033V8.3125C7.34574 8.57776 7.56078 8.7928 7.82605 8.7928Z"
                                fill="white"
                              />
                            </svg>
                            <p>{data.totalDuration}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p
                          style={{
                            textAlign: "center",
                            margin: 0,
                            fontFamily: "Hurme Geometric Sans 2",
                            color: `var(--primary-text-color,black)`,
                          }}
                        >
                          {data.assigned}
                        </p>
                      </td>
                      <td style={{ float: "right", paddingRight: "0.5rem" }}>
                        <div className="anaActionBtn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            onClick={() => handleViewAnalyticModal(data)}
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M23.3104 12.8487C23.3104 12.8487 23.1009 13.3199 22.6737 13.9984C22.6737 13.9984 21.832 15.3352 20.7178 16.4494C20.7178 16.4494 17.1232 20.0441 12.125 20.0441C12.125 20.0441 7.12684 20.0441 3.53217 16.4494C3.53217 16.4494 2.41798 15.3352 1.57626 13.9984C1.57626 13.9984 1.14906 13.3199 0.939641 12.8487C0.853453 12.6547 0.853453 12.4334 0.939641 12.2395C0.939641 12.2395 1.14906 11.7683 1.57626 11.0898C1.57626 11.0898 2.41798 9.75293 3.53217 8.63874C3.53217 8.63874 7.12684 5.04407 12.125 5.04407C12.125 5.04407 17.1231 5.04407 20.7178 8.63874C20.7178 8.63874 21.832 9.75292 22.6737 11.0898C22.6737 11.0898 23.1009 11.7683 23.3104 12.2395C23.3965 12.4334 23.3965 12.6547 23.3104 12.8487ZM21.4044 13.1991C21.4044 13.1991 21.6375 12.8289 21.7894 12.5441C21.7894 12.5441 21.6375 12.2592 21.4044 11.889C21.4044 11.889 20.6523 10.6946 19.6572 9.6994C19.6572 9.6994 16.5018 6.54407 12.125 6.54407C12.125 6.54407 7.74816 6.54407 4.59283 9.6994C4.59283 9.6994 3.59765 10.6946 2.84561 11.889C2.84561 11.889 2.61253 12.2592 2.46058 12.5441C2.46058 12.5441 2.61253 12.829 2.84561 13.1991C2.84561 13.1991 3.59765 14.3936 4.59283 15.3887C4.59283 15.3887 7.74816 18.5441 12.125 18.5441C12.125 18.5441 16.5018 18.5441 19.6572 15.3887C19.6572 15.3887 20.6523 14.3936 21.4044 13.1991Z"
                              fill="#4B52FF"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.125 8.04407C12.125 8.04407 13.989 8.04407 15.307 9.36209C15.307 9.36209 16.625 10.6801 16.625 12.5441C16.625 12.5441 16.625 14.408 15.307 15.726C15.307 15.726 13.989 17.0441 12.125 17.0441C12.125 17.0441 10.261 17.0441 8.94302 15.726C8.94302 15.726 7.625 14.408 7.625 12.5441C7.625 12.5441 7.625 10.6801 8.94302 9.36209C8.94302 9.36209 10.261 8.04407 12.125 8.04407ZM12.125 9.54407C12.125 9.54407 10.8824 9.54407 10.0037 10.4227C10.0037 10.4227 9.125 11.3014 9.125 12.5441C9.125 12.5441 9.125 13.7867 10.0037 14.6654C10.0037 14.6654 10.8824 15.5441 12.125 15.5441C12.125 15.5441 13.3676 15.5441 14.2463 14.6654C14.2463 14.6654 15.125 13.7867 15.125 12.5441C15.125 12.5441 15.125 11.3014 14.2463 10.4227C14.2463 10.4227 13.3676 9.54407 12.125 9.54407Z"
                              fill="#4B52FF"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {isViewAnalyticDetails && <AnalyticModal isOpen={isViewAnalyticDetails} onClose={handleViewAnalyticModal} data={isBlockSet} />}
            </table>
            }
            
          </div>
        </>
      )}
    </>
  );
};

export default AnalysisAtheleteTable;
