import React from 'react'
import { contact, EllipseLight, globe, SecSide, winFile } from '../../../../../assessts/LandingPage'
import style from './secComp.module.css'

const SecComp = () => {

    const iconInfo = [
        { img: winFile, text: 'Build and Sell Your Programs' },
        { img: contact, text: 'Manage and Grow Clientel' },
        { img: globe, text: 'Track and Measure Performance' },
        { img: winFile, text: 'Monetise Your Knowledge' },
    ]
    return (
        <div className={`${style.main} d-flex flex-column`}>
            <div className={`${style.beforeMap}`}>
                {iconInfo.map((data, key) => (
                    <div key={key} className={`${style.icons} `}>
                        <img src={data.img} alt={data.text} />
                        <p>{data.text}</p>
                    </div>
                ))}
            </div>

            <div className={`${style.belowDiv} align-items-center justify-content-center`}>
                <div className={`${style.text} d-flex flex-column`}>
                    <h1>OPTIMISE YOUR OUTPUT</h1>
                    <p>Unleash the true potential of your coaching abilities with TrainingApp, the game-changing platform that will revolutionise your business and propel it to new heights. Scale your coaching services beyond your wildest imagination with our innovative features and tools.</p>
                </div>
                <div className={`${style.imgDIV} d-flex flex-row`}>
                    <img src={EllipseLight} alt="" />
                    <div className='d-flex flex-column'>
                        <img src={EllipseLight} className={`${style.ecli}`} />
                        <img src={SecSide} className={`${style.mainPic}`} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecComp
