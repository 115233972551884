// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.selected-option {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.dropdown-options {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 150px; 
  overflow-y: auto; 
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f1f1f1;
}

.add-exercise {
  border-top: 1px solid #ccc;
  background-color: #f7f7f7;
  position: sticky;
  bottom: 0; /* Stick the button to the bottom */
  width: 100%; /* Ensure the button takes the full width */
  z-index: 1001; /* Make sure the button stays above the options */
}

.add-exercise button {
  width: 100%;
  padding: 8px 0;
  border: none;
  background-color: #a19686;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.add-exercise button:hover {
  background-color: #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/Form/AthleteSessionForm/CreateAtheleteSession/AtheleteSessionBlock/DropDown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,gBAAgB;EAChB,SAAS,EAAE,mCAAmC;EAC9C,WAAW,EAAE,2CAA2C;EACxD,aAAa,EAAE,iDAAiD;AAClE;;AAEA;EACE,WAAW;EACX,cAAc;EACd,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".custom-select {\r\n  position: relative;\r\n  width: 100%;\r\n  cursor: pointer;\r\n}\r\n\r\n.selected-option {\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  background-color: #f9f9f9;\r\n  border-radius: 4px;\r\n}\r\n\r\n.dropdown-options {\r\n  position: absolute;\r\n  width: 100%;\r\n  border: 1px solid #ccc;\r\n  background-color: white;\r\n  z-index: 1000;\r\n  border-radius: 4px;\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n  max-height: 150px; \r\n  overflow-y: auto; \r\n}\r\n\r\n.dropdown-option {\r\n  padding: 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.dropdown-option:hover {\r\n  background-color: #f1f1f1;\r\n}\r\n\r\n.add-exercise {\r\n  border-top: 1px solid #ccc;\r\n  background-color: #f7f7f7;\r\n  position: sticky;\r\n  bottom: 0; /* Stick the button to the bottom */\r\n  width: 100%; /* Ensure the button takes the full width */\r\n  z-index: 1001; /* Make sure the button stays above the options */\r\n}\r\n\r\n.add-exercise button {\r\n  width: 100%;\r\n  padding: 8px 0;\r\n  border: none;\r\n  background-color: #a19686;\r\n  color: white;\r\n  cursor: pointer;\r\n  border-radius: 4px;\r\n}\r\n\r\n.add-exercise button:hover {\r\n  background-color: #ccc;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
