import React from 'react'
import style from './Program1.module.css'
import Benefits from './Benefits/Benefits'
import CoachDes from './CoachDescription/CoachDes'
import Bundle from './Bundle/Bundle'
import Frequently from './FrequentlyAskedQuestion/Frequently'

const Program1 = () => {
  return (
    <div className={`${style.main} `}>
     <div className={`${style.bgIMG}`}>
     </div>
     <Bundle/>
     <Benefits/>
     <CoachDes/>
     <Frequently/>
    </div>
  )
}

export default Program1
