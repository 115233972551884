import React from 'react'
import { FaSearch } from 'react-icons/fa'

const SearchInput = () => {
  return (
    <div className='w-100'>
    <div className="search-container w-100">
        <span className="search-icon" style={{ marginTop: "-4px" }}>
            <FaSearch />
        </span>
        <input
            type="text"
            placeholder="Search"
            className="search-input"
        />
    </div>
    
</div>
  )
}

export default SearchInput