import React, { createContext, useContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const[currentUser, setCurrentUser] = useState()
  useEffect(()=>{
    const itemValue = localStorage.getItem('IsLogged');
    setCurrentUser(itemValue)
  },[])
  const login = (userData) => {
    // Logic to set the user data and authenticate
    setUser(userData);
  };

  const logout = () => {
    // Logic to log the user out
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, setCurrentUser, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
