import axios from "axios";
import { auth } from "../firebase";



export const getApiMethod = async (endpoint, method = 'get') => {
    try {
        const token = await auth.currentUser.getIdToken(true);
        const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
        const config = { headers: { Authorization: `Bearer ${token}` } }

        // Convert method to lowercase
        const response = await axios[method](url, config);

        return response.data;
    } catch (error) {
        console.error("API call error:", error);
        throw error;
    }
};
