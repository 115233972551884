import React, { useState, useEffect } from 'react';

const Tabs = ({ tabs = [], onTabSelect, isCustomComp }) => {
    // Check local storage for the active tab, or default to the first tab
    const getInitialTab = () => {
        return localStorage.getItem('activeAtheleteTab') || tabs[0]?.id || '';
    };

    // Set the initial state of the active tab
    const [activeTab, setActiveTab] = useState(getInitialTab);

    // Update local storage whenever the active tab changes
    useEffect(() => {
        localStorage.setItem('activeAtheleteTab', activeTab);
    }, [activeTab]);

    const handleTabClick = (tabId) => {
        localStorage.setItem('activeAtheleteTab',tabId)
        setActiveTab(tabId);
        if (onTabSelect) {
            onTabSelect(tabId);
        }
    };

    return (
      <div
  className="businessHeaderbuttons"
  style={{
    margin: isCustomComp === true ? '18px 7%' : '18px auto',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'var(--primary-color, #F6F6F0)',
  }}
>
  {tabs.map((tab) => (
    <div
      key={tab.id}
      style={{
        width: '132px',
        height: '45px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: activeTab === tab.id ? 'var(--secondary-color, #A19686)' : 'transparent',
      }}
    >
      <button
        style={{
          width: '100%',
          background: activeTab === tab.id ? '' : 'transparent',
        }}
        onClick={() => handleTabClick(tab.id)}
      >
        <span
          style={{
            color: activeTab === tab.id ? 'var(--secondary-text-color, white)' : 'var(--primary-text-color, black)',
          }}
        >
          {tab.label}
        </span>
      </button>
    </div>
  ))}
</div>

    );
};

export default Tabs;
