import React from 'react'
import { Conveience, Ellipse1, GreenCheck } from '../../../../../../assessts/LandingPage'
import style from './six.module.css'

const Six = () => {
    return (
        <div className={`${style.main} d-flex align-items-center justify-content-center`}>
            <div className={`${style.imgDIV} d-flex flex-row`}> 
                <div className='d-flex flex-column'>
                    <img src={Ellipse1} className={`${style.ecli}`}/>
                    <img src={Conveience} className={`${style.mainPic}`}/>
                </div>
                <img src={Ellipse1} className={`${style.ecli2}`}/>
            </div>

            <div className={`${style.content} d-flex flex-column `}>
                <h1>Convenience at your fingertips</h1>
                <div className='d-flex align-items-center'>
                    <img src={GreenCheck} />
                    <p>Pay for your one-on-one sessions or programming directly through the app.</p>
                </div>
                <div className='d-flex align-items-center'>
                    <div>
                        <img src={GreenCheck} />
                    </div>
                    <p>No more manual transactions or delays—simply make secure and hassle-free payments with ease.</p>
                </div>
            </div>
        </div>
    )
}

export default Six
