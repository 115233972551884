import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { auth } from "../../../../firebase";
import { toast } from "react-toastify";
import axios from "axios";
import { saveOneRepMaxValue } from "../../../../apis";


const ModalUi = ({ onClose, isOpen, exerciseId, valueResponse }) => {

    const [loader, setLoader] = useState(false)

    const [formData, setFormData] = useState(
        {
            exerciseId: exerciseId,
            value: ""
        }
    )


    const handleInputChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    };

    const handleCreateAthelete = async () => {
        try {
            setLoader(true)
            const token = await auth.currentUser.getIdToken(true);
            const endpoint = saveOneRepMaxValue;
            const payload = { ...formData };

            await makeApiCall(endpoint, payload, token);
            onClose();
            setLoader(false);
            toast.success("Athelete Invited Sucessfully");
        } catch (err) {
            setLoader(false);
            onClose();
            console.error(err);
        }
    }


    /**
 * Makes an API call to the given endpoint with the provided payload and token.
 * @param {string} endpoint - API endpoint
 * @param {Object} payload - Data to send in the API request
 * @param {string} token - Authorization token
 */
    const makeApiCall = async (endpoint, payload, token) => {
        const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
        const config = {
            headers: { Authorization: token }
        };
        await axios.post(url, payload, config);
    }

    return (
        <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`}>
            <div className="exercise-overlay" onClick={onClose}></div>
            <div className="exercisemmodalBody" style={{ width: "30vw" }}>
                <h2 style={{ textTransform: "uppercase" }}>Working Max For 1 Rep</h2>
                <Container>
                    <Row className='mb-3'>
                        <Col xs={12} md={12} className='p-0'>
                            <Form.Group controlId='frmB'>
                                <Form.Label className='customInputLabel'>Value</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={valueResponse}
                                    className='customInput'
                                    onChange={(e) => handleInputChange(e, 'value')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='d-flex justify-content-end mt-3' >

                            <Button
                                variant="secondary"
                                className='programSave'
                                onClick={handleCreateAthelete}
                            >
                                Send
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};


export default ModalUi