import React from 'react'
import style from './athmain.module.css'
import { DayNight } from '../../../../../../assessts/LandingPage'
import Button from '../../../../Components/Button/Button'

const Main = () => {
  return (
    <div className={`${style.bgImg}`}>
            <div className={`${style.content}`}>
                <div className={`${style.TEXT} d-flex flex-column`}>
                    <h1 className='text-center'>PERFORM, EXCEL AND IMPROVE EFFECIENTLY</h1>
                    <p>A world where being an athlete is not just about physical prowess, but about embracing a seamless and empowering experience that promotes better performance.</p>
                    <div className={`${style.btnDIV} d-flex`}>
                    <Button title={'Start Training Now'} bg={true}/>
                    <Button title={'Available Programs'} bg={false}/>
                    </div>
                </div>
                <div className={`${style.Img} d-flex`}>
                    <img src={DayNight} alt="" />
                </div>
            </div>
        </div>
  )
}

export default Main
