import React from 'react'
import { Gym1, Gym2, Gym3 } from '../../../../../../assessts/LandingPage'
import style from './sec.module.css'

const Second = () => {
     
    const data = [
        { img: Gym1, name: "Mike Smith", Mentor: "Jimmy Karter"},
        { img: Gym2, name: "Mike Smith", Mentor: "Jimmy Karter"},
        { img: Gym3, name: "Mike Smith", Mentor: "Jimmy Karter"},
        { img: Gym1, name: "Mike Smith", Mentor: "Jimmy Karter"},
    ]
  return (
    <div className={`${style.main} d-flex flex-column align-items-center justify-content-center`}>
        <hr />
        <div className='d-flex flex-column text-center'> 
        <h1>GYM OWNERS</h1>
        <p>Join the movement of amazing gyms optimising their output and performance.</p>
        </div>
      <div className={`${style.beforeMap} `}>
      {data.map((data, key)=>(
        <div key={key} className={`${style.mapped} d-flex flex-column`}>
            <img src={data.img} alt="" />
            <div>
                <h1>{data.name}</h1>
                <p>Mentor: {data.Mentor}</p>
            </div>
        </div>
      ))}
      </div>

    </div>
  )
}

export default Second
