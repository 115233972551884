// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 70%;
  }
  
  .CompleteSession {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .custom-box {
    background: #09d0ad;
    min-width: 10px;
    min-height: 30px;
    border-radius: 100px;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 14px;
  }
  
  .custom-box h5, .custom-box p {
    margin: 0;  /* Remove default margins */
    padding: 0;
  }
  
  .custom-box p {
    margin-bottom: 5px; /* Optional: add a small space between p and h5 */
  }
  
  .main .custom-box:nth-child(2n) {
    margin-right: 10px; /* Add space between every second div */
  }
  .main_text{
    font-size: 10px;
  }
  .sub{
    font-size: 15px;
  }`, "",{"version":3,"sources":["webpack://./src/components/modules/Athelete/TraningSession/Details/CompleteSession.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,SAAS,GAAG,2BAA2B;IACvC,UAAU;EACZ;;EAEA;IACE,kBAAkB,EAAE,iDAAiD;EACvE;;EAEA;IACE,kBAAkB,EAAE,uCAAuC;EAC7D;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB","sourcesContent":[".main {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 30px;\r\n    width: 70%;\r\n  }\r\n  \r\n  .CompleteSession {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n  }\r\n  \r\n  .custom-box {\r\n    background: #09d0ad;\r\n    min-width: 10px;\r\n    min-height: 30px;\r\n    border-radius: 100px;\r\n    padding: 10px 5px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    color: white;\r\n    font-size: 14px;\r\n  }\r\n  \r\n  .custom-box h5, .custom-box p {\r\n    margin: 0;  /* Remove default margins */\r\n    padding: 0;\r\n  }\r\n  \r\n  .custom-box p {\r\n    margin-bottom: 5px; /* Optional: add a small space between p and h5 */\r\n  }\r\n  \r\n  .main .custom-box:nth-child(2n) {\r\n    margin-right: 10px; /* Add space between every second div */\r\n  }\r\n  .main_text{\r\n    font-size: 10px;\r\n  }\r\n  .sub{\r\n    font-size: 15px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
