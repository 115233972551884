import React, { useContext, useEffect } from "react";
import SignupForm from "../../components/forms/signupForm";
import SignupLogo from "../../assessts/signuplogo.png";
import { useLocation } from "react-router-dom";
import AtheleteSignupForm from "../../components/forms/atheleteSignup"
import { ThemeContext } from '../../context/themeContext';
import SignupLogoDark from '../../assessts/logotrnDark.png'

import './styles.css'
import { Col, Container, Row } from "react-bootstrap";


const SignUp = () => {
  const location = useLocation();
  const { theme, setLocation } = useContext(ThemeContext);
  useEffect(() => {
    setLocation(window.location.pathname)
  }, [])

  const userData = location.state;
  return (
    <Container fluid className='chkkk w-100'>
      <Row className="w-100 d-flex justify-content-center ">
        <Col md={6} lg={6} sm={10} className="p-2">
            <div className="containerSign loginForm p-4">
              <img src={theme === 'light' ? SignupLogo : SignupLogoDark} height="60.06px" style={{objectFit: "contain"}} width="350px" />
              <p className="logInfo p-0">Please enter the Signup information</p>
              {userData?.userType === "coach" ? (
                <div className="w-100">
                  <SignupForm user={userData?.userType} />
                </div>
              ) : (
                <div className="">
                  <AtheleteSignupForm user={userData?.userType} />
                </div>
              )}
            </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
