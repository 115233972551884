import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ViewSummaryModel.css";
import SvgIcon from "../../../../../assessts/Svg/svgIcons";
import logo from "../../../../../assessts/thetraining.png";
const ViewSummaryModel = ({ getGroupSesssionDetails,isOpen, onClose, getAtheleteSessionExercise,totalSets,totalRepsValue,totalBlocks,selectedDate }) => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    const formatDate = (selectedDate) => {
      const date = new Date(selectedDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    if (selectedDate) {
      const formatted = formatDate(selectedDate);
      setFormattedDate(formatted);
    }
  }, [selectedDate]);
  return (
    <div>
      <div className={`exercise-modal ${isOpen ? "exercise-open" : ""}`}>
        <div className="exercise-overlay" onClick={onClose}></div>
        <div
          className="exercisemmodalBody"
          style={{
            width: "30vw",
            height: "60vh", // Add height here
            backgroundColor: "white",
          }}
        >
          <div className="feedcompleted">
            <h2
              style={{
                textTransform: "uppercase",
                color: "black",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
             {formattedDate}
            </h2>

            <div className="weight">
              <img width="49px" height="49px" src={logo} alt="" />
              <p className="weighttext">20Kg</p>
            </div>

            <div className="main_model">
              <div className="modeldata">
                <p className="subtext">Exercise</p>
                <p className="text">{totalBlocks}</p>
              </div>

              <div className="modeldata">
                <p className="subtext">Sets</p>
                <p className="text">{totalSets}</p>
              </div>
              <div className="modeldata">
                <p className="subtext">Reps</p>
                <p className="text">{totalRepsValue}</p>
              </div>
            </div>

            <div className="main" style={{ width: "350px" }}>
              <div
                className="custom-box"
                style={{
                  background: "#25BD3D",
                }}
              >
                <SvgIcon iconName="block" size={30} color="blue" />
                <p className="main_text">Blocks</p>
                <h5 className="sub_text">
                  {getAtheleteSessionExercise || getGroupSesssionDetails ? getAtheleteSessionExercise?.totalBlocks  || getGroupSesssionDetails?.totalBlocks : "0"}/5
                </h5>
              </div>
              <div
                className="custom-box"
                style={{
                  background: "#DC5C3E",
                }}
              >
                <SvgIcon iconName="minutes" size={30} color="blue" />
                <p className="main_text">Minutes</p>
                <h5 className="sub_text">
                  {getAtheleteSessionExercise || getGroupSesssionDetails ? getAtheleteSessionExercise?.totalDuration || getGroupSesssionDetails?.totalDuration : "0"}
                </h5>
              </div>
              <div
                className="custom-box"
                style={{
                  background: "#3919EC",
                }}
              >
                <SvgIcon iconName="infinty" size={30} color="blue" />
                <p className="main_text">Intensity</p>
                <h5 className="sub_text">
                  {getAtheleteSessionExercise || getGroupSesssionDetails ? getAtheleteSessionExercise?.intensity || getGroupSesssionDetails?.intensity : "0"}
                </h5>
              </div>
              <div
                className="custom-box"
                style={{
                  background: "#09D0AD",
                }}
              >
                <SvgIcon iconName="LB" size={30} color="blue" />
                <p className="main_text">LB</p>
                <h5 className="sub_text">
                  {getAtheleteSessionExercise || getGroupSesssionDetails ? getAtheleteSessionExercise?.totalWeight || getGroupSesssionDetails?.totalWeight : "0"}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSummaryModel;
