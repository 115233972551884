import React, { useContext, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { ThemeContext } from "../../context/themeContext";
import styles from "./layout.module.css";
import HomePage from "../../components/coachPortal/home/homePage";
import PreviewSession from "../../components/coachPortal/athelete/previewSession/previewSession";
import Athelete from "../../components/coachPortal/athelete/athelete";

import Group from "../../components/coachPortal/group/group";
import Index from "../../components/index/index";
import Analytics from "../../components/coachPortal/analytics/analytics";
import Business from "../../components/coachPortal/business/business";
import Customization from "../../components/coachPortal/customization/customization";
import { checkCoachSubscription } from "../../services/store/coachApi";
import useCheckCoachSub from "../../hooks/useCheckCoachSub";
import Loader from "../../components/ReusableUi/Loader";
import MonthlyCalender from "../../components/modules/Calender/MonthCalender";



const CoachPortal = () => {
  const { activeTab, activeComponent } = useContext(ThemeContext);
  const { fetchSubscription, subscriptionPlan, loading, error } = useCheckCoachSub(
    checkCoachSubscription
  );

  console.log(subscriptionPlan);
  useEffect(() => {
    fetchSubscription();
  }, []);

  if (loading) {
    return (
      <div className={styles.center}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const isGrowthPlan = subscriptionPlan?.subscription?.plan?.name === "growth";
  const isProfessionalPlan = subscriptionPlan?.subscription?.plan?.name === "professional";

  return (
    <Container className={`${styles.coachPortalContainer} my-4`}>
      <Row className="mx-2">
        {subscriptionPlan?.Subscriptions === false ? (
          activeTab === "business" && !activeComponent && <Business />
        ) : (
          <>
            {activeTab === "coach" && <HomePage isGrowthPlan={isGrowthPlan} />}
            {(activeTab === "athelete" && activeComponent === "preview") ||
              (activeTab === "group" && activeComponent === "preview") ||
              (activeTab === "index" && activeComponent === "preview") ? (
              <PreviewSession />
            ) : null}

            {activeTab === "athelete" && !activeComponent && <Athelete isGrowthPlan={isGrowthPlan} />}

            {activeTab === "athelete" && activeComponent === "calendar" && (
              <MonthlyCalender />
            )}

            {activeTab === "group" && !activeComponent && <Group />}

            {activeTab === "index" && !activeComponent && <Index />}

            {/* Analytics and Customization conditions for Growth and Professional Plans */}
            {activeTab === "analytics" && !activeComponent && (

              <Analytics isGrowthPlan={isGrowthPlan} />

            )}

            {activeTab === "custom" && !activeComponent && (

              <Customization isProfessionalPlan={isProfessionalPlan} isGrowthPlan={isGrowthPlan} />

            )}

            {activeTab === "business" && !activeComponent && <Business />}
          </>
        )}
      </Row>
    </Container>
  );
};

export default CoachPortal;
