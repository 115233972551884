import React from 'react'
import Layout from '../../Layout'
import Main from './Main/Main'
import SecComp from './SecComp/SecComp'
import ThirdComp from './ThirdComp/ThirdComp'
import Fourth from './FourthComp/Fourth'
import Fifth from './FifthComp/Fifth'
import Sixth from './SixthComp/Sixth'
import Last from './LastComp/Last'

const Coach = () => {
    return (
        <Layout>
            <div>
            <Main />
            <SecComp />
            <ThirdComp />
            <Fourth/>
            <Fifth/>
            <Sixth/>
            <Last/>
            </div>
        </Layout>
    )
}

export default Coach
