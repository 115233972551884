import React, { useState, useRef } from 'react';
import './Tooltip.css';

const Tooltip = ({ children, content, position = "top" }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div className="tooltip-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {visible && (
        <div ref={tooltipRef} className={`tooltip-box tooltip-${position}`}>
          <div className="tooltip-arrow" />
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
