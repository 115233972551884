import React, { useEffect, useState } from 'react'
import styles from "./table.module.css"
import Av from "../../assessts/images/athleteSource.png"
import moment from 'moment';

const Table = (props) => {
  // Get Data From Props
  const { activeTab, data, setIsOpenExerModal, setIsProgramModal, setCurrentExercise, selectedPrograms, showDeleteCheckBox, setSelectedPrograms } = props;

  // State For Handle Table Heading
  const [currentHeaders, setCurrentHeaders] = useState([])


  // Define Function Where you Match Data from Active Tab And You Will Get Data
  useEffect(() => {
    // Define the headers for different tabs
    const headers = {
      manageAthelete: ["Athlete", "Subscription Type", "Coach", "Joined", "Cost", "Actions"],
      manageCoach: ["Coach", "Program Name", "Assitant Coach", "Subscription", "Athlete", "Actions"],
      manageExercise: ["Title", "URL", "Instruction", "Date", "Action"],
      manageMarketplace: ["Programer", "Coach", "Fee", "Level", "Action"],
      manageBusiness: ["Athlete", "Program", "Coach Name", "Market Place", "Price", "Date"]
    };

    // Ensure activeTab is a string before attempting to call string methods on it
    const formattedActiveTab = typeof activeTab === 'string'
      ? activeTab.charAt(0).toLowerCase() + activeTab.slice(1)
      : '';

    setCurrentHeaders(headers[formattedActiveTab] || []);

  }, [activeTab]);

  const handleEdit = (program) => {
    setCurrentExercise(program)
    setIsOpenExerModal(true);
  }

  const handleCheckboxChange = (programId) => {

    setSelectedPrograms((prevSelectedPrograms) => {
      if (prevSelectedPrograms.includes(programId)) {
        return prevSelectedPrograms.filter((id) => id !== programId);
      } else {
        return [...prevSelectedPrograms, programId];
      }
    });
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr className={`${styles.custom_table_row}`}>
            {currentHeaders.map((header, index) => (
              <th key={index} >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ borderStyle: "none" }} className="tableBody">
          {data?.map((data, index) => {
            return (
              <>
                <br />
                {activeTab === "manageAthelete" && (
                  <tr key={index}>
                    <td className="chk">
                      {showDeleteCheckBox && (
                        <>
                          <label class="containerInput">
                            <input
                              type='checkbox'
                              checked={selectedPrograms?.includes(data?.athleteID)}
                              onChange={() => handleCheckboxChange(data?.athleteID)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </>
                      )}
                      <img width="48px" height="48px" src={data.img} alt='' />
                      <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.athlete.firstName}</p>
                    </td>
                    <td style={{ padding: "0px", textAlign: "center" }}>
                      <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.subscriptionType}</p>
                    </td>
                    <td style={{ padding: "0px" }}>
                      <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.coach.firstName}</p>
                    </td>
                    <td style={{ padding: "0px" }}>
                      <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{moment(data.createdAt).format('D/MM/YYYY')}</p>
                    </td>
                    <td style={{ padding: "0px" }}>
                      <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data?.payment?.amount}</p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div className='actionIconRedCon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67773 1.5C8.67773 1.5 9.99982 1.5 11.208 2.01101C11.208 2.01101 12.3745 2.50442 13.2739 3.40381C13.2739 3.40381 14.1733 4.30319 14.6667 5.46975C14.6667 5.46975 15.1777 6.67791 15.1777 8C15.1777 8 15.1777 9.32208 14.6667 10.5302C14.6667 10.5302 14.1733 11.6968 13.2739 12.5962C13.2739 12.5962 12.3745 13.4956 11.208 13.989C11.208 13.989 9.99982 14.5 8.67773 14.5C8.67773 14.5 7.35565 14.5 6.14749 13.989C6.14749 13.989 4.98092 13.4956 4.08154 12.5962C4.08154 12.5962 3.18216 11.6968 2.68874 10.5302C2.68874 10.5302 2.17773 9.32209 2.17773 8C2.17773 8 2.17773 6.67791 2.68874 5.46975C2.68874 5.46975 3.18216 4.30319 4.08154 3.40381C4.08154 3.40381 4.98092 2.50442 6.14749 2.01101C6.14749 2.01101 7.35564 1.5 8.67773 1.5ZM8.67773 2.5C8.67773 2.5 7.55843 2.5 6.53704 2.93201C6.53704 2.93201 5.55011 3.34945 4.78865 4.11091C4.78865 4.11091 4.02718 4.87238 3.60975 5.85931C3.60975 5.85931 3.17773 6.8807 3.17773 8C3.17773 8 3.17773 9.1193 3.60975 10.1407C3.60975 10.1407 4.02718 11.1276 4.78865 11.8891C4.78865 11.8891 5.55011 12.6506 6.53704 13.068C6.53704 13.068 7.55843 13.5 8.67773 13.5C8.67773 13.5 9.79703 13.5 10.8184 13.068C10.8184 13.068 11.8054 12.6506 12.5668 11.8891C12.5668 11.8891 13.3283 11.1276 13.7457 10.1407C13.7457 10.1407 14.1777 9.1193 14.1777 8C14.1777 8 14.1777 6.8807 13.7457 5.85931C13.7457 5.85931 13.3283 4.87238 12.5668 4.11091C12.5668 4.11091 11.8054 3.34945 10.8184 2.93201C10.8184 2.93201 9.79704 2.5 8.67773 2.5Z" fill="#F55800" />
                          <path d="M4.08047 4.10984L12.5679 12.5973C12.6617 12.691 12.7889 12.7437 12.9215 12.7437C13.0541 12.7437 13.1812 12.691 13.275 12.5973C13.3688 12.5035 13.4215 12.3763 13.4215 12.2437C13.4215 12.1111 13.3688 11.9839 13.275 11.8902L4.78757 3.40273C4.69375 3.3089 4.56657 3.25623 4.43396 3.25623C4.30135 3.25623 4.17417 3.3089 4.08041 3.40267C3.98664 3.49644 3.93396 3.62362 3.93396 3.75623C3.93396 3.88883 3.98664 4.01601 4.08047 4.10984Z" fill="#F55800" />
                        </svg>
                      </div>
                    </td>
                  </tr >
                )}
                {activeTab === "manageCoach" && (
                  <tr key={index}>
                    <td className="chk">
                      <img width="48px" height="48px" src={data.img} />
                      <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.name}</p>
                    </td>
                    <td style={{ padding: "0px", textAlign: "center" }}>
                      <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.program}</p>
                    </td>
                    <td style={{ padding: "0px" }}>
                      <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.assitantCoachName}</p>
                    </td>
                    <td style={{ padding: "0px" }}>
                      <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.subscription}</p>
                    </td>
                    <td style={{ padding: "0px", textAlign: "center" }}>
                      <div className={styles.manageCoachAthAv}>
                        <div>
                          <img className={styles.coachAthAv1} src={Av} alt="" />
                        </div>
                        <div>

                          <img className={styles.coachAthAv1} src={Av} alt="" />
                        </div>
                        <div><span>+3</span></div>
                      </div>
                    </td>
                    <td style={{ textAlign: "center", }}>
                      <div className='actionIconRedCon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67773 1.5C8.67773 1.5 9.99982 1.5 11.208 2.01101C11.208 2.01101 12.3745 2.50442 13.2739 3.40381C13.2739 3.40381 14.1733 4.30319 14.6667 5.46975C14.6667 5.46975 15.1777 6.67791 15.1777 8C15.1777 8 15.1777 9.32208 14.6667 10.5302C14.6667 10.5302 14.1733 11.6968 13.2739 12.5962C13.2739 12.5962 12.3745 13.4956 11.208 13.989C11.208 13.989 9.99982 14.5 8.67773 14.5C8.67773 14.5 7.35565 14.5 6.14749 13.989C6.14749 13.989 4.98092 13.4956 4.08154 12.5962C4.08154 12.5962 3.18216 11.6968 2.68874 10.5302C2.68874 10.5302 2.17773 9.32209 2.17773 8C2.17773 8 2.17773 6.67791 2.68874 5.46975C2.68874 5.46975 3.18216 4.30319 4.08154 3.40381C4.08154 3.40381 4.98092 2.50442 6.14749 2.01101C6.14749 2.01101 7.35564 1.5 8.67773 1.5ZM8.67773 2.5C8.67773 2.5 7.55843 2.5 6.53704 2.93201C6.53704 2.93201 5.55011 3.34945 4.78865 4.11091C4.78865 4.11091 4.02718 4.87238 3.60975 5.85931C3.60975 5.85931 3.17773 6.8807 3.17773 8C3.17773 8 3.17773 9.1193 3.60975 10.1407C3.60975 10.1407 4.02718 11.1276 4.78865 11.8891C4.78865 11.8891 5.55011 12.6506 6.53704 13.068C6.53704 13.068 7.55843 13.5 8.67773 13.5C8.67773 13.5 9.79703 13.5 10.8184 13.068C10.8184 13.068 11.8054 12.6506 12.5668 11.8891C12.5668 11.8891 13.3283 11.1276 13.7457 10.1407C13.7457 10.1407 14.1777 9.1193 14.1777 8C14.1777 8 14.1777 6.8807 13.7457 5.85931C13.7457 5.85931 13.3283 4.87238 12.5668 4.11091C12.5668 4.11091 11.8054 3.34945 10.8184 2.93201C10.8184 2.93201 9.79704 2.5 8.67773 2.5Z" fill="#F55800" />
                          <path d="M4.08047 4.10984L12.5679 12.5973C12.6617 12.691 12.7889 12.7437 12.9215 12.7437C13.0541 12.7437 13.1812 12.691 13.275 12.5973C13.3688 12.5035 13.4215 12.3763 13.4215 12.2437C13.4215 12.1111 13.3688 11.9839 13.275 11.8902L4.78757 3.40273C4.69375 3.3089 4.56657 3.25623 4.43396 3.25623C4.30135 3.25623 4.17417 3.3089 4.08041 3.40267C3.98664 3.49644 3.93396 3.62362 3.93396 3.75623C3.93396 3.88883 3.98664 4.01601 4.08047 4.10984Z" fill="#F55800" />
                        </svg>
                      </div>
                    </td>
                  </tr >
                )}
                {activeTab === "manageExercise" && (
                  <tr key={index}>
                    <td className="chk">
                      {showDeleteCheckBox && (
                        <>
                          <label class="containerInput">
                            <input
                              type='checkbox'
                              checked={selectedPrograms.includes(data?.id)}
                              onChange={() => handleCheckboxChange(data?.id)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </>
                      )}
                      <p style={{ margin: 0, fontFamily: "Hurme Geometric Sans 2" }}>
                        {data.title}
                      </p>
                    </td>
                    <td style={{
                      textAlign: "center",
                      fontFamily: "Hurme Geometric Sans 2",
                    }}>
                      <p style={{ margin: 0, fontFamily: "Hurme Geometric Sans 2" }}>
                        {data.URL}
                      </p>
                    </td>
                    <td style={{
                      textAlign: "center",
                      fontFamily: "Hurme Geometric Sans 2",
                    }}>
                      <p style={{ margin: 0, fontFamily: "Hurme Geometric Sans 2" }}>
                        {data.instructions}
                      </p>
                    </td>
                    <td style={{
                      textAlign: "center",
                      fontFamily: "Hurme Geometric Sans 2",
                    }}>
                      <p style={{ margin: 0, fontFamily: "Hurme Geometric Sans 2" }}>
                        {moment(data.createdAt).format('D/MM/YYYY')}
                      </p>
                    </td>
                    <td>
                      <div style={{ display: "flex", justifyContent: "center", paddingRight: '0.5rem', gap: "5px" }}>
                        <div className='indexSee'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4569 8.70307C15.4569 8.70307 15.3173 9.01721 15.0325 9.46953C15.0325 9.46953 14.4713 10.3608 13.7286 11.1036C13.7286 11.1036 11.3321 13.5 8 13.5C8 13.5 4.66789 13.5 2.27145 11.1036C2.27145 11.1036 1.52865 10.3608 0.967509 9.46953C0.967509 9.46953 0.68271 9.0172 0.543094 8.70307C0.485635 8.57379 0.485635 8.42621 0.543094 8.29693C0.543094 8.29693 0.682709 7.9828 0.967509 7.53047C0.967509 7.53047 1.52865 6.63924 2.27145 5.89645C2.27145 5.89645 4.6679 3.5 8 3.5C8 3.5 11.3321 3.5 13.7286 5.89645C13.7286 5.89645 14.4713 6.63924 15.0325 7.53047C15.0325 7.53047 15.3173 7.98279 15.4569 8.29693C15.5144 8.42621 15.5144 8.57379 15.4569 8.70307ZM14.1863 8.93672C14.1863 8.93672 14.3416 8.68992 14.4429 8.5C14.4429 8.5 14.3417 8.31008 14.1863 8.06328C14.1863 8.06328 13.6849 7.267 13.0214 6.60355C13.0214 6.60355 10.9179 4.5 8 4.5C8 4.5 5.08211 4.5 2.97855 6.60355C2.97855 6.60355 2.3151 7.26701 1.81374 8.06328C1.81374 8.06328 1.65835 8.31007 1.55706 8.5C1.55706 8.5 1.65835 8.68993 1.81374 8.93672C1.81374 8.93672 2.3151 9.73299 2.97855 10.3964C2.97855 10.3964 5.08211 12.5 8 12.5C8 12.5 10.9179 12.5 13.0214 10.3964C13.0214 10.3964 13.6849 9.733 14.1863 8.93672Z" fill="#5E4AE3" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 5.5C8 5.5 9.24264 5.5 10.1213 6.37868C10.1213 6.37868 11 7.25736 11 8.5C11 8.5 11 9.74264 10.1213 10.6213C10.1213 10.6213 9.24264 11.5 8 11.5C8 11.5 6.75736 11.5 5.87868 10.6213C5.87868 10.6213 5 9.74264 5 8.5C5 8.5 5 7.25736 5.87868 6.37868C5.87868 6.37868 6.75736 5.5 8 5.5ZM8 6.5C8 6.5 7.17157 6.5 6.58579 7.08579C6.58579 7.08579 6 7.67157 6 8.5C6 8.5 6 9.32843 6.58579 9.91421C6.58579 9.91421 7.17157 10.5 8 10.5C8 10.5 8.82843 10.5 9.41421 9.91421C9.41421 9.91421 10 9.32843 10 8.5C10 8.5 10 7.67157 9.41421 7.08579C9.41421 7.08579 8.82843 6.5 8 6.5Z" fill="#5E4AE3" />
                          </svg>
                        </div>
                        <div className='indexEdit' onClick={() => handleEdit(data)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9962 6.71081L6.35355 14.3535C6.25979 14.4473 6.13261 14.4999 6 14.4999H3C3 14.4999 2.58579 14.4999 2.29289 14.2071C2.29289 14.2071 2 13.9142 2 13.4999L2 10.7062C2 10.7062 1.99931 10.51 2.07451 10.3269C2.07451 10.3269 2.14971 10.1438 2.28932 10.0035L9.7902 2.50264C9.7902 2.50264 9.92847 2.36222 10.1138 2.28462C10.1138 2.28462 10.2991 2.20703 10.5 2.20703C10.5 2.20703 10.7009 2.20703 10.8862 2.28462C10.8862 2.28462 11.0715 2.36222 11.2125 2.50536L13.9973 5.29014C13.9973 5.29014 14.1377 5.42842 14.2153 5.61373C14.2153 5.61373 14.2929 5.79904 14.2929 5.99995C14.2929 5.99995 14.2929 6.20085 14.2153 6.38616C14.2153 6.38616 14.1385 6.56971 13.9973 6.70975L13.9962 6.71081ZM13.2929 5.99995L13.2902 5.99725L10.5 3.20703L10.4973 3.20975L3 10.707V13.4999H5.79289L13.2902 6.00264L13.2929 5.99995Z" fill="#26CF18" />
                            <path d="M13.5 13.5H5.99999C5.72385 13.5 5.49999 13.7239 5.49999 14C5.49999 14.2761 5.72386 14.4999 6 14.4999L13.5 14.5C13.7761 14.5 14 14.2761 14 14C14 13.7239 13.7761 13.5 13.5 13.5Z" fill="#26CF18" />
                            <path d="M11.6462 8.35329C11.74 8.44706 11.8674 8.5 12 8.5C12.1326 8.5 12.2598 8.44732 12.3535 8.35355C12.4473 8.25979 12.5 8.13261 12.5 8C12.5 7.86739 12.4473 7.74021 12.3535 7.64645L8.85361 4.14651C8.75978 4.05268 8.6326 4 8.49999 4C8.36739 4 8.24021 4.05268 8.14644 4.14645C8.05267 4.24021 7.99999 4.36739 7.99999 4.5C7.99999 4.63261 8.05267 4.75979 8.14644 4.85355L11.6462 8.35329Z" fill="#26CF18" />
                          </svg>
                        </div>
                        <div className='indexUpload'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M2 9H5C5.27614 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.27614 8 5 8H2C1.58579 8 1.29289 8.29289 1.29289 8.29289C1 8.58579 1 9 1 9V13C1 13.4142 1.29289 13.7071 1.29289 13.7071C1.58579 14 2 14 2 14H14C14.4142 14 14.7071 13.7071 14.7071 13.7071C15 13.4142 15 13 15 13V9C15 8.58579 14.7071 8.29289 14.7071 8.29289C14.4142 8 14 8 14 8H11C10.7239 8 10.5 8.22386 10.5 8.5C10.5 8.77614 10.7239 9 11 9H14V13H2V9Z" fill="#DA8B2E" />
                            <path d="M8.5 8.5V2C8.5 1.72386 8.27614 1.5 8 1.5C7.72386 1.5 7.5 1.72386 7.5 2V8.5C7.5 8.77614 7.72386 9 8 9C8.27614 9 8.5 8.77614 8.5 8.5Z" fill="#DA8B2E" />
                            <path d="M10.6462 5.35329C10.74 5.44706 10.8674 5.5 11 5.5C11.1326 5.5 11.2598 5.44732 11.3536 5.35355C11.4473 5.25979 11.5 5.13261 11.5 5C11.5 4.86739 11.4473 4.74021 11.3536 4.64645L8.35355 1.64645C8.25978 1.55268 8.13261 1.5 8 1.5C7.86739 1.5 7.74022 1.55268 7.64645 1.64645L4.64663 4.64626C4.55287 4.74003 4.5 4.86739 4.5 5C4.5 5.00801 4.50019 5.01602 4.50058 5.02402C4.50655 5.14818 4.55855 5.26566 4.64645 5.35355C4.74021 5.44732 4.86739 5.5 5 5.5C5.13261 5.5 5.25979 5.44732 5.35355 5.35355L8 2.70711L10.6462 5.35329Z" fill="#DA8B2E" />
                            <path d="M12.5 11C12.5 11.4142 12.1642 11.75 11.75 11.75C11.3358 11.75 11 11.4142 11 11C11 10.5858 11.3358 10.25 11.75 10.25C12.1642 10.25 12.5 10.5858 12.5 11Z" fill="#DA8B2E" />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {activeTab === "manageMarketplace" && (
                  <tr >
                    <td className="chk">
                      <img width="48px" height="48px" src={data.img} />
                      <p style={{ margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.title} </p>
                    </td>
                    <td>
                      <p style={{ textAlign: 'center', margin: 0, fontFamily: 'Hurme Geometric Sans 2' }}>{data.programcoach.firstName}</p>
                    </td>

                    <td >
                      <div id='as' className='indexTableFeesValue'>
                        <span>
                          {"$"} {data.fee}
                        </span>
                      </div>
                    </td>
                    <td style={{ textAlign: 'center  !important' }}>
                      <div className='d-flex justify-content-center aligin-items-center'>
                        <p className='indexTableLevelTitle' id='interf' style={{ fontSize: "16px", fontFamily: 'Hurme Geometric Sans 2', margin: '0' }}>{data.level}</p>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex", justifyContent: "center", paddingRight: '0.5rem', gap: "5px" }}>
                        <div className='indexSee'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4569 8.70307C15.4569 8.70307 15.3173 9.01721 15.0325 9.46953C15.0325 9.46953 14.4713 10.3608 13.7286 11.1036C13.7286 11.1036 11.3321 13.5 8 13.5C8 13.5 4.66789 13.5 2.27145 11.1036C2.27145 11.1036 1.52865 10.3608 0.967509 9.46953C0.967509 9.46953 0.68271 9.0172 0.543094 8.70307C0.485635 8.57379 0.485635 8.42621 0.543094 8.29693C0.543094 8.29693 0.682709 7.9828 0.967509 7.53047C0.967509 7.53047 1.52865 6.63924 2.27145 5.89645C2.27145 5.89645 4.6679 3.5 8 3.5C8 3.5 11.3321 3.5 13.7286 5.89645C13.7286 5.89645 14.4713 6.63924 15.0325 7.53047C15.0325 7.53047 15.3173 7.98279 15.4569 8.29693C15.5144 8.42621 15.5144 8.57379 15.4569 8.70307ZM14.1863 8.93672C14.1863 8.93672 14.3416 8.68992 14.4429 8.5C14.4429 8.5 14.3417 8.31008 14.1863 8.06328C14.1863 8.06328 13.6849 7.267 13.0214 6.60355C13.0214 6.60355 10.9179 4.5 8 4.5C8 4.5 5.08211 4.5 2.97855 6.60355C2.97855 6.60355 2.3151 7.26701 1.81374 8.06328C1.81374 8.06328 1.65835 8.31007 1.55706 8.5C1.55706 8.5 1.65835 8.68993 1.81374 8.93672C1.81374 8.93672 2.3151 9.73299 2.97855 10.3964C2.97855 10.3964 5.08211 12.5 8 12.5C8 12.5 10.9179 12.5 13.0214 10.3964C13.0214 10.3964 13.6849 9.733 14.1863 8.93672Z" fill="#5E4AE3" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 5.5C8 5.5 9.24264 5.5 10.1213 6.37868C10.1213 6.37868 11 7.25736 11 8.5C11 8.5 11 9.74264 10.1213 10.6213C10.1213 10.6213 9.24264 11.5 8 11.5C8 11.5 6.75736 11.5 5.87868 10.6213C5.87868 10.6213 5 9.74264 5 8.5C5 8.5 5 7.25736 5.87868 6.37868C5.87868 6.37868 6.75736 5.5 8 5.5ZM8 6.5C8 6.5 7.17157 6.5 6.58579 7.08579C6.58579 7.08579 6 7.67157 6 8.5C6 8.5 6 9.32843 6.58579 9.91421C6.58579 9.91421 7.17157 10.5 8 10.5C8 10.5 8.82843 10.5 9.41421 9.91421C9.41421 9.91421 10 9.32843 10 8.5C10 8.5 10 7.67157 9.41421 7.08579C9.41421 7.08579 8.82843 6.5 8 6.5Z" fill="#5E4AE3" />
                          </svg>
                        </div>
                        <div className='indexEdit' onClick={() => handleEdit(data)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9962 6.71081L6.35355 14.3535C6.25979 14.4473 6.13261 14.4999 6 14.4999H3C3 14.4999 2.58579 14.4999 2.29289 14.2071C2.29289 14.2071 2 13.9142 2 13.4999L2 10.7062C2 10.7062 1.99931 10.51 2.07451 10.3269C2.07451 10.3269 2.14971 10.1438 2.28932 10.0035L9.7902 2.50264C9.7902 2.50264 9.92847 2.36222 10.1138 2.28462C10.1138 2.28462 10.2991 2.20703 10.5 2.20703C10.5 2.20703 10.7009 2.20703 10.8862 2.28462C10.8862 2.28462 11.0715 2.36222 11.2125 2.50536L13.9973 5.29014C13.9973 5.29014 14.1377 5.42842 14.2153 5.61373C14.2153 5.61373 14.2929 5.79904 14.2929 5.99995C14.2929 5.99995 14.2929 6.20085 14.2153 6.38616C14.2153 6.38616 14.1385 6.56971 13.9973 6.70975L13.9962 6.71081ZM13.2929 5.99995L13.2902 5.99725L10.5 3.20703L10.4973 3.20975L3 10.707V13.4999H5.79289L13.2902 6.00264L13.2929 5.99995Z" fill="#26CF18" />
                            <path d="M13.5 13.5H5.99999C5.72385 13.5 5.49999 13.7239 5.49999 14C5.49999 14.2761 5.72386 14.4999 6 14.4999L13.5 14.5C13.7761 14.5 14 14.2761 14 14C14 13.7239 13.7761 13.5 13.5 13.5Z" fill="#26CF18" />
                            <path d="M11.6462 8.35329C11.74 8.44706 11.8674 8.5 12 8.5C12.1326 8.5 12.2598 8.44732 12.3535 8.35355C12.4473 8.25979 12.5 8.13261 12.5 8C12.5 7.86739 12.4473 7.74021 12.3535 7.64645L8.85361 4.14651C8.75978 4.05268 8.6326 4 8.49999 4C8.36739 4 8.24021 4.05268 8.14644 4.14645C8.05267 4.24021 7.99999 4.36739 7.99999 4.5C7.99999 4.63261 8.05267 4.75979 8.14644 4.85355L11.6462 8.35329Z" fill="#26CF18" />
                          </svg>
                        </div>
                        <div className='indexUpload'>
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.3223 3.5H3.32227C3.04612 3.5 2.82227 3.72386 2.82227 4C2.82227 4.27614 3.04612 4.5 3.32227 4.5H14.3223C14.5984 4.5 14.8223 4.27614 14.8223 4C14.8223 3.72386 14.5984 3.5 14.3223 3.5Z" fill="#DA8B2E" />
                            <path d="M6.82227 7V11C6.82227 11.2761 7.04612 11.5 7.32227 11.5C7.59841 11.5 7.82227 11.2761 7.82227 11V7C7.82227 6.72386 7.59841 6.5 7.32227 6.5C7.04612 6.5 6.82227 6.72386 6.82227 7Z" fill="#DA8B2E" />
                            <path d="M9.82227 7V11C9.82227 11.2761 10.0461 11.5 10.3223 11.5C10.5984 11.5 10.8223 11.2761 10.8223 11V7C10.8223 6.72386 10.5984 6.5 10.3223 6.5C10.0461 6.5 9.82227 6.72386 9.82227 7Z" fill="#DA8B2E" />
                            <path d="M4.82227 13.5V4C4.82227 3.72386 4.59841 3.5 4.32227 3.5C4.04612 3.5 3.82227 3.72386 3.82227 4V13.5C3.82227 13.9142 4.11516 14.2071 4.11516 14.2071C4.40805 14.5 4.82227 14.5 4.82227 14.5H12.8223C13.2365 14.5 13.5294 14.2071 13.5294 14.2071C13.8223 13.9142 13.8223 13.5 13.8223 13.5V4C13.8223 3.72386 13.5984 3.5 13.3223 3.5C13.0461 3.5 12.8223 3.72386 12.8223 4V13.5H4.82227Z" fill="#DA8B2E" />
                            <path d="M6.26161 1.93934C5.82227 2.37868 5.82227 3 5.82227 3V4C5.82227 4.27614 6.04612 4.5 6.32227 4.5C6.59841 4.5 6.82227 4.27614 6.82227 4V3C6.82227 2.79289 6.96871 2.64645 6.96871 2.64645C7.11516 2.5 7.32227 2.5 7.32227 2.5H10.3223C10.5294 2.5 10.6758 2.64645 10.6758 2.64645C10.8223 2.79289 10.8223 3 10.8223 3V4C10.8223 4.27614 11.0461 4.5 11.3223 4.5C11.5984 4.5 11.8223 4.27614 11.8223 4V3C11.8223 2.37868 11.3829 1.93934 11.3829 1.93934C10.9436 1.5 10.3223 1.5 10.3223 1.5H7.32227C6.70095 1.5 6.26161 1.93934 6.26161 1.93934Z" fill="#DA8B2E" />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {activeTab === "manageBusiness" && (
                  <></>
                )}
              </>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table
