import React, { useState, useEffect, useRef } from 'react';

const Timer = React.memo(({ sessionComplete, setupdatedTime, maxTime }) => {
  // Initialize timer state based on maxTime or start from zero
  const initialMinutes = maxTime ? Math.floor(maxTime / 60) : 0;
  const initialSeconds = maxTime ? maxTime % 60 : 0;

  const [time, setTime] = useState({ minutes: initialMinutes, seconds: initialSeconds });
  const timerRef = useRef(null);

  useEffect(() => {
    // Update timer state if maxTime changes
    setTime({
      minutes: Math.floor(maxTime / 60),
      seconds: maxTime % 60
    });
  }, [maxTime]);

  useEffect(() => {
    if (!sessionComplete) {
      timerRef.current = setInterval(() => {
        setTime(prevTime => {
          let { minutes, seconds } = prevTime;
          seconds++;
          if (seconds === 60) {
            minutes++;
            seconds = 0;
          }
          return { minutes, seconds };
        });
      }, 1000);
    } else {
      // Session is complete, clear the interval and update the time
      clearInterval(timerRef.current);
      setupdatedTime(time); // Store the final time value
    }

    // Clean up the interval on unmount or when sessionComplete changes
    return () => clearInterval(timerRef.current);
  }, [sessionComplete, time, setupdatedTime]);

  // Call setupdatedTime whenever time changes
  useEffect(() => {
    setupdatedTime(time);
  }, [time, setupdatedTime]);

  return (
    <div>
      {time.minutes}:{time.seconds < 10 ? `0${time.seconds}` : time.seconds}
    </div>
  );
});

export default Timer;
