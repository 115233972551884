import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Feeds from "../../../modules/Feeds/Index";
import { auth } from "../../../../firebase";
import axios from "axios";
import { getAtheleteFeed } from "../../../../services/store/atheleteApi";
import { ThemeContext } from "../../../../context/themeContext";
import io from 'socket.io-client';
const FeedsArea = styled.div`
  height: calc(110vh - 150px);
  overflow: hidden;
`;
const Header = styled.header`
  height: max-content;
  margin-bottom: 0px;
`;
const Section = styled.section`
  padding: 0px 40px;
`;
const FeedSection = () => {
  const getInitialActiveTab = () => {
    const storedTab = localStorage.getItem("FeedactiveTab");
    return storedTab ? storedTab : "all";
  };
  const [isEmptyDataForFeed, setisEmptyDataForFeed] = useState(false);
  const [authReady, setAuthReady] = useState(false);
  const [activeTab, setActiveTab] = useState(getInitialActiveTab);
  const [isFeedCardDetails, setFeedCardDetails] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [isLikeCount, setLikeCount] = useState(false);
  const { t } = useTranslation();
  const [isOpenComment, setOpenComment] = useState(false);
  const socket = useRef();
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_Baseurl, { transports: ["websocket"] });

    socket.current.on("connect", () => {
      console.log("Socket connected:", socket.current.id);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);
  const getLikes = async () => {
    // Listen for 'newLike' event from the server
    socket.current.on("likedFeed", async (newLike) => {
      await fetchFeedDetails();
    });
  };

  useEffect(() => {
    getLikes();
    return () => {
      socket.current.off("likedFeed");
    };
  }, []);

  const getComments = async () => {
    // Listen for 'newLike' event from the server
    socket.current.on("commented_Feed", async (newLike) => {
      await fetchFeedDetails();
    });
  };

  useEffect(() => {
    getComments();
    return () => {
      socket.current.off("commented_Feed");
    };
  }, [socket]);

  useEffect(() => {
    getLikes();
    return () => {
      socket.current.off("likedFeed");
    };
  }, []);
  const [isLoading, setIsloading] = useState(false);
  const fetchFeedDetails = async () => {
    
    try {
      const token = await auth.currentUser.getIdToken(true);
      let apiUrl = `${process.env.REACT_APP_Baseurl}${getAtheleteFeed}?All=true`;

      if (activeTab === "groups") {
        apiUrl = `${process.env.REACT_APP_Baseurl}${getAtheleteFeed}?Group=true`;
      }
      if (activeTab === "myself") {
        apiUrl = `${process.env.REACT_APP_Baseurl}${getAtheleteFeed}?Myself=true`;
      }
      // Add an else if condition here for "myself" tab if you have specific parameters
      const response = await axios.get(apiUrl, {
        headers: { Authorization: token },
      });
      const feedDataWithLikes = response?.data?.feeds?.map((item) => ({
        ...item,
        liked: false,
      }));
      console.log(feedDataWithLikes);
      {feedDataWithLikes.length <= 0 ? setisEmptyDataForFeed(true) : setisEmptyDataForFeed(false)}
      setFeedCardDetails(feedDataWithLikes);
      setLikeCount(response?.data?.likesCount);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.error("Error fetching session calendar:", error);
    }
  };
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user); // Set authReady to true if user is logged in
    });

    return () => unregisterAuthObserver(); // Clean up observer on unmount
  }, []);
  useEffect(() => {
    if (authReady) {
      setIsloading(true);
      fetchFeedDetails();
      localStorage.setItem("FeedactiveTab", activeTab);
    }
  }, [authReady, activeTab]);

  const handleOpenComment = () => {
    setOpenComment(!isOpenComment);
  };

  // Implementation Socket Io
  const handleLikeClick = async (feedId, userId, feedData) => {
    try {
      // Find the like object associated with the clicked user ID
      const clickedLike = feedData?.feedlikes.find(
        (like) => like.userId === userId
      );
      const isLikeUpdate = clickedLike?.isLike == true ? true : false;

      socket.current.emit("likeFeed", {
        feedId: feedId,
        userId: userId,
        isLike: isLikeUpdate == true ? false : true,
        likes: isLikeUpdate == true ? null : 1,
        groupId: feedData?.groupId,
        likeId: clickedLike?.id || null,
      });
    } catch (error) {
      console.log(error, "sock error");
    }
  };
  return (
    <FeedsArea fluid style={{ overflowY: "scroll" }}>
      <Header>
        <div
          className="mobileDiv"
          style={{ height: "auto", background: "none" }}
        >
          <div className="homeRow" style={{ background: "none" }}>
            <div
              className="buttonsContainer"
              style={{ padding: "10px 20px 10px" }}
            >
              <div
                className="buttons"
                style={{
                  width: "270px",
                  gap: "0px",
                  margin: "0xp",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    width: "90px",
                    textAlign: "center",
                  }}
                  onClick={() => setActiveTab("all")}
                  id={activeTab === "all" && "active"}
                >
                  <p id="as">{t("All")}</p>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    width: "90px",
                    textAlign: "center",
                  }}
                  onClick={() => setActiveTab("myself")}
                  id={activeTab === "myself" && "active"}
                >
                  <p id="as">{t("Myself")}</p>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    width: "90px",
                    textAlign: "center",
                  }}
                  onClick={() => setActiveTab("groups")}
                  id={activeTab === "groups" && "active"}
                >
                  <p id="as">{t("Groups")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <Section>
        {activeTab === "all" && (
          <Feeds
          setOpenComment={setOpenComment}
          isEmptyDataForFeed={isEmptyDataForFeed}
            isLoading={isLoading}
            isFeedCardDetails={isFeedCardDetails}
            setFeedCardDetails={setFeedCardDetails}
            handleLikeClick={handleLikeClick}
            isLiked={isLiked}
            isLikeCount={isLikeCount}
            handleOpenComment={handleOpenComment}
            isOpenComment={isOpenComment}
          />
        )}
        {activeTab === "groups" && (
          <Feeds
          setOpenComment={setOpenComment}
          isEmptyDataForFeed={isEmptyDataForFeed}
            isLoading={isLoading}
            isFeedCardDetails={isFeedCardDetails}
            setFeedCardDetails={setFeedCardDetails}
            handleLikeClick={handleLikeClick}
            isLiked={isLiked}
            isLikeCount={isLikeCount}
            handleOpenComment={handleOpenComment}
            isOpenComment={isOpenComment}
          />
        )}
        {activeTab === "myself" && (
          <Feeds
          setOpenComment={setOpenComment}
          isEmptyDataForFeed={isEmptyDataForFeed}
            isLoading={isLoading}
            isFeedCardDetails={isFeedCardDetails}
            setFeedCardDetails={setFeedCardDetails}
            handleLikeClick={handleLikeClick}
            isLiked={isLiked}
            isLikeCount={isLikeCount}
            handleOpenComment={handleOpenComment}
            isOpenComment={isOpenComment}
          />
        )}
      </Section>
    </FeedsArea>
  );
};

export default FeedSection;
