import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import SvgIcon from '../../../../../assessts/Svg/svgIcons';
import { addSessionsToProgram, getAllExerciseList } from '../../../../../apis';
import { auth } from '../../../../../firebase';
import { toast } from 'react-toastify';
import IndexProgramSessionBlock from './IndexProgramSessionBlock/groupSessionBlock';

/**
 * Component for creating or editing training sessions.
 * It allows a coach to input session details and manage session blocks dynamically.
 * 
 * @param {Object} props - Component props.
 * @param {Function} props.onClose - Function to call on closing the form.
 */

const IndexSessionForm = (props) => {
const { onClose, currentProgram, selectedWeekNumber, selectedDay, formattedDate } = props;
    // State for managing the list of exercises.
    const [exerciseList, setExerciseList] = useState([]);

    // State for managing session blocks.
    const [sessionBlocks, setSessionBlocks] = useState([{
        title: '',
        selectedExercise: null,
        instructions: '',
        videoURL: '',
        sets: [{ reps: '', weight: '' }],
    }]);

    // State for loading indicator.
    const [isActiveLoader, setActiveLoader] = useState(false);

    // Coach ID, fetched from Firebase authentication.
    const coachId = auth.currentUser.uid;

    // State for form data like session title and instructions.
    const [sessionFormData, setSessionFormData] = useState({
        title: "",
        instructions: "",
        addedByAthlete: false,
        groupSharing: false,
        oneToOne: false,
        fixedLength: true,
        coachId: coachId,
        programId: currentProgram.id,
        leaderboard: {
            scoreType: "Weight",
            customCalculations: "More is better",
            numberOfIntervals: "4",
            leaderboardCalculations: "More is better"
        },
        programDate: {
            Week: selectedWeekNumber,
            Day: selectedDay,
          }
    });

    // Effect to fetch exercise list on mount.
    useEffect(() => {
        fetchExerciseList();
    }, []);

    /**
     * Handler Functions 
     */

    /**
     * Fetches the list of exercises from the API.
     */

    const fetchExerciseList = async () => {
        try {
            const token = await auth.currentUser.getIdToken(true);
            if (token) {
                const response = await axios.get(
                    `${process.env.REACT_APP_Baseurl}${getAllExerciseList}?coachId=${coachId}`,
                    { headers: { Authorization: token } }
                );
                setExerciseList(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    /**
    * Handles form submission for creating or editing a session.
    */
    const handleSaveSession = async () => {
        try {
            setActiveLoader(true)

            // Get authorization token
            const token = await auth.currentUser.getIdToken(true);

            // Determine the API endpoint and payload based on whether it's a new program or an edit
            const endpoint = addSessionsToProgram;
            const payload = {
                ...sessionFormData,
                blocks: sessionBlocks.map(block => ({
                    exerciseTitle: block.selectedExercise.title,
                    exerciseURL: block.videoURL,
                    exerciseInstructions: block.instructions,
                    exerciseID: block.selectedExercise.id, // Assuming you have an id field
                    exerciseCategory: block.selectedExercise.category || "Power", // Assuming you have a category field
                    sets: block.sets.map(set => ({
                        title1: "Reps", // Or any other logic to determine this value
                        title2: "Weight", // Or any other logic to determine this value
                        value1: set.reps,
                        value2: set.weight
                    }))
                }))
            };


            // Make the API call
            await makeApiCall(endpoint, payload, token);
            onClose();
            // Show success message
            toast.success("Session Created Successfully");
        } catch (error) {
            // Handle errors
            setActiveLoader(false);
            toast.error("An error occurred while saving the program");
        }
    }


    /**
* Makes an API call to the given endpoint with the provided payload and token.
* @param {string} endpoint - API endpoint
* @param {Object} payload - Data to send in the API request
* @param {string} token - Authorization token
*/
    const makeApiCall = async (endpoint, payload, token) => {
        const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
        const config = {
            headers: { Authorization: token }
        };

            // For creation (post request)
            await axios.post(url, payload, config);
        
    };


    const handleInstructionsChange = (blockIndex, newInstructions) => {
        const updatedBlocks = [...sessionBlocks];
        updatedBlocks[blockIndex].instructions = newInstructions;
        setSessionBlocks(updatedBlocks);
    };


    const handleURLChange = (blockIndex, newURL) => {
        const updatedBlocks = [...sessionBlocks];
        updatedBlocks[blockIndex].videoURL = newURL;
        setSessionBlocks(updatedBlocks);
    };

    // Handles changes in session title and instructions input fields.
    const handleInputSessionChange = (e, field) => {
        setSessionFormData({ ...sessionFormData, [field]: e.target.value });
    };


    // Adds a new set to a session block.
    const handleAddSet = (blockIndex) => {
        const newBlocks = [...sessionBlocks];
        newBlocks[blockIndex].sets.push({ reps: '', weight: '' });
        setSessionBlocks(newBlocks);
    };


    // Updates the details of a specific set within a block.
    const handleSetChange = (blockIndex, setIndex, field, value) => {
        const newSets = [...sessionBlocks];
        newSets[blockIndex].sets[setIndex][field] = value;
        setSessionBlocks(newSets);
    };


    // Manages the selection of an exercise and updates block data accordingly.
    const handleExerciseSelectionChange = (blockIndex, exerciseTitle) => {
        const newBlocks = [...sessionBlocks];
        const selectedExerciseData = exerciseList.find(exercise => exercise.title === exerciseTitle);
        if (selectedExerciseData) {
            newBlocks[blockIndex].selectedExercise = selectedExerciseData;
            newBlocks[blockIndex].instructions = selectedExerciseData?.instructions;
            newBlocks[blockIndex].videoURL = selectedExerciseData?.URL;
        }
        setSessionBlocks(newBlocks);
    };


    // Adds a new session block to the form.
    const addSessionBlock = () => {
        const newBlock = { title: '', instructions: '', videoURL: '', sets: [] };
        setSessionBlocks([...sessionBlocks, newBlock]);
    };

    
    // Removes a specified session block.
    const removeSessionBlock = (blockIndex) => {
        setSessionBlocks(currentBlocks => currentBlocks.filter((_, index) => index !== blockIndex));
    };

    // Function to generate block label (A, B, C, ...)
    const getBlockLabel = (index) => String.fromCharCode(65 + index);

    return (
        <Container className='m-0 p-0'>
            <Row className='m-0 p-0'>
                <Col sm={12} md={12} lg={12} className='m-0 p-0'>
                    <Form id="as">
                        <Form.Group className="mt-3 mb-3" controlId="formBasic">
                            <Form.Label style={{ fontSize: "16px" }}>Session Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="sessionTitle"
                                placeholder="Session Title"
                                value={sessionFormData.title}
                                onChange={(e) => handleInputSessionChange(e, 'title')}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasic">
                            <Form.Label style={{ fontSize: "16px" }}>Coach</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                name="coach"
                                placeholder="Session Instruction"
                                value={sessionFormData.instructions}
                                onChange={(e) => handleInputSessionChange(e, 'instructions')}
                            />
                            {sessionBlocks?.map((block, index) => (
                                <IndexProgramSessionBlock
                                    key={index}
                                    blockIndex={index}
                                    blockLabel={getBlockLabel(index)}
                                    blockData={block}
                                    handleAddSet={handleAddSet}
                                    handleSetChange={handleSetChange}
                                    exerciseList={exerciseList}
                                    removeBlock={removeSessionBlock}
                                    handleExerciseSelectionChange={handleExerciseSelectionChange}
                                    handleInstructionsChange={handleInstructionsChange}
                                    handleURLChange={handleURLChange}
                                />
                            ))}

                            <div className="sessionBtm mt-4" style={{ textAlign: "end" }}>
                                <div className="pluscircle" onClick={addSessionBlock}>
                                    <SvgIcon iconName="plusButton" size={16} />
                                </div>
                                <div className="lastButtons mb-0">
                                    <Button className="customButton2">Cancel</Button>
                                    <Button className="customButton3" onClick={handleSaveSession}>
                                        {'Create'}
                                    </Button>
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default IndexSessionForm;
