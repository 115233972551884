import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';


const socket = io(`${process.env.REACT_APP_Baseurl}`, {
  transport: ["websocket"],
  autoConnect: false,
});
export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [location, setLocation] = useState("")
  const [hide, setHide] = useState(false)
  const [activeTab, setActiveTab] = useState("coach || manageAtheleteTraining")
  const [activeComponent, setActiveComponent] = useState("")
  const [isTyping, setIsTyping] = useState(false);
  const [typerId, setTyperId] = useState(null);
  const [isGroupChat, setIsGroupChat] = useState(false)
  const [activeChat, setActiveChat] = useState(null);
  const [chatUsers, setChatUsers] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [lastUnreadMessage, setLastUnreadMessage] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [ActiveChatId,setActiveChatId]=useState()
  const [ActiveChatGroupId,setActiveChatGroupId]=useState()


  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', newTheme);
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };


  const handleDefaultTab = () => {
    const pathname = window.location.pathname

    if (pathname === "/coach") {
      setActiveTab("coach");
    } else if (pathname === "/athelete") {
      setActiveTab("athelete");

    } else if (pathname === "/group") {
      setActiveTab("group");

    } else if (pathname === "/index") {
      setActiveTab("index");

    } else if (pathname === "/customHomePage") {
      setActiveTab("customHomePage");
    } else if (pathname === "/business") {
      setActiveTab("business");

    }
    else if (pathname === "/manage-athelete") {
      setActiveTab("manageAthelete");

    }
    else if (pathname === "/manage-coach") {
      setActiveTab("manageCoach");

    }
    else if (pathname === "/manage-exercise") {
      setActiveTab("manageExercise");

    }
    else if (pathname === "manageBusiness") {
      setActiveTab("manageBusiness");

    }
    else if (pathname === "/manage-marketplace") {
      setActiveTab("manageMarketplace");

    }
    else if (pathname === "/manage-athelete-traning") {
      setActiveTab("manageAtheleteTraining");
    }
    else if (pathname === "/manage-athelete-feed") {
      setActiveTab("manageAtheleteFeed");
    }
    else if (pathname === "/manage-athelete-groups") {
      setActiveTab("manageAtheleteGroups");
    }
    else if (pathname === "/manage-athelete-index") {
      setActiveTab("manageAtheleteIndex");
    }
    else if (pathname === "/manage-athelete-chat") {
      setActiveTab("manageAtheleteChat");
    }
    else if (pathname === "/manage-athelete-traning/create-session") {
      setActiveTab("manageCreateSession");
    }
    else if (pathname === "/cutomization") {
      setActiveTab("custom");
    }
  }
  useEffect(() => {
    handleDefaultTab()
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      document.documentElement.setAttribute('data-theme', savedTheme);

      setTheme(savedTheme);
    }

  }, []);
  return (
    <ThemeContext.Provider value={{ ActiveChatGroupId,setActiveChatGroupId,ActiveChatId,setActiveChatId,socket, typerId, setTyperId, isTyping, setIsTyping, theme, setTheme, location, setLocation, toggleTheme, hide, setHide, activeTab, setActiveTab, activeComponent, setActiveComponent, socket, isGroupChat, setIsGroupChat, chatUsers, setChatUsers, activeChat, setActiveChat, unreadCount, lastUnreadMessage, setLastUnreadMessage, setUnreadCount, isChatOpen, setIsChatOpen }}>
      {children}
    </ThemeContext.Provider>
  );
};
