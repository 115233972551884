import React from 'react'
import style from './style.module.css'
import { CalendarHead, RotPhone1, RotPhone2, SmallCard } from '../../../../../../assessts/LandingPage'
import Button from '../../../../Components/Button/Button'

const ThirdComp = () => {
  return (
    <div className={style.third}>
      <div className={`${style.main} d-flex `}>
        <div className={`${style.leftSide} d-flex flex-row position-relative`}>
          <div className={`${style.phones}`}>
            <img src={RotPhone1} className={`${style.rotP1} position-absolute`} />
          </div>
          <div className={`${style.phones}`}>
            <img src={RotPhone2} className={`${style.rotP2}`} />
          </div>
          <div className={`${style.SmallCard} `}>
            <img src={SmallCard} alt="" />
          </div>
        </div>
        <div className={`${style.rightSide} d-flex flex-column`}>
          <img src={CalendarHead} alt="" />
          <div className={`${style.Written}  d-flex flex-column`}>
            <h1>AFFORDABILITY WITHOUT COMPROMISE</h1>
            <p>At TheTrainingApp, we empathize the financial demands of running a personal training business or a large-scale gym. We've designed our pricing strategy with this in mind, ensuring that it doesn't undermine your ability to deliver top-notch programming and an overall exclusive experience to your clients or members. With TheTrainingApp, you can access all the premium features you need without having to strain your budget. Here, affordability meets quality to offer you a cost-effective solution to your needs.</p>
            <Button title={'See Pricing Plans'} bg={false} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThirdComp
