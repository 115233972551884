import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { CiCircleRemove } from "react-icons/ci";
import { AudioRecorder } from "react-audio-voice-recorder";
import { auth } from "../../firebase";
import { ThemeContext } from "../../context/themeContext";
import io from "socket.io-client";
const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ selectedImage }) => {
    if (selectedImage === null) {
      return "53px";
    }
    if (selectedImage !== null) {
      return "auto";
    }
  }};
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid var(--light-mode-secondary-adb-5-bd-text-3, #adb5bd);
  background: var(--white-100, #fff);
  box-shadow: 0px 4px 8px 0px rgba(35, 45, 66, 0.05);
  padding: 5px 7px;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
`;

const Textarea = styled.textarea`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  resize: vertical; /* Enable vertical resizing */
  overflow-y: hidden; /* Hide scrollbars initially */
  vertical-align: middle;
  line-height: 1;
  padding-top: 0.4rem;
  padding-left: 0.5rem;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 0.3rem;
  vertical-align: middle;
  & > svg:hover {
    transform: scale(1.1); /* Scale the icons up by 10% on hover */
    cursor: pointer;
  }
`;
const EmojiPickerContainer = styled.div`
  position: absolute;
  bottom: 60px;
  right: 0;
  z-index: 999;
  display: ${({ showEmojiPicker }) => (showEmojiPicker ? "block" : "none")};
`;

function StyledMessageInput({
  setOpenComment,
  onSendMessage,
  imageInputRef,
  handleImageSelection,
  selectedImage,
  setSelectedImage,
  setRecordedAudio,
  recordedAudio,
  setIsRecording,
  isRecording,
  user,
  setIsTyping,
  isTyping,
  setTyperId,
  isFeed,
  feedData,
  feedId,
}) {
  const [newMessage, setNewMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [currentEmoji, setCurrentEmoji] = useState(null);
  useEffect(() => {}, [selectedImage]);
  const socket = useRef();
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_Baseurl, {
      transports: ["websocket"],
    });

    socket.current.on("connect", () => {
      console.log("Socket connected:", socket.current.id);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);
  useEffect(() => {
    let typingTimer;

    if (isFeed !== true) {
      const handleTyping = () => {
        socket.current.emit("typing", {
          recipientId: user?.id,
          senderId: auth?.currentUser?.uid,
        });
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          socket.current.emit("user_stop_typing", {
            recipientId: user?.id,
            senderId: auth?.currentUser?.uid,
          });
        }, 5000);
      };

      const textInput = document.getElementById("input");
      textInput.addEventListener("input", handleTyping);

      socket.current.on("user_typing", (data) => {
        setTyperId(data.senderId);
        setIsTyping(true);
      });
      socket.current.on("user_stop_typing", (data) => {
        setTyperId(data.senderId);
        setIsTyping(false);
      });

      return () => {
        textInput.removeEventListener("input", handleTyping);
      };
    }
  }, []);

  const inputRef = useRef(null);
  const handleSendMessage = () => {
    if(!isFeed){
      setRecordedAudio(false)
      setIsRecording(false)
    }
  
    if (isFeed) {
      setOpenComment(false);
    }
    if (newMessage.trim() !== "") {
      !isFeed && onSendMessage([{ message: newMessage }]);
      setNewMessage("");
    } else if (recordedAudio) {
      const audio = document.querySelector("audio");
      audio.remove();
      console.log("Sending audio message");
      onSendMessage();
      
    }
    if (isFeed) {
      console.log("Sending feed comment");
      socket.current.emit("comment_Feed", {
        comments: newMessage,
        feedId: feedId,
        userId: auth?.currentUser?.uid,
        groupId: feedData?.groupId,
      });
    }
  };

  const handleToggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiSelect = (emoji) => {
    const updatedMessage = newMessage + emoji.native;
    setNewMessage(updatedMessage);
    inputRef.current.focus();
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const addAudioElement = (blob) => {
    setRecordedAudio(blob);
    setIsRecording(false);
  };
  useEffect(() => {
    const checkMicrophoneAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log("Connect Microphone Successfully")
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    };

    checkMicrophoneAccess();
  }, []);
  <img alt="Selected" width="180" height="150" />;
  return (
    <StyledInputContainer className="test">
      {isRecording ? (
        <AudioRecorder
        onRecordingComplete={addAudioElement}
        audioTrackConstraints={{
          noiseSuppression: true,
          echoCancellation: true,
        }}
        onError={(error) => {
          console.error("Error accessing microphone:", error);
        }}
      />
      ) : (
        <>
          {recordedAudio ? (
            <div style={{display : "flex",gap : "10px",alignItems  :"center" }} >
            
             <audio
             src={
               recordedAudio
                  &&  URL.createObjectURL(new Blob([recordedAudio]))
             }
             controls
           />
           <svg
              onClick={handleSendMessage}
              size={18}
              color="#ADB5BD"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M19.9274 1.31756C19.4274 0.806556 18.6874 0.617556 17.9974 0.817556L1.90742 5.46656C1.17942 5.66856 0.66342 6.24556 0.52442 6.97756C0.38242 7.72356 0.87842 8.67156 1.52642 9.06756L6.55742 12.1396C7.07342 12.4556 7.73942 12.3766 8.16642 11.9486L13.9274 6.18756C14.2174 5.88656 14.6974 5.88656 14.9874 6.18756C15.2774 6.47656 15.2774 6.94756 14.9874 7.24756L9.21642 13.0086C8.78842 13.4366 8.70842 14.1006 9.02342 14.6176L12.0974 19.6676C12.4574 20.2666 13.0774 20.6076 13.7574 20.6076C13.8374 20.6076 13.9274 20.6076 14.0074 20.5966C14.7874 20.4976 15.4074 19.9666 15.6374 19.2166L20.4074 3.24756C20.6174 2.56756 20.4274 1.82756 19.9274 1.31756Z"
                fill="#A19686"
              />
              <path
                opacity="0.4"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.51049 15.5471C1.31849 15.5471 1.12649 15.4741 0.980492 15.3271C0.687492 15.0341 0.687492 14.5601 0.980492 14.2671L2.34549 12.9011C2.63849 12.6091 3.11349 12.6091 3.40649 12.9011C3.69849 13.1941 3.69849 13.6691 3.40649 13.9621L2.04049 15.3271C1.89449 15.4741 1.70249 15.5471 1.51049 15.5471ZM5.27169 16.7395C5.07969 16.7395 4.88769 16.6665 4.74169 16.5195C4.44869 16.2265 4.44869 15.7525 4.74169 15.4595L6.10669 14.0935C6.39969 13.8015 6.87469 13.8015 7.16769 14.0935C7.45969 14.3865 7.45969 14.8615 7.16769 15.1545L5.80169 16.5195C5.65569 16.6665 5.46369 16.7395 5.27169 16.7395ZM5.52539 20.3075C5.67139 20.4545 5.86339 20.5275 6.05539 20.5275C6.24739 20.5275 6.43939 20.4545 6.58539 20.3075L7.95139 18.9425C8.24339 18.6495 8.24339 18.1745 7.95139 17.8815C7.65839 17.5895 7.18339 17.5895 6.89039 17.8815L5.52539 19.2475C5.23239 19.5405 5.23239 20.0145 5.52539 20.3075Z"
                fill="#A19686"
              />
            </svg>
           </div>
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconsContainer>
                  <svg
                    onClick={handleToggleEmojiPicker}
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_605_22036)">
                      <path
                        d="M20.9853 4.25399C18.7188 1.98749 15.7053 0.739258 12.5 0.739258C9.29469 0.739258 6.28119 1.98749 4.01473 4.25399C1.74819 6.52045 0.5 9.53395 0.5 12.7393C0.5 15.9446 1.74819 18.9581 4.01473 21.2245C6.28119 23.491 9.29469 24.7393 12.5 24.7393C15.7053 24.7393 18.7188 23.491 20.9853 21.2245C23.2518 18.9581 24.5 15.9446 24.5 12.7393C24.5 9.53395 23.2518 6.52045 20.9853 4.25399ZM12.5 23.3107C6.67091 23.3107 1.92856 18.5684 1.92856 12.7393C1.92856 6.91016 6.67091 2.16782 12.5 2.16782C18.3291 2.16782 23.0714 6.91016 23.0714 12.7393C23.0714 18.5684 18.3291 23.3107 12.5 23.3107Z"
                        fill="#0048B3"
                      />
                      <path
                        d="M9.90398 9.56967C8.73431 8.39991 6.83104 8.4 5.66136 9.56967C5.38241 9.84858 5.38241 10.3009 5.66136 10.5798C5.94036 10.8587 6.39266 10.8587 6.67157 10.5798C7.28432 9.96713 8.28126 9.96717 8.89392 10.5798C9.03342 10.7193 9.21618 10.7891 9.39895 10.7891C9.58171 10.7891 9.76457 10.7193 9.90398 10.5798C10.1829 10.3009 10.1829 9.84863 9.90398 9.56967Z"
                        fill="#0048B3"
                      />
                      <path
                        d="M19.3386 9.56967C18.1689 8.4 16.2656 8.39991 15.0959 9.56967C14.817 9.84858 14.817 10.3009 15.0959 10.5798C15.3749 10.8587 15.8272 10.8587 16.1061 10.5798C16.7188 9.96713 17.7157 9.96708 18.3285 10.5798C18.468 10.7193 18.6508 10.7891 18.8335 10.7891C19.0163 10.7891 19.1991 10.7193 19.3386 10.5798C19.6175 10.3009 19.6175 9.84863 19.3386 9.56967Z"
                        fill="#0048B3"
                      />
                      <path
                        d="M18.8331 13.2627H6.16643C5.77193 13.2627 5.45215 13.5825 5.45215 13.977C5.45215 17.863 8.61373 21.0246 12.4998 21.0246C16.3858 21.0246 19.5474 17.863 19.5474 13.977C19.5474 13.5825 19.2276 13.2627 18.8331 13.2627ZM12.4998 19.596C9.64329 19.596 7.27779 17.4537 6.9259 14.6913H18.0737C17.7217 17.4537 15.3562 19.596 12.4998 19.596Z"
                        fill="#0048B3"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_605_22036">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.5 0.739258)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </IconsContainer>
                <Textarea
                  id="input"
                  type="input"
                  placeholder={
                    selectedImage ? "add caption" : "Type your message..."
                  }
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  ref={inputRef}
                />
                <IconsContainer>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={imageInputRef}
                    onChange={handleImageSelection}
                  />
                 {
                  !isFeed && (
                    <svg
                    onClick={() => imageInputRef.current.click()}
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M8.65518 18.3379L16.6405 9.8796C17.16 9.32927 17.16 8.43701 16.6405 7.88668C16.1209 7.33635 15.2785 7.33635 14.759 7.88668L5.27933 17.9279C4.24022 19.0286 4.24022 20.8131 5.27933 21.9138C6.31843 23.0144 8.00314 23.0144 9.04225 21.9138L20.331 9.95625C21.8897 8.30527 21.8897 5.62849 20.331 3.9775C18.7724 2.32651 16.2453 2.32651 14.6866 3.9775L7.05224 12.0641"
                      stroke="#A19686"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                  )
                 }
                  <svg
                    onClick={handleSendMessage}
                    size={18}
                    color="#ADB5BD"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M19.9274 1.31756C19.4274 0.806556 18.6874 0.617556 17.9974 0.817556L1.90742 5.46656C1.17942 5.66856 0.66342 6.24556 0.52442 6.97756C0.38242 7.72356 0.87842 8.67156 1.52642 9.06756L6.55742 12.1396C7.07342 12.4556 7.73942 12.3766 8.16642 11.9486L13.9274 6.18756C14.2174 5.88656 14.6974 5.88656 14.9874 6.18756C15.2774 6.47656 15.2774 6.94756 14.9874 7.24756L9.21642 13.0086C8.78842 13.4366 8.70842 14.1006 9.02342 14.6176L12.0974 19.6676C12.4574 20.2666 13.0774 20.6076 13.7574 20.6076C13.8374 20.6076 13.9274 20.6076 14.0074 20.5966C14.7874 20.4976 15.4074 19.9666 15.6374 19.2166L20.4074 3.24756C20.6174 2.56756 20.4274 1.82756 19.9274 1.31756Z"
                      fill="#A19686"
                    />
                    <path
                      opacity="0.4"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.51049 15.5471C1.31849 15.5471 1.12649 15.4741 0.980492 15.3271C0.687492 15.0341 0.687492 14.5601 0.980492 14.2671L2.34549 12.9011C2.63849 12.6091 3.11349 12.6091 3.40649 12.9011C3.69849 13.1941 3.69849 13.6691 3.40649 13.9621L2.04049 15.3271C1.89449 15.4741 1.70249 15.5471 1.51049 15.5471ZM5.27169 16.7395C5.07969 16.7395 4.88769 16.6665 4.74169 16.5195C4.44869 16.2265 4.44869 15.7525 4.74169 15.4595L6.10669 14.0935C6.39969 13.8015 6.87469 13.8015 7.16769 14.0935C7.45969 14.3865 7.45969 14.8615 7.16769 15.1545L5.80169 16.5195C5.65569 16.6665 5.46369 16.7395 5.27169 16.7395ZM5.52539 20.3075C5.67139 20.4545 5.86339 20.5275 6.05539 20.5275C6.24739 20.5275 6.43939 20.4545 6.58539 20.3075L7.95139 18.9425C8.24339 18.6495 8.24339 18.1745 7.95139 17.8815C7.65839 17.5895 7.18339 17.5895 6.89039 17.8815L5.52539 19.2475C5.23239 19.5405 5.23239 20.0145 5.52539 20.3075Z"
                      fill="#A19686"
                    />
                  </svg>
                  {!isFeed && (
                    <>
                      <svg
                        onClick={() => setIsRecording(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="21"
                        viewBox="0 0 17 21"
                        fill="none"
                      >
                        <path
                          d="M8.32511 13.9564H8.67481C10.5987 13.9564 12.231 12.7242 12.8054 11.0156C12.8887 10.7669 12.6979 10.5105 12.4334 10.5105H11.3562C11.0133 10.5105 10.7362 10.2369 10.7362 9.89922C10.7362 9.56056 11.0133 9.28601 11.3562 9.28601H12.4005C12.7463 9.28601 13.0263 9.00953 13.0263 8.66801C13.0263 8.32648 12.7463 8.05001 12.4005 8.05001H11.3562C11.0133 8.05001 10.7362 7.77545 10.7362 7.43775C10.7362 7.09909 11.0133 6.82453 11.3562 6.82453H12.4005C12.7463 6.82453 13.0263 6.54806 13.0263 6.20654C13.0263 5.86501 12.7463 5.58854 12.4005 5.58854H11.3562C11.0133 5.58854 10.7362 5.31398 10.7362 4.97532C10.7362 4.63762 11.0133 4.36306 11.3562 4.36306H12.4886C12.7483 4.36306 12.9343 4.11816 12.8645 3.87039C12.3501 2.06327 10.6694 0.739258 8.67481 0.739258H8.32511C5.92172 0.739258 3.97363 2.66213 3.97363 5.03655V9.6591C3.97363 12.0326 5.92172 13.9564 8.32511 13.9564Z"
                          fill="#A19686"
                        />
                        <path
                          opacity="0.4"
                          d="M16.0313 8.56494C15.4966 8.56494 15.0626 8.99257 15.0626 9.5216C15.0626 13.0947 12.1186 16.002 8.50048 16.002C4.88136 16.002 1.93743 13.0947 1.93743 9.5216C1.93743 8.99257 1.50345 8.56494 0.968716 8.56494C0.433985 8.56494 0 8.99257 0 9.5216C0 13.8265 3.29945 17.3805 7.53177 17.8579V19.7827C7.53177 20.3107 7.96478 20.7393 8.50048 20.7393C9.03522 20.7393 9.4692 20.3107 9.4692 19.7827V17.8579C13.7006 17.3805 17 13.8265 17 9.5216C17 8.99257 16.566 8.56494 16.0313 8.56494Z"
                          fill="#A19686"
                        />
                      </svg>
                    </>
                  )}
                </IconsContainer>
                <div
                  className="emoooo"
                  style={{ display: !showEmojiPicker && "none" }}
                >
                  <Picker
                    className="emoPicker"
                    data={data}
                    emojiSize={18}
                    onEmojiSelect={(e) => {
                      setCurrentEmoji(e.native);
                      handleEmojiSelect(e);
                      setShowEmojiPicker(!showEmojiPicker);
                    }}
                  />
                </div>
                <div></div>
              </div>
            </>
          )}

          {selectedImage && (
            <div
              className="msgContent"
              style={{
                alignSelf: "start",
                marginLeft: "2rem",
              }}
            >
              <img
                src={selectedImage && URL.createObjectURL(selectedImage)}
                alt="Selected"
                width="50"
                height="50"
              />
              <CiCircleRemove onClick={handleRemoveImage} />
            </div>
          )}
         
        </>
      )}
    </StyledInputContainer>
  );
}

export default StyledMessageInput;
