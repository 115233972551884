import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styles from './workingmax.module.css';
import axios from 'axios';
import { getGroupPersonalRecords, getOneRepMax } from '../../../../../../services/store/atheleteApi';
import { auth } from '../../../../../../firebase';
import ModalUi from '../../../../../Common/UI/Modals';
import Loader from '../../../../../ReusableUi/Loader';
import SplineAreaChart from '../../../../../Common/Graph/SplineAreaChart';

const Section = styled.section`
  margin: 0px 150px;
`;

const WorkingMax = ({ apiResponse, getBlocksId, exerciseId, ApisavedRepMaxValues }) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [getPersonalRecords, setPersonalRecords] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [viewOneRepxMax, setviewOneRepxMax] = useState();

  const handleOpenRepModal = () => {
    setOpenModal(!isOpenModal);
  };

  useEffect(() => {
    const fetchPersonalRecord = async () => {
      setLoading(true);  // Start loader
      try {
        const token = await auth.currentUser.getIdToken(true);
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getGroupPersonalRecords}?exerciseId=${exerciseId}`,
          { headers: { Authorization: token } }
        );
        setPersonalRecords(response.data);
      } catch (error) {
        console.error('Error fetching personal records:', error);
      } finally {
        setLoading(false);  // Stop loader
      }
    };

    fetchPersonalRecord();
  }, [getBlocksId, exerciseId]);

  useEffect(() => {
    const fetchOneRepMax = async () => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getOneRepMax}?exerciseId=${exerciseId}`,
          { headers: { Authorization: token } }
        );
        setviewOneRepxMax(response?.data?.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching one rep max:", error);
        setLoading(false); // Also stop loading on error
      }
    };

    fetchOneRepMax();
  }, [exerciseId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };
  
  // Safeguard: Default to empty arrays if undefined or null
  const personalRecords = getPersonalRecords?.personalRecords || [];
  const repsArray = personalRecords.map(item => item.reps || 0);
  const weightArray = personalRecords.map(item => item.weight || 0);

  // Prepare chart data
  const chartData = {
    labels: [0, ...weightArray], // Include 0 at the start
    datasets: [
      {
        label: 'Reps vs Weight',
        data: [0, ...repsArray], // Include 0 for reps to start from zero
        fill: true,
        backgroundColor: 'rgba(0, 0, 0, 0)', // Gradient will be applied dynamically
        borderColor: '#A19686',
        borderWidth: 2, // Thicker line for visibility
        tension: 0.4, // Spline effect
        pointRadius: 4, // Visible points
        pointHitRadius: 10, // Increase interaction radius for points
        pointBackgroundColor: '#A19686', // Point color
      },
    ],
  };

  return (
    <Section>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loader /> {/* Display loader while loading */}
        </div>
      ) : (
        <>
          <div className={styles.workingMaxPersonalRecords}>
            <span>Personal Records</span>
          </div>
          { personalRecords.map((data, index) => (
            <div className={styles.workingMaxPersonalRecordsData} key={index}>
              <div>
                <span>{data?.reps}RM @ {data?.weight}kg</span>
              </div>
              <div>
                <span>{formatDate(data?.createdAt)}</span>
              </div>
            </div>
          ))}
          <div className={styles.workingMaxPersonalRecords} style={{ marginTop: '10px' }}>
            <span>Working Max for 1-Rep</span>
          </div>
          <div className={styles.workingMaxforRep} onClick={handleOpenRepModal}>
            {viewOneRepxMax?.value 
              ? viewOneRepxMax.value 
              : apiResponse?.data?.oneRepMax 
              ? apiResponse?.data?.oneRepMax 
              : 0} Kg
          </div>
          <div className={styles.workingMaxGraph} style={{ marginTop: "10px" }}>
            <span>Working Max</span>
          </div>
          <div style={{ marginTop: "20px", width: "100%", height: "100vh" }}>
            <SplineAreaChart data={chartData} />
          </div>
          {isOpenModal && (
            <ModalUi
              isOpen={isOpenModal}
              onClose={handleOpenRepModal}
              exerciseId={exerciseId}
              valueResponse={apiResponse?.weight}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default WorkingMax;
