import React from 'react';
import PropTypes from 'prop-types'; // If using PropTypes for validation
import styles from "./reusableUi.module.css";
import SvgIcons from './SvgIcon';

const EmptyData = ({ title, subTitle, height, iconName, style, titleStyle, subTitleStyle }) => {
    return (
        <div 
        className="w-full d-flex flex-column justify-content-center align-items-center" 
        style={{ height: height, ...style }}
    >
        <SvgIcons iconName={iconName || "noSessionPlane"} />
        <div className="w-100 d-flex flex-column justify-content-center">
            <h4 
                style={{ color: "black", ...titleStyle }} 
                className={`${styles.noSessionHeading} d-flex justify-content-center`}
            >
                {title}
            </h4>
            {subTitle && (
                <div className="d-flex justify-content-center">
                    <h4 
                        style={{ color: `#63676F`, ...subTitleStyle }} 
                        className={styles.noSessionDescription}
                    >
                        {subTitle}
                    </h4>
                </div>
            )}
        </div>
    </div>
    
    );
};

EmptyData.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    height: PropTypes.string,
    iconName: PropTypes.string,
    style: PropTypes.object,
    titleStyle: PropTypes.object,
    subTitleStyle: PropTypes.object,
};

EmptyData.defaultProps = {
    height: '100%', // Default height if not specified
    subTitle: '', // Default to empty if not provided
    // Add other default props as needed
};

export default EmptyData;
