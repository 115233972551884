import { BrowserRouter as Router } from 'react-router-dom';
import SideBar from './components/sidebar/sideBar';
import { useContext, useEffect, useState } from 'react';
import './app.css';
import { AuthContext } from './context/authContext';
import { ThemeContext } from './context/themeContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './services/routes/AppRoutes';
import { auth } from './firebase';
import { getCustomLightThemeColor, defaultLightThemeColor, checkCoachSubscription } from './services/store/coachApi';
import axios from 'axios';
import useThemeColors from './hooks/useThemeColors';
import useCheckCoachSub from './hooks/useCheckCoachSub';
import Loader from './components/ReusableUi/Loader';
function App() {
  const { location, hide } = useContext(ThemeContext);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [CustomLightColors, setCustomLightColors] = useState(null);
  const { subscriptionPlan, loading, error } = useCheckCoachSub(checkCoachSubscription);
  useEffect(() => {
    const itemValue = localStorage.getItem('IsLogged');
    setCurrentUser(itemValue);
  }, [currentUser]);
  const isInvitationRoute = /^\/invitation\/[^/]+/.test(window.location.pathname);
  useEffect(() => {
    if (window.location.pathname.startsWith('/invitation/')) {
      sessionStorage.setItem('redirectURL', window.location.href);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        await fetchCustomLightTheme();
      } else {
        setCurrentUser(null);
      }
    });
    return () => unsubscribe();
  }, []);
  const fetchCustomLightTheme = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      // Try to fetch custom theme colors
      const response = await makeApiCall(getCustomLightThemeColor, token);
      console.log("Fetched custom theme colors:", response.data);
      setCustomLightColors(response.data);
    } catch (err) {
      console.error("Failed to fetch custom theme colors:", err.response || err.message);
      try {
        const token = await auth.currentUser.getIdToken(true);  // Reuse the token
        console.log("Attempting to fetch default light theme colors");
        const fallbackResponse = await makeApiCall(defaultLightThemeColor, token);
        console.log("Fetched default theme colors:", fallbackResponse.data);
        setCustomLightColors(fallbackResponse.data);
      } catch (fallbackErr) {
        console.error("Failed to fetch default light theme colors as well:", fallbackErr.response || fallbackErr.message);

        // toast.warn("Applying hardcoded default theme colors.");
        setCustomLightColors({
          primaryColor: "#F6F6F0",
          secondaryColor: "#A19686",
          primaryTextColor: "#000000",
          secondaryTextColor: "#606060",
        });
      }
    }
  };
  const makeApiCall = async (endpoint, token) => {
    try {
      const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      console.log("Making API call to:", url);
      const response = await axios.get(url, config);
      console.log("API call response:", response.data);
      return response;
    } catch (error) {
      console.error("Error making API call:", error.response || error.message);
      throw error;
    }
  };
  useThemeColors(CustomLightColors);

  return (
    <div className={location === '/login' ? 'custom' : 'app'}>
      <ToastContainer />
      <Router>
        {
          loading ?
            <div className='center' >
              <Loader />
            </div>
            :
            <>
              {window.location.pathname !== '/invitation/' && (
                <div className="parent">
                  <div className="child">{hide && <SideBar />}</div>
                </div>
              )}
              {window.location.pathname !== '/invitation/' && (
                <div className="destopSideBar">{currentUser && <SideBar checkPlan={subscriptionPlan} loading={loading} />}</div>
              )}
              <AppRoutes
                currentUser={currentUser}
                isInvitationRoute={isInvitationRoute}
                checkPlan={subscriptionPlan}
              />
            </>

        }

      </Router>
    </div>
  );
}
export default App;