import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./calender.css";
import { Col, Container, Row } from 'react-bootstrap';
import { getSessionCalender } from '../../../../apis';
import { auth } from '../../../../firebase';
import axios from 'axios';
import Avtr from "../../../../assessts/images/avtr.png";
import Loader from '../../../ReusableUi/Loader';
import SvgIcons from '../../../ReusableUi/SvgIcon';
import EmptyData from '../../../ReusableUi/EmptyData';


function translateDayAndDate(date, selectedLanguage, t) {
  const day = t(date.toLocaleDateString(selectedLanguage, { weekday: 'short' }));
  const dateString = t(date.toLocaleDateString(selectedLanguage, { day: '2-digit' }));

  return { day, date: dateString };
}

const Calender = () => {

  const [getSessionCalenderList, setSessionCalenderList] = useState([]);
  const { t, i18n } = useTranslation();
  const [selectedDay, setSelectedDay] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const today = new Date();
  const selectedLanguage = i18n.language;
  const [isEmptyDataCalender, setisEmptyDataCalender] = useState(false);
  // Generate timeline data for 60 days
  const timelineData = [...Array(60)].map((_, i) => {
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i);
    const { day, date: dateString } = translateDayAndDate(date, selectedLanguage, t);
    const timeString = date.toLocaleTimeString(selectedLanguage, { hour: '2-digit', minute: '2-digit', hour12: false });
    return { day, date: dateString, time: timeString, fullDate: date };
  });

  // Format today's date as YYYY-MM-DD
  const formattedTodayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

  useEffect(() => {
    // Automatically select today's date on component mount
    setSelectedDay(timelineData.findIndex(item => item.fullDate.toDateString() === today.toDateString()));
    fetchSessionCalendar(formattedTodayDate);
  }, []);

  // Fetch session calendar data
  const fetchSessionCalendar = async (selectedDate) => {
    setLoader(true);
    if (!selectedDate) return;
    try {
      setLoader(true);
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}${getSessionCalender}?date=${selectedDate}`, { headers: { Authorization: token } });
      {
        response?.data?.allSessionsByDate<= 0
          ? setisEmptyDataCalender(true)
          : setisEmptyDataCalender(false);
      }
      setSessionCalenderList(response?.data?.allSessionsByDate);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching session calendar:", error);
      setLoader(false);
    }
  };

  // Update selected date and fetch new data when a different day is clicked
  const onClickDay = (index) => {
    setSelectedDay(index);
    const date = timelineData[index].fullDate;
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    fetchSessionCalendar(formattedDate);
  };
  // Function to convert numbers to letters (A, B, C, ...)
  const numberToLetter = (number) => {
    return String.fromCharCode(65 + number);
  };
  // Function to render AthltBlockSets values for each exercise
  const formatAthBlckAthSession = (AthltBlockSets) => {
    return AthltBlockSets?.map(set => `${set?.value1}@${set?.value2}kg`).join(', ');
  };


  return (
    <Row>
      <Col xs={12} md={12} lg={12} className='w-100'>

        <div className='Calendar' style={{backgroundColor : `white` ,borderColor : `#A19686`}} >
          <div className="horizontal-timeline">
            {timelineData.map((item, index) => (
              <div key={index} className="timeline-item" onClick={() => onClickDay(index)}>
                <div className='day' style={{ backgroundColor: selectedDay === index ? 'var(--secondary-color,#A19686)' : ' ',color: selectedDay === index ? 'var(--primary-text-color,#FFF)' : '' }}>
                  <a id="daystyle" style={{color : `#A19686`,color: selectedDay === index ? 'var(--primary-text-color,#FFF)' : ''}}  > {item.day}</a>
                  <div className='' style={{ borderRadius: selectedDay === index ? '16px' : '', width: selectedDay === index ? '40px' : '', height: selectedDay === index ? '10px' : '', marginTop: selectedDay === index ? '7px' : '7px', color: selectedDay === index ? 'var(--primary-text-color,#FFF)' : ''}}>{item.date}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {isLoader ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Loader />
          </div>
        ) : isEmptyDataCalender ? (
          <div style={{height : "37vh"}} >

          <EmptyData
            title={"No Calender"}
            // subTitle={"Create a Session from Index Session From Groups Athlete and from Index Session."}
            />
            </div>
        ) : (
          <>
            {getSessionCalenderList?.map((data, index) => {
              return (
                <div className="calenderChiild2 mt-4 w-100" key={index} style={{ gap: "5px" }}>
                  <div className='d-flex align-items-center p-2'>
                    <img id="chkImage" src={Avtr} alt="Avatar" />
                    <p className="chkname" id="as" style={{ margin: 0, paddingLeft: "10px", fontWeight: "900", fontSize: "16px", textTransform: "capitalize" }}>                        {data.athlte ? `${data.athlte.firstName} ${data.athlte.lastName}` :
                          data.groupAthlete ? `${data.groupAthlete.firstName} ${data.groupAthlete.lastName}` : 'Group Session'}</p>
                  </div>
                  <div style={{ paddingLeft: "85px" }}>
                    <p id="as" style={{ fontWeight: "900", textTransform: "capitalize" }}>{data?.title}</p>
                    {(data?.AthBlckAthSession || data?.blocks)?.map((data, index) => {
                      return (
                        <>
                          <div className="child2innerDiv" key={index} style={{ marginBottom: "0px", paddingLeft: "20px", gap: "3px", textTransform: "capitalize" }}>
                            <div className="cirlce">{numberToLetter(index)}</div>
                            <div className='w-100' style={{ display: "flex", justifyContent: "space-between" }}>
                              <div className='px-2'>
                                <p id="as" style={{ margin: 0, padding: 0 }}>{data?.athltExercises?.title || data?.exercises?.title || "Traning App"}</p>
                                <p id="as" style={{ margin: 0, padding: 0, fontWeight: "bold" }}>
                                  {(data?.AthltBlockSets || data?.blockSets)?.length >= 1 ? (
                                    formatAthBlckAthSession(data?.AthltBlockSets || data?.blockSets)
                                  ) : (
                                    "There is No Set and Reps"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>

                      )
                    })}
                  </div>
                </div>
              )
            })}
          </>
        )
        }
      
      </Col>
    </Row>

  );
};

export default Calender;
