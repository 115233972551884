export const createUser = "/api/v1/users/createUser";
export const getUsers = "/api/v1/users/getUsers";
export const getChatById = "/api/v1/chat/getChatById/";
export const getGroupChatById = "/api/v1/groupChat/getGroupChatById/";
export const createGroup = "/api/v1/groupChat/createGroup/";
export const getGroups = "/api/v1/groupChat/getGroups";
export const athleteVerification = "/api/v1/athlete/athleteVerification";
export const groupMemberVerification = "/api/v1/athlete/groupMemberVerification";

//Homepage Api
//Session Api
export const getSessionHomepageCoach = "/api/v1/coach/viewCoachSessionsCurrDate";
//Assistant Coach 
export const getAssCoach = "/api/v1/coach/viewAllAssCoach";
export const inviteAssCoach = "/api/v1/coach/InviteAssCoach";
//Get Session Calender Api 
export const getSessionCalender = "/api/v1/coach/getCoachSessisonsByDate";

//Athelete Api 
export const checkConnectId = "/api/v1/coach/checkConnectId"
export const inviteAthelete = "/api/v1/coach/inviteAth"
export const getCoachAthelete = "/api/v1/coach/viewAllCoachAthletes"
export const createAtheleteSession ="/api/v1/coach/createAthSession"
export const EditAtheleteSession ="/api/v1/coach/editAthSession"

//Groups Api 
export const createCoachGroup = "/api/v1/coach/createGroup";
export const editCoachGroup = "/api/v1/coach/editGroup";
export const viewCoachGroup = "/api/v1/coach/viewAllCoachGroups";
export const deleteCoachGroup = "/api/v1/coach/deleteGroup";
export const createGroupSession = "/api/v1/coach/createGroupSession";
export const veiwGroupSession = "/api/v1/coach/ViewGroupSessions";
export const inviteAthletesToGroup = "/api/v1/coach/inviteAthletesToGroup";
export const EditGroupSession = "/api/v1/coach/editGroupSession";

//indexManagement apis
// Progams Api
export const createProgram = "/api/v1/coach/createProgram";
export const getAllPrograms = "/api/v1/coach/viewCoachIndexPrograms";
export const editProgram = "/api/v1/coach/editProgram";
export const deleteProgram = "/api/v1/coach/deleteProgram";
export const addSessionsToProgram = "/api/v1/coach/addSessionsToProgram";

// Session Api 
export const createSession = "/api/v1/coach/createCoachIndexSession";
export const getALLSessions = "/api/v1/coach/viewCoachIndexSessions";
export const editSession = "/api/v1/coach/editCoachIndexSession";
export const deleteSession = "/api/v1/coach/deleteCoachIndexSession";
export const getAllExerciseList = "/api/v1/coach/getAllCoachExercises";


//Exercise Api 
export const createExercise = "/api/v1/coach/createCoachIndexExercise";
export const getAllExercise = "/api/v1/coach/viewCoachIndexExercises";
export const editExercise = "/api/v1/coach/editCoachIndexExercise";
export const deleteExercise = "/api/v1/coach/deleteProgram"

//MarketPlace Api 
export const getMarketPlaceData = "/api/v1/coach/viewCoachMPPrograms";




// Athelet Side Api 
//Traning Area Api 
export const getAtheleSession = "/api/v1/athlete/viewAthleteSession"
export const addExAthSession = "/api/v1/athlete/addExAthSession";
export const calculateOneRepMax = "/api/v1/athlete/calculateOneRepMax";
export const calculatePersonalRecord = "/api/v1/athlete/calculatePersonalRecord";
export const saveOneRepMaxValue = "/api/v1/athlete/saveOneRepMax"
export const atheleteFinishSession = "/api/v1/athlete/finishSession"
export const getPersonalRecords = "/api/v1/athlete/"
export const getExerciseHistory = "/api/v1/athlete/"


//Feeds Api


//Create Groups 
export const getAtheleteGroups = "/api/v1/athlete/getGroupsByAthleteId"
export const getAtheleGroupSession = "/api/v1/athlete/viewGroupAthleteSession"
export const finishGroupSession = "/api/v1/athlete/finishGroupSession"