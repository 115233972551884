// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_coachPortalContainer__GW3Hm {
    background: none !important;
    overflow: hidden;
}
.layout_rowPpadding__h6Fl6 {
    padding: 0 !important;
  }
.layout_center__eGUyI {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/CoachPanel/layout.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,gBAAgB;AACpB;AACA;IACI,qBAAqB;EACvB;AACF;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".coachPortalContainer {\r\n    background: none !important;\r\n    overflow: hidden;\r\n}\r\n.rowPpadding {\r\n    padding: 0 !important;\r\n  }\r\n.center {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coachPortalContainer": `layout_coachPortalContainer__GW3Hm`,
	"rowPpadding": `layout_rowPpadding__h6Fl6`,
	"center": `layout_center__eGUyI`
};
export default ___CSS_LOADER_EXPORT___;
