import React from 'react'
import styles from './reusableUi.module.css'

const Button = ({label,  type = 'button', onClick, bg, color,svg}) => {
  return (
    <button 
    type={type}
    onClick={onClick}
    style={{
        backgroundColor: bg || 'var(--secondary-bg-color, #a19686)',
        color: color || '#fafafa',
      }}
    className={`${styles.btn}`}
    >
        {svg}{label}
    </button>
  )
}

export default Button
