import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/themeContext";

const UpgradePlan = () => {
  const navigate = useNavigate();
  const {
    setActiveTab,
  } = useContext(ThemeContext);
  const handleRoute = () => {
    setActiveTab("business")
    navigate("/business");
  };

  return (
    <div
      className="d-flex rounded-3 flex-column align-items-center justify-content-center text-center position-absolute w-100 h-100"
      style={{
        zIndex: 200,
        backgroundColor: "rgba(0, 0, 0, 0.6)", 
        backdropFilter: "blur(8px)", 
      }}
    >
      <p
        className="fw-bold fs-4 mb-3 text-white"
      >
        To Access This Feature, You Need to Upgrade Your Package
      </p>

      <button
        className="btn btn-dark px-4 py-2 fs-5 fw-bold rounded-3"
        onClick={handleRoute}
      >
        Upgrade Your Package
      </button>
    </div>
  );
};

export default UpgradePlan;
