import React from 'react'
import style from './last.module.css'
import { pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8 } from '../../../../../../assessts/LandingPage'

const GYMLast = () => {
  const data = [
    { img: pic1 },
    { img: pic2 },
    { img: pic3 },
    { img: pic4 },
    { img: pic5 },
    { img: pic6 },
    { img: pic7 },
    { img: pic8 },
  ]

  return (
    <div className={`${style.main} d-flex flex-column align-items-center justify-content-center`}>
      <div className={`${style.texttt} d-flex flex-column align-items-center text-center`}>
      <hr />
        <h1>OUR GYM GALLERY</h1>
        <p>We offer more than 35 group exercis, aerobic classes each week.</p>
      </div>
      
      <div className={`${style.beforeMap}`}>
        {data.map((item, key) => (
          <div key={key} className={`${style.mapped}`}>
            <div className={`${style.map} text-center`}>
              <img src={item.img}  />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GYMLast
