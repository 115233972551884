import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import Program from "../../../../assessts/images/indexCard.svg"
import { SessionContext } from '../../../../context/sessionContext';
import styles from "../atheleteIndex.module.css"
import axios from 'axios';
import { auth } from '../../../../firebase'
import { atheleteIndexSession, groupIndexSession } from '../../../../services/store/atheleteApi';
import Loader from '../../../ReusableUi/Loader';
import SvgIcons from '../../../ReusableUi/SvgIcon';
const Card = styled.div`
display: flex;
width: 100%;
`;
const CardBody = styled.div`
padding : 19px 15px;
width: 31%;
display: flex;
border-radius: 12px;
border-bottom: 1px solid var(--secondary-purple-b, #EAE6E0);
background: var(--white-100, #FFF);
box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
height: 100.315px;
flex-shrink: 0;
margin-right: 38px;
margin-bottom: 20px;
cursor: pointer; 
`;
const CardImage = styled.div`
width: 63px;
height: 63px;
flex-shrink: 0;
border-radius: 9px;
padding-right : 10px;
& > img {
    width: 100%;
    height: 100%;
}
`;

const IndexSession = ({ activeBtn }) => {
    const { setSessionDetails, setSessionIndexDetails } = useContext(SessionContext)
    const [getSessionData, setSessionData] = useState([])
    const [isLoading,setisLoading]=useState(false)
    const [IsEmptyData, setIsEmptyData] = useState(false);
    const [authReady, setAuthReady] = useState(false);
    const handleSessionDetails = (data) => {
        setSessionDetails(true)
        setSessionIndexDetails(data)
    }
    const fetchHistory = async () => {
        try {
            setisLoading(true)
            const token = await auth.currentUser.getIdToken(true);
            const endpoint = activeBtn === "group" ? groupIndexSession : atheleteIndexSession;
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}${endpoint}`, { headers: { Authorization: token } });
            {
                response?.data?.athleteSessions.length <= 0
                  ? setIsEmptyData(true)
                  : setIsEmptyData(false);
              }
            setSessionData(response?.data?.athleteSessions)
            setisLoading(false)
        } catch (error) {
            setisLoading(false)
            console.error("Error fetching session data:", error);
        }
    }

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
          setAuthReady(!!user);
        });
    
        return () => unregisterAuthObserver(); 
      }, []);
      useEffect(() => {
        if (authReady || activeBtn) {
          fetchHistory();
        }
      }, [authReady,activeBtn]);
    return (
        <div className='mt-4 d-flex w-100'>
            <Card>
                {
                isLoading ? (
                    <div className="d-flex justify-content-center align-items-center h-100 w-100">
                      <Loader />
                    </div>
                  )
                :  IsEmptyData ? (
                    <div
                      className="w-full d-flex flex-column justify-content-center align-items-center"
                      style={{ height: "20vw" }}
                    >
                      <SvgIcons iconName="noSessionPlane" />
                      <div className="w-100 d-flex flex-column justify-content-center">
                        <h4 className="noSessionHeading d-flex justify-content-center">
                          No Progammes
                        </h4>
                        <div className="d-flex justify-content-center">
                          {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
                        </div>
                      </div>
                    </div>
                  ) 
                  :
                getSessionData?.map((data, index) => {
                    return (
                        <CardBody onClick={() => handleSessionDetails(data)}>
                            <CardImage>
                                <img src={Program} />
                            </CardImage>
                            <div className={styles.sessionTitle}>
                                <div>{data?.title}</div>
                            </div>
                        </CardBody>
                    )
                })}
            </Card>
        </div>
    )
}

export default IndexSession