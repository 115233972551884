import React, { useContext, useEffect, useState } from "react";
import "./session.css";
import Avtr from "../../../../assessts/images/avtr.png";
import Communication from "../../../../assessts/icons/Communication.png";
import Finance from "../../../../assessts/icons/Finance.png";
import Time from "../../../../assessts/icons/Time.png";
import { SessionContext } from "../../../../context/sessionContext";
import { auth } from "../../../../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import { getSessionHomepageCoach } from "../../../../apis";
import Skeleton from "react-loading-skeleton";
import Loader from "../../../ReusableUi/Loader";
import SvgIcons from "../../../ReusableUi/SvgIcon";
const Session = () => {
  // States
  const [sessionList, setSessionList] = useState();
  const [isEmptyDataAllSession, setEmptyDataAllSession] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [totalPages, setTotalPages] = useState("");
  const [activePage, setActivePage] = useState(() => {
    const savedPage = localStorage.getItem("activePage");
    return savedPage !== null ? JSON.parse(savedPage) : 1;
  });
  // Context
  const { isOpenChat, handleOpenChat } = useContext(SessionContext);
  // Handle Pagination
  const handleActivePagination = (page) => {
    setActivePage(page);
  };
  // Fetch Sessions
  const fetchSession = async () => {
    setLoader(true);
    try {
      const token = await auth?.currentUser?.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getSessionHomepageCoach}?currentPage=1&itemsPerPage=20`,
        { headers: { Authorization: token } }
      );
      const listSession = response?.data?.combinedSessions;
      console.log("response?.data", response?.data);
      console.log(listSession);
      {
        listSession.length <= 0
          ? setEmptyDataAllSession(true)
          : setEmptyDataAllSession(false);
      }
      setSessionList(listSession);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      toast.error("Failed to fetch groups.");
    }
    setLoader(false);
  };
  // Handle Visibility Change
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      fetchSession();
    }
  };
  // Effect Hooks
  useEffect(() => {
    localStorage.setItem("activePage", JSON.stringify(activePage));
  }, [activePage]);
  useEffect(() => {
    fetchSession(activePage);
    // Add event listener for visibilitychange
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [activePage]);
  const coachName = auth.currentUser.displayName;
  return (
    <div   style={{height : "20vw"}} className="sessionsContainer w-100">
      {isLoader ? (
        <div  className="d-flex justify-content-center align-items-center h-100">
          <Loader />
        </div>
      ) : isEmptyDataAllSession ? (
        <div
          className="w-full d-flex flex-column justify-content-center align-items-center"
          style={{ height: "20vw"}}
        >
          <SvgIcons iconName="noSessionPlane" />
          <div className="w-100 d-flex flex-column justify-content-center">
            <h4
              className="noSessionHeading d-flex justify-content-center"
              style={{ color: "black" }}
            >
              No Session
            </h4>
            <div className="d-flex justify-content-center">
              <h4 className="noSessionDescription" style={{ color: `#63676F` }}>
                Create a Session from Index Session From Groups Athlete and from
                Index Session.
              </h4>
            </div>
          </div>
        </div>
      ) : (
        <div className="tableWrapper">
          <table className="mb-3">
            <thead   >
              <tr>
                <th
                  style={{
                    textAlign: "start",
                    padding: "8px 20px",
                    fontSize: "14px",
                    fontWeight: "700",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Athlete
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    fontSize: "14px",
                    fontWeight: "700",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Session Title
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Groups/Individual
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Activity
                </th>
              </tr>
            </thead>
            <tbody style={{ borderStyle: "none" }} className="tableBody">
              {sessionList?.map((data, index) => (
                <>
                  <br />
                  <tr key={index}>
                    <td className="chk">
                      <img id="chkImage" src={Avtr} alt="Avatar" />
                      <p className="chkname" id="as" style={{ margin: 0, fontSize: "16px", textTransform: "capitalize" }}>
                        {data.athlte ? `${data.athlte.firstName} ${data.athlte.lastName}` :
                          data.groupAthlete ? `${data.groupAthlete.firstName} ${data.groupAthlete.lastName}` : 'No Athlete'}
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p
                        className="chkname"
                        id="as"
                        style={{ margin: 0, fontSize: "16px" }}
                      >
                        {data.title}
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <p
                        className="chkname"
                        id="as"
                        style={{ margin: 0, fontSize: "16px" }}
                      >
                        {data.oneToOne === true ? "One to One" : "Groups"}
                      </p>
                    </td>
                    <td>
                      <div
                        className="icongap"
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          id="tableicon"
                          width="16px"
                          height="16px"
                          src={Time}
                          alt="Time"
                        />
                        <img
                          id="tableicon"
                          width="16px"
                          height="16px"
                          src={Communication}
                          onClick={handleOpenChat}
                          alt="Communication"
                        />
                        <img
                          id="tableicon"
                          width="16px"
                          height="16px"
                          src={Finance}
                          alt="Finance"
                        />
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default Session;