import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import Avtr from "../../../../assessts/images/avtr.png";
import SvgIcon from "../../../../assessts/Svg/svgIcons";
import CreateSessionBlock from "./Block";
import style from "./createSession.module.css";
import { auth } from "../../../../firebase";
import Button from "../../../ReusableUi/Button";
import {
  createAtheleteSession,
  createGroupSession,
  createSession,
  EditAtheleteSession,
  EditGroupSession,
  editSession,
  getAllExerciseList,
} from "../../../../apis";
import LoadingSpinner from "../../../loadingSpinner";
import { toast } from "react-toastify";
import axios from "axios";
import { ThemeContext } from "../../../../context/themeContext";
import { postApiMethod } from "../../../../store/usePostApi";
import {
  publishSession,
  unPublishSession,
} from "../../../../services/store/coachApi";
import DeleteSessonModel from "../../../coachPortal/athelete/atheleteCalender/DeleteSessionModel";
const CreateSession = (props) => {
  const {
 
    isOpen,
    onClose,
    isEditSession,
    setEditSession,
    SessionID,
    id,
    selectedDate,
  } = props;
  const selectedDateObj = selectedDate ? new Date(selectedDate) : new Date();
  useEffect(() => {
    isEditSession?.blocks?.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  }, [isEditSession]);

  const [DeleteIsOpen, setDeleteIsOpen] = useState(false);
  const day = selectedDateObj.getDay(); // Returns a number (0-6) representing the day of the week
  const date = selectedDateObj.getDate(); // Returns the day of the month (1-31)
  const month = selectedDateObj.getMonth(); // Returns a number (0-11) representing the month

  // Convert day and month to human-readable format
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const readableDay = dayNames[day];
  const readableMonth = monthNames[month];
  const coachName = auth.currentUser.displayName;
  const [BlockIndex, setBlockIndex] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [exerciseList, setExerciseList] = useState([]);
  const [BlockId, setBlockId] = useState("");
  const [RemoveBlock, setRemoveBlock] = useState(false);
  const [PublishLoading, setPublishLoading] = useState(false);
  const [sessionBlocks, setSessionBlocks] = useState([
    {
      title: "",
      selectedExercise: null,
      instructions: "",
      videoURL: "",
      blocktitle: "",
      Label: "",
      sets: [
        { title1: "", title2: "", reps: "", weight: "" },
        { title1: "", title2: "", reps: "", weight: "" },
        { title1: "", title2: "", reps: "", weight: "" },
      ],
      supersetGroup: "A",
      connected: false,
      supersetOrder: 0,
    },
  ]);

  const [isActiveLoader, setActiveLoader] = useState(false);
  const coachId = auth.currentUser.uid;
  const { activeTab } = useContext(ThemeContext);
  console.log("activeTab", activeTab);
  const [sessionFormData, setSessionFormData] = useState({
    title: isEditSession?.title || "",
    instructions: isEditSession?.instructions || "",
    addedByAthlete: false,
    fixedLength: activeTab === "index" ? true : false,
    groupSharing: activeTab === "group" ? true : false,
    oneToOne: activeTab === "group" ? false : true,
    coachId: coachId,
    athleteId: activeTab === "group" || activeTab === "index" ? null : id,
    programId: null,
    groupId: activeTab === "group" ? id : null,
    isComplete : false,
    date: selectedDate,
    programDate: {
      Week: null,
      Day: null,
    },
    leaderboard: {
      scoreType: "Weight",
      customCalculations: "More is better",
      numberOfIntervals: "4",
      leaderboardCalculations: "More is better",
    },
  });
  useEffect(() => {
    fetchExerciseList();
  }, []);
  useEffect(() => {
    if (isEditSession) {
      let formattedBlocks = [];

      if (Array.isArray(isEditSession.blocks)) {
        formattedBlocks = isEditSession.blocks.map((block) => ({
          id: block.id,
          blocktitle: block.blocktitle || "",
          selectedExercise: {
            title: block.exercises?.title || "Unknown Exercise",
            id: block.exerciseId || null,
          },
          instructions: block.exercises?.instructions || "",
          videoURL: block.exercises?.URL || "",
          connected: block.connected || false,
          sets: Array.isArray(block.blockSets)
            ? block.blockSets.map((set) => ({
                id: set.id,
                title1: set.title1 || "Untitled",
                title2: set.title2 || "Untitled",
                reps: set.value1 || "0",
                weight: set.value2 || "0",
                setDone: set.setDone || false,
              }))
            : [],
          supersetGroup: block.supersetGroup || "A",
          supersetOrder: block.supersetOrder || 0,
        }));
      } else {
        console.error("Unknown response format");
      }

      setSessionBlocks(formattedBlocks);
    } else {
      setSessionBlocks([
        {
          blocktitle: "",
          selectedExercise: null,
          instructions: "",
          videoURL: "",
          sets: [
            { title1: "", title2: "", reps: "", weight: "", setDone: false },
            { title1: "", title2: "", reps: "", weight: "", setDone: false },
            { title1: "", title2: "", reps: "", weight: "", setDone: false },
          ],
          connected: false,
          supersetGroup: "A",
          supersetOrder: 0,
        },
      ]);
    }
  }, [isEditSession]);

  const fetchExerciseList = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getAllExerciseList}?coachId=${coachId}`,
          { headers: { Authorization: token } }
        );
        setExerciseList(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const HandleForm = () => {
    handleSaveSession();
  };
  const handleSaveSession = async () => {
    try {
      setIsLoading(true);
      setActiveLoader(true);
      const token = await auth.currentUser.getIdToken(true);
      const endpoints = {
        index: [editSession, createSession],
        group: [EditGroupSession, createGroupSession],
        athelete: [EditAtheleteSession, createAtheleteSession],
      };
      console.log(endpoints, "endpoints");
      const [editEndpoint, createEndpoint] = endpoints[activeTab] || [
        null,
        null,
      ];
      const endpoint = isEditSession ? editEndpoint : createEndpoint;
      const payload = isEditSession
        ? {
            ...sessionFormData,
            blocks: sessionBlocks.map((block) => ({
              blockId: block.id,
              exerciseTitle: block.selectedExercise
                ? block.selectedExercise.title
                : "",
              exerciseURL: block.videoURL,
              exerciseInstructions: block.instructions,
              exerciseId: block.selectedExercise
                ? block.selectedExercise.id
                : null,
              exerciseCategory: block.selectedExercise
                ? block.selectedExercise.category || "Power"
                : "Power",
              blocktitle: block.blocktitle,
              sets: block.sets.map((set) => ({
                setId: set.id,
                title1: set.title1,
                title2: set.title2,
                value1: set.reps || "0",
                value2: set.weight || "0",
              })),
              supersetGroup: block.supersetGroup,
              supersetOrder: block.supersetOrder,
            })),
          }
        : {
            ...sessionFormData,
            blocks: sessionBlocks.map((block) => ({
              exerciseTitle: block.selectedExercise
                ? block.selectedExercise.title
                : "",
              exerciseURL: block.videoURL,
              exerciseInstructions: block.instructions,
              exerciseId: block.selectedExercise
                ? block.selectedExercise.id
                : null,
              exerciseCategory: block.selectedExercise
                ? block.selectedExercise.category || "Power"
                : "Power",
              blocktitle: block.blocktitle,
              sets: block.sets.map((set) => ({
                id: set.id,
                title1: set.title1,
                title2: set.title2,
                value1: set.reps || "0",
                value2: set.weight || "0",
              })),
              supersetGroup: block.supersetGroup,
              supersetOrder: block.supersetOrder,
            })),
          };

      await makeApiCall(endpoint, payload, token);
      onClose();

      toast.success(
        isEditSession
          ? "Session Updated Successfully"
          : "Session Created Successfully"
      );
    } catch (error) {
      setIsLoading(false);
      setActiveLoader(false);
      toast.error("An error occurred while saving the program");
    }
    setIsLoading(false);
  };

  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token },
    };
    console.log(endpoint, "endpoint");
    console.log(payload, "payload");
    console.log(token, "token");
    if (isEditSession) {
      config.params = { sessionId: isEditSession.id };
    }
    if (isEditSession) {
      await axios.patch(url, payload, config);
    } else {
      await axios.post(url, payload, config);
    }
  };
  const handleInstructionsChange = (blockIndex, newInstructions) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].instructions = newInstructions;
    setSessionBlocks(updatedBlocks);
  };
  const closeDeleteModal = () => {
    setDeleteIsOpen(false);
  };
  const handleURLChange = (blockIndex, newURL) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].videoURL = newURL;
    setSessionBlocks(updatedBlocks);
  };

  const handleInputSessionChange = (e, field) => {
    setSessionFormData({ ...sessionFormData, [field]: e.target.value });
  };

  const handleAddSet = (blockIndex) => {
    const newBlocks = [...sessionBlocks];
    newBlocks[blockIndex].sets.push({ reps: "", weight: "" });
    setSessionBlocks(newBlocks);
  };
  const handleBlockTitle = (blockIndex, newtitle) => {
    const updatedBlocks = [...sessionBlocks];
    updatedBlocks[blockIndex].blocktitle = newtitle;
    setSessionBlocks(updatedBlocks);
  };
  const handleRemoveSet = (blockIndex) => {
    const newBlocks = [...sessionBlocks];

    const numSets = newBlocks[blockIndex].sets.length;

    if (numSets > 0) {
      newBlocks[blockIndex].sets.splice(numSets - 1, 1);
      setSessionBlocks(newBlocks);
    }
  };

  const handleSetChange = (blockIndex, setIndex, key, value) => {
    const newBlocks = [...sessionBlocks];
    if (setIndex < 3) {
      newBlocks[blockIndex].sets.forEach((set, idx) => {
        if (idx < 3) {
          set[key] = value;
        }
      });
    } else {
      newBlocks[blockIndex].sets[setIndex][key] = value;
    }
    setSessionBlocks(newBlocks);
  };

  const handleSessionPublish = async (id) => {
    try {
      setEditSession((prevState) => ({
        ...prevState,
        published: !prevState.published,
      }));
      setPublishLoading(true);
      const endpoint = isEditSession?.published
        ? unPublishSession
        : publishSession;
      const payload = {
        [isEditSession?.published ? "id" : "ids"]: [id],
        sessionType: activeTab === "group" ? "group" : "athlete",
      };
      await postApiMethod(endpoint, payload);

      toast.success(
        isEditSession?.published
          ? "Session Unpublished Successfully"
          : "Session Published Successfully"
      );
    } catch (err) {
      console.error(err.message);
    } finally {
      setPublishLoading(false);
    }
  };

  const handleExerciseSelectionChange = (
    blockIndex,
    exerciseTitle,
    exerciseId
  ) => {
    const newBlocks = [...sessionBlocks];
    const selectedExerciseData = exerciseList.find(
      (exercise) => exercise.title === exerciseTitle
    );

    if (selectedExerciseData) {
      newBlocks[blockIndex] = {
        ...newBlocks[blockIndex],
        selectedExercise: selectedExerciseData,
        instructions: selectedExerciseData.instructions,
        videoURL: selectedExerciseData.URL,
        sets: newBlocks[blockIndex].sets.map((set) => ({
          ...set,
          title1: selectedExerciseData.title1 || "",
          title2: selectedExerciseData.title2 || "",
        })),
      };
    } else {
      console.error("Exercise not found for title:", exerciseTitle);
    }
    setSessionBlocks(newBlocks);
  };

  const getNextGroup = () => {
    const currentGroups = sessionBlocks.map((block) => block.supersetGroup);
    const allSameGroup = currentGroups.every(
      (group) => group === currentGroups[0]
    );

    if (allSameGroup) {
      const lastGroup = currentGroups[0];
      return String.fromCharCode(lastGroup?.charCodeAt(0) + 1);
    } else {
      const uniqueGroups = [...new Set(currentGroups)];
      return uniqueGroups.length > 0
        ? String.fromCharCode(
            uniqueGroups[uniqueGroups.length - 1]?.charCodeAt(0) + 1
          )
        : "A";
    }
  };

  const addSessionBlock = () => {
    const nextGroup = getNextGroup();

    // Add a new block with the determined supersetGroup
    setSessionBlocks((prevBlocks) => [
      ...prevBlocks,
      {
        title: "",
        selectedExercise: null,
        instructions: "",
        videoURL: "",
        blocktitle: "",
        Label: "",
        sets: [
          { title1: "", title2: "", reps: "", weight: "" },
          { title1: "", title2: "", reps: "", weight: "" },
          { title1: "", title2: "", reps: "", weight: "" },
        ],
        supersetGroup: nextGroup,
        supersetOrder: 0,
        connected: false,
      },
    ]);
  };

  const removeSessionBlock = (blockIndex, blockId) => {
    if (isEditSession) {
      setDeleteIsOpen(true);
      setBlockIndex(blockIndex);
    }
    if (!isEditSession) {
      setSessionBlocks((currentBlocks) =>
        currentBlocks.filter((_, index) => index !== blockIndex)
      );
    }
  };
  useEffect(() => {
    setSessionBlocks((currentBlocks) =>
      currentBlocks.filter((_, index) => index !== BlockIndex)
    );
  }, [RemoveBlock]);
  return (
    <>
      <div className={`invite-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-overlay" onClick={onClose}></div>
        <div
          style={{
            overflowY: "scroll",
            height: "83vh",
            maxWidth: "80%",
            width: "80% ",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
            zIndex: "1002",
            position: "relative",
            borderRadius: "8px",
            background: "var(--primary-bg-color, #FFF)",
          }}
        >
          <h2
            id="asf"
            className="pb-2"
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: `var(--primary-text-color,black)`,
            }}
          >
            {readableDay} {readableMonth} {date}
          </h2>
          <div className="userInfo d-flex ml-4 justify-content-between w-full">
            <div className="d-flex h-full">
              <img width="36px" height="36px" src={Avtr} alt="" />
              <div
                className="userNameAndStatus d-flex flex-column align-content-center"
                style={{ marginLeft: "10px" }}
              >
                <h6
                  style={{
                    color: `var(--primary-text-color,black)`,
                    fontSize: "16px",
                    fontWeight: "600",
                    margin: "0px",
                  }}
                  id="as"
                >
                  {coachName}
                </h6>
                <p id="asf" style={{ fontSize: "12px", color: "#4B52FF" }}>
                  {isEditSession?.published === true
                    ?  "Published"
                    :  "Unpublished" }
                </p>
              </div>
            </div>
            <div>
              {activeTab === "index" ? (
                ""
              ) : (
                <button
                  onClick={() => handleSessionPublish(isEditSession?.id)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: `var(--secondary-color,#A19686)`,
                    color: `var(--secondary-text-color,white)`,
                    height: "40px",
                    width: "120px",
                    borderRadius: "8px",
                    border: "none",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  {PublishLoading ? (
                    <LoadingSpinner size="sm" />
                  ) : isEditSession?.published === true ? (
                    "Unpublish"
                  ) : (
                    "Publish"
                    
                  )}
                </button>
              )}
            </div>
          </div>
          <Container className="m-0 p-0">
            <Row className="m-0 p-0">
              <Col sm={12} md={12} lg={12} className="m-0 p-0">
                <Form id="as">
                  <Form.Group className="mt-2 mb-2" controlId="formBasic">
                    <Form.Label
                      style={{
                        fontSize: "16px",
                        color: `var(--primary-text-color,black)`,
                      }}
                    >
                      Session Title
                    </Form.Label>
                    <Form.Control
                      style={{ backgroundColor: `#F6F6F6` }}
                      type="text"
                      name="sessionTitle"
                      placeholder="Session Title"
                      value={sessionFormData.title}
                      onChange={(e) => handleInputSessionChange(e, "title")}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasic">
                    <Form.Label
                      style={{
                        fontSize: "16px",
                        color: `var(--primary-text-color,black)`,
                      }}
                    >
                      Session Instruction
                    </Form.Label>
                    <Form.Control
                      style={{ backgroundColor: `#F6F6F6` }}
                      as="textarea"
                      rows={5}
                      name="coach"
                      placeholder="Session Instruction"
                      value={sessionFormData.instructions}
                      onChange={(e) =>
                        handleInputSessionChange(e, "instructions")
                      }
                    />
                    {sessionBlocks?.map((block, index) => (
                      <CreateSessionBlock
                        setBlockId={setBlockId}
                        isEditSession={isEditSession}
                        getNextGroup={getNextGroup}
                        sessionBlocks={sessionBlocks}
                        setSessionBlocks={setSessionBlocks}
                        fetchExerciseList={fetchExerciseList}
                        onClose={onClose}
                        handleRemoveSet={handleRemoveSet}
                        key={index}
                        blockIndex={index}
                        blockData={block}
                        handleBlockTitle={handleBlockTitle}
                        handleAddSet={handleAddSet}
                        handleSetChange={handleSetChange}
                        exerciseList={exerciseList}
                        removeBlock={removeSessionBlock}
                        handleExerciseSelectionChange={
                          handleExerciseSelectionChange
                        }
                        handleInstructionsChange={handleInstructionsChange}
                        handleURLChange={handleURLChange}
                      />
                      // <CreateSessionBlock
                      //   isEditSession={isEditSession}
                      //   getNextGroup={getNextGroup}
                      //   sessionBlocks={sessionBlocks}
                      //   setSessionBlocks={setSessionBlocks}
                      //   fetchExerciseList={fetchExerciseList}
                      //   onClose={onClose}
                      //   handleRemoveSet={handleRemoveSet}
                      //   key={index}
                      //   blockIndex={index}
                      //   blockData={block}
                      //   handleBlockTitle={handleBlockTitle}
                      //   handleAddSet={handleAddSet}
                      //   handleSetChange={handleSetChange}
                      //   exerciseList={exerciseList}
                      //   removeBlock={removeSessionBlock}
                      //   handleExerciseSelectionChange={handleExerciseSelectionChange}
                      //   handleInstructionsChange={handleInstructionsChange}
                      //   handleURLChange={handleURLChange}
                      // />
                    ))}

                    <div
                      className="sessionBtm mt-4"
                      style={{ textAlign: "end" }}
                    >
                      <div
                        style={{ marginLeft: "85px" }}
                        className="pluscircle"
                        onClick={addSessionBlock}
                      >
                        <SvgIcon iconName="plusButton" size={16} />
                      </div>
                      <div className="lastButtons mb-0">
                        <Button
                          onClick={onClose}
                          label={"Cancel"}
                          bg={"var(--primary-color, #a19686)"}
                          color={"var(--primary-text-color, white)"}
                        />
                        <Button
                          onClick={HandleForm}
                          label={
                            isLoading ? (
                              <LoadingSpinner size="sm" />
                            ) : isEditSession ? (
                              "Update"
                            ) : (
                              "Create"
                            )
                          }
                          bg={"var(--secondary-color, #a19686)"}
                          color={"var(--secondary-text-color, white)"}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {DeleteIsOpen && (
        <DeleteSessonModel
          SessionID={SessionID}
          activeTab={
            activeTab === "index"
              ? "deleteindexBlock"
              : activeTab === "athelete"
              ? "deleteAtheletBlock"
              : activeTab === "group"
              ? "deleteGroupBlock"
              : null
          }
          // GroupSession={GroupSession}
          // IsDelete={IsDelete}
          RemoveBlock={RemoveBlock}
          setRemoveBlock={setRemoveBlock}
          isOpen={DeleteIsOpen}
          onClose={closeDeleteModal}
          Id={BlockId}
        />
      )}
    </>
  );
};

export default CreateSession;
