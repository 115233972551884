import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import logo from '../../../../assessts/logotrnDark.png';
import style from './navbar.module.css';
import { Link } from 'react-router-dom';

const CustomNavbar = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="sm" style={{ fontFamily: "'Hurme Geometric Sans 2', sans-serif" }}>
      <div className={style.main}>
        <Link to="/landingHome">
          <img src={logo} alt="logo" height="30" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className={`${style.links} ms-auto text-center`}>
            <Link to="/landingCoach" className={`${style.routerLink} ${window.location.pathname === "/landingCoach" ? style.SELECTED : ""}`} >Coaches</Link>
            <Link to="/landingAthlete" className={`${style.routerLink} ${window.location.pathname === "/landingAthlete" ? style.SELECTED : ""}`}>Athletes</Link>
            <Link to="/landingGym" className={`${style.routerLink} ${window.location.pathname === "/landingGym" ? style.SELECTED : ""}`}>Gym</Link>
            <Link to="/landingMP" className={`${style.routerLink} ${window.location.pathname === "/landingMP" ? style.SELECTED : ""}`}>Marketplace</Link>
            <Link to="/landingPricing" className={`${style.routerLink} ${window.location.pathname === "/landingPricing" ? style.SELECTED : ""}`}>Pricing</Link>
          </Nav>
          <Link to="/login">
          <Button variant="outline-light" className={`ms-2 ${style.btn}`}>Sign In</Button>
          </Link>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default CustomNavbar;
