import React, { useEffect, useState } from "react";
import "./analytics.css";
import AnalyticsTable from "./analyticsTable/analyticsTable";
import AnalysisAtheleteTable from "./atheletTable/aheletTable";


const Analytics = () => {
  const [activeBtn, setActivebtn] = useState("group");







  const renderTable = () => {
    return activeBtn === "athlete" ? <AnalysisAtheleteTable  /> : <AnalyticsTable  />;
  };

  return (
    <div className="athltContainer" style={{marginLeft : "12px"}} >
      <div className="businessHeaderbuttons" style={{ margin: "30px auto" }}>
        <div
          id={activeBtn === "group" ? "activeButton" : ""}
          style={{
            width: "50%",
            backgroundColor: activeBtn === "group" ? "var(--secondary-color, #A19686)" : "",
          }}
        >
          <button variant="text" onClick={() => setActivebtn("group")}>
            <span>Group</span>
          </button>
        </div>
        <div
          id={activeBtn === "athlete" ? "activeButton" : ""}
          style={{
            width: "50%",
            backgroundColor: activeBtn === "athlete" ? "var(--secondary-color, black)" : "",
          }}
        >
          <button
            onClick={() => setActivebtn("athlete")}
            style={{
              color:
                activeBtn === "athlete"
                  ? "var(--secondary-text-color, white)"
                  : "var(--primary-text-color, black)",
            }}
          >
            <span>Athlete</span>
          </button>
        </div>
      </div>
     
      <div className="athbody" style={{ padding: "1rem 2rem" }}>
        {renderTable()}
      </div>
    </div>
  );
};

export default Analytics;
