import React, { useRef, useState } from 'react'
import style from "./feeds.module.css"
import styled from 'styled-components'
import FeedImg from "../../../assessts/images/exerciseImage.png";
import Avatar from "../../../assessts/images/avtr.png"
import { auth } from '../../../firebase'
import { postFeed } from '../../../services/store/atheleteApi'
import { toast } from 'react-toastify'
import axios from 'axios'
const Header = styled.header`
display: flex;
padding: 30px;
`;
const Section = styled.section`
padding: 30px;
& > div:first-child {
    display: flex;
    width: 369px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid var(--secondary-purple-b, #EAE6E0);
    background: var(--primary-light, #FFF);
}
& > div:nth-child(2){
    width: 369px;
    height: 198px;
    flex-shrink: 0;
    border-radius: 12px;
    margin-top: 20px;
    & > img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    & > video {
        margin-top: 15px;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        object-fit: fill;
    } 
} 
`;
const Footer = styled.footer`
padding: 30px;
& > div {
    display: flex;
    width: 369px;
    height: 46px;
    padding: 12px 38px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--secondary-purple-a, #A19686);
    & > span {
        color: var(--primary-light, var(--white-100, #FFF));
        text-align: center;
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        /* 18 Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
    }
}
`;
const CreateFeeds = ({ sessionId, coachId, handleCreateFeedOpen, groupDetails }) => {
   
    const fileInputRef = useRef(null);
    const videoRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [formData, setFormData] = useState({
        athleteSessionId: sessionId,
        imageOrVideoUrl: "https://example.com/image123.jpg",
        coachId: coachId,
        athleteId: auth.currentUser.uid
    })
    const [isGroupSessionData, setGroupSessionData] = useState({
        groupAthleteSessionId: sessionId,
        imageOrVideoUrl: "https://example.com/image123.jpg",
        coachId: coachId,
        athleteId: auth.currentUser.uid,
        groupId: groupDetails?.id
    })
    const handleSvgClick = () => {
        fileInputRef.current.click();
    };

    const handleCameraSvgClick = async () => {
        if (!isCameraOn) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });

                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    setIsCameraOn(true);
                } else {
                    console.error("videoRef.current is null");
                }
            } catch (error) {
                console.error("Error accessing camera:", error);
            }
        }
    };
    // Handle To Create  Feed 
    const handleCreateFeed = async () => {
        try {
            const token = await auth.currentUser.getIdToken(true);

            // Choose the appropriate form data based on groupDetails
            const dataToUpload = groupDetails ? isGroupSessionData : formData;
          

            // Make the API call with the selected form data
            await makeApiCall(postFeed, dataToUpload, token);
            toast.success("Congratulations! Your Feed is Created Successfully.");
            handleCreateFeedOpen();
        } catch (error) {
            toast.error("An error occurred while saving the feed");
            console.error(error);
        }
    };

    /**
* Makes an API call to the given endpoint with the provided payload and token.
* @param {string} endpoint - API endpoint
* @param {Object} payload - Data to send in the API request
* @param {string} token - Authorization token
*/
    const makeApiCall = async (endpoint, payload, token) => {
        const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
        const config = {
            headers: { Authorization: token }
        };
        await axios.post(url, payload, config);
    }
    return (
        <>
            <Header>
                <div className={style.userAvtar}>
                    <img src={Avatar} />
                </div>
                <div className={style.postSessionFeed}><span>Post Your Session Feed here</span></div>
            </Header>
            <Section>
                <div>
                    <span>
                        Add another Image/ Video
                    </span>
                    <div>
                        <svg onClick={handleCameraSvgClick} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.257 20.5226C21.257 20.5226 20.598 21.1816 19.666 21.1816H4.66602C4.66602 21.1816 3.73404 21.1816 3.07503 20.5226C3.07503 20.5226 2.41602 19.8636 2.41602 18.9316V8.43164C2.41602 8.43164 2.41602 7.49966 3.07503 6.84065C3.07503 6.84065 3.73404 6.18164 4.66602 6.18164H7.26463L8.54198 4.26562C8.68108 4.05697 8.91525 3.93164 9.16602 3.93164H15.166C15.4168 3.93164 15.651 4.05697 15.7901 4.26562L17.0674 6.18164H19.666C19.666 6.18164 20.598 6.18164 21.257 6.84065C21.257 6.84065 21.916 7.49966 21.916 8.43164V18.9316C21.916 18.9316 21.916 19.8636 21.257 20.5226ZM20.1963 19.462C20.1963 19.462 20.416 19.2423 20.416 18.9316V8.43164C20.416 8.43164 20.416 8.12098 20.1963 7.90131C20.1963 7.90131 19.9767 7.68164 19.666 7.68164H16.666C16.4153 7.68164 16.1811 7.55631 16.042 7.34767L14.7646 5.43164H9.5674L8.29005 7.34767C8.15095 7.55631 7.91678 7.68164 7.66602 7.68164H4.66602C4.66602 7.68164 4.35536 7.68164 4.13569 7.90131C4.13569 7.90131 3.91602 8.12098 3.91602 8.43164V18.9316C3.91602 18.9316 3.91602 19.2423 4.13569 19.462C4.13569 19.462 4.35536 19.6816 4.66602 19.6816H19.666C19.666 19.6816 19.9767 19.6816 20.1963 19.462Z" fill="black" fill-opacity="0.8" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.166 9.18164C12.166 9.18164 13.8746 9.18164 15.0828 10.3898C15.0828 10.3898 16.291 11.598 16.291 13.3066C16.291 13.3066 16.291 15.0153 15.0828 16.2235C15.0828 16.2235 13.8746 17.4316 12.166 17.4316C12.166 17.4316 10.4574 17.4316 9.2492 16.2235C9.2492 16.2235 8.04102 15.0153 8.04102 13.3066C8.04102 13.3066 8.04102 11.598 9.2492 10.3898C9.2492 10.3898 10.4574 9.18164 12.166 9.18164ZM12.166 10.6816C12.166 10.6816 11.0787 10.6816 10.3099 11.4505C10.3099 11.4505 9.54102 12.2193 9.54102 13.3066C9.54102 13.3066 9.54102 14.394 10.3099 15.1628C10.3099 15.1628 11.0787 15.9316 12.166 15.9316C12.166 15.9316 13.2533 15.9316 14.0222 15.1628C14.0222 15.1628 14.791 14.394 14.791 13.3066C14.791 13.3066 14.791 12.2193 14.0222 11.4505C14.0222 11.4505 13.2533 10.6816 12.166 10.6816Z" fill="black" fill-opacity="0.8" />
                        </svg>
                    </div>
                    <div style={{ cursor: "pointer" }}>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                        <svg onClick={handleSvgClick} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16602 5.43164C3.16602 5.43164 3.16602 4.81032 3.60536 4.37098C3.60536 4.37098 4.0447 3.93164 4.66602 3.93164H19.666C19.666 3.93164 20.2873 3.93164 20.7267 4.37098C20.7267 4.37098 21.166 4.81032 21.166 5.43164V20.4316C21.166 20.4316 21.166 21.053 20.7267 21.4923C20.7267 21.4923 20.2873 21.9316 19.666 21.9316H4.66602C4.66602 21.9316 4.04469 21.9316 3.60536 21.4923C3.60536 21.4923 3.16602 21.053 3.16602 20.4316V5.43164ZM4.66602 5.43164V20.4316H19.666V5.43164H4.66602Z" fill="black" fill-opacity="0.8" />
                            <path d="M3.38597 16.9009C3.24531 17.0416 3.16602 17.2326 3.16602 17.4316C3.16602 17.4436 3.1663 17.4556 3.16688 17.4676C3.17584 17.6538 3.25384 17.83 3.38569 17.9619C3.52634 18.1025 3.7171 18.1816 3.91602 18.1816C4.11493 18.1816 4.30569 18.1025 4.44635 17.9619L7.66197 14.7463C7.66602 14.7422 7.66601 14.7422 7.66601 14.7422L9.60131 16.6775C9.80872 16.8882 10.0867 17.0045 10.0867 17.0045C10.3647 17.1209 10.666 17.1209 10.666 17.1209C10.9674 17.1209 11.2453 17.0045 11.2453 17.0045C11.5233 16.8882 11.7348 16.6734 11.7348 16.6734L15.912 12.4963L15.916 12.4922L19.8857 16.4619C20.0263 16.6025 20.2171 16.6816 20.416 16.6816C20.6149 16.6816 20.8057 16.6025 20.9463 16.4619C21.087 16.3212 21.166 16.1305 21.166 15.9316C21.166 15.7326 21.087 15.5419 20.9463 15.4012L16.9848 11.4397C16.7733 11.225 16.4953 11.1086 16.4953 11.1086C16.2174 10.9922 15.916 10.9922 15.916 10.9922C15.6147 10.9922 15.3367 11.1086 15.3367 11.1086C15.0587 11.225 14.8513 11.4356 14.8513 11.4356L10.666 15.6209L10.662 15.6169L8.73477 13.6897C8.52331 13.475 8.24534 13.3586 8.24534 13.3586C7.96737 13.2422 7.66602 13.2422 7.66602 13.2422C7.36466 13.2422 7.08669 13.3586 7.08669 13.3586C6.80872 13.475 6.60131 13.6856 6.60131 13.6856L3.38597 16.9009Z" fill="black" fill-opacity="0.8" />
                            <path d="M10.666 9.55664C10.666 10.178 10.1623 10.6816 9.54102 10.6816C8.91974 10.6816 8.41602 10.178 8.41602 9.55664C8.41602 8.93532 8.91974 8.43164 9.54102 8.43164C10.1623 8.43164 10.666 8.93532 10.666 9.55664Z" fill="black" fill-opacity="0.8" />
                        </svg>
                    </div>
                </div>
                <div>

                    <img src={FeedImg} alt="" />
                    <video ref={videoRef} width="640" height="480" autoPlay style={{ display: isCameraOn ? 'block' : 'none' }}></video>
                </div>

            </Section>
            <Footer>
                <div style={{ cursor: "pointer" }} onClick={handleCreateFeed}>
                    <span>Post Feed</span>
                </div>
            </Footer>
        </>
    )
}

export default CreateFeeds