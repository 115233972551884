import React, { useEffect, useState } from 'react'
import History from './History/History';
import WorkingMax from './WorkingMax/WorkingMax';
import { historyData } from '../../../admincomponents/jsonData/atheletes';

const SessionDetails = (props) => {
    const [data, setData] = useState("")
    const { activeBtn, apiResponse, apiPersonalRecordResponse, exerciseId, blockId } = props;

    useEffect(() => {
        setData(historyData);
      }, []);
    return (
        <>
            {activeBtn === "history" ? (<>
                <History history={data} exerciseId={exerciseId} /></>) : (<WorkingMax exerciseId={exerciseId} blockId={blockId} apiResponse={apiResponse} apiPersonalRecordResponse={apiPersonalRecordResponse} />)}
        </>
    )
}

export default SessionDetails