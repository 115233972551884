import React, { useContext } from 'react';
import CreateFeeds from '../Feeds/CreateFeed';
import { SessionContext } from '../../../context/sessionContext';
const ModalUi = ({ isOpen, sessionId, coachId, groupDetail }) => {

const {handleCreateFeed} =useContext(SessionContext)
  return (
    <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`} style={{width: "100%", padding: "0px"}}>
      <div className="exercise-overlay" onClick={handleCreateFeed}></div>
      <div className="exercisemmodalBody" style={{width: "28%", padding: "0px", maxWidth: "28%"}}>
        <CreateFeeds groupDetails={groupDetail} handleCreateFeedOpen={handleCreateFeed} sessionId= {sessionId} coachId = {coachId} />
      </div>
    </div>
  );
};

export default ModalUi;
