import React, { useContext, useEffect, useState } from 'react'
import ManageGroupTables from './Tables/manageGroupsTable'
import { SessionContext } from '../../../context/sessionContext'
import styles from "./atheletegroups.module.css"
import AtheleteGroupSession from '../../modules/AtheleteGroupSession'
import { auth } from '../../../firebase'
import axios from 'axios'
import { getAtheleteGroups } from '../../../apis'


const ManageGroups = () => {
  const [isGroupData, setGroupData] = useState([])
  const [isOpenGroupHistory, setOpenGroupHistory] = useState(false)
  const [isSelectedGroup, setSelectedGroup] = useState({})
  const { setStartSession} = useContext(SessionContext)
  const [authReady, setAuthReady] = useState(false);
  const[isLoader,setIsloader]=useState(false);
  const [isEmptyDataForGroups, setisEmptyDataForGroups] = useState(false);
  const {
  setSessionHistory
} = useContext(SessionContext);
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user); // Set authReady to true if user is logged in
    });

    return () => unregisterAuthObserver(); // Clean up observer on unmount
  }, []);
  const fetchAtheleteGroups = async () => {
    setIsloader(true)
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getAtheleteGroups}`,
        { headers: { Authorization: token } }
      );
      {response.data.length <= 0 ? setisEmptyDataForGroups(true) : setisEmptyDataForGroups(false)}
      setGroupData(response.data)
    
      setIsloader(false)
      
      // setHistory(response.data.responseData[0].AthBlckAthSession)
    } catch (error) {
      setIsloader(false)
      console.error("Error fetching session calendar:", error);
    }
  }

  useEffect(() => {
    if (authReady) {
      fetchAtheleteGroups()
    }
  }, [authReady]);
  const handleGroupDetails = (data) => {
    setOpenGroupHistory(!isOpenGroupHistory)
    setSelectedGroup(data)
    setSessionHistory(false)
  }
  return (
    <div className={styles.manageGroupParent}>

      {isOpenGroupHistory ?
        (
          <>
            <AtheleteGroupSession setOpenGroupHistory={setOpenGroupHistory}  handleSessionGetData={fetchAtheleteGroups} isGroupData={isGroupData} groupDetails={isSelectedGroup} />
          </>
        ) : (
          <>
            <div style={{ height: "calc(100vh - 90px)" }} className="athltContainer">
              <div className="athltHeader">Groups</div>
              <div
                className="athbody"
                style={{
                  padding: "2rem",
                }}
              >
                <ManageGroupTables isLoader={isLoader}  isEmptyDataForGroups={isEmptyDataForGroups}  isGroupData={isGroupData} handleGroupDetails={handleGroupDetails} />
              </div>
            </div></>)}
    </div>

  )
}

export default ManageGroups