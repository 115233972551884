import React, { useContext, useEffect, useState } from "react";
// import "./athelete.css";
import AtheleteTable from "./atheleteTable/atheleteTable";
import { FaSearch, FaPlus } from "react-icons/fa";
import CoachAtheleteModal from "../../modals/CoachAtheleteModal";
import { SessionContext } from "../../../context/sessionContext";
import { auth } from "../../../firebase";
import { checkConnectId, getCoachAthelete } from "../../../apis";
import axios from "axios";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
import ConnectAccountModal from "../../modals/ConnectAccountModal";
import {
  deleteAthelete,
  deleteInvitedAthelete,
  getAllAthelete,
  getTotalStatus,
  invitedAtheleteDropDown,
} from "../../../services/store/coachApi";
import { getApiMethod } from "../../../store/useGetApi";
import MonthlyCalender from "../../modules/Calender/MonthCalender";
const Athelete = ({isGrowthPlan}) => {
 
  const [SelectedData, setSelectedData] = useState("");
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  const [isEmptyDataForJoined, setEmptyDataForJoined] = useState(false);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isConnectAccountModalOpen, setConnectAccountModalOpen] =
    useState(false);
  const [getAtheleteGroup, setAtheleteGroup] = useState(null);
  const [isTotalPages, setTotalPages] = useState("");
  const [getAtheleteId, setAtheleteId] = useState("");
  const [getInvitedAtheleteId, setInvitedAtheleteId] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [getAtheleteInvited, setAtheleteInvited] = useState([]);
  const [selectedAthelete, setSelectedAthelete] = useState([]);
  const [isShowDeleteCheckBox, setShowDeleteCheckBox] = useState(true);
  const [getDropDownData, setDropDownData] = useState([]);

  const [isAtheleteData, setAtheleteData] = useState([]);
  const handleDelete = () => {
    setShowDeleteCheckBox(!isShowDeleteCheckBox);
    const updatedData = isAtheleteData.filter(
      (data) => !isAtheleteData.includes(data.id)
    );
    setAtheleteData(updatedData);
    if (selectedAthelete.length > 0) {
      if(isActiveTab==="invited"){
        handleDeleteInvitedAthelete()
      }else{

        handleDeleteAthelete();
      }
    }
    // Clear the selected items
    setSelectedAthelete([]);
  };

  const [isActiveTab, setActiveTab] = useState("jioned");
  const [isPaginataionathelete, setPaginataionathelete] = useState(() => {
    const savedPage = localStorage.getItem("activePage");
    return savedPage !== null ? JSON.parse(savedPage) : 1;
  });


  const [userDetails, setUserDetails] = useState({});
  
  useEffect(() => {
      const fetchFeedDetails = async () => {
          try {
              const token = await auth.currentUser.getIdToken(true);
              const response = await axios.get(
                  `${process.env.REACT_APP_Baseurl}${getTotalStatus}`,
                  { headers: { Authorization: `Bearer ${token}` } }
              );
              setUserDetails(response.data);
          } catch (error) {
              console.error("Error fetching user details:", error);
          }
      };

      fetchFeedDetails();
  }, []);

  const handleDeleteAthelete = async () => {
    setShowDeleteCheckBox(!isShowDeleteCheckBox);
    try {
      const token = await auth.currentUser.getIdToken(true);

      await axios.delete(`${process.env.REACT_APP_Baseurl}${deleteAthelete}`, {
        headers: { Authorization: token },
        params: { athleteID: selectedAthelete },
      });

      setSelectedAthelete([]);
      setrefresh(!refresh);
      toast.success("Exercise Deleted successfully");
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
  const handleDeleteInvitedAthelete = async () => {
    setShowDeleteCheckBox(!isShowDeleteCheckBox);
    try {
      const token = await auth.currentUser.getIdToken(true);

      await axios.delete(`${process.env.REACT_APP_Baseurl}${deleteInvitedAthelete}`, {
        headers: { Authorization: token },
        params: { athleteID: selectedAthelete },
      });

      setSelectedAthelete([]);
      setrefresh(!refresh);
      toast.success("Exercise Deleted successfully");
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
  const checkConnectAccount = async () => {
    const token = await auth.currentUser.getIdToken(true);
    const apiUrl = `${process.env.REACT_APP_Baseurl}${checkConnectId}`;
    const response = await axios.get(apiUrl, {
      headers: { Authorization: token },
    });
    return response.data;
  };
  const openInviteModal = async () => {
    const { hasConnectId } = await checkConnectAccount();
    hasConnectId == true && setInviteModalOpen(true);
    hasConnectId == false && setConnectAccountModalOpen(true);
  };

  const closeConnectAccountModal = () => {
    setConnectAccountModalOpen(false);
    setrefresh((prev) => !prev);
  };

  const closeInviteModal = () => {
    setInviteModalOpen(false);
    setrefresh((prev) => !prev);
  };

  useEffect(() => {
    // This effect sets up the auth state observer
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); // Set the authenticated user
      if (currentUser) {
        // User is signed in, now it's safe to fetch data
        fetchAtheleteGroup(isPaginataionathelete); // Make sure this function and its dependencies are correctly set up
      } else {
        // User is signed out
        console.log("No user is signed in.");
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, [isPaginataionathelete]); // Add other dependencies as needed

  useEffect(() => {
    localStorage.setItem("activePage", JSON.stringify(isPaginataionathelete));
  }, [isPaginataionathelete]);
  const handleActivePaginationathelete = (page) => {
    setLoader(true);
    setPaginataionathelete(page); // Update pagination state
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      fetchAtheleteGroup();
    }
  };
  useEffect(() => {
    fetchAtheleteGroup();
  }, [refresh]);
  const fetchAtheleteGroup = async (page) => {
    if (!auth.currentUser) {
      return; // Exit the function if no user is logged in.
    }
    try {
      setLoader(true);
      const token = await auth.currentUser.getIdToken(true);
      const apiUrl = `${
        process.env.REACT_APP_Baseurl
      }${getCoachAthelete}?currentPage=${page}&itemsPerPage=2${
        currentSearchTerm ? `&search=${currentSearchTerm}` : ""
      }`;
      const response = await axios.get(apiUrl, {
        headers: { Authorization: token },
      });

      {
        response.data.coachAthletes.length <= 0
          ? setEmptyDataForJoined(true)
          : setEmptyDataForJoined(false);
      }
      setAtheleteGroup(response.data.coachAthletes);

      setTotalPages(response.data.totalPages);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    }
  };
  // Implementing a basic debounce function
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const handleSearchChange = debounce((e) => {
    const searchTerm = e.target.value;
    setCurrentSearchTerm(searchTerm); // Update the current search term
    fetchAtheleteGroup(isPaginataionathelete, searchTerm);
  }, 500);

  useEffect(() => {
    fetchAtheleteGroup(isPaginataionathelete);
    // Add event listener for visibilitychange
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isPaginataionathelete, currentSearchTerm]);

  const handleGroupDropDown = async () => {
    try {
      console.log(isActiveTab);
      const endpoint =
        isActiveTab === "invited" ? invitedAtheleteDropDown : getAllAthelete;
      const dropDownData = await getApiMethod(endpoint, "get");
      console.log(dropDownData);
      if (isActiveTab === "invited") {
        setDropDownData(dropDownData?.invitations);
      } else {
        setDropDownData(dropDownData?.coachAthletes);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  useEffect(() => {
    handleGroupDropDown();
  }, [isActiveTab]);
  return (
    <Container fluid>
      <div style={{ backgroundColor: `white` }} className="athltContainer">
        {/* {!show && <div className="athltHeader">Atheletes</div>} */}
        <div
          className={!show && "athbody"}
          style={{
            padding: !show && "2rem",
          }}
        >
          {!show && (
            <div className="athlt2ndHeader">
              <div>
                <div
                  className="search-container"
                  style={{ backgroundColor: `white` }}
                >
                  <span className="search-icon h-100 d-flex justify-content-center">
                    <FaSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <div className="athltbuttons">
                <button
                  className="delete-button"
                  style={{
                    color: "var(--primary-text-color, #A19686)",
                    background: "var(--primary-color, #F6F6F0)",
                  }}
                  onClick={handleDelete}
                >
                  <span className="delete-icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.875 4.25H3.125C2.77982 4.25 2.5 4.52982 2.5 4.875C2.5 5.22018 2.77982 5.5 3.125 5.5H16.875C17.2202 5.5 17.5 5.22018 17.5 4.875C17.5 4.52982 17.2202 4.25 16.875 4.25Z"
                        fill="var(--primary-text-color, #A19686)"
                      />
                      <path
                        d="M7.5 8.625V13.625C7.5 13.9702 7.77982 14.25 8.125 14.25C8.47018 14.25 8.75 13.9702 8.75 13.625V8.625C8.75 8.27982 8.47018 8 8.125 8C7.77982 8 7.5 8.27982 7.5 8.625Z"
                        fill="var(--primary-text-color, #A19686)"
                      />
                      <path
                        d="M11.25 8.625V13.625C11.25 13.9702 11.5298 14.25 11.875 14.25C12.2202 14.25 12.5 13.9702 12.5 13.625V8.625C12.5 8.27982 12.2202 8 11.875 8C11.5298 8 11.25 8.27982 11.25 8.625Z"
                        fill="var(--primary-text-color, #A19686)"
                      />
                      <path
                        d="M5 16.75V4.875C5 4.52982 4.72018 4.25 4.375 4.25C4.02982 4.25 3.75 4.52982 3.75 4.875V16.75C3.75 17.2678 4.11612 17.6339 4.11612 17.6339C4.48223 18 5 18 5 18H15C15.5178 18 15.8839 17.6339 15.8839 17.6339C16.25 17.2678 16.25 16.75 16.25 16.75V4.875C16.25 4.52982 15.9702 4.25 15.625 4.25C15.2798 4.25 15 4.52982 15 4.875V16.75H5Z"
                        fill="var(--primary-text-color, #A19686)"
                      />
                      <path
                        d="M6.79918 2.29917C6.25 2.84835 6.25 3.625 6.25 3.625V4.875C6.25 5.22018 6.52982 5.5 6.875 5.5C7.22018 5.5 7.5 5.22018 7.5 4.875V3.625C7.5 3.36612 7.68306 3.18306 7.68306 3.18306C7.86612 3 8.125 3 8.125 3H11.875C12.1339 3 12.3169 3.18306 12.3169 3.18306C12.5 3.36612 12.5 3.625 12.5 3.625V4.875C12.5 5.22018 12.7798 5.5 13.125 5.5C13.4702 5.5 13.75 5.22018 13.75 4.875V3.625C13.75 2.84835 13.2008 2.29917 13.2008 2.29917C12.6517 1.75 11.875 1.75 11.875 1.75H8.125C7.34835 1.75 6.79918 2.29917 6.79918 2.29917Z"
                        fill="var(--primary-text-color, #A19686)"
                      />
                    </svg>
                  </span>
                  Delete
                </button>

                <button
                  style={{
                    color: `var(--secondary-text-color,#FFF)`,
                    background: `var(--secondary-color,#A19686)`,
                  }}
                  className="invite-button"
                  onClick={openInviteModal}
                >
                  <span className="plus-icon" style={{ fontSize: "12px" }}>
                    <FaPlus />
                  </span>
                  Invite Athelete
                </button>
              </div>
            </div>
          )}
          {show && (
            <MonthlyCalender
              SelectedData={SelectedData}
              id={getAtheleteId}
              tab={isActiveTab}
              groupList={getAtheleteGroup}
              isOpenCalender={setShow}
              dropDownOption={getDropDownData}
            />
            // <AtheleteCalender
            //   isActiveTab={isActiveTab}
            //   getAtheleteInvited={getAtheleteInvited}
            //   getAtheleteId={getAtheleteId}
            //   getAtheleteGroup={getAtheleteGroup}
            //   setShow1={setShow}
            // />
          )}

          {!show && (
            <AtheleteTable
              setSelectedData={setSelectedData}
              refresh={refresh}
              setSelectedAthelete={setSelectedAthelete}
              isShowDeleteCheckBox={isShowDeleteCheckBox}
              selectedAthelete={selectedAthelete}
              isEmptyDataForJoined={isEmptyDataForJoined}
              isActiveTab={isActiveTab}
              setactiveTab={setActiveTab}
              getAtheleteInvited={getAtheleteInvited}
              setAtheleteInvited={setAtheleteInvited}
              setrefresh={setrefresh}
              isLoader={isLoader}
              setLoader={setLoader}
              getAtheleteId={getAtheleteId}
              setAtheleteId={setAtheleteId}
              setInvitedAtheleteId={setInvitedAtheleteId}
              setShow={setShow}
              handleActivePaginationathelete={handleActivePaginationathelete}
              isPaginataionathelete={isPaginataionathelete}
              isTotalPages={isTotalPages}
              getAtheleteGroup={getAtheleteGroup}
            />
          )}
        </div>
        {isInviteModalOpen && (
          <CoachAtheleteModal
            isOpen={isInviteModalOpen}
            onClose={closeInviteModal}
            getTotalCount= {userDetails}
            isGrowthPlan={isGrowthPlan}
          />
        )}
        {isConnectAccountModalOpen && (
          <ConnectAccountModal
            isOpen={isConnectAccountModalOpen}
            onClose={closeConnectAccountModal}
          />
        )}
      </div>
    </Container>
  );
};

export default Athelete;
