import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import styles from './reusableUi.module.css';

const Input = ({ name, type, value, onChange, placeholder, className, ...props }) => {
  if (type === 'date') {
    return (
      <div className={`${styles.inputWrapper} ${className || ''} w-100`}>
        <DatePicker
          selected={value}
          onChange={(date) => onChange({ target: { value: date, type: 'date' } })}
          placeholderText={placeholder}
          dateFormat="yyyy/MM/dd"
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          wrapperClassName={`${styles?.datepicker}`}
          className={`${styles.input} ${styles.datePicker}`}
          {...props}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.inputWrapper} ${className || ''}`}>
      <input
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`${styles.input}`}
        {...props}
      />
    </div>
  );
};

export default Input;
