// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerSign{
    display: flex;
    padding: 4rem 0;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--white-100, #fff);
}
.centered-form{
  width: 35%;
  margin: 0 auto;
}
.logInfo{
  color: var(--black-100, #1C1C1C);
font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
/* 18 Regular */
margin-top: 2rem;
font-family:Hurme Geometric Sans 2;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;
}
[data-theme='dark'] .container {
    background-color: #1C1C1C;
    /* Add other dark theme styles here */
  }

@media (max-width: 600px) {
    img {
        height: 40.06px;
      width: auto; /* Allow the width to adjust proportionally */
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Signup/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kCAAkC;AACtC;AACA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,gCAAgC;AAClC,sDAAsD;AACtD,eAAe;AACf,gBAAgB;AAChB,kCAAkC;AAClC,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB;AACA;IACI,yBAAyB;IACzB,qCAAqC;EACvC;;AAEF;IACI;QACI,eAAe;MACjB,WAAW,EAAE,6CAA6C;IAC5D;EACF","sourcesContent":[".containerSign{\r\n    display: flex;\r\n    padding: 4rem 0;\r\n    width: 100%;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background: var(--white-100, #fff);\r\n}\r\n.centered-form{\r\n  width: 35%;\r\n  margin: 0 auto;\r\n}\r\n.logInfo{\r\n  color: var(--black-100, #1C1C1C);\r\nfont-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;\r\n/* 18 Regular */\r\nmargin-top: 2rem;\r\nfont-family:Hurme Geometric Sans 2;\r\nfont-size: 18px;\r\nfont-style: normal;\r\nfont-weight: 400;\r\nline-height: 24px;\r\n}\r\n[data-theme='dark'] .container {\r\n    background-color: #1C1C1C;\r\n    /* Add other dark theme styles here */\r\n  }\r\n\r\n@media (max-width: 600px) {\r\n    img {\r\n        height: 40.06px;\r\n      width: auto; /* Allow the width to adjust proportionally */\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
