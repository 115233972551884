import React from 'react'
import SvgIcon from '../../../../assessts/Svg/svgIcons'
import Form from "react-bootstrap/Form";
import { Button } from 'react-bootstrap';

const Blocks = (props) => {
  const { exerciseSections,
    deleteExerciseSection,
    openLeaderModal,
    formData,
    handleSessionInputChange,
    handleExerciseSelectionChange,
    getExercises,
    sets,
    handleInputChange,
    removeLastSet,
    addSet
  } = props;
  return (
    <>
      {exerciseSections.map((section, i) => {
        return (
          <React.Fragment key={`exerciseSection_${i}`}>
            <Form.Group className="mt-4 mb-3 text d-flex flex-column  align-items-end">
              <div onClick={() => deleteExerciseSection(i)} className="deleteIcon">
                <SvgIcon iconName="deleteIcon" size={25} />
              </div>
              <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: "100%", marginTop: '1rem' }}>
                <div key={i} className="exerciseNumbers">{String.fromCharCode(65 + i)}</div>
                <Button className="customButton" onClick={openLeaderModal}>LeaderBoard</Button></div>
            </Form.Group>
            <Form.Group key={i} className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
              <div className="exerCat">
                <Form.Label>Exercise Category</Form.Label>
                <Form.Select
                  name={`exerciseCategory${i}`}
                  value={formData[`exerciseCategory${i}`]}
                  onChange={handleSessionInputChange}
                >
                  <option>Power</option>
                  <option>Power</option>
                  <option>Power</option>
                </Form.Select>
              </div>
              <div className="exerAdd">
                <Form.Select
                  name="selectedExercise"
                  value={section.selectedExercise}
                  onChange={(e) => handleExerciseSelectionChange(i, e.target.value)}
                >
                  {getExercises.map((exerciseList, index) => (
                    <option key={index} value={exerciseList.title}>{exerciseList.title}</option>
                  ))}
                </Form.Select>
              </div>
            </Form.Group>

            <Form.Group controlId="formBasic" className="mt-4">
              <Form.Label>Instructions</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name={`instructions${i}`}
                // value={formData[`instructions${i}`]}
                value={section.instructions || ""}
                onChange={(e) => handleSessionInputChange(i, e)}
                placeholder="use this area for session"
              />
            </Form.Group>
            <Form.Group controlId="formBasic" className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
              <div className="videpurl mb-3">
                <Form.Label>Video URL</Form.Label>
                <Form.Control
                  style={{ background: "var(--neutral-color-grey-natural, #FAFAFA)" }}
                  type="text"
                  name={`videoURL${i}`}
                  // value={formData[`videoURL${i}`]}
                  value={section.URL || ""}
                  onChange={(e) => handleSessionInputChange(i, e)}
                  placeholder="www.youtube.com"
                />
              </div>
              <div key={i} className="exerciseSets">
                <div className="exerciseSetsHeader">
                  <h5
                    style={{
                      fontSize: "19px",
                      color: " var(--light-mode-secondary-232-d-42-text-1, #232D42)",
                    }}
                  >
                    Sets
                  </h5>
                  <div className="exerType">
                    <h5>Reps </h5>
                    <SvgIcon iconName="dropDown" size={14} />
                  </div>
                  <div className="exerType">
                    <h5>Wegith </h5>
                    <SvgIcon iconName="dropDown" size={14} />
                  </div>
                </div>
                <div >
                  {section?.sets?.map((set, setIndex) => (
                    <div className="exerBodyx" key={setIndex}>

                      <div className="frstDv">
                        <input
                          type="text"
                          name="sets"
                          onChange={(e) => handleInputChange(i, setIndex, e)}
                        />
                      </div>
                      <div className="divchek">
                        <input
                          type="text"
                          name="reps"
                          value={set.reps}
                          onChange={(e) => handleInputChange(i, setIndex, e)}
                        />
                      </div>
                      <div className="divchek2">
                        <input
                          type="text"
                          name="weight"
                          value={set.weight}
                          onChange={(e) => handleInputChange(i, setIndex, e)}
                        />
                      </div>

                    </div>
                  ))}

                </div>
                <div className="iconicBtns">
                  {sets.length > 0 && <div onClick={removeLastSet} className="plusMinus">
                    <SvgIcon iconName="minusButton" size={16} />
                  </div>}
                  <div onClick={() => addSet(i)} className="pluscircle">
                    <SvgIcon iconName="plusButton" size={16} />
                  </div>
                </div>
              </div>
            </Form.Group>
          </React.Fragment>

        )

      })}
    </>
  )
}

export default Blocks