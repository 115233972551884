import { useEffect } from "react";

const useThemeColors = (customLightColors) => {
  console.log(customLightColors)
  const applyTheme = (colors) => {
    if (colors) {
      document.documentElement.style.setProperty(
        "--primary-color",
        colors.primaryColor
      );
      document.documentElement.style.setProperty(
        "--primary-text-color",
        colors.primaryTextColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        colors.secondaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-text-color",
        colors.secondaryTextColor
      );
      
      // Store the colors in localStorage
      localStorage.setItem("themeColors", JSON.stringify(colors));
    }
  };

  useEffect(() => {
    // Check if there are stored theme colors in localStorage
    const storedColors = JSON.parse(localStorage.getItem("themeColors"));

    // Apply the stored colors or the provided customLightColors
    if (storedColors) {
      applyTheme(storedColors);
    } else {
      applyTheme(customLightColors);
    }
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    // Apply the new theme colors whenever customLightColors changes
    if (customLightColors) {
      applyTheme(customLightColors);
    }
  }, [customLightColors]);

  return { customLightColors };
};

export default useThemeColors;





