import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Program from "../../../../../assessts/images/indexCard.svg";
import styles from "../../index.module.css";
import { SessionContext } from "../../../../../context/sessionContext";
import { Col } from "react-bootstrap";
import { auth } from "../../../../../firebase";
import axios from "axios";
import { GetProgramme } from "../../../../../services/store/atheleteApi";
import Loader from "../../../../ReusableUi/Loader";
import SvgIcons from "../../../../ReusableUi/SvgIcon";

const Card = styled.div`
  border-radius: 12px;
  border-bottom: 1px solid var(--secondary-purple-b, #eae6e0);
  background: var(--white-100, #fff);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  width: 385px;
  height: 100.315px;
  flex-shrink: 0;
  margin-right: 38px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const CardBody = styled.div`
  padding: 19px 15px;
  display: flex;
`;

const CardImage = styled.div`
  width: 59px;
  height: 63px;
  flex-shrink: 0;
  border-radius: 9px;
  padding-right: 10px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 9px;
  }
`;

const programs = [
  {
    title: "Power clean + 1hang clean +1 Thruster",
    groupName: "Maki",
    level: "Intermediate",
  },
  {
    title: "Power clean + 1hang clean +1 Thruster",
    groupName: "Maki",
    level: "Intermediate",
  },
  {
    title: "Power clean + 1hang clean +1 Thruster",
    groupName: "Maki",
    level: "Intermediate",
  },
];

const ProgramIndex = () => {
  const { handleProgramDetails,setProgrammeDetail } = useContext(SessionContext);
  const [Progammes, setProgammes] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [IsEmptyData, setIsEmptyData] = useState(false);
  const [authReady, setAuthReady] = useState(false);
  const fetchProgramme = async () => {
    try {
      setisLoading(true);
      const token = await auth.currentUser.getIdToken(true);
      const endpoint = GetProgramme;
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${endpoint}`,
        { headers: { Authorization: token } }
      );
      {
        response?.data?.programs.length <= 0
          ? setIsEmptyData(true)
          : setIsEmptyData(false);
      }
      setProgammes(response?.data?.programs);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.error("Error fetching session data:", error);
    }
  };
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user);
    });

    return () => unregisterAuthObserver(); 
  }, []);
  useEffect(() => {
    if (authReady) {
      fetchProgramme();
    }
  }, [authReady]);
  const  handleProgammeDetails = (data)=>{
    setProgrammeDetail(data)
    handleProgramDetails()
  }
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <Loader />
        </div>
      ) : IsEmptyData ? (
        <div
          className="w-full d-flex flex-column justify-content-center align-items-center"
          style={{ height: "20vw" }}
        >
          <SvgIcons iconName="noSessionPlane" />
          <div className="w-100 d-flex flex-column justify-content-center">
            <h4 className="noSessionHeading d-flex justify-content-center">
              No Progammes
            </h4>
            <div className="d-flex justify-content-center">
              {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
            </div>
          </div>
        </div>
      ) : (
        programs?.map((program, index) => (
          <Col key={index} md={6} lg={6} sm={12}  >
            <Card onClick={() => handleProgammeDetails(program)}  >
              <CardBody >
                <CardImage>
                  <img src={Program} alt="Program" />
                </CardImage>
                <div>
                  <div className={styles.programTitle}>{program.title}</div>
                  <div className="d-flex justify-content-between">
                    <span className={styles.groupName}>
                      {program.groupName}
                    </span>
                    <span className={styles.groupLevel}>{program.level}</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))
      )}
    </>
  );
};

export default ProgramIndex;
