import React, { useEffect, useRef, useState } from 'react'
import style from './feedback.module.css'
import { BackArrow, NextArrow, RatingStar, User } from '../../../../../../assessts/LandingPage'
import { Carousel } from 'react-bootstrap'

const FeedBack = () => {

    const cardData = [
        {
            src: User, name: 'Briana Patton', post: 'Manager', para: 'Sed mattis est eget penatibus mauris, sed condimentum vitae viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget nisl pellentesque non, porttitor. Vitae et vestibulum ac id. Dui aliquet porttitor libero consequat volutpat eget sed turpis. Feugiat maecenas commodo et morbi morbi gravida.', rating: RatingStar
        },
        {
            src: User, name: 'Ali Hassan', post: 'Manager', para: 'Sed mattis est eget penatibus mauris, sed condimentum vitae viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget nisl pellentesque non, porttitor. Vitae et vestibulum ac id. Dui aliquet porttitor libero consequat volutpat eget sed turpis. Feugiat maecenas commodo et morbi morbi gravida.', rating: RatingStar
        },
        {
            src: User, name: 'Asif Sandhu', post: 'Manager', para: 'Sed mattis est eget penatibus mauris, sed condimentum vitae viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget nisl pellentesque non, porttitor. Vitae et vestibulum ac id. Dui aliquet porttitor libero consequat volutpat eget sed turpis. Feugiat maecenas commodo et morbi morbi gravida.', rating: RatingStar
        },
        {
            src: User, name: 'Ali Tayyab', post: 'Manager', para: 'Sed mattis est eget penatibus mauris, sed condimentum vitae viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget nisl pellentesque non, porttitor. Vitae et vestibulum ac id. Dui aliquet porttitor libero consequat volutpat eget sed turpis. Feugiat maecenas commodo et morbi morbi gravida.', rating: RatingStar
        },
        {
            src: User, name: 'Asif Sandhu', post: 'Manager', para: 'Sed mattis est eget penatibus mauris, sed condimentum vitae viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget nisl pellentesque non, porttitor. Vitae et vestibulum ac id. Dui aliquet porttitor libero consequat volutpat eget sed turpis. Feugiat maecenas commodo et morbi morbi gravida.', rating: RatingStar
        },
        {
            src: User, name: 'Sabeeh ur Rehman', post: 'Manager', para: 'Sed mattis est eget penatibus mauris, sed condimentum vitae viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget nisl pellentesque non, porttitor. Vitae et vestibulum ac id. Dui aliquet porttitor libero consequat volutpat eget sed turpis. Feugiat maecenas commodo et morbi morbi gravida.', rating: RatingStar
        },
        {
            src: User, name: 'Faizan Ahmed', post: 'Manager', para: 'Sed mattis est eget penatibus mauris, sed condimentum vitae viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget nisl pellentesque non, porttitor. Vitae et vestibulum ac id. Dui aliquet porttitor libero consequat volutpat eget sed turpis. Feugiat maecenas commodo et morbi morbi gravida.', rating: RatingStar
        },
        {
            src: User, name: 'Ali Hamza', post: 'Manager', para: 'Sed mattis est eget penatibus mauris, sed condimentum vitae viverra. Ipsum ut aliquet et morbi ac in. Lacinia mattis eget nisl pellentesque non, porttitor. Vitae et vestibulum ac id. Dui aliquet porttitor libero consequat volutpat eget sed turpis. Feugiat maecenas commodo et morbi morbi gravida.', rating: RatingStar
        },
    ]

    const [cardsToShow, setCardsToShow] = useState("3");

    useEffect(() => {
        const responsiveCard = () => {
            if (window.innerWidth >= 1024) {
                setCardsToShow("3")
            } else if (window.innerWidth >= 768) {
                setCardsToShow("2")
            } else if (window.innerWidth >= 300) {
                setCardsToShow("1")
            }
        }

        responsiveCard();
        window.addEventListener('resize', responsiveCard); // when screen size gets changed it is triggered

        return () => window.removeEventListener('resize', responsiveCard);// purpose of this is to clean the eventListener untill the next screen is triggered
    }, [])
    // 
    const reduceCards = (acc, cur, index) => {
        const groupIndex = Math.floor(index / cardsToShow);
        if (!acc[groupIndex]) // checks if there’s already an array at the position groupIndex in the acc array  - checks if groupIndex is undefined, means no group has been created at that specific groupIndex
            // then the below line will assign an empty arrray at that specific index
            acc[groupIndex] = []; //This ' [] ' array will hold the cards that belong to the current groupIndex. It ensures that before adding cards to the group at groupIndex, a valid array exists to hold them. This line ensures that each group has an array to hold cards before trying to add cards to it.
        // If no array exists at acc[groupIndex], the check creates an empty array at that position
        acc[groupIndex].push(cur); //is responsible for adding the current card (cur) to the appropriate group (represented by groupIndex) jiski limit cardsToShow state me define hai, 1 ya 2 ya phir 3
        return acc;
    };

    const [index, setIndex] = useState(0);
    const corousalRef = useRef(null);
    const handleSlideLeft = () => {
        corousalRef.current.prev();
    };
    const handleSlideRight = () => {
        corousalRef.current.next();
    };
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };


    return (
        <div className={`${style.main}`}>
            <div>
                <div>
                    <h1>Thetrainingapp Loved by The Users</h1>
                    <div>
                        <button onClick={handleSlideLeft}><img src={BackArrow} /></button>
                        <button onClick={handleSlideRight}><img src={NextArrow} /></button>
                    </div>
                </div>
                {/* card */}
                <div className={`${style.aboveMap} wrapper`}>
                    <Carousel interval={null} onSelect={handleSelect} ref={corousalRef} >
                        {cardData.reduce(reduceCards, []).map((data, index) => (
                            //.reduce() takes a function (reduceCards) and an initial value (an empty array [] in this case).The reduceCards function, which you provided earlier, groups the cards into subarrays, where each subarray contains up to 3 cards.
                            //data: This is the current group of cards (an array of up to 3 card objects)
                            <Carousel.Item key={index} >
                                <div className={`${style.rowWise} d-flex`}>

                                    {data.map((dataa, index) => {
                                        return (
                                            <div key={index} className={`${style.card}`}>
                                                <div className='d-flex'>
                                                    <img src={dataa.src} alt="" />
                                                    <div>
                                                        <p>{dataa.name}</p>
                                                        <p>{dataa.post}</p>

                                                    </div>
                                                </div>
                                                <div className={`${style.last}`}>
                                                    <p>{dataa.para}</p>
                                                </div>
                                                <div className={`${style.Rating} d-flex`}>
                                                    {/* {[...Array(dataa.rating)].map((_, index)=>(
                                                        // this above code creates an array depending on the value of rating, _ ye islye use kya k hame array ka data nai chahiye, map array pr iterate krega mtlb check krega k array me kitni values hai to wo values index me agyi or jitni bhi values hongi utne daga img tag create hota jayega
                                                        <img key={index} src={RatingStar} alt="ratingStar" />
                                                    ))} */}
                                                    <img src={dataa.rating} alt="" />
                                                    <img src={dataa.rating} alt="" />
                                                    <img src={dataa.rating} alt="" />
                                                    <img src={dataa.rating} alt="" />
                                                    <img src={dataa.rating} alt="" />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    {/* <Carousel>
                    {cardData.map((data, index) => (
                        <Carousel.Item key={index}>

                        <div className={`${style.card}`}>
                            <div>
                                <img src={data.src} alt="" />
                                <div>
                                    <p>{data.name}</p>
                                    <p>{data.post}</p>

                                </div>
                            </div>
                            <div className={`${style.last}`}>
                                <p>{data.para}</p>
                            </div>
                            <div> 
                                <img src={data.rating} alt="" />
                                <img src={data.rating} alt="" />
                                <img src={data.rating} alt="" />
                                <img src={data.rating} alt="" />
                                <img src={data.rating} alt="" />
                            </div>
                        </div>

                        </Carousel.Item>
                    ))}
                    </Carousel> */}
                </div>
            </div>
        </div>
    )
}

export default FeedBack
