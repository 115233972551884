import { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../firebase'; 

const useCheckCoachSub = (checkCoachSubscription) => {
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSubscription = async () => {
; 

    try {
      setLoading(true); 
      setError(null)
      const token = await getToken(); 

      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${checkCoachSubscription}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response?.data) {
        setSubscriptionPlan(response.data); 
      } else {
        throw new Error('No subscription data received'); 
      }
      setLoading(false); 
    } catch (err) {
      setError(err?.response?.data?.error || 'Something went wrong'); 
      console.error('Error fetching subscription:', err); 
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkCoachSubscription) {
      fetchSubscription();
    }
  }, [checkCoachSubscription]);

  return { subscriptionPlan, loading, error, fetchSubscription, setSubscriptionPlan, setLoading };
};

export default useCheckCoachSub;
