import React from 'react'
import { EllipseLight, Nofees } from '../../../../../../assessts/LandingPage'
import style from './third.module.css'
import Button from '../../../../Components/Button/Button'

const Third = () => {
  return (
    <div className={`${style.main} d-flex align-items-center justify-content-center`}>
      <div className={`${style.text} d-flex flex-column`}>
        <h1>NO FEES AND CHARGES</h1>
        <p>With TrainingApp, you can enjoy our platform without any financial burden. Train, track, and achieve your fitness goals without worrying about additional expenses.</p>
        <Button title={'START TRAINING NOW'} bg={true} />
      </div>
      <div className={`${style.imgDIV} d-flex flex-row`}>
        <img src={EllipseLight} alt="" />
        <div className='d-flex flex-column'>
          <img src={EllipseLight} className={`${style.ecli}`} />
          <img src={Nofees} className={`${style.mainPic}`} />
        </div>
      </div>
    </div>
  )
}

export default Third
