import React from 'react'
import { Button } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import './leaderBoard.css'
const LeaderBoardForm = ({ leaderBoardData, onLeaderBoardDataChange }) => {
  const handleLeaderBoardInputChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...leaderBoardData,
      [name]: value,
    };
    onLeaderBoardDataChange(updatedData);
  };
  return (
    <Form>
      <Form.Group className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
        <div className="scoreDiv">
          <Form.Label>Score Type</Form.Label>
          <Form.Select
            style={{ background: "var(--neutral-color-grey-natural, #FAFAFA)" }}
            name="scoreType"
            value={leaderBoardData.scoreType}
            onChange={handleLeaderBoardInputChange}
          >
            <option>reps</option>
            <option>power</option>
            <option>power</option>
          </Form.Select>
        </div>
        <div className="onBoardDiv">
          <Form.Label>Leaderboard Onboarding</Form.Label>
          <Form.Select
            style={{ background: "var(--neutral-color-grey-natural, #FAFAFA)" }}
            name="leaderBoardOnboarding"
            value={leaderBoardData.leaderBoardOnboarding}
            onChange={handleLeaderBoardInputChange}
          >
            <option>More is better</option>
            <option>Bench Press</option>
            <option>Bench Press</option>
          </Form.Select>
        </div>
      </Form.Group>
      <h4 className='intervals'>Multiple intervals</h4>
      <Form.Group className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
        <div className="scoreDiv">
          <Form.Label>Set the number of scores intervals</Form.Label>
          <Form.Select
            style={{ background: "var(--neutral-color-grey-natural, #FAFAFA)" }}
            name="numIntervals"
            value={leaderBoardData.numIntervals}
            onChange={handleLeaderBoardInputChange}
          >
            <option>4</option>
            <option>power</option>
            <option>power</option>
          </Form.Select>
        </div>
        <div className="onBoardDiv">
          <Form.Label>Leaderboard calculations</Form.Label>
          <Form.Select
            style={{ background: "var(--neutral-color-grey-natural, #FAFAFA)" }}
            name="leaderboardCalculations"
            value={leaderBoardData.leaderboardCalculations}
            onChange={handleLeaderBoardInputChange}
          >
            <option>More is better</option>
            <option>Bench Press</option>
            <option>Bench Press</option>
          </Form.Select>
        </div>
      </Form.Group>
      <div className='multCon'>
        <div className='leaderPlus'><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
          <path d="M2.97407 9.42596L7.07254 9.42496L7.0727 13.5223C7.07158 14.3092 7.71109 14.9488 8.49806 14.9476C9.28848 14.95 9.92449 14.3117 9.92446 13.5235L9.92545 9.42507L14.0262 9.42638C14.8143 9.42641 15.4504 8.7858 15.4526 7.99998C15.4514 7.21301 14.8119 6.5735 14.0261 6.57347L9.92419 6.57331L9.92519 2.48176C9.92516 1.69363 9.28565 1.05412 8.49983 1.0564C8.10462 1.05523 7.74974 1.21422 7.49165 1.47231C7.23241 1.73155 7.07341 2.08643 7.07343 2.48049L7.07359 6.5732L2.97281 6.5742C2.57875 6.57418 2.22387 6.73318 1.96578 6.99126C1.70769 7.24935 1.5487 7.60654 1.54756 7.99945C1.54759 8.78757 2.18595 9.42593 2.97407 9.42596Z" fill="#1AA053" />
        </svg></div>
        <h6 className='multple'>Multiple invervals</h6>
      </div>

      <div className="leaderButtons">
        <Button className="customButton2">cancel</Button>
        <Button className="customButton3" type="submit">
          Link Leaderboard
        </Button>
      </div>
    </Form>
  )
}

export default LeaderBoardForm
