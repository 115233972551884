import React from 'react'
import style from './last.module.css'
import Button from '../../../Components/Button/Button'

const Last = () => {
    return (
        <div className={`${style.main}`}>
            <div className={`${style.content} d-flex flex-column text-center align-items-center justify-content-center`}>
                <p>DISCOVER YOUR POTENTIAL</p>
                <h1>WHITE LABEL CUSTOMISATION</h1>
                <div className={`${style.btnDIV} d-flex`}>
                    <Button title={'Start your FREE trial'} bg={true}/>
                    <Button title={'View Pricing'} bg={false}/>
                </div>
            </div>
        </div>
    )
}

export default Last
