import React from 'react'
import style from './button.module.css'

const Button = ({ title, bg }) => {
    if (bg === true) {
        return <button className={`${style.btn}`} >{title}</button>
    } if (bg === false) {
        return <button className={`${style.btn2}`} >{title}</button>
    } if (bg === 'black') {
        return <button className={`${style.btn} ${style.blackBtn}`}>{title}</button>
    }
    else {
        return <button className={`${style.btn}`} style={{ backgroundColor: 'transparent', color: '#A19686' }}>{title}</button>
    }
}

export default Button
