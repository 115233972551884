import React from "react";

import FeedSection from "./FeedSection/feedSection";
const ManageFeed = () => {
  return (
    <>
      <div style={{ height: "auto" }} className="athltContainer">
        <FeedSection />
      </div>
    </>
  )
}

export default ManageFeed