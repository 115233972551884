import React from 'react'
import style from './one.module.css'
import Button from '../../../../Components/Button/Button'

const One = () => {
  return (
    <div className={`${style.main} d-flex flex-column bg-dark`}>
      <div className={`${style.upper} d-flex text-center flex-column justify-content-center align-items-center text-white`}>
        <p className="">ALL IN ONE SOLUTION</p>
        <h1 className={`${style.mainH1} `}>ENTER A NEW ERA OF GYM MANAGEMENT</h1>
        <div className={`${style.btn}`}>
        <Button title={"Start my free trial"} bg={true} />
        <Button title={"View Pricing"} bg={false} />
        </div>
      </div>
        <div className={`${style.TEXT3} d-flex justify-content-center  `}>
            <div>
                <h1>01 <span>Simplify Payment Control</span> </h1>
                <p>You can effortlessly accept weekly, monthly, or customised payment schedules. Provide your members with a convenient and seamless payment experience, ensuring their satisfaction and loyalty.</p>
            </div>
            <div>
                <h1>02 <span>Instant and Automatic Programming</span> </h1>
                <p>TrainingApp allows coaches to make instant adjustments to programming, ensuring that your members always receive up-to-date and personalised training plans. Stay ahead of the curve and provide seamless and ongoing support for your members.</p>
            </div>
            <div>
                <h1>03 <span>Log, Track and Guide Success</span> </h1>
                <p>TrainingApp provides you with fully customizable scoring systems, ensuring that you can capture and analyse the metrics that matter most to you and your clients. Elevate your coaching game with precise data-driven insights, empowering you to guide your athletes towards peak performance.</p>
            </div>
        </div>
    </div>
  )
}

export default One

