import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import Av from "../../assessts/icons/avtr.png";
import Menu from "../../assessts/icons/menu.png";
import DarkAv from "../../assessts/icons/darkAv.png";
import './styles.css'
import { ThemeContext } from '../../context/themeContext';
import { auth } from '../../firebase';
import { AuthContext } from '../../context/authContext';
import UseCheckRole from '../../hooks/UseCheckRole';


const ZoomableImage = styled.img`
  width: 40px;
  height: 40px;
  transition: transform 0.2s ease; /* Add a smooth transition effect */
cursor:pointer;
  &:hover {
    transform: scale(1.2); /* Scale up the image on hover */
  }
`;
const NavBar = () => {
  const {role, userName} = UseCheckRole()
  const { theme, toggleTheme, setHide, hide } = useContext(ThemeContext);
  
  

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  }
 
 
  
  return (
    <>
      <div className='my-navbar'>
        <div className='displayName' >
          <h4 >Welcome to  {userName &&  userName || "Athelete"}</h4>
          <h4>/</h4>
          <h4 id="userType">{role &&  role}</h4>

        </div>
        <div className='menu' style={{ display: "none" }}>
          <img onClick={() => setHide(!hide)} src={Menu} alt="" />
        </div>
        {/* <CheckoutForm/> */}
        <div className='icons' style={{alignItems: "center", height: "35px"}}>
          <div onClick={toggleTheme} >
            <svg width="25" height="25" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6875 14.5C18.6875 17.0888 16.5888 19.1875 14 19.1875C11.4112 19.1875 9.3125 17.0888 9.3125 14.5C9.3125 11.9112 11.4112 9.8125 14 9.8125C16.5888 9.8125 18.6875 11.9112 18.6875 14.5Z" fill="#E5EFFF" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14 9.1875C14 9.1875 15.0804 9.1875 16.0681 9.60524C16.0681 9.60524 17.0215 10.0085 17.7565 10.7435C17.7565 10.7435 18.4915 11.4785 18.8948 12.4319C18.8948 12.4319 19.3125 13.4196 19.3125 14.5C19.3125 14.5 19.3125 15.5804 18.8948 16.5681C18.8948 16.5681 18.4915 17.5215 17.7565 18.2565C17.7565 18.2565 17.0215 18.9915 16.0681 19.3948C16.0681 19.3948 15.0804 19.8125 14 19.8125C14 19.8125 12.9196 19.8125 11.9319 19.3948C11.9319 19.3948 10.9785 18.9915 10.2435 18.2565C10.2435 18.2565 9.50853 17.5215 9.10524 16.5681C9.10524 16.5681 8.6875 15.5804 8.6875 14.5C8.6875 14.5 8.6875 13.4196 9.10524 12.4319C9.10524 12.4319 9.50853 11.4785 10.2435 10.7435C10.2435 10.7435 10.9785 10.0085 11.9319 9.60524C11.9319 9.60524 12.9196 9.1875 14 9.1875ZM14 10.4375C14 10.4375 12.3173 10.4375 11.1274 11.6274C11.1274 11.6274 9.9375 12.8173 9.9375 14.5C9.9375 14.5 9.9375 16.1827 11.1274 17.3726C11.1274 17.3726 12.3173 18.5625 14 18.5625C14 18.5625 15.6827 18.5625 16.8726 17.3726C16.8726 17.3726 18.0625 16.1827 18.0625 14.5C18.0625 14.5 18.0625 12.8173 16.8726 11.6274C16.8726 11.6274 15.6827 10.4375 14 10.4375Z" fill="#4B52FF" />
              <path d="M14.625 7.3125V5.75C14.625 5.40482 14.3452 5.125 14 5.125C13.6548 5.125 13.375 5.40482 13.375 5.75V7.3125C13.375 7.65768 13.6548 7.9375 14 7.9375C14.3452 7.9375 14.625 7.65768 14.625 7.3125Z" fill="#4B52FF" />
              <path d="M8.25444 7.87056C8.13723 7.75335 7.97826 7.6875 7.8125 7.6875C7.64674 7.6875 7.48777 7.75335 7.37056 7.87056C7.25335 7.98777 7.1875 8.14674 7.1875 8.3125C7.1875 8.47826 7.25335 8.63723 7.37056 8.75444L8.47212 9.856C8.58933 9.97321 8.7483 10.0391 8.91406 10.0391C9.07982 10.0391 9.23879 9.97321 9.356 9.856C9.47321 9.73879 9.53906 9.57982 9.53906 9.41406C9.53906 9.2483 9.47321 9.08933 9.356 8.97212L8.25444 7.87056Z" fill="#4B52FF" />
              <path d="M6.8125 13.875H5.25C4.90482 13.875 4.625 14.1548 4.625 14.5C4.625 14.8452 4.90482 15.125 5.25 15.125H6.8125C7.15768 15.125 7.4375 14.8452 7.4375 14.5C7.4375 14.1548 7.15768 13.875 6.8125 13.875Z" fill="#4B52FF" />
              <path d="M7.37056 20.2456C7.25335 20.3628 7.1875 20.5217 7.1875 20.6875C7.1875 20.6983 7.18778 20.7091 7.18834 20.7198C7.19634 20.8742 7.26126 21.0201 7.37056 21.1294C7.48777 21.2467 7.64674 21.3125 7.8125 21.3125C7.97826 21.3125 8.13723 21.2467 8.25444 21.1294L9.356 20.0279C9.47321 19.9107 9.53906 19.7517 9.53906 19.5859C9.53906 19.4202 9.47321 19.2612 9.356 19.144C9.23879 19.0268 9.07982 18.9609 8.91406 18.9609C8.90328 18.9609 8.89249 18.9612 8.88172 18.9618C8.72735 18.9698 8.58142 19.0347 8.47212 19.144L7.37056 20.2456Z" fill="#4B52FF" />
              <path d="M13.375 21.6875V23.25C13.375 23.5952 13.6548 23.875 14 23.875C14.3452 23.875 14.625 23.5952 14.625 23.25V21.6875C14.625 21.3423 14.3452 21.0625 14 21.0625C13.6548 21.0625 13.375 21.3423 13.375 21.6875Z" fill="#4B52FF" />
              <path d="M18.6441 20.028L19.7456 21.1294C19.8628 21.2467 20.0217 21.3125 20.1875 21.3125C20.3533 21.3125 20.5122 21.2467 20.6294 21.1294C20.7467 21.0122 20.8125 20.8533 20.8125 20.6875C20.8125 20.5217 20.7467 20.3628 20.6294 20.2456L19.528 19.1441C19.4107 19.0268 19.2517 18.9609 19.0859 18.9609C18.9202 18.9609 18.7612 19.0268 18.644 19.144C18.5268 19.2612 18.4609 19.4202 18.4609 19.5859C18.4609 19.7517 18.5268 19.9107 18.6441 20.028Z" fill="#4B52FF" />
              <path d="M21.1875 15.125H22.75C23.0952 15.125 23.375 14.8452 23.375 14.5C23.375 14.1548 23.0952 13.875 22.75 13.875H21.1875C20.8423 13.875 20.5625 14.1548 20.5625 14.5C20.5625 14.8452 20.8423 15.125 21.1875 15.125Z" fill="#4B52FF" />
              <path d="M20.6294 8.75444C20.7467 8.63723 20.8125 8.47826 20.8125 8.3125C20.8125 8.14674 20.7467 7.98777 20.6294 7.87056C20.5122 7.75335 20.3533 7.6875 20.1875 7.6875C20.0217 7.6875 19.8628 7.75335 19.7456 7.87056L18.6441 8.97205C18.5268 9.08933 18.4609 9.2483 18.4609 9.41406C18.4609 9.57982 18.5268 9.73879 18.644 9.856C18.7612 9.97321 18.9202 10.0391 19.0859 10.0391C19.2517 10.0391 19.4107 9.97321 19.5279 9.856L20.6294 8.75444Z" fill="#4B52FF" />
            </svg>
          </div>

          {/* <ZoomableImage height=" 24" width="24px" src={theme === 'light' ? B2 : DarkSwitch} /> */}
          <div>
            <svg width="25" height="25" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.39038 8.62508C4.38931 7.88415 4.53488 7.15028 4.8187 6.46584C5.10251 5.78137 5.51892 5.15984 6.04382 4.637C6.56888 4.11416 7.1922 3.70034 7.87778 3.41939C8.56351 3.13847 9.29791 2.99596 10.0389 3.00008C13.1326 3.02354 15.6091 5.59384 15.6091 8.69538V9.25008C15.6091 12.047 16.195 13.672 16.7106 14.5626C16.7654 14.6574 16.7944 14.765 16.7944 14.8745C16.7946 14.9841 16.7659 15.0917 16.7112 15.1866C16.6566 15.2816 16.578 15.3605 16.4834 15.4155C16.3887 15.4706 16.2811 15.4997 16.1717 15.5001H3.82794C3.71838 15.4997 3.61081 15.4706 3.5162 15.4155C3.42145 15.3605 3.34286 15.2816 3.28824 15.1866C3.23361 15.0917 3.20493 14.9841 3.20508 14.8745C3.20523 14.765 3.23407 14.6574 3.28885 14.5626C3.80444 13.672 4.39038 12.047 4.39038 9.25008V8.62508Z" fill="#E5EFFF" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76538 9.25007V8.62507C3.76538 8.62507 3.76362 7.37864 4.24133 6.22645C4.24133 6.22645 4.71904 5.07425 5.60285 4.19411C5.60285 4.19411 6.48666 3.31397 7.64083 2.84105C7.64083 2.84105 8.79501 2.36814 10.0423 2.37508C10.0423 2.37508 11.3106 2.38468 12.4654 2.89135C12.4654 2.89135 13.5766 3.37881 14.432 4.25745C14.432 4.25745 15.284 5.13266 15.7513 6.26121C15.7513 6.26121 16.2341 7.42735 16.2341 8.69538V9.25007C16.2341 9.25007 16.2341 12.492 17.2516 14.2494C17.2516 14.2494 17.4191 14.5396 17.4194 14.874C17.4194 14.874 17.4197 15.2083 17.2531 15.4982C17.2531 15.4982 17.0864 15.788 16.7973 15.9559C16.7973 15.9559 16.5082 16.1239 16.1738 16.1251L3.82788 16.1251C3.82788 16.1251 3.49133 16.1239 3.20222 15.9559C3.20222 15.9559 2.91311 15.788 2.74645 15.4982C2.74645 15.4982 2.57978 15.2083 2.58008 14.874C2.58008 14.874 2.58038 14.5396 2.74755 14.2501C2.74755 14.2501 3.76538 12.492 3.76538 9.25007ZM5.01538 9.25007C5.01538 9.25007 5.01538 12.8277 3.83008 14.8751L16.1694 14.8751C16.1694 14.8751 14.9841 12.8272 14.9841 9.25007V8.69538C14.9841 8.69538 14.9841 6.61659 13.5363 5.1294C13.5363 5.1294 12.0869 3.6406 10.0353 3.62506C10.0353 3.62506 9.03782 3.61951 8.11477 3.99772C8.11477 3.99772 7.19172 4.37593 6.4849 5.07982C6.4849 5.07982 5.77807 5.78372 5.39602 6.70519C5.39602 6.70519 5.01397 7.62666 5.01538 8.62507V9.25007Z" fill="#4B52FF" />
              <path d="M13.125 16.125V15.5C13.125 15.1548 12.8452 14.875 12.5 14.875C12.1548 14.875 11.875 15.1548 11.875 15.5V16.125C11.875 16.9016 11.3258 17.4508 11.3258 17.4508C10.7766 18 10 18 10 18C9.22335 18 8.67417 17.4508 8.67417 17.4508C8.125 16.9016 8.125 16.125 8.125 16.125V15.5C8.125 15.1548 7.84518 14.875 7.5 14.875C7.15482 14.875 6.875 15.1548 6.875 15.5L6.875 16.125C6.875 17.4194 7.79029 18.3347 7.79029 18.3347C8.70558 19.25 10 19.25 10 19.25C11.2944 19.25 12.2097 18.3347 12.2097 18.3347C13.125 17.4194 13.125 16.125 13.125 16.125Z" fill="#4B52FF" />
            </svg>
          </div>

          <ZoomableImage id='lighticon' src={theme === 'light' ? Av : DarkAv} />
        </div>
      </div>
    </>
  )
}

export default NavBar
