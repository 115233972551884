import axios from "axios";
import React, { useState } from "react";
import { createCoachGroup, createGroup, editCoachGroup, inviteAthletesToGroup } from "../../apis";
import "./inviteModal.css";
import { toast } from "react-toastify";
import { auth } from "../../firebase";
import { FaPlus } from "react-icons/fa";
import LoadingSpinner from "../loadingSpinner";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
const InviteAthleteModal = ({ isOpen, onClose, groupIdOnClcik }) => {
  const generateRandomCode = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };
   // Initialize field groups with an initial empty field group
   const initialFieldGroup = {
    email: [],
    amount: '',
    invitationType: "group",
    
    invitedBy: auth?.currentUser?.uid,
  };

  const [fieldGroups, setFieldGroups] = useState([initialFieldGroup]);


  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    creatorEmail: auth?.currentUser?.email,
    coachID: auth.currentUser.uid,
  }
  )
   // Function to add a new field group
   const addFieldGroup = () => {
    setFieldGroups([...fieldGroups, {
      ...initialFieldGroup, // Use the same structure as the initial field group
    
    }]);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{3,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailsChange = (e, index) => {
    let input = e.target.value;
    const updatedFieldGroups = [...fieldGroups];
    // Remove any trailing space or comma
    input = input.trim().replace(/,\s*$/, '');
  
    // Split the input by commas, trim whitespace, and filter out empty strings
    let emails = input.split(',').map(email => email.trim()).filter(email => email);
  
    // Check if the input string ends with a comma
    const lastCharIsComma = input.endsWith(',');
  
    // If the last character was a comma, add an empty string to the list of emails
    if (lastCharIsComma) {
      emails = [...emails, ''];
    }
  
    updatedFieldGroups[index].email = emails;
    setFieldGroups(updatedFieldGroups);
  };
  
  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

const handleInviteAthleteToGroup = async () => {
  try {
    setIsLoading(true);
    const recipientData = fieldGroups.flatMap((group) => (
      // Check if group.email is an array and convert it to a string
      (Array.isArray(group.email) ? group.email.join(', ') : group.email)
    .split(',')
    .map(email => {
      const cd = generateRandomCode(4);
      return {
        email: email.trim(),
        amount: group.amount,
        invitationLink: `${window.location.origin}/invitation/?invitationType=${group.invitationType}&invitationCode=${cd}&price=${group.amount}&productName=program&subscriptionType=forthnightly`,
        invitedBy: group?.invitedBy,
        invitationCode: cd,
        invitationType: group.invitationType,
        groupId:groupIdOnClcik.id
      };
    })
))

    const token = await auth.currentUser.getIdToken(true);
    const endpoint = inviteAthletesToGroup;
    const payload = { ...formData, fieldGroups: recipientData };
    await makeApiCall(endpoint, payload, token);

    onClose();
    setIsLoading(false);
    toast.success("Athletes invited successfully");
  } catch (err) {
    setIsLoading(false);
    onClose();
    console.error(err);
  }
};

  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token }
    };

      // For creation (post request)
      await axios.post(url, payload, config);
  };
  return (
    <>
      <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`}>
        <div className="exercise-overlay" onClick={onClose}></div>
        <div className="exercisemmodalBody" style={{ width: "35vw",backgroundColor : `white`  }}>
          <h2 style={{ textTransform: "uppercase" ,color : `black`}}>Invite Athletes to Group</h2>
          <Container>
            <Row className='mb-3 mt-3'>
              <h4 style={{color : `black`}} >{groupIdOnClcik?.groupName}</h4>
            </Row>
            {fieldGroups.map((fieldGroup, index) => (
              <>
                <Row className='mb-3 mt-3' key={index}>
                  <Col xs={12} md={12} className='p-0'>
                    <Form.Group controlId='frmB'>
                      <Form.Label className='customInputLabel' style={{color : `black`}} > Invitation Email</Form.Label>
                      <Form.Control type="text" 
                        style={{backgroundColor : `#F9F9F9`,color : `black`}}
                       value={fieldGroup.email.join(', ') + (fieldGroup.email.length && validateEmail(fieldGroup.email[fieldGroup.email.length - 1]) ? ', ' : '')}
                        onChange={(e) => handleEmailsChange(e, index)}
 placeholder="Enter Email of Invitation" className='customInput' />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3' key={index}>
                  <Col xs={12} md={12} className='p-0'>
                    <Form.Group controlId='frmB'>
                      <Form.Label style={{color : `black`}}   className='customInputLabel'> Price</Form.Label>
                      <Form.Control type="email" value={fieldGroup.amount}
                        style={{backgroundColor : `#F9F9F9`,color : `black`}}
                        onChange={(e) => {
                          const updatedFieldGroups = [...fieldGroups];
                          updatedFieldGroups[index].amount = e.target.value;
                          setFieldGroups(updatedFieldGroups);
                        }} placeholder="Enter amount" className='customInput' />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            ))}

            <Row>
              <Col xs={12}>
                <div style={{ width: '100%', marginBottom: "0.5rem", cursor: "pointer", justifyContent: "center", textAlign: "center",color : `black` }}>
                  <FaPlus onClick={addFieldGroup} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='d-flex justify-content-end gap-4 mt-3' >
                <Button
                
                style={{backgroundColor:`var(--secondary-color,#A19686)`,color : `var(--secondary-text-color,white)`,height : "48px",width : "120px", borderRadius : "8px",border : "none",fontSize : "19px" , fontWeight: "600" , lineHeight: "24px"}}onClick={handleInviteAthleteToGroup}> {isLoading ? <LoadingSpinner size="sm" /> : "invite"}</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    </>
  );
};

export default InviteAthleteModal;
