import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import SignupLogo from '../../assessts/signuplogo.png'
import SignupLogoDark from '../../assessts/logotrnDark.png'
import SignInForm from '../../components/forms/signInForm';
import { ThemeContext } from '../../context/themeContext';
import './styles.css'
import SignUpModal from '../Auth/SignUpModal';



const SignIn = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const { theme, setLocation } = useContext(ThemeContext);
  useEffect(() => {
    setLocation(window.location.pathname)
  }, [])
  return (
    <>
      <div className='chkkk' style={{ height: "100vh" }}>
        <div className='loginForm p-5'>
          <img alt='' src={theme === 'light' ? SignupLogo : SignupLogoDark} height="71.06px" width="418px" />
          <h5 className='firstHeader text-center'>
            Please enter your login information
          </h5>
          <SignInForm />
          <h5 className="secondHeader text-center mt-3">
            You do not have an account ? <Link style={{ textDecoration: "none" }} onClick={handleShowModal}>Create an account</Link>
          </h5>
        </div>
      </div>
      {showModal && (
        <SignUpModal
          isOpen={showModal}
          onClose={handleCloseModal}
        />
      )}
      {/* <SignUpModal show={showModal} handleClose={handleCloseModal} /> */}

    </>
  )
}

export default SignIn
