import React from 'react'
import { Barbell, BodyB } from '../../../../../../assessts/LandingPage'
import style from './Benefits.module.css'

const Benefits = () => {
    const data = [
        { img: Barbell, title: "TRAIN ANYTIME, ANYWHERE", description: "Take your training on-the-go. Access anywhere and easily train around your work and life demands." },
        { img: Barbell, title: "TRAIN ANYTIME, ANYWHERE", description: "Take your training on-the-go. Access anywhere and easily train around your work and life demands." },
        { img: Barbell, title: "TRAIN ANYTIME, ANYWHERE", description: "Take your training on-the-go. Access anywhere and easily train around your work and life demands." },
        { img: Barbell, title: "TRAIN ANYTIME, ANYWHERE", description: "Take your training on-the-go. Access anywhere and easily train around your work and life demands." },
        { img: Barbell, title: "TRAIN ANYTIME, ANYWHERE", description: "Take your training on-the-go. Access anywhere and easily train around your work and life demands." },
        { img: Barbell, title: "TRAIN ANYTIME, ANYWHERE", description: "Take your training on-the-go. Access anywhere and easily train around your work and life demands." },
    ]
  return (
    <div className={`${style.main} d-flex flex-column align-items-center justify-content-center`}>
      <h1>Benefits</h1>
      <div  className={`${style.cardDIV}`}>
        {data.map((detail, key)=> (
            <div key={key} className={`${style.card} d-flex flex-column align-items-center justify-content-center text-center`}>
                <img src={detail.img} alt="" />
                <h1>{detail.title}</h1>
                <p>{detail.description}</p>
            </div>
        ))}
      </div>
    </div>
  )
}

export default Benefits
