import React, { useEffect } from "react";
import { auth } from "../../../../firebase";
import Avtr from "../../../../assessts/images/avtr.png";
import GroupSessionForm from "./CreateGroupSession/createGroupSession";

const GroupSessionModal = ({
  isOpen,
  onClose,
  selectedDate,
  groupDetails,
  SessionData,
  IsEdit,
}) => {
  useEffect(() => {
    SessionData?.groupBlocks?.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  }, [SessionData]);

  // Convert 'selectedDate' (string) into a Date object
  const selectedDateObj = new Date(selectedDate);

  // Get day, date, and month from 'selectedDateObj'
  const day = selectedDateObj.getDay(); // Returns a number (0-6) representing the day of the week
  const date = selectedDateObj.getDate(); // Returns the day of the month (1-31)
  const month = selectedDateObj.getMonth(); // Returns a number (0-11) representing the month

  // Convert day and month to human-readable format
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const readableDay = dayNames[day];
  const readableMonth = monthNames[month];

  // To Get Coach Name From Auth
  const coachName = auth.currentUser.displayName;

  return (
    <>
      <div className={`invite-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-overlay" onClick={onClose}></div>
        <div
          style={{
            overflowY: "scroll",
            height: "83vh",
            maxWidth: "80%",
            width: "80% ",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
            zIndex: "1002",
            position: "relative",
            borderRadius: "8px",
            background: "var(--primary-bg-color, #FFF)",
          }}
        >
          <h2
            id="asf"
            className="pb-2"
            style={{
              fontSize: "20px",
              fontWeight: 700,
              color: `var(--primary-text-color,black)`,
            }}
          >
            {readableDay} {readableMonth} {date}
          </h2>
          <div className="userInfo d-flex ml-4">
            <img width="36px" height="36px" src={Avtr} alt="" />
            <div className="userNameAndStatus" style={{ marginLeft: "10px" }}>
              <h6
                style={{
                  color: `var(--primary-text-color,black)`,
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "0px",
                }}
                id="as"
              >
                {coachName}
              </h6>
              <p id="asf" style={{ fontSize: "12px", color: "#4B52FF" }}>
                Unpublished
              </p>
            </div>
          </div>

          <GroupSessionForm
            IsEdit={IsEdit}
            SessionData={SessionData}
            groupDetails={groupDetails}
            selectedDate={selectedDate}
            onClose={onClose}
          />
        </div>
      </div>
    </>
  );
};

export default GroupSessionModal;
