import React from 'react'
import Navbar from './Components/Navbar/Navbar'
import Footer from './Components/Footer/Footer'
import CustomNavbar from './Components/Navbar/Navbar'

const Layout = ({ children  }) => {
  return (
    <>
     <CustomNavbar  />
    { children }
     <Footer/>
    </>
  )
}

export default Layout
