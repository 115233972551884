import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";


const Protected = ({ isLoggedIn, children }) => {
  const [currentUser, setCurrentUser] = useState("")

  useEffect(() => {
    const itemValue = localStorage.getItem('IsLogged');
    setCurrentUser(itemValue)
  })
  if (currentUser === null) {
    return <Navigate to="/login" replace />;
  } else if (currentUser === "true") {
    return children;
  }
};
export default Protected;