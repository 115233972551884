import React, { useContext, useEffect, useState } from "react";
import "./assistance.css";
import Avtr from "../../../../assessts/images/avtr.png";
import AssistantCoachModal from "../../../modals/AssistantCoachModal";
import { SessionContext } from "../../../../context/sessionContext";
import { FaPlus } from "react-icons/fa";
import { getAssCoach } from "../../../../apis";
import { auth } from "../../../../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "../../../ReusableUi/Loader";
import EmptyData from "../../../ReusableUi/EmptyData";
import UpgradePlan from "../../../ContentArea/UpgradePlan";

const Assistant = ({ isActiveTab, isGrowthPlan }) => {
  console.log(isGrowthPlan)
  const [loader, setLoader] = useState(false)
  const [getAssCoachList, setAssCoachList] = useState([])
  const { handleAssistantCoach, isInviteAssitantCoach } = useContext(SessionContext)
  const {userRole, setUserRole} = useState("")
  const fetchUserRole = async () => {
    try {
      const userClaims =  auth.getUser("G81dZPtmajZMq9fgalXfhZXWRzf2");
      setUserRole(userClaims);

    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };

  const fetchAssCoach = async () => {
    setLoader(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getAssCoach}?currentPage=1&itemsPerPage=4`,
        { headers: { Authorization: token } }
      );
      const listAssCoach = response.data.assisCoaches;
      setAssCoachList(listAssCoach)
    } catch (error) {
      toast.error("Failed to fetch groups.");
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchAssCoach();
    fetchUserRole();
  }, []);
  const name = auth.currentUser.displayName;
  return (
    <>
      <div className="d-flex h-100 justify-content-end mx-3">
        {isGrowthPlan && (
          <UpgradePlan />
          )}
        {isActiveTab === "assitantCoach" && 
        <button type="submit" className="assistantButton"  style={{backgroundColor : `var(--secondary-color,#A19686)`,color : `var(--secondary-text-color,white)`}}  onClick={handleAssistantCoach} >
          <span className="plus-icon">
            <FaPlus />
          </span>
          Invite Assistant Coach
        </button>}
        {isInviteAssitantCoach && (
          <AssistantCoachModal />
        )}
      </div>
      {loader ? (
        <div  className="d-flex justify-content-center  align-items-center h-100">
          <Loader />
        </div>
      ) : (
        <>
          <div className="sessionContainer w-100">
            {getAssCoachList.length === 0 ? (
              <>
                <EmptyData
                  title={"No Coach Invited"}
                  subTitle={"Create a Session from Index Session From Groups Athelete and from Index Session."}
                  height= {"20vw"}
                />
              </>
            ) : (
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr style={{ borderRadius: "50px" }} className="tablesession">
                      <th style={{ textAlign: "start", padding: "8px 20px", fontSize: "14px", fontWeight: "700" }}> Name</th>
                      <th style={{ textAlign: "center", fontSize: "14px", fontWeight: "700" }}>Email</th>
                      <th style={{ textAlign: "center", fontSize: "14px", fontWeight: "700" }}>Date </th>
                    </tr>
                  </thead>
                  <tbody style={{ borderStyle: "none" }} className="tableBody">

                    {getAssCoachList.map((data, index) => {
                      return (
                        <>
                          <br />
                          <tr key={index}>
                            <td className="chk">
                              <img id="chkImage" src={Avtr} />
                              <p className="chkname text-capitalize" id="as" style={{ margin: 0, fontSize: "16px" }}>{name}</p>
                            </td>
                            <td>
                              <p className="chkname" id="as" style={{ margin: 0, textAlign: "center", fontSize: "16px" }}>{data?.email}</p>
                            </td>
                            <td className="chkname" id="as" style={{ margin: 0, textAlign: "center", fontSize: "16px" }}>{moment(data?.createdAt).format('D/MM/YYYY')}</td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}

          </div></>)}
    </>
  );
};

export default Assistant;
