import React, { useState } from "react";
import "./ManageBusiness.css";
import BusinessTable from "./manageBusinessTable/businessTable";
import { FaSearch, FaTrash, FaPlus } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";

const ManageBusiness = (props) => {

  const { activeTab } = props;
  const [show, setShow] = useState(false);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [activeBtn, setActivebtn] = useState("athlete");
  const [activePlan, setActivePlan] = useState("")
  const openInviteModal = () => {
    setInviteModalOpen(true);
  };

  const closeInviteModal = () => {
    setInviteModalOpen(false);
  };
  return (
    <div className="businessContainer">
      <div
        className={!show && "athbody"}
        style={{
          padding: !show && "2rem",
        }}
      >
        <div className="businessHeader">
          <div className="businessHeaderTexts">
            <h4>Pricing plans that suit you</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor.
            </p>
          </div>
          <div className="businessHeaderbuttons">
            <div id={activeBtn === "athlete" && "activeButton"} style={{ width: "50%" }}>
              <button
                variant="text"
                onClick={() => setActivebtn("athlete")}
              >
                <span>
                  Athlete
                </span>
              </button>
            </div>
            <div style={{ width: "50%" }} id={activeBtn === "coach" && "activeButton"}>
              <button

                onClick={() => setActivebtn("coach")}
              >
                <span>
                  Coach
                </span>
              </button>
            </div>
          </div>
        </div>

        <BusinessTable activeTab={activeTab} activeBtn={activeBtn} />

      </div>

    </div>
  );
};

export default ManageBusiness;
