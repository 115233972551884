import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { firebaseConfig } from "./config/firebaseConfig";

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);


const getToken = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken(true); 
          resolve(token); 
        } catch (error) {
          reject('Error retrieving token: ', error);
        }
      } else {
        reject('User not authenticated');
      }
    });
  });
};

export { auth, getToken };
