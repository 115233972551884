// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat_trainingSessionContent__7K7F5 {
    width: 100%;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    border-radius: 10px;
    background: var(--white-100, #FFF);
    box-shadow: 6.93062px 11.38602px 48.01932px 0px rgba(0, 0, 0, 0.08);

    @media (max-width: 1400px) {
        height: auto;
    }

    @media (max-width: 768px) {
        height: auto;
    }

    @media (max-width: 480px) {
        height: auto-content;
    }

    & > div {
        width: 50vw;
    }
}
/******* Athelete Chat ***********/
.chat_atheleteName__UMC0E {
   font-family: "Hurme Geometric Sans 2";
   font-size: 24px;
   font-weight: 600;
   color: #232D42;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/Athelete/Chat/chat.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,0BAA0B;IAC1B,mBAAmB;IACnB,kCAAkC;IAClC,mEAAmE;;IAEnE;QACI,YAAY;IAChB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,oBAAoB;IACxB;;IAEA;QACI,WAAW;IACf;AACJ;AACA,kCAAkC;AAClC;GACG,qCAAqC;GACrC,eAAe;GACf,gBAAgB;GAChB,cAAc;AACjB","sourcesContent":[".trainingSessionContent {\r\n    width: 100%;\r\n    padding: 20px 20px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: calc(100vh - 90px);\r\n    border-radius: 10px;\r\n    background: var(--white-100, #FFF);\r\n    box-shadow: 6.93062px 11.38602px 48.01932px 0px rgba(0, 0, 0, 0.08);\r\n\r\n    @media (max-width: 1400px) {\r\n        height: auto;\r\n    }\r\n\r\n    @media (max-width: 768px) {\r\n        height: auto;\r\n    }\r\n\r\n    @media (max-width: 480px) {\r\n        height: auto-content;\r\n    }\r\n\r\n    & > div {\r\n        width: 50vw;\r\n    }\r\n}\r\n/******* Athelete Chat ***********/\r\n.atheleteName {\r\n   font-family: \"Hurme Geometric Sans 2\";\r\n   font-size: 24px;\r\n   font-weight: 600;\r\n   color: #232D42;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trainingSessionContent": `chat_trainingSessionContent__7K7F5`,
	"atheleteName": `chat_atheleteName__UMC0E`
};
export default ___CSS_LOADER_EXPORT___;
