import React, { useContext, useState } from "react";
import "./home.css";
import "react-toggle/style.css";
import ChatList from "./chatList";
import '../../../fonts.css';
import { SessionContext } from "../../../context/sessionContext";
import CoachGrid from "./UserDetails/userDetails";
import { Container } from "react-bootstrap";
import UserSection from "./UserSection/userSection";
import { auth } from "../../../firebase";
const HomePage = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  
 
  React.useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const { isOpenChat } = useContext(SessionContext)

  return (
    <>
      {isOpenChat && !isDesktop ? (
        <Container fluid>
          <ChatList />
        </Container>
      ) : (
        <Container fluid>
          <CoachGrid />
          <UserSection />
        </Container>
      )}

    </>
  );
};

export default HomePage;
