import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SearchInput from '../../../ReusableUi/SearchInput'
import AH from "../../../../assessts/images/athleteSource.png"
import styles from "./chat.module.css"
import ChatList from '../../../coachPortal/home/chatList'
import Chats from '../../../inbox/chats'
import { ThemeContext } from '../../../../context/themeContext'
import { auth } from '../../../../firebase'

const AtheleteChat = () => {
    const { isGroupChat, activeChat, socket } = useContext(ThemeContext);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        // Function to capitalize the first letter of the first name
        function capitalizeFirstLetter(string) {
            return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
        }

        const fetchUserName = async () => {
            await auth.onAuthStateChanged((user) => {
                if (user) {
                    const fullName = user.displayName;
                    setUserName(fullName);
                } else {
                    // Handle no user signed in
                    setUserName('Guest'); // Default name or handle accordingly
                }
            });
        };

        fetchUserName();
    }, []); // Empty dependency array ensures this effect runs only once on mount

    return (
        <Container fluid className='athltContainer'>
            <Row>
                <Col md={4} sm={12} lg={4}>
                    <div>
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <div className="d-inline">
                                <img src={AH} className='rounded-circle w-50 h-50 d-flex mx-auto' alt="" />
                                <p className={`${styles.atheleteName} d-flex justify-content-center pt-2`}>{userName}</p>
                            </div>
                        </div>
                        {/* <SearchInput /> */}
                    </div>
                        <ChatList isAthlete={true}/>
                </Col>
                <Col md={8} sm={12} lg={8}>
                    {/* <h1>Test</h1> */}
                <Chats isAthlete={true} isGroupChat={isGroupChat} user={activeChat} />
                </Col>
            </Row>
        </Container>
    )
}

export default AtheleteChat