import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import styles from "../groupSession.module.css"
import { toast } from 'react-toastify';
import axios from 'axios';
import { auth } from '../../../../firebase';
import { addOrEditBlockSetGroup, calculateGroupOneRepMax, calculateGroupPersonalRecord } from '../../../../services/store/atheleteApi';
import { calculateOneRepMax, calculatePersonalRecord } from '../../../../apis';

const SetsArea = styled.div`
width: max-content;
height: 153px;
overflow-y: scroll;
`;
const SetDiv = styled.div`
display: flex;
margin-top: 3vh;
gap: 70px;
flex-shrink: 0;
`;

const GroupBlocks = (props) => {
    const {  setReps,
      setWeight,
      setMaxTime,  refresh,setRefresh,sessionId, isSelectedDate,setApisavedRepMaxValues,handleSessionGetData, currentExerciseIndex,blockId,hit,indexChange,setchecking,refreshCheck,save, globalTotalReps, sumOfReps, getBlocksId, globalTotalWeight, totalWeight, onClose, exerciseId, getAtheleteSessionExercise, setApiResponse } = props;
    const [sets, setSets] = useState([]);
    const [shouldSaveSets, setShouldSaveSets] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const addSet = () => {
        const newSet = {
            setValue: sets.length + 1,
            reps: '',
            weight: '',
            checked: false,
        };
        setSets(sets => [...sets, newSet]);
    };

    // Function to remove the last set
    const removeLastSet = () => {
        if (sets?.length > 0) {
            const updatedSets = [...sets];
            updatedSets.pop();
            setSets(updatedSets);
        }
    };

    useEffect(() => {
        GetAtheleteSessionExercise();
      }, [indexChange]);
    const GetAtheleteSessionExercise = () => {
        const updatedSets = getAtheleteSessionExercise?.map((session, index) => ({
          id: `${exerciseId}-${index}`,
          setId: session.id,
          setValue: index + 1,
          reps: session.value1,
          weight: session.value2,
          checked: session.setDone,
        }));
        setSets(updatedSets);
      };

      const handleCheckboxChange = async (index) => {
        const updatedSets = sets.map((set, i) =>
          i === index ? { ...set, checked: !set.checked } : set
        );
        
        setSets(updatedSets);
      
        const currentTime = hit;
        try {
        await updateAthleteBlockSet(updatedSets[index], currentTime);
        // Only trigger the API if the checkbox is being checked
        if (updatedSets[index].checked) {
            await handleSaveTraningSessionBlock();
        }
      } catch (error) {
        console.error("Error updating checkbox:", error);
        // Revert the state in case of an error
        setSets(sets); 
      }
      };
      
    // Function to handle input changes
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        setSets(prevSets => {
            // Create a new array with all the previous sets
            const updatedSets = [...prevSets];
            // Update the specific set with the new value
            updatedSets[index] = { ...updatedSets[index], [name]: value };
            return updatedSets;
        });
    };
    

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const updatedSets = sets.map(set => ({
            ...set,
            checked: newSelectAll,
        }));
        setSets(updatedSets);
        setShouldSaveSets(true);
    };



    useEffect(() => {
        if (shouldSaveSets) {
            handleSaveTraningSessionBlock();
            setShouldSaveSets(false); // Reset the flag after the API call
        }
    }, [sets, shouldSaveSets]); // Depend on sets and shouldSaveSets
    useEffect(() => {
        props.onExerciseDataUpdate(exerciseId, { sumOfReps, totalWeight });
    }, []);
    const updateAthleteBlockSet = async (set, currentTime) => {
      setRefresh(!refresh);
    
      const convertTimeToSeconds = (timeString) => {
        const [minutes, seconds] = timeString.split(":").map(Number);
        return minutes * 60 + seconds;
      };
    
      const totalSeconds = convertTimeToSeconds(currentTime);
    
      try {
        const token = await auth.currentUser.getIdToken(true);
        const payload = {
          blockId,
          sets: [
            {
              setId: set.setId, 
              title1: "Reps",
              title2: "Weight",
              value1: set.reps,
              value2: set.weight,
              setDone: set.checked,
              time: set.checked ? totalSeconds : 0,
            },
          ],
        };
    
        const url = `${process.env.REACT_APP_Baseurl}${addOrEditBlockSetGroup}`;
        const config = { headers: { Authorization: `Bearer ${token}` } };
        await axios.patch(url, payload, config);
    
        await updateGlobalTotals(sessionId);
    
        toast.success("Set updated successfully.");
      } catch (error) {
        console.error("Error updating athlete block set:", error);
        const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred.";
        toast.error(`Failed to update set: ${errorMessage}`);
      }
    };
    const [calculateBlocks,setcalculateBlocks]=useState({})
    const updateGlobalTotals = async (sessionId) => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const url = `${process.env.REACT_APP_Baseurl}/api/v1/athlete/getGroupsBlockTotals/${sessionId}`;
    
        const response = await axios.get(url, config);
        setcalculateBlocks(response.data.data)
        const { totalReps, totalWeight, maxTime } = response.data.data
  
        setReps(totalReps || 0); 
        setWeight(totalWeight || 0); 
        setMaxTime(maxTime || 0);
      } catch (error) {
        console.error("Error fetching global totals:", error);
      }
    };
  
    useEffect(() => {
      updateGlobalTotals(sessionId);
    }, [sessionId, refresh]);
    
    


    // Function To Get Api hit in One Rep Max Value 
    const handleSaveTraningSessionBlock = async () => {
      setRefresh(!refresh);
      try {
        const token = await auth.currentUser.getIdToken(true);
        const formattedSets = calculateBlocks.sets
          .filter((set) => set.setDone) 
          .map((set) => ({
            id: set.id,
            value1: set.value1,
            value2: set.value2,
          }));
    
        if (formattedSets.length === 0) {
          return;
        }
        console.log(formattedSets,"formattedSets")
        const payload = {
          exerciseId: exerciseId, // assuming exerciseId is passed as a prop
          sets: formattedSets,
        };
        const payload2 = {
          exerciseId: exerciseId, // assuming exerciseId is passed as a prop
          sets: formattedSets,
        };
        // Determine the API endpoint and payload based on whether it's a new program or an edit
  
        const endpoint1 = calculateOneRepMax;
        const endpoint2 = calculatePersonalRecord;
        // Make the API call
        await makeApiCall(endpoint1, payload, token);
        await makeApiCall2(endpoint2, payload2, token);
      } catch (err) {
        console.error(err);
  
      }
    };
    useEffect(()=>{
      handleSaveTraningSessionBlock()
    },[])

    /**
* Makes an API call to the given endpoint with the provided payload and token.
* @param {string} endpoint - API endpoint
* @param {Object} payload - Data to send in the API request
* @param {string} token - Authorization token
*/
    const makeApiCall = async (endpoint1, payload1, token) => {
        try {
            const url = `${process.env.REACT_APP_Baseurl}${endpoint1}`;
            const config = {
                headers: { Authorization: token }
            };
            // Making the API call
            const response = await axios.post(url, payload1, config);
            // Set the API response data to the state
            setApiResponse(response?.data);
         
          
          // } // You might need to adjust this depending on the structure of your response
        } catch (error) {
            console.error("Error in API call:", error);
            // Optionally, handle errors differently or set error-related state
        }
    };

    const makeApiCall2 = async (endpoint2, payload2, token) => {
        const url = `${process.env.REACT_APP_Baseurl}${endpoint2}`;
        const config = {
            headers: { Authorization: token }
        };
        // Making the API call
        const response = await axios.post(url, payload2, config);
        setApisavedRepMaxValues( response?.data?.savedRepMaxValues)
    };
    return (
        <SetsArea className={styles.setRepsWeightDiv}>
            <SetDiv>
                <div className={styles.setsDiv}>
                    <span>Sets</span>
                </div>
                <div className={styles.setsDiv}>
                    <span>Reps</span>
                </div>
                <div className={styles.setsDiv}>
                    <span>Weight</span>
                </div>
                <div className={`${styles.custom_checkbox} d-flex`} style={{ alignItems: "center" }}>
                    <input
                        type="checkbox"
                        id="selectAllCheckbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                    />
                    <label htmlFor="selectAllCheckbox"></label>
                </div>
            </SetDiv>
            <div >
                {sets?.map((set, index) => (
                    <div key={set.id || index} className={styles.setsInputDiv}>
                        <input
                            type="text"
                            name="set"
                            className={styles.sessionValueInput}
                            value={set?.setValue}
                            onChange={(e) => handleInputChange(index, e)}
                        />

                        <input
                            type="text"
                            name="reps"
                            placeholder={!set?.checked ? set?.reps : ''}
                            value={set?.reps}
                            className={styles.sessionValueInput}
                            onChange={(e) => handleInputChange(index, e)}
                        />

                        <input
                            type="text"
                            name="weight"
                            placeholder={!set?.checked ? set?.weight : ''}
                            value={set?.weight}
                            className={styles.sessionValueInput}
                            onChange={(e) => handleInputChange(index, e)}
                        />

                        <div className="d-flex" style={{ alignItems: 'center' }}>
                            <div className={styles.custom_checkbox}>
                                <input
                                    type="checkbox"
                                    id={`checkbox-${index}`}
                                    checked={set?.checked}
                                    onChange={() => handleCheckboxChange(index)}
                                />
                                <label htmlFor={`checkbox-${index}`}></label>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <div className="iconicBtns">
                {sets?.length > 0 && <div onClick={removeLastSet} className="plusMinus">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M1.43503 9.99956L5.98747 9.99851L5.98763 7.99792C5.9865 8.82538 6.20031 9.4978 6.99198 9.49662C6.35267 9.49908 5.9865 8.82792 5.98647 7.99924L5.98747 9.99851L14.5651 10C15.3579 10 15.9977 9.32645 16 8.5002C15.9988 7.67273 15.3555 7.00031 14.565 7.00028L5.98621 7.00011L5.98721 9.00677C5.98718 8.17809 6.7783 7.50567 6.99376 7.50806C6.59619 7.50683 6.23919 7.67401 5.97956 7.94538C5.71878 8.21796 5.98835 8.5911 5.98836 9.00544L5.98852 7L1.43376 7.00105C1.03734 7.00103 0.680345 7.16821 0.420716 7.43958C0.161086 7.71095 0.00114402 8.08651 0 8.49964C3.02537e-05 9.32832 0.642198 9.99953 1.43503 9.99956Z" fill="#A01A1A" />
                    </svg>
                </div>}
                <div onClick={() => addSet()} className="pluscircle">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M2.47505 9.42596L6.57352 9.42496L6.57367 13.5223C6.57255 14.3092 7.21206 14.9488 7.99903 14.9476C8.78946 14.95 9.42546 14.3117 9.42543 13.5235L9.42643 9.42507L13.5272 9.42638C14.3153 9.42641 14.9513 8.7858 14.9536 7.99998C14.9524 7.21301 14.3129 6.5735 13.5271 6.57347L9.42517 6.57331L9.42617 2.48176C9.42614 1.69363 8.78663 1.05412 8.00081 1.0564C7.60559 1.05523 7.25071 1.21422 6.99263 1.47231C6.73338 1.73155 6.57439 2.08643 6.57441 2.48049L6.57456 6.5732L2.47379 6.5742C2.07973 6.57418 1.72485 6.73318 1.46676 6.99126C1.20867 7.24935 1.04968 7.60654 1.04854 7.99945C1.04857 8.78757 1.68693 9.42593 2.47505 9.42596Z"
                            fill="#1AA053"
                        />
                    </svg>
                </div>
            </div>
            {save === "save" ? (<> <div className={styles.saveButton} onClick={onClose}><span>Save</span></div> </>) : (<></>)}
        </SetsArea>
    )
}

export default GroupBlocks