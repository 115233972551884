import React, { useContext } from 'react';
import { SessionContext } from '../../../../../context/sessionContext';
import styled from 'styled-components';
import Program from "../../../../../assessts/images/indexCard.svg";
import styles from "../../index.module.css";

const Card = styled.div`
  display: flex;
  width: 100%;
`;
const CardBody = styled.div`
  padding: 19px 15px;
  width: 31%;
  display: flex;
  border-radius: 12px;
  border-bottom: 1px solid var(--secondary-purple-b, #EAE6E0);
  background: var(--white-100, #FFF);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  height: 100.315px;
  flex-shrink: 0;
  margin-right: 38px;
  margin-bottom: 20px;
  cursor: pointer; 
`;
const CardImage = styled.div`
  width: 63px;
  height: 63px;
  flex-shrink: 0;
  border-radius: 9px;
  padding-right: 10px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const sessionData = [
  { title: 'Bench Press', image: Program },
  { title: 'Bench Press', image: Program },
  { title: 'Bench Press', image: Program }
];

const CircuitIndex = () => {
  const { handleSessionDetail } = useContext(SessionContext);

  return (
    <div className="mt-4 d-flex w-100">
      <Card>
        {sessionData.map((session, index) => (
          <CardBody key={index} onClick={handleSessionDetail}>
            <CardImage>
              <img src={session.image} alt={`${session.title} image`} />
            </CardImage>
            <div className={styles.sessionTitle}>
              <div>{session.title}</div>
            </div>
          </CardBody>
        ))}
      </Card>
    </div>
  );
};

export default CircuitIndex;
