import React from 'react'
import style from './users.module.css'

const UsersComp = () => {
    const userData = [
        {
            count: "590",
            heading: "Sessions Logged"
        },
        {
            count: "2500",
            heading: "Total Time Trained (hours)"
        },
        {
            count: "200+",
            heading: "Programs Uploaded"
        },
        {
            count: "470",
            heading: "PRS Set"
        }
    ]
    return (
        <div className={`${style.main}`}>
            <div className={`${style.bgIMG}`}>
                <h1>The Training App <span>currently</span> has</h1>
                <div className={`${style.mainDiv}`}>
                    {userData.map((data, k)=>(
                        <div key={k} className={`${style.content}`}>
                            <p className={`${style.p1}`}>{data.count}</p>
                            <p className={`${style.p2}`}>{data.heading}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default UsersComp
