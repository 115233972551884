import React from 'react';
import './createExerciseModal.css'
import CreateExercise from '../forms/createExercise/createExercise';
const CreateExerciseModal = ({ isOpen, onClose, isEditExercise, setEditEditExercise, activeTab, }) => {

  return (
    <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`}>
      <div className="exercise-overlay" onClick={onClose}></div>
      <div className="exercisemmodalBody" style={{ backgroundColor: `var(--secondary-bg-color,white)` }} >
        <h2 style={{ color: `var(--primary-text-color,black)` }} >{isEditExercise ? 'Edit Exercise' : 'Create Exercise'}</h2>
        <CreateExercise
          isEditExercise={isEditExercise}
          onClick={onClose}
          setEditEditExercise={setEditEditExercise}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
};

export default CreateExerciseModal;
