import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./calender.module.css";


// Function to translate day and date based on selected language
function translateDayAndDate(date, selectedLanguage, t) {
  const day = t(date.toLocaleDateString(selectedLanguage, { weekday: 'short' }));
  const dateString = t(date.toLocaleDateString(selectedLanguage, { day: '2-digit' }));

  return { day, date: dateString };
}

const CalenderUI = ({ setSelectedDate,width }) => {
  const { t, i18n } = useTranslation();

  const today = new Date();
  const selectedLanguage = i18n.language;


  const timelineData = [];

  for (let i = 0; i < 60; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);

    // Translate day and date
    const { day, date: dateString } = translateDayAndDate(date, selectedLanguage, t);

    // Format time as HH:mm
    const timeString = date.toLocaleTimeString(selectedLanguage, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    // Add data to the timeline
    timelineData.push({ day, date: dateString, time: timeString, fullDate: date });
  }

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    // Check if there's a selected date in localStorage
    const storedDate = localStorage.getItem('selectedDate');
    console.log(storedDate)
    if (storedDate) {
      // Use the stored date as the selected date
      setSelectedDate(storedDate);

      // Find the index of the stored date in the timeline
      const storedDateObj = new Date(storedDate);
      const currentIndex = timelineData.findIndex(
        (item) => item.fullDate.toDateString() === storedDateObj.toDateString()
      );

      setSelectedDay(currentIndex);
    } else {
      // Find the index of the current date in the timeline
      const currentIndex = timelineData.findIndex(
        (item) => item.fullDate.toDateString() === today.toDateString()
      );

      setSelectedDay(currentIndex);

      // Set the current time
      const currentHour = today.getHours();
      const currentMinute = today.getMinutes();
      const currentTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
      setSelectedTime(currentTime);

      // Set the selected date to today's date and save it in localStorage
      const date = timelineData[currentIndex].fullDate;
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      setSelectedDate(formattedDate);
      localStorage.setItem('selectedDate', formattedDate);
    }
  }, [timelineData, today, setSelectedDate]);

  const onClickDay = (index) => {
    setSelectedDay(index);
    setSelectedTime(0);

    const date = timelineData[index].fullDate;
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    setSelectedDate(formattedDate)

    // Save the selected date in localStorage
    localStorage.setItem('selectedDate', formattedDate);
  };

  return (
    <div  style={{width : width}} className={`${styles.Calendar}`}>
      <div className={styles.horizontalTimeline}>
        {timelineData?.map((item, index) => (
          <div key={index} className={styles.timelineItem}>
            <div
              className={styles.day}
              style={{
                backgroundColor: selectedDay === index ? '#A19686' : ' ',
                color: selectedDay === index ? '#FFF' : '#A19686',
              }}
              onClick={() => onClickDay(index, item)}
            >
              <a id="daystyle"> {item.day}</a>

              <div className='' style={{
                borderRadius: selectedDay === index ? '16px' : '',
                width: selectedDay === index ? '40px' : '',
                height: selectedDay === index ? '10px' : '',
                marginTop: selectedDay === index ? '7px' : '7px',
                color: selectedDay === index ? '#FFF' : '#A19686',
              }}>{item.date}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalenderUI;
