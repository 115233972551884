// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.atheletegroups_manageGroupParent__SM6Tt {
    border-radius: 10px;
    background: var(--white-100, #FFF);
    /* Shadow */
    box-shadow: 6.93062px 11.38602px 48.01932px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
}

.atheletegroups_groupHistoryTitle__5up\\+e {
    color: #000;
    text-align: center;
    font-family: Hurme Geometric Sans 2;
    font-size: 20.8px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 125% */
}`, "",{"version":3,"sources":["webpack://./src/components/atheleteComponnent/manageGroups/atheletegroups.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kCAAkC;IAClC,WAAW;IACX,mEAAmE;IACnE,WAAW;AACf;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,mCAAmC;IACnC,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb","sourcesContent":[".manageGroupParent {\r\n    border-radius: 10px;\r\n    background: var(--white-100, #FFF);\r\n    /* Shadow */\r\n    box-shadow: 6.93062px 11.38602px 48.01932px 0px rgba(0, 0, 0, 0.08);\r\n    width: 100%;\r\n}\r\n\r\n.groupHistoryTitle {\r\n    color: #000;\r\n    text-align: center;\r\n    font-family: Hurme Geometric Sans 2;\r\n    font-size: 20.8px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: 26px;\r\n    /* 125% */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageGroupParent": `atheletegroups_manageGroupParent__SM6Tt`,
	"groupHistoryTitle": `atheletegroups_groupHistoryTitle__5up+e`
};
export default ___CSS_LOADER_EXPORT___;
