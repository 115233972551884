// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth_modalTitle__oU6To {
    display: flex;
    justify-content: center;
}
.auth_modalTitle__oU6To > h2 {
    color: var(--neutral-color-black, #0A1633);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-family: Hurme Geometric Sans 2 !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 1rem;
}
.auth_active__KKhpF:hover {
    border-radius: 12px;
    display: flex;
    text-align: center;
    justify-content: center;
    color: white !important;
    background: var(--Primary-Barand, #A19686);
    height: 53px;
}
.auth_textHover__qKDqt:hover{
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/auth.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,0CAA0C;IAC1C,sDAAsD;IACtD,8CAA8C;IAC9C,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,uBAAuB;IACvB,0CAA0C;IAC1C,YAAY;AAChB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".modalTitle {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n.modalTitle > h2 {\r\n    color: var(--neutral-color-black, #0A1633);\r\n    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;\r\n    font-family: Hurme Geometric Sans 2 !important;\r\n    font-size: 24px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: 36px;\r\n    margin-bottom: 1rem;\r\n}\r\n.active:hover {\r\n    border-radius: 12px;\r\n    display: flex;\r\n    text-align: center;\r\n    justify-content: center;\r\n    color: white !important;\r\n    background: var(--Primary-Barand, #A19686);\r\n    height: 53px;\r\n}\r\n.textHover:hover{\r\n    color: white !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": `auth_modalTitle__oU6To`,
	"active": `auth_active__KKhpF`,
	"textHover": `auth_textHover__qKDqt`
};
export default ___CSS_LOADER_EXPORT___;
