import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './pagination.module.css';

/**
 * CustomPagination Component
 * This component handles the pagination logic and display.
 *
 * @param {number} currentPage - The current active page.
 * @param {number} totalPages - The total number of pages available.
 * @param {function} onPageChange - The function to call when a new page is selected.
 */
const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
 
    // State to keep track of the current page number within the component
    const [pageNumber, setPageNumber] = useState(currentPage);

    // The maximum number of page numbers to display in the pagination control
    const maxPageNumbersToShow = 5;

    /**
     * Handles clicking on a page number.
     * Updates the internal page number state and triggers the onPageChange event.
     * 
     * @param {number} newPageNumber - The new page number selected.
     */
    const handlePageClick = (newPageNumber) => {
        setPageNumber(newPageNumber);
        onPageChange(newPageNumber);
    };

    /**
     * Calculates the range of page numbers to display.
     * 
     * @returns {Object} An object containing the start and end page numbers.
     */
    const getPageRange = () => {
        let startPage = Math.max(1, pageNumber - Math.floor(maxPageNumbersToShow / 2));
        let endPage = startPage + maxPageNumbersToShow - 1;

        // Adjust the range if the end page number exceeds the total number of pages
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
        }

        return { startPage, endPage };
    };

    /**
     * Renders the list of page numbers.
     * 
     * @returns {Array} An array of JSX elements representing page numbers.
     */
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const { startPage, endPage } = getPageRange();

        // Create page number elements from the calculated range
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={pageNumber === i ? styles.active : ''}>
                    <a href="#" style={{ textDecoration: 'none' }} onClick={() => handlePageClick(i)}>
                        {i}
                    </a>
                </li>
            );
        }
        return pageNumbers;
    };

    // Rendering the pagination component
    return (
        <div className="row mb-5">
            <div className="col-md-12">
                <div className={`${styles.Pagination} d-flex justify-content-end mx-4`}>
                    <ul>
                        {/* Previous page link */}
                        <li>
                            <a href="#" style={{ textDecoration: 'none' }} onClick={() => handlePageClick(Math.max(1, pageNumber - 1))}>
                                &lt;
                            </a>
                        </li>
                        {/* Dynamic page numbers */}
                        {renderPageNumbers()}
                        {/* Next page link */}
                        <li>
                            <a href="#" style={{ textDecoration: 'none' }} onClick={() => handlePageClick(Math.min(totalPages, pageNumber + 1))}>
                                &gt;
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

// PropTypes for type checking the props passed to the component
CustomPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;
