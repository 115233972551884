import React, { useState, useEffect } from 'react';

const SvgIcon = ({ iconName, size, ...props }) => {
    const [svg, setSvg] = useState(null);

    useEffect(() => {
        // Dynamically import the SVG file based on iconName
        import(`../icons/${iconName}.svg`)
            .then((svg) => {
                setSvg(svg.default);
            })
            .catch(err => console.error(`Error loading ${iconName}.svg`, err));
    }, [iconName]);

    if (!svg) {
        return null;
    }

    return (
        <img src={svg} alt={iconName} width={size} height={size}   {...props} />
    );
};

export default SvgIcon;
// A19686