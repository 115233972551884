import React from 'react'
import styles from "./reusableUi.module.css"
const Loader = () => {
    return (
        <div class={styles?.ring}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Loader