import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CalenderUI from "../CalenderUI";
import AtheleteDetails from "./AtheleteDetails";
import AtheleteSession from "./AtheleteSession/atheleteSession";
import { SessionContext } from "../../../context/sessionContext";
import { getAtheleGroupSession } from "../../../services/store/atheleteApi";
import AtheleteTraningSession from "./AtheleteTraningSession";
import Header from "./Header";
import { auth } from "../../../firebase";
import axios from "axios";
import styles from "./atheleteSessions.module.css";
import TraningGroup from "../GroupSession/traningGroup";
import SvgIcons from "../../ReusableUi/SvgIcon";

const AtheleteGroupSession = ({ setOpenGroupHistory,handleSessionGetData,isGroupData, groupDetails }) => {
  const { isStartSession, setStartSession, handleOpenGroupHistory } =
    useContext(SessionContext);
  const [isSelectedDate, setSelectedDate] = useState("");
  const [getExerciseId, setExerciseId] = useState("");
  const [getCoachId, setCoachId] = useState("");
  const [getBlocksId, setBlockId] = useState("");
  const [getSessionId, setSessionId] = useState("");
  const [getExerciseData, setExerciseData] = useState([]);
  const [getGroupSesssionDetails, setGroupSesssionDetails] = useState([]);
  const [isEmptyData, setisEmptyData] = useState([]);
  const [isLoder, setisLoder] = useState();
  const [totalSets, setTotalSets] = useState(0);
  const [totalRepsValue,setTotalRepsValue]=useState(0);
  const [totalBlocks,setTotalBlocks]=useState(0)
  const [refresh,setRefresh]=useState(false)
  useEffect(() => {
    fetchAtheletGroupSession();
  }, [isSelectedDate]);
  const fetchAtheletGroupSession = async () => {
    setisLoder(true);
    if (!isSelectedDate) return; // Don't fetch if selectedDate is null or undefined

    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getAtheleGroupSession}?date=${isSelectedDate}&groupId=${groupDetails?.id}`,
        { headers: { Authorization: token } }
      );
      console.log(response?.data?.responseData,"response?.data?.responseData?.id")
      setSessionId(response?.data?.responseData[0]?.id);
      setCoachId(response?.data?.responseData?.coachId);
      setExerciseId(response?.data?.responseData?.groupBlocks?.exerciseId);
      
      setGroupSesssionDetails(response?.data?.responseData);
      {
        response?.data?.responseData.length <= 0
          ? setisEmptyData(true)
          : setisEmptyData(false);
      }
      setBlockId(response?.data?.responseData[0]?.groupBlocks[0]?.id);
      const data = response?.data?.responseData[0]?.groupBlocks
      const sortedData = data
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .map(item => {
        
        return {
          ...item,
          groupBlockSets: item.groupBlockSets
            ? item.groupBlockSets.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            : []
        };
      });
      setExerciseData(sortedData);
      const countSets = (data) => {
        let total = 0;
        data.forEach(item => {
          if (item.groupBlockSets) {
            total += item.groupBlockSets.length; // Count the sets in each block
          }
        });
        return total;
      };
      
      const setsCount = countSets(sortedData);
      setTotalSets(setsCount);
      const calculateTotalRepsValue = (data) => {
        let total = 0;
        data.forEach(item => {
          if (item.groupBlockSets) {
            item.groupBlockSets.forEach(set => {
              total += parseInt(set.value1, 10); // Convert value1 (Reps) to integer and sum
            });
          }
        });
        return total;
      };
      
      const totalReps = calculateTotalRepsValue(sortedData);
      setTotalRepsValue(totalReps);
      const countBlocks = (data) => {
        let blockCount = 0;
        data.forEach(item => {
          if (item.groupBlockSets) {
            blockCount++; // Increment for each block found
          }
        });
        return blockCount;
      };
      
      const blocksCount = countBlocks(sortedData);
      setTotalBlocks(blocksCount);
   
      setisLoder(false);
    } catch (error) {
      setisLoder(false);
      console.error("Error fetching session calendar:", error);
    }
  };
  return (
    <Container>
      <Row>
        {isStartSession ? (
          <TraningGroup
          setRefresh={setRefresh}
          refresh={refresh}
          getGroupSesssionDetails={getGroupSesssionDetails}
          isSelectedDate={isSelectedDate}
          setOpenGroupHistory={setOpenGroupHistory}
          handleSessionGetData={fetchAtheletGroupSession}
            groupDetails={groupDetails}
            getBlocksId={getBlocksId}
            isExerciseId={getExerciseId}
            getSessionId={getSessionId}
            getCoachId={getCoachId}
            getAtheleteSessionExercise={getExerciseData}
          />
        ) : (
          <>
            <div className={styles.traningGroupHeading}>
              <div className="d-flex justify-content-between px-lg-5 my-3 px-sm-0 px-md-3  align-content-center">
                <div>
                  <SvgIcons iconName="backArrow" size={20} />
                </div>
                <div>
                  <span className={styles.traningGroupTitle}>
                    {getGroupSesssionDetails[0]?.title}
                  </span>
                </div>
                <div></div>
              </div>
            </div>
            <Col
              sm={12}
              md={12}
              lg={7}
              className="px-lg-5 my-3 px-sm-0 px-md-3"
            >
              <CalenderUI
                setSelectedDate={setSelectedDate}
                isGroupData={isGroupData}
              />
              {!isEmptyData && !isEmptyData && (
                <>
                  <AtheleteDetails
                  totalSets={totalSets}
                  totalRepsValue={totalRepsValue}
                  totalBlocks={totalBlocks}
                    className="pt-4"
                    getGroupSesssionDetails={getGroupSesssionDetails[0]}
                    isSelectedDate={isSelectedDate}
                  />
                </>
              )}
              <AtheleteSession
                isLoder={isLoder}
                isEmptyData={isEmptyData}
                getAtheleteSessionExercise={getGroupSesssionDetails}
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default AtheleteGroupSession;
