import React from 'react'
import Layout from '../../Layout'
import One from './components/One/One'
import Second from './components/Second/Second'
import Third from './components/Third/Third'
import Fourth from './components/Fourth/Fourth'
import GYMLast from './components/Last/Last'
import Last from '../Coach/LastComp/Last'

const Gym = () => {
  return (
    <Layout>
      <div>

      <One/>
      <Second/>
      <Third/>
      <Fourth/>
      {/* <Fifth/> */} {/* This was intended to be build but then is used Last form some other page */}
      <Last/>
      <GYMLast/>
      </div>
    </Layout>
  )
}

export default Gym
