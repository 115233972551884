import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { athleteVerification, groupMemberVerification } from '../../apis';
import { auth } from '../../firebase';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import LoadingSpinner from '../loadingSpinner';

const stripePromise = loadStripe('pk_test_51NXcWqKpYizIWrOobsNz3syBnITmQuKTbCLUALIYfcpzSgg56xIv4q6pmV8kpMM5TYRiGePtekovSchdbzikjODf00P2vpl6bc');


const GroupInvitation = () => {
  const [isLoader, setLoader] = useState(false);
  const [queryParameters] = useSearchParams();
  const invitationCode = queryParameters.get("invitationCode");
  const invitationType = queryParameters.get("invitationType");
  const productName = queryParameters.get("productName");
  const subscriptionType = queryParameters.get("subscriptionType");
  const [isReadyToNavigate, setReadyToNavigate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(false);
  }, [location]);

  const handleAccept = async () => {
    try {
      setLoader(true);
      const requestData = {
        invitationCode,
        invitationType,
        productName,
        subscriptionType,
        memberId: auth?.currentUser?.uid
      };
      const invitation = invitationType === 'one-to-one' ? athleteVerification : groupMemberVerification;
      const token = await auth.currentUser.getIdToken(true);
      setIsLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}${invitation}`, requestData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if(token){
        console.log(token)
      }
      if(response){
       
      }
      if (response.data.message === 'Invitation is valid') {
        const stripe = await stripePromise;
      //   // Open the Checkout page in a new tab
      //   // Redirect to Stripe Checkout using the session ID
        await stripe.redirectToCheckout({ sessionId: response?.data?.sessionId });

        navigate("/manage-athelete-traning", { state: { needToFetchData: true } });
      } else {
        toast.error('Invalid invitation');
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    return () => {
      setReadyToNavigate(false);
    };
  }, []);

  const handleReject = () => {
    navigate("/manage-athelete-traning", { state: { needToFetchData: true } });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h1 className="card-title"> {invitationType === 'one-to-one' ? 'Athlete Invitation' : 'Group Invitation'}</h1>
              <p className="card-text">
                You have received an invitation. Would you like to accept or reject it?
              </p>
              <div className="d-flex justify-content-between">
                <button className="btn btn-primary" onClick={handleAccept}>{isLoading ? <LoadingSpinner size="sm" /> : "Accept"}</button>
                <button className="btn btn-danger" onClick={handleReject}>Reject</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupInvitation;