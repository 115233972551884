import React, { useState } from 'react';
import './signupForm.css'; // Import your CSS file
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../loadingSpinner';
import Input from '../ReusableUi/Input';
import { Col, Row } from 'react-bootstrap';
import PasswordInput from '../ReusableUi/PasswordInput';
import CheckBox from '../ReusableUi/CheckBox';
import { createUser } from '../../services/store/authApi';
import { toast } from 'react-toastify';

const SignupForm = ({ user }) => {
  const navigation = useNavigate();

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    businessName: '',
    hearAboutUs: '',
    clientsCoached: '',
    showPassword: false,
    userType: user
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const [termValidation, setTermsValidation] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    const value = event.target.value;
    setFormData({ ...formData, [prop]: value });

    // Clear error when field is filled
    if (value) {
      setFieldErrors({ ...fieldErrors, [prop]: '' });
    }
  };

  const handleCheckBoxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setTermsValidation(""); // Clear error when checked
    }
  };

  const handleShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const newFieldErrors = {};
    const requiredFields = ['firstname', 'lastname', 'email', 'password', 'businessName', 'clientsCoached', 'hearAboutUs'];

    // Validate required fields
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newFieldErrors[field] = 'This field is required';
      }
    });

    // Validate terms acceptance
    if (!isChecked) {
      setTermsValidation("You must accept the terms and conditions.");
      newFieldErrors.acceptTerms = "You must accept the terms and conditions."; // Add to field errors for display
    } else {
      setTermsValidation(""); // Clear any previous error
    }

    setFieldErrors(newFieldErrors);

    // If there are errors, prevent the API call
    if (Object.keys(newFieldErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_Baseurl}${createUser}`, formData);
      toast.success('User Created Successfully', { onClose: () => navigation("/login"), autoClose: 3000 });
    } catch (error) {
      const errorMsg = error.response?.data?.error || "An error occurred while creating the user.";
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="signup-formCoach" style={{ borderRadius: "0px", width: "100%", background: "#F8FAFB" }}>
      <Row>
        <Col md={12} sm={12} lg={6}>
          <div className="form-field gap-4">
            <label className='pb-2'>First Name</label>
            <Input
              value={formData.firstname}
              onChange={handleChange('firstname')}
              className="w-100"
              placeholder="John"
              type="text"
            />
            <p className="error-message">{fieldErrors.firstname}</p>
          </div>
        </Col>
        <Col md={12} sm={12} lg={6}>
          <div className="form-field gap-4">
            <label className='pb-2'>Last Name</label>
            <Input
              placeholder='Doe'
              type="text"
              value={formData.lastname}
              onChange={handleChange('lastname')}
            />
            <p className="error-message">{fieldErrors.lastname}</p>
          </div>
        </Col>
        <Col md={12} sm={12} lg={12}>
          <div className="form-field gap-4">
            <label className='pb-2'>Email</label>
            <Input
              placeholder='johndoe@gmail.com'
              type="email"
              value={formData.email}
              className="w-100"
              onChange={handleChange('email')}
            />
            <p className="error-message">{fieldErrors.email}</p>
          </div>
        </Col>
        <Col md={12} sm={12} lg={12}>
          <div className="form-field">
            <label>Password</label>
            <PasswordInput
              value={formData.password}
              onChange={handleChange('password')}
              showPassword={formData.showPassword}
              onToggleShowPassword={handleShowPassword}
              className="your-custom-class"
            />
            <p className="error-message">{fieldErrors.password}</p>
          </div>
        </Col>
        <Col md={12} sm={12} lg={6}>
          <div className="form-field">
            <label>Business Name</label>
            <Input
              placeholder='Training App'
              type="text"
              onChange={handleChange('businessName')}
              value={formData.businessName}
              className="w-100"
            />
            <p className="error-message">{fieldErrors.businessName}</p>
          </div>
        </Col>
        <Col md={12} sm={12} lg={6}>
          <div className="form-field">
            <label>How many Clients do you coach?</label>
            <Input
              placeholder='100'
              type="text"
              value={formData.clientsCoached}
              onChange={handleChange('clientsCoached')}
              className="w-100"
            />
            <p className="error-message">{fieldErrors.clientsCoached}</p>
          </div>
        </Col>
        <Col md={12} sm={12} lg={12}>
          <div className="form-field">
            <label>How did you hear about us?</label>
            <Input
              placeholder='Facebook'
              type="text"
              value={formData.hearAboutUs}
              onChange={handleChange('hearAboutUs')}
              className="w-100"
            />
            <p className="error-message">{fieldErrors.hearAboutUs}</p>
          </div>
        </Col>
      </Row>
      <div className="terms-checkbox">
        <div className='checkbox' >
        <CheckBox
          checked={isChecked}
          onChange={handleCheckBoxChange}
          label="I accept the terms & Privacy Policies of use."
          customStyles={{
            container: 'custom-container',
            checkbox: 'custom-checkbox',
            checkmark: 'custom-checkmark',
            label: 'acceptTerms',
          }}
        />
        <p className="error-message">{termValidation || fieldErrors.acceptTerms}</p>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", gap: '10px' }}>
        <button className='button' type="submit">
          {isLoading ? (
            <LoadingSpinner size="sm" />
          ) : (
            'Sign Up')}
        </button>
      </div>
    </form>
  );
};

export default SignupForm;
