import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { auth } from '../../firebase';

const RefreshUrlPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {


      const token = await auth.currentUser.getIdToken(true);
        // Make an API call to your server to get the account link
        const response = await axios.get(
            `${process.env.REACT_APP_Baseurl}/api/v1/coach/connectAccountLink`,
            { 
              headers: { Authorization: token } 
            }
          );
        
        // Assuming the response contains the account link
        const { link } = response.data;

        // Redirect to the account link
        window.location.href = link;
      } catch (error) {
        console.error('Error fetching account link:', error);
      } finally {
        // Set loading to false after the API call completes (whether success or error)
        setIsLoading(false);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [auth]);

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {/* You can add additional loading UI if needed */}
    </div>
  );
};

export default RefreshUrlPage;
