import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import styles from "./session.module.css";
import { ThemeContext } from "../../../context/themeContext";
import { SessionContext } from "../../../context/sessionContext";
import DetailsHistoy from "../../../assessts/icons/Button.svg";
import UserSessionDetails from "../UserSesssionDetails/UserSessionDetails";
import HistorySession from "../HistorySession/HistorySession";
import RestTime from "../../modals/SingleExercise/restTime";
import CountdownModal from "../../modals/SingleExercise/countDown";
import AMRAP from "../../modals/SingleExercise/amrap";
import ManageTarningModal from "../../modals/manageTraningModal";
import ForTime from "../../modals/SingleExercise/TraningSession/forTime";
import ExerciseStopwatch from "../../modals/SingleExercise/Counters/StopWatch";
import TabataTimer from "../../modals/SingleExercise/TraningSession/tabataTimer";
import Counter from "../../modals/SingleExercise/Counter";
import TabataTimerCounter from "../../modals/SingleExercise/Counters/tabataTimerCounter";
import CustomInterval from "../../modals/SingleExercise/TraningSession/customInterval";
import CustomIntervalCounter from "../../modals/SingleExercise/Counters/customIntervalCounter";
import EmomTimer from "../../modals/SingleExercise/TraningSession/emomTimer";
import EmomTimerCounter from "../../modals/SingleExercise/Counters/eMomTimerCounter";
import ArampCounter from "../../modals/SingleExercise/Counters/ArampCounter";
import IntensityModal from "../../modals/intensityModal";
import PostSessionFeedModal from "../../modals/SingleExercise/postSessionModal";
import ModalUi from "../../modules/Modal";
import Timer from "./Timer";

const HeaderArea = styled.header`
  border-bottom: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
  background: var(--white-100, #fff);
`;
const HeaderSection = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 10px 40px;
`;
const SectionContent = styled.section`
  width: 46vw;
  margin: 15px 40px;
`;
const ZoomableImage = styled.img`
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease; /* Add a smooth transition effect */
  cursor: pointer;
  &:hover {
    transform: scale(1.2); /* Scale up the image on hover */
  }
`;
const FooterSection = styled.section`
  width: 46vw;
  height: 40px;
  margin: 15px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Session = ({
  CurrentExerciseid,
  refresh,
  setRefresh,
  getAtheleteSessionExercise,
  getSessionId,
  getCoachId,
  sessionData,
  setStartSession,
  AtheleteExercise,
  handleSessionGetData,
  startSession,
  setFeedValue,
  setCurrentExerciseIndex,
  currentExerciseIndex,
  apiResponse,
  setApiResponse,
  apiPersonalRecordResponse,
  setApiPersonalRecordResponse,
  setCurrentExerciseBlockId,
  setCurrentExerciseid,
}) => {
  const [time, setupdatedTime] = useState({ minutes: 0, seconds: 0 });
  console.log("CurrentExerciseidCurrentExerciseid",CurrentExerciseid)
  const [totalStats, setTotalStats] = useState({
    totalReps: 0,
    totalWeight: 0,
  });
  const [exerciseData, setExerciseData] = useState({});
  const [sumOfReps, setSumOfReps] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [globalTotalReps, setGlobalTotalReps] = useState(0);
  const [globalTotalWeight, setGlobalTotalWeight] = useState(0);
  const [sessionComplete, setSessionComplete] = useState(false);
  const [checking, setchecking] = useState([]);
  const [maxTime, setMaxTime] = useState();
  const handleExerciseDataUpdate = (exerciseId, data) => {
    setExerciseData((prevData) => ({
      ...prevData,
      [exerciseId]: data,
    }));
  };
  const {
    SelectedDate,
    sessionHistory,
    isManageTrainingModalOpen,
    setIsManageTrainingModalOpen,
    isForTime,
    isRestTimeModalOpen,
    setIsRestTimeModalOpen,
    isArampModal,
    handleStartSessionGroup,
    setArampModal,
    isCountDownActive,
    setIsCountDownActive,
    minutes,
    setMinutes,
    isOpenCreateFeed,
    seconds,
    setSeconds,
    isOpenCustomInterval,
    handleStartSession,
    handleManageTrainingModal,
    handleOpenRestTimeModal,
    handleOpenCountDownModal,
    isStopWatchRun,
    isTabataTimer,
    preCountdown,
    istabataTimerCounter,
    isOpenCustomCounter,
    isOpenEmomTimer,
    isOpenEMOMTimerCounter,
    isOpenArampModalCounter,
    setOpenArampModalCounter,
    isEndTraningSession,
    isOpenPostFeedModal,
    setEndTraningSession,
    setSessionHistory,
    // setRefresh
  } = useContext(SessionContext);
  const [UpdatedAllSets, setUpdatedAllSets] = useState([]);
  const { theme } = useContext(ThemeContext);
  const [selectedSets, setSelectedSets] = useState({});
  const [refreshCheck, setrefreshCheck] = useState(false);
  const [indexChange, setIndexChange] = useState(false);
  const handleSetSelection = (exerciseId, sets) => {
    setSelectedSets((prevSelectedSets) => ({
      ...prevSelectedSets,
      [exerciseId]: sets,
    }));
  };
  console.log(getAtheleteSessionExercise,"getAtheleteSessionExercise")
  const getCurrentBlockId = () => {
    return getAtheleteSessionExercise[currentExerciseIndex]?.id || null;
  };
  const currentBlockId = getCurrentBlockId();
  useEffect(()=>{
    setCurrentExerciseBlockId(currentBlockId)
  },[currentBlockId])
  const handleNextClick = () => {
    handleSessionGetData();
    setIndexChange(!indexChange);
    setCurrentExerciseIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      // Check if the nextIndex exceeds the number of exercises
      if (nextIndex >= getAtheleteSessionExercise.length) {
        // Call handleEndSession if there are no more exercises
        handleEndSession();
        return prevIndex;
      }
      return nextIndex;
    });
    setrefreshCheck(true);
  };
  const handleEndSession = () => {
    setEndTraningSession(!isEndTraningSession);
    setSessionComplete(true);
  };
  // Use currentExerciseIndex to access the current exercise data
  const currentExercise = getAtheleteSessionExercise[currentExerciseIndex];
  useEffect(()=>{
    setCurrentExerciseid(currentExercise?.athltExercises?.id)
  },[currentExercise])
  // Function to handle Back click
  const handleBackClick = () => {
    handleSessionGetData();
    setIndexChange(!indexChange);
    setCurrentExerciseIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      return newIndex >= 0 ? newIndex : 0;
    });
  };
  const currentExerciseId = currentExercise?.athltExercises?.id;
  useEffect(() => {
    if (exerciseData[currentExerciseId]) {
      // If data exists for this exercise, use it
      const data = exerciseData[currentExerciseId];
      setSumOfReps(data.sumOfReps);
      setTotalWeight(data.totalWeight);
    } else {
      // If no data exists, set default values
      setSumOfReps(0);
      setTotalWeight(0);
    }
  }, [currentExerciseId, exerciseData]);
  const [reps, setReps] = useState(0);
  const [weight, setWeight] = useState(0);
  
  
  const result = getAtheleteSessionExercise.map((item) => {
    const hasTrueSetDone = item.AthltBlockSets.some(
      (blockSet) => blockSet.setDone
    );
    return hasTrueSetDone ? 1 : 0;
  });

  const PerformBlock = result.reduce((sum, current) => sum + current, 0);
  const [Name, setName] = useState("");
  const [Instruction, setInstruction] = useState("");
  const [url, setUrl] = useState("")
  useEffect(() => {
    setName(currentExercise?.athltExercises?.title);
    setInstruction(currentExercise?.athltExercises?.instructions);
    setUrl(currentExercise?.athltExercises?.URL)
  }, [currentExercise]);
  let letters = ["A", "B", "C", "D", "E"];
  let displayLetter = "Unknown";

  for (let i = 0; i < letters.length; i++) {
    if (currentExerciseIndex === i) {
      displayLetter = letters[i];
      break;
    }
  }

  return (
    <>
      {sessionHistory ? (
        <HistorySession
        sessionHistory={sessionHistory}
        startSession={startSession}
        setStartSession={setStartSession}
        apiPersonalRecordResponse={apiPersonalRecordResponse}
          apiResponse={apiResponse}
          exerciseId={CurrentExerciseid}
          blockId={currentBlockId}
          setSessionHistory={setSessionHistory}
        />
      ) : (
        <>
          <HeaderArea>
            <HeaderSection>
              <div
                onClick={() => setStartSession(false)}
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="22"
                  viewBox="0 0 12 22"
                  fill="none"
                >
                  <path
                    d="M10 20.1038L2 11.2511L10 2.39844"
                    stroke="#100837"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                {" "}
                <span className={styles.valueReps}>
                  {/* {globalTotalReps} */}
                  {reps}
                  <span> reps</span>
                </span>{" "}
                ,{" "}
                <span className={styles.valueReps}>
                  {/* {globalTotalWeight} */}
                  {weight}
                  <span> weight</span>
                </span>
              </div>
              <div>
                <span
                  className={styles.timeCounts}
                  style={{ color: "#1180EA" }}
                >
                  {
                    <Timer
                      sessionComplete={sessionComplete}
                      setupdatedTime={setupdatedTime}
                      maxTime={maxTime}
                    />
                  }
                </span>
              </div>
            </HeaderSection>
          </HeaderArea>
          <SectionContent>
            <div
              className="child2innerDiv"
              style={{ marginBottom: "0px", height: "54px" }}
            >
              <div className="cirlce" style={{ margin: "9px 0px" }}>
                {displayLetter}
              </div>
              <div
                className="w-100"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className={styles.sessionTitleDiv}>
                  <p className={styles.sessionTitle}>{Name}</p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="33"
                      viewBox="0 0 33 33"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_3214_42485)">
                        <path
                          d="M32.6029 9.46344C32.6029 6.65094 30.5341 4.38844 27.9779 4.38844C24.5154 4.22656 20.9841 4.16406 17.3754 4.16406H16.2504C12.6504 4.16406 9.11288 4.22656 5.65038 4.38906C3.10038 4.38906 1.03163 6.66406 1.03163 9.47656C0.87538 11.7009 0.80913 13.9259 0.81288 16.1509C0.80663 18.3759 0.877463 20.603 1.02538 22.8322C1.02538 25.6447 3.09413 27.9259 5.64413 27.9259C9.28163 28.0947 13.0129 28.1697 16.8066 28.1634C20.6066 28.1759 24.3275 28.0968 27.9691 27.9259C30.5254 27.9259 32.5941 25.6447 32.5941 22.8322C32.7441 20.6009 32.8129 18.3759 32.8066 16.1447C32.8208 13.9197 32.7529 11.6926 32.6029 9.46344ZM13.7504 22.2822V10.0009L22.8129 16.1384L13.7504 22.2822Z"
                          fill="#F50000"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3214_42485">
                          <rect
                            width="32"
                            height="32"
                            fill="white"
                            transform="translate(0.8125 0.164062)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <div
                    className={styles.historyDetails}
                    onClick={()=>setSessionHistory(true)}
                  >
                    <ZoomableImage
                      id="lighticon"
                      src={theme === "light" ? DetailsHistoy : DetailsHistoy}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.instructionArea}>
              <label className={styles.instructionLabel}>Instructions</label>
              <textarea
                type="text"
                placeholder="Search"
                value={Instruction}
                className={styles.instructionInput}
              />
            </div>
            <div style={{ width: "45vw" }}>
              <UserSessionDetails
              sessionId={sessionData?.id}
               setReps={setReps}
               setWeight={setWeight}
               setMaxTime={setMaxTime}
                handleSessionGetData={handleSessionGetData}
                indexChange={indexChange}
                // updateGlobalTotals={updateGlobalTotals}
                currentExerciseIndex={currentExerciseIndex}
                setRefresh={setRefresh}
                refresh={refresh}
                SelectedDate={SelectedDate}
                setUpdatedAllSets={setUpdatedAllSets}
                UpdatedAllSets={UpdatedAllSets}
                hit={`${time.minutes.toString().padStart(2, "0")}:${time.seconds
                  .toString()
                  .padStart(2, "0")}`}
                refreshCheck={refreshCheck}
                setchecking={setchecking}
                blockId={currentBlockId}
                selectedSets={selectedSets[CurrentExerciseid] || []}
                onSetSelection={handleSetSelection}
                setApiPersonalRecordResponse={setApiPersonalRecordResponse}
                setApiResponse={setApiResponse}
                getAtheleteSessionExercise={currentExercise?.AthltBlockSets}
                exerciseId={currentExercise?.athltExercises?.id}
                setTotalStats={setTotalStats}
                onExerciseDataUpdate={handleExerciseDataUpdate}
                setSumOfReps={setSumOfReps}
                setTotalWeight={setTotalWeight}
                sumOfReps={sumOfReps}
                totalWeight={totalWeight}
                globalTotalReps={globalTotalReps}
                setGlobalTotalReps={setGlobalTotalReps}
                globalTotalWeight={globalTotalWeight}
                setGlobalTotalWeight={setGlobalTotalWeight}
              />
            </div>
          </SectionContent>
          <FooterSection>
            <div
              onClick={handleBackClick}
              style={{
                width: "80px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.875 9.375H3.125C2.77982 9.375 2.5 9.65482 2.5 10C2.5 10.3452 2.77982 10.625 3.125 10.625H16.875C17.2202 10.625 17.5 10.3452 17.5 10C17.5 9.65482 17.2202 9.375 16.875 9.375Z"
                  fill="#A19686"
                />
                <path
                  d="M9.19171 4.81718C9.30892 4.69997 9.375 4.54076 9.375 4.375C9.375 4.20924 9.30915 4.05027 9.19194 3.93306L9.18655 3.92773C9.06983 3.814 8.91296 3.75 8.75 3.75C8.58424 3.75 8.42527 3.81585 8.30806 3.93306L2.68306 9.55806C2.56585 9.67527 2.5 9.83424 2.5 10C2.5 10.1658 2.56585 10.3247 2.68306 10.4419L8.30806 16.0669C8.42527 16.1841 8.58424 16.25 8.75 16.25C8.91576 16.25 9.07473 16.1841 9.19194 16.0669C9.30915 15.9497 9.375 15.7908 9.375 15.625C9.375 15.4592 9.30915 15.3003 9.19194 15.1831L4.00888 10L9.19171 4.81718Z"
                  fill="#A19686"
                />
              </svg>
              <span className={styles.backSessionPage}>Back</span>
            </div>
            <div onClick={handleManageTrainingModal}>
              <svg
                width="45"
                height="46"
                viewBox="0 0 45 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.5" width="44" height="46" rx="22" fill="#A19686" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M32.2717 21.7193C32.2717 21.7193 32.608 21.92 32.8022 22.2605C32.8022 22.2605 32.998 22.6039 32.998 22.9991C32.998 22.9991 32.998 23.3944 32.8022 23.7377C32.8022 23.7377 32.6064 24.081 32.2661 24.2822L18.785 32.5264C18.785 32.5264 18.4406 32.7389 18.0332 32.7479C18.0332 32.7479 17.6257 32.7569 17.2697 32.5585C17.2697 32.5585 16.9137 32.3601 16.7071 32.0088C16.7071 32.0088 16.5004 31.6575 16.5 31.2499V14.7491C16.5 14.7491 16.5004 14.3407 16.7071 13.9894C16.7071 13.9894 16.9137 13.6381 17.2697 13.4397C17.2697 13.4397 17.6257 13.2413 18.0332 13.2503C18.0332 13.2503 18.4406 13.2593 18.7875 13.4733L32.2717 21.7193ZM18 14.7499L31.4893 22.9992L18.0025 31.2468L18 31.2483L18 14.7499Z"
                  fill="white"
                />
              </svg>
            </div>
            <div
              onClick={handleNextClick}
              style={{
                width: "80px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <span
                className={styles.backSessionPage}
                style={{ marginRight: "5px" }}
              >
                Next
              </span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.125 10.625H16.875C17.2202 10.625 17.5 10.3452 17.5 10C17.5 9.65482 17.2202 9.375 16.875 9.375H3.125C2.77982 9.375 2.5 9.65482 2.5 10C2.5 10.3452 2.77982 10.625 3.125 10.625Z"
                  fill="#A19686"
                />
                <path
                  d="M15.9911 10L10.8081 15.1831C10.6908 15.3003 10.625 15.4592 10.625 15.625C10.625 15.7908 10.6908 15.9497 10.8081 16.0669C10.9253 16.1841 11.0842 16.25 11.25 16.25C11.4158 16.25 11.5747 16.1841 11.6919 16.0669L17.3169 10.4419C17.561 10.1979 17.561 9.80214 17.3169 9.55806L11.6919 3.93306C11.5747 3.81585 11.4158 3.75 11.25 3.75C11.0842 3.75 10.9253 3.81585 10.8081 3.93306C10.6908 4.05027 10.625 4.20924 10.625 4.375C10.625 4.54076 10.6908 4.69973 10.8081 4.81694L15.9911 10Z"
                  fill="#A19686"
                />
              </svg>
            </div>
          </FooterSection>
          {isManageTrainingModalOpen && (
            <ManageTarningModal
              onClose={() => setIsManageTrainingModalOpen(false)}
            />
          )}
          {isRestTimeModalOpen && (
            <RestTime
              isOpen={isRestTimeModalOpen}
              onClose={() => setIsRestTimeModalOpen(false)}
              isPerviousOpen={handleManageTrainingModal}
              onOpenCountDown={handleOpenCountDownModal}
              updateMinutes={setMinutes}
              updateSeconds={setSeconds}
              minutes={minutes}
              seconds={seconds}
            />
          )}
          {isArampModal && (
            <AMRAP
              isOpen={isArampModal}
              onClose={() => setArampModal(false)}
              isPerviousOpen={handleManageTrainingModal}
              onOpenCountDown={handleOpenCountDownModal}
              updateMinutes={setMinutes}
              updateSeconds={setSeconds}
              minutes={minutes}
              seconds={seconds}
            />
          )}
          {isForTime && <ForTime />}
          {isTabataTimer && <TabataTimer />}
          {isCountDownActive && (
            <CountdownModal
              isOpen={isCountDownActive}
              onClose={() => setIsCountDownActive(false)}
              isPerviousOpen={handleOpenRestTimeModal}
              minutes={minutes}
              seconds={seconds}
              onOpenRestTime={handleOpenRestTimeModal}
            />
          )}
          {isOpenArampModalCounter && (
            <ArampCounter
              isOpen={isOpenArampModalCounter}
              onClose={() => setOpenArampModalCounter(false)}
              isPerviousOpen={handleOpenRestTimeModal}
              minutes={minutes}
              seconds={seconds}
              onOpenRestTime={handleOpenRestTimeModal}
            />
          )}
          {isStopWatchRun && <ExerciseStopwatch />}
          {preCountdown > 0 && <Counter preCountdown={preCountdown} />}
          {istabataTimerCounter && <TabataTimerCounter />}
          {isOpenCustomInterval && <CustomInterval />}
          {isOpenCustomCounter && <CustomIntervalCounter />}
          {isOpenEmomTimer && <EmomTimer />}
          {isOpenEMOMTimerCounter && <EmomTimerCounter />}
          {isEndTraningSession && (
            <IntensityModal
            setFeedValue={setFeedValue}
              weight={weight}
              group={false}
              PerformBlock={PerformBlock}
              setSessionComplete={setSessionComplete}
              AtheleteExercise={AtheleteExercise}
              getAtheleteSessionExercise={getAtheleteSessionExercise}
              checking={checking}
              globalTotalWeight={globalTotalWeight}
              getCoachId={getCoachId}
              getSessionId={getSessionId}
              sessionTime={maxTime}
            />
          )}
          {isOpenPostFeedModal && <PostSessionFeedModal  reps={reps} weight={weight} />}
          {isOpenCreateFeed && (
            <ModalUi
              isOpen={isOpenCreateFeed}
              sessionId={getSessionId}
              coachId={getCoachId}
            />
          )}
        </>
      )}
    </>
  );
};

export default Session;
