import React, { useContext, useEffect, useState } from "react";
import Avtr from "../../../../assessts/images/athleteSource.png";
import { SessionContext } from "../../../../context/sessionContext";
import { getAtheleteGroups } from "../../../../apis";
import axios from "axios";
import { auth } from "../../../../firebase";
import moment from "moment";
import Loader from "../../../ReusableUi/Loader";
import SvgIcons from "../../../ReusableUi/SvgIcon";
import Skeleton from "react-loading-skeleton";
const ManageGroupTables = ({
  isLoader,
  isEmptyDataForGroups,
  isGroupData,
  handleGroupDetails,
}) => {
  const { handleOpenGroupHistory } = useContext(SessionContext);
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  const getImgSrc = (imageSrc) => {
    const binaryImage = imageSrc?.data;
    if (binaryImage) {
      const base64Image = arrayBufferToBase64(binaryImage);
      return `data:image/png;base64,${base64Image}`;
    }
    return null;
  };
  return (
    // <div className="businesscontainer">
   
      
    isLoader ? (
      <table>
        <tbody>
          {Array.from({ length: 4 }).map((_, index) => (
            <tr key={index} className="fade-in">
              <td><Skeleton height={50} width={200} style={{ marginTop: '10px' }} /></td>
              <td><Skeleton height={50} width={100} style={{ marginTop: '10px' }} /></td>
              <td><Skeleton height={50} width={150} style={{ marginTop: '10px' }} /></td>
              <td><Skeleton height={50} width={150} style={{ marginTop: '10px' }} /></td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : isEmptyDataForGroups ? (
      <div
        className="w-full d-flex flex-column justify-content-center align-items-center"
        style={{ height: "20vw" }}
      >
        <SvgIcons iconName="noSessionPlane" />
        <div className="w-100 d-flex flex-column justify-content-center">
          <h4 className="noSessionHeading d-flex justify-content-center">
            No Groups
          </h4>
          <div className="d-flex justify-content-center">
            {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
          </div>
        </div>
      </div>
    ) : (
      
      <table>
        <thead>
          <tr>
            <th
              style={{
                textAlign: "start",
                padding: "8px 32px",
                fontFamily: "Hurme Geometric Sans 2",
              }}
            >
              Group Name
            </th>
            <th
              style={{
                textAlign: "center",
                fontFamily: "Hurme Geometric Sans 2",
              }}
            >
              Planned Session
            </th>
            <th
              style={{
                textAlign: "center",
                fontFamily: "Hurme Geometric Sans 2",
              }}
            >
              Coach Name
            </th>
            <th
              style={{
                textAlign: "center",
                fontFamily: "Hurme Geometric Sans 2",
              }}
            >
              Date
            </th>
            <th
              style={{
                textAlign: "center",
                paddingRight: "1rem",
                fontFamily: "Hurme Geometric Sans 2",
              }}
            >
              Total Members
            </th>
            <th
              style={{
                textAlign: "end",
                paddingRight: "1rem",
                fontFamily: "Hurme Geometric Sans 2",
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody style={{ borderStyle: "none" }} className="tableBody">
          {isGroupData?.map((data, index) => (
            
            <tr className="mt-2 businessTablebody" key={index}>
              <td className="chk">
                <img width="48px" height="48px" src={getImgSrc(data?.file)} alt="Avatar" />
                <p
                  style={{
                    margin: 0,
                    fontFamily: "Hurme Geometric Sans 2",
                  }}
                >
                  {data?.groupName}
                </p>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontFamily: "Hurme Geometric Sans 2",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontFamily: "Hurme Geometric Sans 2",
                  }}
                >
                  {data.groupPrograms ? data.groupPrograms.length : "0"}
                </p>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontFamily: "Hurme Geometric Sans 2",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontFamily: "Hurme Geometric Sans 2",
                  }}
                >
                  {data?.grpCoach?.firstName} {data?.grpCoach?.lastName}
                </p>
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontFamily: "Hurme Geometric Sans 2",
                }}
              >
                {moment(data.createdAt).format("D/MM/YYYY")}
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontFamily: "Hurme Geometric Sans 2",
                }}
              >
                {data?.totalMembers}
              </td>
              <td
                style={{
                  textAlign: "center",
                  fontFamily: "Hurme Geometric Sans 2",
                }}
              >
                <div
                  className="view"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleGroupDetails(data)}
                >
                  <p>View</p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
    
        
     
    // </div>
  );
};

export default ManageGroupTables;
