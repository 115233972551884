import React from 'react';
import './atheleteExercise.css'
import CreateBlocks from '../../../../modules/CreateBlocks';
const AtheleteCreateExerciseModal = (props) => {
  const { isOpen, onClose } = props;

  return (
    <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`}>
      <div className="exercise-overlay" onClick={onClose}></div>
      <div className="exercisemmodalBody" style={{ width: "60%", maxWidth: "60%" }}>
        <CreateBlocks />
      </div>
    </div>
  );
};

export default AtheleteCreateExerciseModal;
