import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import SvgIcons from '../../ReusableUi/SvgIcon'

const CreateBlocks = () => {
  const [getExerciseList, setExerciseList] = useState([])
  useEffect(() => {
    try{

    }catch{
      
    }
  })
  return (
    <Container className="m-0 p-0">
      <Row className="m-0 p-0">
        <Col sm={12} md={12} lg={12} className="m-0 p-0">
          <Form.Group className="mb-3 text d-flex flex-column  align-items-end">
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: "100%", marginTop: '1rem' }}>
              <div className="exerciseNumbers">{"A"}</div>
              <Button className="customButton" >LeaderBoard</Button></div>
          </Form.Group>
          <Form.Group className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
            <div className="exerCat">
              <Form.Label style={{ fontSize: "16px" }}>Exercise Category</Form.Label>
              <Form.Select>
                <option>Power</option>
                <option>Power</option>
                <option>Power</option>
              </Form.Select>
            </div>
            <div className="exerAdd">
              <Form.Label style={{ fontSize: "16px" }}>Add Exercise</Form.Label>
              <Form.Select>
                <option>Power</option>
                <option>Power</option>
                <option>Power</option>
              </Form.Select>
            </div>
          </Form.Group>
          <Form.Group controlId="formBasic" className="mt-4">
            <Form.Label style={{ fontSize: "16px" }}>Instructions</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              // value={blockData.instructions || ""}
              // onChange={(e) => handleInstructionsChange(blockIndex, e.target.value)}
              placeholder="ExerciseInstruction"
            />
          </Form.Group>
          <Form.Group controlId="formBasic" className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
            <div className="videpurl mb-3">
              <Form.Label style={{ fontSize: "16px" }}>Video URL</Form.Label>
              <Form.Control
                style={{ background: "var(--neutral-color-grey-natural, #FAFAFA)" }}
                type="text"
                // value={blockData.videoURL || ""}
                // onChange={(e) => handleURLChange(blockIndex, e.target.value)}
                placeholder="www.youtube.com"
              />
            </div>
            <div className="exerciseSets">
              <div className="exerciseSetsHeader" style={{gap: "5px"}}>
                <div style={{width: "30%"}}>
                <h5
                  style={{
                    fontSize: "19px",
                    color: " var(--light-mode-secondary-232-d-42-text-1, #232D42)",
                  }}
                >
                  Sets
                </h5>
                </div>
                <div className="exerType" style={{width: "30%", gap: "5px"}}>
                  <h5>Reps </h5>
                  <SvgIcons iconName="dropDown" size={14} />
                </div>
                <div className="exerType" style={{width: "30%", gap: "5px"}}>
                  <h5>Wegith </h5>
                  <SvgIcons iconName="dropDown" size={14} />
                </div>
              </div>

              <div >
                <div className="exerBodyx mt-0">
                  <div className="frstDv" style={{width: "30%"}}>
                    <input
                      type="text"
                    // name={`set-${set.id}`}
                    // value={set.title}
                    />
                  </div>
                  <div className="frstDv" style={{width: "33%"}}>
                    <input
                      type="text"
                      name="reps"
                    // value={set.reps}
                    // onChange={(e) => handleSetChange(blockIndex, setIndex, 'reps', e.target.value)}
                    />
                  </div>
                  <div className="divchek" style={{width: "33%"}}>
                    <input
                      type="text"
                      name="weight"
                    // value={set.weight}
                    // onChange={(e) => handleSetChange(blockIndex, setIndex, 'weight', e.target.value)}
                    />
                  </div>
                </div>


              </div>
              <div className="iconicBtns"> <div className="plusMinus">
                <SvgIcons iconName="minusButton" size={16} />
              </div>
                <div className="pluscircle">
                  <SvgIcons iconName="plusButton" size={16} />
                </div>
              </div>
            </div>
          </Form.Group>
        </Col>
      </Row>
      <div className="sessionBtm mt-4" style={{ textAlign: "end" }}>
        <div className="pluscircle" >
          <SvgIcons iconName="plusButton" size={16} />
        </div>
        <div className="lastButtons mb-0">
          <Button className="customButton2">Cancel</Button>
          <Button className="customButton3">
            {'Save'}
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default CreateBlocks