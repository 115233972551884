import React from 'react'
import "./previewSession.css";

const PreviewSessionDate = () => {
  return (
    <div>
    <div className="innerChilds">
    <div className="first">
      <p>Mon</p>
      <p>08</p>
    </div>
    <div>
      <p>Tue</p>
      <p>08</p>
    </div>
    <div>
      <p>Wed</p>
      <p>08</p>
    </div>
    <div>
      <p>Thu</p>
      <p>08</p>
    </div>
    <div>
      <p>Fri</p>
      <p>08</p>
    </div>
    <div>
      <p>Sat</p>
      <p>08</p>
    </div>
    <div>
      <p>Sun</p>
      <p>08</p>
    </div>
  </div>
  </div>
  )
}

export default PreviewSessionDate
