import React, { useContext } from 'react'
import { SessionContext } from '../../../context/sessionContext'
import styled from 'styled-components';
import styles from "../../Common/Session/session.module.css";
import customcss from "./resttime.module.css"
import HandShakeImg from "../../../assessts/images/handshake.svg"

const HeaderSection = styled.div`
& > div:first-child  {
    display: flex;
    justify-content: space-between;
    height: 45px;
    & > div:nth-child(2) {
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
    }
   & >  div:nth-child(3) {
        display: flex;
        justify-content: end;
        padding: 10px 10px 0px 0px;
        & > div {
            width: 33.3px;
            cursor: pointer;
            display: flex;
            height: 33.3px;
            transform: rotate(-90deg);
            flex-shrink: 0;
            border-radius: 75.967px;
            background: rgba(38, 38, 38, 0.50);
            & > span {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
            }
        }
    }
}
`;
const Footer = styled.footer`
display: grid;
gap: 10px;
& > div:first-child {
    border-radius: 8px;
    background: var(--secondary-purple-a, #A19686);
    border-radius: 8px;
    background: var(--secondary-purple-a, #A19686);
    display: flex;
    width: 100%;
    height: 46px;
    padding: 12px 37px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    & > span {
        color: var(--primary-light, var(--white-100, #FFF));
        text-align: center;
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        /* 18 Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}
& > div:last-child {
    color: var(--secondary-purple-a, #A19686);
text-align: center;
font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
width: 100%;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 133.333% */
}
`;
const PostSessionFeedModal = ({isOpenPostFeed,reps,weight}) => {
    const { isOpenPostFeedModal, setOpenPostFeedModal, handleCreateFeed } = useContext(SessionContext)
  
    return (
        <div className={`exercise-modal ${isOpenPostFeedModal || isOpenPostFeed ? 'exercise-open w-100' : ''}`}>
            <div className="exercise-overlay" onClick={() => setOpenPostFeedModal(false)}></div>
            <div className="exercisemmodalBody" style={{ padding: "18px", width: "29%" }}>
                <HeaderSection>
                    <div>
                        <div></div>
                        <div>      <span className={styles.valueReps}>{reps}<span>reps</span></span>&nbsp;&nbsp;,&nbsp;&nbsp;<span className={styles.valueReps}>{weight} <span>reps</span></span></div>
                        <div>
                            <div onClick={() => setOpenPostFeedModal(false)}><span><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M4.85693 2.43839L8.97426 6.55572L13.0703 2.45972C13.1607 2.36342 13.2697 2.28638 13.3907 2.23323C13.5117 2.18008 13.6421 2.1519 13.7743 2.15039C14.0572 2.15039 14.3285 2.26277 14.5285 2.46281C14.7285 2.66285 14.8409 2.93416 14.8409 3.21706C14.8434 3.34783 14.8192 3.47774 14.7696 3.5988C14.7201 3.71986 14.6464 3.82952 14.5529 3.92106L10.4036 8.01706L14.5529 12.1664C14.7287 12.3384 14.8318 12.5713 14.8409 12.8171C14.8409 13.1 14.7285 13.3713 14.5285 13.5713C14.3285 13.7713 14.0572 13.8837 13.7743 13.8837C13.6383 13.8894 13.5027 13.8667 13.376 13.8171C13.2493 13.7675 13.1343 13.6921 13.0383 13.5957L8.97426 9.47839L4.8676 13.5851C4.77747 13.6781 4.6698 13.7525 4.5508 13.8037C4.4318 13.855 4.30382 13.8822 4.17426 13.8837C3.89136 13.8837 3.62005 13.7713 3.42001 13.5713C3.21998 13.3713 3.1076 13.1 3.1076 12.8171C3.10511 12.6863 3.12937 12.5564 3.1789 12.4353C3.22842 12.3143 3.30216 12.2046 3.3956 12.1131L7.54493 8.01706L3.3956 3.86772C3.21979 3.69573 3.1167 3.46283 3.1076 3.21706C3.1076 2.93416 3.21998 2.66285 3.42001 2.46281C3.62005 2.26277 3.89136 2.15039 4.17426 2.15039C4.43026 2.15359 4.6756 2.25706 4.85693 2.43839Z" fill="white" />
                            </svg></span></div></div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={HandShakeImg} />
                    </div>

                    <div className={customcss.postFeedsSection}>
                        <span>Want to post this session into Feed?</span>
                    </div>
                </HeaderSection>
                <Footer>
                    <div><span onClick={handleCreateFeed} style={{cursor: "pointer"}}>Yes</span></div>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div>
                            <svg width="78" height="2" viewBox="0 0 78 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="0.363281" y1="0.617188" x2="77.1432" y2="0.617191" stroke="#606160" />
                            </svg>
                        </div>
                        <div>
                            <span className={customcss.postFeedOrrButton}>
                                OR
                            </span>
                        </div>
                        <div>
                            <svg width="78" height="2" viewBox="0 0 78 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="0.363281" y1="0.617188" x2="77.1432" y2="0.617191" stroke="#606160" />
                            </svg>
                        </div>
                    </div>
                    <div><span>No</span></div>
                </Footer>
            </div>
        </div>
    )
}

export default PostSessionFeedModal