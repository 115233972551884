import React from 'react'
import AdminPanel from '../../pages/AdminPanel/admin';
import Invitation from '../../pages/Invitaion/invitation';
import AtheletePanel from '../../pages/AthletePanel/atheletePanel';
import SignUp from '../../pages/Signup/signup';
import Login from '../../pages/Login/login';
import { Route, Routes } from 'react-router-dom';
import Protected from './ProtectedRoutes';
import styled from 'styled-components';
import NavBar from '../../components/navbar/navBar';
import RefreshUrlPage from '../../components/coachPortal/returnUrl';
import CoachPortal from '../../pages/CoachPanel/Coach';
import NotFound from '../../layout/GuestUser/NotFound';
import Home from '../../pages/LandingPages/Pages/Home/Home';
import Coach from '../../pages/LandingPages/Pages/Coach/Coach';
import Athlete from '../../pages/LandingPages/Pages/Athlete/Athlete';
import Gym from '../../pages/LandingPages/Pages/Gym/Gym';
import MarketPlace from '../../pages/LandingPages/Pages/MarketPlace/MarketPlace';
import Programs from '../../pages/LandingPages/Components/Programs/Programs';
import Pricing from '../../pages/LandingPages/Pages/Pricing/Pricing';
const TestCheck = styled.div`
  display: flex;
  width: 100%;
  // overflow-y: auto;

  flex-direction: column;
`;
const AppRoutes = (props) => {

    const { currentUser, isInvitationRoute, checkPlan } = props;
  

    return (
        <>
            <TestCheck className='w-100'>

                {currentUser && window.location.pathname !== '/invitation/' && <NavBar />}
            
                <Routes>
                    {/* Coach Routes */}
                    <Route
                        path="/"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <CoachPortal />
                            </Protected>
                        }
                    />
                    <Route
                        path="/athelete"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <CoachPortal />
                            </Protected>
                        }
                    />
                    <Route
                        path="/group"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <CoachPortal />
                            </Protected>
                        }
                    />
                    <Route
                        path="/refresh"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <RefreshUrlPage />
                            </Protected>
                        }
                    />
                    <Route
                        path="/index"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <CoachPortal />
                            </Protected>
                        }
                    />
                    <Route
                        path="/analytics"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <CoachPortal />
                            </Protected>
                        }
                    />
                    <Route
                        path="/business"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <CoachPortal />
                            </Protected>
                        }
                    />
                    <Route
                        path="/customization"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <CoachPortal checkPlan={checkPlan} />
                            </Protected>
                        }
                    />
                    <Route
                        path="/customHomePage"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <CoachPortal />
                            </Protected>
                        }
                    />

                    <Route
                        path="/manage-athelete"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AdminPanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-coach"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AdminPanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-exercise"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AdminPanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-business"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AdminPanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-marketplace"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AdminPanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/invitation/"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <Invitation />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-athelete-traning"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AtheletePanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-athelete-feed"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AtheletePanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-athelete-groups"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AtheletePanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-athelete-index"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AtheletePanel />
                            </Protected>
                        }
                    />
                    <Route
                        path="/manage-athelete-chat"
                        element={
                            <Protected currentUser={currentUser} isLoggedIn={currentUser}>
                                <AtheletePanel />
                            </Protected>
                        }
                    />
                    {currentUser == null && (
                        <>
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/login" element={<Login />} />
                        </>
                    )}
                    <Route path="*" element={<NotFound />} />
                    <Route path="/landingHome" element={<Home />} />
                    <Route path="/landingCoach" element={<Coach />} />
                    <Route path="/landingAthlete" element={<Athlete />} />
                    <Route path="/landingGym" element={<Gym />} />
                    <Route path="/landingMP" element={<MarketPlace />} />
                    <Route path="/landingPricing" element={<Pricing />} />

                    <Route path="/MPprograms" element={<Programs />} />


                </Routes>

            </TestCheck>
        </>
    )
}

export default AppRoutes