import React from 'react'
import History from './History/History';
import WorkingMax from './WorkingMax/WorkingMax';

const SessionDetails = (props) => {
    const { getBlocksId, activeBtn, apiResponse, exerciseId,ApisavedRepMaxValues } = props;

    return (
        <>
            {activeBtn === "history" ? 
            (<>
                <History  exerciseId={exerciseId} />
            </>
            )
            :
            (
            <WorkingMax  ApisavedRepMaxValues={ApisavedRepMaxValues} getBlocksId={getBlocksId} exerciseId={exerciseId} apiResponse={apiResponse}  
            />
            )
            }
        </>
    )
}

export default SessionDetails