import React, { useContext, useEffect, useState } from "react";
import "./customization.css";
import { useTranslation } from "react-i18next";
import Tabs from "../../ReusableUi/Tabs";
import { Row, Col, Form, Button } from "react-bootstrap";
import { SketchPicker } from "react-color";
import SvgIcon from "../../../assessts/Svg/svgIcons";
import {
  darkThemeColor,
  defaultLightThemeColor,
  getCustomLightThemeColor,
  lightThemeColor,
} from "../../../services/store/coachApi";
import { auth } from "../../../firebase";
import axios from "axios";
import LoadingSpinner from "../../loadingSpinner";
import { toast } from "react-toastify";
import useThemeColors from "../../../hooks/useThemeColors";
import UpgradePlan from "../../ContentArea/UpgradePlan";


const Customization = (props) => {

  const { isProfessionalPlan, isGrowthPlan } = props;
  console.log(isProfessionalPlan, isGrowthPlan)
  const { t } = useTranslation();
  const [displayColorPicker, setDisplayColorPicker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultColorsLoading, setDefaultColorsLoading] = useState(false);
  const [CustomLightColors, setCustomLightColors] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const themeColors = JSON.parse(localStorage.getItem('themeColors')) || {};
  console.log("Theme Colors from localStorage:", themeColors);

  const { primaryColor, primaryTextColor, secondaryColor, secondaryTextColor } = themeColors;

  const localStorageTheme = {
    primaryColor: primaryColor || undefined,
    secondaryColor: secondaryColor || undefined,
    primaryTextColor: primaryTextColor || undefined,
    secondaryTextColor: secondaryTextColor || undefined
  };

  console.log("LocalStorage Theme Object:", localStorageTheme);

  const [colors, setColors] = useState(() => {


    return primaryColor && secondaryColor && primaryTextColor && secondaryTextColor
      ? localStorageTheme
      : {
        primaryColor: "#F6F6F0",
        secondaryColor: "#A19686",
        primaryTextColor: "#000000",
        secondaryTextColor: "#606060",
      };
  });
  const [darkColors, setDarkColors] = useState({
    primaryColor: "#856DEC",
    // primaryBackgroundColor: "#ff8181",
    primaryTextColor: "#83df9b",
    secondaryColor: "#856DEC",
    // secondaryBackgroundColor: "#ff8181",
    secondaryTextColor: "#83df9b",
  });
  const [formData, setFormData] = useState({ file: "" });
  const [validationErrors, setValidationErrors] = useState({});
  const [authReady, setAuthReady] = useState(false);
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user);
    });

    return () => unregisterAuthObserver();
  }, []);

  useEffect(() => {
    if (authReady) {
      fetchCustomLightTheme();
    }
  }, [authReady]);

  useThemeColors(CustomLightColors);
  /**
   * @method {Api Calls For Post and Fetching Colors}
   */
  const fetchCustomLightTheme = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await makeApiCall(
        getCustomLightThemeColor,
        null,
        token,
        "GET"
      );
      setCustomLightColors(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const makeApiCall = async (endpoint, payload, token, method) => {
    console.log(payload);
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token },
    };
    return method === "POST"
      ? await axios.post(url, payload, config)
      : await axios.get(url, config);
  };

  const handleThemeSave = async (endpoint, colors, isDark = false) => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      isDark ? setIsLoading(true) : setIsLoading(true);

      // Trigger the API call to save the colors
      await makeApiCall(endpoint, colors, token, "POST");

      // Update the theme colors in the state immediately after saving
      if (isDark) {
        setDarkColors(colors);
      } else {
        setColors(colors);
        setCustomLightColors(colors); // Update the custom light colors for immediate application
      }

      // Ensure the theme is applied immediately
      // useThemeColors(colors);

      isDark ? setIsLoading(false) : setIsLoading(false);
      toast.success(
        isDark ? "Dark theme colors saved!" : "Light theme colors saved!"
      );
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleGetDefaultColors = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      setDefaultColorsLoading(true);
      const response = await makeApiCall(defaultLightThemeColor, null, token, "GET");
      console.log(response.data)
      const { primaryColor, secondaryColor, primaryTextColor, secondaryTextColor } = response.data
      const Theme = { primaryColor, secondaryColor, primaryTextColor, secondaryTextColor }

      setColors(Theme)
      setDefaultColorsLoading(false);
      toast.success("Default colors reset successfully!");
    } catch (err) {
      console.error(err);
    }
  };

  /// End Api Calls ////

  const handleTabSelect = (tabId) => setActiveTab(tabId);

  const handleColorChange = (color, colorType, isDark = false) => {
    if (isDark) {
      setDarkColors({ ...darkColors, [colorType]: color.hex });
    } else {
      setColors({ ...colors, [colorType]: color.hex });
    }
  };

  const tabs = [
    { id: "web", label: "Web" },
    { id: "app", label: "App" },
  ];

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, file: reader.result });
        setValidationErrors((prevErrors) => ({ ...prevErrors, file: null }));
      };
      reader.readAsDataURL(file);
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        file: "Image is required",
      }));
    }
  };

  const renderColorPicker = (colorType, isDark = false) =>
    displayColorPicker === colorType && (
      <div style={{ position: "absolute", zIndex: 2 }}>
        <div
          style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }}
          onClick={() => setDisplayColorPicker(null)}
        />
        <SketchPicker
          color={isDark ? darkColors[colorType] : colors[colorType]}
          onChange={(color) => handleColorChange(color, colorType, isDark)}
        />
      </div>
    );

  const renderColorBoxes = (isDark = false) => {
    const colorSet = isDark ? darkColors : colors;
    console.log(colorSet)
    const colorTypes = Object.keys(colorSet);
    console.log("colorTypes", colorTypes)

    return colorTypes.map((colorType) => (
      <Col key={colorType} className="pt-4">
        <div
          className="box box1"
          style={{ position: "relative", backgroundColor: colorSet[colorType] }}
        >
          <p className="box-text text-capitalize">
            {colorType
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, (str) => str.toUpperCase())
              .split(" ")
              .slice(0, 2)
              .join(" ")}
          </p>

          <p className="box-text">{colorSet[colorType]}</p>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setDisplayColorPicker(colorType)}
          >
            <SvgIcon iconName="editColor" size={33} />
          </div>
          {renderColorPicker(colorType, isDark)}
        </div>
      </Col>
    ));
  };



  return (
    <div className="customContainer position-relative" style={{
      backgroundColor: "#ffffff",
      ...(isProfessionalPlan || isGrowthPlan ? { overflow: "hidden" } : {  })
    }} >
      {isProfessionalPlan || isGrowthPlan ? (
        <UpgradePlan />
      ) : null}
      <div className="w-100 d-flex justify-content-center my-4">
        <Tabs isCustomComp={true} tabs={tabs} onTabSelect={handleTabSelect} />
      </div>
      <div className="customizeAddress">
        <h4>Web Address</h4>
        <Form.Group controlId="frmB" style={{ width: "40%" }}>
          <Form.Control
            type="text"
            placeholder="Enter Group Name"
            name="groupName"
            className="customInput"
          />
        </Form.Group>

        <Form.Group style={{ marginTop: "2rem" }}>
          <Form.Label className="upload">Logo</Form.Label>
          <div className="upload-container">
            <div className="drag-drop-text">
              <p>Drag & Drop your file here</p>
              <span style={{ marginRight: "5px" }}> or</span>
              <label htmlFor="fileInput" className="browse-text">
                Browse
              </label>
              <span>to upload</span>
            </div>
            <input
              type="file"
              id="fileInput"
              className="file-input"
              onChange={handleFileUpload}
            />
            {formData.file && (
              <img
                src={formData.file}
                alt="Selected"
                className="selected-photo"
              />
            )}
          </div>
          {validationErrors.file && (
            <p style={{ color: "red", marginTop: "5px" }}>
              {validationErrors.file}
            </p>
          )}
        </Form.Group>

        <Row className="d-flex flex-column mt-4">
          <Col
            style={{ width: "60%", alignItems: "center" }}
            className="d-flex justify-content-between"
          >
            <h4>Colors</h4>
            <Button
              style={{
                backgroundColor: `var(--secondary-color,#A19686)`,
                color: `var(--secondary-text-color,white)`,
                height: "48px",
                width: "233px",
                borderRadius: "8px",
                border: "none",
                fontSize: "19px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
              onClick={handleGetDefaultColors}
            >
              {defaultColorsLoading ? (
                <LoadingSpinner size="sm" />
              ) : (
                "Get Default Colors"
              )}
            </Button>
          </Col>

          <Col>
            <p className="mode">Light Mode</p>
          </Col>
          <Row style={{ width: "50%" }}>{renderColorBoxes()}</Row>
          <Col
            xs={12}
            md={12}
            lg={12}
            style={{ width: "60%" }}
            className="d-flex justify-content-end mt-4"
          >
            <Button
              style={{
                backgroundColor: `var(--secondary-color,#A19686)`,
                color: `var(--secondary-text-color,white)`,
                height: "48px",
                width: "233px",
                borderRadius: "8px",
                border: "none",
                fontSize: "19px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
              onClick={() => handleThemeSave(lightThemeColor, colors)}
            >
              {isLoading ? <LoadingSpinner size="sm" /> : "Save Light Theme"}
            </Button>
          </Col>
        </Row>
      </div>



    </div>
  );
};

export default Customization;
