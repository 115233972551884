import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";

import { toast } from "react-toastify";
import "./atheleteCalender.css";
import {
  DeleteGroupBlockSession,
  DeleteGroupSession,
  DeleteIndexSession,
  DeleteIndexSessionBlock,
  DeleteSession,
  DeleteSessionBlockAthelete,
} from "../../../../services/store/coachApi";
import { auth } from "../../../../firebase";
import LoadingSpinner from "../../../loadingSpinner";

const DeleteSessonModel = ({
  RemoveBlock,
  SessionID,
  removeSessionBlock,
  isOpen,
  onClose,
  Id,
  GroupSession,
  activeTab,
  setRemoveBlock,
}) => {
  console.log(activeTab, "activeTab");
  const [isLoading, setIsLoading] = useState(false);
  const handleApiCall = async (endpoint, id, method = "delete") => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}${id}`;
    const token = await auth.currentUser.getIdToken(true);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(url, "url");
    console.log(config, "config");
    return await axios[method](url, config);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      let endpoint = null;
      if (activeTab === "athelete") {
        endpoint = `${DeleteSession}?`;
      } else if (activeTab === "deleteAtheletBlock") {
        endpoint = `${DeleteSessionBlockAthelete}?`;
      } else if (activeTab === "group") {
        endpoint = `${DeleteGroupSession}?`;
      } else if (activeTab === "deleteGroupBlock") {
        endpoint = `${DeleteGroupBlockSession}?`;
      } else if (activeTab === "index") {
        endpoint = `${DeleteIndexSession}?`;
      } else if (activeTab === "deleteindexBlock") {
        endpoint = `${DeleteIndexSessionBlock}?`;
      }
      console.log(activeTab,"activeTab")
      const id =
        activeTab === "athelete" ||
        activeTab === "group" ||
        activeTab === "index"
          ? `sessionId=${Id}`
          : activeTab === "deleteGroupBlock"
          ? `sessionId=${SessionID}&blockId=${Id}`
          : `blockId=${Id}`;
      const response = await handleApiCall(endpoint, id);
      if (response?.data?.message === "Block deleted successfully") {
        setRemoveBlock(!RemoveBlock);
      }
      toast.success(`Deleted Successfully!`);
      onClose();
    } catch (err) {
      console.error("Error:", err);
      toast.error("Failed to delete. Please try again.");
      onClose();
    } finally {
      setIsLoading(false);
    }
  };
  return isOpen ? (
    <div className="exercise-modal exercise-open">
      <div className="exercise-overlay" onClick={onClose}></div>
      <div
        className="exercisemmodalBody text-center"
        style={{
          width: "35%",
          padding: "20px",
          backgroundColor: `var(--secondary-bg-color,white)`,
        }}
      >
        <h2 style={{ color: `var(--primary-text-color,black)` }}>
        {`Are you sure you want to delete this  ${activeTab === "athelete" || activeTab === "group" || activeTab === "index"  ? "Session?" :  "Block?"  }`}  
        </h2>
        <div className="d-flex justify-content-around p-3">
          <Button
            variant="light"
            onClick={onClose}
            style={{
              width: "30%",
              backgroundColor: `var(--secondary-bg-color,#f8f9fa)`,
              color: `var(--primary-text-color,black)`,
            }}
          >
            No
          </Button>
          <Button
            variant="danger"
            onClick={handleDelete}
            style={{ width: "30%" }}
          >
            {isLoading ? <LoadingSpinner size="sm" /> : "Yes"}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};
export default DeleteSessonModel;
