import React, { useContext, useState } from "react";
import styles from "./index.module.css";
import { SessionContext } from "../../../context/sessionContext";
import ActiveTab from "./ActiveIndex/activeTab";
import ProgramDetails from "./ActiveIndex/Program/ProgramDetails/programDetails";
import SessionDetails from "./ActiveIndex/Session/SessionDetails/sessionDetails";
import HistorySession from "../../Common/HistorySession/HistorySession";
import IndexSessionDetails from "../../modules/AtheleteIndex/IndexSession/Details/IndexSessionDetails";
const ManageIndex = () => {
  const {
    isActiveIndex,
    setActiveIndex,
    isProgramDetails,
    isSessionDetail,
  } = useContext(SessionContext);
  const [sessionHistory,setSessionHistory]=useState()
  return (
    <div
      className={styles.manageIndexDiv}
      style={{ height: "calc(100vh - 90px)" }}
    >
      {isProgramDetails ? (
        <>
          <ProgramDetails />
        </>
      ) : isSessionDetail ? (
        <IndexSessionDetails />
      ) : sessionHistory ? (
        <HistorySession setSessionHistory={setSessionHistory}  />
      ) : (
        <>
          <div
            className="businessHeaderbuttons"
            style={{ margin: "34px 48px", width: "400px" }}
          >
            <div
              id={isActiveIndex === "program" && "activeButton"}
              style={{ width: "25%" }}
            >
              <button variant="text" onClick={() => setActiveIndex("program")}>
                <span className={styles.indexTitle}>Program</span>
              </button>
            </div>
            <div
              style={{ width: "25%" }}
              id={isActiveIndex === "session" && "activeButton"}
            >
              <button onClick={() => setActiveIndex("session")}>
                <span className={styles.indexTitle}>Session</span>
              </button>
            </div>
            <div
              style={{ width: "25%" }}
              id={isActiveIndex === "exercise" && "activeButton"}
            >
              <button onClick={() => setActiveIndex("exercise")}>
                <span className={styles.indexTitle}>Exercise</span>
              </button>
            </div>
            <div
              style={{ width: "25%" }}
              id={isActiveIndex === "circuit" && "activeButton"}
            >
              <button onClick={() => setActiveIndex("circuit")}>
                <span className={styles.indexTitle}>Circuit</span>
              </button>
            </div>
          </div>
          <ActiveTab setSessionHistory={setSessionHistory} activeIndex={isActiveIndex} />
        </>
      )}
    </div>
  );
};

export default ManageIndex;
