import React, { useContext, useState } from "react";
import Communication from "../../../../assessts/icons/Communication.png";
import Invite from "../../../../assessts/icons/invite.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Calender from "../../../../assessts/icons/Time.png";
import Av from "../../../../assessts/images/avtr.png";
import { useTranslation } from "react-i18next";
import "./table.css";
import SvgIcon from "../../../../assessts/Svg/svgIcons";
import CustomPagination from "../../../Common/Pagination/pagination";
import { ThemeContext } from "../../../../context/themeContext";
import SvgIcons from "../../../ReusableUi/SvgIcon";
import CreateGroupModal from "../../../modals/createGroupModal";
import Tooltip from "../../../ReusableComponent/Tooltip";
const AtheleteTable = ({ ...props }) => {
  const {
    setSelectedData,
    isEmptyData,
    handleActivePaginationGroup,
    setGetGroupDetails,
    isPaginataionGroups,
    refreshGroups,
    isTotalPages,
    setShow,
    groupList,
    loader,
    setSelectedGroup,
    selectedGroup,
    activeCheckBox,
    setEditGroup,
    isEditGroup,
    openInviteModal,
    setRefresh,
    refresh
  } = props;
  const [isOpenCreateGroupModal, setOpenCreateGroupModal] = useState(false);
  const { setActiveTab, setActiveChat, setIsGroupChat } =
    useContext(ThemeContext);
  console.log(groupList);
  const handleEdit = (data) => {
    setEditGroup(data);
    setOpenCreateGroupModal(!isOpenCreateGroupModal);
   
  };
  const handleClose = () => {
    setOpenCreateGroupModal(false);
    setRefresh(!refresh)
  };
  const handleDeleteCheckBox = (groupId) => {
    setSelectedGroup((prevSelectedPrograms) => {
      if (prevSelectedPrograms.includes(groupId)) {
        return prevSelectedPrograms.filter((id) => id !== groupId);
      } else {
        return [...prevSelectedPrograms, groupId];
      }
    });
  };

  const handleCreateSession = (data,groupData) => {
    setSelectedData(groupData)
    setGetGroupDetails(data);
    setShow(true);
  };

  const handleChatOpen = (group) => {
    setActiveTab("coach");
    setIsGroupChat(true);
    setActiveChat(group);
  };

  const { t } = useTranslation();
  return (
    <>
      {loader ? (
        <table>
          <tbody>
            {Array.from({ length: 4 }).map((_, index) => (
              <>
                <br />
                <tr key={index} >
                  <td>
                    <Skeleton
                   
                      height={50}
                      width={200}
                      style={{ marginTop: "10px" }}
                    />
                  </td>
                  <td>
                    <Skeleton
                     
                      height={50}
                      width={100}
                      style={{ marginTop: "10px" }}
                    />
                  </td>
                  <td>
                    <Skeleton
                  
                      height={50}
                      width={150}
                      style={{ marginTop: "10px" }}
                    />
                  </td>
                  <td>
                    <Skeleton
                   
                      height={50}
                      width={150}
                      style={{ marginTop: "10px" }}
                    />
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      ) : isEmptyData ? (
        <div
          className="w-full d-flex flex-column justify-content-center align-items-center"
          style={{ height: "20vw" }}
        >
          <SvgIcons iconName="noSessionPlane" />
          <div className="w-100 d-flex flex-column justify-content-center">
            <h4 className="noSessionHeading d-flex justify-content-center">
              No Groups
            </h4>
            <div className="d-flex justify-content-center">
              {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="table-container">
          <table>
            <thead className="tablehead">
              <tr>
                <th
                  style={{
                    textAlign: "start",
                    padding: "10px 0px 10px 23px",
                    fontWeight: "700",
                    paddingLeft: "1rem",
                    fontSize: "14px",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  {" "}
                  Group Name
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "700",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  {t("Planned session")}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "700",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  {t("Sport")}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "700",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  {t("Actions")}
                </th>
                <th
                  style={{
                    textAlign: "start",
                    padding: "10px",
                    fontWeight: "700",
                    paddingRight: "1rem",
                    fontSize: "14px",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Athletes
                </th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {groupList.map((data, index) => (
                <tr
               
                  key={index}
               
                  style={{ margin: "10px 0px" }}
                >
                  <td className="chk">
                    {activeCheckBox && (
                      <label className="containerInput">
                        <input
                          type="checkbox"
                          checked={selectedGroup.includes(data.id)}
                          onChange={() => handleDeleteCheckBox(data.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    )}
                    {/* <img id='chkImage' width="48px" height="48px" src={data.Avtr} /> */}
                    <p
                      className="chkname"
                      id="as"
                      style={{
                        margin: 0,
                        fontSize: "16px",
                        fontFamily: "Hurme Geometric Sans 2",
                       
                      }}
                    >
                      {data.groupName ? (
                        data.groupName
                      ) : (
                        <Skeleton width={100} />
                      )}
                    </p>
                  </td>
                  <td>
                    <p
                      className="chkname"
                      id="as"
                      style={{
                        margin: 0,
                        textAlign: "center",
                        fontSize: "16px",
                        fontFamily: "Hurme Geometric Sans 2",
                        
                      }}
                    >
                      {data?.groups1 ? (
                        data?.groups1?.length
                      ) : (
                        0
                      )}
                    </p>
                  </td>
                  <td>
                    <p
                      className="chkname"
                      id="as"
                      style={{
                        margin: 0,
                        textAlign: "center",
                        fontSize: "16px",
                        fontFamily: "Hurme Geometric Sans 2",
                       
                      }}
                    >
                      {data.sportsType ? (
                        data.sportsType
                      ) : (
                        <Skeleton width={100} />
                      )}
                    </p>
                  </td>
                  <td>
                    <div
                      className="icongap"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingRight: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      <div  onClick={() => handleCreateSession(data.id,data)} >
                      <Tooltip children={
                         <img
                         id="tableicon"
                         className="athttblimg"
                        
                         src={Calender}
                         alt="Calendar"
                       />
                      }  content={"View Calendar"} />
                     </div>
                     <div onClick={() => handleChatOpen(data)} >
                      <Tooltip children={
                       <img
                       id="tableicon"
                       className="athttblimg"
                       src={Communication}
                       
                       alt="Communication"
                     />
                      }  content={"Send Message"} />
                     </div>
                     <div   onClick={() => handleEdit(data)}>
                      <Tooltip children={
                       <div className="indexEdit">
                       <SvgIcon
                         iconName="editGroup"
                         size={16}
                        
                       />
                     </div>
                       
                      }  content={"Edit Group"} />
                     </div>
                     <div   onClick={() => openInviteModal(data)}>
                      <Tooltip children={
                         <img
                         style={{ cursor: "pointer" }}
                         id="tableicon"
                         className="athttblimg"
                         src={Invite}
                        
                         alt="Invite"
                       />
                      }  content={"Invite Athelete"} />
                     </div>
                      
                      
                     
                    </div>
                  </td>
                  <td>
                    <div className="groupAthAv" style={{ textAlign: "center" }}>
                      <img
                        className="groupAthAv2"
                        src={Av}
                        alt="Athlete Avatar"
                      />
                      <div style={{ color: "black" }}>
                        {data.groups ? (
                          data.groups.length
                        ) : (
                          <Skeleton width={30} />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <CustomPagination
        currentPage={isPaginataionGroups}
        totalPages={isTotalPages}
        onPageChange={handleActivePaginationGroup}
      />
      {isOpenCreateGroupModal && (
        <CreateGroupModal
          isOpen={isOpenCreateGroupModal}
          onClose={handleClose}
          isEditGroup={isEditGroup}
          setEditGroup={setEditGroup}
          refreshGroups={refreshGroups}
        />
      )}
    </>
  );
};

export default AtheleteTable;
