import { useState, useEffect } from 'react';
import { auth } from '../firebase';

const UseCheckRole = () => {
  const [role, setRole] = useState("");
  const [userName, setUserName] = useState("");

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const fullName = user.displayName;
        setUserName(fullName);
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.isAdmin) {
          setRole("Admin");
        } else if (idTokenResult.claims.isCoach) {
          setRole("Coach");
        } else {
          setRole("Athlete");
        }
      } else {
        setUserName("");
        setRole("");
      }
    });

    return () => unsubscribe();
  }, []);

  return { role, userName };
};

export default UseCheckRole;
