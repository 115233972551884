import React from 'react'
import { ProgramCoach, RoundFB, RoundInsta, RoundSite, RoundTwitter, RoundYT } from '../../../../../../assessts/LandingPage'
import style from './Coach.module.css'

const CoachDes = () => {
    return (
        <div className={`${style.main} d-flex align-items-center justify-content-center`}>
            <div className={`${style.TextDIV} d-flex flex-column`}>
                <h1>Coach Description</h1>
                <h2>Hairy Smith</h2>
                <h5>Instructure</h5>
                <p>Lorem ipsum dolor sit amet consectetur. At viverra suspendisse tortor velit accumsan. Faucibus aenean vitae magna porttitor quisque egestas risus vitae. Cursus purus vel eleifend suspendisse mauris tempus tortor. Vitae at ut at lacinia et. Amet facilisi odio malesuada dictum libero orci sapien odio sollicitudin. Egestas egestas aliquet pellentesque amet orci tortor augue vel. Aliquam eget gravida mi lacus leo duis vulputate est. Sagittis</p>
                <div className={`${style.socials} d-flex align-items-center`}>
                    <img src={RoundSite} alt="" />
                    <img src={RoundFB} alt="" />
                    <img src={RoundInsta} alt="" />
                    <img src={RoundTwitter} alt="" />
                    <img src={RoundYT} alt="" />
                </div>
            </div>
            <div className={`${style.picture}`}>
                <img src={ProgramCoach} alt="" />
            </div>
        </div>
    )
}

export default CoachDes
