//Athelete Api 
export const getAthelete = "/api/v1/admin/viewAthletes";
export const deleteAthelete = "/api/v1/admin/deleteAthlete";
export const getAtheleteById = "/api/v1/admin/getAthleteById";
export const blockAthelete = "/api/v1/admin/blockAthlete";

//Manage Exercise
export const createAdminExercise = "/api/v1/admin/createAdminExercise";
export const getExercises = "/api/v1/admin/viewAdminExercises";
export const editAdminExercise = "/api/v1/admin/editAdminExercise";
export const deleteExercise = "/api/v1/admin/deleteAdminExercise";

//Manage Market Place
export const veiwMarketPlace = "/api/v1/admin/viewPrograms";
export const getProgram = "/api/v1/admin/getProgramById";
export const editAdminProgram = "/api/v1/admin/editProgram";
export const deleteProgram = "/api/v1/admin/deleteProgram";