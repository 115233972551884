import React from 'react'
import style from './Bundle.module.css'
import { BodyBuildingProgramPic, BundleUser1, BundleUser2 } from '../../../../../../assessts/LandingPage'
import Button from '../../../Button/Button'

const Bundle = () => {
    return (
        <div className={`${style.main} d-flex`}>
            <div className={`${style.pictures} d-flex flex-column justify-content-between`}>
                <img src={BodyBuildingProgramPic} alt="" />
                <div className={`${style.bundleUsers} d-flex align-items-center position-relative`}>
                    <img src={BundleUser1} className='position-absolute' alt="more" />
                    <img src={BundleUser2} className='position-absolute' alt="more" />
                    <p className='position-absolute'>+3</p>
                </div>
            </div>
            <div>
                <div className={`${style.TextDIV} d-flex flex-column`}>
                    <h2>Bodybuilding</h2>
                    <h1>Full Gym Physique Bundle</h1>
                    <h5>Laurie King (LCK)</h5>
                    <p>Lorem ipsum dolor sit amet consectetur. At viverra suspendisse tortor velit accumsan. Faucibus aenean vitae magna porttitor quisque egestas risus vitae. Cursus purus vel eleifend suspendisse mauris tempus tortor. Vitae at ut at lacinia et. Amet facilisi odio malesuada dictum libero orci sapien odio sollicitudin. Egestas egestas aliquet pellentesque amet orci tortor augue vel. Aliquam eget gravida mi lacus leo duis vulputate est. Sagittis sed nisi semper Aliquam eget gravida mi lacus leo duis vulputate est. Sagittis sed nisi semper  Aliquam eget gravida mi lacus leo duis vulputate est. Sagittis sed nisi semper </p>
                </div>
                <div className={`${style.subscription} d-flex flex-column`}>
                    <div>
                        <h5>Subscription</h5>
                        <h1>$49/mo</h1>
                        <Button bg={true} title="Buy Now" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bundle
