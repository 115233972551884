import React, { useEffect, useState } from 'react';
import './marketplace.css';
import { Container, Row, Col } from 'react-bootstrap';
import cardImg1 from "../../../assessts/images/cardimg1.png";
import { getMarketPlaceData } from '../../../apis';
import axios from 'axios';
import { auth } from '../../../firebase';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../loadingSpinner';
import SvgIcons from '../../ReusableUi/SvgIcon';

const Marketplace = ({ authReady, coachName }) => {
  const [loader, setLoader] = useState(false);
  const [getMarketPlacesList, setMarketPlaceList] = useState([]);
  const [IsEmptyData,setIsEmptyData]=useState(false)
  const getMarketPlaces = async () => {
    setLoader(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getMarketPlaceData}?currentPage=1&itemsPerPage=10`,
        { headers: { Authorization: token } }
      );
      const getListMarketPlace = response.data.marketplacePrograms;
      setMarketPlaceList(getListMarketPlace);
      { getListMarketPlace.length <=0 ? setIsEmptyData(true): IsEmptyData(false)  }
    } catch (error) {
      console.error("Error fetching data:", error);
      // toast.error("Failed to fetch groups.");
    }
    setLoader(false);
  };

  useEffect(() => {
    if (authReady) {
      getMarketPlaces();
    }
  }, [authReady]);
  // console.log(getMarketPlacesList,"getMarketPlacesList")
  return (
    <Container>
      <Row>
        <div className='Marketplace' style={{height : "65vh"}} >
          {loader ? (
            <div style={{ display: "flex", justifyContent: "center", aliginItem: "center", width: "100%", height: "100vh" }}>
            <LoadingSpinner size="lg" >
            </LoadingSpinner>
          </div>
          ) : IsEmptyData ?  (
            <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "20vw",width : "100%" }}
            
          >
            <SvgIcons iconName="noSessionPlane" />
            <div className="w-100 d-flex flex-column justify-content-center">
              <h4 className="noSessionHeading d-flex justify-content-center">
                No MarketPlace
              </h4>
              <div className="d-flex justify-content-center">
                {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
              </div>
            </div>
          </div>
          )
          
          : (
            getMarketPlacesList.map((data, index) => {
              return (
                <Col xs={12} md={3} key={index} className="d-flex justify-content-center">
                  <div className='marketplaceCard'>
                    <div className='marketplaceCardHeader'>
                      <h4>{data.title}</h4>
                      <p>{coachName}</p>
                    </div>
                    <img className='cardImg' src={data?.Picture} alt="" />
                    <div className='cardsub mt-2'>
                      <p>Subscription</p>
                      <h4>${data.fee}/mo</h4>
                    </div>
                  </div>
                </Col>
              );
            })
          )}
        </div>
      </Row>
    </Container>
  );
}

export default Marketplace;
