// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../src/assessts/LandingPage/Coach/CoachMainBg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Program1_main__ky2bF{
    /* height: 533px; */
}

.Program1_bgIMG__CL6Cx {
    background-image:
      url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 40vh;
    padding-top: 100px;
    padding-bottom: 100px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/LandingPages/Components/Programs/Program1/Program1.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI;6CACyE;IACzE,4BAA4B;IAC5B,sBAAsB;IACtB,wBAAwB;IACxB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;EACvB","sourcesContent":[".main{\r\n    /* height: 533px; */\r\n}\r\n\r\n.bgIMG {\r\n    background-image:\r\n      url(\"../../../../../../src/assessts/LandingPage/Coach/CoachMainBg.png\");\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    background-position: top;\r\n    height: 40vh;\r\n    padding-top: 100px;\r\n    padding-bottom: 100px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Program1_main__ky2bF`,
	"bgIMG": `Program1_bgIMG__CL6Cx`
};
export default ___CSS_LOADER_EXPORT___;
