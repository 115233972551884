import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./auth.module.css"
const SignUpModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const navigateWithData = (route, data) => {
        navigate(route, { state: data });
    };
    return (
        <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`}>
            <div className="exercise-overlay" onClick={onClose}></div>
            <div className="exercisemmodalBody" style={{ width: "30%" }}>
                <div className={styles?.modalTitle}>
                    <h2>{'Sign Up As'}</h2>
                </div>
                <div className="businessHeaderbuttons w-100" style={{ margin: "20px auto" }}>
                    <div className={styles?.active} style={{ width: "50%" }}>
                        <button className={styles?.textHover} onClick={() => navigateWithData('/signup', { userType: 'coach' })}>
                            <span>
                                Sign Up As Coach
                            </span>
                        </button>
                    </div>
                    <div style={{ width: "50%" }} className={styles?.active}>
                        <button className={styles?.textHover} onClick={() => navigateWithData('/signup', { userType: 'athlete' })}>
                            <span>
                                Sign Up As Athlete
                            </span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SignUpModal;
