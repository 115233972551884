import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Program from "../../../../../assessts/images/indexCard.svg";
import styles from "../../index.module.css";
import { SessionContext } from '../../../../../context/sessionContext';
import { Col } from 'react-bootstrap';
import { auth } from '../../../../../firebase';
import axios from 'axios';
import { GetExercise } from '../../../../../services/store/atheleteApi';

const Card = styled.div`
    border-radius: 12px;
    border-bottom: 1px solid var(--secondary-purple-b, #EAE6E0);
    background: var(--white-100, #FFF);
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    width: 385px;
    height: 100.315px;
    flex-shrink: 0;
    margin-right: 38px;
    margin-bottom: 20px;
    cursor: pointer; 
`;

const CardBody = styled.div`
    padding: 19px 15px;
    display: flex;
`;

const CardImage = styled.div`
    width: 59px;
    height: 63px;
    flex-shrink: 0;
    border-radius: 9px;
    padding-right: 10px;
    & > img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 9px;
    }
`;

const sessions = [
    { title: "Session A", groupName: "Maki" },
    { title: "Session B", groupName: "Kara" },
    { title: "Session C", groupName: "Nina" },
    { title: "Session D", groupName: "Lara" },
    { title: "Session E", groupName: "Jade" },
];

const ExerciseIndex = ({setSessionHistory}) => {
  const [isLoading, setisLoading] = useState(false);
  const [IsEmptyData, setIsEmptyData] = useState(false);
  const [authReady, setAuthReady] = useState(false);
  const [Exercise, setExercise] = useState([]);
  const fetchExercise = async () => {
    try {
      setisLoading(true);
      const token = await auth.currentUser.getIdToken(true);
      const endpoint = GetExercise;
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${endpoint}`,
        { headers: { Authorization: token } }
      );
      // {
      //   response?.data?.message == "No exercises found for the logged-in user"
      //     ? setIsEmptyData(true)
      //     : setIsEmptyData(false);
      // }
      setExercise(response?.data?.exercise);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.error("Error fetching session data:", error);
    
    }
  };
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user);
    });

    return () => unregisterAuthObserver(); 
  }, []);
  useEffect(() => {
    if (authReady) {
      fetchExercise();
    }
  }, [authReady]);
  return (
    <>
      {Exercise.map((session, index) => (
        <Col key={index} md={6} lg={6} sm={12}>
          <Card>
            <CardBody onClick={()=>setSessionHistory(true)}>
              <CardImage>
                <img src={Program} alt="Program" />
              </CardImage>
              <div>
                <div className={styles.programTitle}>{session.title}</div>
                <div className="d-flex justify-content-between">
                  <span className={styles.groupName}>{session.groupName}</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
}

export default ExerciseIndex;
