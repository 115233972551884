import React, { useContext, useEffect, useState } from "react";

import "./calender.css"; // Create a CSS file for styling

import { toast } from "react-toastify";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { ThemeContext } from "../../../../context/themeContext";
import GroupSessionModal from "../../../Common/Form/GroupSessionForm/groupSessionForm";
import {
  copyCalenderSession,
  getAtheleteSessionCalender,
  publishSession,
  veiwGroupSession,
} from "../../../../services/store/coachApi";
import DropDown from "../../../ReusableUi/DropDown";
import Loader from "../../../ReusableUi/Loader";
import DeleteSessonModel from "../../../coachPortal/athelete/atheleteCalender/DeleteSessionModel";
import { postApiMethod } from "../../../../store/usePostApi";
import { getApiMethod } from "../../../../store/useGetApi";
import CreateSession from "../../Modal/CreateSession";
import SvgIcon from "../../../../assessts/Svg/svgIcons";
import LoadingSpinner from "../../../loadingSpinner";
import Tooltip from "../../../ReusableComponent/Tooltip";
const MonthlyCalender = (props) => {
  const {
    id,
    isOpenCalender,
    dropDownOption,
    tab,
    DelBlockType,
    SelectedData,
  } = props;

  const [isLoader, setLoader] = useState(false);
  const [monthlyData, setMonthlyData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null); // Track the selected date for event creation
  const [isOpenSession, setIsOpenSession] = useState(false);
  const [show, setShow] = useState(false);
  console.log(monthlyData, "monthlyData");
  const [refreshData, setRefreshData] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  // State to track the currently displayed month
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [SessionData, SetSessionData] = useState();
  const [EditIsOpen, setEditIsOpen] = useState(false);
  const [DeleteIsOpen, setDeleteIsOpen] = useState(false);
  const [IsDelete, setIsDelete] = useState(false);
  const [GroupSession, setGroupSession] = useState(false);
  const [copiedSessions, setCopiedSessions] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const { activeTab, setActiveTab, setActiveChat, setIsGroupChat } =
    useContext(ThemeContext);
  const [isSelectedSessionIds, setSelectedSessionIds] = useState([]);
  const [selectedSession, setSelectedSession] = useState({});
  const [ShowDetails, setShowDetails] = useState(false);
  const [isPublishForm, setPublishForm] = useState({
    ids: [],
    sessionType: activeTab === "group" ? "group" : "athlete",
  });
  // Get Id from Data Where Published is False
  useEffect(() => {
    const sessionIds = Object.values(monthlyData)
      .flat()
      .filter((session) => session.published === false)
      .map((session) => session.id);

    setPublishForm({
      ids: sessionIds,
      sessionType: activeTab === "group" ? "group" : "athlete",
    });
  }, [monthlyData, activeTab]);

  console.log(isPublishForm);

  const closeSessionForm = () => {
    setRefreshData(!refreshData);
    setIsOpenSession(false);
  };
  const handleRightClick = (event, session, date) => {
    event.preventDefault(); // Prevent default context menu

    // Set the selected session state
    setSelectedSessionIds(session);

    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handlePastRightClick = async (event, date) => {
    event.preventDefault(); // Prevent default context menu

    // Extract ids from the session if available
    const sessionIds = isSelectedSessionIds?.map((s) => s.id) || [];

    // Update the selected session state with the response data, if necessary
    setSelectedSession({
      ids: sessionIds,
      pasteDate: date,
      sessionType: activeTab === "group" ? "group" : "athlete",
    });

    // Open the context menu
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  };

  // Function to handle copying sessions
  const handleCopyIndex = (index) => {
    setCopiedSessions(isSelectedSessionIds);
    closeContextMenu();
    console.log(`Copied session for index ${index}:`, selectedSession);
  };

  // Function to handle pasting sessions into a new date
  const handlePasteSession = async (date) => {
    if (!copiedSessions) {
      toast.error("No session to paste");
      return;
    }

    // Define the endpoint for copying/pasting the session
    const endpoint = copyCalenderSession; // Replace with your actual endpoint

    try {
      // Make the API request using postApiMethod
      const response = await postApiMethod(endpoint, selectedSession, "post");

      console.log("API Response Data:", response);
    } catch (error) {
      console.error("Error in pasting session:", error);
    }

    // Add copied session to the selected date
    const newMonthlyData = { ...monthlyData };
    console.log(newMonthlyData);
    newMonthlyData[date] = (newMonthlyData[date] || []).concat(copiedSessions);

    setMonthlyData(newMonthlyData);
    toast.success(`Pasted session to ${date}`);
    closeContextMenu();
  };

  // Function to close context menu
  const closeContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  // Close context menu when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu.visible) {
        closeContextMenu();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);

  // Function to switch to the previous month
  const goToPreviousMonth = () => {
    const prevMonth = new Date(currentMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setCurrentMonth(prevMonth);
  };

  // Function to switch to the next month
  const goToNextMonth = () => {
    const nextMonth = new Date(currentMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setCurrentMonth(nextMonth);
  };

  const processResponseData = (responseData) => {
    const data = {};
    responseData.sessions.forEach((item) => {
      const dateKey = item.date.split("T")[0]; // Extract just 'YYYY-MM-DD'

      if (!data[dateKey]) {
        data[dateKey] = [];
      }

      data[dateKey].push(item); // Group all events by the date
    });

    return data;
  };

  const handlePublish = async () => {
    try {
      setLoading(true);
      const endpiont = publishSession;
      const response = await postApiMethod(endpiont, isPublishForm);

      toast.success("The Session Has Been Published Successfully...");
      setLoading(false);
    } catch (err) {
      toast.success("Already All Session is Published");
      setLoading(false);
    }
  };

  const fetchMonthData = async () => {
    setLoader(true);
    const firstDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    const formattedDate = `${firstDayOfMonth.getFullYear()}-${String(
      firstDayOfMonth.getMonth() + 1
    ).padStart(2, "0")}-${String(firstDayOfMonth.getDate()).padStart(2, "0")}`;

    const idToUse =
      activeTab === "group" ? selectedOption || id : selectedOption || id;
    const endpoint = `${
      activeTab === "group" ? veiwGroupSession : getAtheleteSessionCalender
    }?date=${formattedDate}&${
      activeTab === "group" ? "groupId" : "athleteId"
    }=${idToUse}`;

    try {
      const data = await getApiMethod(endpoint, "get");
      console.log("Fetched data:", data); // Debug log
      const processedData = processResponseData(data);
      setMonthlyData(processedData || {});
    } catch (error) {
      toast.error(error.message || "An error occurred while fetching data.");
      console.error("Error fetching month data:", error);
    } finally {
      setLoader(false); // Always hide loader
    }
  };

  useEffect(() => {
    fetchMonthData();
  }, [currentMonth, selectedOption, id, refreshData]);

  const getWeekdaysForMonth = (date) => {
    const weekdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const weekdaysArray = [];

    while (startDate.getMonth() === date.getMonth()) {
      const dayOfWeek = startDate.getDay();
      weekdaysArray.push(weekdays[dayOfWeek]);
      startDate.setDate(startDate.getDate() + 1);
    }

    return weekdaysArray;
  };
  const handleSelectionChange = (selectedValue) => {
    setSelectedOption(selectedValue);
  };
  const weekdaysForCurrentMonth = getWeekdaysForMonth(currentMonth);
  const EditEvent = (data) => {
    setIsOpenSession(true);
    SetSessionData(data);
  };
  const DeleteEvent = (data) => {
    setGroupSession(true);
    setIsDelete(true);
    setDeleteIsOpen(true);
    SetSessionData(data);
  };
  const closeEditSessionModal = () => {
    setEditIsOpen(false);
    setRefreshData((prev) => !prev);
  };
  const closeDeleteModal = () => {
    setDeleteIsOpen(false);
    setRefreshData((prev) => !prev);
  };

  // Function to render the days of the current month
  const renderDays = () => {
    const daysInMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    ).getDate();
    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth() + 1;
      const day = i;
      const date = `${currentMonth.getFullYear()}-${String(
        currentMonth.getMonth() + 1
      ).padStart(2, "0")}-${String(i).padStart(2, "0")}`;
      // Format the date consistently
      // const date = `${year}-${String(month).padStart(2, "0")}-${String(
      //   day
      // ).padStart(2, "0")}`;
      const eventsForDate = monthlyData[date] || [];
      const getFirstDayOfMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() is zero-based
        return `${year}-${String(month).padStart(2, "0")}-01`;
      };
      const numberToLetter = (number) => {
        return String.fromCharCode(65 + number);
      };
      // Function to render AthltBlockSets values for each exercise
      const formatAthBlckAthSession = (AthltBlockSets) => {
        return AthltBlockSets?.map(
          (set) => `${set?.value1}@${set?.value2}kg`
        ).join(", ");
      };
      const isDateSelected = selectedDate === date;
      // Access events based on the formatted date
      // const eventsForDate = monthlyData[date] || [];

      // console.log(eventsForDate)
      // Check if the day has events
      const hasEvents = eventsForDate.length > 0;

      // Disable the click event for days with events
      const isDisabled = hasEvents;
      const dayClass =
        eventsForDate.length > 0 ? "calendar-day" : "calendar-day no-events";
      days.push(
        <div
          // className={`${isDateSelected ? "chek" : "calenderMainDiv"} ${isDisabled ? "disabled" : ""}`}
          style={{
            borderRadius: "1px",
            margin: "2px",
            // marginBottom : "15px",
            minWidth: "150px",
            minHeight: "150px",
          }}
          onContextMenu={(event) => handlePastRightClick(event, date)}
        >
          <div
            style={{
              borderRadius: "8px",
              padding: "5px 10px 5px 10px",
              height: "100%",
            }}
            id={eventsForDate.length > 0 && "has-events"}
            key={date}
            className={`${isDateSelected ? "chek" : "calendar-day"} `}
            onMouseEnter={() => handleDateClick(date)}
          >
            {eventsForDate.length == 0 && (
              <div style={{ color: `black` }}>{i}</div>
            )}
            <div>
              {eventsForDate &&
                eventsForDate.length > 0 &&
                eventsForDate.map((event, index, data) => {
                  console.log(event, "event");
                  event?.blocks?.sort(
                    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                  );
                  return (
                    <>
                      <div
                        className="event"
                        key={index}
                        onContextMenu={(event) => handleRightClick(event, data)}
                      >
                        <span>{event.name}</span>{" "}
                        {isDateSelected && (
                          <div className="event-actions">
                            <div
                              style={{ cursor: "pointer" }}
                              className="icon"
                              onClick={() => EditEvent(event)}
                            >
                              <MdModeEdit
                                style={{ width: "20px", height: "20px" }}
                              />
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              className="icon"
                              onClick={() => DeleteEvent(event)}
                            >
                              <MdDelete
                                style={{ width: "20px", height: "20px" }}
                              />
                            </div>
                          </div>
                        )}
                        <div
                          style={{
                            color: `black`,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                          className="event-title"
                        >
                          {index === 0 && <>{i}</>}

                          <h6
                            style={{
                              color: `#000`,
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "18px",
                              lineHeight: "1.2",
                              textTransform: "capitalize",
                              marginTop: "15px",
                              fontWeight: "600",
                            }}
                          >
                            {`${event?.title}`}
                          </h6>
                        </div>
                        {/* {(event?.totalBlocks > 0 ||
                          event?.totalDuration > 0 ||
                          event?.totalWeight > 0 ||
                          event?.intensity > 0) && (
                          <div className="event-icons" style={{ gap: "5px" }}>
                            <div id="icon1" className="event-icon">
                              <div>
                                <svg
                                  id="iconpic"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M5.75447 7.86764C5.6651 7.78223 5.54624 7.73457 5.42263 7.73457C5.42253 7.73457 5.41173 7.7347 5.41173 7.7347C5.28438 7.73759 5.16339 7.79095 5.07538 7.88304C4.98998 7.97241 4.94232 8.09127 4.94232 8.21488L4.94244 8.22578C4.94533 8.35313 4.99869 8.47412 5.09079 8.56213L6.84992 10.2432C7.03546 10.4205 7.32761 10.4206 7.5133 10.2435L11.0374 6.88144C11.1296 6.79351 11.1832 6.67245 11.1862 6.5451C11.1864 6.53741 11.1864 6.52972 11.1862 6.52202C11.1833 6.40265 11.136 6.28865 11.0536 6.20226C10.9629 6.10725 10.8373 6.05349 10.706 6.05349L10.6925 6.05368C10.5737 6.05704 10.4604 6.10427 10.3745 6.18627L7.18204 9.23187L5.75447 7.86764Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.06433 1.73071C8.06433 1.73071 9.33435 1.73071 10.4949 2.2216C10.4949 2.2216 11.6155 2.69558 12.4795 3.55954C12.4795 3.55954 13.3435 4.42351 13.8175 5.54413C13.8175 5.54413 14.3083 6.70471 14.3083 7.97473C14.3083 7.97473 14.3083 9.24474 13.8175 10.4053C13.8175 10.4053 13.3435 11.5259 12.4795 12.3899C12.4795 12.3899 11.6155 13.2539 10.4949 13.7279C10.4949 13.7279 9.33434 14.2187 8.06433 14.2187C8.06433 14.2187 6.79431 14.2187 5.63373 13.7279C5.63373 13.7279 4.51311 13.2539 3.64914 12.3899C3.64914 12.3899 2.78518 11.5259 2.3112 10.4053C2.3112 10.4053 1.82031 9.24475 1.82031 7.97473C1.82031 7.97473 1.82031 6.7047 2.3112 5.54413C2.3112 5.54413 2.78518 4.42351 3.64914 3.55954C3.64914 3.55954 4.51311 2.69558 5.63373 2.2216C5.63373 2.2216 6.7943 1.73071 8.06433 1.73071ZM8.06433 2.69133C8.06433 2.69133 6.9891 2.69133 6.00794 3.10633C6.00794 3.10633 5.05988 3.50732 4.3284 4.2388C4.3284 4.2388 3.59692 4.97028 3.19593 5.91834C3.19593 5.91834 2.78093 6.8995 2.78093 7.97473C2.78093 7.97473 2.78093 9.04995 3.19593 10.0311C3.19593 10.0311 3.59692 10.9792 4.3284 11.7107C4.3284 11.7107 5.05988 12.4421 6.00794 12.8431C6.00794 12.8431 6.98911 13.2581 8.06433 13.2581C8.06433 13.2581 9.13955 13.2581 10.1207 12.8431C10.1207 12.8431 11.0688 12.4421 11.8003 11.7107C11.8003 11.7107 12.5317 10.9792 12.9327 10.0311C12.9327 10.0311 13.3477 9.04994 13.3477 7.97473C13.3477 7.97473 13.3477 6.89951 12.9327 5.91834C12.9327 5.91834 12.5317 4.97028 11.8003 4.2388C11.8003 4.2388 11.0688 3.50732 10.1207 3.10633C10.1207 3.10633 9.13955 2.69133 8.06433 2.69133Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>

                              <p style={{ fontSize: "7.68px" }}>Blocks</p>
                              <h6 style={{ fontSize: "10.76px" }}>
                                {event?.totalBlocks || "0"}
                              </h6>
                            </div>
                            <div id="icon2" className="event-icon">
                              <div>
                                <svg
                                  id="iconpic"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.00183 1.73071C8.00183 1.73071 9.27185 1.73071 10.4324 2.2216C10.4324 2.2216 11.553 2.69558 12.417 3.55954C12.417 3.55954 13.281 4.42351 13.755 5.54413C13.755 5.54413 14.2458 6.70471 14.2458 7.97473C14.2458 7.97473 14.2458 9.24474 13.755 10.4053C13.755 10.4053 13.281 11.5259 12.417 12.3899C12.417 12.3899 11.553 13.2539 10.4324 13.7279C10.4324 13.7279 9.27184 14.2187 8.00183 14.2187C8.00183 14.2187 6.73181 14.2187 5.57123 13.7279C5.57123 13.7279 4.45061 13.2539 3.58664 12.3899C3.58664 12.3899 2.72268 11.5259 2.2487 10.4053C2.2487 10.4053 1.75781 9.24475 1.75781 7.97473C1.75781 7.97473 1.75781 6.7047 2.2487 5.54413C2.2487 5.54413 2.72268 4.42351 3.58664 3.55954C3.58664 3.55954 4.45061 2.69558 5.57123 2.2216C5.57123 2.2216 6.7318 1.73071 8.00183 1.73071ZM8.00183 2.69133C8.00183 2.69133 6.9266 2.69133 5.94544 3.10633C5.94544 3.10633 4.99738 3.50732 4.2659 4.2388C4.2659 4.2388 3.53442 4.97028 3.13343 5.91834C3.13343 5.91834 2.71843 6.8995 2.71843 7.97473C2.71843 7.97473 2.71843 9.04995 3.13343 10.0311C3.13343 10.0311 3.53442 10.9792 4.2659 11.7107C4.2659 11.7107 4.99738 12.4421 5.94544 12.8431C5.94544 12.8431 6.92661 13.2581 8.00183 13.2581C8.00183 13.2581 9.07705 13.2581 10.0582 12.8431C10.0582 12.8431 11.0063 12.4421 11.7378 11.7107C11.7378 11.7107 12.4692 10.9792 12.8702 10.0311C12.8702 10.0311 13.2852 9.04994 13.2852 7.97473C13.2852 7.97473 13.2852 6.89951 12.8702 5.91834C12.8702 5.91834 12.4692 4.97028 11.7378 4.2388C11.7378 4.2388 11.0063 3.50732 10.0582 3.10633C10.0582 3.10633 9.07705 2.69133 8.00183 2.69133Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M8.00183 8.45504H11.364C11.6293 8.45504 11.8443 8.23999 11.8443 7.97473C11.8443 7.70946 11.6293 7.49442 11.364 7.49442H8.48213V4.61257C8.48213 4.3473 8.26709 4.13226 8.00183 4.13226C7.73656 4.13226 7.52152 4.3473 7.52152 4.61257V7.97473C7.52152 8.23999 7.73656 8.45504 8.00183 8.45504Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                              <p style={{ fontSize: "7.68px" }}>Minutes</p>
                              <h6 style={{ fontSize: "10.76px" }}>
                                {event?.totalDuration || "0"}
                              </h6>
                            </div>
                            <div id="icon3" className="event-icon">
                              <div>
                                <svg
                                  id="iconpic"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_1963_19636)">
                                    <path
                                      d="M10.0512 7.25132L8.62532 9.51777C8.56698 9.59862 8.49603 9.66957 8.41518 9.72791C8.22 9.86407 7.979 9.91777 7.74447 9.87734C7.50995 9.83691 7.30083 9.70562 7.16251 9.51196C7.02418 9.31831 6.96781 9.07792 7.00563 8.84296C7.04345 8.608 7.1724 8.39743 7.36451 8.25696L9.63097 6.83105C9.68249 6.79513 9.74378 6.77588 9.80658 6.77588C9.86938 6.77588 9.93067 6.79513 9.98219 6.83105C10.0469 6.87776 10.0905 6.94819 10.1034 7.02694C10.1164 7.10569 10.0976 7.18636 10.0512 7.25132Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M7.94698 2.21106C4.2336 2.21106 1.22266 5.21899 1.22266 8.93238C1.22038 10.5753 1.82186 12.1618 2.91275 13.3902C2.94577 13.4263 2.97579 13.4623 3.00881 13.4953C3.07985 13.5722 3.16608 13.6335 3.26205 13.6754C3.35802 13.7172 3.46163 13.7387 3.56633 13.7384C3.67102 13.7381 3.77452 13.7161 3.87026 13.6737C3.966 13.6314 4.0519 13.5696 4.12253 13.4923C4.60929 12.9633 5.2005 12.5411 5.85879 12.2522C6.51707 11.9634 7.22812 11.8143 7.94698 11.8143C8.66585 11.8143 9.3769 11.9634 10.0352 12.2522C10.6935 12.5411 11.2847 12.9633 11.7714 13.4923C11.8421 13.5696 11.928 13.6314 12.0237 13.6737C12.1195 13.7161 12.2229 13.7381 12.3276 13.7384C12.4323 13.7387 12.5359 13.7172 12.6319 13.6754C12.7279 13.6335 12.8141 13.5722 12.8852 13.4953L12.9812 13.3902C14.0721 12.1618 14.6736 10.5753 14.6713 8.93238C14.6713 5.21899 11.6604 2.21106 7.94698 2.21106Z"
                                      stroke="white"
                                      stroke-width="1.15274"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.94727 4.13232V5.09294"
                                      stroke="white"
                                      stroke-width="1.53699"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M12.7497 8.9353H11.7891"
                                      stroke="white"
                                      stroke-width="1.53699"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M4.10515 8.9353H3.14453"
                                      stroke="white"
                                      stroke-width="1.53699"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M5.23012 6.21828L4.55078 5.53894"
                                      stroke="white"
                                      stroke-width="1.53699"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M10.6641 6.21828L11.3434 5.53894"
                                      stroke="white"
                                      stroke-width="1.53699"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1963_19636">
                                      <rect
                                        width="15.3699"
                                        height="15.3699"
                                        fill="white"
                                        transform="translate(0.261719 0.289795)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>

                              <p style={{ fontSize: "7.68px" }}>Intensity</p>
                              <h6 style={{ fontSize: "10.76px" }}>
                                {event?.intensity || 0}
                              </h6>
                            </div>
                            <div id="icon4" className="event-icon">
                              <div>
                                <svg
                                  id="iconpic"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3.07252 4.08264C3.07252 4.08264 3.07252 3.68107 3.35647 3.39711C3.35647 3.39711 3.64043 3.11316 4.042 3.11316H5.49623C5.49623 3.11316 5.89781 3.11316 6.18176 3.39711C6.18176 3.39711 6.46572 3.68107 6.46572 4.08264V11.8385C6.46572 11.8385 6.46572 12.2401 6.18176 12.5241C6.18176 12.5241 5.89781 12.808 5.49623 12.808H4.042C4.042 12.808 3.64043 12.808 3.35647 12.5241C3.35647 12.5241 3.07252 12.2401 3.07252 11.8385V4.08264ZM4.042 4.08264V11.8385H5.49623V4.08264H4.042Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.37417 4.08264C9.37417 4.08264 9.37417 3.68107 9.65813 3.39711C9.65813 3.39711 9.94208 3.11316 10.3437 3.11316H11.7979C11.7979 3.11316 12.1995 3.11316 12.4834 3.39711C12.4834 3.39711 12.7674 3.68107 12.7674 4.08264V11.8385C12.7674 11.8385 12.7674 12.2401 12.4834 12.5241C12.4834 12.5241 12.1995 12.808 11.7979 12.808H10.3437C10.3437 12.808 9.94208 12.808 9.65813 12.5241C9.65813 12.5241 9.37417 12.2401 9.37417 11.8385V4.08264ZM10.3437 4.08264V11.8385H11.7979V4.08264H10.3437Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M13.7369 10.3843H12.2826C12.0149 10.3843 11.7979 10.6013 11.7979 10.869C11.7979 11.1368 12.0149 11.3538 12.2826 11.3538H13.7369C14.1384 11.3538 14.4224 11.0698 14.4224 11.0698C14.7063 10.7859 14.7063 10.3843 14.7063 10.3843V5.53687C14.7063 5.1353 14.4224 4.85134 14.4224 4.85134C14.1384 4.56739 13.7369 4.56739 13.7369 4.56739H12.2826C12.0149 4.56739 11.7979 4.78441 11.7979 5.05213C11.7979 5.31985 12.0149 5.53687 12.2826 5.53687H13.7369V10.3843Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M2.10303 5.53687H3.55726C3.82498 5.53687 4.042 5.31985 4.042 5.05213C4.042 4.78441 3.82498 4.56739 3.55726 4.56739H2.10303C1.70146 4.56739 1.4175 4.85134 1.4175 4.85134C1.13355 5.1353 1.13355 5.53687 1.13355 5.53687V10.3843C1.13355 10.7859 1.4175 11.0698 1.4175 11.0698C1.70146 11.3538 2.10303 11.3538 2.10303 11.3538H3.55726C3.82498 11.3538 4.042 11.1368 4.042 10.869C4.042 10.6013 3.82498 10.3843 3.55726 10.3843H2.10303V5.53687Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M5.98097 8.44533H9.85892C10.1266 8.44533 10.3437 8.2283 10.3437 7.96059C10.3437 7.69287 10.1266 7.47584 9.85892 7.47584H5.98097C5.71326 7.47584 5.49623 7.69287 5.49623 7.96059C5.49623 8.2283 5.71326 8.44533 5.98097 8.44533Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M13.7369 7.96059C13.7369 8.2283 13.9539 8.44533 14.2216 8.44533H15.1911C15.4588 8.44533 15.6758 8.2283 15.6758 7.96059C15.6758 7.69287 15.4588 7.47584 15.1911 7.47584H14.2216C13.9539 7.47584 13.7369 7.69287 13.7369 7.96059Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M0.164062 7.96059C0.164062 8.2283 0.381089 8.44533 0.648805 8.44533H1.61829C1.88601 8.44533 2.10303 8.2283 2.10303 7.96059C2.10303 7.69287 1.88601 7.47584 1.61829 7.47584H0.648805C0.381089 7.47584 0.164062 7.69287 0.164062 7.96059Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>

                              <p style={{ fontSize: "7.68px" }}>LB</p>
                              <h6 style={{ fontSize: "10.76px" }}>
                                {event?.totalWeight || "0"}
                              </h6>
                            </div>
                          </div>
                        )} */}
                        {event?.blocks?.map((data, index) => {
                          console.log(data, "checkingblock");

                          return (
                            <>
                              {show && (
                                <div
                                  style={{ color: `black` }}
                                  className="event-details"
                                >
                                  {data.description}
                                </div>
                              )}
                              {ShowDetails && (
                                <>
                                  <div
                                    style={{
                                      color: `#000`,
                                      justifyContent: "center",
                                      display: "flex",
                                      fontSize: "16px",
                                      lineHeight: "1.2",
                                      textTransform: "capitalize",
                                      marginTop: "15px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {`${data?.blocktitle}`}
                                  </div>
                                </>
                              )}
                              <div className="sets">
                                <div className="event-type mt-2 mb-3 ">
                                  <div
                                    style={{
                                      color: `black`,
                                      backgroundColor: `rgba(0, 0, 0, 0.05)`,
                                    }}
                                    className="event-nmbr"
                                  >
                                    {data?.supersetGroup}
                                    {data?.supersetOrder > 0 &&
                                      data?.supersetOrder}
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        color: `black`,
                                        fontSize: "18px",
                                        fontWeight: "400",
                                      }}
                                      className="event-exer"
                                    >
                                      {data?.blockSets?.title}
                                    </div>

                                    <div className="event-exer">
                                      <div
                                        style={{
                                          color: `#000`,
                                          // justifyContent: "center",
                                          display: "flex",
                                          fontSize: "14px",
                                          // lineHeight: "1.2",
                                          // textTransform: "capitalize",
                                          // marginTop: "15px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {data?.exercises?.title}
                                      </div>
                                      <p
                                        className="size"
                                        id="as"
                                        style={{
                                          color: `#000`,

                                          display: "flex",
                                          fontSize: "12px",
                                          // lineHeight: "1.2",
                                          textTransform: "capitalize",
                                          // marginTop: "15px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {data?.blockSets?.length >= 1
                                          ? formatAthBlckAthSession(
                                              data?.blockSets
                                            )
                                          : "There is No Set and Reps"}
                                      </p>
                                      <div>
                                        {ShowDetails && (
                                          <>
                                            <div
                                              style={{
                                                position: "relative",
                                                bottom: "15px",

                                                color: `#000`,

                                                display: "flex",
                                                fontSize: "12px",

                                                textTransform: "capitalize",

                                                fontWeight: "400",
                                              }}
                                            >
                                              {data?.exercises?.instructions}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {show && (
                                <div
                                  style={{ color: `black` }}
                                  className="event-details"
                                >
                                  {event.detail}
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>

                      {index < data.length - 1 && (
                        <div
                          style={{
                            width: "130px",
                            height: "2px",
                            backgroundColor: "black",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        ></div>
                      )}
                    </>
                  );
                })}
            </div>

            {isDateSelected && (
              <div className="event-creation">
                <button
                  id="asf"
                  style={{
                    marginTop: "1rem",
                    backgroundColor: `white`,
                    color: `black)`,
                  }}
                  className="create-session-button"
                  onClick={createEvent}
                >
                  Create Session
                </button>
                <button
                  className="create-session-button"
                  onClick={() => createEvent(date)}
                  style={{
                    backgroundColor: `white`,
                    color: `black`,
                  }}
                >
                  {activeTab === "group" ? "Add Program" : "Add from index"}
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }

    return days;
  };
  useEffect(() => {
    renderDays();
  }, [currentMonth, monthlyData, refreshData]);
  useEffect(() => {
    fetchMonthData(currentMonth.toISOString().slice(0, 7)); // Fetch data for the current month
  }, [currentMonth]);
  // Function to handle date click
  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  // Function to create a new event
  const createEvent = (date) => {
    SetSessionData(null);
    setIsOpenSession(true);
  };
  const handleChatOpen = (athelete) => {
    setActiveTab("coach");
    setIsGroupChat(true);
    setActiveChat(athelete);
  };

  return isLoader ? (
    <>
      <div
        className="d-flex justify-content-center align-items-center "
        style={{ height: "600px" }}
      >
        <Loader />
      </div>
    </>
  ) : (
    <>
      <div className="calendar-container">
        <div className="test">
          <div
            className="calendar-header"
            style={{ backgroundColor: `var(--primary-color,#f0f0f0)` }}
          >
            <div
              onClick={() => isOpenCalender(false)}
              style={{ cursor: "pointer", width: "fit-content" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="22"
                viewBox="0 0 12 22"
                fill="none"
              >
                <path
                  d="M10 20.1038L2 11.2511L10 2.39844"
                  stroke="#100837"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <DropDown
              selectedOption={selectedOption}
              getAtheleteId={id}
              options={dropDownOption}
              placeholder="Select an Athlete"
              onChange={handleSelectionChange}
              isActiveTab={tab}
              style={{ width: "20%" }} // Override styles as needed
              className="my-custom-class" // Add custom classes as needed
              ariaLabel="Athlete Select" // Custom ARIA label for accessibility
            />
            <div className="subHeader">
              <svg
                style={{ cursor: "pointer", marginLeft: "1rem" }}
                onClick={goToPreviousMonth}
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
              >
                <path
                  d="M11.1255 12C10.8573 12 10.5878 11.8962 10.3828 11.6887L5.48664 6.75669C5.28866 6.55628 5.17773 6.2848 5.17773 6.00053C5.17773 5.71769 5.28866 5.44621 5.48664 5.2458L10.3828 0.310919C10.7942 -0.104112 11.4597 -0.104112 11.8711 0.313761C12.2811 0.731634 12.2797 1.40677 11.8683 1.8218L7.72198 6.00053L11.8683 10.1793C12.2797 10.5943 12.2811 11.268 11.8711 11.6859C11.6661 11.8962 11.3951 12 11.1255 12Z"
                  fill="#232D42"
                />
                <path
                  d="M6.12553 12C5.85735 12 5.58776 11.8962 5.38276 11.6887L0.486638 6.75669C0.288659 6.55628 0.177734 6.2848 0.177734 6.00053C0.177734 5.71769 0.288659 5.44621 0.486638 5.2458L5.38276 0.310919C5.79416 -0.104112 6.45971 -0.104112 6.87111 0.313761C7.28111 0.731634 7.27971 1.40677 6.8683 1.8218L2.72198 6.00053L6.8683 10.1793C7.27971 10.5943 7.28111 11.268 6.87111 11.6859C6.66611 11.8962 6.39512 12 6.12553 12Z"
                  fill="#232D42"
                />
              </svg>
              <h1
                style={{ color: `var(--primary-text-color,black)` }}
                className="month"
              >
                {currentMonth.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </h1>
              <svg
                style={{ cursor: "pointer" }}
                onClick={goToNextMonth}
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
              >
                <path
                  d="M1.56295 12C1.83113 12 2.10072 11.8962 2.30572 11.6887L7.20184 6.75669C7.39982 6.55628 7.51074 6.2848 7.51074 6.00053C7.51074 5.71769 7.39982 5.44621 7.20184 5.2458L2.30572 0.310919C1.89432 -0.104112 1.22877 -0.104112 0.817366 0.313761C0.407367 0.731634 0.408771 1.40677 0.820174 1.8218L4.9665 6.00053L0.820174 10.1793C0.408771 10.5943 0.407367 11.268 0.817366 11.6859C1.02237 11.8962 1.29336 12 1.56295 12Z"
                  fill="#232D42"
                />
                <path
                  d="M6.56295 12C6.83113 12 7.10072 11.8962 7.30572 11.6887L12.2018 6.75669C12.3998 6.55628 12.5107 6.2848 12.5107 6.00053C12.5107 5.71769 12.3998 5.44621 12.2018 5.2458L7.30572 0.310919C6.89432 -0.104112 6.22877 -0.104112 5.81737 0.313761C5.40737 0.731634 5.40877 1.40677 5.82017 1.8218L9.9665 6.00053L5.82017 10.1793C5.40877 10.5943 5.40737 11.268 5.81737 11.6859C6.02237 11.8962 6.29336 12 6.56295 12Z"
                  fill="#232D42"
                />
              </svg>
            </div>
            <div className="clndrbtns">
              <button
                onClick={handlePublish}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: `var(--secondary-color,#A19686)`,
                  color: `var(--secondary-text-color,white)`,
                  height: "35px",
                  width: "90px",
                  borderRadius: "8px",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                {Loading ? <LoadingSpinner size="sm" /> : "Publish"}
              </button>
              <button
                onClick={() => handleChatOpen(SelectedData)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: `var(--secondary-color,#A19686)`,
                  color: `var(--secondary-text-color,white)`,
                  height: "35px",
                  width: "160px",
                  borderRadius: "8px",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                <span className="plus-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M15.8027 11.25H19.5527C19.8979 11.25 20.1777 10.9702 20.1777 10.625C20.1777 10.2798 19.8979 10 19.5527 10H15.8027C15.4575 10 15.1777 10.2798 15.1777 10.625C15.1777 10.9702 15.4575 11.25 15.8027 11.25Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.61522 2.5C8.61522 2.5 9.69563 2.5 10.6833 2.91774C10.6833 2.91774 11.6368 3.32103 12.3717 4.056C12.3717 4.056 13.1067 4.79096 13.51 5.74444C13.51 5.74444 13.9277 6.73209 13.9277 7.8125C13.9277 7.8125 13.9277 8.89291 13.51 9.88056C13.51 9.88056 13.1067 10.834 12.3717 11.569C12.3717 11.569 11.6368 12.304 10.6833 12.7073C10.6833 12.7073 9.69563 13.125 8.61522 13.125C8.61522 13.125 7.53481 13.125 6.54716 12.7073C6.54716 12.7073 5.59368 12.304 4.85871 11.569C4.85871 11.569 4.12375 10.834 3.72046 9.88056C3.72046 9.88056 3.30272 8.89291 3.30272 7.8125C3.30272 7.8125 3.30272 6.73209 3.72046 5.74444C3.72046 5.74444 4.12375 4.79096 4.85871 4.056C4.85871 4.056 5.59368 3.32103 6.54716 2.91774C6.54716 2.91774 7.53481 2.5 8.61522 2.5ZM8.61522 3.75C8.61522 3.75 6.93248 3.75 5.7426 4.93988C5.7426 4.93988 4.55272 6.12976 4.55272 7.8125C4.55272 7.8125 4.55272 9.49524 5.7426 10.6851C5.7426 10.6851 6.93248 11.875 8.61522 11.875C8.61522 11.875 10.298 11.875 11.4878 10.6851C11.4878 10.6851 12.6777 9.49524 12.6777 7.8125C12.6777 7.8125 12.6777 6.12976 11.4878 4.93988C11.4878 4.93988 10.298 3.75 8.61522 3.75Z"
                      fill="white"
                    />
                    <path
                      d="M12.0492 13.8854C13.6817 14.6467 14.8395 16.0266 14.8395 16.0266C14.9583 16.1682 15.1336 16.25 15.3184 16.25L15.3285 16.2499C15.4719 16.2476 15.6102 16.196 15.7201 16.1038C15.8616 15.985 15.9434 15.8097 15.9434 15.625L15.9433 15.6149C15.941 15.4714 15.8894 15.3332 15.7972 15.2233L15.7966 15.2226C14.4608 13.6308 12.5775 12.7525 12.5775 12.7525C10.6937 11.8741 8.61523 11.8741 8.61523 11.8741C6.53674 11.8741 4.65301 12.7525 4.65301 12.7525C2.76966 13.6308 1.43386 15.2226 1.43386 15.2226L1.43332 15.2233C1.33887 15.3358 1.28711 15.478 1.28711 15.625L1.28711 15.6268C1.28716 15.6444 1.28796 15.662 1.28949 15.6795C1.30394 15.8446 1.3834 15.9972 1.51039 16.1038C1.62295 16.1982 1.76518 16.25 1.91211 16.25C1.9303 16.25 1.94848 16.2492 1.96661 16.2476C2.13174 16.2331 2.28436 16.1537 2.3909 16.0267C3.54874 14.6467 5.18131 13.8854 5.18131 13.8854C6.81388 13.1241 8.61523 13.1241 8.61523 13.1241C10.4166 13.1241 12.0492 13.8854 12.0492 13.8854Z"
                      fill="white"
                    />
                  </svg>
                </span>
                {activeTab === "group" ? "Message Group" : "Message Athlete"}
              </button>
              <Tooltip
             children={
              <div className="icons">
                <SvgIcon iconName="folderIcon" />
              </div>
             }
              position={"bottom"}
              content={"Add File"}
              />
              <div style={{marginTop : "3px"}} onClick={() => setShowDetails(!ShowDetails)}>
                <Tooltip
                position="bottom"
                  content={ShowDetails ? "View Details" : "Hide Details"}
                  children={
                    <div className="icons">
                      <SvgIcon
                        iconName={ShowDetails ? "eyeWhiteClose" : "eyeWhite"}
                        iconSize={30}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          <div
            style={{
              color: `var(--primary-text-color,black)`,
              backgroundColor: `var(--primary-color,#f0f0f0)`,
            }}
            id="asf"
            className="weekdays"
          >
            {weekdaysForCurrentMonth.slice(0, 7).map((day, index) => (
              <div key={index}>{day}</div>
            ))}
          </div>
        </div>
        <div
          className="athbody"
          style={{
            padding: "1rem",
          }}
        >
          <div className="calendar-grid">{renderDays()}</div>
        </div>

        {/* {isOpen && (
          <GroupSessionModal
            groupDetails={id}
            activeTab={activeTab}
            selectedDate={selectedDate}
            isOpen={isOpen}
            onClose={closeSessionModal}
          />
        )}
        {EditIsOpen && (
          <GroupSessionModal
            GroupSession={GroupSession}
            selectedDate={selectedDate}
            isOpen={EditIsOpen}
            IsEdit={IsEdit}
            onClose={closeEditSessionModal}
            SessionData={SessionData}
            editIsOpen={EditIsOpen}
          />
        )} */}
        {isOpenSession && (
          <CreateSession
            SessionID={SessionData?.id}
            selectedDate={selectedDate}
            id={id}
            isEditSession={SessionData}
            setEditSession={SetSessionData}
            isOpen={isOpenSession}
            onClose={closeSessionForm}
          />
        )}

        {DeleteIsOpen && (
          <DeleteSessonModel
            activeTab={activeTab}
            isDeleteBlock={false}
            GroupSession={GroupSession}
            IsDelete={IsDelete}
            isOpen={IsDelete}
            onClose={closeDeleteModal}
            Id={SessionData.id}
          />
        )}
        {contextMenu.visible && (
          <ul
            className="custom-context-menu"
            style={{
              position: "absolute",
              top: `${contextMenu.y}px`,
              left: `${contextMenu.x}px`,
              zIndex: 1000,
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              borderRadius: "5px",
              listStyle: "none",
              padding: "10px",
              color: "black",
            }}
          >
            <li className="cursor" onClick={() => handleCopyIndex(1)}>
              Copy 1 Session
            </li>
            <li
              className="cursor"
              onClick={() => handlePasteSession(selectedSession.pasteDate)}
            >
              Paste Session
            </li>
            <li className="cursor" onClick={() => handleCopyIndex(3)}>
              Delete 1 Session
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default MonthlyCalender;
