// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createSession_session_container__QIfwp {
  overflow-y: scroll;
  height: 83vh;
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
  z-index: 1002;
  position: relative;
  background-color: var(--primary-bg-color, #fff);
  padding: 20px;

}
.createSession_session_container__QIfwp::-webkit-scrollbar {
  width: 7px;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.createSession_session_container__QIfwp::-webkit-scrollbar-track {
  background-color: var(--Grey, #F9F9F9);
}

.createSession_session_container__QIfwp::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #A19686;
  border-left: 3px solid #EDF2F4;
  border-right: 3px solid #EDF2F4
}
.createSession_date__s0BG9 {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-text-color, black);
}
.createSession_user_details__SzPmd {
  margin-left: 0px;
}
.createSession_user_info__R5pET{
  margin-left: 10px;
}
.createSession_user_name__MFp3R{
  color: var(--primary-text-color, black);
  font-weight: 600;
  margin: 0px;
}
.createSession_user_status__Y5MfR{
  color: #4B52FF;
}
.createSession_session_label__n26CL {
  font-size: 16px;
  background-color: var(--secondary-bg-color, #fafafa);
}
.createSession_session_input__n7ZOh {
  background-color:  #F6F6F6;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/Modal/CreateSession/createSession.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,+CAA+C;EAC/C,aAAa;;AAEf;AACA;EACE,UAAU;EAEV,6BAAqB;EAArB,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B;AACF;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,uCAAuC;AACzC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,uCAAuC;EACvC,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,oDAAoD;AACtD;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".session_container {\r\n  overflow-y: scroll;\r\n  height: 83vh;\r\n  max-width: 100%;\r\n  width: 100%;\r\n  border-radius: 5px;\r\n  z-index: 1002;\r\n  position: relative;\r\n  background-color: var(--primary-bg-color, #fff);\r\n  padding: 20px;\r\n\r\n}\r\n.session_container::-webkit-scrollbar {\r\n  width: 7px;\r\n  -webkit-transition: all 300ms;\r\n  transition: all 300ms;\r\n}\r\n\r\n.session_container::-webkit-scrollbar-track {\r\n  background-color: var(--Grey, #F9F9F9);\r\n}\r\n\r\n.session_container::-webkit-scrollbar-thumb {\r\n  border-radius: 20px;\r\n  background: #A19686;\r\n  border-left: 3px solid #EDF2F4;\r\n  border-right: 3px solid #EDF2F4\r\n}\r\n.date {\r\n  font-size: 20px;\r\n  font-weight: 700;\r\n  color: var(--primary-text-color, black);\r\n}\r\n.user_details {\r\n  margin-left: 0px;\r\n}\r\n.user_info{\r\n  margin-left: 10px;\r\n}\r\n.user_name{\r\n  color: var(--primary-text-color, black);\r\n  font-weight: 600;\r\n  margin: 0px;\r\n}\r\n.user_status{\r\n  color: #4B52FF;\r\n}\r\n.session_label {\r\n  font-size: 16px;\r\n  background-color: var(--secondary-bg-color, #fafafa);\r\n}\r\n.session_input {\r\n  background-color:  #F6F6F6;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"session_container": `createSession_session_container__QIfwp`,
	"date": `createSession_date__s0BG9`,
	"user_details": `createSession_user_details__SzPmd`,
	"user_info": `createSession_user_info__R5pET`,
	"user_name": `createSession_user_name__MFp3R`,
	"user_status": `createSession_user_status__Y5MfR`,
	"session_label": `createSession_session_label__n26CL`,
	"session_input": `createSession_session_input__n7ZOh`
};
export default ___CSS_LOADER_EXPORT___;
