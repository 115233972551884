

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../context/themeContext";
import ManageFeed from "../../components/atheleteComponnent/manageFeeds/manageFeed";
import ManageGroups from "../../components/atheleteComponnent/manageGroups/manageGroups";
import ManageIndex from "../../components/atheleteComponnent/manageIndex/manageIndex";
import Session from "../../components/Common/Session/Session";
import TraningSession from "../../components/modules/Athelete/TraningSession";
import { Container, Row } from "react-bootstrap";
import styles from "../Home/homepage.module.css"
import AtheleteChat from "../../components/modules/Athelete/Chat/AtheleteChat";
const AtheletePanel = () => {
  const location = useLocation();
  const { activeTab } = useContext(ThemeContext);
  return (
    <Container className={`${styles?.coachPortalContainer} my-4`}>
      <Row className="mx-2">
        {activeTab === "manageAtheleteTraining" && <TraningSession activeTab={activeTab} />}
        {activeTab === "manageAtheleteFeed" && <ManageFeed activeTab={activeTab} />}
        {activeTab === "manageAtheleteGroups" && <ManageGroups activeTab={activeTab} />}
        {activeTab === "manageAtheleteIndex" && <ManageIndex activeTab={activeTab} />}
        {activeTab === "manageAtheleteChat" && <AtheleteChat activeTab={activeTab} />}
        {activeTab === "manageCreateSession" && <Session activeTab={activeTab} />}
      </Row>
    </Container>
  );
};

export default AtheletePanel;
