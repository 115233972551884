import React from 'react'
import style from './fourth.module.css'
import { SidePanel } from '../../../../../assessts/LandingPage'

const Fourth = () => {
  return (
    <div className={`${style.Main}`}>
            <div className={`${style.contentBox}`}>
                <h1>Never miss a beat with our on-the-go programming editing and updating feature</h1>
                <p>Our user-friendly app allows you to make real-time adjustments to your training programs, keeping them dynamic and responsive to the changing needs of your clients.</p>
            </div>
            <div className={`${style.Img}`}>
                <img src={SidePanel} alt="" />
            </div>
        </div>
  )
}

export default Fourth
