import React from "react";
import { auth } from "../../../../firebase";
import Avtr from "../../../../assessts/images/avtr.png";
import CreateSessionForm from "./CreateSessionForm/createSessionForm";

const SessionModal = ({
  isOpen,
  onClose,
  isEditSession,
  setEditSession,
}) => {
  const currentDate = new Date();
  // Get day, date, and month
  const day = currentDate.getDay(); // Returns a number (0-6) representing the day of the week
  const date = currentDate.getDate(); // Returns the day of the month (1-31)
  const month = currentDate.getMonth(); // Returns a number (0-11) representing the month

  // Convert day and month to human-readable format
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const readableDay = dayNames[day];
  const readableMonth = monthNames[month];
  // To Get Coach Id From Auth
  const coachName = auth.currentUser.displayName;
  return (
    <>
      <div className={`invite-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-overlay" onClick={onClose}></div>
        <div style={{
          overflowY: "scroll",
          height: "83vh",
          maxWidth: "80%",
          width: "80% ",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
          zIndex: "1002",
          position: "relative",
          borderRadius: "8px",
          background: "var(--primary-bg-color, #FFF)"
        }} >
          <h2
            id="asf"
            className="pb-2"
            style={{ fontSize: "20px", fontWeight: 700, color: `var(--primary-text-color,black)` }}
          >
            {readableDay} {readableMonth} {date}
          </h2>
          <div className="userInfo d-flex ml-4">
            <img width="36px" height="36px" src={Avtr} alt="" />
            <div className="userNameAndStatus" style={{marginLeft: "10px"}}>
              <h6 style={{ color: `var(--primary-text-color,black)`, fontSize: "16px", fontWeight: "600", margin: "0px"}} id="as">{coachName}</h6>
              <p id="asf" style={{fontSize: "12px", color: "#4B52FF"}}>Unpublished</p>
            </div>
          </div>
          <CreateSessionForm
            onClose={onClose}
            isEditSession={isEditSession}
            setEditSession={setEditSession}
          />
        </div>
      </div>
    </>
  );
};

export default SessionModal;
