import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./chatlist.css";
import GroupChat from "../../../assessts/icons/groupChat.png";
import Avt from "../../../assessts/images/avtr.png";
import { useLocation } from "react-router-dom";
import { getGroups, getUsers } from "../../../apis";
import { auth } from "../../../firebase";
import axios from "axios";
import { ThemeContext } from "../../../context/themeContext";
import { useTranslation } from "react-i18next";
import Chats from "../../inbox/chats";
import { FaSearch } from "react-icons/fa";
import io from "socket.io-client";
const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

const Container = styled.div`
  display: ${(props) =>
    props.checkathlt == true && props.activeChat === "flex"};
  flex-direction: ${(props) =>
    props.checkathlt == true && props.activeChat && "column"};
  justify-content: ${(props) =>
    props.checkathlt == true && props.activeChat && "space-between"};
  height: ${(props) =>
    props.checkathlt == true && props.activeChat ? "550px" : "600px"};
  padding: 0 10px;
  background: white;
  // direction: rtl;

  @media screen and (max-width: 2560px) {
    height: 833px !important;
  }
  @media only screen and (max-width: 2000px) and (min-width: 1441px) {
    height: 300px !important;
  }

  @media screen and (max-width: 1440px) {
    height: 600px !important;
  }

  @media screen and (max-width: 1024px) {
    height: 528px !important;
  }
`;
const ChatList = ({ isAthlete }) => {

  
  const {
    isChatOpen,
    typerId,
    setIsChatOpen,
    isTyping,
    unreadCount,
    getUnreadMesg,
    setLastUnreadMessage,
    setUnreadCount,
    lastUnreadMessage,
    theme,
    isGroupChat,
    setIsGroupChat,
    chatUsers,
    setChatUsers,
    activeChat,
    setActiveChat,
    setActiveChatId,
    setActiveChatGroupId
  } = useContext(ThemeContext)
  const location = useLocation();;
  const check = location.pathname === "/chat" ? true : false;
  const [refresh, setRefresh] = useState(false);
  const [hideMessagList, setHideMessageList] = useState(false);
  const { t } = useTranslation();
  const [groupChat, setGroupChat] = useState([]);
  const [authReady, setAuthReady] = useState(false);
  const [SenderChat, setSenderChat] = useState([]);
  const socket = useRef();
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_Baseurl, {
      transports: ["websocket"],
    });

    socket.current.on("connect", () => {
      console.log("Socket connected:", socket.current.id);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);
  const updateUnread = (userId) => {
    if (isChatOpen == true) {
      socket.current.emit("update_unread", {
        userId: userId || chatUsers?.[0]?.id,
      });
      setIsChatOpen(false);
    }
  };

  useEffect(() => {
    // setIsChatOpen(true)
    updateUnread();
  }, [chatUsers?.length > 0, !activeChat, refresh]);

  const getUnreadStatus = () => {
    socket.current.on("unread_messages", () => {
      fetchChatUsers();
    });

    socket.current.on("error", (error) => {
      console.error("Socket error:", error);
    });
  };
  
  useEffect(() => {
    getUnreadStatus();
    // scrollToBottom();
    return () => {
      socket.current.off("unread_messages");
    };
  }, [socket, refresh]);

  const fetchChatUsers = async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      const portal = isAthlete ? "athlete" : "coach";

      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/api/v1/${portal}/getCoachAthletes`,
        { headers: { Authorization: token } }
      );

      setChatUsers(response?.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const getUnread = () => {
    socket.current.on(
      "private_message",
      (data) => {
        fetchChatUsers();
        // scrollToBottom();
      },
      [socket, refresh]
    );
  };

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setAuthReady(!!user);
    });

    return () => unregisterAuthObserver();
  }, []);
  useEffect(() => {
    if (authReady) {
      fetchChatUsers();
      fetchGroupChats();
    }
  }, [authReady, refresh]);
  useEffect(() => {
    getUnread();
  }, [socket, refresh]);
  const fetchGroupChats = async () => {
    try {
      const userId = auth.currentUser?.uid;
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${getGroups}/${userId}`
      );

      setGroupChat(response.data);
    } catch (error) {
      console.error("Error fetching group chats", error);
    }
  };
 
  const handleChatClick = (user) => {
    console.log(user,"user")
    setRefresh(!refresh);
    updateUnread(user.id);
    setSenderChat(user.sender);
    setActiveChatId(user.id);
   setActiveChatGroupId(user.groupId || user.id )
    setActiveChat(user);
    updateUnread(user);
    setIsChatOpen(!isChatOpen);
  };
  // useEffect(()=>{
  //   fetchChatUsers()
  // })
  return (
    <>
      {!isAthlete && activeChat && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: " #F6F6F0",
            padding: "0px",
          }}
        >
          {theme === "light" ? (
            <svg
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveChat(null);
                setRefresh(!refresh);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M20.7067 6.70748L20.7071 6.70711C20.8946 6.51957 21 6.26522 21 6C21 5.73478 20.8946 5.48043 20.7071 5.29289L20.7065 5.29232L20.6985 5.28436L20.6979 5.2838C20.5112 5.10184 20.2607 5 20 5C19.7348 5 19.4804 5.10536 19.2929 5.29289L19.2925 5.29327L9.29289 15.2929C9.10536 15.4804 9 15.7348 9 16C9 16.2652 9.10536 16.5196 9.29289 16.7071L19.2929 26.7071C19.4804 26.8946 19.7348 27 20 27C20.2652 27 20.5196 26.8946 20.7071 26.7071C20.8946 26.5196 21 26.2652 21 26C21 25.7348 20.8946 25.4804 20.7071 25.2929L11.4142 16L20.7067 6.70748Z"
                fill="#1C1C1C"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M14.8732 0.5H6.2032C2.8132 0.5 0.533203 2.88 0.533203 6.42V14.59C0.533203 18.12 2.8132 20.5 6.2032 20.5H14.8732C18.2632 20.5 20.5332 18.12 20.5332 14.59V6.42C20.5332 2.88 18.2632 0.5 14.8732 0.5Z"
                fill="#F6F6F0"
              />
              <path
                d="M13.923 9.6163L11.4078 9.61701L11.4078 7.10254C11.4085 6.61958 11.0161 6.22714 10.5331 6.22785C10.048 6.22643 9.6577 6.61817 9.6577 7.10183L9.657 9.61701L7.1404 9.6163C6.65674 9.6163 6.26642 10.0095 6.26501 10.4917C6.26571 10.9747 6.65816 11.3671 7.1404 11.3671L9.6577 11.3671L9.657 13.878C9.657 14.3617 10.0494 14.7541 10.5317 14.7527C10.7742 14.7534 10.992 14.6559 11.1504 14.4975C11.3095 14.3384 11.4071 14.1206 11.4071 13.8787L11.4071 11.3671L13.9237 11.3664C14.1655 11.3664 14.3833 11.2688 14.5417 11.1104C14.7001 10.952 14.7977 10.7328 14.7984 10.4917C14.7984 10.008 14.4066 9.6163 13.923 9.6163Z"
                fill="#A19686"
              />
            </svg>
          )}
          <img className="image" height="48px" width="48px" src={Avt} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "13px",
              marginLeft: "0.5rem",
            }}
          >
            <h6 className="chatName">
              {activeChat?.firstName}
              {activeChat?.lastName}
              {activeChat?.groupName}
            </h6>
            <p style={{ margin: 0 }} className="chatText">
              {activeChat?.email}
            </p>
          </div>
        </div>
      )}
      <StyledDiv>
        <div>
          {(!activeChat && (
            <>
              <div style={{ padding: "20px 20px", backgrounColor: `none` }}>
                <div
                  className="search-container w-100"
                  style={{ background: `none`, borderColor: `#EAE6E0` }}
                >
                  <span className="search-icon h-100 d-flex justify-content-center">
                    <FaSearch />
                  </span>
                  <input
                    style={{ color: `#EAE6E0` }}
                    type="text"
                    placeholder="Search"
                    className="search-input"
                  />
                </div>
              </div>
              <div
                className="chatHeader"
                style={{ padding: "0px 20px", background: "none" }}
              >
                <p style={{ color: `black` }} className="chatListHeader m-0">
                  {t("Messages")}
                </p>
                <div className="chatListCreateMessageicon">
                  {theme === "light" ? (
                    <>
                      <svg
                        onClick={() => {
                          setHideMessageList(!hideMessagList);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <path
                          opacity="0.4"
                          d="M14.84 -0.000183105H6.17C2.78 -0.000183105 0.5 2.37982 0.5 5.91982V14.0898C0.5 17.6198 2.78 19.9998 6.17 19.9998H14.84C18.23 19.9998 20.5 17.6198 20.5 14.0898V5.91982C20.5 2.37982 18.23 -0.000183105 14.84 -0.000183105Z"
                          fill="#4B52FF"
                        />
                        <path
                          d="M13.89 9.11624L11.3748 9.11695L11.3748 6.60248C11.3755 6.11952 10.9831 5.72708 10.5001 5.72778C10.0151 5.72637 9.62475 6.11811 9.62475 6.60177L9.62404 9.11695L7.10744 9.11624C6.62378 9.11624 6.23346 9.50939 6.23205 9.99164C6.23275 10.4746 6.6252 10.867 7.10745 10.867L9.62475 10.867L9.62404 13.378C9.62404 13.8616 10.0165 14.2541 10.4987 14.2527C10.7413 14.2534 10.9591 14.1558 11.1174 13.9974C11.2765 13.8383 11.3741 13.6205 11.3741 13.3787L11.3741 10.867L13.8907 10.8663C14.1326 10.8663 14.3503 10.7687 14.5087 10.6104C14.6671 10.452 14.7647 10.2328 14.7654 9.99164C14.7654 9.50798 14.3737 9.11624 13.89 9.11624Z"
                          fill="#3A57E8"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Group">
                          <path
                            id="Fill 1"
                            d="M14.8732 0.5H6.2032C2.8132 0.5 0.533203 2.88 0.533203 6.42V14.59C0.533203 18.12 2.8132 20.5 6.2032 20.5H14.8732C18.2632 20.5 20.5332 18.12 20.5332 14.59V6.42C20.5332 2.88 18.2632 0.5 14.8732 0.5Z"
                            fill="#F6F6F0"
                          />
                          <path
                            id="Fill 4"
                            d="M13.923 9.6163L11.4078 9.61701L11.4078 7.10254C11.4085 6.61958 11.0161 6.22714 10.5331 6.22785C10.048 6.22643 9.6577 6.61817 9.6577 7.10183L9.657 9.61701L7.1404 9.6163C6.65674 9.6163 6.26642 10.0095 6.26501 10.4917C6.26571 10.9747 6.65816 11.3671 7.1404 11.3671L9.6577 11.3671L9.657 13.878C9.657 14.3617 10.0494 14.7541 10.5317 14.7527C10.7742 14.7534 10.992 14.6559 11.1504 14.4975C11.3095 14.3384 11.4071 14.1206 11.4071 13.8787L11.4071 11.3671L13.9237 11.3664C14.1655 11.3664 14.3833 11.2688 14.5417 11.1104C14.7001 10.952 14.7977 10.7328 14.7984 10.4917C14.7984 10.008 14.4066 9.6163 13.923 9.6163Z"
                            fill="#A19686"
                          />
                        </g>
                      </svg>
                    </>
                  )}
                </div>
              </div>
            </>
          )) ||
            (isAthlete && (
              <>
                <div style={{ padding: "20px 20px", background: "none" }}>
                  <div
                    className="search-container w-100"
                    style={{ background: "none" }}
                  >
                    <span className="search-icon h-100 d-flex justify-content-center">
                      <FaSearch />
                    </span>
                    <input
                      type="text"
                      placeholder="Search"
                      className="search-input"
                    />
                  </div>
                </div>
                <div
                  className="chatHeader"
                  style={{ padding: "0px 20px", background: "none" }}
                >
                  <p className="chatListHeader m-0">{t("Messages")}</p>
                  <div className="chatListCreateMessageicon">
                    {theme === "light" ? (
                      <>
                        <svg
                          onClick={() => {
                            setHideMessageList(!hideMessagList);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            opacity="0.4"
                            d="M14.84 -0.000183105H6.17C2.78 -0.000183105 0.5 2.37982 0.5 5.91982V14.0898C0.5 17.6198 2.78 19.9998 6.17 19.9998H14.84C18.23 19.9998 20.5 17.6198 20.5 14.0898V5.91982C20.5 2.37982 18.23 -0.000183105 14.84 -0.000183105Z"
                            fill="#4B52FF"
                          />
                          <path
                            d="M13.89 9.11624L11.3748 9.11695L11.3748 6.60248C11.3755 6.11952 10.9831 5.72708 10.5001 5.72778C10.0151 5.72637 9.62475 6.11811 9.62475 6.60177L9.62404 9.11695L7.10744 9.11624C6.62378 9.11624 6.23346 9.50939 6.23205 9.99164C6.23275 10.4746 6.6252 10.867 7.10745 10.867L9.62475 10.867L9.62404 13.378C9.62404 13.8616 10.0165 14.2541 10.4987 14.2527C10.7413 14.2534 10.9591 14.1558 11.1174 13.9974C11.2765 13.8383 11.3741 13.6205 11.3741 13.3787L11.3741 10.867L13.8907 10.8663C14.1326 10.8663 14.3503 10.7687 14.5087 10.6104C14.6671 10.452 14.7647 10.2328 14.7654 9.99164C14.7654 9.50798 14.3737 9.11624 13.89 9.11624Z"
                            fill="#3A57E8"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group">
                            <path
                              id="Fill 1"
                              d="M14.8732 0.5H6.2032C2.8132 0.5 0.533203 2.88 0.533203 6.42V14.59C0.533203 18.12 2.8132 20.5 6.2032 20.5H14.8732C18.2632 20.5 20.5332 18.12 20.5332 14.59V6.42C20.5332 2.88 18.2632 0.5 14.8732 0.5Z"
                              fill="#F6F6F0"
                            />
                            <path
                              id="Fill 4"
                              d="M13.923 9.6163L11.4078 9.61701L11.4078 7.10254C11.4085 6.61958 11.0161 6.22714 10.5331 6.22785C10.048 6.22643 9.6577 6.61817 9.6577 7.10183L9.657 9.61701L7.1404 9.6163C6.65674 9.6163 6.26642 10.0095 6.26501 10.4917C6.26571 10.9747 6.65816 11.3671 7.1404 11.3671L9.6577 11.3671L9.657 13.878C9.657 14.3617 10.0494 14.7541 10.5317 14.7527C10.7742 14.7534 10.992 14.6559 11.1504 14.4975C11.3095 14.3384 11.4071 14.1206 11.4071 13.8787L11.4071 11.3671L13.9237 11.3664C14.1655 11.3664 14.3833 11.2688 14.5417 11.1104C14.7001 10.952 14.7977 10.7328 14.7984 10.4917C14.7984 10.008 14.4066 9.6163 13.923 9.6163Z"
                              fill="#A19686"
                            />
                          </g>
                        </svg>
                      </>
                    )}
                  </div>
                </div>
              </>
            ))}
          {!isAthlete && activeChat && (
            <Chats
            refresh={refresh}
              SenderChat={SenderChat}
              isGroupChat={isGroupChat}
              user={activeChat}
            />
          )}
          {(!activeChat &&
            chatUsers.map((user, index) => {
              console.log("user?.sender[0]?.message",user?.sender[0]?.message)
              return (
                <div
                  style={{
                    display: !isAthlete && hideMessagList ? "none" : "flex",
                    cursor: "pointer",
                    padding: "10px 25px",
                   
                  }}
                  
                  key={index}
                  className="chat1"
                  onClick={() =>  {setIsGroupChat(false);handleChatClick(user)}}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="image"
                      height="48px"
                      width="48px"
                      src={Avt}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                        marginLeft: "0.5rem",
                      }}
                    >
                      <h6 style={{ color: `black` }} className="chatName">
                        {user?.firstName}
                        {user?.lastName}
                      </h6>
                      {typerId !== auth?.currentUser?.uid && isTyping ? (
                        <p className="typing"> Typing...</p>
                      ) : (
                       
                        <p style={{ margin: 0 }} className="chatText">
                          {user?.sender[0]?.message}
                        </p>
                      
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                    }}
                  >
                    {
                      user?.sender?.length > 0 && <>
                      <div className="chatNumsCon">
                      <p className="chatNums">{user?.sender?.length}</p>
                    </div>
                      </>
                    }
                    
                    {/* <p className="chatTime">{user?.phone}</p> */}
                  </div>
                </div>
              );
            })) ||
            (isAthlete &&
              activeChat &&
              chatUsers.map((user, index) => {
                return (
                  <div
                    
                    key={index}
                    className="chat1"
                    style={{
                      display: !isAthlete && hideMessagList ? "none" : "flex",
                      cursor: "pointer",
                      padding: "10px 25px",
                       backgroundColor : activeChat?.id===user?.id && "#F6F6F0 "
                    }}
                    onClick={() =>{setIsGroupChat(false);handleChatClick(user)}}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className="image"
                        height="48px"
                        width="48px"
                        src={Avt}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                          marginLeft: "0.5rem",
                        }}
                      >
                        <h6 className="chatName">
                          {user?.firstName}
                          {user?.lastName}
                        </h6>
                        {typerId !== auth?.currentUser?.uid && isTyping ? (
                          <p className="typing"> Typing...</p>
                        ) : (
                          <p style={{ margin: 0 }} className="chatText">
                            {user?.sender[0]?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                     {
                      user?.sender?.length > 0 && <>
                      <div className="chatNumsCon">
                      <p className="chatNums">{user?.sender?.length}</p>
                    </div>
                      </>
                    }
                      {/* <p className="chatTime">{user?.phone}</p> */}
                    </div>
                  </div>
                );
              }))}
          {!activeChat &&
            groupChat.length > 0 &&
            groupChat.map((group, index) => (
              <div
                style={{
                  display: !isAthlete && hideMessagList && "none",
                  cursor: "pointer",
                  padding: "10px 25px",
                }}
                key={index}
                className="chat1"
                onClick={() => {
                  setIsGroupChat(true)
                  handleChatClick(group);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="image"
                    height="48px"
                    width="48px"
                    src={GroupChat}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "13px",
                      marginLeft: "0.5rem",
                    }}
                  >
                    <h6 style={{ color: `black` }} className="chatName">
                      {group?.groupName || group.groups?.groupName}
                    </h6>
                    {typerId !== auth?.currentUser?.uid && isTyping ? (
                      <p className="typing"> Typing...</p>
                    ) : (
                      <p style={{ margin: 0 }} className="chatText">
                        {/* {group?.lastUnreadMessage} */}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <p className="chatNums"> 6:00 pm</p>
                  </div>
                </div>
              </div>
            ))}
          {isAthlete &&
            activeChat &&
            groupChat.length > 0 &&
            groupChat.map((group, index) => (
              <div
                style={{
                  display: !isAthlete && hideMessagList && "none",
                  cursor: "pointer",
                  padding: "10px 25px",
                    backgroundColor : activeChat?.id===group?.id && "#F6F6F0 "
                }}
                key={index}
                className="chat1"
                onClick={() => {
                  setIsGroupChat(true)
                  handleChatClick(group);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="image"
                    height="48px"
                    width="48px"
                    src={GroupChat}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "13px",
                      marginLeft: "0.5rem",
                    }}
                  >
                    <h6 className="chatName">
                      {group?.groupName || group.groups?.groupName}
                    </h6>
                    {typerId !== auth?.currentUser?.uid && isTyping ? (
                      <p className="typing"> Typing...</p>
                    ) : (
                      <p style={{ margin: 0 }} className="chatText">
                        {/* {user?.lastUnreadMessage} */}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <p className="chatNums"> 6:00 pm</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </StyledDiv>
   
    </>
  );
};

export default ChatList;
