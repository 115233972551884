import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import SvgIcon from '../../../../../../assessts/Svg/svgIcons';

const IndexProgramSessionBlock = (props) => {
    const { blockLabel,
        blockIndex,
        removeBlock,
        exerciseList,
        handleSetChange,
        handleAddSet,
        blockData,
        handleExerciseSelectionChange,
        handleURLChange,
        handleInstructionsChange,
    } = props;


    return (
        <>
            <Container className="m-0 p-0">
                <Row className="m-0 p-0">
                    <Col sm={12} md={12} lg={12} className="m-0 p-0">
                        <Form.Group className="mt-4 mb-3 text d-flex flex-column  align-items-end">
                            <div className="deleteIcon" onClick={() => removeBlock(blockIndex)}
                            >
                                <SvgIcon iconName="deleteIcon" size={25} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: "100%", marginTop: '1rem' }}>
                                <div className="exerciseNumbers">{blockLabel}</div>
                                <Button className="customButton" >LeaderBoard</Button></div>
                        </Form.Group>
                        <Form.Group className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
                            <div className="exerCat">
                                <Form.Label style={{ fontSize: "16px" }}>Exercise Category</Form.Label>
                                <Form.Select>
                                    <option>Power</option>
                                    <option>Power</option>
                                    <option>Power</option>
                                </Form.Select>
                            </div>
                            <div className="exerAdd">
                                <Form.Label style={{ fontSize: "16px" }}>Add Exercise</Form.Label>
                                <Form.Select
                                    name="selectedExercise"
                                    value={blockData.selectedExercise ? blockData.selectedExercise.title : ''}
                                    onChange={(e) => handleExerciseSelectionChange(blockIndex, e.target.value)}
                                >
                                    {exerciseList.map((exercise, index) => (
                                        <option key={index} value={exercise.title}>{exercise.title}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasic" className="mt-4">
                            <Form.Label style={{ fontSize: "16px" }}>Instructions</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={blockData.instructions || ""}
                                onChange={(e) => handleInstructionsChange(blockIndex, e.target.value)}
                                placeholder="ExerciseInstruction"
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasic" className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
                            <div className="videpurl mb-3">
                                <Form.Label style={{ fontSize: "16px" }}>Video URL</Form.Label>
                                <Form.Control
                                    style={{ background: "var(--neutral-color-grey-natural, #FAFAFA)" }}
                                    type="text"
                                    value={blockData.videoURL || ""}
                                    onChange={(e) => handleURLChange(blockIndex, e.target.value)}
                                    placeholder="www.youtube.com"
                                />
                            </div>
                            <div className="exerciseSets">
                                <div className="exerciseSetsHeader">
                                    <h5
                                        style={{
                                            fontSize: "19px",
                                            color: " var(--light-mode-secondary-232-d-42-text-1, #232D42)",
                                        }}
                                    >
                                        Sets
                                    </h5>
                                    <div className="exerType">
                                        <h5>Reps </h5>
                                        <SvgIcon iconName="dropDown" size={14} />
                                    </div>
                                    <div className="exerType">
                                        <h5>Wegith </h5>
                                        <SvgIcon iconName="dropDown" size={14} />
                                    </div>
                                </div>

                                <div >
                                    {blockData.sets.map((set, setIndex) => (
                                        <div className="exerBodyx mt-0" key={setIndex}>
                                            <div className="frstDv">
                                                <input
                                                    type="text"
                                                    name={`set-${set.id}`}
                                                    value={set.title}
                                                />
                                            </div>
                                            <div className="frstDv">
                                                <input
                                                    type="text"
                                                    name="reps"
                                                    value={set.reps}
                                                    onChange={(e) => handleSetChange(blockIndex, setIndex, 'reps', e.target.value)}
                                                />
                                            </div>
                                            <div className="divchek">
                                                <input
                                                    type="text"
                                                    name="weight"
                                                    value={set.weight}
                                                    onChange={(e) => handleSetChange(blockIndex, setIndex, 'weight', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                <div className="iconicBtns">
                                    {blockData.sets.length > 0 && <div className="plusMinus">
                                        <SvgIcon iconName="minusButton" size={16} />
                                    </div>}
                                    <div className="pluscircle" onClick={() => handleAddSet(blockIndex)}>
                                        <SvgIcon iconName="plusButton" size={16} />
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Container></>
    )
}

export default IndexProgramSessionBlock