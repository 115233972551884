// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_main__fh--u{
    background-color: white;
    /* font-family: 'Hurme Geometric Sans 2'; */
    
}`, "",{"version":3,"sources":["webpack://./src/pages/LandingPages/Pages/Home/home.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,2CAA2C;;AAE/C","sourcesContent":[".main{\r\n    background-color: white;\r\n    /* font-family: 'Hurme Geometric Sans 2'; */\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `home_main__fh--u`
};
export default ___CSS_LOADER_EXPORT___;
