import React from 'react'
import SvgIcons from '../../ReusableUi/SvgIcon'
import styles from "./atheleteSessions.module.css"

const Header = (props) => {
  const { data } = props;
  return (
    <div className='d-flex justify-content-between px-lg-5 my-3 px-sm-0 px-md-3  align-content-center'>
      <div>
        <SvgIcons iconName="backArrow" size={20} />
      </div>
      <div>
        <span className={styles.traningGroupTitle}>{data?.title}</span>
      </div>
      <div>
      </div>
    </div>
  )
}

export default Header