import React from 'react'
import style from './sec.module.css'
import { LeftRotPh, RightRotPh, StrPh } from '../../../../../../assessts/LandingPage'

const Sec = () => {
    return (
        <div className={`${style.main} d-flex align-items-center justify-content-center`}>
            <div className={`${style.ImgWrapper} d-flex align-items-center justify-content-center`}>
                <img src={StrPh} alt="StrPh" className={style.strImage} />
                <img src={LeftRotPh} alt="LeftRotPh" className={style.leftImage} />
                <img src={RightRotPh} alt="RightRotPh" className={style.rightImage} />
            </div>
            <div className={`${style.TEXT} d-flex flex-column `}>
                <h1>Embrace healthy competition and global connections</h1>
                <p>TrainingApp's scoring system allows you to compete against friends and athletes from around the world. See where you stand and get motivated by comparing your performance against others. It's time to elevate your game and join a vibrant community of athletes striving for excellence.</p>
            </div>
        </div>
    )
}

export default Sec
