import React, { useContext, useState } from "react";
import "./exerciseTable.css";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../../context/sessionContext";
import CustomPagination from "../../Common/Pagination/pagination";
import moment from "moment/moment";
import CreateExerciseModal from "../../modals/createExersiceModal";
import Skeleton from "react-loading-skeleton";
import DeleteModal from "../../modules/Modal/DeleteModal";
import SvgIcons from "../../ReusableUi/SvgIcon";
import Tooltip from "../../ReusableComponent/Tooltip";
const ExerciseTable = ({
  setSelectedExercise,
  selectedExercise,
  showDeleteCheckBox,
  setRefreshData,
  isEmptyDataForExercise,
  exerciseLists,
  totalPages,
  setEditEditExercise,
  isEditExercise,
}) => {
  const { t } = useTranslation();
  const {
    isActivePaginationExercise,
    isLoader,
    handleActivePaginationExercise,
  } = useContext(SessionContext);
  const [isOpenExerciseModal, setOpenExerciseModal] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [getId, setId] = useState();
  const handleEditExercise = (exerciseData) => {
    setEditEditExercise(exerciseData);
    setOpenExerciseModal(true);
    setRefreshData((prev) => !prev);
  };

  const handleDeleteExercise = (exerciseData) => {
    setOpenModal(!isOpenModal);
    setId(exerciseData);
    setRefreshData((prev) => !prev);
  };

  const handleCloseExerciseModal = () => {
    setOpenExerciseModal(false);
    setRefreshData((prev) => !prev);
  };
  const handleCheckboxChange = (ExerciseId) => {
    setSelectedExercise((prevSelectedExercise) => {
      if (prevSelectedExercise.includes(ExerciseId)) {
        return prevSelectedExercise.filter((id) => id !== ExerciseId);
      } else {
        return [...prevSelectedExercise, ExerciseId];
      }
    });
  };
  return (
    <div className="table-container ">
      <table>
        {isLoader ? (
          Array.from({ length: 5 }).map((_, index) => (
            <>
              <br />
              <tr key={index}>
                <td>{<Skeleton height={30} width={50} />}</td>
                <td>{<Skeleton height={30} width={100} />}</td>
                <td>{<Skeleton height={30} width={100} />}</td>
                <td>{<Skeleton height={30} width={50} />}</td>
                <td>{<Skeleton height={30} width={150} />}</td>
              </tr>
            </>
          ))
        ) : isEmptyDataForExercise ? (
          <div
            className="w-full d-flex flex-column justify-content-center align-items-center"
            style={{ height: "20vw" }}
          >
            <SvgIcons iconName="noSessionPlane" />
            <div className="w-100 d-flex flex-column justify-content-center">
              <h4 className="noSessionHeading d-flex justify-content-center">
                No Exercise
              </h4>
              <div className="d-flex justify-content-center">
                {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
              </div>
            </div>
          </div>
        ) : (
          <table>
            <thead>
              <tr
                className="tablehead"
                style={{
                  padding: " 0px 64px 0px 40px",
                }}
              >
                <th
                  style={{
                    fontWeight: 700,
                    textAlign: "start",
                    paddingLeft: "1rem ",
                    paddingBottom: "0.5rem",
                    fontSize: "14px",
                    paddingTop: "0.5rem",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  {t("Title")}
                </th>
                <th
                  style={{
                    fontWeight: 700,
                    fontSize: "14px",
                    textAlign: "center",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  {t("URL")}
                </th>
                <th
                  style={{
                    fontWeight: 700,
                    fontSize: "14px",
                    textAlign: "center",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  {t("Instruction")}
                </th>
                <th
                  style={{
                    fontWeight: 700,
                    fontSize: "14px",
                    textAlign: "center",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  {t("Date")}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontFamily: "Hurme Geometric Sans 2",
                    fontWeight: 700,
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <br />
            <tbody style={{ borderStyle: "none" }} className="tableBody">
              {exerciseLists?.map((exerciseData, index) => {
                console.log(exerciseData);
                return (
                  <>
                    <tr key={index}>
                      <td className="chk" style={{ paddingLeft: "2rem" }}>
                        {showDeleteCheckBox && (
                          <>
                            <label class="containerInput">
                              <input
                                type="checkbox"
                                checked={selectedExercise.includes(
                                  exerciseData?.id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(exerciseData?.id)
                                }
                              />
                              <span class="checkmark"></span>
                            </label>
                          </>
                        )}

                        <p
                          className="chkname"
                          id="as"
                          style={{ margin: 0, fontSize: "16px" }}
                        >
                          {exerciseData.title}
                        </p>
                      </td>

                      <td
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontFamily: "Hurme Geometric Sans 2",
                        }}
                      >
                        <a
                          href={exerciseData.URL}
                          target="_blank"
                          className="chkname"
                          id="as"
                          style={{ margin: 0, fontSize: "16px" }}
                        >
                          {exerciseData.URL ? exerciseData.URL : "N/A"}
                        </a>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontFamily: "Hurme Geometric Sans 2",
                        }}
                      >
                        <p
                          className="chkname"
                          id="as"
                          style={{
                            margin: 0,
                            fontSize: "16px",
                            width: "300px",
                          }}
                        >
                          {exerciseData.instructions &&
                          exerciseData.instructions.length > 100
                            ? `${exerciseData.instructions.slice(0,69)} ....`
                            : exerciseData.instructions || "N/A"}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontFamily: "Hurme Geometric Sans 2",
                        }}
                      >
                        <p
                          className="chkname"
                          id="as"
                          style={{ margin: 0, fontSize: "16px" }}
                        >
                          {moment(exerciseData.createdAt).format("D/MM/YYYY")}
                        </p>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingRight: "0.5rem",
                            gap: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <div onClick={() => handleEditExercise(exerciseData)} >
                          <Tooltip
                          content={"Edit Exercise"}
                          children={<div
                            className="indexEdit"
                            
                          >
                            <svg
                              id="tableiconindex"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.9962 6.71081L6.35355 14.3535C6.25979 14.4473 6.13261 14.4999 6 14.4999H3C3 14.4999 2.58579 14.4999 2.29289 14.2071C2.29289 14.2071 2 13.9142 2 13.4999L2 10.7062C2 10.7062 1.99931 10.51 2.07451 10.3269C2.07451 10.3269 2.14971 10.1438 2.28932 10.0035L9.7902 2.50264C9.7902 2.50264 9.92847 2.36222 10.1138 2.28462C10.1138 2.28462 10.2991 2.20703 10.5 2.20703C10.5 2.20703 10.7009 2.20703 10.8862 2.28462C10.8862 2.28462 11.0715 2.36222 11.2125 2.50536L13.9973 5.29014C13.9973 5.29014 14.1377 5.42842 14.2153 5.61373C14.2153 5.61373 14.2929 5.79904 14.2929 5.99995C14.2929 5.99995 14.2929 6.20085 14.2153 6.38616C14.2153 6.38616 14.1385 6.56971 13.9973 6.70975L13.9962 6.71081ZM13.2929 5.99995L13.2902 5.99725L10.5 3.20703L10.4973 3.20975L3 10.707V13.4999H5.79289L13.2902 6.00264L13.2929 5.99995Z"
                                fill="#26CF18"
                              />
                              <path
                                d="M13.5 13.5H5.99999C5.72385 13.5 5.49999 13.7239 5.49999 14C5.49999 14.2761 5.72386 14.4999 6 14.4999L13.5 14.5C13.7761 14.5 14 14.2761 14 14C14 13.7239 13.7761 13.5 13.5 13.5Z"
                                fill="#26CF18"
                              />
                              <path
                                d="M11.6462 8.35329C11.74 8.44706 11.8674 8.5 12 8.5C12.1326 8.5 12.2598 8.44732 12.3535 8.35355C12.4473 8.25979 12.5 8.13261 12.5 8C12.5 7.86739 12.4473 7.74021 12.3535 7.64645L8.85361 4.14651C8.75978 4.05268 8.6326 4 8.49999 4C8.36739 4 8.24021 4.05268 8.14644 4.14645C8.05267 4.24021 7.99999 4.36739 7.99999 4.5C7.99999 4.63261 8.05267 4.75979 8.14644 4.85355L11.6462 8.35329Z"
                                fill="#26CF18"
                              />
                            </svg>
                          </div>}
                          />
                          </div>
                          <div  onClick={() =>
                              handleDeleteExercise(exerciseData?.id)
                            } >
                          <Tooltip
                          content={"Delete Exercise"}
                          children={ <div
                            className="indexUpload"
                           
                          >
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.3223 3.5H3.32227C3.04612 3.5 2.82227 3.72386 2.82227 4C2.82227 4.27614 3.04612 4.5 3.32227 4.5H14.3223C14.5984 4.5 14.8223 4.27614 14.8223 4C14.8223 3.72386 14.5984 3.5 14.3223 3.5Z"
                                fill="#DA8B2E"
                              />
                              <path
                                d="M6.82227 7V11C6.82227 11.2761 7.04612 11.5 7.32227 11.5C7.59841 11.5 7.82227 11.2761 7.82227 11V7C7.82227 6.72386 7.59841 6.5 7.32227 6.5C7.04612 6.5 6.82227 6.72386 6.82227 7Z"
                                fill="#DA8B2E"
                              />
                              <path
                                d="M9.82227 7V11C9.82227 11.2761 10.0461 11.5 10.3223 11.5C10.5984 11.5 10.8223 11.2761 10.8223 11V7C10.8223 6.72386 10.5984 6.5 10.3223 6.5C10.0461 6.5 9.82227 6.72386 9.82227 7Z"
                                fill="#DA8B2E"
                              />
                              <path
                                d="M4.82227 13.5V4C4.82227 3.72386 4.59841 3.5 4.32227 3.5C4.04612 3.5 3.82227 3.72386 3.82227 4V13.5C3.82227 13.9142 4.11516 14.2071 4.11516 14.2071C4.40805 14.5 4.82227 14.5 4.82227 14.5H12.8223C13.2365 14.5 13.5294 14.2071 13.5294 14.2071C13.8223 13.9142 13.8223 13.5 13.8223 13.5V4C13.8223 3.72386 13.5984 3.5 13.3223 3.5C13.0461 3.5 12.8223 3.72386 12.8223 4V13.5H4.82227Z"
                                fill="#DA8B2E"
                              />
                              <path
                                d="M6.26161 1.93934C5.82227 2.37868 5.82227 3 5.82227 3V4C5.82227 4.27614 6.04612 4.5 6.32227 4.5C6.59841 4.5 6.82227 4.27614 6.82227 4V3C6.82227 2.79289 6.96871 2.64645 6.96871 2.64645C7.11516 2.5 7.32227 2.5 7.32227 2.5H10.3223C10.5294 2.5 10.6758 2.64645 10.6758 2.64645C10.8223 2.79289 10.8223 3 10.8223 3V4C10.8223 4.27614 11.0461 4.5 11.3223 4.5C11.5984 4.5 11.8223 4.27614 11.8223 4V3C11.8223 2.37868 11.3829 1.93934 11.3829 1.93934C10.9436 1.5 10.3223 1.5 10.3223 1.5H7.32227C6.70095 1.5 6.26161 1.93934 6.26161 1.93934Z"
                                fill="#DA8B2E"
                              />
                            </svg>
                          </div>}
                          />
                          </div>
                         
                        </div>
                      </td>
                    </tr>
                    <br />
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </table>
      <CustomPagination
        currentPage={isActivePaginationExercise}
        totalPages={totalPages}
        onPageChange={handleActivePaginationExercise}
      />
      {isOpenModal && (
        <DeleteModal
          isOpen={isOpenModal}
          onClose={handleDeleteExercise}
          sessionId={getId}
          type={"exerciseDelete"}
        />
      )}
      {isOpenExerciseModal && (
        <CreateExerciseModal
          isEditExercise={isEditExercise}
          setEditEditExercise={setEditEditExercise}
          isOpen={isOpenExerciseModal}
          onClose={handleCloseExerciseModal}
        />
      )}
    </div>
  );
};

export default ExerciseTable;
