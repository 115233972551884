import React from 'react'
import "./previewSession.css";

const ExerciseTypes = () => {
  return (
    <div className="con">
    <div className="exerTypeContainer">
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="exerType">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <circle
                  cx="10.5"
                  cy="10.5"
                  r="9.5"
                  transform="rotate(-90 10.5 10.5)"
                  stroke="#3A57E8"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
          <div className="preLine"></div>
        </div>
        <div className='exerTp' style={{ paddingTop: "1rem" }}>
          <h6 id='asf'>Bench press</h6>
          <p id='as'>2 sets</p>
        </div>
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="exerTypes">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
              >
                <circle
                  cx="10.707"
                  cy="10.5"
                  r="9.5"
                  transform="rotate(-90 10.707 10.5)"
                  stroke="#08B1BA"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
          <div className="preLine"></div>
        </div>
        <div className='exerTp' style={{ paddingTop: "1rem" }}>
          <h6 id='asf'>Bicep</h6>
          <p id='as'>2 sets</p>
        </div>
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="exerTypes">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
              >
                <circle
                  cx="11"
                  cy="10.5"
                  r="9.5"
                  transform="rotate(-90 11 10.5)"
                  stroke="#3A57E8"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
          <div className="preLine"></div>
        </div>
        <div className='exerTp' style={{ paddingTop: "1rem" }}>
          <h6 id='asf'>Tricep</h6>
          <p id='as'>2 sets</p>
        </div>
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="exerTypes">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
              >
                <circle
                  cx="10.6406"
                  cy="10.5"
                  r="9.5"
                  transform="rotate(-90 10.6406 10.5)"
                  stroke="#08B1BA"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
          <div className="preLine"></div>
        </div>
        <div className='exerTp' style={{ paddingTop: "1rem" }}>
          <h6 id='asf'>Bench press</h6>
          <p id='as'>2 sets</p>
        </div>
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="exerTypes">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
              >
                <circle
                  cx="10.6406"
                  cy="10.5"
                  r="9.5"
                  transform="rotate(-90 10.6406 10.5)"
                  stroke="#08B1BA"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='exerTp' style={{ paddingTop: "1rem" }}>
          <h6 id='asf'>Bench press</h6>
          <p id='as'>2 sets</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ExerciseTypes
