import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './createExercise.css'
import { auth } from '../../../firebase';
import { createExercise, editExercise } from '../../../apis';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../loadingSpinner';
import axios from 'axios';
import { createAdminExercise, editAdminExercise } from '../../../services/store/adminApi';

const CreateExercise = (props) => {


  const { isEditExercise, onClick, activeTab } = props;



  // State for loading and validation errors
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [firstSelectValue, setFirstSelectValue] = useState();
  const [secondSelectValue, setSecondSelectValue] = useState();
  console.log(isEditExercise,"isEditExercise")
  const [exerciseData, setExerciseData] = useState({
    title: isEditExercise?.title || '',
    URL: isEditExercise?.URL || '',
    instructions: isEditExercise?.instructions || '',
    createdBy: auth.currentUser.uid,
    title1: isEditExercise?.title1 || 'Reps', 
    title2:  isEditExercise?.title2 || 'Weight(lb)', 
  });
  useEffect(()=>{
    setFirstSelectValue(isEditExercise?.title1)
    setSecondSelectValue(isEditExercise?.title2)
  },[isEditExercise])
  useEffect(() => {
    setExerciseData((prevData) => ({
      ...prevData,
      title1: firstSelectValue,
      title2: secondSelectValue,
    }));
  }, [firstSelectValue, secondSelectValue]);

  const handleFirstSelectChange = (e) => {
    const value = e.target.value;
    setFirstSelectValue(value);
  };
  
  const handleSecondSelectChange = (e) => {
    const value = e.target.value;
    setSecondSelectValue(value);
  };

  /**
  * Handles input changes and updates form data state.
  * @param {Object} e - Event object
  * @param {string} field - The field to update
  */
  const handleInputChange = (e, field) => {
    setExerciseData({ ...exerciseData, [field]: e.target.value });
    setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  /**
 * Validates form data and returns any errors found.
 * @param {Object} data - Form data to validate
 * @return {Object} - Object containing validation errors
 */
  const validateFormData = (data) => {
    const errors = {};
    if (!data.title) errors.title = "Title is required";
    return errors;
  };

  const handleSave = async () => {
    try {
        // Check if it's a new program creation
        if (!isEditExercise) {
            // Validate form data
            const validationErrors = validateFormData(exerciseData);
            // Update state with validation errors
            setValidationErrors(validationErrors);
            // Check for any validation errors
            if (Object.keys(validationErrors).length > 0) {
                return;
            }
        }

        // Check if dropdown values are selected
        if (!firstSelectValue) {
            exerciseData.title1 = "Reps";
        } else {
            exerciseData.title1 = firstSelectValue;
        }

        if (!secondSelectValue) {
            exerciseData.title2 = "Reps";
        } else {
            exerciseData.title2 = secondSelectValue;
        }

        // Indicate loading state
        setIsLoading(true);

        // Get authorization token
        const token = await auth.currentUser.getIdToken(true);

        // Determine the API endpoint and payload based on whether it's a new program or an edit
        const endpoint = activeTab === "manageExercise"
            ? (isEditExercise ? editAdminExercise : createAdminExercise)
            : (isEditExercise ? editExercise : createExercise);

        const payload = { ...exerciseData }; // Ensure the updated title1 and title2 are included
        console.log(payload);

        // Make the API call
        await makeApiCall(endpoint, payload, token);
        onClick();

        // Show success message
        toast.success(isEditExercise ? "Exercise edited successfully" : "Exercise created successfully");
    } catch (err) {
        // Handle errors
        setIsLoading(false);
        console.error(err);
        toast.error("An error occurred while saving the program");
    }
};



  /**
   * Makes an API call to the given endpoint with the provided payload and token.
   * @param {string} endpoint - API endpoint
   * @param {Object} payload - Data to send in the API request
   * @param {string} token - Authorization token
   */
  const makeApiCall = async (endpoint, payload, token) => {
    console.log(payload)
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token }
    };

    if (isEditExercise) {
      config.params = { exerciseId: isEditExercise.id };
    }

    if (isEditExercise) {
      // For editing (patch request)
      await axios.patch(url, payload, config);
    } else {
      // For creation (post request)
      await axios.post(url, payload, config);
    }
  };


  return (
    <Container>
      <Row className='mb-3'>
        <Col xs={12} md={12} className='p-0'>
          <Form.Group controlId='frmB'>
            <Form.Label style={{ color: `var(--primary-text-color,black)` }} className='customInputLabel'>Title</Form.Label>
            <Form.Control style={{ backgroundColor: `var(--primary-color,#F9F9F9)`, color: `var(--primary-text-color,black)` }} value={exerciseData.title} type="text" placeholder=" Title" className='customInput' onChange={(e) => handleInputChange(e, 'title')} />
            {validationErrors.title && (
              <Form.Text className="text-danger">
                {validationErrors.title}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col xs={12} md={6} className='p-0'>
          <Form.Group className="pr-2" style={{paddingRight: "10px"}}>
            <div>
            <Form.Label
                  style={{
                    fontSize: "16px",
                    color: `var(--primary-text-color,black)`,
                  }}
                >
                  Default Parameter 1
                </Form.Label>
              <Form.Select
                style={{ backgroundColor: '#F6F6F0', border: 'none', width: "100%" }}
                value={firstSelectValue}
                onChange={handleFirstSelectChange}
              >
                <option value="">Reps</option>
                <option>Weight(lb)</option>
                <option>Weight(kg)</option>
                <option>Weight(%)</option>
                <option>Weight(LWP+)</option>
                <option>Time(min::sec)</option>
                <option>Seconds(s)</option>
                <option>Distance(miles)</option>
                <option>Distance(yd)</option>
                <option>Distance(ft)</option>
                <option>Distance(inches)</option>
                <option>Height(inches)</option>
                <option>Calories(call)</option>
                <option>RPE</option>
                <option>Watts</option>
                <option>Velocity(m/s)</option>
                <option>Other Members</option>
              </Form.Select>
            </div>
          </Form.Group>



        </Col>
        <Col xs={12} md={6} className='p-0'>
          <Form.Group className="w-90 pr-2" >
            <div>
            <Form.Label
                  style={{
                    fontSize: "16px",
                    color: `var(--primary-text-color,black)`,
                  }}
                >
                  Default Parameter 2
                </Form.Label>
              <Form.Select
                style={{ backgroundColor: '#F6F6F0', border: 'none', width: '100%' }}
                value={secondSelectValue}
                onChange={handleSecondSelectChange}
              >
                <option>Reps</option>
                <option>Weight(lb)</option>
                <option>Weight(kg)</option>
                <option>Weight(%)</option>
                <option>Weight(LWP+)</option>
                <option>Time(min::sec)</option>
                <option>Seconds(s)</option>
                <option>Distance(miles)</option>
                <option>Distance(yd)</option>
                <option>Distance(ft)</option>
                <option>Distance(inches)</option>
                <option>Height(inches)</option>
                <option>Calories(call)</option>
                <option>RPE</option>
                <option>Watts</option>
                <option>Velocity(m/s)</option>
                <option>Other Members</option>
              </Form.Select>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col xs={12} md={12} className='p-0'>
          <Form.Group controlId='frmB'>
            <Form.Label style={{ color: `var(--primary-text-color,black)` }} className='customInputLabel'>URL</Form.Label>
            <Form.Control style={{ backgroundColor: `var(--primary-color,#F9F9F9)`, color: `var(--primary-text-color,black)` }} value={exerciseData.URL} type="text" placeholder="Enter youtube and vimeo URL" className='customInput' onChange={(e) => handleInputChange(e, 'URL')} />
          </Form.Group>
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col xs={12} className='p-0'>
          <Form.Group controlId='frmB'>
            <Form.Label style={{ color: `var(--primary-text-color,black)` }} className='customInputLabel'>Instructions</Form.Label>
            <Form.Control style={{ backgroundColor: `var(--primary-color,#F9F9F9)`, color: `var(--primary-text-color,black)` }} value={exerciseData.instructions} as="textarea" rows={3} placeholder="Instructions" className='customInput' onChange={(e) => handleInputChange(e, 'instructions')} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className='d-flex justify-content-end gap-4 mt-3' >

          <Button style={{ backgroundColor: `var(--primary-color,#A19686)`, color: `var(--primary-text-color,white)`, height: "48px", width: "120px", borderRadius: "8px", border: "none", fontSize: "19px", fontWeight: "600", lineHeight: "24px" }} onClick={onClick}>Cancel</Button>
          <Button style={{ backgroundColor: `var(--secondary-color,#A19686)`, color: `var(--secondary-text-color,white)`, height: "48px", width: "120px", borderRadius: "8px", border: "none", fontSize: "19px", fontWeight: "600", lineHeight: "24px" }} onClick={handleSave}>
            {isLoading ?
              <LoadingSpinner size="sm" />
              : isEditExercise ? 'Update' : 'Save'}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateExercise;
