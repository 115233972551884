// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invitattion{
    display:flex;
    width: 100%;
    height: 100vh;
flex-direction: column;
background-color: var(--background-color);
}


[data-theme='dark'] .invitattion {
    background-color:black; /* Define a variable for dark background color */
    color: white   ;
    /* Add other dark theme styles here */
  }
`, "",{"version":3,"sources":["webpack://./src/pages/Invitaion/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;AACjB,sBAAsB;AACtB,yCAAyC;AACzC;;;AAGA;IACI,sBAAsB,EAAE,gDAAgD;IACxE,eAAe;IACf,qCAAqC;EACvC","sourcesContent":[".invitattion{\r\n    display:flex;\r\n    width: 100%;\r\n    height: 100vh;\r\nflex-direction: column;\r\nbackground-color: var(--background-color);\r\n}\r\n\r\n\r\n[data-theme='dark'] .invitattion {\r\n    background-color:black; /* Define a variable for dark background color */\r\n    color: white   ;\r\n    /* Add other dark theme styles here */\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
