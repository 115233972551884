import React, { useState } from 'react'
import style from './frequently.module.css'
import { Minus, Plus } from '../../../../../../assessts/LandingPage';
import { Collapse } from 'react-bootstrap';

const Frequently = () => {

    const questions = [
        { question: "How do I grow my business?", answer: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." },
        { question: "Can I cancel my subscription?", answer: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." },
        { question: "How do I contact the support?", answer: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." },
        { question: "Is a credit card required?", answer: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." },
    ]

    const [openIndex, setOpenIndex] = useState(null);

    const handleDiscription = (index) =>{ //jab click kya to index yaha agya
        setOpenIndex( openIndex === index ? null : index); 
        // ye check krta k jis pe humne click kiya kya wo Pehle open to nai agr han to ye state ko null krdega agr nai to ye state me us question ka index dal dega jis pe humne click kya -- mtlb dubara click krne pe us question ko band kr dega -- index is lye k ye array se question answer arhe hai is lye
        // jab onclick se index function me jayega to condition check krlegi agr pehle state khali hai to us index to waha set kr degi agr waha pehle se koi index hai to use null krdegi
    }

    const [open, setOpen] = useState(false); //animation for answers appearance
    return (
        <div className={`${style.main} d-flex align-items-center justify-content-center`}>
            <div className={`${style.left} d-flex flex-column`}>
                <h1>Frequestly Asked
                    Questions</h1>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>
            </div>

            <div className={`${style.right} d-flex flex-column`}>
                {questions.map((data, index) => (
                    <>
                        <div key={index} className={`${style.rightContent} d-flex `}>
                             <img onClick={()=>{handleDiscription(index) ; setOpen(!open)}}
                              src={openIndex === index ? Minus : Plus} alt="" />
                             {/* ye uper wala index wohi hai k jis question pe click kya 
                             ab ye agr state se match huwa to minus show hoga agr na huwa to plus */}
                            <div className={`${style.questions} d-flex flex-column`}>
                                <h1>{data.question}</h1>
                                <Collapse in={open}>
                                <p className={` ${ openIndex === index ? 'd-block ' : 'd-none' } `}>{data.answer}</p>
                                {/* yaha bhi agr state clicked se match krdy to block nai to none */}
                                </Collapse>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    )
}

export default Frequently
