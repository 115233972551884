import React, { useContext, useEffect, useState } from "react";
import styles from "../atheleteSessions.module.css";
import styled from "styled-components";
import { SessionContext } from "../../../../context/sessionContext";
import AtheleteCreateExerciseModal from "../../../atheleteComponnent/manageTraining/Components/ExerciseModal/atheleteCreateExerciseModal";
import SvgIcons from "../../../ReusableUi/SvgIcon";
import Loader from "../../../ReusableUi/Loader";

const Footer = styled.div`
  display: flex;
  justify-content: end;
  @media (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
const AtheleteSession = (props) => {
  const { getAtheleteSessionExercise, isEmptyData, isLoder } = props;
  const { handleStartSessionGroup } = useContext(SessionContext);
  const [isOpenExerciseModal, setOpenExerciseModal] = useState(false);

  /**
   * Handler To Open ExerciseModal
   */
  const handleOpenExerciseModal = () => {
    setOpenExerciseModal(!isOpenExerciseModal);
  };

  // Function to convert numbers to letters (A, B, C, ...)
  const numberToLetter = (number) => {
    return String.fromCharCode(65 + number);
  };
  // Function to render AthltBlockSets values for each exercise
  const formatAthBlckAthSession = (AthltBlockSets) => {
    return AthltBlockSets?.map((set) => `${set?.value1}@${set?.value2}kg`).join(
      ", "
    );
  };
  
  return (
    <>
      {isLoder ? (
        <>
          <div
            className="w-full d-flex flex-column justify-content-center align-items-center"
            style={{ height: "30vw" }}
          >
            <Loader />
          </div>
        </>
      ) : isEmptyData ? (
        <>
          <div
            className="w-full d-flex flex-column justify-content-center align-items-center"
            style={{ height: "30vw" }}
          >
            <SvgIcons iconName="noSessionPlane" />
            <div className="w-100 d-flex flex-column justify-content-center">
              <h4
                className={`${styles?.noSessionHeading} d-flex justify-content-center`}
              >
                No Sessions Planned
              </h4>
              <div className="d-flex justify-content-center">
                {/* <h4 className={styles?.noSessionDescription}>
                      Contact your coach and plan a session today or buy a
                      program from the marketplace.
                    </h4> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="calenderChiild2 w-100" style={{ border: "none" }}>
          <div>
            <p id="as" className={styles.userSessionCommentTitle}>
              Comment On Session
            </p>
            {getAtheleteSessionExercise &&
              getAtheleteSessionExercise[0]?.groupBlocks?.map((data, index) => {
                return (
                  <>
                    <div
                      className="child2innerDiv"
                      key={index}
                      style={{ marginBottom: "0px" }}
                    >
                      <div className="cirlce">{numberToLetter(index)}</div>
                      <div
                        className="w-100"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="px-2">
                          <p id="as" style={{ margin: 0, padding: 0 }}>
                            {data?.groupExercises?.title || "Traning App"}
                          </p>
                          <p
                            id="as"
                            style={{
                              margin: 0,
                              padding: 0,
                              fontWeight: "bold",
                            }}
                          >
                            {data?.groupBlockSets?.length >= 1
                              ? formatAthBlckAthSession(data?.groupBlockSets)
                              : "There is No Set and Reps"}
                          </p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <svg
                            width="33"
                            height="32"
                            viewBox="0 0 33 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.121094"
                              width="32"
                              height="32"
                              rx="8"
                              fill="#FF0000"
                              fill-opacity="0.06"
                            />
                            <path
                              d="M24.3711 8.5H7.87109C7.45688 8.5 7.12109 8.83579 7.12109 9.25C7.12109 9.66421 7.45688 10 7.87109 10H24.3711C24.7853 10 25.1211 9.66421 25.1211 9.25C25.1211 8.83579 24.7853 8.5 24.3711 8.5Z"
                              fill="#FF0000"
                            />
                            <path
                              d="M13.1211 13.75V19.75C13.1211 20.1642 13.4569 20.5 13.8711 20.5C14.2853 20.5 14.6211 20.1642 14.6211 19.75V13.75C14.6211 13.3358 14.2853 13 13.8711 13C13.4569 13 13.1211 13.3358 13.1211 13.75Z"
                              fill="#FF0000"
                            />
                            <path
                              d="M17.6211 13.75V19.75C17.6211 20.1642 17.9569 20.5 18.3711 20.5C18.7853 20.5 19.1211 20.1642 19.1211 19.75V13.75C19.1211 13.3358 18.7853 13 18.3711 13C17.9569 13 17.6211 13.3358 17.6211 13.75Z"
                              fill="#FF0000"
                            />
                            <path
                              d="M10.1211 23.5V9.25C10.1211 8.83579 9.78531 8.5 9.37109 8.5C8.95688 8.5 8.62109 8.83579 8.62109 9.25V23.5C8.62109 24.1213 9.06043 24.5607 9.06043 24.5607C9.49977 25 10.1211 25 10.1211 25H22.1211C22.7424 25 23.1818 24.5607 23.1818 24.5607C23.6211 24.1213 23.6211 23.5 23.6211 23.5V9.25C23.6211 8.83579 23.2853 8.5 22.8711 8.5C22.4569 8.5 22.1211 8.83579 22.1211 9.25V23.5H10.1211Z"
                              fill="#FF0000"
                            />
                            <path
                              d="M12.2801 6.15901C11.6211 6.81802 11.6211 7.75 11.6211 7.75V9.25C11.6211 9.66421 11.9569 10 12.3711 10C12.7853 10 13.1211 9.66421 13.1211 9.25V7.75C13.1211 7.43934 13.3408 7.21967 13.3408 7.21967C13.5604 7 13.8711 7 13.8711 7H18.3711C18.6818 7 18.9014 7.21967 18.9014 7.21967C19.1211 7.43934 19.1211 7.75 19.1211 7.75V9.25C19.1211 9.66421 19.4569 10 19.8711 10C20.2853 10 20.6211 9.66421 20.6211 9.25V7.75C20.6211 6.81802 19.9621 6.15901 19.9621 6.15901C19.3031 5.5 18.3711 5.5 18.3711 5.5H13.8711C12.9391 5.5 12.2801 6.15901 12.2801 6.15901Z"
                              fill="#FF0000"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="2"
                      viewBox="0 0 868 2"
                      fill="none"
                    >
                      <path
                        d="M0.759766 0.726562H867.121"
                        stroke="black"
                        stroke-opacity="0.13"
                      />
                    </svg>
                  </>
                );
              })}
          </div>
          <Footer>
            <button
              className={styles.add_button}
              onClick={handleOpenExerciseModal}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8613 4.76953C10.8613 4.42435 10.5815 4.14453 10.2363 4.14453C9.89115 4.14453 9.61133 4.42435 9.61133 4.76953V9.76953H4.61133C4.26615 9.76953 3.98633 10.0494 3.98633 10.3945C3.98633 10.7397 4.26615 11.0195 4.61133 11.0195H9.61133V16.0195C9.61133 16.3647 9.89115 16.6445 10.2363 16.6445C10.5815 16.6445 10.8613 16.3647 10.8613 16.0195V11.0195H15.8613C16.2065 11.0195 16.4863 10.7397 16.4863 10.3945C16.4863 10.0494 16.2065 9.76953 15.8613 9.76953H10.8613V4.76953Z"
                  fill="#A19686"
                />
              </svg>
              <span className={styles.add_button_text}>Add Exercise</span>
            </button>
            <button className={styles.add_button}>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8613 4.76953C10.8613 4.42435 10.5815 4.14453 10.2363 4.14453C9.89115 4.14453 9.61133 4.42435 9.61133 4.76953V9.76953H4.61133C4.26615 9.76953 3.98633 10.0494 3.98633 10.3945C3.98633 10.7397 4.26615 11.0195 4.61133 11.0195H9.61133V16.0195C9.61133 16.3647 9.89115 16.6445 10.2363 16.6445C10.5815 16.6445 10.8613 16.3647 10.8613 16.0195V11.0195H15.8613C16.2065 11.0195 16.4863 10.7397 16.4863 10.3945C16.4863 10.0494 16.2065 9.76953 15.8613 9.76953H10.8613V4.76953Z"
                  fill="#A19686"
                />
              </svg>
              <span className={styles.add_button_text}>Add Circuit</span>
            </button>
          </Footer>
        </div>
      )}

      {isOpenExerciseModal && (
        <AtheleteCreateExerciseModal
          isOpen={handleOpenExerciseModal}
          onClose={handleOpenExerciseModal}
        />
      )}
    </>
  );
};

export default AtheleteSession;
