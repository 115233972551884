import React, { useContext, useState } from "react";
import Avtr from "../../../assessts/images/avtr.png";
import "./sessionTable.css";
import { SessionContext } from "../../../context/sessionContext";
import CustomPagination from "../../Common/Pagination/pagination";
import moment from "moment";
import SvgIcon from "../../../assessts/Svg/svgIcons";
import SessionModal from "../../Common/Form/SessionForm/sessionModal";
import SvgIcons from "../../ReusableUi/SvgIcon";
import Skeleton from "react-loading-skeleton";
import DeleteModal from "../../modules/Modal/DeleteModal";
import CreateSession from "../../modules/Modal/CreateSession";
import Tooltip from "../../ReusableComponent/Tooltip";
const SessionTable = ({
  isEmptyDataForSession,
  setRefreshData,
  getSessions,
  paginationActive,
  handleActivePagination,
  totalPages,
  isEditSession,
  setEditSession,
  showDeleteCheckBox,
  setSelectedSession,
  selectedSession,
  handleEditSession,
}) => {
  const { isLoader } = useContext(SessionContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [sessionId, setSessionId] = useState();

  const closeSessionForm = () => {
    setRefreshData((prev) => !prev);
    setIsOpen(false);
  };

  const handleCheckboxChange = (sessionId) => {
    setSelectedSession((prevSelectedPrograms) => {
      if (prevSelectedPrograms.includes(sessionId)) {
        return prevSelectedPrograms.filter((id) => id !== sessionId);
      } else {
        return [...prevSelectedPrograms, sessionId];
      }
    });
  };
  const handleDeleteModal = (id) => {
    setOpenModal(!isOpenModal);
    setSessionId(id);
    setRefreshData((prev) => !prev);
  };
  console.log(getSessions);
  return (
    <div className="table-container ">
      <table className="mb-4">
        {isLoader ? (
          Array.from({ length: 5 }).map((_, index) => (
            <>
              <br />
              <tr key={index}>
                <td>{<Skeleton height={30} width={100} />}</td>
                <td>{<Skeleton height={30} width={50} />}</td>
                <td>{<Skeleton height={30} width={100} />}</td>
                <td>{<Skeleton height={30} width={50} />}</td>
                <td>{<Skeleton height={30} width={150} />}</td>
              </tr>
            </>
          ))
        ) : isEmptyDataForSession ? (
          <div
            className="w-full d-flex flex-column justify-content-center align-items-center"
            style={{ height: "20vw" }}
          >
            <SvgIcons iconName="noSessionPlane" />
            <div className="w-100 d-flex flex-column justify-content-center">
              <h4 className="noSessionHeading d-flex justify-content-center">
                No Session
              </h4>
              <div className="d-flex justify-content-center">
                {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
              </div>
            </div>
          </div>
        ) : (
          <table className="mb-4">
            <thead>
              <tr
                className="tablehead"
                style={{
                  padding: " 0px 64px 0px 40px",
                }}
              >
                <th
                  style={{
                    textAlign: "start",
                    fontWeight: 700,
                    paddingLeft: "1rem ",
                    fontSize: "14px",
                    paddingBottom: "0.5rem",
                    paddingTop: "0.5rem",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Session
                </th>
                <th
                  style={{
                    fontWeight: 700,
                    textAlign: "center",
                    fontSize: "14px",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Coach Name
                </th>
                <th
                  style={{
                    fontWeight: 700,
                    textAlign: "center",
                    fontSize: "14px",
                    fontFamily: "Hurme Geometric Sans 2",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    fontWeight: 700,
                    textAlign: "right",
                    fontSize: "14px",
                    fontFamily: "Hurme Geometric Sans 2",
                    paddingRight: "2rem",
                    backgroundColor: `var(--primary-color,#F6F6F0)`,
                    color: `var(--primary-text-color,#a19686)`,
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody style={{ borderStyle: "none" }} className="tableBody">
              {getSessions.map((data, index) => {
                return (
                  <>
                    <br />
                    <tr key={index}>
                      <td className="chk">
                        {showDeleteCheckBox && (
                          <>
                            <label class="containerInput">
                              <input
                                type="checkbox"
                                checked={selectedSession.includes(data?.id)}
                                onChange={() => handleCheckboxChange(data?.id)}
                              />
                              <span class="checkmark"></span>
                            </label>
                          </>
                        )}
                        <img
                          id="chkImage"
                          width="48px"
                          height="48px"
                          src={Avtr}
                        />
                        <p id="as" style={{ margin: 0, fontSize: "16px" }}>
                          {data.title}
                        </p>
                      </td>
                      <td>
                        <p
                          id="as"
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            margin: 0,
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.coach?.firstName} {data?.coach?.lastName}
                        </p>
                      </td>
                      <td>
                        <p
                          id="as"
                          style={{
                            textAlign: "center",
                            margin: 0,
                            fontSize: "16px",
                          }}
                        >
                          {moment(data.createdAt).format("D/MM/YYYY")}
                        </p>
                      </td>
                      <td style={{ float: "right" }}>
                        <div
                          style={{
                            display: "flex",
                            width: "fit-content",
                            justifyContent: "center",
                            paddingRight: "0.5rem",
                            gap: "5px",
                          }}
                        >
                          <Tooltip
                            children={
                              <div className="indexSessionSee">
                                <SvgIcon iconName="veiwEye" size={16} />
                              </div>
                            }
                            content={"View Session"}
                          />
                          <div onClick={() => handleDeleteModal(data?.id)} >
                          <Tooltip
                            children={
                              <div className="indexSessionDel">
                            <SvgIcon
                              iconName="deleteIcon"
                              size={16}
                              
                            />
                          </div>
                            }
                            content={"Delete Session"}
                          />
                          </div>
                          <div  onClick={() => handleEditSession(data)} >
                          <Tooltip
                            children={
                              <div className="indexSessionEdit">
                            <SvgIcon
                              iconName="edit"
                              size={16}
                              
                            />
                          </div>
                            }
                            content={"Edit Session"}
                          />
                          </div>
                          
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </table>
      <CustomPagination
        currentPage={paginationActive}
        totalPages={totalPages}
        onPageChange={handleActivePagination}
      />
      {isOpenModal && (
        <DeleteModal
          isOpen={isOpenModal}
          onClose={handleDeleteModal}
          sessionId={sessionId}
        />
      )}
      {/* {isOpen && (
        // <SessionModal
        //   isEditSession={isEditSession}
        //   setEditSession={setEditSession}
        //   isOpen={isOpen}
        //   onClose={closeSessionForm}
        // />
        // <CreateSession
        //   show={isOpen}
        //   handleClose={closeSessionForm}
        //   type={"indexSession"}
        // />
      )} */}
    </div>
  );
};

export default SessionTable;
