import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ThemeContext } from "../../../context/themeContext";
import LeaderBoardModal from "../../modals/leaderBoardModal";
import "./sessionForm.css";
import { createSession, editSession, getAllExerciseList } from "../../../apis";
import axios from "axios";
import { auth } from "../../../firebase";
import SvgIcon from "../../../assessts/Svg/svgIcons";
import Blocks from "./Blocks/blocks";
import { toast } from "react-toastify";
const SessionForm = (props) => {
  const { onClose, selectedDate, isEditSession, setEditSession } = props;
  const [sets, setSets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [getExercises, setGetExercise] = useState([]);
  const [exerciseSections, setExerciseSections] = useState([]);
  const [selectecDataExercise, setSelectecDataExercise] = useState([]);
  const [exerciseCount, setExerciseCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { setActiveComponent, activeComponent } = useContext(ThemeContext);
  // States To Send Data
  const [formData, setFormData] = useState({
    title: isEditSession?.title || "",
    instructions: isEditSession?.instructions || "",
    addedByAthlete: false,
    fixedLength: false,
    groupSharing: true,
    oneToOne: false,
    coachId: auth.currentUser.uid,
    athleteId: null,
    programId: null,
    groupId: null,
    date: null,
    programDate: {
      Week: null,
      Day: null,
    },
    leaderBoardData: {
      scoreType: "reps",
      leaderBoardOnboarding: "More is better",
      numIntervals: "4",
      leaderboardCalculations: "More is better"
    },
  });
  useEffect(() => {
    if (selectecDataExercise && selectecDataExercise.length > 0) {
      const exercises = selectecDataExercise.map(exercise => ({
        exerciseTitle: exercise.selectedExercise || "",
        exerciseURL: exercise.URL || "",
        exerciseInstructions: exercise.instructions || "",
        exerciseDuplicate: exercise.duplicate || ""
      }));
      const blocks = selectecDataExercise.map(exercise => ({
        exerciseID: exercise.id || "",
        exerciseCategory: exercise.exerciseCategory || "Power",
        sets: exercise.sets.map(set => ({
          title1: "Reps",
          title2: "Weight",
          value1: set.reps || 0, // Map reps to value1
          value2: set.weight || 0, // Map weight to value2
        })),
      }));
      // Update formData with these new arrays
      setFormData(prevFormData => ({
        ...prevFormData,
        exercise: exercises,
        blocks: blocks,
      }));
    }
  }, [selectecDataExercise]);

  /**
* Validates form data and returns any errors found.
* @param {Object} data - Form data to validate
* @return {Object} - Object containing validation errors
*/
  const validateFormData = (data) => {
    const errors = {};
    if (!data.title) errors.title = "Title is required";
    if (!data.instructions) errors.instructions = "Instruction is required";
    return errors;
  };


  /**
   * Handles saving the program.
   * Performs validation and makes an API call to save or edit the program data.
   */
  const handleSave = async () => {
    try {
      // Indicate loading state
      setIsLoading(true);
      // Get authorization token
      const token = await auth.currentUser.getIdToken(true);
      // Determine the API endpoint and payload based on whether it's a new program or an edit
      const endpoint = isEditSession ? editSession : createSession;
 
      const payload = { ...formData };

      // Make the API call
      await makeApiCall(endpoint, payload, token);
      // Show success message
      toast.success(isEditSession ? "Session Updated Successfully" : "Session Created Successfully");
      onClose();
    } catch (err) {
      // Handle errors
      setIsLoading(false);
      console.error(err);
      toast.error("An error occurred while saving the program");

    }
  };

  /**
 * Makes an API call to the given endpoint with the provided payload and token.
 * @param {string} endpoint - API endpoint
 * @param {Object} payload - Data to send in the API request
 * @param {string} token - Authorization token
 */
  const makeApiCall = async (endpoint, payload, token) => {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
    const config = {
      headers: { Authorization: token }
    };

    if (isEditSession) {
      config.params = { sessionId: isEditSession.id };
    }
    if (isEditSession) {

      // For editing (patch request)
      await axios.patch(url, payload, config);
    } else {
      // For creation (post request)
      await axios.post(url, payload, config);
    }
    // For creation (post request)
  };

  // To Get Coach Id From Auth
  const coachId = auth.currentUser.uid;
  //Fetch all exercises when the component mounts
  const getALLExerciseList = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getAllExerciseList}?coachId=${coachId}`,
          {
            headers: {
              Authorization: token,
            }
          }
        );
        setGetExercise(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getALLExerciseList();
  }, []);

  /**
   * Adds a new exercise section to the form.
   */
  const addExerciseSection = () => {
    if (isEditSession?.blocks && Array.isArray(isEditSession.blocks)) {
      const newSections = isEditSession.blocks.map(block => ({
        exerciseCategory: isEditSession.exerciseCategory || "Power",
        selectedExercise: isEditSession.title || '',
        id: block.exerciseID || "",
        instructions: block.exercises?.instructions || "",
        duplicate: true,
        URL: block.exercises?.URL || "",
        sets: block.blockSets?.map(set => ({
          reps: set?.value1 || "",
          weight: set?.value2 || ""
        })) || []
      }));
      setExerciseSections([...exerciseSections, ...newSections]);
    } else {
      const newSection = {
        exerciseCategory: '',
        selectedExercise: '',
        id: "",
        instructions: "",
        duplicate: false,
        URL: "",
        sets: [{ reps: "", weight: "" }],
      };
      setExerciseSections([...exerciseSections, newSection]);
    }
  };


  /**
  * Handles input changes for session details.
  * @param {number} sectionIndex - The index of the exercise section being modified.
  * @param {Event} event - The event object from the input change.
  */

  const handleSessionInputChange = (sectionIndex, event) => {
    const { name, value } = event.target;
    const updatedSections = [...exerciseSections];

    // Updating the specific field in the correct section
    if (name.startsWith('instructions') || name.startsWith('videoURL')) {
      const fieldName = name.split(`${sectionIndex}`)[0]; // gets 'instructions' or 'videoURL'
      updatedSections[sectionIndex] = { ...updatedSections[sectionIndex], [fieldName]: value };
    } else {
      updatedSections[sectionIndex] = { ...updatedSections[sectionIndex], [name]: value };
    }

    setExerciseSections(updatedSections);

    // Optionally, if you want to update formData as well
    setFormData(prev => ({ ...prev, exerciseSections: updatedSections }));
  };

  /**
 * Deletes an exercise section from the form.
 * @param {string} exerciseId - The ID of the exercise to be deleted.
 */

  const deleteExerciseSection = (exerciseId) => {

    const updatedFormData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !key.includes(exerciseId))
    );

    setFormData(updatedFormData);

    // Removing sets associated with the deleted exercise
    const newSets = sets.filter((set) => set.exerciseId !== exerciseId);
    setSets(newSets);

    // Decreasing the exercise count after deletion
    setExerciseCount((prevCount) => prevCount - 1);

  };

  /**
 * Adds or Remove new set to the exercise.
 */
  const addSet = (sectionIndex) => {
    const newSections = [...exerciseSections];
    const newSet = { reps: '', weight: '' };
    if (newSections[sectionIndex].sets) {
      newSections[sectionIndex].sets.push(newSet);
    } else {
      newSections[sectionIndex].sets = [newSet];
    }
    setExerciseSections(newSections);
  };

  const removeLastSet = (sectionIndex) => {
    const newSections = [...exerciseSections];
    if (newSections[sectionIndex].sets.length > 0) {
      newSections[sectionIndex].sets.pop();
      setExerciseSections(newSections);
    }
  };

  /**
 * Handles input changes for each set.
 * @param {number} index - Index of the set being changed.
 * @param {Event} event - The event object from the input change.
 */

  const handleInputChange = (sectionIndex, setIndex, event) => {
    const { name, value } = event.target;
    const newSections = [...exerciseSections];
    newSections[sectionIndex].sets[setIndex][name] = value;
    setExerciseSections(newSections);
  };

  /**
 * Opens the leaderboard modal.
 */
  const openLeaderModal = () => {
    setShowModal(true);
  };

  /**
 * Closes the leaderboard modal.
 */
  const closeLeaderModal = () => {
    setShowModal(false);
  };

  /**
 * Handles changes in the leaderboard data.
 * @param {Object} data - The updated data for the leaderboard.
 */
  const handleLeaderBoardDataChange = (data) => {
    setFormData({
      ...formData,
      leaderBoardData: data,
    });
  };


  /**
 * Handles the selection change of an exercise.
 * @param {number} sectionIndex - The index of the section where the exercise is changed.
 * @param {string} selectedTitle - The title of the selected exercise.
 */
  const handleExerciseSelectionChange = (sectionIndex, selectedTitle) => {
    const updatedSections = [...exerciseSections];
    const selectedExercise = getExercises.find(exercise => exercise.title === selectedTitle);
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      selectedExercise: selectedTitle,
      id: selectedExercise.id || "",
      instructions: selectedExercise.instructions || "",
      URL: selectedExercise.URL || ""
    };
    setSelectecDataExercise(updatedSections)
    setExerciseSections(updatedSections);
  };

  /**
 * Submits the form data.
 * @param {Event} e - The event object from form submission.
 */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeComponent) {
      setActiveComponent("calendar")
    } else {
      setActiveComponent("preview")
    }
  };

  const handleInputSessionChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  /**
 * Updates the form data with the selected exercise ID from the dropdown.
 * @param {string} exerciseId - The ID of the selected exercise.
 * @param {number} sectionIndex - The index of the exercise section being updated.
 */
  const handleExerciseIdChange = (exerciseId, sectionIndex) => {
    const updatedSections = [...exerciseSections];
    updatedSections[sectionIndex] = {
      ...updatedSections[sectionIndex],
      exerciseID: exerciseId
    };
    setExerciseSections(updatedSections);


    // Update formData with new exerciseSections
    setFormData({ ...formData, exercise: updatedSections });
  };

  return (
    <Form id="as" onSubmit={handleSubmit}>
      <Form.Group className="mt-3 mb-3" controlId="formBasic">
        <Form.Label >Session Title</Form.Label>
        <Form.Control type="text"
          name="sessionTitle"
          value={formData.title}
          onChange={(e) => handleInputSessionChange(e, 'title')}
          placeholder="Session Title" />
      </Form.Group>
      <Form.Group controlId="formBasic">
        <Form.Label>Coach</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          name="coach"
          value={formData.instructions}
          onChange={(e) => handleInputSessionChange(e, 'instructions')}
          placeholder="Session Instruction"
        />
      </Form.Group>
      <Blocks
        exerciseSections={exerciseSections}
        deleteExerciseSection={deleteExerciseSection}
        openLeaderModal={openLeaderModal}
        formData={formData}
        handleSessionInputChange={handleSessionInputChange}
        handleExerciseIdChange={handleExerciseIdChange}
        handleExerciseSelectionChange={handleExerciseSelectionChange}
        getExercises={getExercises}
        sets={sets}
        handleInputChange={handleInputChange}
        removeLastSet={removeLastSet}
        addSet={addSet}
      />
      <br />
      <div className="sessionBtm" style={{ textAlign: "end" }}>
        <div onClick={addExerciseSection} className="pluscircle">
          <SvgIcon iconName="plusButton" size={16} />
        </div>
        <div className="lastButtons">
          <Button className="customButton2">Cancel</Button>
          <Button className="customButton3" onClick={handleSave}>
            {isEditSession ? 'Update' : 'Create'}
          </Button>
        </div>
      </div>
      <LeaderBoardModal isOpen={showModal} onClose={closeLeaderModal} leaderBoardData={formData.leaderBoardData}
        onLeaderBoardDataChange={handleLeaderBoardDataChange} />
    </Form>
  );
};

export default SessionForm;
