import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import SvgIcon from "../../../../../../assessts/Svg/svgIcons";
import CreateExerciseModal from "../../../../../modals/createExersiceModal";
import DeleteModal from "../../../../../modules/Modal/DeleteModal";
import "./css.css"

const CreateSessionBlock = (props) => {
  const {
    isEditSession,
    onClose,
    sessionBlocks,
    setSessionBlocks,
    handleBlockTitle,
    fetchExerciseList,
    handleRemoveSet,
    // blockLabel,
    blockIndex,
    removeBlock,
    exerciseList,
    handleSetChange,
    handleAddSet,
    blockData,
    handleExerciseSelectionChange,
    handleURLChange,
    handleInstructionsChange,
  } = props;

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpenExerModal, setIsOpenExer] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [getBlockId, setBlockId] = useState("");
  const [firstSelectValue, setFirstSelectValue] = useState('Reps');
  const [secondSelectValue, setSecondSelectValue] = useState('Weight(lb)');

  // Handler to update state when an option is selected
  const handleFirstSelectChange = (e, blockIndex) => {
    const newBlocks = [...sessionBlocks];
    const newTitle1 = e.target.value;
    newBlocks[blockIndex].sets = newBlocks[blockIndex].sets.map((set) => ({
      ...set,
      title1: newTitle1,
    }));
    setSessionBlocks(newBlocks);
    setFirstSelectValue(newTitle1);
  };
  useEffect(() => {
    if (isEditSession) {
      return;
    }
    const exerciseTitle = exerciseList[0]?.title;
    handleExerciseSelectionChange(blockIndex, exerciseTitle);
  }, [exerciseList.length > 0, isEditSession]);
  useEffect(()=>{
    if(isEditSession){
      setIsSolid(isEditSession?.blocks?.map(block => block.supersetOrder > 0))
    }else{
      Array(isEditSession.length).fill(false)
    }
  },[isEditSession])
  const handleSecondSelectChange = (e, blockIndex) => {
    const newBlocks = [...sessionBlocks];
    const newTitle2 = e.target.value;
    newBlocks[blockIndex].sets = newBlocks[blockIndex].sets.map((set) => ({
      ...set,
      title2: newTitle2,
    }));
    setSessionBlocks(newBlocks);
    setSecondSelectValue(newTitle2);
  };
  const handleDeleteSession = (blockIndex, blockId) => {
    console.log(blockIndex, blockId);
    setOpenModal(true);
    // removeBlock(blockIndex);
    setBlockId(blockId);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleAddExercise = () => {
    setIsOpenExer(true);
  };
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  // Close Exercise Modal Function
  const closeExerciseModal = () => {
    fetchExerciseList();
    setIsOpenExer(false);
  };
  const handleSelection = (exercise) => {
    setFirstSelectValue(exercise.title1);
    setSecondSelectValue(exercise.title2);
    handleExerciseSelectionChange(blockIndex, exercise.title, exercise.id);
    setDropdownOpen(false);
  };
  const [isSolid, setIsSolid] = useState(
    Array(sessionBlocks.length).fill(false)
  );
  const toggleLineStyle = (index) => {
    console.log(index, "index");
    const newSolid = [...isSolid];
    newSolid[index] = !newSolid[index];
    setIsSolid(newSolid);

    setSessionBlocks((prevBlocks) => {
      const updatedBlocks = [...prevBlocks];

      // Handle merging
      if (newSolid[index]) {
        const supersetGroup = getLastsupersetGroup(updatedBlocks, index + 1);
        let subsetCounter = getLastSubsetCounter(
          updatedBlocks,
          supersetGroup,
          index
        );

        for (let i = index; i < updatedBlocks.length; i++) {
          updatedBlocks[i].supersetGroup = supersetGroup;
          updatedBlocks[i].Label = `${supersetGroup}${subsetCounter}`;
          updatedBlocks[i].supersetOrder = subsetCounter;
          subsetCounter++;

          if (!newSolid[i]) break;
        }
      } else {
        // Handle unmerging
        for (let i = index; i < updatedBlocks.length; i++) {
          if (newSolid[i]) break;

          // Calculate and revert to original group by position
          const originalGroup = String.fromCharCode("A".charCodeAt(0) + i);

          updatedBlocks[i].supersetGroup = originalGroup;
          updatedBlocks[i].Label = originalGroup;
          updatedBlocks[i].supersetOrder = -1;

          if (!newSolid[i + 1]) break; // Stop unmerging if the next block is connected
        }
      }

      // Update the sequence after the merged/unmerged section
      let nextGroupCounter = index + 1;
      for (let j = index + 1; j < updatedBlocks.length; j++) {
        if (!newSolid[j - 1] && !newSolid[j]) {
          const nextGroup = String.fromCharCode(
            "A".charCodeAt(0) + nextGroupCounter
          );
          updatedBlocks[j].supersetGroup = nextGroup;
          updatedBlocks[j].Label = nextGroup;
          updatedBlocks[j].supersetOrder = -1;
          nextGroupCounter++;
        }
      }

      return updatedBlocks;
    });
  };
  const getLastsupersetGroup = (blocks, index) => {
    for (let i = index - 1; i >= 0; i--) {
      if (blocks[i].supersetGroup) {
        return blocks[i].supersetGroup;
      }
    }
    return "A"; // Default to "A" if no previous subset group exists
  };
  const getLastSubsetCounter = (blocks, supersetGroup, index) => {
    for (let i = index - 1; i >= 0; i--) {
      if (
        blocks[i].supersetGroup === supersetGroup &&
        blocks[i].supersetOrder
      ) {
        return blocks[i].supersetOrder + 1; // Increment the last counter
      }
    }
    return 1; // Default to 1 if no previous counter exists
  };
  const getBlockLabel = (index) => {
    const block = sessionBlocks[index];
    return block.supersetOrder
      ? block.Label || `${String.fromCharCode(65 + index)}`
      : String.fromCharCode(65 + index); // Default to the letter based on index
  };




  return (
    <>
      <Container className="m-0 p-0">
        <Row className="m-0 p-0">
          <Col sm={12} md={12} lg={12} className="m-0 p-0 position-relative">
          
            {blockIndex < sessionBlocks.length - 1 && (
              <div
                className="block_superset_link"
                onClick={() => toggleLineStyle(blockIndex)}
              >
                <div className={`line ${isSolid[blockIndex] ? "solid" : ""}`}>
                  <i className="mdi mdi_link_variant"></i>
                </div>
              </div>
            )}
            <Form.Group className="mt-4 mb-3 text d-flex flex-column align-items-end">
              <div
                className="deleteIcon"
                onClick={() => removeBlock(blockIndex)}
              >
                <SvgIcon iconName="deleteIcon" size={25} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                <div className="blocktitle">
                  <div>
                    <div
                      className="exerciseNumbers"
                      style={{ marginRight: "10px" }}
                    >
                      {blockData.Label ||
                        `${blockData.supersetGroup}${
                          blockData.supersetOrder > 0
                            ? blockData.supersetOrder
                            : ""
                        }` 
                        ||
                        getBlockLabel(blockIndex)
                        }
                    </div>
                  </div>
                  <div>
                    <div className="videpurl">
                      <Form.Control
                        type="text"
                        value={blockData?.blocktitle || ""}
                        onChange={(e) =>
                          handleBlockTitle(blockIndex, e.target.value)
                        }
                        placeholder="Block Title"
                      />
                    </div>
                  </div>
                </div>
                <Button className="customButton">LeaderBoard</Button>
              </div>
            </Form.Group>
            <Form.Group className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
              <div className="exerCat" style={{ marginLeft: "98px" }} >
                <Form.Label
                  style={{
                    fontSize: "16px",
                    color: `var(--primary-text-color,black)`,
                  }}
                >
                  Exercise Category
                </Form.Label>
                <Form.Select style={{ backgroundColor: `#F6F6F6` }}>
                  <option>Power</option>
                  <option>Power</option>
                  <option>Power</option>
                </Form.Select>
              </div>
              <div className="exerAdd" style={{ marginLeft: "98px" }} >
                <Form.Label
                  style={{
                    fontSize: "16px",
                    color: `var(--primary-text-color,black)`,
                  }}
                >
                  Add Exercise
                </Form.Label>

                <div>
                  <div className="custom-select">
                    <div
                      className="selected-option"
                      onClick={toggleDropdown}
                      style={{ backgroundColor: `#F6F6F6` }}
                    >
                      {blockData.selectedExercise
                        ? blockData.selectedExercise.title
                        : "Select an exercise"}
                    </div>

                    {isDropdownOpen && (
                      <div
                        className="dropdown-options"
                        style={{ backgroundColor: `#F6F6F6` }}
                      >
                        {exerciseList.map((exercise, index) => (
                          <div
                            key={index}
                            className="dropdown-option"
                            onClick={() => handleSelection(exercise)}
                          >
                            {exercise.title}
                          </div>
                        ))}
                        <div
                          style={{
                            backgroundColor: `var(--secondary-bg-color,white)`,
                          }}
                          className="dropdown-option add-exercise"
                          onClick={handleAddExercise}
                        >
                          <button
                            style={{
                              backgroundColor: `var(--primary-bg-color,#a19686)`,
                            }}
                            type="button"
                          >
                            Add Exercise
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="formBasic" className="mt-4"  style={{ marginLeft: "98px" }} >
              <Form.Label
                style={{
                  fontSize: "16px",
                  color: `var(--primary-text-color,black)`,
                }}
              >
                Instructions
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: `#F6F6F6` }}
                as="textarea"
                rows={5}
                value={blockData.instructions || ""}
                onChange={(e) =>
                  handleInstructionsChange(blockIndex, e.target.value)
                }
                placeholder="ExerciseInstruction"
              />
            </Form.Group>
            <Form.Group
              controlId="formBasic"
              className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm"
              style={{ marginLeft: "98px" }}
            >
              <div className="videpurl mb-3">
                <Form.Label
                  style={{
                    fontSize: "16px",
                    color: `var(--primary-text-color,black)`,
                  }}
                >
                  Video URL
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: `#F6F6F6` }}
                  type="text"
                  value={blockData.videoURL || ""}
                  onChange={(e) => handleURLChange(blockIndex, e.target.value)}
                  placeholder="www.youtube.com"
                />
              </div>
              <div
                className="exerciseSets mb-0"
                style={{ backgroundColor: "var(--secondary-bg-color, white)", width: "390px" }}
              >
                <div
                  className="exerciseSetsHeader"
                  style={{
                    backgroundColor: "#F6F6F6",
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                  }}
                >
                  <h5
                    style={{
                      fontSize: "19px",
                      fontWeight: "700",
                      color: "#232D42",
                      width: "23%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Sets
                  </h5>

                  <Form.Group className="d-flex justify-content-around flex-wrap flex-wrap-sm">
                    <div>
                      <Form.Select
                        style={{ backgroundColor: '#F6F6F6', border: 'none', width: '110px' }}
                        value={firstSelectValue}
                        onChange={(e) => handleFirstSelectChange(e, blockIndex)}
                      >
                        <option value="">Select</option>
                        <option>Reps</option>
                        <option>Weight(lb)</option>
                        <option>Weight(kg)</option>
                        <option>Weight(%)</option>
                        <option>Weight(LWP+)</option>
                        <option>Time(min::sec)</option>
                        <option>Seconds(s)</option>
                        <option>Distance(miles)</option>
                        <option>Distance(yd)</option>
                        <option>Distance(ft)</option>
                        <option>Distance(inches)</option>
                        <option>Height(inches)</option>
                        <option>Calories(call)</option>
                        <option>RPE</option>
                        <option>Watts</option>
                        <option>Velocity(m/s)</option>
                        <option>Other Members</option>
                      </Form.Select>
                    </div>
                  </Form.Group>

                  <Form.Group className="d-flex justify-content-around flex-wrap flex-wrap-sm">
                    <div>
                      <Form.Select
                        style={{ backgroundColor: '#F6F6F6', border: 'none', width: '110px' }}
                        value={secondSelectValue}
                        onChange={(e) =>
                          handleSecondSelectChange(e, blockIndex)
                        }
                      >
                        <option value="">Select</option>
                        <option>Reps</option>
                        <option>Weight(lb)</option>
                        <option>Weight(kg)</option>
                        <option>Weight(%)</option>
                        <option>Weight(LWP+)</option>
                        <option>Time(min::sec)</option>
                        <option>Seconds(s)</option>
                        <option>Distance(miles)</option>
                        <option>Distance(yd)</option>
                        <option>Distance(ft)</option>
                        <option>Distance(inches)</option>
                        <option>Height(inches)</option>
                        <option>Calories(call)</option>
                        <option>RPE</option>
                        <option>Watts</option>
                        <option>Velocity(m/s)</option>
                        <option>Other Members</option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                </div>

                <div>

                  <div key={blockIndex}>
                    {blockData.sets.map((set, setIndex) => (
                      <div
                        className="exerBodyx mt-0"
                        style={{
                          backgroundColor: "#F6F6F6",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "50px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        key={setIndex}
                      >
                        <div style={{ width: "30%", height: "100%" }}>
                          <input
                            style={{
                              fontSize: "19px",
                              fontWeight: "700",
                              color: "#232D42",
                              backgroundColor: "white",
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              boxSizing: "border-box",
                            }}
                            type="text"
                            value={`${setIndex + 1}`}
                            readOnly
                          />
                        </div>
                        <div style={{ width: "30%", height: "100%" }}>
                          <input
                            style={{
                              backgroundColor: "white",
                              color: "#232D42",
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              boxSizing: "border-box",
                            }}
                            type="text"
                            name="reps"
                            value={set.reps}
                            onChange={(e) =>
                              handleSetChange(blockIndex, setIndex, "reps", e.target.value)
                            }
                          />
                        </div>
                        <div style={{ width: "30%", height: "100%" }}>
                          <input
                            style={{
                              backgroundColor: "white",
                              color: "#232D42",
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              boxSizing: "border-box",
                            }}
                            type="text"
                            name="weight"
                            value={set.weight}
                            onChange={(e) =>
                              handleSetChange(
                                blockIndex,
                                setIndex,
                                "weight",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    ))}

                    <div
                      className="iconicBtns"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {blockData.sets.length > 0 && (
                        <div
                          className="plusMinus"
                          onClick={() => handleRemoveSet(blockIndex)}
                        >
                          <SvgIcon iconName="minusButton" size={16} />
                        </div>
                      )}
                      <div
                        className="pluscircle"
                        onClick={() => handleAddSet(blockIndex)}
                      >
                        <SvgIcon iconName="plusButton" size={16} />
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </Form.Group>
          </Col>
        </Row>
        {isOpenExerModal && (
          <CreateExerciseModal
            isOpen={setIsOpenExer}
            onClose={closeExerciseModal}
          />
        )}
        {isOpenModal && (
          <DeleteModal
            isOpen={isOpenModal}
            onClose={handleClose}
            sessionId={getBlockId}
            type={"blockDelete"}
          />
        )}
      </Container>
    </>
  );
};

export default CreateSessionBlock;
