import React from 'react'
import "./loader.css"
const WhiteLoader = () => {
  return (
    <div>
      <div className="loader"></div>
    </div>
  )
}

export default WhiteLoader
