import Youtube from './icons/Youtube.png'
import Facebook from './icons/Facebook.png'
import Pinterest from './icons/Pinterest.png'
import Twitter from './icons/Twitter.png'
import Instagram from './icons/Instagram.png'
import AppStore from './icons/AppStore.png'
import GooglePlay from './icons/GooglePlay.png'
import HomeBGImg from './homeBGpic.png'
import Phone1 from './iPhone1.png'
import Phone2 from './iPhone2.png'
import Phone3 from './iPhone3.png'
import Side from './secondCompSIDE.png'

import RotPhone1 from './RotPhone1.png'
import RotPhone2 from './RotPhone2.png'
import SmallCard from './SmallCard.png'
import CalendarHead from './CalenderHead.png'
import CurvyBG from './Curve.png'

import BodyB from './icons/BodyBuilding.png'
import Gymnastic from './icons/Gymnastics.png'
import Mobility from './icons/Mobility.png'
import Running from './icons/RUnning.png'
import Sports from './icons/Sports.png'
import FunctionalF from './icons/FunctionalFitness.png'

import unsplash from './icons/unsplash.png'
import discript from './icons/discript.png'
import intercom from './icons/intercom.png'

import User from './feedBackUser.png'
import ManHW from './ManholdingWeights.png'
import BackArrow from './icons/backArrow.png'
import NextArrow from './icons/nextArrow.png'
import RatingStar from './icons/RatingStar.png'

// Coach's Pictures
import topBg from './Coach/CoachMainBg.png'
import SecSide from './Coach/secSide.png'
import SidePanel from './Coach/CoachSide.png'
import Ellipse1 from './Coach/EllipseObj.png'
import Ellipse2 from './Coach/EllipseObj2.png'
import PaymentPic from './Coach/PaymentRectangle.png'
import Mockup from './Coach/PortfolioMockup.jpg'
// Coach icons 
import winFile from './icons/window-fill.png'
import globe from './icons/globeicon.png'
import contact from './icons/contact.png'


// Athlete Pictures
import AthleteBG from './Athlete/AthleteBG.png'
import Conveience from './Athlete/convenience.png'
import Nofees from './Athlete/nofees.png'
import SidePnl from './Athlete/sidepanelAhtlete.png'
import StrPh from './Athlete/phoneee.png'
import LeftRotPh from './Athlete/rot1.png'
import RightRotPh from './Athlete/rot3.png'
import DayNight from './Athlete/DayNight.png'
import MarketPlace from './Athlete/discoverMarket.png'
import EllipseLight from './Coach/Ellipse 971.png'
//Athlete icons
import GreenCheck from './icons/GreenTik.png'

// Gym Pictures
import Gym1 from './Gym/gym1.png'
import Gym2 from './Gym/gym2.png'
import Gym3 from './Gym/gym3.png'
//Gym's last section pictures
import pic1 from './Gym/gymLASTpic1.png'
import pic2 from './Gym/gymLASTpic2.png'
import pic3 from './Gym/gymLASTpic3.png'
import pic4 from './Gym/gymLASTpic4.png'
import pic5 from './Gym/gymLASTpic5.png'
import pic6 from './Gym/gymLASTpic6.png'
import pic7 from './Gym/gymLASTpic7.png'
import pic8 from './Gym/gymLASTpic8.png'
// Gym Icons
import icon1 from './icons/weightIcon.png'
import icon2 from './icons/weightIcon2.png'
import icon3 from './icons/cycleIcon.png'

// MarketPlace pictures
import Picture1 from './MarketPlace/Image1.png'
import Picture2 from './MarketPlace/Image2.png'
import Picture3 from './MarketPlace/Image3.png'
import Picture4 from './MarketPlace/Image4.png'
import Picture5 from './MarketPlace/Image5.png'
import Picture6 from './MarketPlace/Image6.png'
import Picture7 from './MarketPlace/Image7.png'
import Picture8 from './MarketPlace/Image8.png'
import Picture9 from './MarketPlace/Image4.png'
// icon
import BlueStar from './icons/blueStar.png'
import SearchIcon from './icons/SearchIcon.png'
import FilterIcon from './icons/filterIcon.png'
import DropDownArrow from './icons/dropdownArrow.png'

// Pricing
import MPopularTag from './Pricing/MostPopularTag.png'
import Pointer from './Pricing/Pointer.png'

// Market Place's Program's picutres
import ProgramBG from './Programs/MPprogram1.png'
import ProgramCoach from './Programs/ProgramCoach.png'
import BodyBuildingProgramPic from './Programs/bodybuildinpic.png'
// icons 
import Barbell from './icons/Barbell.png'
import RoundYT from './icons/RoundedYT.png'
import RoundFB from './icons/RoundedFB.png'
import RoundTwitter from './icons/RoundedTwitter.png'
import RoundInsta from './icons/RoundedInsta.png'
import RoundSite from './icons/RoundedSITE.png'
import BundleUser1 from './icons/bundleUser1.png'
import BundleUser2 from './icons/BundleUser2.png'
import Minus from './icons/Minus.png'
import Plus from './icons/Plus.png'


export { Youtube, Facebook, Pinterest, Twitter, Instagram, AppStore, GooglePlay, HomeBGImg, Phone1, Phone2, Phone3, Side, RotPhone1, RotPhone2, SmallCard, CalendarHead, CurvyBG, BodyB, Gymnastic, Mobility, Running, Sports, FunctionalF, unsplash, discript, intercom, User, ManHW, BackArrow, NextArrow, RatingStar, topBg, SecSide, SidePanel, Ellipse1, Ellipse2, PaymentPic, Mockup, winFile, globe, contact,
    AthleteBG, Conveience, Nofees, SidePnl, StrPh,
    LeftRotPh, RightRotPh, DayNight, MarketPlace, GreenCheck, EllipseLight,
    Gym1, Gym2, Gym3, icon1, icon2, icon3, 
    pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8,
    Picture1, Picture2, Picture3, Picture4, Picture5, Picture6, Picture7, Picture8, Picture9, BlueStar, SearchIcon, FilterIcon,DropDownArrow, ProgramBG, Barbell, ProgramCoach, RoundFB, RoundInsta,RoundSite, RoundTwitter, RoundYT, BodyBuildingProgramPic, BundleUser1, BundleUser2, MPopularTag, Pointer,
    Minus, Plus
 }
