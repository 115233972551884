import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CreateExerciseModal from "../../../../modals/createExersiceModal";
import SvgIcon from "../../../../../assessts/Svg/svgIcons";

const CreateSessionBlock = (props) => {
  const {
    
    isEditSession,
    sessionBlocks,
    setSessionBlocks,
    handleBlockTitle,
    fetchExerciseList,
    handleRemoveSet,
    blockIndex,
    removeBlock,
    exerciseList,
    handleSetChange,
    handleAddSet,
    blockData,
    handleExerciseSelectionChange,
    handleURLChange,
    handleInstructionsChange,
    setBlockId,

  } = props;

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpenExerModal, setIsOpenExer] = useState(false);
  const [firstSelectValue, setFirstSelectValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");
  const handleFirstSelectChange = (e, blockIndex) => {
    const newBlocks = [...sessionBlocks];
    const newTitle1 = e.target.value;
    newBlocks[blockIndex].sets = newBlocks[blockIndex].sets.map((set) => ({
      ...set,
      title1: newTitle1,
    }));
    setSessionBlocks(newBlocks);
    setFirstSelectValue(newTitle1);
  };
  console.log(firstSelectValue, "firstSelectValue");
  console.log(secondSelectValue, "secondSelectValue");

  useEffect(()=>{
    setBlockId(blockData?.id)
  },[blockData])
  const handleSecondSelectChange = (e, blockIndex) => {
    const newBlocks = [...sessionBlocks];
    const newTitle2 = e.target.value;
    newBlocks[blockIndex].sets = newBlocks[blockIndex].sets.map((set) => ({
      ...set,
      title2: newTitle2,
    }));
    setSessionBlocks(newBlocks);
    setSecondSelectValue(newTitle2);
  };

  const handleAddExercise = () => {
    console.log("triger");
    setIsOpenExer(true);
    //   setEditSession("")
  };
  const closeExerciseModal = () => {
    setIsOpenExer(false);
    fetchExerciseList();
    // setRefreshData((prev) => !prev);
  };
  useEffect(() => {
    if (isEditSession) {
      return;
    }
    const exerciseTitle = exerciseList[0]?.title;
    handleExerciseSelectionChange(blockIndex, exerciseTitle);
  }, [exerciseList.length > 0, isEditSession]);
  useEffect(() => {
    if (isEditSession && isEditSession.blocks.length > blockIndex) {
      const firstBlock = isEditSession.blocks[blockIndex];
      if (
        firstBlock &&
        firstBlock.blockSets &&
        firstBlock.blockSets.length > 0
      ) {
        setFirstSelectValue(firstBlock.blockSets[0].title1 || "");
        setSecondSelectValue(firstBlock.blockSets[0].title2 || "");
      } else {
        setFirstSelectValue("");
        setSecondSelectValue("");
      }
    } else {
      setFirstSelectValue(exerciseList[0]?.title1 || "");
      setSecondSelectValue(exerciseList[0]?.title2 || "");
    }
  }, [exerciseList, isEditSession, blockIndex]);

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const handleSelection = (exercise) => {
    setFirstSelectValue(exercise.title1);
    setSecondSelectValue(exercise.title2);
    handleExerciseSelectionChange(blockIndex, exercise.title, exercise.id);
    setDropdownOpen(false);
  };
  const [isSolid, setIsSolid] = useState(
    Array(sessionBlocks.length).fill(false)
  );
  console.log(isSolid,"isSolid")
  useEffect(() => {
    if (isEditSession) {
      setIsSolid(
        isEditSession?.blocks?.map((block) => block.supersetOrder > 0)
      );
    } else {
      Array(sessionBlocks.length).fill(false);
    }
  }, [isEditSession]);
  const toggleLineStyle = (index) => {
    console.log(index, "Show Me Index");
    const newSolid = [...isSolid];
    newSolid[index] = !newSolid[index];
    setIsSolid(newSolid);

    setSessionBlocks((prevBlocks) => {
      const updatedBlocks = [...prevBlocks];

      if (newSolid[index]) {
        const supersetGroup = getLastsupersetGroup(updatedBlocks, index + 1);
        let subsetCounter = getLastSubsetCounter(
          updatedBlocks,
          supersetGroup,
          index
        );
        updatedBlocks[index].supersetGroup = supersetGroup;
        updatedBlocks[index].supersetOrder = subsetCounter;
        updatedBlocks[index].connected = true;
        if (index + 1 < updatedBlocks.length) {
          updatedBlocks[index + 1].supersetGroup = supersetGroup;
          updatedBlocks[index + 1].supersetOrder = subsetCounter + 1;
          updatedBlocks[index + 1].connected = true;
        }

        for (let i = index + 1; i < updatedBlocks.length; i++) {
          if (!updatedBlocks[i].connected) break;
          updatedBlocks[i].supersetGroup = supersetGroup;
          updatedBlocks[i].supersetOrder = ++subsetCounter;
          updatedBlocks[i].Label = `${supersetGroup}${
            subsetCounter > 0 ? subsetCounter : ""
          }`;
        }
        for (let i = 1; i < updatedBlocks.length; i++) {
          if (
            index + i + 1 < updatedBlocks.length &&
            !updatedBlocks[index + i + 1].connected
          ) {
            updatedBlocks[index + i + 1].supersetGroup = String.fromCharCode(
              supersetGroup.charCodeAt(0) + i
            ); 
            updatedBlocks[index + i + 1].supersetOrder = 0;
            updatedBlocks[index + i + 1].Label = `${
              updatedBlocks[index + i + 1].supersetGroup
            }`;
            updatedBlocks[index + i + 1].connected = false;
          }
        }
      } else {
        const supersetGroup = getLastsupersetGroup(updatedBlocks, index);
        const lastCharCode = supersetGroup.charCodeAt(0);
        const lastGroupIndex = index + 1;
        
        for (let i = lastGroupIndex; i < updatedBlocks.length; i++) {
          console.log("Show me Index ====>", i);
          const newLabelCharCode = lastCharCode + (i - lastGroupIndex + 1);
          updatedBlocks[i].supersetGroup = String.fromCharCode(newLabelCharCode);
          updatedBlocks[i].supersetOrder = 0;
          updatedBlocks[i].connected = false;
          updatedBlocks[i].Label = `${updatedBlocks[i].supersetGroup}`;
        }
        for (let i = blockIndex; i < newSolid.length; i++) {
          newSolid[i] = false;
        }
        
      }

      return updatedBlocks;
    });
  };
  //   const toggleLineStyle = (index) => {
  //     console.log(index, "Show Me Index");
  //     const newSolid = [...isSolid];
  //     newSolid[index] = !newSolid[index];
  //     setIsSolid(newSolid);

  //     setSessionBlocks((prevBlocks) => {
  //         const updatedBlocks = [...prevBlocks];

  //         if (newSolid[index]) {
  //             // Logic for when the condition is true (connecting blocks)
  //             const supersetGroup = getLastsupersetGroup(updatedBlocks, index + 1);
  //             let subsetCounter = getLastSubsetCounter(updatedBlocks, supersetGroup, index);

  //             // Connect the current and next block
  //             updatedBlocks[index].supersetGroup = supersetGroup;
  //             updatedBlocks[index].supersetOrder = subsetCounter;
  //             updatedBlocks[index].connected = true;

  //             if (index + 1 < updatedBlocks.length) {
  //                 updatedBlocks[index + 1].supersetGroup = supersetGroup;
  //                 updatedBlocks[index + 1].supersetOrder = subsetCounter + 1;
  //                 updatedBlocks[index + 1].connected = true;
  //             }

  //             // Update labels for A1, A2
  //             for (let i = index; i <= index + 1; i++) {
  //                 updatedBlocks[i].Label = `${supersetGroup}${i === index ? 1 : 2}`;
  //             }

  //             // Update subsequent blocks (C, D, E)
  //             for (let i = index + 2; i < updatedBlocks.length; i++) {
  //                 updatedBlocks[i].supersetGroup = String.fromCharCode(supersetGroup.charCodeAt(0) + 1);
  //                 updatedBlocks[i].Label = updatedBlocks[i].supersetGroup;
  //             }
  //         } else {
  //             // Logic for when the condition is false (disconnecting blocks)
  //             for (let i = index + 1; i < updatedBlocks.length; i++) {
  //                 console.log("Show me Index ====>", i);
  //                 updatedBlocks[i].supersetGroup = String.fromCharCode(65 + i);
  //                 updatedBlocks[i].supersetOrder = 0;
  //                 updatedBlocks[i].connected = false;
  //                 updatedBlocks[i].Label = `${updatedBlocks[i].supersetGroup}`;
  //             }

  //             // Reset the solid line status for unconnected blocks
  //             for (let i = index; i < newSolid.length; i++) {
  //                 newSolid[i] = false;
  //             }
  //         }

  //         return updatedBlocks;
  //     });
  // };

  const getLastsupersetGroup = (blocks, index) => {
    for (let i = index - 1; i >= 0; i--) {
      if (blocks[i].supersetGroup) {
        return blocks[i].supersetGroup;
      }
    }
    return "A";
  };
  const getLastSubsetCounter = (blocks, supersetGroup, index) => {
    for (let i = index - 1; i >= 0; i--) {
      if (
        blocks[i].supersetGroup === supersetGroup &&
        blocks[i].supersetOrder
      ) {
        return blocks[i].supersetOrder + 1; // Increment the last counter
      }
    }
    return 1; // Default to 1 if no previous counter exists
  };
  const getBlockLabel = (index) => {
    const block = sessionBlocks[index];
    return block.connected ? block.Label : String.fromCharCode(65 + index); // Default to the letter based on index
  };

  return (
    <>
      <Container className="m-0 p-0">
        <Row className="m-0 p-0">
          <Col sm={12} md={12} lg={12} className="m-0 p-0 position-relative">
            {blockIndex < sessionBlocks.length - 1 && (
              <div
                className="block_superset_link"
                onClick={() => toggleLineStyle(blockIndex)}
              >
                <div className={`line ${isSolid[blockIndex] ? "solid" : ""}`}>
                  <i className="mdi mdi_link_variant"></i>
                </div>
              </div>
            )}
            <Form.Group className="mt-4 mb-3 text d-flex flex-column  align-items-end">
              <div
              style={{cursor : "pointer"}}
                className="deleteIcon"
                onClick={() => removeBlock(blockIndex)}
              >
                <SvgIcon iconName="deleteIcon" size={25} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                <div className="blocktitle">
                  <div>
                    <div
                      className="exerciseNumbers"
                      style={{ marginRight: "10px" }}
                    >
                      {blockData.Label ||
                        `${blockData.supersetGroup}${
                          blockData.supersetOrder > 0
                            ? blockData.supersetOrder
                            : ""
                        }` ||
                        getBlockLabel(blockIndex)}
                    </div>
                  </div>
                  <div>
                    <div className="videpurl">
                      <Form.Control
                        type="text"
                        value={blockData?.blocktitle || ""}
                        onChange={(e) =>
                          handleBlockTitle(blockIndex, e.target.value)
                        }
                        placeholder="Block Title"
                      />
                    </div>
                  </div>
                </div>

                <Button className="customButton">LeaderBoard</Button>
              </div>
            </Form.Group>
            <Form.Group className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm">
              <div className="exerCat" style={{ marginLeft: "98px" }}>
                <Form.Label style={{ fontSize: "16px" }}>
                  Exercise Category
                </Form.Label>
                <Form.Select>
                  <option>Power</option>
                  <option>Power</option>
                  <option>Power</option>
                </Form.Select>
              </div>
              <div className="exerAdd">
                <Form.Label style={{ fontSize: "16px" }}>
                  Add Exercise
                </Form.Label>

                <div>
                  <div className="custom-select">
                    <div className="selected-option" onClick={toggleDropdown}>
                      {blockData?.selectedExercise?.id
                        ? blockData.selectedExercise.title
                        : "Select an exercise"}
                    </div>
                    {isDropdownOpen && (
                      <div className="dropdown-options">
                        {exerciseList.map((exercise, index) => (
                          <div
                            key={index}
                            className="dropdown-option"
                            onClick={() => handleSelection(exercise)}
                          >
                            {exercise.title}
                          </div>
                        ))}
                        <div
                          style={{
                            backgroundColor: `var(--secondary-bg-color,white)`,
                          }}
                          className="dropdown-option add-exercise"
                          onClick={handleAddExercise}
                        >
                          <button
                            style={{
                              backgroundColor: `var(--secondary-color,#a19686)`,
                            }}
                            type="button"
                          >
                            Add Exercise
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Form.Group>
            <Form.Group
              controlId="formBasic"
              style={{ marginLeft: "98px" }}
              className="mt-4 ml-[20px]"
            >
              <Form.Label style={{ fontSize: "16px" }}>Instructions</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={blockData.instructions || ""}
                onChange={(e) =>
                  handleInstructionsChange(blockIndex, e.target.value)
                }
                placeholder="ExerciseInstruction"
              />
            </Form.Group>
            <Form.Group
              controlId="formBasic"
              style={{ marginLeft: "98px" }}
              className="d-flex justify-content-between mt-4 flex-wrap flex-wrap-sm ml-[20px]"
            >
              <div className="videpurl mb-3">
                <Form.Label style={{ fontSize: "16px" }}>Video URL</Form.Label>
                <Form.Control
                  type="text"
                  value={blockData.videoURL || ""}
                  onChange={(e) => handleURLChange(blockIndex, e.target.value)}
                  placeholder="www.youtube.com"
                />
              </div>
              <div
                className="exerciseSets mb-0"
                style={{
                  backgroundColor: "var(--secondary-bg-color, white)",
                  width: "510px",
                }}
              >
                <div
                  className="exerciseSetsHeader"
                  style={{
                    backgroundColor: "#F6F6F6",
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                  }}
                >
                  <h5
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#232D42",
                      width: "28%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop : "5px"
                    }}
                  >
                    Sets
                  </h5>
                  <Form.Group className="d-flex justify-content-around flex-wrap flex-wrap-sm">
                    <div>
                      <Form.Select
                        style={{
                          fontSize : "13px",
                          backgroundColor: "#F6F6F6",
                          border: "none",
                          fontWeight: "700",
                          // width: calculateWidth(firstSelectValue || "Select"),
                          minWidth : "100px"
                        }}
                        value={firstSelectValue}
                        onChange={(e) => handleFirstSelectChange(e, blockIndex)}
                      >
                        <option value="">Select</option>
                        <option>Reps</option>
                        <option>Weight(lb)</option>
                        <option>Weight(kg)</option>
                        <option>Weight(%)</option>
                        <option>Weight(LWP+)</option>
                        <option>Time(min::sec)</option>
                        <option>Seconds(s)</option>
                        <option>Distance(miles)</option>
                        <option>Distance(yd)</option>
                        <option>Distance(ft)</option>
                        <option>Distance(inches)</option>
                        <option>Height(inches)</option>
                        <option>Calories(call)</option>
                        <option>RPE</option>
                        <option>Watts</option>
                        <option>Velocity(m/s)</option>
                        <option>Other Members</option>
                      </Form.Select>
                    </div>
                  </Form.Group>

                  <Form.Group className="d-flex justify-content-around flex-wrap flex-wrap-sm">
                    <div>
                      <Form.Select
                        style={{
                          fontSize : "13px",
                          backgroundColor: "#F6F6F6",
                          border: "none",
                          fontWeight: "700",
                          // width: calculateWidth(secondSelectValue || "Select"),
                        width : "auto"
                        }}
                        value={secondSelectValue}
                        onChange={(e) =>
                          handleSecondSelectChange(e, blockIndex)
                        }
                      >
                        <option value="">Select</option>
                        <option>Reps</option>
                        <option>Weight(lb)</option>
                        <option>Weight(kg)</option>
                        <option>Weight(%)</option>
                        <option>Weight(LWP+)</option>
                        <option>Time(min::sec)</option>
                        <option>Seconds(s)</option>
                        <option>Distance(miles)</option>
                        <option>Distance(yd)</option>
                        <option>Distance(ft)</option>
                        <option>Distance(inches)</option>
                        <option>Height(inches)</option>
                        <option>Calories(call)</option>
                        <option>RPE</option>
                        <option>Watts</option>
                        <option>Velocity(m/s)</option>
                        <option>Other Members</option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                </div>

                <div>
                  <div key={blockIndex}>
                    {blockData.sets.map((set, setIndex) => (
                      <div
                        className="exerBodyx mt-0"
                        style={{
                          backgroundColor: "#F6F6F6",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "50px",
                          paddingLeft: "30px",
                          paddingRight: "30px",
                        }}
                        key={setIndex}
                      >
                        <div style={{ width: "27%", height: "100%" }}>
                          <input
                            style={{
                              fontSize: "19px",
                              fontWeight: "700",
                              color: "#232D42",
                              backgroundColor: "white",
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              boxSizing: "border-box",
                            }}
                            type="text"
                            value={`${setIndex + 1}`}
                            readOnly
                          />
                        </div>
                        <div style={{ width: "27%", height: "100%" }}>
                          <input
                            style={{
                              backgroundColor: "white",
                              color: "#232D42",
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              boxSizing: "border-box",
                            }}
                            type="text"
                            name="reps"
                            value={set.reps}
                            onChange={(e) =>
                              handleSetChange(
                                blockIndex,
                                setIndex,
                                "reps",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div style={{ width: "27%", height: "100%" }}>
                          <input
                            style={{
                              backgroundColor: "white",
                              color: "#232D42",
                              width: "100%",
                              height: "100%",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              boxSizing: "border-box",
                            }}
                            type="text"
                            name="weight"
                            value={set.weight}
                            onChange={(e) =>
                              handleSetChange(
                                blockIndex,
                                setIndex,
                                "weight",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    ))}

                    <div
                      className="iconicBtns"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {blockData.sets.length > 0 && (
                        <div
                          className="plusMinus"
                          onClick={() => handleRemoveSet(blockIndex)}
                        >
                          <SvgIcon iconName="minusButton" size={16} />
                        </div>
                      )}
                      <div
                        className="pluscircle"
                        onClick={() => handleAddSet(blockIndex)}
                      >
                        <SvgIcon iconName="plusButton" size={16} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      {isOpenExerModal && (
        <CreateExerciseModal
          isOpen={setIsOpenExer}
          onClose={closeExerciseModal}
        />
      )}
    </>
  );
};

export default CreateSessionBlock;
