import React from 'react'
import GroupInvitation from '../../components/groupInvitation/invitationLink'
import './styles.css'


const Invitation = () => {

  return (
<div className='invitattion'>
  <GroupInvitation/>
</div>
  )
}

export default Invitation

