import React, { useState } from 'react';
import styles from "./reusableUi.module.css"

const Comment = ({ comments }) => {
    const [showAllComments, setShowAllComments] = useState(false);
    const toggleComments = () => {
        setShowAllComments(!showAllComments);
    };
    return (
        <div>
            <div>
                {comments?.slice(0, showAllComments ? comments?.length : 1).map((comment, index) => {
                    const firstNameInitial = comment?.users?.firstName?.charAt(0).toUpperCase();
                    const lastNameInitial = comment?.users?.lastName?.charAt(0).toUpperCase();
                    return (
                        <div className='d-flex' style={{ width: "330px" }}>
                            <div
                                className='align-items-center d-flex gap-2 justify-content-center '
                                style={{
                                    width: "30px",
                                    height: "29px",
                                    borderRadius: "50px",
                                    background: '#A19686',
                                }}
                            >
                                <p style={{ fontWeight: 700, padding: 0, margin: 0, textAlign: 'center', color: "white", fontSize: "12px" }}>{firstNameInitial}{lastNameInitial}</p>

                            </div>
                            <div className='w-100'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <span className={styles.comentsTitle} >{comment?.users?.firstName} {comment.users?.lastName}</span>
                                    </div>
                                    <div className={styles.comentsMinsAgo}>
                                        <span>8h</span>
                                    </div>
                                </div>
                                <div className={styles.comentsDescroption}>
                                    <span>{comment.comments}</span>
                                </div>
                            </div>

                        </div>

                        // <div key={index} style={{ height: "70px" }}>
                        //     <div className='align-items-center d-flex gap-2'>
                        //         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', background: '#A19686', width: '50px', height: '50px' }}>
                        //             <p style={{ fontWeight: 700, padding: 0, margin: 0, textAlign: 'center', color: "white" }}>{firstNameInitial}{lastNameInitial}</p>
                        //         </div>
                        //         <div className='pt-2 gap-5'>
                        //             <p style={{ fontWeight: 700, padding: 0, margin: 0 }}>{comment?.users?.firstName} {comment.users?.lastName}</p>
                        //             <p className={styles?.commentsText}>{comment.comments}</p>
                        //         </div>
                        //     </div>
                        // </div>
                    );
                })}
                {/* Show more button */}
                {!showAllComments && comments?.length > 1 && (
                    <div className='d-flex justify-content-center' onClick={toggleComments} style={{ color: "blue", fontWeight: "700",cursor : "pointer" }}>Show More</div>
                )}
                {/* Hide button */}
                {showAllComments && (
                    <div className='d-flex justify-content-center' onClick={toggleComments} style={{ color: "blue", fontWeight: "700",cursor : "pointer" }}>Hide</div>
                )}
            </div>
        </div>
    );
};

export default Comment;
