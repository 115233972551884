import React, { useContext } from "react";
import { ThemeContext } from "../../context/themeContext";

const Messages = React.memo(({ messages, auth, Avt }) => {
  const { isGroupChat } = useContext(ThemeContext);
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const getImgSrc = (imageSrc) => {
    const binaryImage = imageSrc?.data;
    if (binaryImage) {
      const base64Image = arrayBufferToBase64(binaryImage);
      return `data:image/png;base64,${base64Image}`;
    }
    return null;
  };
  const SortArray = messages.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );
  console.log(SortArray, "SortArray");
  console.log(isGroupChat, "isGroupChat");
  return (
    <>
      {SortArray.map((message, index) => {
        const cleanSenderId = message?.senderId?.trim()?.replace(/\s+/g, "");
        const isMine = auth.currentUser.uid === cleanSenderId;
        const imgSrc = getImgSrc(message.imageSrc);

        return (
          <div
            key={index}
            className={`message ${
              !isMine ? "messageLeftSide" : "messageRightSide"
            }`}
          >
            <p style={{ color: "#A19686", fontWeight: "bold" }}>
              {isMine ? (
                <div style={{ display: "flex", gap: "5px" }}>
                  You
                  <img
                    className="image"
                    height="38px"
                    width="38px"
                    src={Avt}
                    alt="Your Avatar"
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-end",
                  }}
                >
                  {isGroupChat === true && (
                    <>
                      {" "}
                      <img
                        className="image"
                        height="38px"
                        width="38px"
                        src={Avt}
                        alt="Receiver's Avatar"
                      />
                    </>
                  )}
                  {isGroupChat === true &&
                    `${message?.user?.firstName} ${message?.user?.lastName}`}
                </div>
              )}
            </p>

            {message?.audioMsg ? (
              <audio
                style={{ width: "250px" }}
                src={
                  message.audioMsg.data
                    ? URL.createObjectURL(
                        new Blob([new Uint8Array(message.audioMsg.data)], {
                          type: "audio/wav",
                        })
                      )
                    : URL.createObjectURL(new Blob([message.audioMsg]))
                }
                controls
              />
            ) : message?.message ? (
              <p>{message.message}</p>
            ) : message?.imageSrc ? (
              <>
                <img
                  src={
                    imgSrc || URL.createObjectURL(new Blob([message.imageSrc]))
                  }
                  alt="Selected"
                  width="180"
                  height="150"
                />
                <p>{message.imageCaption}</p>
              </>
            ) : null}
          </div>
        );
      })}
    </>
  );
});

export default Messages;
