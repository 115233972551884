import React, { useState } from 'react';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import './AtheleteSignupForm.css'; // Import your CSS file
import axios from 'axios';
import { createUser } from "../../apis";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../loadingSpinner';
import { Col, Row } from 'react-bootstrap';
import Input from '../ReusableUi/Input';
import PasswordInput from '../ReusableUi/PasswordInput';
import CheckBox from '../ReusableUi/CheckBox';
import { toast } from 'react-toastify';

const SignupForm = ({ user }) => {
  const navigation = useNavigate();

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    trainingPref: '',
    athDOB: null, // Initialize as null for date value
    showPassword: false,
    userType: user
  });

  const [fieldErrors, setFieldErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    trainingPref: '',
    athDOB: '',
    coachingSpecialties: '',
    qualifications: ''
  });

  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termValidation, setTermsValidation] = useState("");
  const [serverError, setServerError] = useState("");

  const handleChange = (prop) => (event) => {
    const value = event.target.value;

    if (prop === 'athDOB' && event.target.type === 'date') {
      setFormData({ ...formData, athDOB: value });
      return;
    }

    if (prop === 'email') {
      // Email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setFieldErrors({ ...fieldErrors, [prop]: emailPattern.test(value) ? '' : 'Invalid email format' });
    } else {
      // Required field validation
      setFieldErrors({ ...fieldErrors, [prop]: value ? '' : 'This field is required' });
    }

    setFormData({ ...formData, [prop]: value });
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    if (checked) {
      setTermsValidation(""); // Clear error when checked
    }
  };

  const handleShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    
    // Clear previous server error message
    setServerError("");

    // Validate all fields
    const newFieldErrors = {};
    const requiredFields = ['firstname', 'lastname', 'email', 'password', 'trainingPref', 'athDOB'];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newFieldErrors[field] = 'This field is required';
      }
    });

    // Check if terms are accepted
    if (!isChecked) {
      setTermsValidation("You must accept the terms and conditions.");
      newFieldErrors.acceptTerms = "You must accept the terms and conditions."; // Add to field errors for display
    } else {
      setTermsValidation(""); // Clear any previous error
    }

    setFieldErrors(newFieldErrors);

    // If there are errors, stop the submission
    if (Object.keys(newFieldErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    // Proceed with API call
    try {
      await axios.post(`${process.env.REACT_APP_Baseurl}${createUser}`, formData);
      toast.success('User Created Successfully', { onClose: () => navigation("/login"), autoClose: 3000 });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setServerError(error.response.data.error); // Set the server error message
      } else {
        setServerError("An error occurred while creating the user."); // Fallback error message
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="signup-formAthlt" onSubmit={handleSubmit}>
      <Row>
        <Col md={6} sm={12} lg={6}>
          <div className="form-field">
            <label>First Name</label>
            <Input
              value={formData.firstname}
              onChange={handleChange('firstname')}
              className="w-100"
              placeholder="John"
              type="text"
            />
            <p className="error-message">{fieldErrors.firstname}</p>
          </div>
        </Col>
        <Col md={6} sm={12} lg={6}>
          <div className="form-field">
            <label>Last Name</label>
            <Input
              placeholder='Doe'
              type="text"
              className="w-100"
              value={formData.lastname}
              onChange={handleChange('lastname')}
            />
            <p className="error-message">{fieldErrors.lastname}</p>
          </div>
        </Col>
        <Col md={12} sm={12} lg={12}>
          <div className="form-field">
            <label>Email</label>
            <Input
              placeholder='johndoe@gmail.com'
              type="email"
              value={formData.email}
              onChange={handleChange('email')}
            />
            <p className="error-message">{fieldErrors.email}</p>
          </div>
        </Col>
        <Col md={12} sm={12} lg={12}>
          <div className="form-field">
            <label>Password</label>
            <PasswordInput
              value={formData.password}
              onChange={handleChange('password')}
              showPassword={formData.showPassword}
              onToggleShowPassword={handleShowPassword}
              className="your-custom-class" // If you have any custom styling
            />
            <p className="error-message">{fieldErrors.password}</p>
          </div>
        </Col>
        <Col md={6} sm={12} lg={6}>
          <div className="form-field">
            <label>What kind of training do you do?</label>
            <Input
              placeholder='XYZ'
              type="text"
              value={formData.trainingPref}
              onChange={handleChange('trainingPref')}
            />
            <p className="error-message">{fieldErrors.trainingPref}</p>
          </div>
        </Col>
        <Col md={6} sm={12} lg={6}>
          <div className="form-field">
            <label>DOB</label>
            <Input
              placeholder='15/5/1996'
              type="date"
              className="w-100"
              value={formData.athDOB}
              onChange={handleChange('athDOB')}
            />
            <p className="error-message">{fieldErrors.athDOB}</p>
          </div>
        </Col>
      </Row>
      <div className="terms-checkbox">
      <div className='checkbox' >
        <CheckBox
          checked={isChecked}
          onChange={handleCheckboxChange}
          label="I accept the terms & Privacy Policies of use."
          customStyles={{
            container: 'custom-container',
            checkbox: 'custom-checkbox',
            checkmark: 'custom-checkmark',
            label: 'acceptTerms',
          }}
        />
        <p className="error-message">{termValidation}</p>
        </div>
      </div>
      {serverError && <p className="error-message">{serverError}</p>}
      <div style={{ display: "flex", width: "100%", gap: '10px' }}>
        <button className='button' type="submit">
          {isLoading ? (
            <LoadingSpinner size="sm" />
          ) : ('Sign Up')}
        </button>
      </div>
    </form>
  );
};

export default SignupForm;
