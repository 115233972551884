import React, { useState } from 'react'
import styled from "styled-components";
import styles from "./historysession.module.css";
import SessionDetails from './SessionDetails/SessionDetails';

const Header = styled.header`
display: flex;
justify-content: space-around;
margin-top: 20px;
width: 800px;
`;

const Section = styled.section`
margin-top: 5px;
`;
const HistorySession = ({ApisavedRepMaxValues,apiResponse, exerciseId, setSessionHistory, getBlocksId}) => {
    const [activeBtn, setActivebtn] = useState("workingMax");
    const Back = ()=>{
      
        setSessionHistory(false)
      
 
    
    
}
    return (
        <>
      
            <Header>
            <div onClick={Back} style={{ marginTop: '1rem', cursor: "pointer", width: "fit-content" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none">
                <path d="M10 20.1038L2 11.2511L10 2.39844" stroke="#100837" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
                <div className={styles.historySessionTab}>
                    <div id={activeBtn === "workingMax" ? styles.activeButton : undefined}>
                        <button
                            variant="text"
                            onClick={() => setActivebtn("workingMax")}
                        >
                            <span>
                                Working Max
                            </span>
                        </button>
                    </div>
                    <div id={activeBtn === "history" ? styles.activeButton : undefined}>
                        <button

                            onClick={() => setActivebtn("history")}
                        >
                            <span>
                                History
                            </span>
                        </button>
                    </div>
                </div>
            </Header>
            <Section>
                <SessionDetails  ApisavedRepMaxValues={ApisavedRepMaxValues} getBlocksId={getBlocksId} exerciseId={exerciseId}  activeBtn={activeBtn} apiResponse={apiResponse} />
            </Section>
        </>
    )
}

export default HistorySession