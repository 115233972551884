import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../../../context/themeContext";
import { SessionContext } from "../../../../../context/sessionContext";
import Av from "../../../../../assessts/icons/avtr.png";
import DarkAv from "../../../../../assessts/icons/darkAv.png";
import DetailsHistoy from "../../../../../assessts/icons/Button.svg";
import styles from "../traningSession.module.css";
import { Card } from "react-bootstrap";
import SvgIcon from "../../../../../assessts/Svg/svgIcons";
import ViewSummaryModel from "./ViewSummaryModel"; // Import the modal component
import "./CompleteSession.css";
import { auth } from "../../../../../firebase";
import { calculateOneRepMax, calculatePersonalRecord } from "../../../../../apis";
import axios from "axios";

const AtheleteDetails = ({
  getAtheleteSessionExercise,
  handleSession,
  setStartSession,
  totalSets,
  totalRepsValue,
  totalBlocks,
  selectedDate,
  currentExerciseIndex,
  setApiResponse,
  setApiPersonalRecordResponse,
  exerciseId,
  blockId
}) => {
  const { handleStartSessionGroup } = useContext(SessionContext);
  const TraningSession = styled.div`
    width: 40rem;
    max-width: 800px;
    margin: 20px 10px 0px;
    @media (max-width: 768px) {
      width: 70vw;
    }
    @media (max-width: 480px) {
      width: 90vw;
    }
  `;
  const Section = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    & > div:first-child {
      display: flex;
      align-items: center;
    }
    & > div:nth-child(2) {
      display: flex;
      justify-content: end;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(23vw, 1fr));
    }
    @media (max-width: 480px) {
      width: 100vw;
      grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
      & > div:nth-child(2) {
        display: flex;
        justify-content: center;
      }
    }
  `;
  const ZoomableImage = styled.img`
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  `;

  const { theme } = useContext(ThemeContext);
  const { handleStartSession, setSessionHistory } = useContext(SessionContext);

  const [feedValue, setFeedValue] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  
  const [showBtn, setShowBtn] = useState(true);
  const currentDate =
    localStorage.getItem("selectedDate") ||
    new Date().toISOString().split("T")[0];
  const storageKey = `IsCompleteSet_${currentDate}_true`;



  const StartHistoryMenu = () => {
    setStartSession(true);
    setSessionHistory(true);
  };

  const viewSummary = () => {
    setIsModalOpen(true); // Open the modal when the button is clicked
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal when the overlay or close button is clicked
  };
 // Function To Get Api hit in One Rep Max Value
 
 const [authReady, setAuthReady] = useState(false);
 useEffect(() => {
   const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
     setAuthReady(!!user); // Set authReady to true if user is logged in
   });

   return () => unregisterAuthObserver(); // Clean up observer on unmount
 }, []);
 useEffect(()=>{
  if(authReady){


  handleSaveTraningSessionBlock()
}
 },[authReady])
 const handleSaveTraningSessionBlock = async () => {
  try {
    // Get authorization token
    const token = await auth.currentUser.getIdToken(true);

    // Filter and format the data for the payload
    const currentDate = localStorage.getItem("selectedDate") || new Date().toISOString().split("T")[0];
    const newStorageKey = `UpdatedAllSets_${currentDate}_${currentExerciseIndex}`;
    const storedData = localStorage.getItem(newStorageKey);
    const parsedData = storedData ? JSON.parse(storedData) : null;



    // Format the sets data for the payload
    const formattedSets = parsedData.sets
      .filter((set) => set.setDone) // Filter sets where setDone is true
      .map((set) => ({
        id: set.id,
        value1: set.value1,
        value2: set.value2,
      }));

    if (formattedSets.length === 0) {
      // toast.error("No sets selected");
      return;
    }
    // Prepare the payload
    if( exerciseId && blockId){
      const payload = {
        exerciseId: exerciseId, // assuming exerciseId is passed as a prop
        sets: formattedSets,
      };
      const payload2 = {
        exerciseId: exerciseId, // assuming exerciseId is passed as a prop
        // athleteBlockId: blockId,
        sets: formattedSets,
      };
      // Determine the API endpoint and payload based on whether it's a new program or an edit
  
      const endpoint1 = calculateOneRepMax;
      const endpoint2 = calculatePersonalRecord;
      // Make the API call
      await makeApiCall(endpoint1, payload, token);
      await makeApiCall2(endpoint2, payload2, token);
    }
   
  } catch (err) {
    console.error(err);
  }
};
const makeApiCall = async (endpoint1, payload, token) => {
  try {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint1}`;
    const config = {
      headers: { Authorization: token },
    };
    // Making the API call
    const response = await axios.post(url, payload, config);
    console.log(response?.data)
    // Set the API response data to the state
    setApiResponse(response?.data); // You might need to adjust this depending on the structure of your response
  } catch (error) {
    console.error("Error in API call:", error);
    // Optionally, handle errors differently or set error-related state
  }
};

const makeApiCall2 = async (endpoint2, payload, token) => {
  try {
    const url = `${process.env.REACT_APP_Baseurl}${endpoint2}`;
    const config = {
      headers: { Authorization: token },
    };
    // Making the API call
    const response = await axios.post(url, payload, config);
    console.log(response?.data?.savedRepMaxValues)
    // Set the API response data to the state
    setApiPersonalRecordResponse(response?.data?.savedRepMaxValues); // You might need to adjust this depending on the structure of your response
  } catch (error) {
    console.error("Error in API call:", error);
    // Optionally, handle errors differently or set error-related state
  }
};
  return (
    <TraningSession>
      <Section>
        <div>
          <ZoomableImage id="lighticon" src={theme === "light" ? Av : DarkAv} />
          <span
            className={styles.cardiotext}
            style={{
              fontSize: "22px",
              fontWeight: "700",
              marginRight: "20px",
              color: "black",
            }}
          >
            {getAtheleteSessionExercise?.title}
          </span>
        </div>
        <div>
          {/* <div className={styles.historyDetails} onClick={StartHistoryMenu}>
            <ZoomableImage
              id="lighticon"
              src={theme === "light" ? DetailsHistoy : DetailsHistoy}
            />
          </div> */}
        </div>
      </Section>

    {getAtheleteSessionExercise &&  getAtheleteSessionExercise.isComplete===false ? (
        <button className={styles.startSession} onClick={handleSession}>
          <span>Start Sessions</span>
        </button>
      ) : getAtheleteSessionExercise && getAtheleteSessionExercise.isComplete===true ? (
        <div className="CompleteSession">
          <div className="main">
            <div
              className="custom-box"
              style={{
                background: "#25BD3D",
              }}
            >
              <SvgIcon iconName="block" size={30} color="blue" />
              <p className="main_text" >Blocks</p>
              <h5  className="sub_text">{getAtheleteSessionExercise ? getAtheleteSessionExercise?.totalBlocks : "0"}/5</h5>
            </div>
            <div
              className="custom-box"
              style={{
                background: "#DC5C3E",
              }}
            >
              <SvgIcon iconName="minutes" size={30} color="blue" />
              <p className="main_text" >Minutes</p>
              <h5 className="sub_text" >{getAtheleteSessionExercise ? getAtheleteSessionExercise?.totalDuration : "0"}</h5>
            </div>
            <div
              className="custom-box"
              style={{
                background: "#3919EC",
              }}
            >
              <SvgIcon iconName="infinty" size={30} color="blue" />
              <p className="main_text" >Intensity</p>
              <h5 className="sub_text" >{getAtheleteSessionExercise ? getAtheleteSessionExercise?.intensity : "0"}</h5>
            </div>
            <div
              className="custom-box"
              style={{
                background: "#09D0AD",
              }}
            >
              <SvgIcon iconName="LB" size={30} color="blue" />
              <p className="main_text"  >LB</p>
              <h5  className="sub_text" >{getAtheleteSessionExercise ? getAtheleteSessionExercise?.totalWeight : ""}</h5>
            </div>
          </div>

          <button className={styles.startSession} onClick={viewSummary}>
            <span>View Summary</span>
          </button>
        </div>
        
      )
      :
      null
    
    }
      <ViewSummaryModel
      getAtheleteSessionExercise={getAtheleteSessionExercise}
      selectedDate={selectedDate}
      totalBlocks={totalBlocks}
      totalRepsValue={totalRepsValue}
      totalSets={totalSets}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </TraningSession>
  );
};

export default AtheleteDetails;
