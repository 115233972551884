import React from 'react'
import style from './fifth.module.css'
import { RotPhone1, RotPhone2 } from '../../../../../assessts/LandingPage'
import Button from '../../../Components/Button/Button'

const Fifth = () => {
  return (
      <div className={`${style.main} `}>
        <div className={`${style.leftSide} d-flex flex-row  position-relative`}>
          <div className={`${style.phones} `}>
            <img src={RotPhone1} className={`${style.rotP1} position-absolute`} />
          </div>
          <div className={`${style.phones}`}>
            <img src={RotPhone2} className={`${style.rotP2}`} />
          </div>
        </div>
        <div className={`${style.rightSide}`}>
          <div className={`${style.Written} `}>
            <h1>DON'T PAY MORE FOR DOING MORE</h1>
            <p>We believe in fairness and simplicity. We charge a single flat rate that grants you unlimited access to our platform, allowing you to have as many clients as you desire. It's time to free yourself from constraints and grow your coaching business without limitations.</p>
            <Button title={'View Plans'} bg={false} />
          </div>
        </div>
      </div>
  )
}

export default Fifth
