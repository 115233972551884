// Dashboard API
export const getTotalStatus = "/api/v1/coach/getTotalCounts"
export const getFeeds = "/api/v1/coach/viewCoachFeed"
export const likeFeed = "/api/v1/coach/likeOnFeedPost"
export const getSessionHomepageCoach = "/api/v1/coach/viewCoachSessionsCurrDate";
export const getAssCoach = "/api/v1/coach/viewAllAssCoach";
export const inviteAssCoach = "/api/v1/coach/InviteAssCoach";
export const getSessionCalender = "/api/v1/coach/getCoachSessisonsByDate";

//Athelete Api 
export const inviteAthelete = "/api/v1/coach/inviteAth"
export const getCoachAthelete = "/api/v1/coach/viewAllCoachAthletes"
export const createAtheleteSession = "/api/v1/coach/createAthSession"
export const deleteAthelete = "/api/v1/coach/deleteAth";
export const deleteInvitedAthelete = "/api/v1/coach/deleteInvitedAthlete";
export const getAllAthelete = "/api/v1/coach/viewAllCoachAthletesDropDown";
export const invitedAthelete = "/api/v1/coach/viewAllCoachAthletesDropDown";
export const invitedAtheleteDropDown = "/api/v1/coach/viewInvitedAthletesDropDown";
export const getInvitedAthelete = "/api/v1/coach/viewInvitedAthletes";
export const fetchUserByEmail = "/api/v1/coach/fetchUserByEmail";
export const DeleteSession = "/api/v1/coach/deleteAthSession";
export const DeleteIndexSessionBlock = "/api/v1/coach/deleteSessionBlock"
export const DeleteSessionBlockAthelete = "/api/v1/coach/deleteAthSessionBlock"

//Groups Api 
export const createCoachGroup = "/api/v1/coach/createGroup";
export const editCoachGroup = "/api/v1/coach/editGroup";
export const viewCoachGroup = "/api/v1/coach/viewAllCoachGroups";
export const deleteCoachGroup = "/api/v1/coach/deleteGroup";
export const createGroupSession = "/api/v1/coach/createCoachIndexSession";
export const getAtheleteSessionCalender = "/api/v1/coach/viewAthltSessions"
export const veiwGroupSession = "/api/v1/coach/ViewGroupSessions";
export const getAllGroups = "/api/v1/coach/viewAllCoachGroupsDropdown";
export const DeleteIndexSession = "/api/v1/coach/deleteCoachIndexSession";
export const DeleteGroupSession = "/api/v1/coach/deleteGroupSession";
export const DeleteGroupBlockSession = "/api/v1/coach/deleteGroupSessionBlock";

//Index Api 's

//Program Api
export const createProgram = "/api/v1/coach/createProgram";
export const getAllPrograms = "/api/v1/coach/viewCoachIndexPrograms";
export const editProgram = "/api/v1/coach/editProgram";
export const deleteProgram = "/api/v1/coach/deleteProgram";
export const addSessionsToProgram = "/api/v1/coach/addSessionsToProgram";
export const getProgramSession = "/api/v1/coach/getProgramSessions";

//Session Api 
export const createSession = "/api/v1/coach/createCoachIndexSession";
export const getALLSessions = "/api/v1/coach/viewCoachIndexSessions";
export const editSession = "/api/v1/coach/editCoachIndexSession";
export const deleteSession = "/api/v1/coach/deleteCoachIndexSession";
export const getAllExerciseList = "/api/v1/coach/getAllCoachExercises";
export const deleteBlockSession = "/api/v1/coach/deleteSessionBlock";

//Exercise Api 
export const createExercise = "/api/v1/coach/createCoachIndexExercise";
export const getAllExercise = "/api/v1/coach/viewCoachIndexExercises";
export const editExercise = "/api/v1/coach/editCoachIndexExercise";
export const deleteExercise = "/api/v1/coach/deleteCoachIndexExercise"

//MarketPlace Api 
export const getMarketPlaceData = "/api/v1/coach/viewCoachMPPrograms";

// Analytic Api 
export const getSessionAnalytics = "/api/v1/coach/getSessions"

//Business Api 
export const coachSubscritpion = "/api/v1/coach/coachSubscription";
export const getCustomerPortal = "/api/v1/coach/getCustomerPortal";
export const coachSubscription = "/api/v1/coach/coachSubscription";
export const checkCoachConnect = "/api/v1/coach/checkConnectId";
export const checkCoachSubscription = "/api/v1/coach/checkcoachSub";

// Customization Colors 
export const checkSubscription = "/api/v1/coach/checkSubscription";
export const lightThemeColor = "/api/v1/coach/updateLightThemeColor";
export const getCustomLightThemeColor = "/api/v1/coach/getCustomLightThemeColors"
export const defaultLightThemeColor = "/api/v1/coach/getDefaultLightThemeColors"
export const darkThemeColor = "/api/v1/coach/updateDarkThemeColor";
export const defaultDarkThemeColor = "/api/v1/coach/coachSubscription";


// Copy Session Api 
export const copyCalenderSession = "/api/v1/coach/copySessions"


// Publish and Unpublish Api /
export const publishSession = "/api/v1/coach/publishSession"
export const unPublishSession = "/api/v1/coach/unPublishSession"