import React, { useContext, useEffect, useState } from "react";
import "./group.css";
import GroupTable from "./groupTable/groupTable";
import { FaSearch, FaPlus } from "react-icons/fa";

import InviteAthleteModal from "../../modals/inviteAtheleteModal";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../../../context/sessionContext";
import axios from "axios";
import { auth } from "../../../firebase";
import { deleteCoachGroup, viewCoachGroup } from "../../../apis";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
import CreateGroupModal from "../../modals/createGroupModal";
import { getAllGroups } from "../../../services/store/coachApi";
import { getApiMethod } from "../../../store/useGetApi";
import MonthlyCalender from "../../modules/Calender/MonthCalender";
const Group = (props) => {
  const { activeTab } = props;
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [show, setShow] = useState(false);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isGrouModalOpen, setGroupModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeCheckBox, setActiveCheckBox] = useState(true);
  const [getGroupLists, setGroupLists] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [isEditGroup, setEditGroup] = useState("");
  const [isGetGroupDetails, setGetGroupDetails] = useState("");
  const [isTotalPages, setTotalPages] = useState("");
  const [groupIdOnClcik, setGroup] = useState(null);
  const [isEmptyData, setEmptyData] = useState(false);
  const [getDropDownData, setDropDownData] = useState([])
  const [refresh,setRefresh]=useState(false)
  const [isPaginataionGroups, setPaginataionGroups] = useState(() => {
    const savedPage = localStorage.getItem("activePageGroup");
    return savedPage !== null ? JSON.parse(savedPage) : 1;
  });
  const [SelectedData,setSelectedData]=useState()
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); 
      if (currentUser) {
        fetchGroups(isPaginataionGroups); 
      } else {
        // User is signed out
        console.log("No user is signed in.");
      }
    });

    return () => unsubscribe();
  }, [isPaginataionGroups]); 

  useEffect(() => {
    localStorage.setItem(
      "activePageGroup",
      JSON.stringify(isPaginataionGroups)
    );
  }, [isPaginataionGroups]);
  const handleActivePaginationGroup = (page) => {
    setPaginataionGroups(page);
  };
  const openInviteModal = (group) => {
    setGroup(group);

    setInviteModalOpen(true);
  };
  const closeInviteModal = () => {
    fetchGroups();
    setInviteModalOpen(false);
  };

  const openGroupModal = () => {
    setGroupModalOpen(true);
  };
  const closeGroupModal = () => {
    setGroupModalOpen(false);
    fetchGroups();
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      fetchGroups();
    }
  };
  useEffect(() => {
    fetchGroups(isPaginataionGroups);
    // Add event listener for visibilitychange
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isPaginataionGroups]);

  const fetchGroups = async () => {
    if (!auth.currentUser) {
      return; // Exit the function if no user is logged in.
    }
    setLoader(true);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${viewCoachGroup}?currentPage=${isPaginataionGroups}&itemsPerPage=4`,
        { headers: { Authorization: token } }
      );
      {
        response.data.groups.length <= 0
          ? setEmptyData(true)
          : setEmptyData(false);
      }
      setGroupLists(response.data.groups);
      console.log(response.data, "response.data");
      setTotalPages(response.data.totalPages);
      console.log(response.data.totalPages);
      console.log(response.data.groups);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoader(false);
  };
  const handleDeleteGroups = async () => {
    setActiveCheckBox(!activeCheckBox);
    try {
      const token = await auth.currentUser.getIdToken(true);

      await axios.delete(
        `${process.env.REACT_APP_Baseurl}${deleteCoachGroup}`,
        {
          headers: { Authorization: token },
          params: { groupId: selectedGroup },
        }
      );
      // After deletion, you might want to refresh the table data
      setSelectedGroup([]);
      await fetchGroups();
      toast.success("Groups Deleted successfully");
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };
  const handleDeleteBoth = async () => {
    try {
      await handleDeleteGroups();
    } catch (error) {
      // Error handling if either of the deletions fail
      console.error("Error in deleting:", error);
    }
  };


  const handleGroupDropDown = async () => {
    try {
      const endpoint = getAllGroups; // Fixed typo
      const dropDownData = await getApiMethod(endpoint, "get");
      setDropDownData(dropDownData?.groups);
    } catch (error) {
      console.error("Error fetching groups:", error); 
    }
  };
  
  useEffect(() => {
    handleGroupDropDown();
  }, [getAllGroups]); 
  
  useEffect(()=>{
    fetchGroups()
  },[refresh])
  return (
   

    <Container fluid>
      <div
        style={{
          backgroundColor: `var(--secondary-bg-color,white)`,
        }}
        className="athltContainer"
      >
        {/* {!show && <div className="athltHeader" style={{ fontWeight: "600" }}>{t("Group")}</div>} */}
        <div
          className={!show && "athbody"}
          style={{
            padding: !show && "2rem",
          }}
        >
          {!show && (
            <div className="athlt2ndHeader">
              <div>
                <div
                  style={{
                    maxWidth: "235px",
                    backgroundColor: `var(--primary-bg-color,white)`,
                  }}
                  className="search-container"
                >
                  <span className="search-icon h-100 d-flex justify-content-center">
                    <FaSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-input"
                  />
                </div>
              </div>
              <div className="athltbuttons">
                <button
                  style={{
                    color: "var(--primary-text-color, #A19686)",
                    background: "var(--primary-color, #F6F6F0)",
                  }}
                  className="delete-button"
                  onClick={handleDeleteBoth}
                >
                  <span className="delete-icon">
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.875 4.25H3.125C2.77982 4.25 2.5 4.52982 2.5 4.875C2.5 5.22018 2.77982 5.5 3.125 5.5H16.875C17.2202 5.5 17.5 5.22018 17.5 4.875C17.5 4.52982 17.2202 4.25 16.875 4.25Z"
                            fill="var(--primary-text-color, #A19686)"
                      />
                      <path
                        d="M7.5 8.625V13.625C7.5 13.9702 7.77982 14.25 8.125 14.25C8.47018 14.25 8.75 13.9702 8.75 13.625V8.625C8.75 8.27982 8.47018 8 8.125 8C7.77982 8 7.5 8.27982 7.5 8.625Z"
                           fill="var(--primary-text-color, #A19686)"
                      />
                      <path
                        d="M11.25 8.625V13.625C11.25 13.9702 11.5298 14.25 11.875 14.25C12.2202 14.25 12.5 13.9702 12.5 13.625V8.625C12.5 8.27982 12.2202 8 11.875 8C11.5298 8 11.25 8.27982 11.25 8.625Z"
                            fill="var(--primary-text-color, #A19686)"
                      />
                      <path
                        d="M5 16.75V4.875C5 4.52982 4.72018 4.25 4.375 4.25C4.02982 4.25 3.75 4.52982 3.75 4.875V16.75C3.75 17.2678 4.11612 17.6339 4.11612 17.6339C4.48223 18 5 18 5 18H15C15.5178 18 15.8839 17.6339 15.8839 17.6339C16.25 17.2678 16.25 16.75 16.25 16.75V4.875C16.25 4.52982 15.9702 4.25 15.625 4.25C15.2798 4.25 15 4.52982 15 4.875V16.75H5Z"
                            fill="var(--primary-text-color, #A19686)"
                      />
                      <path
                        d="M6.79918 2.29917C6.25 2.84835 6.25 3.625 6.25 3.625V4.875C6.25 5.22018 6.52982 5.5 6.875 5.5C7.22018 5.5 7.5 5.22018 7.5 4.875V3.625C7.5 3.36612 7.68306 3.18306 7.68306 3.18306C7.86612 3 8.125 3 8.125 3H11.875C12.1339 3 12.3169 3.18306 12.3169 3.18306C12.5 3.36612 12.5 3.625 12.5 3.625V4.875C12.5 5.22018 12.7798 5.5 13.125 5.5C13.4702 5.5 13.75 5.22018 13.75 4.875V3.625C13.75 2.84835 13.2008 2.29917 13.2008 2.29917C12.6517 1.75 11.875 1.75 11.875 1.75H8.125C7.34835 1.75 6.79918 2.29917 6.79918 2.29917Z"
                            fill="var(--primary-text-color, #A19686)"
                      />
                    </svg>
                  </span>
                  {t("Remove Group")}
                </button>
                <button
                  style={{
                    color: `var(--secondary-text-color,#FFF)`,
                    background: `var(--secondary-color,#A19686)`,
                  }}
                  className="invite-button"
                  onClick={openGroupModal}
                >
                  <span className="plus-icon">
                    <FaPlus />
                  </span>
                  {t("Create Group")}
                </button>
              </div>
            </div>
          )}
          {show && (
            <MonthlyCalender
            SelectedData={SelectedData}
              id={isGetGroupDetails}
              tab={activeTab}
              groupList={getGroupLists}
              isOpenCalender={setShow}
              dropDownOption= {getDropDownData}
            />
          )}
          {!show && (
            <GroupTable
            setRefresh={setRefresh}
            refresh={refresh}
            setSelectedData={setSelectedData}
              isEmptyData={isEmptyData}
              setGetGroupDetails={setGetGroupDetails}
              setShow={setShow}
              groupList={getGroupLists}
              loader={loader}
              setSelectedGroup={setSelectedGroup}
              selectedGroup={selectedGroup}
              activeCheckBox={activeCheckBox}
              isEditGroup={isEditGroup}
              setEditGroup={setEditGroup}
              refreshGroups={fetchGroups}
              handleActivePaginationGroup={handleActivePaginationGroup}
              isPaginataionGroups={isPaginataionGroups}
              isTotalPages={isTotalPages}
              openInviteModal={openInviteModal}
            />
          )}
        </div>
        {isGrouModalOpen && (
          <CreateGroupModal
         
          isEditGroup={isEditGroup}
            isOpen={isGrouModalOpen}
            onClose={closeGroupModal}
          />
        )}

        {isInviteModalOpen && (
          <InviteAthleteModal
            groupIdOnClcik={groupIdOnClcik}
            isOpen={isInviteModalOpen}
            onClose={closeInviteModal}
          />
        )}
      </div>
    </Container>
  );
};

export default Group;
