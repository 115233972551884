import React, { createContext, useEffect, useState } from 'react';
import Av from "../assessts/images/avtr.png";
export const SessionContext = createContext();

/////// Initial State 
// const initialState = {
//   sessionHistory: false,
//   isInviteAssitantCoach: false,
//   isStartSession: false,
//   isManageTrainingModalOpen: false,
//   isRestTimeModalOpen: false,
//   isArampModal: false,
//   isForTime: false,
//   isOpenEmomTimer: false,
//   isTabataTimer: false,
//   isOpenCustomInterval: false,
//   isEndTraningSession: false,
//   isOpenPostFeedModal: false,
//   isOpenGroupHistory: false,
//   isActiveIndex: "program",
//   isProgramDetails: false,
//   isSessionDetail: false,
//   isOpenExerciseHistory: false,
//   isRound: 3,
//   isWork: 20,
//   isRest: 10,
//   istabataTimerCounter: false,
//   isCountDownActive: false,
//   preCountdown: 0,
//   countsecond: 3,
//   isStopWatchRun: false,
//   minutes: 0,
//   seconds: 0,
//   countdownStatus: "",
//   isOpenCustomCounter: false,
//   round: 3,
//   workMinutes: 1,
//   workSeconds: 30,
//   restMinutes: 1,
//   restSeconds: 0,
//   isOpenArampModalCounter: false,
//   forMinutes: 0,
//   forSeconds: 0,
//   everyrMinutes: 0,
//   everyrSeconds: 0,
//   isEmomRound: 0,
//   selectedDropdownValue: 0,
//   isOpenEMOMTimerCounter: false,
// }

// Reducer Function
// const sessionReducer = (state, action) => {
//   switch (action.type) {
//     case 'TOGGLE_SESSION_HISTORY':
//       return { ...state, sessionHistory: !state.sessionHistory };
//     case 'TOGGLE_INVITE_ASSISTANT_COACH':
//       return { ...state, isInviteAssitantCoach: !state.isInviteAssitantCoach };
//     case 'TOGGLE_START_SESSION':
//       return { ...state, isStartSession: !state.isStartSession };
//     case 'TOGGLE_MANAGE_TRANING_MODAL':
//       return {
//         ...state,
//         isManageTrainingModalOpen: !state.isManageTrainingModalOpen,
//         isRestTimeModalOpen: false,
//         isArampModal: false,
//         isForTimeModal: false,
//         isStopWatchRun: false,
//         isTabataTimer: false,
//         isOpenCustomInterval: false,
//         isCountDownActive: false,
//         isOpenEMOMTimerCounter: false,
//         isEmomTimer: false,
//         isOpenCustomCounter: false,
//       }
//     case 'TOGGLE_REST_TIME_MODAL':
//       return { ...state, isRestTimeModalOpen: !state.isRestTimeModalOpen };
//   }

// }

export const SessionProvider = ({ children }) => {


  // States To Open Modals 
  // Invite Assistant Coach
  const [isInviteAssitantCoach, setInviteAssitantCoach] = useState(false)
  const [isSessionIndexDetails, setSessionIndexDetails] = useState({})
  const [colors, setColors] = useState({
    primary: '#F6F6F0',
    primaryBg: '#F1F5F9',
    primaryText: '#606060',
    secondary: "#A19686",
    secondaryBg: "#ffffff",
    secondaryText: "#000000",
    darkPrimary: "#856DEC",
    darkPrimaryBg: '#ff8181',
    darkPrimaryText: '#83df9b',
    darkSecondary: "#856DEC",
    darkSecondaryBg: "#ff8181",
    darkSecondaryText: "#83df9b",

  });
  //History Session State
  const [sessionHistory, setSessionHistory] = useState(false);
  const [selectedDate, setSelectedDate] = useState({})
  const [ProgrammeDetail,setProgrammeDetail]=useState()
  //Start Session Details
  const [isStartSession, setStartSession] = useState(false)
  //Manage Traning Modal Opens State
  const [isManageTrainingModalOpen, setIsManageTrainingModalOpen] = useState(false);
  //Rest Time Modal Open State
  const [isRestTimeModalOpen, setIsRestTimeModalOpen] = useState(false);
  //Aramp Modal Open State
  const [isArampModal, setArampModal] = useState(false);
  //For Time & Stop Watch Modal Open State
  const [isForTime, setForTimeModal] = useState(false)
  //EMOM Modal Open State
  const [isOpenEmomTimer, setEmomTimer] = useState(false)
  //Tabata Timer Modal Open State
  const [isTabataTimer, setTabataTimer] = useState(false)
  //Custom Interval Modal Open State
  const [isOpenCustomInterval, setOpenCustomInterval] = useState(false)
  //Next Session Modal 
  const [isEndTraningSession, setEndTraningSession] = useState(false)
  //Post Feed Modal 
  const [isOpenPostFeedModal, setOpenPostFeedModal] = useState(false)
  //Create Feed Modal 
  const [isOpenCreateFeed, setOpenCreateFeed] = useState(false)
  //Handle to Group Details 
  const [isOpenGroupHistory, setOpenGroupHistory] = useState(false)
  //State For Index
  //Handle To Index 
  const [isActiveIndex, setActiveIndex] = useState("program")
  //Handle To Program Details
  const [isProgramDetails, setProgramDetails] = useState(false)
  //Handle To Index Sessoion
  const [isSessionDetail, setSessionDetails] = useState(false)
  //Handle To Index Exercise 
  const [isOpenExerciseHistory, setOpenExerciseHistory] = useState(false)


  // State to Handle Tabata Timer Rounds, Work, Rest
  const [isRound, setRound] = useState(3)
  const [isWork, setWork] = useState(20)
  const [isRest, setRest] = useState(10)
  const [istabataTimerCounter, setTabataTimerCounter] = useState(false)

  // State to Handle EMOM Timer Counter 
  const [isOpenEMOMTimerCounter, setOpenEMOMTimerCounter] = useState(false)

  // State to Handle Aramp Counter Modal 
  const [isOpenArampModalCounter, setOpenArampModalCounter] = useState(false)
  // State to Handle Every and For Input 
  const [forMinutes, setForMinutes] = useState(0);
  const [forSeconds, setForSeconds] = useState(0);
  const [everyrMinutes, setEveryrMinutes] = useState(0);
  const [everyrSeconds, setEveryrSeconds] = useState(0);
  const [isEmomRound, setEmomRound] = useState(0)
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(0);
  const [rounds, setRounds] = useState(3);
  const [workMinutes, setWorkMinutes] = useState(1);
  const [workSeconds, setWorkSeconds] = useState(30);
  const [restMinutes, setRestMinutes] = useState(1);
  const [restSeconds, setRestSeconds] = useState(0);

  // Funtion To Handle Play Session For Counter 
  const [countdownStatus, setCountdownStatus] = useState("");

  const [isOpenCustomCounter, setOpenCustonCounter] = useState(false)
  // State to Handle Count Down A
  const [isCountDownActive, setIsCountDownActive] = useState(false);

  // State to Handle Count Down
  const [preCountdown, setPreCountdown] = useState(0);
  const [countsecond, setCountSecond] = useState(3)
  // State to Handle Stop Watch 
  const [isStopWatchRun, setStopWatchRun] = useState(false)

  // State to Handle Mins And Seconds
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  //State to Handle Loader 
  const [isLoader, setLoader] = useState(false)

  const [isActivePaginationProgamme, setActivePaginationProgamme] = useState(() => {
    const savedPage = localStorage.getItem('ProgammeactivePage');
    return savedPage !== null ? JSON.parse(savedPage) : 1;
  });

 


  const handleActivePaginationProgamme = (page) => {
    setActivePaginationProgamme(page);
  };
 

  const [isActivePaginationExercise, setActivePaginationExercise] = useState(() => {
    const savedPage = localStorage.getItem('ExerciseactivePage');
    return savedPage !== null ? JSON.parse(savedPage) : 1;
  });




  const handleActivePaginationExercise = (page) => {
    setActivePaginationExercise(page);
  };
  


  //Function To Handle Exercise Session 
  const handleExerciseSession = () => {
    setOpenExerciseHistory(!isOpenExerciseHistory)
  }

  //Function To Handle Start Session Screen
  const handleStartSessionGroup = () => {
    setStartSession(!isStartSession)
  }
  // Function To Handle Session Details 
  const handleSessionDetail = () => {
    setSessionDetails(!isSessionDetail)
  }
  //Function to Handle Program Details
  const handleProgramDetails = () => {
    setProgramDetails(!isProgramDetails)
  }
  //Fuction To Handle Group History 
  const handleOpenGroupHistory = () => {
    setOpenGroupHistory(!isOpenGroupHistory)
  }

  //Function To Handle Modal 
  const handleOpenPostFeed = () => {
    setOpenPostFeedModal(!isOpenPostFeedModal)
    setEndTraningSession(!isEndTraningSession)
  }
  const handleCreateFeed = () => {
    setOpenCreateFeed(!isOpenCreateFeed)
    setOpenPostFeedModal(false)
    setEndTraningSession(false)
  }

  //Function To handle End Traning Session
  const handleEndSession = () => {
    setEndTraningSession(!isEndTraningSession)
  }

  // Functio To Handle Custom Interval 
  const handleCustomInterval = () => {
    setOpenCustomInterval(true);
    setIsManageTrainingModalOpen(false);
    setOpenCustonCounter(false);
  }

  // Funtion To Handle The Show History
  const handleStartSession = () => {
    setSessionHistory(true);
  };

  // Funtion To Handle Traning Modal 
  const handleManageTrainingModal = () => {
    setIsManageTrainingModalOpen(true);
    setIsRestTimeModalOpen(false);
    setArampModal(false);
    setForTimeModal(false);
    setStopWatchRun(false);
    setTabataTimer(false);
    setOpenCustomInterval(false);
    setIsCountDownActive(false);
    setOpenEMOMTimerCounter(false);
    setEmomTimer(false);
    setOpenCustonCounter(false);
  };

  // Funtion To Close All Modals
  const handleCloseModals = () => {
    setIsManageTrainingModalOpen(false);
    setIsRestTimeModalOpen(false);
    setArampModal(false);
    setForTimeModal(false);
    setIsCountDownActive(false)
    setStopWatchRun(false);
    setEmomTimer(false);
    setTabataTimer(false);
    setOpenEMOMTimerCounter(false);
    setOpenCustonCounter(false);
    setTabataTimerCounter(false);
  }

  // Funtion to Handle Rest Time Modal
  const handleOpenRestTimeModal = () => {
    setIsRestTimeModalOpen(true);
    setIsManageTrainingModalOpen(false);
  };

  // Function to Open ARAMP Modal
  const handleOpenArampModal = () => {
    setArampModal(true);
    setIsManageTrainingModalOpen(false);
  };

  //Funtion TO Open For Time Modal
  const handleOpenForTime = () => {
    setForTimeModal(true);
    setIsManageTrainingModalOpen(false);
  }

  // Function To Open Stop Watch Timer 
  const handleStartTimer = () => {
    setStopWatchRun(true);
    setForTimeModal(false);
  }
  // Funtion to Open Count Down Modal
  const handleOpenCountDownModal = () => {
    setIsCountDownActive(true);
    setIsRestTimeModalOpen(false);
    setIsManageTrainingModalOpen(false);
    setArampModal(false);
  };

  // Funtion To Handle Tabata Timer 
  const handleOpenTabataTimerModal = () => {
    setTabataTimer(true);
    setIsManageTrainingModalOpen(false)
  }

  // Funtion TO Handle Assitant Coach
  const handleAssistantCoach = () => {
    setInviteAssitantCoach(true)
  }


  const speak = (text, callback) => {
    const speech = new SpeechSynthesisUtterance(text);
    speech.onend = callback;
    window.speechSynthesis.speak(speech);
  };


  const handleArampCounter = () => {

    setCountdownStatus("Get Ready");

    speak("Get Ready", () => {
      setTimeout(() => {
        let countdownValue = countsecond;
        setCountdownStatus("Counting");
        setPreCountdown(countdownValue);
        setArampModal(false);
        const countdown = () => {
          if (countdownValue > 0) {
            setPreCountdown(countdownValue);
            speak(countdownValue.toString(), countdown);
            countdownValue--;
          } else {
            setCountdownStatus("Go");
            speak("Go", () => {
              setTimeout(() => {
                setOpenArampModalCounter(true)
                setArampModal(false);
                setPreCountdown(null);
              }, 500);
            });
          }
        };
        countdown();
      }, 2000); // Delay for starting the countdown after "Get Ready"
    });
  };


  const handleForTimeCounter = () => {
    setCountdownStatus("Get Ready");

    speak("Get Ready", () => {
      setTimeout(() => {
        let countdownValue = countsecond;
        setCountdownStatus("Counting");
        setPreCountdown(countdownValue);
        setForTimeModal(false);
        const countdown = () => {
          if (countdownValue > 0) {
            setPreCountdown(countdownValue);
            speak(countdownValue.toString(), countdown);
            countdownValue--;
          } else {
            setCountdownStatus("Go");
            speak("Go", () => {
              setTimeout(() => {
                setStopWatchRun(true)
                setForTimeModal(false);
                setPreCountdown(null);
              }, 500);
            });
          }
        };
        countdown();
      }, 2000); // Delay for starting the countdown after "Get Ready"
    });
  };

  const handleTabatTimerCounter = () => {
    setCountdownStatus("Get Ready");
    speak("Get Ready", () => {
      setTimeout(() => {
        let countdownValue = countsecond;
        setCountdownStatus("Counting");
        setPreCountdown(countdownValue);
        setTabataTimer(false)
        const countdown = () => {
          if (countdownValue > 0) {
            setPreCountdown(countdownValue);
            speak(countdownValue.toString(), countdown);
            countdownValue--;
          } else {
            setCountdownStatus("Go");
            speak("Go", () => {
              setTimeout(() => {
                setTabataTimerCounter(true);
                setPreCountdown(null);
              }, 500);
            });
          }
        };
        countdown();
      }, 2000); // Delay for starting the countdown after "Get Ready"
    });
  };

  const handlePlaySession = () => {
    setCountdownStatus("Get Ready");
    speak("Get Ready", () => {
      setTimeout(() => {
        let countdownValue = countsecond;
        setCountdownStatus("Counting");
        setPreCountdown(countdownValue);
        setOpenCustomInterval(false)
        const countdown = () => {
          if (countdownValue > 0) {
            setPreCountdown(countdownValue);
            speak(countdownValue.toString(), countdown);
            countdownValue--;
          } else {
            setCountdownStatus("Go");
            speak("Go", () => {
              setTimeout(() => {
                setOpenCustonCounter(true);
                setPreCountdown(null);
              }, 500);
            });
          }
        };
        countdown();
      }, 2000); // Delay for starting the countdown after "Get Ready"
    });
  };


  const handleInputChange = (e, type) => {
    const value = parseInt(e.target.value.replace(/[^0-9]/g, ''), 10) || 0;
    if (type === 'rounds') {
      setRounds(value);
    }
  };


  // Funtion TO Handle EMOM Timer 
  const handleEMOTimer = () => {
    setEmomTimer(true);
    setIsManageTrainingModalOpen(false);

  }


  const handleEMOMTimerCounter = () => {
    setCountdownStatus("Get Ready");
    speak("Get Ready", () => {
      setTimeout(() => {
        let countdownValue = countsecond;
        setCountdownStatus("Counting");
        setPreCountdown(countdownValue);
        setEmomTimer(!isOpenEmomTimer);
        const countdown = () => {
          if (countdownValue > 0) {
            setPreCountdown(countdownValue);
            speak(countdownValue.toString(), countdown);
            countdownValue--;
          } else {
            setCountdownStatus("Go");
            speak("Go", () => {
              setTimeout(() => {
                setOpenEMOMTimerCounter(true);
                setPreCountdown(null);
              }, 500);
            });
          }
        };
        countdown();
      }, 2000); // Delay for starting the countdown after "Get Ready"
    });
  };

  //State To Delete Athelete Table Data
  const [isShowDeleteCheckBox, setShowDeleteCheckBox] = useState(true)

  const handleDelete = () => {
    setShowDeleteCheckBox(!isShowDeleteCheckBox)
    const updatedData = isAtheleteData.filter((data) => !selectedItems.includes(data.id));
    setAtheleteData(updatedData);

    // Clear the selected items
    setSelectedItems([]);
  }

  const [isAtheleteData, setAtheleteData] = useState([
    {
      id: 1,
      name: "Daniyal Khan",
      programName: "DevOps",
      jioned: "25/10/2023",
      action: ""
    },
    {
      id: 2,
      name: "Daniyal Khan",
      programName: "DevOps",
      jioned: "25/10/2023",
      action: ""
    }
  ])
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleSelection = (itemId) => {
    console.log(itemId)
    if (selectedItems.includes(itemId)) {
      // If item is already selected, remove it from the selected items
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      // If item is not selected, add it to the selected items
      setSelectedItems([...selectedItems, itemId]);
    }
  };
  //State To Handle Groups Data 
  const [isShowCheckBoxGroups, setShowCheckBoxGroups] = useState(false)
  const [groupData, setGroupData] = useState(
    [
      {
        id: 5,
        img: Av,
        name: "Daniyal Khan",
        plannedSession: 24,
        sport: "Sport Type",
        action: "",
        athelete: [
          {
            userId: 123,
            img: Av
          },
          {
            userId: 456,
            img: Av
          },
          {
            userId: 854,
            img: Av
          },
          {
            userId: 126,
            img: Av
          },
        ]
      },
      {
        id: 15,
        img: Av,
        name: "Inthair Hussian",
        plannedSession: 24,
        sport: "Sport Type",
        action: "",
        athelete: [
          {
            userId: 12,
            img: Av
          },
          {
            userId: 456,
            img: Av
          },
          {
            userId: 854,
            img: Av
          },
          {
            userId: 126,
            img: Av
          },
        ]
      }
    ]
  )
  //Function To Delete Group Data 
  const handleDeleteGroups = () => {
    setShowCheckBoxGroups(true)
    const updatedGroup = groupData.filter((data) => !groupSelected.includes(data.id));
    setGroupData(updatedGroup)
    setGroupSelected([])
  }
  const [groupSelected, setGroupSelected] = useState([])
  const toggleSelectionGroups = (itemId) => {
    if (groupSelected.includes(itemId)) {
      setGroupSelected(groupSelected.filter((id) => id !== itemId));
    } else {
      setGroupSelected([...groupSelected, itemId]);
    }
  }


  //State to Handle View Analytic Details
  const [isViewAnalyticDetails, setViewAnalyticDetails] = useState(false)
  const handleViewAnalyticModal = () => {
    setViewAnalyticDetails(!isViewAnalyticDetails)
  }

  const [isOpenChat, setOpenChat] = useState(false)
  const handleOpenChat = () => {
    setOpenChat(!isOpenChat)
  }
  const [activeBtn, setActivebtn] = useState("oneToOne");

  const sessionState = {
    setProgrammeDetail,
    ProgrammeDetail,
    setActivePaginationProgamme,
    setActivePaginationExercise,
    setSelectedItems,
    setStartSession,
    handleOpenChat,isOpenChat,
    isActivePaginationExercise,
    handleActivePaginationExercise,
    isActivePaginationProgamme,
    handleActivePaginationProgamme,
    isOpenEMOMTimerCounter,
    handleViewAnalyticModal,
    isViewAnalyticDetails,
    isLoader, setLoader,
    setViewAnalyticDetails,
    isShowCheckBoxGroups,
    groupData,
    groupSelected,
    handleDeleteGroups,
    toggleSelectionGroups,
    selectedItems,
    toggleSelection,
    isShowDeleteCheckBox,
    setShowDeleteCheckBox,
    handleDelete,
    setOpenArampModalCounter,
    selectedDate, setSelectedDate,
    forMinutes,
    isAtheleteData, setAtheleteData,
    isOpenPostFeedModal, setOpenPostFeedModal,
    handleEMOMTimerCounter,
    handleCreateFeed, isOpenCreateFeed,
    setForMinutes,
    forSeconds, setForSeconds, everyrMinutes, setEveryrMinutes, everyrSeconds, setEveryrSeconds, isEmomRound, setEmomRound, handleOpenPostFeed,
    rounds,
    workMinutes,
    setEmomTimer,
    setWorkMinutes,
    sessionHistory,
    workSeconds,
    setWorkSeconds,
    handleExerciseSession, isOpenExerciseHistory,
    restMinutes,
    setRestMinutes,
    isActiveIndex, setActiveIndex,
    restSeconds,
    handleInputChange,
    setRestSeconds,
    setOpenCustomInterval,
    setSessionHistory,
    isManageTrainingModalOpen,
    setIsManageTrainingModalOpen,
    isRestTimeModalOpen,
    handleCustomInterval,
    handleStartSessionGroup,
    isStartSession,
    isOpenCustomInterval,
    setCountSecond,
    countsecond,
    isOpenArampModalCounter,
    setIsRestTimeModalOpen,
    isProgramDetails, setProgramDetails, handleProgramDetails,
    isArampModal,
    setArampModal,
    isCountDownActive,
    setIsCountDownActive,
    countdownStatus,
    minutes,
    setMinutes,
    handleEndSession,
    isEndTraningSession,
    setEndTraningSession,
    seconds,
    setSeconds,
    isSessionDetail,
    handleSessionDetail,
    handleManageTrainingModal,
    handleOpenRestTimeModal,
    handleOpenArampModal,
    handleOpenCountDownModal,
    handleStartSession,
    handlePlaySession,
    handleCloseModals,
    preCountdown,
    isForTime,
    handleOpenTabataTimerModal,
    setForTimeModal,
    handleOpenForTime,
    handleForTimeCounter,
    handleStartTimer,
    isStopWatchRun,
    isTabataTimer,
    handleAssistantCoach,
    isInviteAssitantCoach,
    setRound,
    setWork,
    setRest,
    isRest,
    isWork,
    handleArampCounter,
    isRound,
    setInviteAssitantCoach,
    istabataTimerCounter,
    handleTabatTimerCounter,
    isOpenCustomCounter,
    isOpenEmomTimer,
    handleEMOTimer,
    selectedDropdownValue, setSelectedDropdownValue,
    setTabataTimer,
    handleOpenGroupHistory,
    isOpenGroupHistory,
    isSessionIndexDetails, setSessionIndexDetails, setSessionDetails, activeBtn, setActivebtn,
    colors, setColors
  }
  return (
    <SessionContext.Provider value={sessionState}>
      {children}
    </SessionContext.Provider>
  );
};