import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../../firebase";
import './signinForm.css';
import { AuthContext } from '../../context/authContext';
import LoadingSpinner from '../loadingSpinner';
import PasswordInput from '../ReusableUi/PasswordInput';
import Input from '../ReusableUi/Input';
import { ThemeContext } from '../../context/themeContext';
import useCheckCoachSub from '../../hooks/useCheckCoachSub';
import { checkCoachSubscription } from '../../services/store/coachApi';

const SignInForm = () => {
  const navigation = useNavigate();
  const { socket, setActiveTab } = useContext(ThemeContext);
  const { setCurrentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [fieldErrors, setFieldErrors] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const { fetchSubscription,subscriptionPlan} = useCheckCoachSub(checkCoachSubscription);

  const handleChange = (prop) => (event) => {
    const value = event.target.value;
    if (prop === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setFieldErrors({ ...fieldErrors, [prop]: emailPattern.test(value) ? '' : 'Invalid email format' });
    } else {
      setFieldErrors({ ...fieldErrors, [prop]: value ? '' : 'This field is required' });
    }
    setFormData({ ...formData, [prop]: value });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  useEffect(()=>{
    fetchSubscription()
  },[])
  const handleLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const newFieldErrors = {};
  
    // Validate form fields
    for (const field in formData) {
      if (!formData[field]) {
        newFieldErrors[field] = 'This field is required';
      }
    }
  
    // If there are field errors, set them and return
    if (Object.keys(newFieldErrors).length > 0) {
      // Handle field errors (e.g., setFieldErrors(newFieldErrors));
      setIsLoading(false);
      return;
    }
  
    try {
      const data = await signInWithEmailAndPassword(auth, formData.email, formData.password);
      socket.connect();
  
      const redirectPathname = sessionStorage.getItem('redirectURL');
      const userId = auth?.currentUser?.uid;
  
      localStorage.setItem('IsLogged', true);
      localStorage.setItem('userId', userId);
  
      const idTokenResult = await auth.currentUser.getIdTokenResult();

      if (idTokenResult.claims.isAdmin) {
        navigation("/manage-athelete");
      } else if (idTokenResult.claims.isCoach) {
  
        // if (subscriptionPlan.Subscriptions===false || subscriptionPlan.hasActiveSubscriptions===false) {
          localStorage.setItem("activeTab", "business");
          setActiveTab("business");
          navigation("/business");
        // } else if (subscriptionPlan.Subscriptions===true || subscriptionPlan.hasActiveSubscriptions===true) {
          // localStorage.setItem("activeTab", "coach");
          // setActiveTab("coach");
          // navigation("/");
        // }
      } else if (redirectPathname) {
        localStorage.setItem('IsLogged', true);
        localStorage.setItem('userId', userId);
        sessionStorage.removeItem('redirectURL');
        window.location.href = redirectPathname;
      } else {
        localStorage.setItem("activeTab", "manageAtheleteTraining");
        setActiveTab("manageAtheleteTraining");
        navigation("/manage-athelete-traning");
      }
      socket.emit('user_login', { userId: auth.currentUser.uid });
      setCurrentUser("true");
    } catch (error) {
      setIsLoading(false);
      
      switch (error.code) {
        case "auth/invalid-email":
          setEmailError("Invalid Email");
          break;
        case "auth/user-disabled":
        case "auth/user-not-found":
          setEmailError("User Not Found");
          break;
        case "auth/wrong-password":
          setPasswordError("Wrong Password");
          break;
        default:
          // Handle other errors if necessary
          break;
      }
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };
  

  return (
    <form className="signup-form w-100 p-0" onSubmit={handleLogin}>
      <div className="form-field">
        <label>Email</label>
        <Input
          value={formData.email}
          onChange={handleChange('email')}
          className="w-100"
          placeholder="youremail@emial.com"
          type="email"
        />
        <p className="error-message">{fieldErrors.email}</p>
        <p className="error-message">{emailError}</p>
      </div>

      <div className="form-field">
        <label>Password</label>
        <PasswordInput
          value={formData.password}
          onChange={handleChange('password')}
          showPassword={showPassword}
          onToggleShowPassword={toggleShowPassword}
          className="your-custom-class"
        />
        <p className="error-message">{fieldErrors.password}</p>
        <p className="error-message">{passwordError}</p>
      </div>

      <div className="terms-checkbox text-end w-100 d-flex justify-content-end">
        <Link className='forgotPassword' to="#">Forgot your password?</Link>
      </div>

      <div style={{ display: "flex", gap: '10px' }}>
        <button className='button' type="submit">
          {isLoading ? (
            <LoadingSpinner size="sm" />
          ) : ('Login')}
        </button>
      </div>
    </form>
  );
};

export default SignInForm;
