import React from 'react';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import styles from "./reusableUi.module.css";

const PasswordInput = ({ value, onChange, showPassword, onToggleShowPassword, className }) => {
  const combinedClassName = `${styles.input} ${className || ''}`; // Combine provided class name with default

  return (
    <div className={`password-field`}>
      <input
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange} // Make sure this is correctly passed
        placeholder='***********'
        className={combinedClassName}
      />
      <button
        type="button"
        className="show-password-btn d-flex"
        onClick={onToggleShowPassword}
      >
        {showPassword ? <RiEyeOffLine /> : <RiEyeLine />}
      </button>
    </div>
  );
}

export default PasswordInput;
