import React from 'react'
import Layout from '../../Layout'
import { MPopularTag, Pointer } from '../../../../assessts/LandingPage'
import style from './pricing.module.css'
import Button from '../../Components/Button/Button'

const Pricing = () => {

    const pricingCards = [
        {
            amount: 'Free',
            time: 'Life Time',
            cardName: "Starter",
            description: 'Ideal for entry level coaches starting up their business.',
            points: ['Ideal for new coaches.', 'Manage up to 3 clients with ease.', 'Design and deliver up to 3 distinct training programs.', 'Internal Messenger'],
            img: null
        },
        {
            amount: '$9.99',
            time: 'Per Month',
            cardName: "Growth",
            description: "For those building their clientele base and exploring more potential",
            points: ['Designed for coaches expanding their client base.', 'Manage 4 to 10 clients seamlessly.', 'Unlimited program creation: diversify your offerings without limits.', 'Enhanced support and faster response times.'],
            img: null
        },
        {
            amount: '$39.99',
            time: 'Per Month',
            cardName: "Professional",
            description: "Its time to take your business to the next level.",
            points: ['Tailored for established coaches or fitness businesses.', 'Unlimited clients: scale without restrictions.', 'Unlimited program creation to cater to diverse client needs.', 'Payment integration: streamline client billing and monetize your programs effortlessly.', 'Collaborative coaching: Add additional coaches for just $6.99/month per coach. Grow your team and diversify expertise.'],
            img: MPopularTag
        },
        {
            amount: '$79.99',
            time: 'Per Month',
            cardName: "Elite",
            description: "Complete customization and complete control.",
            points: ['For top-tier coaches & gyms prioritizing brand identity.', 'All benefits of the Professional tier, plus:', 'Brand customisation: infuse your unique brand identity with customizable colors, logos, and more.', 'Unlimited coaching team expansion: Bring on as many coaches as you need without any additional costs.'],
            img: null
        },
    ]

    return (
        <Layout>
            <div className={`${style.main} d-flex flex-column align-items-center`} >
                <h1 className='text-center'>ELEVATE EXPECTATIONS WITH EXCEPTIONAL VALUE</h1>
                <div className={`${style.beforeMapDIV} d-grid`}>
                    {pricingCards.map((data, key) => (
                        <div className={`${style.parent} d-flex flex-column`}>
                            <div key={key} className={`${style.card} d-flex flex-column position-relative`}>
                                <div className='d-flex align-items-end bg-border-radius'>
                                    <h1>{data.amount}</h1>
                                    <p>{data.time}</p>
                                    {data.img && <img src={data.img} alt="tag" className={`${style.MPTag} position-absolute`} />}
                                </div>
                                <div>
                                    <h1>{data.cardName}</h1>
                                    <p>{data.description}</p>
                                </div>
                                <div>
                                    {data.points.map((point, idx) => (
                                        <div key={idx} className={`${style.points} d-flex align-items-center`}>
                                            <img src={Pointer} alt="pointer" />
                                            <li>{point}</li>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='btn d-flex justify-content-center'>
                                <Button bg={'black'} title={'Get Started'} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}

export default Pricing
