import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import styles from './history.module.css'
import HistorySessionModal from './HistorySessionModal';
import { getExerciseHistory } from '../../../../../apis';
import { auth } from '../../../../../firebase';
import axios from 'axios';
import Loader from '../../../../ReusableUi/Loader';


const Section = styled.section`
display: flex;
justify-content: space-between;
margin: 30px 50px;
`;


const EditIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75391 17.499H7.24507L7.2461 17.499C7.2461 17.499 7.49234 17.4994 7.72019 17.4058C7.72019 17.4058 7.94906 17.3118 8.12444 17.1373L17.5005 7.76121C17.5005 7.76121 17.6761 7.58837 17.7731 7.35672C17.7731 7.35672 17.8701 7.12508 17.8701 6.87395C17.8701 6.87395 17.8701 6.62283 17.7731 6.39118C17.7731 6.39118 17.6761 6.15954 17.4971 5.98333L14.0162 2.50233C14.0162 2.50233 13.8433 2.32679 13.6117 2.2298C13.6117 2.2298 13.38 2.13281 13.1289 2.13281C13.1289 2.13281 12.8778 2.13281 12.6461 2.2298C12.6461 2.2298 12.4145 2.32679 12.2383 2.50572L2.86665 11.8773C2.86665 11.8773 2.69104 12.0538 2.59704 12.2827C2.59704 12.2827 2.50304 12.5115 2.50391 12.759L2.50391 16.249C2.50391 16.249 2.50391 16.7667 2.87003 17.1328C2.87003 17.1328 3.23614 17.499 3.75391 17.499ZM16.6167 6.87733L7.24503 16.249H3.75391L3.7539 12.7578L13.1289 3.38281L13.1323 3.38621L16.62 6.87396L16.6167 6.87733Z" fill="#A19686" />
    <path d="M14.5616 9.81564C14.6789 9.93285 14.8381 9.99902 15.0039 9.99902C15.1697 9.99902 15.3286 9.93318 15.4458 9.81597C15.5631 9.69875 15.6289 9.53978 15.6289 9.37402C15.6289 9.20826 15.5631 9.04929 15.4458 8.93208L11.0709 4.55716C10.9536 4.43987 10.7947 4.37402 10.6289 4.37402C10.4631 4.37402 10.3042 4.43987 10.187 4.55708C10.0698 4.67429 10.0039 4.83326 10.0039 4.99902C10.0039 5.16478 10.0698 5.32375 10.187 5.44097L14.5616 9.81564Z" fill="#A19686" />
    <path d="M15.6103 9.22275C15.6086 9.21592 15.6067 9.20881 15.6048 9.20205C15.5612 9.04971 15.4613 8.91962 15.3255 8.83809C15.3142 8.83131 15.3027 8.8249 15.291 8.81885C15.2022 8.77297 15.1038 8.74902 15.0039 8.74902C14.9528 8.74902 14.9019 8.75529 14.8523 8.76768C14.8455 8.76939 14.8387 8.77121 14.8319 8.77315C14.5638 8.8499 14.3789 9.09508 14.3789 9.37402C14.3789 9.42512 14.3852 9.47603 14.3976 9.52561L14.9367 11.6823L12.0622 14.5568C11.945 14.6741 11.8789 14.8333 11.8789 14.999C11.8789 15.1648 11.9448 15.3238 12.062 15.441C12.1792 15.5582 12.3381 15.624 12.5039 15.624C12.6697 15.624 12.8286 15.5582 12.9458 15.441L16.0708 12.316C16.2261 12.1607 16.2885 11.9354 16.2352 11.7224L15.6103 9.22275Z" fill="#A19686" />
    <path d="M7.02258 17.2766C7.13979 17.3938 7.29908 17.46 7.46484 17.46C7.6306 17.46 7.78957 17.3941 7.90678 17.2769C8.02399 17.1597 8.08984 17.0007 8.08984 16.835C8.08984 16.6692 8.02399 16.5102 7.90678 16.393L3.60998 12.0962C3.4927 11.9789 3.33373 11.9131 3.16797 11.9131C3.00221 11.9131 2.84324 11.9789 2.72603 12.0961C2.60881 12.2134 2.54297 12.3723 2.54297 12.5381C2.54297 12.7038 2.60881 12.8628 2.72603 12.98L7.02258 17.2766Z" fill="#A19686" />
  </svg>
);
const History = (props) => {
  const { history, exerciseId } = props;
  const [isOpenExerModal, setIsOpenExerModal] = useState(false);
  const [getHistory, setHistory] = useState([])
  const [isLoading, setIsloading] = useState(false);
  const closeExerciseModal = () => {
    setIsOpenExerModal(false);
  };

  useEffect(() => {
    setIsloading(true)
    const fetchSessionHistory = async () => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}${getExerciseHistory}viewHistory?exerciseId=${exerciseId}`,
          { headers: { Authorization: token } }
        );
        setHistory(response.data.athleteBlocks)
        // setHistory(response.data.responseData[0].AthBlckAthSession)
        setIsloading(false)
      } catch (error) {
        setIsloading(false)
        console.error("Error fetching session calendar:", error);
      }
    }

    fetchSessionHistory();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return date.toLocaleDateString('en-US', options);
  };

  // Function to render AthltBlockSets values for each exercise
  const formatAthBlckAthSession = (AthltBlockSets) => {
    return AthltBlockSets?.map(set => `${set?.value1}@${set?.value2}kg`).join(', ');
  };

  const handleHistorySession = () => {
    setIsOpenExerModal(true)
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loader />
        </div>
      ) : getHistory.map((data, index) => (
        <Section key={index}>
          <div>
            <div className={styles.historySessionDate}><span>{formatDate(data?.createdAt)}</span></div>
            <div className={styles.historySessionReps}><span>                                            {data.AthltBlockSets.length >= 1 ? (
              formatAthBlckAthSession(data.AthltBlockSets)
            ) : (
              "There is No Set and Reps"
            )}</span></div>
          </div>
          <div className={styles.historySessionEdit} onClick={handleHistorySession}>
            <EditIcon />
            <span>Edit</span>
          </div>
        </Section>
      ))}
      {isOpenExerModal && (
        <HistorySessionModal
        getHistory={getHistory}
          isOpen={isOpenExerModal}
          onClose={closeExerciseModal}
        />
      )}
    </>
  )
}

export default History