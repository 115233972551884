import React from 'react'
import { Side } from '../../../../../../assessts/LandingPage'
import style from './style.module.css'
import Button from '../../../../Components/Button/Button'

const SecondComp = () => {
    return (
        <div className={`${style.Main}`}>
            <div className={`${style.contentBox} d-flex flex-column`}>
                <h1>CONTROL WITH CONVENIENCE</h1>
                <h5>Upload   Programming and Take Payments Anytime</h5>
                <p>In the fast-paced world of fitness, adaptability is key. Life has a habit of throwing curveballs, and sometimes, changes need to be made on the fly. TheTrainingApp equips you with not only the ability to modify and update your programming instantly, right from your device, but the added luxury of adding brand new clients straight to the programs and team without having to wait. Whether you're a coach or a gym owner, our app gives you the flexibility to make immediate changes, ensuring your training plans are always up-to-date and reflective of your evolving needs and those of your clients. Dive into the new age of fitness management with TheTrainingApp</p>
                <Button title={"Get Started Now"} bg={false} />
            </div>
            <div className={`${style.Img} d-flex`}>
                <img src={Side} alt="" />
            </div>
        </div>
    )
}

export default SecondComp
