import React from 'react'
import style from './five.module.css'
import { SidePnl } from '../../../../../../assessts/LandingPage'

const Five = () => {
  return (
    <div className={`${style.main}`}>
            <div className={`${style.belowDiv} d-flex  align-items-center justify-content-center`}>
                <div className={`${style.text}`}>
                    <h1>Stay ahead of the game with real-time program updates</h1>
                    <p>TrainingApp enables your coach to make adjustments on the move, ensuring that your training plan remains tailored to your evolving needs and goals. Experience the power of dynamic training that adapts to your progress.</p>
                </div>
                <div className={`${style.IMGdiv} `}>
                    <img src={SidePnl} alt="" />
                </div>
            </div>
        </div>
  )
}

export default Five
