import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from "../index.module.css"
import { useTranslation } from 'react-i18next';
const TabHeader = (props) => {
    //Get Data From Props 
    const { indexActive, handleActiveIndex } = props;
    //React 18 Translation 
    const { t } = useTranslation();


    return (
        <Row className='mb-4'>
            <Col sm={12} md={6} lg={6} className={`${styles.tabHeaderSection}`}>
                <Col sm={3} md={3} lg={3}>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleActiveIndex("programs")}
                        id={indexActive === "programs" && "active"}
                    >
                        <p id="as" className={`${styles.tabHeaderSectionText} m-0 p-0`}>{t("Programs")}</p>
                    </div>
                </Col>
                <Col sm={3} md={3} lg={3}>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleActiveIndex("session")}
                        id={indexActive === "session" && "active"}
                    >
                        <p id="as" className={`${styles.tabHeaderSectionText} m-0 p-0`}>{t("Session")}</p>
                    </div>
                </Col>
                <Col sm={3} md={3} lg={3}>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleActiveIndex("exercises")}
                        id={indexActive === "exercises" && "active"}
                    >
                        <p id="as" className={`${styles.tabHeaderSectionText} m-0 p-0`}>{t("Exercises")}</p>
                    </div>
                </Col>
                <Col sm={3} md={3} lg={3}>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleActiveIndex("marketplace")}
                        id={indexActive === "marketplace" && "active"}
                    >
                        <p id="as" className={`${styles.tabHeaderSectionText} m-0 p-0`}>{t("Marketplace")}</p>
                    </div>
                </Col>
            </Col>
        </Row>
    )
}

export default TabHeader