import axios from "axios";
import { auth } from "../firebase";

export const postApiMethod = async (endpoint, payload = {}, method = 'post') => {
    try {
        const url = `${process.env.REACT_APP_Baseurl}${endpoint}`;
        const token = await auth.currentUser.getIdToken(true);
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        // Convert method to lowercase
        const response = await axios[method](url, payload, config);

        return response.data;
    } catch (error) {
        console.error("API call error:", error);
        throw error;
    }
};
