import React from 'react'
import style from './style.module.css'
import { Phone1, Phone2, Phone3 } from '../../../../../../assessts/LandingPage'
import Button from '../../../../Components/Button/Button'

const Main = () => {
  return (
        <div className={`${style.bgImg}`}>
          <div className={`${style.content}`}>
            <div className={`${style.TEXT} text-center`}>
              <h1>QUALITY WITHOUT COMPROMISE</h1>
              <p>The training app alleviates the financial impact of over priced software and introduces innovative means of seamless management and improved client relationships.</p>
            </div>
            <div  className={`${style.btnDIV} d-flex`}>
              <Button title={'COACH'} bg={true}/>
              <Button title={'ATHLETE'} bg={true}/>
              <Button title={'GYM'} bg={true}/>
            </div>
            <div className={`${style.phones} "d-flex flex-row" `}>
              <img src={Phone1} alt="iPhone 1" />
              <img src={Phone2} alt="iPhone 2" />
              <img src={Phone3} alt="iPhone 3" />
            </div>
          </div>
        </div>
  )
}

export default Main
