
import React from "react";
import "./inviteModal.css";
import { Container, Row, Col } from 'react-bootstrap';

const ConnectAccountModal = ({ isOpen, onClose }) => {
    return (
        <div className={`exercise-modal ${isOpen ? 'exercise-open' : ''}`}>
            <div className="exercise-overlay" onClick={onClose}></div>
            <div className="exercisemmodalBody" style={{ width: "30vw",backgroundColor : `white`  }}>
                <h2 style={{ textTransform: "uppercase",color : `black` }}>Connect Account</h2>
                <Container>
                    <Row className='mb-3'>
                        <Col xs={12} md={12} className='p-0 mt-3'>
                            <p style={{ fontSize: '20px', color: ' rgba(0, 0, 0, 0.40)' }}>Please create connect account first before inviting an athlete. Go to Business Tab and click Setup Stripe Payments.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default ConnectAccountModal;
