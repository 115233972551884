import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./usersection.module.css";
import { useTranslation } from "react-i18next";
import ChatList from "../chatList";
import Session from "../session/session";
import Assistant from "../assistance/assistance";
import Calender from "../calender/calender";
import { SessionContext } from "../../../../context/sessionContext";
import FeedCard from "../FeedCard/feedCard";
const UserSection = () => {
  const { t } = useTranslation();
  const [isActiveTab, setActiveTab] = useState("feed");
  const { colors } = useContext(SessionContext);
  return (
    <>
      <Row className={`${styles.userSectionRow} m-0`}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          className={styles.userSection}
          style={{ background: "#F8FAFB"}}
        >
          <Col xs={12} sm={12} md={12} lg={10}>
            <div  className={`${styles.userSectionActiveTab} mt-3 w-100`}>
              <div
                className={`${styles.dashboard_buttonsTab} w-100`}
                style={{ cursor: "pointer" }}
                onClick={() => setActiveTab("feed")}
                id={isActiveTab === "feed" && "active"}
                
               
              >
                <p  id="as" className="m-0">
                  {t("Feed")}
                </p>
              </div>
              <div
                className={`${styles.dashboard_buttonsTab} w-100`}
                style={{ cursor: "pointer" }}
                onClick={() => setActiveTab("session")}
                id={isActiveTab === "session" && "active"}
              >
                <p id="as" className="m-0">
                  {t("Session")}
                </p>
              </div>
              <div
                className={`${styles.dashboard_buttonsTab} w-100`}
                style={{ cursor: "pointer" }}
                onClick={() => setActiveTab("assitantCoach")}
                id={isActiveTab === "assitantCoach" && "active"}
              >
                <p id="as" className="m-0">
                  {t("Assistant Coach")}
                </p>
              </div>
              <div
                className={`${styles.dashboard_buttonsTab} w-100`}
                style={{ cursor: "pointer" }}
                onClick={() => setActiveTab("calender")}
                id={isActiveTab === "calender" && "active"}
              >
                <p id="as" className="m-0">
                  {t("Calender")}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12}>
            {isActiveTab === "feed" && <FeedCard />}
            {isActiveTab === "session" && <Session />}
            {isActiveTab === "assitantCoach" && (
              <Assistant isActiveTab={isActiveTab} />
            )}
            {isActiveTab === "calender" && <Calender />}
          </Col>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={4}
          style={{backgroundColor :  "white"}}
          className={`${styles.userSectionChatList}  p-0`}
        >
          <ChatList />
        </Col>
      </Row>
    </>
  );
};

export default UserSection;
