import React, { useEffect, useState } from "react";
import "./manageMarketplace.css";
import { FaSearch} from "react-icons/fa";
import Table from "../../Table/Table";
import { toast } from "react-toastify";
import { auth } from "../../../firebase";
import axios from "axios";
import { veiwMarketPlace } from "../../../services/store/adminApi";
import CreateProgramModal from "../../modals/createProgramModal";

const ManageMarketplace = (props) => {

  const {activeTab} = props;

  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [isProgramModalOpen, setIsProgramModal] = useState(false);
  const [currentProgram, setCurrentProgram] = useState("");

  const fetchMarketPlace = async () => {

    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}${veiwMarketPlace}?currentPage=1&itemsPerPage=4`,
        { headers: { Authorization: token } }
      );
      setData(response?.data?.programs)
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch groups.");
    }
  }

  useEffect(() => {
    fetchMarketPlace();
  }, []);

  const closeProgramModal = () => {
    setIsProgramModal(false);
  };

  return (
    <div style={{ height:  "auto" }} className="athltContainer">
      <div className="athltHeader">Marketplace</div>
      <div
        className={!show && "athbody"}
        style={{
          padding: !show && "2rem",
        }}
      >
          <div className="athlt2ndHeader">
            <div>
              <div className="search-container">
                <span className="search-icon">
                  <FaSearch />
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input"
                />
              </div>
            </div>
         
          </div>
        <Table setCurrentExercise={setCurrentProgram} activeTab={activeTab} data={data} setIsOpenExerModal={setIsProgramModal} />
      </div>
      {isProgramModalOpen && (
          <CreateProgramModal
            setCurrentProgram={setCurrentProgram}
            isOpen={isProgramModalOpen}
            onClose={closeProgramModal}
            currentProgram={currentProgram}
            activeTab={activeTab}
          />
        )}
    </div>
  );
};

export default ManageMarketplace;
