//Traning Area Api 
export const getAtheleSession = "/api/v1/athlete/viewAthleteSession"
export const addExAthSession = "/api/v1/athlete/addExAthSession";
export const calculateOneRepMax = "/api/v1/athlete/calculateOneRepMax";
export const calculatePersonalRecord = "/api/v1/athlete/calculatePersonalRecord";
export const saveOneRepMaxValue = "/api/v1/athlete/saveOneRepMax"
export const atheleteFinishSession = "/api/v1/athlete/finishSession"
export const getPersonalRecords = "/api/v1/athlete/viewPersonalRecords"
export const getExerciseHistory = "/api/v1/athlete/"
export const postFeed = "/api/v1/athlete/postFeed"
export const getOneRepMax = "/api/v1/athlete/veiwOneRepMax"
export const addOrEditBlockSet ="/api/v1/athlete/addOrEditAthleteBlockSet"

//Feeds Api
export const getAtheleteFeed = "/api/v1/athlete/viewFeed"
export const  likeAPost = "/api/v1/athlete/likePost"
export const  commentsAPost= "/api/v1/athlete/commentOnPost"


// Athelete Group Api 
export const getAtheleteGroups = "/api/v1/athlete/getGroupsByAthleteId"
export const getAtheleGroupSession = "/api/v1/athlete/viewGroupAthleteSession"
export const addAthelteGroupExercise = "/api/v1/athlete/addGroupAthleteExerciseSession"
export const calculateGroupOneRepMax = "/api/v1/athlete/calculateOneRepMax";
export const calculateGroupPersonalRecord = "/api/v1/athlete/calculatePersonalRecords"
export const getGroupPersonalRecords = "/api/v1/athlete/viewPersonalRecords"
export const saveGroupOneRep = "/api/v1/athlete/saveOneRepMax"
export const addOrEditBlockSetGroup ="/api/v1/athlete//addOrEditGroupBlockSet"

//Index Athelete Api 
export const programIndex = "/api/v1/athlete/getPrograms"
export const atheleteIndexSession = "/api/v1/athlete/getAthleteSessions"
export const groupIndexSession = "/api/v1/athlete/getGroupAthleteSessions"
export const GetProgramme = "/api/v1/athlete/getPrograms"
export const GetExercise = "/api/v1/athlete/getExercises"
export const CreateExercise = "/api/v1/athlete/createExercise"
export const ViewExercises = "/api/v1/athlete/viewExercises"
