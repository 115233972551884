import React, { useContext, useEffect } from 'react'
import styles from "../../atheleteSessions.module.css"
import { SessionContext } from '../../../../../context/sessionContext'
import SvgIcons from '../../../../ReusableUi/SvgIcon'
const Header = ({ isTimeStart, setTimeStart, globalTotalReps, globalTotalWeight }) => {
    const { handleStartSessionGroup } = useContext(SessionContext)
    /**
     * Time Handler Which Runs 
     */

    useEffect(() => { // Timer updates only if the session is not complete
        const timer = setInterval(() => {
            setTimeStart(prevTime => {
                let { minutes, seconds } = prevTime;

                seconds++;
                if (seconds === 60) {
                    minutes++;
                    seconds = 0;
                }

                return { minutes, seconds };
            });
        }, 1000);

        return () => clearInterval(timer);

    }, []);

    return (
        <div className='d-flex justify-content-between px-lg-5 my-3 px-sm-0 px-md-3  align-content-center'>
            <div onClick={handleStartSessionGroup} style={{ cursor: "pionter" }}>
                <SvgIcons iconName="backArrow" size={20} />
            </div>
            <div>
                <span className={styles.valueReps}>
                    {globalTotalReps || 0}
                    <span> &nbsp;reps</span>
                </span>    ,&nbsp;
                <span className={styles.valueReps}>
                    {globalTotalWeight || 0}
                    <span>
                        &nbsp;weight
                    </span>
                </span>
            </div>
            <div>
                <div><span className={styles.timeCounts} style={{ color: "#1180EA" }}>{isTimeStart.minutes.toString().padStart(2, '0')}:{isTimeStart.seconds.toString().padStart(2, '0')}</span></div>
            </div>
        </div>
    )
}

export default Header