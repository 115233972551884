import React from 'react'
import Layout from '../../Layout'
import Program1 from './Program1/Program1'

const Programs = () => {
  return (
    <Layout>
      <Program1 />
    </Layout>
  )
}
export default Programs
