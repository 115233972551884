import React, { useContext, useState } from "react";
import Avtr from "../../../assessts/images/avtr.png";
import "./table.css";
import CreateProgramModal from "../../modals/createProgramModal";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../Common/Pagination/pagination";
import { SessionContext } from "../../../context/sessionContext";

import { Oval } from "react-loader-spinner";
import Loader from "../../ReusableUi/Loader";
import SvgIcons from "../../ReusableUi/SvgIcon";
import Skeleton from "react-loading-skeleton";
import Tooltip from "../../ReusableComponent/Tooltip";
const IndexTable = ({
  isEmptyDataForProgram,
  totalPages,
  programs,
  currentProgram,
  setCurrentProgram,
  setShow,
  showDeleteCheckBox,
  setSelectedPrograms,
  selectedPrograms,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const {
    isActivePaginationProgamme,
    isLoader,
    handleActivePaginationProgamme,
  } = useContext(SessionContext);
  const closeProgramModal = () => {
    setIsOpen(false);
  };

  const handleEdit = (program) => {
    setCurrentProgram(program);
    setIsOpen(true);
  };

  const handleCheckboxChange = (programId) => {
    setSelectedPrograms((prevSelectedPrograms) => {
      if (prevSelectedPrograms.includes(programId)) {
        return prevSelectedPrograms.filter((id) => id !== programId);
      } else {
        return [...prevSelectedPrograms, programId];
      }
    });
  };
  console.log(programs, "programs");
  return (
    <>
      <table>
        <br />
        {isLoader ? (
          Array.from({ length: 5 }).map((_, index) => (
            <>
              <br />
              <tr key={index}>
                <td>{<Skeleton height={30} width={100} />}</td>
                <td>{<Skeleton height={30} width={50} />}</td>
                <td>{<Skeleton height={30} width={100} />}</td>
                <td>{<Skeleton height={30} width={50} />}</td>
                <td>{<Skeleton height={30} width={150} />}</td>
              </tr>
            </>
          ))
        ) : isEmptyDataForProgram ? (
          <div
            className="w-full d-flex flex-column justify-content-center align-items-center"
            style={{ height: "20vw" }}
          >
            <SvgIcons iconName="noSessionPlane" />
            <div className="w-100 d-flex flex-column justify-content-center">
              <h4 className="noSessionHeading d-flex justify-content-center">
                No progames
              </h4>
              <div className="d-flex justify-content-center">
                {/* <h4 className="noSessionDescription">Create a Session from Index Session From Groups Athlete and from Index Session.</h4> */}
              </div>
            </div>
          </div>
        ) : (
          programs && (
            <>
              <table>
                <thead>
                  <tr
                    className="tablehead"
                    style={{
                      padding: " 0px 64px 0px 40px",
                    }}
                  >
                    {/* <th></th> */}
                    <th
                      style={{
                        textAlign: "start",
                        fontWeight: 700,
                        paddingLeft: "2rem ",
                        paddingBottom: "0.5rem",
                        paddingTop: "0.5rem",
                        fontSize: "14px",
                        fontFamily: "Hurme Geometric Sans 2",
                        backgroundColor: `var(--primary-color,#F6F6F0)`,
                        color: `var(--primary-text-color,#a19686)`,
                      }}
                    >
                      Program
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        fontFamily: "fontFamily:Hurme Geometric Sans 2",
                        fontWeight: 700,
                        backgroundColor: `var(--primary-color,#F6F6F0)`,
                        color: `var(--primary-text-color,#a19686)`,
                      }}
                    >
                      Sell to MarketPlace
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        fontFamily: "fontFamily:Hurme Geometric Sans 2",
                        fontWeight: 700,
                        backgroundColor: `var(--primary-color,#F6F6F0)`,
                        color: `var(--primary-text-color,#a19686)`,
                      }}
                    >
                      Coach
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        fontFamily: "fontFamily:Hurme Geometric Sans 2",
                        fontWeight: 700,
                        backgroundColor: `var(--primary-color,#F6F6F0)`,
                        color: `var(--primary-text-color,#a19686)`,
                      }}
                    >
                      Fee
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        fontFamily: "fontFamily:Hurme Geometric Sans 2",
                        fontWeight: 700,
                        backgroundColor: `var(--primary-color,#F6F6F0)`,
                        color: `var(--primary-text-color,#a19686)`,
                      }}
                    >
                      Level
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        fontFamily: "fontFamily:Hurme Geometric Sans 2",
                        fontWeight: 700,
                        backgroundColor: `var(--primary-color,#F6F6F0)`,
                        color: `var(--primary-text-color,#a19686)`,
                      }}
                    >
                      Sessions
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        fontFamily: "Hurme Geometric Sans 2",
                        fontWeight: 700,
                        backgroundColor: `var(--primary-color,#F6F6F0)`,
                        color: `var(--primary-text-color,#a19686)`,
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <br />
                <tbody style={{ borderStyle: "none" }} className="tableBody">
                  {" "}
                  {programs &&
                    programs.map((program) => {
                      return (
                        <>
                          <tr key={program.id}>
                            <td className="chk" style={{ paddingLeft: "2rem" }}>
                              {showDeleteCheckBox && (
                                <>
                                  <label class="containerInput">
                                    <input
                                      type="checkbox"
                                      // checked={selectedPrograms.includes(
                                      //   program?.id
                                      // )}
                                      onChange={() =>
                                        handleCheckboxChange(program?.id)
                                      }
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </>
                              )}
                              <img
                                id="chkImage"
                                width="48px"
                                height="48px"
                                src={program?.Picture}
                              />
                              <p
                                id="as"
                                className="indexTableDataName"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Hurme Geometric Sans 2",
                                }}
                              >
                                {program.title}
                              </p>
                            </td>
                            <td>
                              <p id="as" className="indexTableMarketPlace">
                                {program.marketplace === true
                                  ? "True"
                                  : "False"}
                              </p>
                            </td>

                            <td>
                              <p
                                id="as"
                                className="indexCoachTitle"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Hurme Geometric Sans 2",
                                  textTransform: "capitalize",
                                }}
                              >
                                {program?.programcoach?.firstName}{" "}
                                {program?.programcoach?.lastName}
                              </p>
                            </td>
                            <td>
                              <div id="as" className="indexTableFeesValue">
                                <span>
                                  {"$"} {program.fee}
                                </span>
                              </div>
                            </td>
                            <td style={{ textAlign: "center  !important" }}>
                              <div className="d-flex justify-content-center aligin-items-center">
                                <p
                                  className="indexTableLevelTitle"
                                  id="interf"
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Hurme Geometric Sans 2",
                                    margin: "0",
                                  }}
                                >
                                  {program.level}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center aligin-items-center">
                                <button
                                  style={{
                                    background: `var(--primary-color, rgba(75, 82, 255, 0.10))`,
                                    color: `var(--primary-text-color,#4B52FF)`,
                                  }}
                                  className="indexSession-button indexTableCreateSession"
                                  onClick={() => {
                                    setShow(true);
                                    setCurrentProgram(program);
                                  }}
                                >
                                  <span>Add Session</span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingRight: "0.5rem",
                                  gap: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                <Tooltip
                                  content={"Program View"}
                                  children={
                                    <div className="indexSee">
                                      <svg
                                        id="tableiconindex"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M15.4569 8.70307C15.4569 8.70307 15.3173 9.01721 15.0325 9.46953C15.0325 9.46953 14.4713 10.3608 13.7286 11.1036C13.7286 11.1036 11.3321 13.5 8 13.5C8 13.5 4.66789 13.5 2.27145 11.1036C2.27145 11.1036 1.52865 10.3608 0.967509 9.46953C0.967509 9.46953 0.68271 9.0172 0.543094 8.70307C0.485635 8.57379 0.485635 8.42621 0.543094 8.29693C0.543094 8.29693 0.682709 7.9828 0.967509 7.53047C0.967509 7.53047 1.52865 6.63924 2.27145 5.89645C2.27145 5.89645 4.6679 3.5 8 3.5C8 3.5 11.3321 3.5 13.7286 5.89645C13.7286 5.89645 14.4713 6.63924 15.0325 7.53047C15.0325 7.53047 15.3173 7.98279 15.4569 8.29693C15.5144 8.42621 15.5144 8.57379 15.4569 8.70307ZM14.1863 8.93672C14.1863 8.93672 14.3416 8.68992 14.4429 8.5C14.4429 8.5 14.3417 8.31008 14.1863 8.06328C14.1863 8.06328 13.6849 7.267 13.0214 6.60355C13.0214 6.60355 10.9179 4.5 8 4.5C8 4.5 5.08211 4.5 2.97855 6.60355C2.97855 6.60355 2.3151 7.26701 1.81374 8.06328C1.81374 8.06328 1.65835 8.31007 1.55706 8.5C1.55706 8.5 1.65835 8.68993 1.81374 8.93672C1.81374 8.93672 2.3151 9.73299 2.97855 10.3964C2.97855 10.3964 5.08211 12.5 8 12.5C8 12.5 10.9179 12.5 13.0214 10.3964C13.0214 10.3964 13.6849 9.733 14.1863 8.93672Z"
                                          fill="#5E4AE3"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M8 5.5C8 5.5 9.24264 5.5 10.1213 6.37868C10.1213 6.37868 11 7.25736 11 8.5C11 8.5 11 9.74264 10.1213 10.6213C10.1213 10.6213 9.24264 11.5 8 11.5C8 11.5 6.75736 11.5 5.87868 10.6213C5.87868 10.6213 5 9.74264 5 8.5C5 8.5 5 7.25736 5.87868 6.37868C5.87868 6.37868 6.75736 5.5 8 5.5ZM8 6.5C8 6.5 7.17157 6.5 6.58579 7.08579C6.58579 7.08579 6 7.67157 6 8.5C6 8.5 6 9.32843 6.58579 9.91421C6.58579 9.91421 7.17157 10.5 8 10.5C8 10.5 8.82843 10.5 9.41421 9.91421C9.41421 9.91421 10 9.32843 10 8.5C10 8.5 10 7.67157 9.41421 7.08579C9.41421 7.08579 8.82843 6.5 8 6.5Z"
                                          fill="#5E4AE3"
                                        />
                                      </svg>
                                    </div>
                                  }
                                />
                                <div  onClick={() => handleEdit(program)} ></div>
                                <Tooltip content={"Program Edit"}  children={ <div
                                  className="indexEdit"
                                 
                                >
                                  <svg
                                    id="tableiconindex"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M13.9962 6.71081L6.35355 14.3535C6.25979 14.4473 6.13261 14.4999 6 14.4999H3C3 14.4999 2.58579 14.4999 2.29289 14.2071C2.29289 14.2071 2 13.9142 2 13.4999L2 10.7062C2 10.7062 1.99931 10.51 2.07451 10.3269C2.07451 10.3269 2.14971 10.1438 2.28932 10.0035L9.7902 2.50264C9.7902 2.50264 9.92847 2.36222 10.1138 2.28462C10.1138 2.28462 10.2991 2.20703 10.5 2.20703C10.5 2.20703 10.7009 2.20703 10.8862 2.28462C10.8862 2.28462 11.0715 2.36222 11.2125 2.50536L13.9973 5.29014C13.9973 5.29014 14.1377 5.42842 14.2153 5.61373C14.2153 5.61373 14.2929 5.79904 14.2929 5.99995C14.2929 5.99995 14.2929 6.20085 14.2153 6.38616C14.2153 6.38616 14.1385 6.56971 13.9973 6.70975L13.9962 6.71081ZM13.2929 5.99995L13.2902 5.99725L10.5 3.20703L10.4973 3.20975L3 10.707V13.4999H5.79289L13.2902 6.00264L13.2929 5.99995Z"
                                      fill="#26CF18"
                                    />
                                    <path
                                      d="M13.5 13.5H5.99999C5.72385 13.5 5.49999 13.7239 5.49999 14C5.49999 14.2761 5.72386 14.4999 6 14.4999L13.5 14.5C13.7761 14.5 14 14.2761 14 14C14 13.7239 13.7761 13.5 13.5 13.5Z"
                                      fill="#26CF18"
                                    />
                                    <path
                                      d="M11.6462 8.35329C11.74 8.44706 11.8674 8.5 12 8.5C12.1326 8.5 12.2598 8.44732 12.3535 8.35355C12.4473 8.25979 12.5 8.13261 12.5 8C12.5 7.86739 12.4473 7.74021 12.3535 7.64645L8.85361 4.14651C8.75978 4.05268 8.6326 4 8.49999 4C8.36739 4 8.24021 4.05268 8.14644 4.14645C8.05267 4.24021 7.99999 4.36739 7.99999 4.5C7.99999 4.63261 8.05267 4.75979 8.14644 4.85355L11.6462 8.35329Z"
                                      fill="#26CF18"
                                    />
                                  </svg>
                                </div>} />
                              <Tooltip content={"Upload"} children={<div className="indexUpload">
                                  <svg
                                    id="tableiconindex"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                  >
                                    <path
                                      d="M2 9H5C5.27614 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.27614 8 5 8H2C1.58579 8 1.29289 8.29289 1.29289 8.29289C1 8.58579 1 9 1 9V13C1 13.4142 1.29289 13.7071 1.29289 13.7071C1.58579 14 2 14 2 14H14C14.4142 14 14.7071 13.7071 14.7071 13.7071C15 13.4142 15 13 15 13V9C15 8.58579 14.7071 8.29289 14.7071 8.29289C14.4142 8 14 8 14 8H11C10.7239 8 10.5 8.22386 10.5 8.5C10.5 8.77614 10.7239 9 11 9H14V13H2V9Z"
                                      fill="#DA8B2E"
                                    />
                                    <path
                                      d="M8.5 8.5V2C8.5 1.72386 8.27614 1.5 8 1.5C7.72386 1.5 7.5 1.72386 7.5 2V8.5C7.5 8.77614 7.72386 9 8 9C8.27614 9 8.5 8.77614 8.5 8.5Z"
                                      fill="#DA8B2E"
                                    />
                                    <path
                                      d="M10.6462 5.35329C10.74 5.44706 10.8674 5.5 11 5.5C11.1326 5.5 11.2598 5.44732 11.3536 5.35355C11.4473 5.25979 11.5 5.13261 11.5 5C11.5 4.86739 11.4473 4.74021 11.3536 4.64645L8.35355 1.64645C8.25978 1.55268 8.13261 1.5 8 1.5C7.86739 1.5 7.74022 1.55268 7.64645 1.64645L4.64663 4.64626C4.55287 4.74003 4.5 4.86739 4.5 5C4.5 5.00801 4.50019 5.01602 4.50058 5.02402C4.50655 5.14818 4.55855 5.26566 4.64645 5.35355C4.74021 5.44732 4.86739 5.5 5 5.5C5.13261 5.5 5.25979 5.44732 5.35355 5.35355L8 2.70711L10.6462 5.35329Z"
                                      fill="#DA8B2E"
                                    />
                                    <path
                                      d="M12.5 11C12.5 11.4142 12.1642 11.75 11.75 11.75C11.3358 11.75 11 11.4142 11 11C11 10.5858 11.3358 10.25 11.75 10.25C12.1642 10.25 12.5 10.5858 12.5 11Z"
                                      fill="#DA8B2E"
                                    />
                                  </svg>
                                </div>}  />
                                
                              </div>
                            </td>
                          </tr>
                          <br />
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          )
        )}
      </table>
      <CustomPagination
        currentPage={isActivePaginationProgamme}
        totalPages={totalPages}
        onPageChange={handleActivePaginationProgamme}
      />

      {isOpen && (
        <CreateProgramModal
          currentProgram={currentProgram}
          setCurrentProgram={setCurrentProgram}
          isOpen={isOpen}
          onClose={closeProgramModal}
        />
      )}
    </>
  );
};

export default IndexTable;
